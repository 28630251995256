import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    userFetch: builder.query({
      query: (profile) => ({ url: `/user?profile=${profile}` }),
      providesTags: ["User"],
    }),

    userFetchToken: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user`,
        method: "GET",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    userFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/user/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["User"],
    }),

    userUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/${payload.id}`,
        method: "PUT",
        body: payload.data,
      }),
      invalidatesTags: ["User"],
    }),

    userChangePassword: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/${payload.id}/change-password`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    userResetPassword: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/reset-password`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),

    userCheckToken: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/check-token`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useUserFetchQuery,
  useUserUpdateMutation,
  useUserFetchOneMutation,
  useUserCheckTokenMutation,
  useUserFetchTokenMutation,
  useUserResetPasswordMutation,
  useUserChangePasswordMutation,
} = userApi;
