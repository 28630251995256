import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    productFetch: builder.query({
      query: () => `/api/v1/product/`,
      providesTags: ["Product"],
    }),

    productFetchOne: builder.query({
      query: (id) => `/api/v1/product/${id}`,
      providesTags: ["Product"],
    }),

    ProductFetchMedia: builder.query({
      query: (id) => `/api/v1/media/product/${id}`,
      providesTags: ["Product"],
    }),
  }),
});

export const {
  useProductFetchQuery,
  useProductFetchOneQuery,
  useProductFetchMediaQuery,
} = productApi;
