import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Alert, Spin, Col } from "antd";
import { useDispatch } from "react-redux";
import Banner from "../../account/components/banner";
import {
  useAuthFetchTokenMutation,
  useAuthLoginCustomerMutation,
} from "../service/auth-api";
import {
  saveChatTokenState,
  saveTokenState,
} from "../../../utils/local-storage";
import { setChatToken, setToken, setUser } from "../service/auth-slice";

function LoginClientPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authLoginCustomer] = useAuthLoginCustomerMutation();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorCustomer, setIsErrorCustomer] = useState(false);
  const [errorCustomer, setErrorCustomer] = useState(false);

  const handleSubmitCustomer = (value) => {
    const data = { ...value, profile: "customer" };
    setIsLoading(true);
    authLoginCustomer(data)
      .unwrap()
      .then((res) => {
        if (res.role === "professional") {
          setIsLoading(false);
          setIsErrorCustomer(true);
          setErrorCustomer("Ce compte client n'existe pas !");
        } else {
          saveTokenState(res.access_token);
          saveChatTokenState(res.twilio_token);
          dispatch(setToken(res.access_token));
          dispatch(setChatToken(res.twilio_token));
          authFetchToken()
            .unwrap()
            .then((res) => {
              setIsLoading(false);
              dispatch(setUser(res));
              navigate("/account/profile");
            })
            .catch((error) => {
              setIsLoading(false);
              setIsErrorCustomer(true);
              setErrorCustomer(error.data.message);
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsErrorCustomer(true);
        setErrorCustomer(error.data.message);
      });
  };

  return (
    <div className="auth">
      <Banner title="Connexion" />
      <div className="container">
        <Spin spinning={isLoading}>
          <div className="row">
            <Col lg={8} style={{ margin: "auto" }}>
              <div className="title">
                <h2>Connexion Espace Client</h2>
              </div>
              <Form
                name="basic"
                onFinish={handleSubmitCustomer}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button className="btn-lg btn-primary" htmlType="submit">
                    Connexion
                  </Button>
                </Form.Item>
              </Form>

              <div style={{ textAlign: "center" }}>
                <Link to="/register-client" className="deja-inscrit">
                  Vous n'avez pas de compte? Inscrivez-vous
                </Link>
                <br />
                <Link to="/forget-password" className="deja-inscrit">
                  Mot de passe oublié?
                </Link>
                <br />
              </div>
              <br />
              {isErrorCustomer && (
                <Alert message={errorCustomer} type="error" showIcon />
              )}
            </Col>
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default LoginClientPage;
