import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../../../utils/baseUrl";
import { handleApiError } from "../../../utils/errorHandler";
import { showAlert } from "../../../redux/alertSlice";
import { showDialog } from "../../../redux/dialogSlice";


export const productFetch = createAsyncThunk(
  "products/productFetch",
  async () => {
    try {
      const response = await axios.get(baseURL + "api/product/");
      return response.data;
    } catch (error) {
      console.log("error.response:", error.response)
    }
  }
);


export const productsDelete = createAsyncThunk(
  "products/productsDelete",
  async (payload, { dispatch }) => {
    try {
      const response = await axios.delete(baseURL + "api/product/" + payload.id);
      dispatch(productFetch())
      return dispatch(showAlert({ isSuccess: true, successMessage: response.data.message }))
    } catch (error) {
      return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
    }
  }
);


export const productFetchOne = createAsyncThunk(
  "products/productFetchOne",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await axios.get(baseURL + "api/product/" + productId);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleApiError(error.response))
    }
  }
);

export const productCreate = createAsyncThunk(
  "products/productCreate",
  async (payload, { dispatch }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${baseURL}api/product/`,
        data: payload
      });
      dispatch(productFetch())
      dispatch(showDialog({ type: "" }))
      return dispatch(showAlert({ isSuccess: true, successMessage: response.data.message }))
    } catch (error) {
      return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
    }
  }
);


const initialState = {
  products: [],
  product: "",
  error: "",
  status: "idle",
  isFetching: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productClearState: (state) => {
      state.products = []
      state.product = ""
      state.error = "";
      state.status = 'idle';
      state.isFetching = false;
    },
  },
  extraReducers: {
    // fetch
    [productFetch.pending]: (state) => {
      state.status = "pending";
      state.isFetching = true
    },
    [productFetch.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.isFetching = false;
      state.products = payload;

    },
    [productFetch.rejected]: (state, action) => {
      console.log("action.payload:", action.payload)
      state.status = "rejected";
      state.isFetching = false
      state.error = action.payload;
    },

    // fetchOne
    [productFetchOne.pending]: (state) => {
      state.status = "pending";
      state.isFetching = true;
    },
    [productFetchOne.fulfilled]: (state, action) => {
      state.status = "success";
      state.isFetching = false;
      state.product = action.payload;
    },
    [productFetchOne.rejected]: (state, action) => {
      state.status = "rejected";
      state.isFetching = false;
      state.error = action.payload;
    },

    // Delete
    [productsDelete.pending]: (state) => {
      state.status = "pending";
      state.isFetching = true;
    },
    [productsDelete.fulfilled]: (state) => {
      state.status = "success";
      state.isFetching = false;
    },
    [productsDelete.rejected]: (state) => {
      state.status = "rejected";
      state.isFetching = false;
    },

    // Create
    [productCreate.pending]: (state) => {
      state.status = "pending";
      state.isFetching = true;
    },
    [productCreate.fulfilled]: (state) => {
      state.status = "success";
      state.isFetching = false;
    },
    [productCreate.rejected]: (state) => {
      state.status = "rejected";
      state.isFetching = false;
    },
  },
});

export const { productClearState } = productsSlice.actions;
export default productsSlice.reducer;