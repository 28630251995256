import React, { useState } from "react";
import { Button, Table, Image } from "antd";
import { useSelector } from "react-redux";
import PrestationCreate from "./prestation-create";
import {
  usePrestationCreateMutation,
  usePrestationDeleteMutation,
  usePrestationUpdateMutation,
  usePrestationByUserQuery,
} from "./service/prestation-api";
import { IMAGE_S3_URL } from "../../../utils/api-config";
import { Typography } from "antd";
import { Divider } from 'antd';

function PrestationPage() {
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editServiceId, seteditServiceId] = useState(null);
  const [prestationCreate] = usePrestationCreateMutation();
  const [prestationUpdate] = usePrestationUpdateMutation();
  const [prestationDelete] = usePrestationDeleteMutation();
  const { data: prestationData, refetch: refetchPrestation, isFetching } = usePrestationByUserQuery(user.id);
  const { Link } = Typography;

  const handleCreate = (values) => {
    const data = {
      libelle: values.libelle,
      price: values.price,
      image: values.image.file.name,
    };
    prestationCreate(data)
      .unwrap()
      .then(() => setShow(false))
      .catch((error) => console.log("error: ===>", error));
  };

  const handleDelete = (id) => {
    prestationDelete(id)
      .unwrap()
      .then()
      .catch((error) => console.log("error: ===>", error));
  };

  const handleEdit = (values) => {
    setShowEdit(false)
    const data = {
      id: editServiceId,
      libelle: values.libelle,
      price: values.price,
      image: values.image.file.name,
    };
    prestationUpdate(data)
    .unwrap()
    .then(() => {
      //setShowEdit(false)
      seteditServiceId(null)
      refetchPrestation()
    })
    .catch((error) => console.log("error: ===>", error));
  }

  const columns = [
    {
      title: "Libelle",
      width: 100,
      render: (record) => (
        <span>
          <Image width={60} src={`${IMAGE_S3_URL}/${record.image}`} />
          <span style={{ margin: "10px" }}>{record.libelle}</span>
        </span>
      ),
    },
    {
      title: "Prix",
      width: 30,
      render: (record) => <span>{record.price} €</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 30,
      render: (record) => (
        <>
          <Link onClick={() => {
            setShowEdit(true)
            seteditServiceId(record.id)
          }} >
            Edit
          </Link>
          <Divider type="vertical" />
          <Link onClick={() => handleDelete(record.id)}>
            Supprimer
          </Link>
        </>
      ),
    },
  ];

  const generateUniqueString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueString = '';
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueString += characters.charAt(randomIndex);
    }
    return uniqueString;
  };

  return (
    <div className="prestation animate__animated animate__fadeIn">
      <div className="page-title">
        <span>MES SERVICES</span>
      </div>

      <PrestationCreate
        status={show}
        handleCancel={() => setShow(false)}
        handleCreate={handleCreate}
      />

      <Button
        type="primary"
        className="btn-status"
        onClick={() => setShow(true)}
      >
        Ajouter
      </Button>
      <br />
      <br />
      {prestationData && (
        <>
          <Table
            columns={columns}
            key={generateUniqueString()}
            dataSource={prestationData}
            loading={isFetching}
          />
          <PrestationCreate
            status={showEdit}
            key={generateUniqueString()}
            handleCancel={() => setShowEdit(false)}
            handleCreate={handleEdit}
            editMode={true}
            data={prestationData.find((service) => service.id === editServiceId) || {}}
          />
        </>
      )}
    </div>
  );
}
export default PrestationPage;
