import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";


export const createFactureAsync = createAsyncThunk(
    "user/createFactureAsync",
    async (payload, { dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/facture/`,
                data: payload
            });
            return response.data.message;
        } catch (error) {
            return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
        }
    }
);

export const fetchCustomerAsync = createAsyncThunk(
    "facture/fetchCustomerAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/customer/facture/`,
                data: payload
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchProAsync = createAsyncThunk(
    "facture/fetchProAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/pro/facture/`,
                data: payload
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);


const initialState = {
    factures: [],
    error: "",
    status: "idle",
    loading: false
};

const factureSlice = createSlice({
    name: "facture",
    initialState,
    reducers: {},
    extraReducers: {
        [createFactureAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [createFactureAsync.fulfilled]: (state) => {
            state.status = "success";
            state.loading = false
            // state.factures = action.payload;
        },
        [createFactureAsync.rejected]: (state) => {
            state.loading = false
            state.status = 'failed';
        },

        [fetchCustomerAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [fetchCustomerAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.factures = action.payload;
        },
        [fetchCustomerAsync.rejected]: (state) => {
            state.loading = false
            state.status = 'failed';
        },

        [fetchProAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [fetchProAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.factures = action.payload;
        },
        [fetchProAsync.rejected]: (state) => {
            state.loading = false
            state.status = 'failed';
        },
    },
});

export default factureSlice.reducer;
