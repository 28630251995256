export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const apiKey =  process.env.REACT_APP_API_KEY;
export const mapApiJs = process.env.REACT_APP_MAP_API;
export const geocodeJson = process.env.REACT_APP_GEOCODE_JSON;

export const InputRegex = {
  strongPass: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  alphabetsCheck: "^[A-Za-z]*$",
  mobilePhoneCheck: "^[0-9]*$",
  addressCheck: "^[a-zA-Z0-9 -_]*$",
  departmentCheck: "^[a-zA-Z ]*$",
};

export const InputErrors = {
  strongPassError: `Le mot de passe doit contenir au minimum huit caractères, 
  au moins une lettre majuscule, un caractère spécial et un chiffre!`,
  nameError:
    "Le nom ne peut pas contenir de chiffres, de symboles ou de caractères spéciaux",
  mobilePhoneError: "Les numéros de mobile ne peuvent contenir que des numéros",
  addressError:
    "L'adresse ne peut pas commencer par des espaces vides ou ne peut pas être vide",
  departmentError:
    "Le département ne peut pas commencer avec des espaces vides ou ne peut pas être vide",
  regionError:
    "La région/état/province ne peut pas être vide ni contenir de caractères spéciaux ou de chiffres.",
  countryError:
    "Le pays ne peut pas être vide ni contenir de caractères spéciaux ou de chiffres.",
  postalCodeError: "Le code postal ne peut contenir que des chiffres.",
};

export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

export const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };
  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });
  return address;
};
