import { Row, Col, Button, Form, Input, Alert } from "antd";
import { useSelector } from "react-redux";
import { openNotification } from "../../../components/notification";
import { useUserChangePasswordMutation } from "../profile/service/user-api";
import { validation } from "../../../utils/errorHandler";
import { InputErrors, InputRegex } from "../../../utils/globalVars";

function ChangePasswordPage() {
  const { user } = useSelector((state) => state.auth);
  const [userChangePassword, { isLoading, isError, error }] =
    useUserChangePasswordMutation();
  const [form] = Form.useForm();

  const onFinish = ({ password }) => {
    userChangePassword({ id: user.id, password })
      .unwrap()
      .then(() => {
        form.resetFields();
        openNotification({
          message: "Succès",
          description: "Votre mot de passe a été mis à jour",
        });
      })
      .catch((error) => console.log("error ===>", error));
  };

  return (
    <div className="animate__animated animate__fadeIn account-page">
      <div className="page-title">
        <span>MODIFIER LE MOT PASSE</span>
      </div>
      <Form
        name="basic"
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        form={form}
      >
        {isError && (
          <>
            <Alert message={error.data} type="error" showIcon />
            <br />
          </>
        )}
        <Row gutter={24}>
          <Col lg={12}>
            <Form.Item
              label="Nouveau mot de passe"
              name="password"
              rules={[
                { required: true, message: "Champs requis!" },
                () => ({
                  validator(_, value) {
                    return validation(
                      value,
                      InputRegex.strongPass,
                      InputErrors.strongPassError
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Confirmer le nouveau mot de passe"
              name="passwordConfirm"
              rules={[
                { required: true, message: "Champs requis!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Les mots de passe ne correspondent pas!")
                    );
                  },
                }),
              ]}
              dependencies={["password"]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
export default ChangePasswordPage;
