import { Button, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./styles/style.scss";
import { useMailMessageNotificationMutation } from "../../features/account/service/mail-api";
import {
  SendOutlined,
  CloseSquareOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { setConversationUnreadMessage } from "../../utils/conversations";

const ChatBox = ({
  bottom = false,
  activeConversation,
  chatClient,
  setShowChat,
  closeButton = true,
}) => {
  const [messages, setMessages] = useState([]),
    [inputMessage, setInputMessage] = useState(""),
    [recieverEmail, setRecieverEmail] = useState(""),
    [chatHeader, setChatHeader] = useState(""),
    [loading, setLoading] = useState(false),
    [unreadIndex, setUnreadIndex] = useState(null),
    chatRef = useRef(null),
    [mailMessageNotification] = useMailMessageNotificationMutation(),
    { user } = useSelector((state) => state.auth);

  const handleSendMessage = async () => {
    setLoading(true);
    setInputMessage("");
    await mailMessageNotification({
      to: recieverEmail,
      sender_fname: user.firstname,
      sender_lname: user.lastname,
    });
    await activeConversation.sendMessage(inputMessage);
    setUnreadIndex(null);
  };

  const setConversationMessages = async () => {
    const { items } = await activeConversation.getMessages(1000);
    setMessages(items);
    setLoading(false);
  };

  const setChatAttributes = () => {
    const userKey =
      activeConversation.attributes?.user1?.id === user?.id ? "user2" : "user1";
    setChatHeader(activeConversation.attributes[userKey].name);
    setRecieverEmail(activeConversation.attributes[userKey].email);
  };

  const updateUnreadMessages = async () => {
    activeConversation.lastMessage &&
      (await activeConversation.updateLastReadMessageIndex(
        activeConversation.lastMessage?.index
      ));
  };

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
      updateUnreadMessages();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSendMessage();
  };

  useEffect(() => {
    setChatAttributes();
    setConversationMessages();
    setConversationUnreadMessage(activeConversation, user, setUnreadIndex);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    chatClient?.on("messageAdded", async (message) => {
      if (activeConversation.sid === message.conversation.sid) {
        setConversationMessages();
      }
    });
    // eslint-disable-next-line
  }, [chatClient]);

  useEffect(() => {
    scrollToBottom();
  });

  return (
    <div
      className={`Chat_container ${
        bottom ? "bottom_container" : "normal_container"
      }`}
    >
      <div className="Header">
        <h3>{chatHeader}</h3>
        {closeButton && (
          <span onClick={() => setShowChat(false)}>
            <CloseSquareOutlined />
          </span>
        )}
      </div>
      <div className="Chat_body" ref={chatRef}>
        {messages.length ? (
          messages.map((message, index) => (
            <div
              className={`${
                message.state.author === user.email
                  ? "Right_Message"
                  : "Left_Message"
              }`}
              key={index + message.sid}
            >
              {index === unreadIndex && (
                <div className="unreadMessages">---- Messages non lus ----</div>
              )}
              <div className="message">
                <p>{message.state.body}</p>
              </div>
              <div className="time_container">
                <span>{message.state.timestamp.toLocaleTimeString()}</span>
              </div>
            </div>
          ))
        ) : (
          <div>
            <h4>There are no messages yet!</h4>
          </div>
        )}
      </div>
      <div className="dummy" />
      <div className="Input_Container">
        <Input
          placeholder="Write your message"
          onChange={(e) => setInputMessage(e.target.value)}
          value={inputMessage}
          onKeyPress={handleKeyPress}
        />
        <Button
          type="primary"
          shape="default"
          onClick={handleSendMessage}
          disabled={loading}
        >
          Send {loading ? <LoadingOutlined /> : <SendOutlined />}{" "}
        </Button>
      </div>
    </div>
  );
};

export default ChatBox;
