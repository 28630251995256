import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import DisponibiliteCreate from "./disponibilite-create";
import {
  useDisponibiliteCreateMutation,
  useDisponibiliteDeleteMutation,
  useDisponibiliteFetchQuery,
} from "./service/disponibilite-api";
import DisponibiliteDelete from "./disponibilite-delete";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/fr";

const localizer = momentLocalizer(moment);

function DisponibilitePage() {
  const { user } = useSelector((state) => state.auth);
  const disponibilite = useDisponibiliteFetchQuery(user.id);
  const [disponibiliteCreate] = useDisponibiliteCreateMutation();
  const [disponibiliteDelete] = useDisponibiliteDeleteMutation();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const openModal = ({ start, end }) => {
    setShow(true);
    setStartDate(start);
    setEndDate(end);
  };

  const openDeleteModal = (value) => {
    setShowDelete(true);
    setId(value);
  };

  const handleCreate = async () => {
    disponibiliteCreate({
      start: startDate,
      end: endDate,
      professional: user.id,
    })
      .unwrap()
      .then(() => setShow(false))
      .catch((error) => console.log("error: ===>", error));
  };

  const handleDelete = async () => {
    disponibiliteDelete(id)
      .unwrap()
      .then(() => setShowDelete(false))
      .catch((error) => console.log("error: ===>", error));
  };

  const taskStyles = () => {
    const backgroundColor = "#881711";
    const style = {
      backgroundColor: backgroundColor,
      border: "none",
      borderRadius: "0",
      placeItems: "center",
      padding: "0",
      margin: "0",
      width: "10%",
    };
    return { style: style };
  };

  const filter = () => {
    var items = [];
    if (disponibilite)
      disponibilite.data.forEach((child) => {
        items.push({
          id: child.id,
          title: "Disponible",
          start: new Date(child.start),
          end: new Date(child.end),
        });
      });
    return items;
  };

  return (
    <div className="disponibilite animate__animated animate__fadeIn">
      <DisponibiliteCreate
        status={show}
        handleCancel={() => setShow(false)}
        startDate={startDate}
        handleCreate={handleCreate}
        endDate={endDate}
      />
      <DisponibiliteDelete
        status={showDelete}
        handleCancel={() => setShowDelete(false)}
        handleDelete={handleDelete}
      />
      <br />
      <div
        className="alert fade alert alert-info show"
        style={{ fontSize: "14px" }}
      >
        Cliquez sur une heure en maintenant la souris enfoncée puis relâchez
        pour renseigner une plage de disponibilité.
      </div>
      <div className="animate__animated animate__fadeIn">
        {disponibilite.data && (
          <Calendar
            events={filter()}
            step={60}
            formats={{
              timeGutterFormat: "hh:mm A",
            }}
            drilldownView="agenda"
            selectable
            localizer={localizer}
            onSelectSlot={openModal}
            onSelectEvent={(item) => openDeleteModal(item.id)}
            defaultView="week"
            views={{ week: true }}
            eventPropGetter={taskStyles}
            showAllEvents
            messages={{
              next: "Suivant",
              previous: "Précédent",
              today: "Aujourd'hui",
              month: "Mois",
              week: "Semaine",
              day: "Jour",
            }}
          />
        )}
      </div>
    </div>
  );
}
export default DisponibilitePage;
