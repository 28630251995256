import { Spin, Upload } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import { API_ROOT, IMAGE_S3_URL } from "../../../utils/api-config";
import { useUserUpdateMutation } from "../profile/service/user-api";
import { useAuthFetchTokenMutation } from "../../auth/service/auth-api";
import { setUser } from "../../auth/service/auth-slice";
import { useState } from "react";

function MenuLateral() {
  const dispatch = useDispatch();
  const [showAvatar, setShowAvatar] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user, token } = useSelector((state) => state.auth);
  const [authFetchToken] = useAuthFetchTokenMutation();
  const [userUpdate] = useUserUpdateMutation();

  const props = {
    name: "file",
    action: `${API_ROOT}/api/v1/upload`,
    headers: { Authorization: `Bearer ${token}` },

    onChange(info) {
      setShowAvatar(false);
      setLoading(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        userUpdate({ id: user.id, data: { image: info.file.name } })
          .unwrap()
          .then(() => {
            authFetchToken()
              .unwrap()
              .then((res) => {
                dispatch(setUser(res));
              });
          })
          .catch((error) => console.log("error ===>", error))
          .finally(() => {
            setTimeout(() => {
              setShowAvatar(true);
              setLoading(false);
            }, 2000);
          });
      } else if (info.file.status === "error") {
      }
    },
  };

  return (
    <>
      <div className="avatar">
        <Upload {...props}>
          <div className="avatar">
            {user.image && showAvatar ? (
              <div
                className="image"
                style={{
                  backgroundImage: `url(${IMAGE_S3_URL}/${user.image})`,
                }}
              ></div>
            ) : loading ? (
              <Spin size="large" />
            ) : (
              <Image
                src="/assets/img/avatar.png"
                alt="Logo"
                roundedCircle
                fluid
              />
            )}
          </div>
        </Upload>
        <p>
          <span>
            {user.lastname} {user.firstname}
          </span>
        </p>
        <br />
      </div>
      <ul>
        <NavLink to="/account/profile">Informations personnelles</NavLink>
        <NavLink to="/account/password-reset">Modifier le mot de passe</NavLink>
        <NavLink to="/account/mes-rendez-vous">Mes rendez-vous</NavLink>
        <NavLink to="/account/purchase-history/">Mes commandes</NavLink>
        <NavLink to="/account/message">Mes Messages</NavLink>
        {user.role === "professional" && (
          <>
            <NavLink to="/account/service">Prestations de service</NavLink>
            <NavLink to="/account/disponibilite">Mon calendrier</NavLink>
            <NavLink to="/account/paiement">Mes paiements</NavLink>
          </>
        )}
      </ul>
    </>
  );
}
export default MenuLateral;
