import React, { useEffect, useState } from "react";
import { Button, Row, Col, Alert, Tabs, Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import {
  usePaymentByUserQuery,
  usePaymentUpdateMutation,
} from "./service/payment-api";
import "./styles/style.scss";
import { useCreateDashboardLinkMutation } from "../../Stripe/service/stripe-api";

function PaiementPage() {
  const { user } = useSelector((state) => state.auth);
  const payment = usePaymentByUserQuery(user.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [paymentUpdate] = usePaymentUpdateMutation();
  const [createDashboardLink] = useCreateDashboardLinkMutation();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();

  const handleUpdate = (values) => {
    setIsLoading(true);
    paymentUpdate({
      id: payment.data.id,
      data: { ...values, isAccountConfirm: true },
    })
      .unwrap()
      .then(() => {
        setIsLoading(false);
        form.resetFields();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error: ===>", error);
      });
  };

  const handleSendMail = () => {
    setIsPayment(false);
  };

  const getDashboardLink = async () => {
    const { data } = await createDashboardLink({ account_id: user.connect_id });
    setDashboardUrl(data?.url);
  };

  const handleStripeDashboard = () => {
    window.location.replace(dashboardUrl);
  };

  useEffect(() => {
    if (user) getDashboardLink();
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="paiement-page animate__animated animate__fadeIn">
      <div className="page-title">
        <span>MES PAIEMENTS</span>
        {user.role !== "customer" && (
          <button
            className="stripe-dashboard-btn"
            onClick={handleStripeDashboard}
          >
            Stripe Dashboard
          </button>
        )}
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Fonds disponibles" key="1">
          <Alert
            message="Les fonds demandés sont virés sur votre compte renseigné dans vos préférences de virement. L'argent apparaitra sur votre compte bancaire ou mobile sous 1 à 7 jours ouvrés."
            type="info"
          />
          <br />
          {payment.data && payment.isSuccess && (
            <>
              <table className="table">
                <thead>
                  <th>J'ai déjà reçu</th>
                  <th>Je vais recevoir</th>
                  <th>J'ai gagné</th>
                </thead>
                <tr>
                  <td> {payment.data.received} €</td>
                  <td> {payment.data.toBePaid} €</td>
                  <td> {payment.data.total} €</td>
                </tr>
              </table>
              <Button
                type="primary"
                loading={isLoading}
                className="btn-sm"
                onClick={() => setIsPayment(true)}
              >
                Faire une demande de paiement anticipé
              </Button>
            </>
          )}
        </TabPane>
        <TabPane tab="Methode de virement" key="2">
          <Alert
            message="Veuillez renseigner votre IBAN pour recevoir vos fonds par virement bancaire. Une fois le virement demandé, il faut compter 1 à 7 jours ouvrés pour que l'argent soit visible sur votre compte."
            type="info"
          />
          <br />
          <div>
            {payment.data && payment.isSuccess && (
              <>
                <Form
                  name="basic"
                  onFinish={handleUpdate}
                  autoComplete="off"
                  form={form}
                >
                  <p style={{ letterSpacing: "3px", fontSize: "20px" }}>
                    {payment.data.numero}
                  </p>
                  <Row gutter={24}>
                    <Col lg={12}>
                      <Form.Item
                        name="numero"
                        rules={[{ required: true, message: "Champs requis!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          loading={isLoading}
                          htmlType="submit"
                        >
                          Enregister
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </div>
        </TabPane>
      </Tabs>

      <Modal
        className="disponibilite"
        centered
        open={isPayment}
        footer={null}
        closable={true}
        onCancel={() => setIsPayment(false)}
      >
        <div className="modal-body">
          <img src="/assets/img/icons8-warning.png" width="70" alt="img" />
          <p>
            En cas de besoin urgent, vous pouvez formuler à nos équipes une
            demande de versement anticipé de vos dernières recettes. Veuillez
            cependant noter que ces demandes doivent rester exceptionnelles. En
            cas d'abus, Kimekoif se réserve le droit de ne pas donner suite à
            votre demande.
          </p>
          <a
            href="mailto:kimekoif@gmail.com"
            target="_blank"
            without
            rel="noreferrer"
            className="btn btn-primary"
            onClick={() => handleSendMail()}
          >
            Poursuivre
          </a>
        </div>
      </Modal>
    </div>
  );
}
export default PaiementPage;
