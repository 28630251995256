import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";

export const commandeCreateAsync = createAsyncThunk(
    "commande/commandeCreateAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/commande/`,
                data: payload
            });
            localStorage.removeItem('cartItems');
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

export const commandeFetchAsync = createAsyncThunk(
    "commande/commandeFetchAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/user/commande/`,
                data: payload
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const commandeFetchAllAsync = createAsyncThunk(
    "commande/commandeFetchAllAsync",
    async () => {
        try {
            const response = await axios.get(baseURL + "api/commandes");
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const commandeFetchOneAsync = createAsyncThunk(
    "commande/commandeFetchOneAsync",
    async (payload) => {
        try {
            const response = await axios.get(baseURL + `api/commande/${payload.id}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const commandeUpdateAsync = createAsyncThunk(
    "commande/commandeUpdateAsync",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.put(baseURL + "api/commande/" + payload.id, { "payment_status": payload.payment_status });
            dispatch(commandeFetchAllAsync());
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

const initialState = {
    error: "",
    status: "idle",
    loading: false,
    commandes: []
};

const commandeSlice = createSlice({
    name: "commande",
    initialState,
    reducers: {
        commandeClearState: (state) => {
            state.status = 'idle';
            state.loading = false;
            state.error = "";
            state.commandes = [];
        },
    },
    extraReducers: {
        // Create
        [commandeCreateAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [commandeCreateAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.message = action.payload;
        },
        [commandeCreateAsync.rejected]: (state, action) => {
            state.loading = false
            state.status = 'failed';
            state.error = action.payload;
        },

        // Fetch By User
        [commandeFetchAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [commandeFetchAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.commandes = action.payload;
        },

        [commandeFetchAsync.rejected]: (state, action) => {
            state.loading = false
            state.status = 'failed';
            state.error = action.payload;
        },

        // Fetch All
        [commandeFetchAllAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [commandeFetchAllAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.loading = false
            state.commandes = payload;
        },
        [commandeFetchAllAsync.rejected]: (state, { payload }) => {
            state.loading = false
            state.status = 'failed';
            state.error = payload;
        },

        // Fetch One
        [commandeFetchOneAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [commandeFetchOneAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.loading = false
            state.commande = payload;
        },
        [commandeFetchOneAsync.rejected]: (state, { payload }) => {
            state.loading = false
            state.status = 'failed';
            state.error = payload;
        },

        // Update
        [commandeUpdateAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [commandeUpdateAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.loading = false
            state.message = payload;
        },
        [commandeUpdateAsync.rejected]: (state, { payload }) => {
            state.loading = false
            state.status = 'failed';
            state.error = payload;
        },
    },
});

export const { commandeClearState } = commandeSlice.actions;
export default commandeSlice.reducer;
