import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Cart"],
  endpoints: (builder) => ({
    cartFetch: builder.query({
      query: () => `/api/v1/cart/`,
      providesTags: ["Cart"],
    }),
    cartFetchOne: builder.query({
      query: (id) => `/api/v1/cart/${id}`,
      providesTags: ["Cart"],
    }),

    cartFetchProduct: builder.query({
      query: (id) => `/api/v1/cart/product/${id}`,
      providesTags: ["Cart"],
    }),

    cartCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/cart/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Cart"],
    }),

    cartUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/cart/${payload.id}`,
        method: "PUT",
        body: payload.data,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useCartFetchQuery,
  useCartFetchProductQuery,
  useCartFetchOneQuery,
  useCartCreateMutation,
  useCartUpdateMutation,
} = cartApi;
