export const getUnreadMessages = async (conversation, loggedInUser) => {
  const participant = await conversation.getParticipantByIdentity(
    loggedInUser.email
  );
  if (
    conversation.lastMessage &&
    conversation.lastMessage?.index !== participant.lastReadMessageIndex
  ) {
    return participant.lastReadMessageIndex === null
      ? conversation.lastMessage?.index + 1
      : conversation.lastMessage?.index - participant.lastReadMessageIndex;
  } else {
    return 0;
  }
};

export const sortItems = async (conversations, loggedInUser) => {
  const sortedConversations = await Promise.all(
    conversations.map(async (conversation) => {
      conversation["unreadMessagesCount"] = await getUnreadMessages(
        conversation,
        loggedInUser
      );
      return conversation;
    })
  );
  sortedConversations.sort((c1, c2) => {
    const lastMessageTimeA = c1.lastMessage ? new Date(c1.lastMessage.dateCreated) : 0;
    const lastMessageTimeB = c2.lastMessage ? new Date(c2.lastMessage.dateCreated) : 0;

    if (lastMessageTimeA !== lastMessageTimeB) {
      return lastMessageTimeB - lastMessageTimeA;
    }

    return 0;
  });

  return sortedConversations;
};

export const setConversationUnreadMessage = async (
  activeConversation,
  loggedInUser,
  setUnreadIndex
) => {
  const participant = await activeConversation.getParticipantByIdentity(
    loggedInUser.email
  );
  if (participant.lastReadMessageIndex === null) {
    setUnreadIndex(0);
  } else if (
    participant.lastReadMessageIndex !== activeConversation.lastMessage?.index
  ) {
    setUnreadIndex(participant.lastReadMessageIndex + 1);
  } else {
    setUnreadIndex(null);
  }
};
