import * as React from "react";
import Banner from "../account/components/banner";

function CguPage() {
  return (
    <div className="cgu-page animate__animated animate__fadeIn">
      <Banner title="CGU" />
      <div className="container">
        <div className="content">
          <p>
            KIMEKOIF est une plateforme web, accessible à l’adresse
            www.kimekoif.com (le « Site ») exploitée par Strong And Bold
            Extensions, société à responsabilité limitée au capital de 100
            euros, ayant son siège social situé 93B rue Saint Auber à Arras
            (62000), immatriculée au registre du commerce et des sociétés de
            Paris sous le numéro d’identification 818 340 754 et dont le numéro
            de TVA intracommunautaire est FR46852235480 (la « Société »). Ladite
            plateforme est dédiée à la mise en relation de Clients avec des
            Prestataires proposant des prestations de coiffure afro pour un
            public féminin (la ou les « Prestation(s) »).
          </p>
          <br />
          <p>
            L’objet des présentes Conditions Générales d’Utilisation (les «
            Conditions » ou prises dans leur ensemble, le « Contrat ») est de
            définir les termes et les conditions régissant les relations entre
            les Utilisateurs du Site et la Société. Ces Conditions constituent
            un accord contractuel pour une durée indéterminée à compter de
            l’acceptation par l’Utilisateur du présent Contrat.
            <br />
            <br />
            L’Utilisateur s’engage lors de chacune de ses visites du Site à
            respecter l’ensemble des présentes Conditions sans aucune réserve.
            En conséquence, l’Utilisateur reconnait avoir pris connaissance des
            Conditions et accepte d’être lié par les présentes dispositions. Si
            l’Utilisateur accède au Site pour le compte d’une entreprise ou de
            toute autre entité juridique, il est néanmoins personnellement lié
            par le présent Contrat.
          </p>
          <p>
            Article préliminaire – Définitions et règles d’interprétation
            <br />
            0.1. Définitions
            <br />
            Sauf s’ils sont définis dans d’autres sections du présent Contrat,
            les termes et expressions commençant par une majuscule utilisés
            ci-après ont la signification suivante :
            <br />
            <br />
            - « Client » désigne tout Utilisateur répondant aux conditions
            d’éligibilité posées à l’article 1.1 et souhaitant souscrire à une
            Prestation sur le Site ;<br />
            - « Condition(s) » a le sens qui lui est attribué dans l’exposé
            préalable ;<br />
            - « Contrat » a le sens qui lui est attribué dans l’exposé préalable
            ;<br />
            - « Prestataire » désigne tout Utilisateur professionnel, au sens du
            droit de la consommation, proposant des Prestations sur le Site ;
            <br />
            - « Prestation(s) » désigne la ou les prestations proposés par le
            Prestataire ;<br />
          </p>
          <br />
          <br />
          - « Service(s) » désigne le ou les services proposés par la Société
          sur le Site tels que visés à l’article 2 ; <br />
          <br />
          - « Site » a le sens qui lui est attribué dans l’exposé préalable ;
          <br />
          <br />
          - « Société » a le sens qui lui est attribué dans l’exposé préalable ;
          et
          <br />
          <br />
          - « Utilisateur » désigne toute personne physique ou morale utilisant
          le Site, en ce compris notamment tout Prestataire ou Client.
          <br />
          <br />
          0.2. Règles d’interprétation
          <br />
          <br />
          Les règles exposées ci-après s’appliquent à l’interprétation du
          présent Contrat :<br />
          <br />
          (a) les titres des articles et des annexes sont inclus par commodité
          et n’affectent en aucun cas l’interprétation de l’une quelconque des
          stipulations du présent Contrat ;<br />
          <br />
          (b) l’usage des expressions « y compris », « en particulier », ou «
          notamment » implique que l’énumération qui les suit n’est pas
          limitative ou exhaustive ;<br />
          <br />
          (c) le terme « ou » n’est pas exclusif ;<br />
          <br />
          (d) la définition attribuée à un terme singulier s’applique également
          à ce terme lorsqu’il est employé au pluriel et vice versa. Il en est
          de même concernant l’utilisation du genre masculin ou féminin ;<br />
          <br />
          (e) le décompte des délais exprimés en jours, en mois ou en années
          doit être fait conformément aux dispositions des articles 640 à 642 du
          code de procédure civile ;<br />
          <br />
          (f) toute référence à une partie inclut une référence à ses héritiers,
          successeurs et ayants droit ; et
          <br />
          <br />
          (g) toute référence à un document s’entend de ce document tel qu’il
          pourrait être modifié ou remplacé (autrement qu’en violation des
          stipulations du présent Contrat).
          <br />
          <br />
          Article 1 – Inscription au Site
          <br />
          <br />
          1.1 Eligibilité au Service
          <br />
          Pour être éligible au Service, l’Utilisateur doit être une personne
          physique ayant atteint l’âge de 18 ans et disposant de sa pleine
          capacité juridique. En outre, l’Utilisateur devra utiliser les
          Services en tant que consommateur uniquement au sens du droit de la
          consommation à l’exclusion de toute utilisation professionnelle.
          <br />
          <br />
          1.2. Création d’un compte
          <br />
          La création d’un compte membre sur le Site est un préalable
          obligatoire à la souscription à une Prestation sur le Site ou à la
          publication d’une Prestation. Elle suppose de compléter le formulaire
          d’inscription, disponible sur le Site, en fournissant des informations
          exactes, à jour et complètes. Ces informations devront faire, par la
          suite, l’objet de mises à jour régulières, par l’Utilisateur afin de
          préserver leur exactitude.
          <br />
          L’Utilisateur devra choisir un nom d’utilisateur constitué d’un mail
          valide et un mot de passe et fournir obligatoirement les informations
          suivantes : son nom et prénom, son adresse mail et l’adresse de son
          domicile.
          <br />
          Les codes d’accès au compte d’Utilisateur sont strictement
          confidentiels. En cas d’utilisation non autorisée de son compte ou de
          toute atteinte à la confidentialité et à la sécurité de ses moyens
          d’identification, l’Utilisateur devra, sans délais, informer la
          Société. Chaque Utilisateur s’engage à ne créer qu’un seul compte
          correspondant à son profil.
          <br />
          <br />
          Article 2 – Fonctionnement du Site
          <br />
          La création d’un compte permet d’accéder notamment aux services
          suivants (le ou les « Service(s) ») :<br />
          <br />
          constitution et mise à jour d’un profil d’Utilisateur ; et la
          souscription aux Prestations.
          <br />
          <br />
          2.1. Souscription aux Prestations
          <br />
          Toute souscription par un Utilisateur suppose l’acceptation desdites
          conditions générales.
          <br />
          <br />
          Sur le Site, un service de messagerie instantanée est mis à
          disposition du Client et du Prestataire afin de leur permettre
          d’échanger sur les Prestations à réaliser. Le Client et le Prestataire
          sont informés que la Société a un accès au contenu de cette messagerie
          et pourra éventuellement se servir à tout moment, sans information
          préalable des Utilisateurs, de tout ou partie de leurs échanges dans
          le cadre notamment de la résolution de toute contestation ou litige.
          Par suite, la Société ne garantit nullement la confidentialité des
          échanges.
          <br />
          <br />
          La souscription à une Prestation par un Client se fait impérativement
          et directement sur le Site. Le Client s’interdit de de souscrire à
          tout service d’un Prestataire ou procéder à tout paiement en dehors du
          Site sans l’accord préalable et dénué de toute ambiguïté de la
          Société.
          <br />
          <br />
          Elle suppose la conclusion d’un contrat de prestation de services
          conclu entre le Prestataire et le Client régit par les conditions
          générales de service. La Société n’assure qu’un rôle technique dans le
          cadre de la souscription du Client.
          <br />
          <br />
          La Société n’assure nullement au Client la qualité ou la légalité des
          Prestations, ni le sérieux des Prestataires.
          <br />
          <br />
          La Société n’offre, en outre, aucune assurance ou garantie dans le
          cadre de la souscription et de l’utilisation des Prestations par
          l’Utilisateur. En conséquence, il est recommandé à l’Utilisateur de
          s’informer auprès du Prestataire, avant toute souscription, sur les
          conditions d’assurance et les garanties qu’il fournit dans le cadre de
          la souscription et l’exécution de toute Prestation.
          <br />
          <br />
          2.2. Modalités de classement des Prestataires
          <br />
          Sur le Site, l’Utilisateur a la possibilité de consulter les
          Prestations et leur contenu. Les Prestataires sont classés en fonction
          des filtres disponibles sur le Site et des choix exprimés par
          l’Utilisateur sur le Site.
          <br />
          <br />
          Un filtre de classement repose sur la localisation, le type ainsi que
          la date souhaitée d’exécution de la Prestation recherchée ou le nom du
          Prestataire.
          <br />
          <br />
          2.3. Existence d’une relation contractuelles entre la Société et les
          Prestataires
          <br />
          Les Prestataires ont une relation contractuelle, établies par des
          conditions générales de publication des Prestations avec la Société
          mais n’entretiennent pas de liens capitalistiques. Cette relation
          contractuelle prévoit une rémunération de la Société en cas de
          souscription à une Prestation sur le Site.
          <br />
          <br />
          Le classement et la présentation des Prestataires sont indépendants
          des modalités et conditions de rémunération de la Société. La
          rémunération de la Société n’impacte donc nullement le classement des
          Prestataires.
          <br />
          <br />
          Les Prestataires peuvent-être déréférencés du Site en cas de violation
          de tout ou partie des présentes Conditions Générales, des conditions
          générales de service ou de sérieuses contestations portées un ou
          plusieurs Utilisateurs.
          <br />
          <br />
          2.4. Détails des éléments constitutifs du prix et garanties
          <br />
          Sur le Site sont affichés, le prix proposé par le Prestataire et
          l’objet des Prestations. La Société n’opère aucun contrôle des prix
          indiqués par les Prestataires ou sur la qualité de leurs prestations.
          <br />
          <br />
          La Société n’apporte aucune garantie aux Utilisateurs sur la qualité
          des Prestations proposées.
          <br />
          <br />
          Il est donc recommandé à l’Utilisateur de faire preuve d’une vigilance
          accrue dans le cadre de l’éventuelle conclusion définitive de sa
          transaction auprès du Prestataire.
          <br />
          <br />
          2.5. Modalités techniques de souscription
          <br />
          Dans l’hypothèse où un Client souhaiterait souscrire à une Prestation,
          le paiement du tarif indiqué sur le Site est fait par carte bancaire
          ou par virement. La Société utilise le service de paiement sécurisé
          fourni par la société Stripe dont les conditions générales figurent
          sous le lien suivant : https://stripe.com/fr/legal .<br />
          <br />
          La Société n’a pas d’accès aux données bancaires confidentielles (le
          numéro de carte bancaire à 16 chiffres ainsi que la date
          d&#39;expiration, le code CVV) lesquelles sont directement transmises
          cryptées sur le serveur de la banque.
          <br />
          <br />
          Le Client est engagé par sa souscription dès qu’il clique sur « Payer
          ».
          <br />
          <br />
          Le destinataire du paiement est le Prestataire déduction faite des
          frais de services de la Société ainsi que des éventuels frais
          bancaires.
          <br />
          <br />
          Les systèmes d&#39;enregistrement automatique sont considérés comme
          valant preuve de la nature, du contenu et de la date de la
          souscription.
          <br />
          <br />
          Le Client recevra la confirmation de son paiement par l’envoi d’un
          message à l’adresse courriel que ce dernier aura communiquée.
          <br />
          <br />
          La Société se réserve le droit de refuser ou d&#39;annuler toute
          souscription d&#39;un Client notamment en cas d&#39;insolvabilité de
          ce dernier ou dans l&#39;hypothèse d&#39;un défaut de paiement de la
          souscription concernée ou d&#39;un litige relatif au paiement
          d&#39;une commande antérieure.
          <br />
          <br />
          Les mentions indiquées par le Client, lors de la saisie des
          informations inhérentes à sa souscription engagent celui-ci. La
          Société ne saurait être tenue responsable des erreurs commises par les
          Clients dans le libellé des champs à compléter pour réaliser une
          commande.
          <br />
          <br />
          2.6. Obligations fiscales et sociales
          <br />
          <br />
          Conformément aux dispositions de l’article 242 bis du Code général des
          impôts, la Société rappelle aux Utilisateurs les informations
          suivantes :<br />
          <br />
          Les revenus perçus par les Prestataires par l’intermédiaire du Site
          doivent être déclarés annuellement à l’administration fiscale avec
          leurs autres revenus. A cet égard, la Société communique à ses
          Utilisateurs, en janvier de chaque année, ainsi qu’à leur demande, un
          document récapitulant le montant brut des transactions dont il a
          connaissance et qu&#39;ils ont perçu, par son intermédiaire, au cours
          de l&#39;année précédente.
          <br />
          <br />
          Pour plus d’informations sur leurs obligations fiscales et sociales,
          les Utilisateurs peuvent consulter le site internet suivant :<br />
          <br />
          https://www.impots.gouv.fr/portail/node/10841
          <br />
          La Société se réserve le droit de suspendre le compte de tout
          Utilisateur dont la conformité avec ses obligations civiles, fiscales
          et sociales serait inexacte ou insuffisante, et ce jusqu’à sa mise en
          conformité avec ces mêmes obligations dans un délai de 24 heures. À
          défaut, la Société se réserve le droit de supprimer le compte dudit
          Utilisateur.
          <br />
          <br />
          2.7. Avis et commentaires
          <br />
          Le Client a la possibilité de publier sur le Site des commentaires,
          témoignages et indices de satisfaction sur les Prestations et la
          qualité de service du Prestataire. La Société ne saurait être
          responsable au titre de la publication de ces commentaires et avis sur
          le Site. Le Client s’engage à ne pas saisir un contenu susceptible de
          porter atteinte à l’ordre public ou aux bonnes mœurs, de provoquer des
          protestations de tiers, ou encore de contrevenir aux dispositions
          légales en vigueur. La Société se réserve le droit de modérer ou de ne
          pas publier discrétionnairement tout avis ou commentaire du Client.
          Article 3 – Utilisation du Site
          <br />
          <br />
          3.1. Droit d’accès au Site
          <br />
          La Société, selon les présentes Conditions, accorde aux Utilisateurs
          un droit d’accès limité révocable, non exclusif, non cessible aux
          Services et au Site à titre strictement personnel. Toute utilisation
          contraire du Site à sa finalité est strictement interdite et constitue
          un manquement aux présentes dispositions.
          <br />
          <br />
          L’utilisation du Site requiert une connexion et un navigateur
          internet. Afin de garantir un bon fonctionnement du Site, il est
          précisé que le Site est optimisé pour :<br />
          <br />
          - une résolution d’écran de 1200x768px ;<br />
          <br />
          - les dernières versions des navigateurs Internet Explorer, Chrome,
          Firefox et Safari.
          <br />
          <br />
          Tous matériels et logiciels nécessaires à l’accès au Site et à
          l’utilisation des Services restent exclusivement à la charge de
          l’Utilisateur.
          <br />
          <br />
          La Société se réserve le droit de suspendre, modifier, remplacer,
          refuser un accès ou supprimer discrétionnairement des comptes
          d’Utilisateurs.
          <br />
          <br />
          3.2. Obligations des Utilisateurs
          <br />
          Les Utilisateurs s’interdisent :<br />
          <br />
          1. de transmettre, publier, distribuer, enregistrer ou détruire tout
          matériel, en particulier les contenus du Site, en violation des lois
          ou règlementations en vigueur concernant la collecte, le traitement ou
          le transfert d&#39;informations personnelles ;<br />
          <br />
          2. de créer des profils fictifs ;<br />
          <br />
          3. de fournir des informations inexactes dans le formulaire ou de ne
          pas les mettre à jour régulièrement ;<br />
          <br />
          4. de diffuser des données, informations, ou contenus à caractère
          diffamatoire, injurieux, obscène, offensant, violent ou incitant à la
          violence, ou à caractère politique, raciste ou xénophobe et de manière
          générale tout contenu qui serait contraire aux lois et règlements en
          vigueur ou aux bonnes mœurs ;<br />
          <br />
          5. de référencer ou créer des liens vers tout contenu ou information
          disponible depuis les sites de la Société, sauf accord exprès, écrit
          et préalable de de la Société ;<br />
          <br />
          6. d’obtenir d’autres Utilisateurs des mots de passe ou des données
          personnelles d’identification ;<br />
          <br />
          7. d’utiliser des informations, contenus ou toutes données présentes
          sur le Site afin de proposer un service considéré, à l’entière
          discrétion de la Société comme concurrentiel à la Société ;<br />
          <br />
          8. de vendre, échanger ou monnayer des informations, contenus ou
          toutes données présentes sur le Site ou service proposé par le Site,
          sans l’accord expresse et écrit de la Société ;<br />
          <br />
          9. de pratiquer de l’ingénierie inversée (Reverse Engineering),
          décompiler, désassembler, déchiffrer ou autrement tenter d’obtenir le
          code source en relation avec toute propriété intellectuelle
          sous-jacente utilisée pour fournir tout ou partie des Services ;<br />
          <br />
          10. d’utiliser des logiciels ou appareils manuels ou automates, robots
          de codage ou autres moyens pour accéder, explorer, extraire ou indexer
          toute page du Site ;<br />
          <br />
          11. de mettre en danger ou essayer de mettre en danger la sécurité
          d&#39;un site web de la Société. Cela comprend les tentatives de
          contrôler, scanner ou tester la vulnérabilité d’un système ou réseau
          ou de violer des mesures de sécurité ou d’authentification sans une
          autorisation préalable expresse ;<br />
          <br />
          12. de contrefaire ou d’utiliser les produits, les logos, les marques
          ou tout autre élément protégé par les droits de propriété
          intellectuelle de la Société ;<br />
          <br />
          13. de simuler l’apparence ou le fonctionnement du Site, en procédant
          par exemple à un effet miroir ;<br />
          <br />
          14. de perturber ou troubler, directement ou indirectement, la Société
          ou les Services, ou imposer une charge disproportionnée sur
          l’infrastructure du Site ou tenter de transmettre ou d’activer des
          virus informatique via ou sur le Site.
          <br />
          <br />
          Les violations de la sécurité du système ou du réseau peuvent conduire
          à des poursuites civiles et pénales. La Société vérifie l’absence de
          telle violation et peut faire appel aux autorités judiciaires pour
          poursuivre, le cas échéant, des Utilisateurs ayant participé à de
          telles violations.
          <br />
          <br />
          Les Utilisateurs s’engagent à utiliser le Site de manière loyale,
          conformément à sa finalité et aux dispositions légales,
          règlementaires, aux présentes Conditions et aux usages en vigueur.
          <br />
          <br />
          Article 4 – Utilisation du contenu du Site
          <br />
          L’ensemble du contenu du Site, plateformes et logiciels mis à
          disposition, notamment les designs, textes, graphiques, images,
          vidéos, informations, logos, icônes-boutons, logiciels, fichiers audio
          et autres appartient à la Société ou ses partenaires.
          <br />
          <br />
          Toute représentation et/ou reproduction et/ou exploitation partielle
          ou totale des contenus et services proposés par la Société, par
          quelque procédé que ce soit, sans l&#39;autorisation préalable et
          écrite de la Société, est strictement interdite et serait susceptible
          de donner lieu à des poursuites judiciaires.
          <br />
          <br />
          Article 5 – Données à caractère personnel
          <br />
          5.1. Données collectées
          <br />
          <br />
          Afin de permettre à tout Utilisateur de profiter pleinement des
          Services et fonctionnalités du Site, la Société collecte différentes
          données. Il est rappelé qu’en s’inscrivant sur le Site, l’Utilisateur
          accepte expressément que ces données soient collectées et cela quel
          que soit le pays à partir duquel il se connecte.
          <br />
          <br />
          Ces données sont collectées à différents moments et selon diverses
          méthodes :<br />
          <br />
          - Lors de l’inscription sur le Site : nom, prénom, adresse mail,
          numéro de téléphone, pseudo, ville, et mot de passe ;<br />
          <br />
          - Lors de toute souscription à une Prestation, les informations
          suivantes doivent être délivrées en sus : adresse de facturation et
          numéro de téléphone, adresse d’exécution des Prestations. Les données
          transmises à la Société, notamment, lors de l’inscription de
          l’Utilisateur sur le Site sont communiquées, sans information
          préalable de ce dernier, au Prestataire en vue de la parfaite
          exécution des Prestations ;<br />
          <br />
          - Fichiers journaux et adresse de protocole internet (IP) : La Société
          reçoit à chaque connexion sur le Site, le lien du site depuis lequel
          l’Utilisateur et arrivé et celui vers lequel il se dirige lorsqu’il
          quitte le Site. La Société reçoit également l’adresse de protocole
          internet (IP) de l’Utilisateur ou encore certaines informations
          relatives au système d’exploitation de son ordinateur ou son
          navigateur internet ;<br />
          <br />
          - Cookies : La Société utilise des fichiers cookies qui peuvent être
          définis comme des fichiers textes susceptibles d’être enregistrés dans
          un terminal lors de la consultation d’un service en ligne avec un
          logiciel de navigation. Un fichier cookie permet à son émetteur,
          pendant sa durée de validité n’excédant pas 13 mois, de reconnaître le
          terminal concerné à chaque fois que ce terminal accède à un contenu
          numérique comportant des cookies du même émetteur. Il est néanmoins
          possible de désactiver l’utilisation des cookies en modifiant les
          préférences de l’Utilisateur dans son navigateur internet. Dans ce
          cas, certaines fonctionnalités du Site sont susceptibles de ne plus
          fonctionner.
          <br />
          <br />
          5.2. Conservation des données collectées
          <br />
          Les données à caractère personnel sont stockées par la Société sur ses
          serveurs, en vue de leur traitement dans le cadre de l’utilisation des
          Services. Elles sont conservées aussi
          <br />
          <br />
          longtemps que nécessaire pour l’apport des Services et fonctions
          offerts par le Site. En conséquence, tant qu’un Utilisateur disposera
          d’un compte membre, les données collectées seront conservées. En cas
          de désinscription du Site, les données collectées seront effacées par
          la Société et uniquement conservées à titre d’archive aux fins
          d’établissement de la preuve d’un droit ou d’un contrat qui peuvent
          être archivées conformément aux dispositions du code de commerce
          relatives à la durée de conservation des livres et documents créés à
          l’occasion des activités commerciales.
          <br />
          <br />
          L’Utilisateur reste toujours propriétaire des informations le
          concernant qu’il transmet à la Société. Il dispose, conformément à la
          loi n° 78-17 du 6 janvier 1978 modifiée par l’ordonnance n° 2018-1125
          du 12 décembre 2018, d’un droit d’accès, de rectification et de
          suppression des données à caractère personnel le concernant, ainsi que
          du droit de s’opposer à la communication de ces données à des tiers
          pour de justes motifs. L’Utilisateur pourra exercer ses droits en
          écrivant à l’adresse électronique suivante : contact@kimekoif.com ou à
          l’adresse postale suivante : Strong And Bold Extensions – 10, rue
          Penthièvre à Paris (75008).
          <br />
          <br />
          Une réponse à la requête de l’Utilisateur lui sera adressée dans un
          délai de 30 jours. 5.3. Finalités de la collecte des données
          collectées Les données personnelles sont collectées auprès des
          Utilisateur afin (i) de permettre à l’Utilisateur de profiter
          pleinement des Services et des fonctions proposés par le Site, (ii) de
          prévenir toute fraude et (iii) à des fins statistiques.
          <br />
          <br />
          Ces données peuvent être communiquées par la Société, au Prestataire
          concerné, à tout tiers chargés de l’exécution, du traitement et de la
          gestion des Services, en ce compris Stripe.
          <br />
          <br />
          Toutefois, dans différentes hypothèses, la Société sera susceptible de
          divulguer ou partager les données personnelles d’un Utilisateur à tous
          autres tiers, parmi lesquelles : - avec le consentement de
          l’Utilisateur ; - afin de se conformer à la loi, la règlementation en
          vigueur, à toute procédure judiciaire, aux décisions de justice ou à
          tout autre cas de divulgation obligatoire ; ou - pour protéger les
          droits, la propriété ou la sécurité du Site, de ses membres ou du
          public.
          <br />
          <br />
          Article 6 – Responsabilité
          <br />
          6.1. L’utilisation du Site
          <br />
          <br />
          Le rôle de la Société se limite à la mise à disposition technique des
          Services aux Utilisateurs. L’Utilisateur est seul responsable des
          conséquences de l’utilisation du Site.
          <br />
          <br />
          L’Utilisateur est tenu de s’assurer que l’utilisation qu’il fait du
          Site est conforme aux dispositions légales, règlementaires ainsi
          qu’aux Conditions Générales. La Société ne donne aucune garantie à
          l’Utilisateur quant à la conformité de l’utilisation du Site, qu’il
          fait ou qu’il projette de faire, aux dispositions légales et
          règlementaires nationales ou internationales. L’Utilisateur est averti
          des aléas techniques et des interruptions d’accès pouvant survenir sur
          le Site. En conséquence, la Société ne pourra être tenue responsable
          des indisponibilités ou ralentissements des Services.
          <br />
          <br />
          L’ensemble des informations, les conseils et guides et toute autre
          donnée affichée sur le Site n&#39;ont pas vocation à constituer des
          conseils sur la foi desquels une décision pourrait être prise par
          l&#39;Utilisateur.
          <br />
          <br />
          Par suite, l’Utilisateur ne doit en aucun cas considérer comme
          acquises lesdites informations et données du Site mais devra vérifier
          indépendamment par lui-même toutes ces informations et données. La
          Société ne saurait donc être responsable des conséquences de
          l’utilisation des données et informations affichées sur le Site.
          <br />
          <br />
          6.2. Conditions des transactions réalisées via le Site
          <br />
          La Société n’assure qu’un rôle technique dans la mise en relation des
          Prestataires et des Clients. La Société ne garantit nullement la
          fiabilité et le sérieux des Prestataires. En conséquence, les
          Utilisateurs sont tenus d’organiser entre eux les conditions notamment
          juridiques et financières de leurs relations dont ils seront les seuls
          responsables. Ils devront alors s’assurer du respect des conditions
          légales et règlementaires.
          <br />
          <br />
          En outre, la Société ne saurait en aucun cas être tenue responsable
          notamment :<br />
          1. des conséquences des rencontres virtuelles ou réelles entre
          Utilisateurs suite à l’utilisation du Site ;<br />
          <br />
          2. des conditions de la réalisation des Prestations ;<br />
          <br />
          3. de la qualité, légalité et conformité des Prestations ;<br />
          <br />
          4. de la violation par le Prestataire ou le Client de ses obligations
          légales, règlementaires ou encore contractuelles ;<br />
          <br />
          5. des dommages de toutes natures subis par le Client ou de tout
          tiers, y compris corporels, suite à la souscription à une Prestation ;
          et
          <br />
          <br />
          6. de tout préjudice consécutif à une faute de l’Utilisateur ou à un
          évènement imputable à un tiers ou à un cas de force majeure.
          <br />
          <br />
          Pour l’ensemble des Services proposés sur le Site, la Société ne
          saurait en aucun cas avoir la qualité d’exécutant des Prestations ou
          de garant des Prestataires, le rôle de la Société étant limité à la
          facilitation technique des rencontres entre les Clients et les
          Prestataires. Plus généralement, la Société reste tierce aux actes
          conclus par les Utilisateurs via le Site, et ne saurait être
          responsable des conséquences de tels actes à quelque titre que ce
          soit. 6.3. Les données
          <br />
          <br />
          La Société n’a pas d’obligation générale de surveillance des données
          et du contenu fournis par les Prestataires, Clients et autres
          Utilisateurs, ni d’obligation de suppression d’un contenu qui ne
          paraitrait pas manifestement illicite, nonobstant son signalement.
          <br />
          <br />
          L’Utilisateur s’engage à ne pas saisir un contenu susceptible de
          porter atteinte à l’ordre public ou aux bonnes mœurs, de provoquer des
          protestations de tiers, ou encore de contrevenir aux dispositions
          légales en vigueur.
          <br />
          <br />
          En conséquence, il est expressément convenu qu’au cas où la Société
          serait mise en cause, à quelque titre que ce soit, dans quelque pays
          que ce soit, par un tiers sur le fondement notamment d’un droit de la
          propriété industrielle et/ou intellectuelle relatif à un élément
          fournis par un Utilisateur, cet Utilisateur s’engage à garantir
          entièrement la Société des conséquences économiques et financières
          directes et/ou indirectes (y compris les frais de procédure et de
          défense) qui découleraient de ces revendications.
          <br />
          <br />
          Il est rappelé que les données publiées par les Utilisateurs et les
          informations partagées par ces derniers peuvent être captées et
          exploitées par d’autres Utilisateurs ou des tiers. En ce sens, la
          Société ne garantit pas le respect de la propriété de ces données, il
          incombe à l’Utilisateur de prendre l’ensemble des dispositions
          nécessaires afin que soit préservée la propriété de ses données.
          <br />
          <br />
          L’Utilisateur devra s’assurer de l’envoi des données au Site et ne
          pourra reprocher à la Société sur un quelque fondement et à quelque
          titre que ce soit la non-réception ou la perte des données transmises.
          L’Utilisateur veillera alors à conserver une sauvegarde des données
          transmises. En outre, une fois le compte membre d’un Utilisateur
          clôturé, cet Utilisateur est informé par les présentes Conditions que
          les données transmises ainsi que l’ensemble des données générées
          seront détruites par la Société.
          <br />
          <br />
          6.4. Dispositions générales
          <br />
          En tout état de cause, la Société ne saurait en aucune circonstance
          être responsable au titre des pertes ou dommages indirects ou
          imprévisibles des Utilisateurs ou des tiers, ce qui inclut notamment
          tout gain manqué, inexactitude ou corruption de fichiers ou données ou
          perte de chance lié à quelque titre et sur quelque fondement que ce
          soit au présent Contrat.
          <br />
          <br />
          La Société ne saurait être responsable du retard ou de l’inexécution
          du présent Contrat justifié par un cas de force majeure, telle qu’elle
          est définie par la jurisprudence des cours et tribunaux français.
          <br />
          <br />
          Article 7 – Résiliation
          <br />
          L’Utilisateur peut résilier à tout moment le présent Contrat, en se
          rendant sur le Site et en y suivant la procédure prévue à cet effet.
          Il est rappelé qu’une fois le compte membre d’un Utilisateur clôturé,
          les données transmises ainsi que l’ensemble des données générées
          seront détruites par la Société.
          <br />
          <br />
          La Société se réserve le droit de restreindre, suspendre ou supprimer,
          sans préavis ni remboursement, le compte de tout Utilisateur qui
          utilise de façon abusive ou inappropriée les Services. L’appréciation
          du comportement de l’Utilisateur est réservée à l’entière discrétion
          de la Société.
          <br />
          <br />
          Article 8 – Convention de preuve
          <br />
          Les systèmes et fichiers informatiques font foi dans les rapports
          entre la Société et l’Utilisateur.
          <br />
          <br />
          Ainsi, la Société pourra valablement produire dans le cadre de toute
          procédure, aux fins de preuve les données, fichiers, programmes,
          enregistrements ou autres éléments, reçus, émis ou conservés au moyen
          des systèmes informatiques exploités par la Société, sur tous supports
          numériques ou analogiques, et s’en prévaloir sauf erreur manifeste.
          <br />
          <br />
          Article 9 – Dispositions diverses
          <br />
          9.1. Indivisibilité du Contrat
          <br />
          Le fait que l’une quelconque des dispositions du Contrat soit ou
          devienne illégale ou inapplicable n’affectera en aucune façon la
          validité ou l’applicabilité des autres stipulations du Contrat.
          <br />
          <br />
          9.2. Modification du Contrat
          <br />
          La Société se réserve le droit de modifier, compléter ou remplacer les
          présentes Conditions. En cas de refus de l’Utilisateur des nouvelles
          dispositions, il est rappelé que l’Utilisateur pourra à tout moment,
          conformément à l’article 7 (Résiliation), résilier le présent Contrat.
          <br />
          <br />
          Article 10 – Règlement des différends
          <br />
          La conclusion, l’interprétation et la validité du présent Contrat sont
          régis par la loi française, quel que soit le pays d’origine de
          l’Utilisateur ou le pays depuis lequel l’Utilisateur accède au Site et
          nonobstant les principes de conflits de lois.
          <br />
          <br />
          Dans l’hypothèse où un différend portant sur la validité, l’exécution
          ou l’interprétation du présent Contrat et serait porté devant les
          juridictions civiles, il sera soumis à la compétence exclusive des
          tribunaux français auquel il est fait expressément attribution de
          compétence, même en cas de référé ou de pluralité de défendeurs.
          <br />
          <br />
          Le Client est informé qu’il peut en tout état de cause recourir à une
          médiation conventionnelle ou à tout mode alternatif de règlement des
          différends (conciliation par exemple) en cas de contestation.
          <br />
          <br />
          En application de l’article 14.1 du règlement (UE) n°524/2013 du
          parlement européen et du conseil du 21 mai 2013, il est précisé au
          Client, qu’il peut consulter la page suivante pour avoir plus
          d’informations sur ses démarches en cas de contestation :<br />
          <br />
          https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=FR.
          Conformément aux dispositions légales concernant le règlement amiable
          des litiges, la Société adhère au service du médiateur de la
          consommation de MCP mediation dont les coordonnées sont les suivantes
          http://mcpmediation.org . Après démarche préalable écrite du Client,
          consommateur au sens du droit de la consommation, ayant la qualité de
          consommateur au sens du code de la consommation auprès de la Société,
          le service du médiateur précité peut être saisi pour tout litige de
          consommation dont le règlement n’aurait pas abouti.
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
export default CguPage;
