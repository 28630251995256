import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import { useSelector } from "react-redux";
import CatalogueCreate from "./catalogue-create";
import {
  useCatalogueCreateMutation,
  useCatalogueDeleteMutation,
  useCatalogueByUserQuery,
} from "./service/catalogue-api";
import { IMAGE_S3_URL } from "../../../utils/api-config";

function CataloguePage() {
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [catalogueCreate] = useCatalogueCreateMutation();
  const [catalogueDelete] = useCatalogueDeleteMutation();
  const catalogue = useCatalogueByUserQuery(user.id);

  const handleCreate = (files) => {
    for (let index = 0; index < files.length; index++) {
      catalogueCreate({ image: files[index] })
        .unwrap()
        .then(() => setShow(false))
        .catch((error) => console.log("error: ===>", error));
    }
  };

  const handleDelete = (id) => {
    catalogueDelete(id)
      .unwrap()
      .then()
      .catch((error) => console.log("error: ===>", error));
  };

  return (
    <div className="catalogue animate__animated animate__fadeIn">
      <div className="page-title">
        <span>MON CATALOGUE</span>
      </div>

      <CatalogueCreate
        status={show}
        handleCancel={() => setShow(false)}
        handleCreate={handleCreate}
      />
      <Button
        type="primary"
        className="btn-status"
        onClick={() => setShow(true)}
      >
        Ajouter
      </Button>
      <br />
      <br />
      <Row gutter={24}>
        {catalogue.data &&
          catalogue.data.map((item, index) => (
            <Col lg={6} key={index + item.id} className="gallery-item">
              <img
                src={`${IMAGE_S3_URL}/${item.image}`}
                width="100"
                alt="img"
              />
              <br />
              <Button type="text" onClick={() => handleDelete(item.id)}>
                Supprimer
              </Button>
            </Col>
          ))}
      </Row>
    </div>
  );
}
export default CataloguePage;
