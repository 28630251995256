import { Link, useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { IMAGE_S3_URL } from "../../../utils/api-config";
import { useEffect, useRef } from "react";

function SearchResultContent({ data }) {
  const navigate = useNavigate(),
    resultantRef = useRef(null);

  const handleProfessionalClick = (item) => {
    navigate(`/fiche-coiffeuse/${item.firstname}`, {
      state: {
        professionalId: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
      },
    });
  };

  // Have to implement an inner html update else than using state as dom was not updating when page was translated with google.
  useEffect(() => {
    resultantRef.current.textContent = data.length;
  }, [data]);

  return (
    <div className="search-page animate__animated animate__fadeIn">
      <div className="search-title">
        <div className="container">
          <div className="title">
            <h3>
              <span ref={resultantRef}>0</span>
              <br /> <span>résultats</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="search-result">
        <div className="container">
          <div className="row">
            {data.length > 0 &&
              data.map((item, index) => (
                <div
                  className="col-6 col-lg-3 animate__animated animate__backInUp"
                  key={index + item.id}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleProfessionalClick(item)}
                  >
                    <div className="blc">
                      <div
                        className="avatar"
                        style={{
                          backgroundImage: `url(${IMAGE_S3_URL}/${item.image})`,
                        }}
                      ></div>
                      <div className="content">
                        <div className="notes">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                        <h3 style={{ textAlign: "center" }}>
                          {item.firstname} {item.lastname},{" "}
                          <span>{item.mobilite}</span>
                        </h3>
                        <p>{item.city}</p>
                        <div style={{ textAlign: "center" }}>
                          <Link
                            to={`/fiche-coiffeuse/${item.firstname}`}
                            state={{
                              professionalId: item.id,
                              firstname: item.firstname,
                              lastname: item.lastname,
                            }}
                            className="btn btn-primary btn-sm"
                          >
                            Voir le profil
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchResultContent;
