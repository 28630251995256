import { useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, Alert, Modal } from "antd";
import Banner from "../account/components/banner";
import { useNavigate, Link } from "react-router-dom";
import {
  useUserChangePasswordMutation,
  useUserCheckTokenMutation,
} from "../account/profile/service/user-api";

function ResetPasswordPage() {
  const navigate = useNavigate();
  let token = new URLSearchParams(window.location.search).get("token");
  const [userId, setUserId] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [userChangePassword, { isLoading, isError, error }] =
    useUserChangePasswordMutation();
  const [userCheckToken] = useUserCheckTokenMutation();
  const [form] = Form.useForm();

  const onFinish = ({ password }) => {
    userChangePassword({ id: userId, password })
      .unwrap()
      .then(() => {
        form.resetFields();
        setIsSuccess(true);
      })
      .catch((error) => console.log("error ===>", error));
  };

  useEffect(() => {
    userCheckToken({ token })
      .unwrap()
      .then((res) => {
        setUserId(res.message.id);
      })
      .catch((error) => setTokenExpired(true));
  }, [userCheckToken, token]);

  return (
    <div className="auth">
      <Banner title="Connexion" />
      <div className="container">
        <div className="title" style={{ textAlign: "center" }}>
          <h2>Creer un nouveau mot de passe</h2>
        </div>
        <Row>
          <Col lg={10} style={{ margin: "auto" }}>
            {tokenExpired ? (
              <p style={{ textAlign: "center" }}>
                Le lien de réinitialisation du mot de passe a expiré. Veuillez
                demander une nouvelle {" "}
                <Link to={"/forget-password"}>
                  réinitialisation du mot de passe.
                </Link>
              </p>
            ) : (
              <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
                form={form}
              >
                {isError && (
                  <>
                    <Alert message={error.data} type="error" showIcon />
                    <br />
                  </>
                )}
                <Form.Item
                  label="Nouveau mot de passe"
                  name="password"
                  rules={[{ required: true, message: "Champs requis!" }]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Confirmer le nouveau mot de passe"
                  name="passwordConfirm"
                  rules={[
                    { required: true, message: "Champs requis!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Les mots de passe ne correspondent pas!")
                        );
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Modifier
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        className="auth"
        centered
        visible={isSuccess}
        footer={null}
        closable={false}
      >
        <div className="modal-body">
          <img
            src="assets/img/icon-professionalisme.png"
            alt="icon-success"
            style={{ width: "35%" }}
          />
          <p className="my-4">Votre mot de passe a été mis à jour</p>
          <Button variant="primary" onClick={() => navigate("/")}>
            Fermer
          </Button>
        </div>
      </Modal>
    </div>
  );
}
export default ResetPasswordPage;
