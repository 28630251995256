import { Button, Row, Col, Modal, Space } from "antd";
import "./styles/style.scss";

export function DisponibiliteDelete({ handleCancel, handleDelete, status }) {
  return (
    <Modal
      className="disponibilite"
      centered
      visible={status}
      footer={null}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="modal-body">
        <Row>
          <Col lg={24}>
            <div className="modal-title"> SUPPRESSION</div>
            <p>Voulez-vous supprimer ?</p>
            <Space>
              <Button
                type="default"
                className="btn-status"
                onClick={() => handleCancel()}
              >
                Annuler
              </Button>

              <Button
                type="primary"
                className="btn-status"
                onClick={() => handleDelete()}
              >
                Supprimer
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default DisponibiliteDelete;
