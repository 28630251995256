import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { id, cartQuantity } = action.payload;
      const existingItem = state.cartItems.find(item => item.id === id);

      if (existingItem) {
        existingItem.cartQuantity += cartQuantity;
      } else {
        state.cartItems.push({ ...action.payload, cartQuantity });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    increaseCart(state, action) {
      const itemId = action.payload.id;
      const existingItem = state.cartItems.find(item => item.id === itemId);

      if (existingItem) {
        if (existingItem.cartQuantity >= 1) {
          existingItem.cartQuantity += 1;
        } else {
          state.cartItems = state.cartItems.filter(item => item.id !== itemId);
        }
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemId = action.payload.id;
      const existingItem = state.cartItems.find(item => item.id === itemId);

      if (existingItem) {
        if (existingItem.cartQuantity > 1) {
          existingItem.cartQuantity -= 1;
        } else {
          state.cartItems = state.cartItems.filter(item => item.id !== itemId);
        }
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      const itemId = action.payload.id;
      state.cartItems = state.cartItems.filter(item => item.id !== itemId);
      
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems))    },
    getTotals(state) {
      const { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = parseFloat(total.toFixed(2));
    },
    clearCart(state) {
      state.cartItems = [];
      localStorage.removeItem('cartItems');
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart, increaseCart } = cartSlice.actions;

export default cartSlice.reducer;
