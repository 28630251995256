import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";


export const createCommentaireAsync = createAsyncThunk(
    "user/createCommentaireAsync",
    async (payload, { dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/commentaire/`,
                data: payload
            });
            return response.data.message;
        } catch (error) {
            return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
        }
    }
);

export const fetchCommentaireAsync = createAsyncThunk(
    "commentaire/fetchCustomerAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/commentaires/`,
                data: payload
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);


export const deleteCommentaireAsync = createAsyncThunk(
    "commentaire/deleteCommentaireAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'DELETE',
                url: `${baseURL}api/commentaire/${payload.id}`
            });
            return response.data;
        } catch (error) {
            console.log("error:", error);
        }
    }
);


const initialState = {
    data: [],
    status: "idle",
    isSuccess: false,
    isFetching: false,
};

const commentaireSlice = createSlice({
    name: "commentaire",
    initialState,
    reducers: {},
    extraReducers: {
        [createCommentaireAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [createCommentaireAsync.fulfilled]: (state) => {
            state.status = "success";
            state.isFetching = false
        },
        [createCommentaireAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },

        [fetchCommentaireAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [fetchCommentaireAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.isFetching = false;
            state.data = payload;
        },
        [fetchCommentaireAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },

        [deleteCommentaireAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [deleteCommentaireAsync.fulfilled]: (state) => {
            state.status = "success";
            state.isFetching = false;
        },
        [deleteCommentaireAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },
    },
});

export default commentaireSlice.reducer;
