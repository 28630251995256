import { Button, Form, Input, Row, Col, Alert } from "antd";
import Banner from "../account/components/banner";
import { useUserResetPasswordMutation } from "../account/profile/service/user-api";

function ForgetPasswordPage() {
  const [userResetPassword, { isLoading, isError, error, isSuccess }] =
    useUserResetPasswordMutation();
  const [form] = Form.useForm();

  const handleSubmit = (value) => {
    userResetPassword(value)
      .unwrap()
      .then(() => {
        form.resetFields();
      })
      .catch(() => {});
  };

  return (
    <div className="auth">
      <Banner title="Connexion" />
      <div className="container">
        <div className="title" style={{ textAlign: "center" }}>
          <h2>Mot de passe oublié</h2>
          <p>Un email vous sera envoyé pour réinitialiser votre mot de passe</p>
        </div>
        <Row>
          <Col lg={8} style={{ margin: "auto" }}>
            <Form
              name="basic"
              onFinish={handleSubmit}
              autoComplete="off"
              form={form}
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Champs requis!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  className="btn-lg btn-primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Demander la réinitialisation du mot de passe
                </Button>
              </Form.Item>
              {isError && <Alert message={error} type="error" showIcon />}
              
              {isSuccess && (
                <Alert message="Email envoyé" type="success" showIcon />
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ForgetPasswordPage;
