import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Alert, Spin, Select, Modal, Col } from "antd";
import Banner from "../../account/components/banner";
import { useAuthRegisterMutation } from "../service/auth-api";
import cities from "../../../utils/cities";
import "../styles/style.scss";
import { InputErrors, InputRegex } from "../../../utils/globalVars";
import { validation } from "../../../utils/errorHandler";

function RegisterCoiffeursePage() {
  const navigate = useNavigate();
  const [authRegister] = useAuthRegisterMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isErrorProfessional, setIsErrorProfessional] = useState(false);
  const [errorProfessional, setErrorProfessional] = useState(false);

  const handleSubmitProfessional = (value) => {
    setIsLoading(true);
    const data = { ...value, role: "professional", status: false };
    authRegister(data)
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsErrorProfessional(true);
        setErrorProfessional(error.data.message);
      });
  };

  const handleClose = () => navigate("/");

  return (
    <div className="auth">
      <Banner title="Inscription" />
      <div className="container">
        <Spin spinning={isLoading}>
          <div className="row">
            <Col lg={8} style={{ margin: "auto" }}>
              <div className="title">
                <h2>Inscription Espace Coiffeuse</h2>
              </div>
              <div className="content">
                <Form
                  name="coiffeuse"
                  onFinish={handleSubmitProfessional}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Nom"
                    name="firstname"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      () => ({
                        validator(_, value) {
                          return validation(
                            value,
                            InputRegex.alphabetsCheck,
                            InputErrors.nameError
                          );
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={80} />
                  </Form.Item>

                  <Form.Item
                    label="Prenom(s)"
                    name="lastname"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      () => ({
                        validator(_, value) {
                          return validation(
                            value,
                            InputRegex.alphabetsCheck,
                            InputErrors.nameError
                          );
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={80} />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Champs requis!" }]}
                  >
                    <Input type="email" maxLength={80} />
                  </Form.Item>
                  <Form.Item
                    label="Mobile"
                    name="phone"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      () => ({
                        validator(_, value) {
                          return validation(
                            value,
                            InputRegex.mobilePhoneCheck,
                            InputErrors.mobilePhoneError
                          );
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={80} minLength={10} />
                  </Form.Item>
                  <Form.Item
                    label="Mobilité"
                    name="mobilite"
                    rules={[{ required: true, message: "Champs requis!" }]}
                  >
                    <Select className="form-control">
                      <Select.Option value="se déplace">
                        Se déplace
                      </Select.Option>
                      <Select.Option value="Ne se déplace pas">
                        Ne se déplace pas
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Ville"
                    name="ville"
                    rules={[{ required: true, message: "Champs requis!" }]}
                  >
                    <Select className="form-control" showSearch>
                      {cities.map((item, index) => (
                        <Select.Option value={item.name} key={index + item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Département"
                    name="departement"
                    rules={[
                      { required: false, message: "Champs requis!" },
                    ]}
                  >
                    <Input maxLength={80} />
                  </Form.Item>

                  <Form.Item
                    label="Adresse"
                    name="adresse"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      () => ({
                        validator(_, value) {
                          if (value.trim().length === 0) {
                            return Promise.reject(
                              new Error(InputErrors.addressError)
                            );
                          }
                          return validation(
                            value,
                            InputRegex.addressCheck,
                            InputErrors.addressError
                          );
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={80} />
                  </Form.Item>
                  <Form.Item
                    label="Mot de passe"
                    name="password"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      () => ({
                        validator(_, value) {
                          return validation(
                            value,
                            InputRegex.strongPass,
                            InputErrors.strongPassError
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Confirmer le nouveau mot de passe"
                    name="passwordConfirm"
                    rules={[
                      { required: true, message: "Champs requis!" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Les mots de passe ne correspondent pas!")
                          );
                        },
                      }),
                    ]}
                    dependencies={["password"]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button className="btn-lg btn-primary" htmlType="submit">
                      Créer votre compte
                    </Button>
                  </Form.Item>
                  {isErrorProfessional && (
                    <Alert message={errorProfessional} type="error" showIcon />
                  )}
                </Form>
              </div>
            </Col>
          </div>
        </Spin>
      </div>
      <Modal
        className="auth"
        centered
        open={isSuccess}
        footer={null}
        closable={false}
      >
        <div className="modal-body">
          <img
            src="assets/img/icon-professionalisme.png"
            alt="icon-success"
            style={{ width: "35%" }}
          />
          <p className="my-4">
            Votre demande d'inscription a bien été enregistrée. Veuillez
            consulter votre boîte mail pour confirmer votre demande.
          </p>
          <Button variant="primary" onClick={() => handleClose()}>
            Fermer
          </Button>
        </div>
      </Modal>
    </div>
  );
}
export default RegisterCoiffeursePage;
