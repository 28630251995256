import React, { useState } from "react";
import { Button, Alert } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  useAppointmentCreateMutation,
  useAppointmentMailAdminMutation,
  useAppointmentMailUserMutation,
} from "../appointment/service/appointment-api";
import { usePaymentCreateMutation } from "../paiement/service/payment-api";
import moment from "moment";
import "./styles/style.scss";
import { calculateServiceCharges } from "../../../utils/payment-calculation";
import { useDisponibiliteUpdateMutation } from "../disponibilite/service/disponibilite-api";

function PaiementForm({
  prestation,
  params,
  professional,
  date,
  adresse,
  endTime,
  slotId,
}) {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [appointmentCreate] = useAppointmentCreateMutation();
  const [disponibiliteUpdate] = useDisponibiliteUpdateMutation();
  const [createPayment] = usePaymentCreateMutation();
  const [appointmentMailUser] = useAppointmentMailUserMutation();
  const [appointmentMailAdmin] = useAppointmentMailAdminMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sendMail = () => {
    const data = {
      hd_firstname: params.firstname,
      hd_lastname: params.lastname,
      hd_email: params.email,
      hd_phone: params.phone,
      adresse: adresse,
      firstname_customer: user.firstname,
      lastname_customer: user.lastname,
      email_customer: user.email,
      phone_customer: user.phone,
      date: moment(date).format("lll"),
    };
    appointmentMailUser(data);
    appointmentMailAdmin(data);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error && paymentMethod) {
      setIsLoading(true);
      const total = calculateServiceCharges(prestation.price);
      const paiementData = {
        amount: total * 100,
        currency: "eur",
        description: `Type: Rendez-vous - Cliente: ${user.firstname} ${user.lastname} - Email: ${user.email} - Téléphone: ${user.phone}`,
        payment_method: paymentMethod.id,
        confirm: true,
        hairdresserId: professional,
        charges: (total - prestation.price) * 100,
      };
      const appointmentData = {
        libelle: prestation.libelle,
        price: prestation.price,
        total: total,
        image: prestation.image,
        professional: professional,
        adresse,
        status: "Payé",
        customer: user.id,
        date: date,
        endTime,
      };
      appointmentCreate(appointmentData)
        .unwrap()
        .then((res) => {
          createPayment({ ...paiementData, appointmentId: res.id })
            .unwrap()
            .then(async () => {
              await disponibiliteUpdate({
                id: slotId,
                data: { booked: true },
              });
              setIsLoading(false);
              setPaymentSuccess(true);
              sendMail();
            })
            .catch((error) => {
              setIsLoading(false);
              setError(error.data?.message);
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error.data?.message);
        });
    } else {
      setIsLoading(false);
      setError(error?.message);
    }
  };

  return (
    <div>
      <div className="payment animate__animated animate__fadeIn">
        {paymentSuccess ? (
          <div className="animate__animated animate__fadeIn">
            <img
              src={
                window.location.origin + `/assets/img/icon-professionalisme.png`
              }
              alt="icon-success"
              style={{ width: "35%" }}
            />
            <p>La reservation a été prise en compte</p>
            <Button variant="primary" onClick={() => navigate("/")}>
              Fermer
            </Button>
          </div>
        ) : (
          <div>
            <div className="img-stripe">
              <img
                src={window.location.origin + `/assets/img/stripe-payment.png`}
                alt="img"
                width="300px"
              />
            </div>
            <div className="prestation-price">
              Prix de la prestation: {prestation.price} €
            </div>
            <div className="service-price">Frais de services: 7 %</div>
            <div className="total-price">
              <span>Total:</span>
              <span> {calculateServiceCharges(prestation.price)} €</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="input-stripe">
                <CardElement />
              </div>

              <button
                className="btn btn-primary btn-lg mt-4"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? `Chargement...` : `Payer`}
              </button>
            </form>
          </div>
        )}
        <br />
        {error && <Alert message={error} type="error" showIcon />}
      </div>
    </div>
  );
}
export default PaiementForm;
