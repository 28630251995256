import React from "react";
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from "../../redux/dialogSlice";


function DialogError() {
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => state.dialog);

    return (
        <Modal show={true} centered animation={false}>
            <Modal.Body>
                <img src={window.location.origin + `/assets/img/icons8-delete.png`} alt="icon-success" style={{ width: "15%" }} />
                <p className="my-4">{errorMessage}</p>
                <Button variant="primary" onClick={() => dispatch(showDialog({ isError: false, errorMessage: "" }))} >Fermer</Button>
            </Modal.Body>
        </Modal>
    );
}
export default DialogError;
