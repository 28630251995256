import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Alert } from "antd";
import { useDispatch } from "react-redux";
import {
  useAuthLoginMutation,
  useAuthFetchTokenMutation,
} from "./service/auth-api";
import { saveTokenState } from "../../utils/local-storage";
import { setToken, setUser } from "./service/auth-slice";

function LoginForm({ handleClose }) {
  const dispatch = useDispatch();

  const [authLogin] = useAuthLoginMutation();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (value) => {
    setIsLoading(true);
    authLogin(value)
      .unwrap()
      .then((res) => {
        saveTokenState(res.access_token);
        dispatch(setToken(res.access_token));
        authFetchToken()
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            dispatch(setUser(res));
            handleClose(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error.data.message);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.data.message);
      });
  };

  return (
    <div className="">
      <h2>Connexion</h2>
      <Form
        name="basic"
        // initialValues={{
        //   email: "makymadi242@gmail.com",
        //   password: "passer",
        // }}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Champs requis!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Champs requis!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            className="btn-lg btn-primary"
            htmlType="submit"
            loading={isLoading}
          >
            Connexion
          </Button>
        </Form.Item>
      </Form>
      <div style={{ textAlign: "center" }}>
        <Link to="/inscription" className="deja-inscrit">
          Vous n'avez pas de compte? Inscrivez-vous
        </Link>
        <br />
        <Link to="/forget-password" className="deja-inscrit">
          Mot de passe oublié?
        </Link>
        <br />
      </div>
      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
}
export default LoginForm;
