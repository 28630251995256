import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";


export const wishCreateAsync = createAsyncThunk(
    "wish/wishCreateAsync",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/wish/`,
                data: payload
            });
            dispatch(wishFetchAsync({ id: payload.userId }))
            dispatch(showAlert({ isSuccess: true, successMessage: "Ce produit a été ajouté à votre liste de souhaits" }))
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);


export const wishDeleteAsync = createAsyncThunk(
    "wish/wishDeleteAsync",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/wish/delete/`,
                data: payload
            });
            dispatch(wishFetchAsync({ id: payload.userId }))
            dispatch(showAlert({ isSuccess: true, successMessage: "Ce produit a été supprimé de votre liste de souhaits" }))
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

export const wishFetchAsync = createAsyncThunk(
    "wish/wishFetchAsync",
    async (payload) => {
        try {
            const response = await axios.get(baseURL + "api/wish/" + payload.id);
            return response.data.wishs;
        } catch (error) {
            console.log(error);
        }
    }
);

const initialState = {
    res: "",
    error: "",
    status: "idle",
    loading: false,
    data: []
};

const wishSlice = createSlice({
    name: "wish",
    initialState,
    reducers: {},
    extraReducers: {
        // Create
        [wishCreateAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [wishCreateAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.message = action.payload;
        },
        [wishCreateAsync.rejected]: (state, action) => {
            state.loading = false
            state.status = 'failed';
            state.error = action.payload;
        },
        // Fetch
        [wishFetchAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [wishFetchAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.loading = false;
            state.data = payload;
        },
        [wishFetchAsync.rejected]: (state, action) => {
            state.loading = false
            state.status = 'failed';
            state.error = action.payload;
        },
        // Delete
        [wishDeleteAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [wishDeleteAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.res = action.payload;
        },
        [wishDeleteAsync.rejected]: (state, action) => {
            state.loading = false
            state.status = 'failed';
            state.error = action.payload;
        },

    },
});

export default wishSlice.reducer;
