import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Boutique from "../../assets/img/boutique.jpg";
import StripePayment from "../../assets/img/stripe-payment.png";
import { useProductFetchQuery } from "./service/product-api";
import { IMAGE_S3_URL } from "../../utils/api-config";

function BoutiquePage() {
  const product = useProductFetchQuery();

  return (
    <div className="boutique-page animate__animated animate__fadeIn">
      <img src={Boutique} className="img-fluid" alt={Boutique} />
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col">
              <div className="row">
                {product.isSuccess &&
                  product.data &&
                  product.data.map((item, index) => (
                    <div className="col-md-3" key={index + item.id}>
                      <div className="blc">
                        <Link
                          to={`/boutique/${item.slug}`}
                          state={{ id: item.id }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              className="visual"
                              style={{
                                backgroundImage: `url(${IMAGE_S3_URL}/${item.image})`,
                              }}
                            />
                          </div>
                          <div className="title">{item.libelle}</div>
                          <div className="price">{item.price} €</div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <hr />
          <div className="mode-paiement">
            <Image src={StripePayment} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BoutiquePage;
