// @ts-nocheck
import * as React from "react";
import logoWhite from '../../assets/img/logo-white.png';
import { NavLink } from "react-router-dom";


function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <img src={logoWhite} alt="img" className="logo" />
                        <p>La beauté près de chez vous</p>
                    </div>
                    <div className="col-md-3">
                        <div className="blc">
                            <h4>Liens utiles</h4>
                            <div className="menu">
                                <ul>
                                    <li><NavLink to="/cgu">CGU</NavLink></li>
                                    <li><NavLink to="/faq">FAQ</NavLink></li>
                                    <li><NavLink to="/mentions-legales">Mentions légales</NavLink></li>
                                    <li><NavLink to="/apropos">Qui sommes-nous?</NavLink></li>
                                    <li><NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;
