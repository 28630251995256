import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../features/boutique/slice/cart-slice";
import factureSlice from "./factureSlice";
import rdvSlice from "./rdvSlice";
import dialogSlice from "./dialogSlice";
import commandeSlice from "./commande-slice";
import wishSlice from "./wish-slice";
import productsSlice from "../features/boutique/slice/products-slice";
import alertSlice from "./alertSlice";
import adresseSlice from "./adresse-slice";
import commentaireSlice from "./commentaire-slice";
import stripeSlice from "./stripe-slice";
import mailchimpSlice from "./mailchimp-stripe";
import { authApi } from "../features/auth/service/auth-api";
import authSlice from "../features/auth/service/auth-slice";
import { userApi } from "../features/account/profile/service/user-api";
import { prestationApi } from "../features/account/prestation/service/prestation-api";
import { disponibiliteApi } from "../features/account/disponibilite/service/disponibilite-api";
import { catalogueApi } from "../features/account/catalogue/service/catalogue-api";
import { homeApi } from "../features/home/service/home-api";
import { appointmentApi } from "../features/account/appointment/service/appointment-api";
import { paymentApi } from "../features/account/paiement/service/payment-api";
import { orderApi } from "../features/account/orders/service/order-api";
import { productApi } from "../features/boutique/service/product-api";
import { cartApi } from "../features/cart/service/cart-api";
import { mailApi } from "../features/account/service/mail-api";
import { stripeApi } from "../features/Stripe/service/stripe-api";

export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [prestationApi.reducerPath]: prestationApi.reducer,
    [disponibiliteApi.reducerPath]: disponibiliteApi.reducer,
    [catalogueApi.reducerPath]: catalogueApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [mailApi.reducerPath]: mailApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    adresse: adresseSlice,
    alert: alertSlice,
    cart: cartSlice,
    commentaire: commentaireSlice,
    dialog: dialogSlice,
    facture: factureSlice,
    product: productsSlice,
    rdv: rdvSlice,
    commande: commandeSlice,
    wish: wishSlice,
    auth: authSlice,
    stripe: stripeSlice,
    mailchimp: mailchimpSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      appointmentApi.middleware,
      homeApi.middleware,
      userApi.middleware,
      prestationApi.middleware,
      disponibiliteApi.middleware,
      catalogueApi.middleware,
      paymentApi.middleware,
      productApi.middleware,
      orderApi.middleware,
      cartApi.middleware,
      mailApi.middleware,
      stripeApi.middleware
    ),
});
