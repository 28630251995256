import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import Boutique from "../../assets/img/boutique.jpg";
import {
  decreaseCart,
  increaseCart,
  getTotals,
  removeFromCart,
} from "./slice/cart-slice";
import { BsArrowLeft } from "react-icons/bs";
import { IMAGE_S3_URL } from "../../utils/api-config";
import FormCoordonnees from "./form-coordonnees";
import LoginForm from "../auth/login-form";
import BoutiquePaiements from "./boutique-paiement";

function BoutiqueCartPage() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [locationData, setLocationData] = useState(null); 
  const [customer, setIsCustomer] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleIncreaseCart = (product) => {
    dispatch(increaseCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };

  const handleGetInfo = async (value) => {
    setIsCustomer(value);
    setShowStripeForm(true);
  };

  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      async function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const data = await response.json();
          setLocationData(data)
          
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      },
      function(error) {
        console.error('Error getting geolocation:', error);
      }
    );
  }

  useEffect(()=>{
          getLocation()
  },[])

  return (
    <div className="boutique-page animate__animated animate__fadeIn">
      <img src={Boutique} className="img-fluid" alt={Boutique} />
      <div className="container cart">
        {cart.cartItems.length > 0 ? (
          <div>
            <div className="title">
              <h2>Panier </h2>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <td>Article</td>
                  <td>Prix</td>
                  <td>Quantité</td>
                  <td>Total</td>
                </thead>
                <tbody>
                  {cart.cartItems &&
                    cart.cartItems.map((cartItem) => (
                      <tr key={cartItem}>
                        <td style={{ display: "flex" }}>
                          <div
                            className="cart-product-visual"
                            style={{
                              backgroundImage: `url(${IMAGE_S3_URL}/${cartItem.image})`,
                            }}
                          />
                          <div className="col cart-product-title">
                            <div className="libelle">{cartItem.libelle}</div>
                            <span
                              className="delete"
                              onClick={() => handleRemoveFromCart(cartItem)}
                            >
                              Supprimer
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className=" cart-product-price">
                            {cartItem.price}€
                          </div>
                        </td>
                        <td>
                          <div className="cart-product-quantity">
                            <span onClick={() => handleDecreaseCart(cartItem)}>
                              -
                            </span>
                            <div className="count">{cartItem.cartQuantity}</div>
                            <span onClick={() => handleIncreaseCart(cartItem)}>
                              +
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="cart-product-total-price">
                            {cartItem.price * cartItem.cartQuantity}€
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="cart-summary">
              <div className="cart-checkout">
                <div className="total">
                  <span>Total:</span>
                  <span>{cart.cartTotalAmount}€</span>
                </div>
                {user ? (
                  <Button
                    className="btn-lg btn-primary"
                    onClick={() => setShowModal(true)}
                  >
                    Finaliser la commande
                  </Button>
                ) : (
                  <Button
                    className="btn-lg btn-primary"
                    onClick={() => setIsAuth(true)}
                  >
                    Finaliser la commande
                  </Button>
                )}
                <Link to="/boutique" className="continue-shopping">
                  <BsArrowLeft />
                  Poursuivre vos achats
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="cart-empty">
            <h4>Votre panier est vide !</h4>
            <Link to="/boutique" className="btn btn-primary">
              Commencer vos achats
            </Link>
          </div>
        )}
      </div>
      <Modal
        width={1000}
        centered
        open={showModal}
        footer={null}
        closable={true}
        onCancel={() => setShowModal(false)}
      >
        <div className="modal-body">
          <FormCoordonnees
            handleCoordonnee={(value) => handleGetInfo(value)}
            handleClose={setShowModal}
            locationData={locationData}
           
          />
        </div>
      </Modal>

      <Modal
        className="auth"
        centered
        open={isAuth}
        footer={null}
        closable={true}
        onCancel={() => setIsAuth(false)}
      >
        <div className="modal-body">
          <LoginForm handleClose={setIsAuth} />
        </div>
      </Modal>

      <Modal
        className="auth"
        centered
        open={showStripeForm}
        footer={null}
        closable={true}
        onCancel={() => setShowStripeForm(false)}
      >
        <div className="modal-body">
          <BoutiquePaiements
            customer={customer}
            handleClose={setShowStripeForm}
            locationData={locationData}
          />
        </div>
      </Modal>
    </div>
  );
}
export default BoutiqueCartPage;
