import * as React from "react";
import tissage from "../../assets/img/tissage.jpg";
import braids from "../../assets/img/braids.png";
import poseperruque from "../../assets/img/pose-perruque.jpg";
import lissage from "../../assets/img/lissage.jpg";
import natte from "../../assets/img/natte.jpg";
import soinsnaturels from "../../assets/img/soins-naturels.jpg";
import defrisage from "../../assets/img/defrisage.jpg";
import event from "../../assets/img/event.jpg";

function NosServices() {
  const handleSearch = (value) => {
    // history.push(`/search/ville/${value}/date`);
  };
  return (
    <div className="nos-services">
      <div className="title">
        <h3>
          Nos <br /> <span>Services</span>
        </h3>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-1"
                style={{ backgroundImage: `url(${tissage})` }}
                onClick={() => handleSearch("tissage")}
              >
                <h4>TISSAGE</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-2"
                style={{ backgroundImage: `url(${braids})` }}
                onClick={() => handleSearch("Braids")}
              >
                <h4>BRAIDS</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-3"
                style={{ backgroundImage: `url(${poseperruque})` }}
                onClick={() => handleSearch("Pose perruque")}
              >
                <h4>
                  <span>POSE </span>PERRUQUE
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-4"
                style={{ backgroundImage: `url(${lissage})` }}
                onClick={() => handleSearch("Lissage")}
              >
                <h4>
                  <span>LISSAGE</span> BRUSHING
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-5"
                style={{ backgroundImage: `url(${natte})` }}
                onClick={() => handleSearch("Nattes")}
              >
                <h4>NATTES</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-6"
                style={{ backgroundImage: `url(${soinsnaturels})` }}
                onClick={() => handleSearch("Soins naturels")}
              >
                <h4>
                  <span>SOINS </span>NATURELS
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-7"
                style={{ backgroundImage: `url(${defrisage})` }}
                onClick={() => handleSearch("Défrisage")}
              >
                <h4>DÉFRISAGE</h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-8"
                style={{ backgroundImage: `url(${event})` }}
                onClick={() => handleSearch("Coiffures évènements")}
              >
                <h4>
                  <span>COIFFURES </span>EVENEMENTS
                </h4>
              </div>
            </div>

            <div className="col-6 col-lg-3">
              <div
                className="blc blc-8"
                style={{ backgroundImage: `url(assets/img/barbier.png)` }}
                onClick={() => handleSearch("Barbier")}
              >
                <h4>
                  <span>BARBIER </span>
                </h4>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div
                className="blc blc-8"
                style={{ backgroundImage: `url(assets/img/locks.png)` }}
                onClick={() => handleSearch("Locks")}
              >
                <h4>
                  <span>LOCKS </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NosServices;
