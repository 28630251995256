import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const stripeApi = createApi({
  reducerPath: "stripeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT, prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Stripe"],
  endpoints: (builder) => ({
    getConnectAccountLink: builder.query({
      query: (payload) => ({
        url: `/api/v1/stripe/account/link/${payload}`,
        method: "GET",
      }),
      invalidatesTags: ["Stripe"],
    }),
    updateUserConnectAccount: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/stripe/connect/${payload.account_id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Stripe"],
    }),
    createDashboardLink: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/stripe/dashboard/link`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Stripe"],
    }),
  }),
});

export const {
  useGetConnectAccountLinkQuery,
  useUpdateUserConnectAccountMutation,
  useCreateDashboardLinkMutation,
} = stripeApi;
