import { Row, Col, Button, Form, Input, Alert, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useUserUpdateMutation } from "./service/user-api";
import { setUser } from "../../auth/service/auth-slice";
import { useAuthFetchTokenMutation } from "../../auth/service/auth-api";
import cities from "../../../utils/cities";
import { InputErrors, InputRegex } from "../../../utils/globalVars";
import { validation } from "../../../utils/errorHandler";

function ProfilePage() {
  const dispatch = useDispatch();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const [userUpdate, { isLoading, isError, error }] = useUserUpdateMutation();
  const { user } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    userUpdate({ id: user.id, data: values })
      .unwrap()
      .then(() => {
        authFetchToken()
          .unwrap()
          .then((res) => dispatch(setUser(res)));
      })
      .catch((error) => console.log("error ===>", error));
  };

  return (
    <div className="animate__animated animate__fadeIn account-page">
      <div className="page-title">
        <span>INFORMATIONS PERSONNELLES</span>
      </div>
      {user && (
        <Form
          name="basic"
          initialValues={{
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            adresse: user.adresse,
            departement: user.departement,
            ville: user.ville,
            city: user.city,
          }}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          {isError && (
            <>
              <Alert message={error.data} type="error" showIcon />
              <br />
            </>
          )}

          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label="Nom"
                name="firstname"
                rules={[
                  { required: true, message: "Champs requis!" },
                  () => ({
                    validator(_, value) {
                      return validation(
                        value,
                        InputRegex.alphabetsCheck,
                        InputErrors.nameError
                      );
                    },
                  }),
                ]}
              >
                <Input maxLength={80} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Prenom(s)"
                name="lastname"
                rules={[
                  { required: true, message: "Champs requis!" },
                  () => ({
                    validator(_, value) {
                      return validation(
                        value,
                        InputRegex.alphabetsCheck,
                        InputErrors.nameError
                      );
                    },
                  }),
                ]}
              >
                <Input maxLength={80} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Téléphone"
                name="phone"
                rules={[
                  { required: true, message: "Champs requis!" },
                  () => ({
                    validator(_, value) {
                      return validation(
                        value,
                        InputRegex.mobilePhoneCheck,
                        InputErrors.mobilePhoneError
                      );
                    },
                  }),
                ]}
              >
                <Input maxLength={80} minLength={10} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={5}>
            <Col lg={24}>
              <Form.Item
                label="Adresse"
                name="adresse"
                rules={[
                  { required: true, message: "Champs requis!" },
                  () => ({
                    validator(_, value) {
                      if (value.trim().length === 0) {
                        return Promise.reject(
                          new Error(InputErrors.addressError)
                        );
                      }
                      return validation(
                        value,
                        InputRegex.addressCheck,
                        InputErrors.addressError
                      );
                    },
                  }),
                ]}
              >
                <Input maxLength={80} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label="Département"
                name="departement"
                rules={[
                  { required: false, message: "Champs requis!" },
                ]}
              >
                <Input maxLength={80} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Ville"
                name="ville"
                rules={[{ required: true, message: "Champs requis!" }]}
              >
                <Select className="form-control" showSearch>
                  {cities.map((item, index) => (
                    <Select.Option value={item.name} key={index + item}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
export default ProfilePage;
