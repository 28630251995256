import { Navigate } from "react-router-dom";

export const handleFirebaseError = (error) => {
  console.log("error FB:", error);

  if (error.code === "auth/wrong-password") {
    return "Identifiant ou mot de passe incorrect";
  }

  if (error.code === "auth/weak-password") {
    return "Identifiant ou mot de passe incorrect";
  }

  if (error.code === "auth/email-already-in-use") {
    return "Adresse dejà utilisée";
  }

  if (error.code === "auth/invalid-email") {
    return "Adresse email incorrecte";
  }

  if (error.code === "auth/user-not-found") {
    return "Cet utilisateur n'existe pas.";
  }

  if (error.code === "auth/user-disabled") {
    return "Le compte d’utilisateur a été désactivé par un administrateur";
  }
};

export const handleApiError = (error) => {
  console.log("error :", error);
  if (error === "Unauthorized") {
    return <Navigate to="/" />;
  } else {
    return error;
  }
};

export const validation = (value, regex, errorMessage) => {
  return new Promise((resolve, reject) => {
    if (!value || value.match(regex)) resolve();
    reject(new Error(errorMessage));
  });
};
