import React from "react";
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dialogClearState } from "../../redux/dialogSlice";
import iconSuccess from '../../assets/img/icon-professionalisme.png';
import { useNavigate } from "react-router-dom";

function DialogSuccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { successMessage, route } = useSelector((state) => state.dialog);

    const handleClose = () => {
        dispatch(dialogClearState())
        console.log("route:", route);
        navigate(route)
        // dispatch(showDialog({ isSuccess: false, successMessage: "" }));
    }

    return (
        <Modal show={true} centered>
            <Modal.Body>
                <img src={iconSuccess} alt="icon-success" style={{ width: "35%" }} />
                <p className="my-4"> {successMessage}</p>
                <Button variant="primary" onClick={() => handleClose()}>Fermer</Button>
            </Modal.Body>
        </Modal>
    );
}
export default DialogSuccess;
