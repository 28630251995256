import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";

export const mailchimpSubscribeContactAsync = createAsyncThunk(
    "mailchimp/mailchimpSubscribeContactAsync",
    async (dispatch) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${baseURL}api/mailchimp/payment`
            });
            return response.data;
        } catch (error) {
            return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
        }
    }
);


const initialState = {
    data: [],
    status: "idle",
    isSuccess: false,
    isFetching: false,
    errorMessage: "",
};

const mailchimpSlice = createSlice({
    name: "mailchimp",
    initialState,
    reducers: {
        mailchimpClearState: (state) => {
            state.status = 'idle';
            state.errorMessage = '';
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [mailchimpSubscribeContactAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [mailchimpSubscribeContactAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.paiements = payload;
            state.isFetching = false
        },
        [mailchimpSubscribeContactAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },

    },
});
export const { mailchimpClearState } = mailchimpSlice.actions;
export default mailchimpSlice.reducer;
