import React, { createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthFetchTokenMutation } from "../features/auth/service/auth-api";
import {
  setChatToken,
  setToken,
  setUser,
} from "../features/auth/service/auth-slice";
import { Client } from "@twilio/conversations";
import { loadTokenState } from "../utils/local-storage";
import jwtDecode from "jwt-decode";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(null);
  const { user, chatToken, token } = useSelector((state) => state.auth);
  const [authFetchToken] = useAuthFetchTokenMutation();
  const dispatch = useDispatch();

  const connectUser = async () => {
    const client = new Client(chatToken);
    setChatClient(client);
  };

  useEffect(() => {
    if (user) {
      connectUser();
    } else {
      if (loadTokenState()) {
        authFetchToken()
          .unwrap()
          .then((res) => {
            dispatch(setUser(res));
          })
          .catch((error) => {});
      }
    }

    return () => {
      if (chatClient && user) {
        setChatClient(null);
      }
    };
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (token && jwtDecode(token).exp < new Date() / 1000) {
      localStorage.clear();
      dispatch(setToken(""));
      dispatch(setChatToken(""));
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <UserContext.Provider value={chatClient}>{children}</UserContext.Provider>
  );
};
