import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT, prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Home"],
  endpoints: (builder) => ({
    search: builder.mutation({
      query: (payload) => ({
        params: {
          ...payload,
        },
        url: `/api/v1/search/`,
        method: "GET",
      }),
      invalidatesTags: ["Home"],
    }),
  }),
});

export const { useSearchMutation } = homeApi;
