import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import Boutique from "../../assets/img/boutique.jpg";
import StripePayment from "../../assets/img/stripe-payment.png";
import { addToCart, getTotals } from "./slice/cart-slice";
import { showAlert } from "../../redux/alertSlice";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useProductFetchOneQuery } from "./service/product-api";
import { IMAGE_S3_URL } from "../../utils/api-config";
import { useDispatch } from "react-redux";
import "./style/style.scss";
import { Alert } from "antd";
import { debounce } from 'lodash';

function BoutiqueDetailPage() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const product = useProductFetchOneQuery(slug);
  const [productAdded, setProductAdded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1)
  // eslint-disable-next-line
  const debouncedSetQuantity = useCallback(debounce(setQuantity, 500), []);

  const handleDecrement = () => {
    debouncedSetQuantity(prevQuantity => {
      const newQuantity = prevQuantity - 1;
      return newQuantity < 1 ? 1 : newQuantity;
    });
  };

  const handleIncrement = () => {
    debouncedSetQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleAddToCart = (prod) => {
    const productToAdd = { ...prod, cartQuantity: quantity };
    setLoading(true);
    
    setTimeout(() => {
      dispatch(addToCart(productToAdd));
      setLoading(false)
      dispatch(getTotals());
      dispatch(
        showAlert({
          isSuccess: true,
          successMessage: `${productToAdd.libelle} a été ajouté à votre panier`,
        })
      );
      setProductAdded(true);
      setLoading(false);
  
      setTimeout(() => {
        setProductAdded(false); 
      }, 2000);
    }, 1000);
  };
  
  

  return (
    <div className="boutique-page animate__animated animate__fadeIn">
      <img src={Boutique} className="img-fluid" alt={Boutique} />
      {product.isSuccess && product.data && (
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="aside-left">
                  <img
                    src={`${IMAGE_S3_URL}/${product.data.image}`}
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="aside-right">
                  <span>STRONGNBOLDEXTENSION</span>
                  <h1>{product.data.libelle}</h1>
                  <p className="price">
                    {product.data.price} € <span>En stock</span>
                  </p>

                  <div className="cart">
                    <div className="cart-product-quantity">
                      <span onClick={handleDecrement}
                        className="quantity-button"
                        disabled={quantity <= 1}
                        
                      >
                        -
                      </span>
                      <div className="count">{quantity}</div>
                      <span
                        onClick={handleIncrement}
                        className="quantity-button"
                      >
                        +
                      </span>
                    </div>
                  </div>

                  <span style={{ fontSize: "14px" }}>
                    Taxes locales incluses
                  </span>

                  {product.data.description && (
                    <>
                      <p className="description">Detail</p>
                      <span>{product.data.description}</span>
                    </>
                  )}
                  <br />
                  <br />
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={() => handleAddToCart(product.data)}
                    className="btn-lg"
                  >
                    {isLoading ? "Chargement..." : "Ajouter au pannier"}
                  </Button>
                  {productAdded && (
                    <Alert
                      style={{ marginTop: "1rem" }}
                      message="Article ajouté au panier"
                      type="success"
                      showIcon
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mode-paiement">
              <Image src={StripePayment} alt="img" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default BoutiqueDetailPage;
