import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const disponibiliteApi = createApi({
  reducerPath: "disponibiliteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Disponibilite"],
  endpoints: (builder) => ({
    disponibiliteCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/disponibilite`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Disponibilite"],
    }),

    disponibiliteFetch: builder.query({
      query: (id) => ({ url: `/api/v1/disponibilite/user/${id}` }),
      providesTags: ["Disponibilite"],
    }),

    disponibiliteFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/disponibilite/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Disponibilite"],
    }),

    disponibiliteUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/disponibilite/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Disponibilite"],
    }),

    disponibiliteDelete: builder.mutation({
      query: (id) => ({
        url: `/api/v1/disponibilite/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Disponibilite"],
    }),
  }),
});

export const {
  useDisponibiliteFetchQuery,
  useDisponibiliteCreateMutation,
  useDisponibiliteFetchOneMutation,
  useDisponibiliteUpdateMutation,
  useDisponibiliteDeleteMutation,
} = disponibiliteApi;
