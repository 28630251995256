import { Button, Row, Col, Modal, Space } from "antd";
import moment from "moment";
import "moment/locale/fr";
import "./styles/style.scss";
moment.locale("fr");

export function DisponibiliteCreate({
  handleCancel,
  status,
  startDate,
  endDate,
  handleCreate,
}) {
  return (
    <Modal
      className="disponibilite"
      centered
      visible={status}
      footer={null}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="modal-body">
        <Row>
          <Col lg={24}>
            <div className="modal-title"> Programmer</div>
            <p>
              <strong>Début:</strong> {moment(startDate).format("LLL")}
            </p>
            <p>
              <strong> Fin: </strong> {moment(endDate).format("LLL")}
            </p>
            <br />
            <Space>
              <Button
                type="default"
                className="btn-status"
                onClick={() => handleCancel()}
              >
                Annuler
              </Button>
              <Button
                type="primary"
                className="btn-status"
                onClick={() => handleCreate()}
              >
                Ajouter
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default DisponibiliteCreate;
