import { createSlice } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState: {
        isError: false,
        isSuccess: false,
        // isSuccessAuth: false,
        isFetching: false,
        // isAuth: false,
        // isPayment: false,
        successMessage: '',
        errorMessage: '',
        route: '',
        data: '',
        type: ''
    },
    reducers: {
        showDialog: (state, { payload }) => {
            state.isError = payload.isError
            state.isPayment = payload.isPayment
            state.isSuccess = payload.isSuccess
            state.errorMessage = payload.errorMessage
            state.successMessage = payload.successMessage
            state.route = payload.route
            state.data = payload.data
            state.type = payload.type
        },

        dialogClearState: (state) => {
            state.isError = false
            state.isSuccess = false
            state.errorMessage = ""
            state.successMessage = ""
            state.data = ""
            state.route = ""
        }
    },
});

export const { dialogClearState, showDialog } = dialogSlice.actions;
export default dialogSlice.reducer;