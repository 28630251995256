import { useEffect, useState } from "react";

const LastMessage = ({ conversation }) => {
  const [lastMessage, setLastMessage] = useState(null);

  const getLastMessage = async () => {
    const { items } = await conversation.getMessages(1000);
    setLastMessage(
      items.length
        ? items[conversation?.lastMessage?.index]?.body
        : "Pas encore de messages"
    );
  };

  useEffect(() => {
    getLastMessage();
    // eslint-disable-next-line
  }, []);

  return (
    <span className={`${conversation.unreadMessagesCount ? "bold_chat" : ""}`}>
      {lastMessage}
    </span>
  );
};

export default LastMessage;
