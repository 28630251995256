import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/header";
import HomePage from "./features/home";
import Footer from "./components/footer";
import AboutPage from "./features/about";
import FaqPage from "./features/faq";
import DialogError from "./components/dialog/dialog-error";
import DialogSuccess from "./components/dialog/dialog-success";
import Banner from "./features/account/components/banner";
import MenuLateral from "./features/account/components/menu-lateral";
import FicheCoiffeusePage from "./features/account/reservation/fiche-coiffeuse-page";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "./utils/globalVars";
import ProfilePage from "./features/account/profile/profile-page";
import PrestationPage from "./features/account/prestation/prestation-page";
import DisponibilitePage from "./features/account/disponibilite/disponibilite-page";
import CataloguePage from "./features/account/catalogue/catalogue-page";
import ReservationPage from "./features/account/reservation/reservation-page";
import AppointmentPage from "./features/account/appointment";
import PaiementPage from "./features/account/paiement/paiement-page";
import BoutiquePage from "./features/boutique/boutique-page";
import BoutiqueDetailPage from "./features/boutique/boutique-detail-page";
import BoutiqueCartPage from "./features/boutique/boutique-cart-page";
import OrdersPage from "./features/account/orders/order-page";
import ConfirmEmailPage from "./features/auth/confirm-email-page";
import ForgetPasswordPage from "./features/auth/forget-password-page";
import ChangePasswordPage from "./features/account/change-password";
import ResetPasswordPage from "./features/auth/reset-password-page";
import CguPage from "./features/cgu";
import MentionLegale from "./features/mentions-legales";
import Politique from "./features/politique-de-confidentialite";
import WelcomePage from "./features/auth/welcome-page";
import LoginClientPage from "./features/auth/connexion/login-client-page";
import LoginCoiffeusePage from "./features/auth/connexion/login-coiffeuse-page";
import RegisterClientPage from "./features/auth/inscription/register-client-page";
import RegisterCoiffeursePage from "./features/auth/inscription/register-coliffeuse-page";
import Message from "./features/message";
import ConnectLink from "./features/Stripe/ConnectLink/ConnectLink";
import ConnectSuccess from "./features/Stripe/ConnectSuccess/ConnectSuccess";
import PublicWrapper from "./components/PublicWrapper/PublicWrapper";
import PrivateWrapper from "./components/PrivateWrapper/PrivateWrapper";

function App() {
  const dialog = useSelector((state) => state.dialog);
  const auth = useSelector((state) => state.auth);
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return (
    <BrowserRouter>
      <Header />
      <Elements stripe={stripePromise}>
        <Routes>
          <Route element={<PublicWrapper auth={auth} />}>
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/welcome-page" element={<WelcomePage />} />
            <Route path="/login-client" element={<LoginClientPage />} />
            <Route path="/login-coiffeuse" element={<LoginCoiffeusePage />} />
            <Route path="/register-client" element={<RegisterClientPage />} />
            <Route
              path="/register-coiffeuse"
              element={<RegisterCoiffeursePage />}
            />
          </Route>

          <Route element={<PrivateWrapper auth={auth} />}>
            <Route exact path="/boutique" element={<BoutiquePage />} />
            <Route exact path="/boutique-cart" element={<BoutiqueCartPage />} />
            <Route
              exact
              path="/boutique/:slug"
              element={<BoutiqueDetailPage />}
            />
            <Route
              exact
              path="/fiche-coiffeuse/:name"
              element={<FicheCoiffeusePage />}
            />
            <Route
              exact
              path="/reservation/:name"
              element={<ReservationPage />}
            />
          </Route>

          <Route element={<MenuWrapper auth={auth} />}>
            <Route path="/account/profile" element={<ProfilePage />} />
            <Route
              path="/account/password-reset"
              element={<ChangePasswordPage />}
            />
            <Route path="/account/service" element={<PrestationPage />} />
            <Route
              path="/account/disponibilite"
              element={<DisponibilitePage />}
            />
            <Route path="/account/catalogue" element={<CataloguePage />} />
            <Route
              path="/account/mes-rendez-vous"
              element={<AppointmentPage />}
            />
            <Route path="/account/paiement" element={<PaiementPage />} />
            <Route path="/account/purchase-history" element={<OrdersPage />} />
            <Route path="/account/message" element={<Message />} />
          </Route>

          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/apropos" element={<AboutPage />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/cgu" element={<CguPage />} />
          <Route exact path="/mentions-legales" element={<MentionLegale />} />
          <Route
            exact
            path="/politique-de-confidentialite"
            element={<Politique />}
          />
          <Route
            exact
            path="/connect/link/:account_id"
            element={<ConnectLink />}
          />
          <Route
            exact
            path="/connect/success/:account_id"
            element={<ConnectSuccess />}
          />
          <Route exact path="/confirm-email" element={<ConfirmEmailPage />} />
          <Route
            exact
            path="/forget-password"
            element={<ForgetPasswordPage />}
          />
        </Routes>
      </Elements>
      <Footer />
      {dialog.isError && <DialogError />}
      {dialog.isSuccess && <DialogSuccess />}
    </BrowserRouter>
  );
}

const MenuWrapper = ({ auth }) => {
  return auth.token ? (<div className="info-perso">
    <Banner title="Bienvenue" />
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div className="menu-info-perso">
            <MenuLateral />
          </div>
        </div>
        <div className="col-md-9">
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  </div>)
    : (<Navigate to="/welcome-page" />)
};

export default App;
