import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    paymentCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/payment`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Payment"],
    }),

    paymentByUser: builder.query({
      query: (playload) => ({ url: `/api/v1/payment/user/${playload}` }),
      providesTags: ["Payment"],
    }),

    paymentFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/payment/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Payment"],
    }),

    paymentUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/payment/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Payment"],
    }),
  }),
});

export const {
  usePaymentByUserQuery,
  usePaymentCreateMutation,
  usePaymentFetchOneMutation,
  usePaymentUpdateMutation,
} = paymentApi;
