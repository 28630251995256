import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    orderCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/order`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Order"],
    }),

    orderPayment: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/order/payment`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Order"],
    }),

    orderByUser: builder.query({
      query: (id) => ({ url: `/api/v1/order/user/${id}` }),
      providesTags: ["Order"],
    }),

    orderFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/order/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Order"],
    }),

    orderUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/order/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Order"],
    }),

    orderSendMail: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/mail/order`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Order"],
    }),

    orderCancelSendMail: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/mail/order/cancel`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useOrderByUserQuery,
  useOrderPaymentMutation,
  useOrderCreateMutation,
  useOrderFetchOneMutation,
  useOrderUpdateMutation,
  useOrderSendMailMutation,
  useOrderCancelSendMailMutation,
} = orderApi;
