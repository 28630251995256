import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";


export const rdvCreateAsync = createAsyncThunk(
    "rdv/rdvCreateAsync",
    async (payload, { dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/rdv/`,
                data: payload
            });
            // return dispatch(showAlert({ isSuccess: true, successMessage: response.data.message }))
            return response.data.message;
        } catch (error) {
            return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
        }
    }
);

export const fetchCustomerAsync = createAsyncThunk(
    "rdv/fetchCustomerAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/customer/rdv/`,
                data: payload
            });
            var items = [];
            response.data.forEach((child) => {
                items.push({
                    id: child.id,
                    title: `RDV: ${child?.libelle} avec ${child.customerName}`,
                    start: new Date(child.start),
                    end: new Date(child.start)
                });
            });
            return items;
        } catch (error) {
            console.log(error);
        }
    }
);

export const fetchProAsync = createAsyncThunk(
    "rdv/fetchProAsync",
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/pro/rdv/`,
                data: payload
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);


const initialState = {
    rdv: [],
    error: "",
    status: "idle",
    loading: false
};

const rdvSlice = createSlice({
    name: "rdv",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCustomerAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [fetchCustomerAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.rdv = action.payload;
        },
        [fetchCustomerAsync.rejected]: (state) => {
            state.loading = false
            state.status = 'failed';
        },

        [fetchProAsync.pending]: (state) => {
            state.status = 'pending'
            state.loading = true
        },
        [fetchProAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.loading = false
            state.rdv = action.payload;
        },
        [fetchProAsync.rejected]: (state) => {
            state.loading = false
            state.status = 'failed';
        },
    },
});

export default rdvSlice.reducer;
