import * as React from "react";
import Banner from "../account/components/banner";

function Politique() {
  return (
    <div className="faq animate__animated animate__fadeIn">
      <Banner title="POLITIQUE DE CONFIDENTIALITE" />
      <div className="container">
        <div className="content">
          <h5>ARTICLE 1 – DONNÉES PERSONNELLE RECUEILLIES</h5>
          <p>
            Lorsque vous prenez un rendez-vous ou effectuez un achat sur notre
            boutique, dans le cadre de notre processus de vente, nous
            recueillons les renseignements personnels que vous nous fournissez,
            tels que votre nom, votre adresse et votre adresse e-mail. Lorsque
            vous naviguez sur notre boutique, nous recevons également
            automatiquement l’adresse de protocole Internet (adresse IP) de
            votre ordinateur, qui nous permet d’obtenir plus de détails au sujet
            du navigateur et du système d’exploitation que vous utilisez. Avec
            votre permission, nous pourrions également vous envoyer des e-mails
            au sujet de notre boutique, de nouveaux produits et d’autres mises à
            jour.
          </p>
          <h5>ARTICLE 2 - CONSENTEMENT</h5>
          <p>
            <strong>Comment obtenons-nous votre consentement?</strong>
          </p>
          <p>
            Lorsque vous nous fournissez vos renseignements personnels pour
            conclure une transaction, vérifier votre carte de crédit, passer une
            commande, nous présumons que vous consentez à ce que nous
            recueillions vos renseignements et à ce que nous les utilisions à
            cette fin uniquement.
            <br />
            Si nous vous demandons de nous fournir vos renseignements personnels
            pour une autre raison, à des fins de marketing par exemple, nous
            vous demanderons directement votre consentement explicite, ou nous
            vous donnerons la possibilité de refuser.
            <br />
          </p>
          <p>Comment retirer votre consentement?</p>
          <p>
            Si après nous avoir donné votre consentement, vous changez d’avis et
            ne consentez plus à ce que nous puissions vous contacter, recueillir
            vos renseignements ou les divulguer, vous pouvez nous en aviser en
            nous contactant par courriel à contact@kimekoif.com ou par courrier
            à l’adresse 93B Rue Saint Auber 62000 ARRAS France
          </p>
          <h5>ARTICLE 3 – DIVULGATION</h5>
          <p>
            Nous pouvons divulguer vos renseignements personnels si la loi nous
            oblige à le faire ou si vous violez nos Conditions Générales de
            Vente et d’Utilisation.
          </p>
          <h5>ARTICLE 4 – VOS DONNEES</h5>
          <strong>Notre boutique est hébergée sur Amazon Web Services.</strong>
          <br />
          <br />
          Vos données sont stockées dans le système de stockage de données et
          les bases de données d’ O2Switch. Vos données sont conservées sur un
          serveur sécurisé protégé par un pare-feu.
          <br />
          <br />
          Paiement:
          <br />
          Vous réalisez vos paiements via la plateforme de paiement Stripe. Ces
          renseignements sont chiffrés conformément à la norme de sécurité des
          données établie par l’industrie des cartes de paiement (norme
          PCI-DSS). Les renseignements relatifs à votre transaction d’achat sont
          conservés aussi longtemps que nécessaire pour finaliser votre
          commande. Une fois votre commande finalisée, les renseignements
          relatifs à la transaction d’achat sont supprimés. Toutes les
          passerelles de paiement direct respectent la norme PCI-DSS, gérée par
          le conseil des normes de sécurité PCI, qui résulte de l’effort
          conjoint d’entreprises telles que Visa, MasterCard et American
          Express.
          <br />
          <br />
          ARTICLE 5 – SERVICES FOURNIS PAR DES TIERS
          <br />
          <br />
          De manière générale, les fournisseurs tiers que nous utilisons vont
          uniquement recueillir, utiliser et divulguer vos renseignements dans
          la mesure du nécessaire pour pouvoir réaliser les services qu’ils nous
          fournissent.
          <br />
          Cependant, certains tiers fournisseurs de services, comme les
          passerelles de paiement et autres processeurs de transactions de
          paiement, possèdent leurs propres politiques de confidentialité quant
          aux renseignements que nous sommes tenus de leur fournir pour vos
          transactions d’achat.
          <br />
          En ce qui concerne ces fournisseurs, nous vous recommandons de lire
          attentivement leurs politiques de confidentialité pour que vous
          puissiez comprendre la manière dont ils traiteront vos renseignements
          personnels.
          <br />
          Il ne faut pas oublier que certains fournisseurs peuvent être situés
          ou avoir des installations situées dans une juridiction différente de
          la vôtre ou de la nôtre. Donc si vous décidez de poursuivre une
          transaction qui requiert les services d’un fournisseur tiers, vos
          renseignements pourraient alors être régis par les lois de la
          juridiction dans laquelle ce fournisseur se situe ou celles de la
          juridiction dans laquelle ses installations sont situées. Une fois que
          vous quittez le site de notre boutique ou que vous êtes redirigé vers
          le site web ou l’application d’un tiers, vous n’êtes plus régi par la
          présente Politique de Confidentialité ni par les Conditions Générales
          de Vente et d’Utilisation de notre site web.
          <br />
          <br />
          Liens
          <br />
          <br />
          Vous pourriez être amené à quitter notre site web en cliquant sur
          certains liens présents sur notre site. Nous n’assumons aucune
          responsabilité quant aux pratiques de confidentialité exercées par ces
          autres sites et vous recommandons de lire attentivement leurs
          politiques de confidentialité.
          <br />
          <br />
          ARTICLE 6 – SÉCURITÉ
          <br />
          Pour protéger vos données personnelles, nous prenons des précautions
          raisonnables et suivons les meilleures pratiques de l’industrie pour
          nous assurer qu’elles ne soient pas perdues, détournées, consultées,
          divulguées, modifiées ou détruites de manière inappropriée.
          <br />
          <br />
          COOKIES
          <br />
          Les cookies permettent de stocker et récupérer des informations. Un
          cookie est une petite information qui s’envoie à un site web et reste
          stockée dans le navigateur de l’utilisateur. Il permet au site web
          visité de peut consulter l’activité précédente de l’utilisateur. Il ne
          permet pas d’identifier l’utilisateur, mais enregistre des
          informations relatives à la navigation de celui-ci sur le site. Les
          cookies peuvent expirer à la fin d’une session de navigation (quand
          vous fermez le navigateur) ou après un certain bout de temps.
          <br />
          Notre site peut être amené à vous demander l’acceptation des cookies
          pour des besoins de statistiques et d’affichage. Un cookie est une
          information déposée sur votre disque dur par le serveur du site que
          vous visitez. Il contient plusieurs données qui sont stockées sur
          votre ordinateur dans un simple fichier texte auquel un serveur accède
          pour lire et enregistrer des informations.
          <br />
          KIMEOIF utilise aussi des cookies pour comprendre, analyser et
          améliorer son site. Pour le faire, il travaille avec des membres
          tiers, comme Google Analytics qui introduisent et lisent les cookies
          et d’autres technologies de suivi. À continuation nous décrirons les
          technologies que nous utilisons et les mesures que vous pouvez opter
          pour vous exclure de ces fonctionnalités.
          <br />
          <br />
          L’utilisateur est informé que lors de ses visites sur le site
          kimekoif.com un ou des cookies sont susceptibles de s’installer
          automatiquement sur son ordinateur par l’intermédiaire de son logiciel
          de navigation. N’importe quel utilisateur peut changer la
          configuration de son navigateur pour ne plus stocker de cookies. Vous
          pouvez accepter ou refuser l’utilisation des cookies grâce à une
          fonctionnalité intégrée dans le navigateur.
          <br />
          Comment désactiver les cookies et autres technologies de suivi ?{" "}
          <br />
          Pour plus d’information sur la configuration des cookies, veuillez
          cliquer sur ces liens:
          <br />
          <br />
          ​ Pour Internet Explorer, cliquez ici 
          <br />
          Pour Mozilla Firefox, cliquez ici 
          <br />
          Pour Google Chrome, cliquez ici 
          <br />
          Pour Safari, cliquez ici 
          <br />
          <br />
          ​ Dans le cas où vous en utiliseriez un autre, KIMEKOIF vous conseille
          de consulter les différentes options de gestion de cookies de votre
          navigateur.
          <br />
          Voici les cookies que nous utilisons. Nous les avons énumérés ici pour
          que vous ayez la possibilité de choisir si vous souhaitez les
          autoriser ou non.
          <br />
          - identificateur unique de session, permet pour stocker les
          informations relatives à votre session (référent, page de renvoi,
          etc.).
          <br />
          <br />
          ARTICLE 7 – ÂGE DE CONSENTEMENT
          <br />
          En utilisant ce site, vous déclarez que vous avez au moins l’âge de la
          majorité dans votre État ou province de résidence, et que vous nous
          avez donné votre consentement pour permettre à toute personne d’âge
          mineur à votre charge d’utiliser ce site web.
          <br />
          <br />
          ARTICLE 8 – MODIFICATIONS APPORTÉES À LA PRÉSENTE POLITIQUE DE
          CONFIDENTIALITÉ
          <br />
          Nous nous réservons le droit de modifier la présente politique de
          confidentialité à tout moment, donc veuillez s’il vous plait la
          consulter fréquemment. Les changements et les clarifications prendront
          effet immédiatement après leur publication sur le site web. Si nous
          apportons des changements au contenu de cette politique, nous vous
          aviserons ici qu’elle a été mise à jour, pour que vous sachiez quels
          renseignements nous recueillons, la manière dont nous les utilisons,
          et dans quelles circonstances nous les divulguons, s’il y a lieu de le
          faire.
          <br />
          Si notre boutique fait l’objet d’une acquisition par ou d’une fusion
          avec une autre entreprise, vos renseignements pourraient être
          transférés aux nouveaux propriétaires pour que nous puissions
          continuer à vous vendre des services.
          <br />
          <br />
          QUESTIONS ET COORDONNÉES
          <br />
          <br />
          Si vous souhaitez: accéder à, corriger, modifier ou supprimer toute
          information personnelle
          <br />
          que nous avons à votre sujet, déposer une plainte, ou si vous
          souhaitez simplement avoir plus d’informations, contactez notre agent
          responsable des normes de confidentialité à contact@kimekoif.com ou
          par courrier à 93B Rue Saint Auber 62000 ARRAS France.
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
export default Politique;
