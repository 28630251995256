import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Modal,
  Upload,
  Tooltip,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import coiffures from "../../../assets/json/coiffuresJson";
import { API_ROOT } from "../../../utils/api-config";
import { useEffect } from "react";
import "./styles/style.scss";
import { useSelector } from "react-redux";
import { IMAGE_S3_URL } from "../../../utils/api-config";

export function PrestationCreate({ handleCancel, status, handleCreate, editMode=false, data = {} }) {
  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue({
        libelle: data.libelle,
        price: data.price,
        image: {
          file: {
            name: data.image,
            thumbUrl: `${IMAGE_S3_URL}/${data.image}`,
            status: 'done',
          }
        }
      });
    } else {
      form.resetFields();
    }
  }, [editMode, data, form]);

  const props = {
    name: "file",
    action: `${API_ROOT}/api/v1/upload`,
    headers: { Authorization: `Bearer ${token}` },
    maxCount: 1,
    listType: "picture",
    ...(editMode ? { defaultFileList: [{
      uid: '-1',
      name: data.image,
      status: 'done',
      thumbUrl: `${IMAGE_S3_URL}/${data.image}`
    }] } : {}),
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };

  const handleSubmit = (values) => {
    if (editMode) {
      handleCreate({ ...data, ...values });
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal
      className="prestation"
      centered
      open={status}
      footer={null}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="modal-body">
        <Row>
          <Col lg={24}>
            <div className="modal-title"> {editMode ? 'Modifier le service' : 'Ajouter un service'}</div>
            <div className="form-wrapper">
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                  name="libelle"
                  rules={[
                    {
                      required: true,
                      message: "Champs requis!",
                    },
                  ]}
                >
                  <Select placeholder="Coiffure" className="form-control">
                    {coiffures.map((item, index) => (
                      <Select.Option value={item} key={index + item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "Champs requis!",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Prix" />
                </Form.Item>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Champs requis!",
                    },
                  ]}
                >
                  <Upload {...props}>
                    <Tooltip title="maximum 1 image">
                      <Button icon={<UploadOutlined />}>
                        { editMode ? 'Replace' : 'Upload'} <span className="asteric"> * </span>
                      </Button>
                    </Tooltip>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    {editMode ? 'Modifier' : 'Ajouter'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default PrestationCreate;
