const coiffures = [
    'Barbier',
    'Braids',
    'Coiffures évènements',
    'Défrisage',
    'Lissage',
    'Locks',
    'Nattes',
    'Tissage',
    'Pose perruque',
    'Soins naturels'
]
export default coiffures;