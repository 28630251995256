import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Alert, Spin, Col } from "antd";
import { useDispatch } from "react-redux";
import Banner from "../../account/components/banner";
import {
  useAuthFetchTokenMutation,
  useAuthLoginProfessionalMutation,
} from "../service/auth-api";
import {
  saveChatTokenState,
  saveTokenState,
} from "../../../utils/local-storage";
import { setChatToken, setToken, setUser } from "../service/auth-slice";
import jwt_decode from "jwt-decode";

function LoginCoiffeusePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authLoginProfessional] = useAuthLoginProfessionalMutation();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorProfessional, setIsErrorProfessional] = useState(false);
  const [errorProfessional, setErrorProfessional] = useState(false);

  const handleSubmitProfessional = (value) => {
    setIsLoading(true);
    const data = { ...value, profile: "professional" };
    authLoginProfessional(data)
      .unwrap()
      .then((res) => {
        if (res.profile === "customer") {
          setIsLoading(false);
          setIsErrorProfessional(true);
          setErrorProfessional("Ce compte n'existe pas !");
        } else {
          const decoded = jwt_decode(res.access_token);
          if (decoded.connectAccount) {
            connectAccountSuccess(res);
          } else {
            navigate(`/connect/link/${decoded.connect_id}`);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsErrorProfessional(true);
        setErrorProfessional(error.data.message);
      });
  };
  const connectAccountSuccess = (result) => {
    saveChatTokenState(result.twilio_token);
    saveTokenState(result.access_token);
    dispatch(setToken(result.access_token));
    dispatch(setChatToken(result.twilio_token));
    authFetchToken()
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        dispatch(setUser(res));
        navigate("/account/profile");
      })
      .catch((error) => {
        setIsLoading(false);
        setIsErrorProfessional(true);
        setErrorProfessional(error.data.message);
      });
  };

  return (
    <div className="auth">
      <Banner title="Connexion" />
      <div className="container">
        <Spin spinning={isLoading}>
          <div className="row">
            <Col lg={8} style={{ margin: "auto" }}>
              <div className="title">
                <h2>Connexion Espace Coiffeuse</h2>
              </div>
              <Form
                name="coiffeuse"
                onFinish={handleSubmitProfessional}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[{ required: true, message: "Champs requis!" }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button className="btn-lg btn-primary" htmlType="submit">
                    Connexion
                  </Button>
                </Form.Item>
              </Form>

              <div style={{ textAlign: "center" }}>
                <Link to="/register-coiffeuse" className="deja-inscrit">
                  Vous n'avez pas de compte? Inscrivez-vous
                </Link>
                <br />
                <Link to="/forget-password" className="deja-inscrit">
                  Mot de passe oublié?
                </Link>
                <br />
              </div>

              {isErrorProfessional && (
                <Alert message={errorProfessional} type="error" showIcon />
              )}
            </Col>
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default LoginCoiffeusePage;
