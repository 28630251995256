// @ts-nocheck
import * as React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../account/components/banner";
import NosServices from "../../components/nosServices";
import Transparence from '../../assets/img/icon-transparence.png';
import Proximite from '../../assets/img/icon-proximite.png';
import Professionalisme from '../../assets/img/icon-professionalisme.png';
import reactivite from '../../assets/img/icon-ecoute-reactivite.png';


function AboutPage() {
    return (
        <div className="about animate__animated animate__fadeIn">
            <Banner title="A propos" />
            <div className="container">
                <div className="title"><h2>Qui sommes-nous ?</h2></div>
                <div className="content">
                    <p>
                        KIMEKOIF est une plateforme en ligne de mise en relation entre les femmes souhaitant se faire coiffer et les professionnelles de leur région. Créée par <NavLink to="/boutique">STRONG AND BOLD EXTENSIONS</NavLink>, nous vous proposons également nos propres extensions capillaires de qualité supérieure. Notre but ? Répondre à une véritable besoin régional: celui de trouver une coiffeuse de confiance près de chez soi.
                        <br /><br />
                        Kimekoif, c’est tout simplement la beauté près de chez vous !
                    </p>
                    <br />
                    <div className="title"><h2>Nos valeurs</h2></div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="blc">
                                <img src={Transparence} alt="Logo" className="img-fluid" />
                                <h4>Transparence</h4>
                                <p>Au sein de notre équipe comme envers vous, Kimekoif tient à garder une communication simple et claire, en répondant à un maximum de vos interrogations avant même que vous ne nous les posiez. Pour cela, nous avons mis en place un <NavLink to="/faq">FAQ !</NavLink>.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="blc">
                                <img src={Professionalisme} alt="Logo" className="img-fluid" />
                                <h4>Professionalisme</h4>
                                <p>Nous tenons à vous garantir une constance dans la qualité de nos services, que vous soyez coiffeuse, ou simple cliente.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="blc">
                                <img src={reactivite} alt="Logo" className="img-fluid" />
                                <h4>Écoute & Réactivité</h4>
                                <p>Notre service client met un point d’honneur à prendre en compte vos problématiques afin de vous proposer des solutions adaptées à votre besoin.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="blc">
                                <img src={Proximite} alt="Logo" className="img-fluid" />
                                <h4>Proximité</h4>
                                <p>Grâce à Kimekoif, votre prochaine coiffeuse ne se trouve qu’à quelques clics de chez vous !</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NosServices />

            {/* <div className="rejoindre-coiffeuse">
                <Link to="/" className="btn btn-primary">Rejoindre nos coiffeuses</Link>
            </div> */}
        </div >
    );
}
export default AboutPage;