import { NavLink } from "react-router-dom";
import NosServices from "../../../components/nosServices";

function DefaultContent() {
  return (
    <>
      <div className="home-content">
        <NosServices />
        <div className="decouvrir-les-extensions">
          <div className="container">
            <div className="title">
              <h3>
                Découvrir les extensions <br />
                <span>Strong n bold</span>
              </h3>
              <br />
              <NavLink to="/boutique" className="btn btn-primary">
                Voir la boutique
              </NavLink>
            </div>
            <div className="content">
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="blc"
                    style={{
                      backgroundImage: `url("./assets/img/creation-page-home-1.png")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <div
                    className="blc"
                    style={{
                      backgroundImage: `url("./assets/img/creation-page-home-2.png")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <div
                    className="blc"
                    style={{
                      backgroundImage: `url("./assets/img/creation-page-home-3.png")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DefaultContent;
