import * as React from "react";

function Banner({ title }) {
    return (
        <>
            <div className="banner">
                <h1 style={{ color: 'white' }}><em>{title}</em></h1>
            </div>
            <div className="banner-footer"></div>
        </>
    );
}
export default Banner;
