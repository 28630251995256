import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const prestationApi = createApi({
  reducerPath: "prestationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Prestation"],
  endpoints: (builder) => ({
    prestationCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Prestation"],
    }),

    prestationByUser: builder.query({
      query: (id) => ({ url: `/api/v1/prestation/user/${id}` }),
      providesTags: ["Prestation"],
    }),

    prestationFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/prestation/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Prestation"],
    }),

    prestationUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Prestation"],
    }),

    prestationDelete: builder.mutation({
      query: (id) => ({
        url: `/api/v1/prestation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Prestation"],
    }),
  }),
});

export const {
  usePrestationByUserQuery,
  usePrestationCreateMutation,
  usePrestationFetchOneMutation,
  usePrestationUpdateMutation,
  usePrestationDeleteMutation,
} = prestationApi;
