import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { TiShoppingCart } from "react-icons/ti";
import { NavLink, useNavigate } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/img/logo.svg";
import iconHome from "../../assets/img/icon-home.svg";
import { setToken, setUser } from "../../features/auth/service/auth-slice";
import { loadTokenState, removeTokenState } from "../../utils/local-storage";
import { useAuthFetchTokenMutation } from "../../features/auth/service/auth-api";
import CookieConsent from "react-cookie-consent";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authFetchToken] = useAuthFetchTokenMutation();
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);

  const onLogout = async () => {
    removeTokenState();
    dispatch(setToken(""));
    dispatch(setUser(""));
    navigate("/");
  };

  const goToHome = async () => {
    navigate("/");
  };

  useEffect(() => {
    if(loadTokenState()){
      authFetchToken()
      .unwrap()
      .then((res) => {
        dispatch(setUser(res));
      })
      .catch((error) => {});
    }
  }, [authFetchToken, dispatch]);

  return (
    <div className="header">
      <CookieConsent
        location="bottom"
        buttonText="Accepter & Fermer"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "rgba(255, 255, 255, 0.926)",
          color: "black",
          fontSize: "13px",
        }}
        buttonStyle={{
          background: "#eba348",
          color: "black",
          fontSize: "13px",
          fontWeight: "bold",
        }}
      >
        Avec votre accord, nos partenaires et nous utilisons des cookies ou
        technologies similaires pour stocker et accéder à des informations
        personnelles comme votre visite sur ce site. Vous pouvez retirer votre
        consentement ou vous opposer aux traitements basés sur l'intérêt
        légitime à tout moment en cliquant sur <a href="@">En savoir plus</a> ou
        dans notre politique de confidentialité sur ce site.
      </CookieConsent>
      <div className="topbar">
        <div className="container">
          <ul>
            <li>
              <a href="@">
                <BsFillCircleFill className="dot" /> Rejoindre notre communauté
              </a>
            </li>
            <li>
              <a href="@">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="@">
                <AiOutlineInstagram />
              </a>
            </li>
            <li>
              <a href="@">
                <AiOutlineTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand>
            <div onClick={() => goToHome()} style={{ cursor: "pointer" }}>
              <img src={logo} alt="Logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"> </Nav>
            <Nav>
              <NavLink to="/" className="nav-link">
                <div onClick={() => goToHome()}>
                  <img src={iconHome} alt="img" className="iconHome" />{" "}
                </div>
              </NavLink>
              <NavLink to="/apropos" className="nav-link">
                A propos
              </NavLink>
              <NavLink to="/faq" className="nav-link">
                FAQ
              </NavLink>
              <NavLink to="/boutique" className="nav-link">
                La Boutique
              </NavLink>
              <>
                {user ? (
                  <div className="logout nav-link">
                    <NavLink to="/account/profile">{user.firstname}</NavLink>
                    <span onClick={() => onLogout()}> {">"} Quitter</span>
                  </div>
                ) : (
                  <div className="logout nav-link">
                    <NavLink to="/welcome-page">Mon Espace</NavLink>
                  </div>
                )}
              </>
            </Nav>
            <div className="cart-icon">
              <NavLink to="/boutique-cart">
                {!(cartTotalQuantity === 0) && <span>{cartTotalQuantity}</span>}
                <TiShoppingCart className="icon" />
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
export default Header;
