import { Layout } from "antd";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateWrapper = ({ auth }) => {
    return (
        <>
            {auth.token ? (
                <Layout>
                    <Outlet />
                </Layout>
            ) : (
                <Navigate to="/welcome-page" />
            )}
        </>
    );
};

export default PrivateWrapper;
