import * as React from "react";
import Banner from "../account/components/banner";


function FaqPage() {
    return (
        <div className="faq animate__animated animate__fadeIn">
            <Banner title="FAQ" />
            <div className="container">
                <div className="content">
                    <div className="title"><h2>Comment ça marche?</h2></div>
                    <div className="blc">
                        <h4>1 - Sur le site, je renseigne mon besoin</h4>
                        <span>Mon type de prestation souhaité, à quelle date et dans quelle ville
                        </span>
                    </div>
                    <div className="blc">
                        <h4>2 - Je sélectionne une coiffeuse </h4>
                        <span>Parmi celles qui se trouvent dans mes environs, en me fiant aux notes et avis de chacune </span>
                    </div>
                    <div className="blc">
                        <h4>3 - Je prends rendez-vous</h4>
                        <span>En m'inscrivant afin d'entrer en contact avec ma coiffeuse</span>
                    </div>

                    <div className="blc">
                        <h4>4 - Je paie ma prestation</h4>
                        <span>En toute sécurité via notre portail en ligne</span>
                    </div>

                    <div className="blc">
                        <h4>5 - C'est fini!</h4>
                        <span>Je n'ai plus qu'à attendre ma coiffeuse, et me faire belle depuis le confort de mon domicile!</span>
                    </div>
                    <br />
                    <br /><br /><br />
                </div>

            </div>
        </div >
    );
}
export default FaqPage;