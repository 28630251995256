import { Table, Popconfirm, Button, Tag } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  useOrderByUserQuery,
  useOrderCancelSendMailMutation,
  useOrderUpdateMutation,
} from "./service/order-api";
import { handleApiError } from "../../../utils/errorHandler";
import "./styles/style.scss";

function OrdersPage() {
  const { user } = useSelector((state) => state.auth);
  const order = useOrderByUserQuery(user.id);
  const [orderUpdate] = useOrderUpdateMutation();
  const [orderCancelSendMail] = useOrderCancelSendMailMutation();

  const handleUpdate = (value) => {
    orderUpdate({ id: value.id, data: { status: "annulé" } })
      .unwrap()
      .then(() =>
        orderCancelSendMail({ order: value.order_number })
          .unwrap()
          .then()
          .catch((error) => console.log("error: ===>", error))
      )
      .catch((error) => console.log("error: ===>", error));
  };

  const columns = [
    {
      title: "Nº de commande",
      key: "order_number",
      render: (record) => (
        <span>
          <span style={{ margin: "10px" }}>{record.order_number}</span>
        </span>
      ),
    },
    {
      title: "Nº de colis",
      render: (record) => <span>{record.colis_number}</span>,
    },
    {
      title: "Date",
      render: (record) => <span>{moment(record.date).format("llll")}</span>,
    },
    {
      title: "Statut",
      key: "status",
      render: (record) =>
        record.status === "annulé" ? (
          <Tag>Annulé</Tag>
        ) : (
          <Tag> {record.status}</Tag>
        ),
    },
    {
      title: "Action",
      key: "status",
      render: (record) =>
        record.status !== "annulé" && (
          <Popconfirm
            title="Voullez-vous annuler cette commande ?"
            onConfirm={() => handleUpdate(record)}
            okText="Oui"
            disabled={record.status === "annulé" ? true : false}
            cancelText="Non"
          >
            <Button type="primary" className="btn-status">
              Annuler
            </Button>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div className="order-page animate__animated animate__fadeIn">
      <div className="page-title">
        <span>MES COMMANDES</span>
      </div>
      {order.isSuccess && order.data && (
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => (
              <table className="cart-detail">
                <thead>
                  <th>Article</th>
                  <th>Prix</th>
                  <th width="20%">Quantité</th>
                </thead>
                {record.cart.map((item, index) => (
                  <tr key={index + item.id}>
                    <td>{item.libelle}</td>
                    <td>{item.price} €</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </table>
            ),
            rowExpandable: (record) => record.order_number !== "Not Expandable",
          }}
          dataSource={order.data}
          loading={order.isFetching}
        />
      )}
      {order.isError && handleApiError(order.error.data.message)}
    </div>
  );
}
export default OrdersPage;
