import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../account/components/banner";
import { useAuthConfirmUserMutation } from "./service/auth-api";

function ConfirmEmailPage() {
  let token = new URLSearchParams(window.location.search).get("token");
  
  const [authConfirmUser] = useAuthConfirmUserMutation();
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    authConfirmUser({ token })
      .unwrap()
      .then()
      .catch(() => setTokenExpired(true));
  }, [authConfirmUser, token]);

  return (
    <div className="confirm-page animate__animated animate__fadeIn">
      <Banner title="" />
      <div className="container text-center">
        <div className="content">
          {tokenExpired ? (
            <>
              <br />
              <h4 style={{ textAlign: "center" }}>
                Le lien de confirmation a expiré.
              </h4>
            </>
          ) : (
            <>
              <div className="title">
                <h2>Félicitation!</h2>
              </div>
              <br></br>
              <div className="blc">
                <h4>Votre inscription a bien été prise en compte!</h4>
              </div>
              <br />
              <Link to="/welcome-page" className="btn btn-primary ">
                Connectez-vous
              </Link>
            </>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
export default ConfirmEmailPage;
