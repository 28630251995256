import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-awesome-lightbox/build/style.css";
import { AiFillStar } from "react-icons/ai";
import { Button, Modal } from "antd";
import Banner from "../components/banner";
import { usePrestationByUserQuery } from "../prestation/service/prestation-api";
import { useUserFetchOneMutation } from "../profile/service/user-api";
import { IMAGE_S3_URL } from "../../../utils/api-config";
import LoginForm from "../../auth/login-form";
import "./styles/style.scss";

function FicheCoiffeusePage() {
  const { state } = useLocation();
  const prestation = usePrestationByUserQuery(state.professionalId);
  const [userFetchOne] = useUserFetchOneMutation();
  const [isAuth, setIsAuth] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [professional, setProfessional] = useState("");

  useEffect(() => {
    userFetchOne(state.professionalId)
      .unwrap()
      .then((res) => {
        setProfessional(res);
      })
      .catch((error) => console.log("error: ===>", error));
  }, [state, userFetchOne]);

  return (
    <>
      <div className="fiche-coiffeuse animate__animated animate__fadeIn">
        <Banner title={`${state.firstname} ${state.lastname}`} />
        <div className="container">
          <div className="services">
            <ul>
              {prestation.data &&
                prestation.data.map((item, index) => (
                  <li className="shadow-sm" key={index + item.id}>
                    {item.libelle} - {item.price} €{" "}
                  </li>
                ))}
            </ul>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="aside-left">
                {professional ? (
                  <div className="avis animate__animated animate__fadeIn">
                    <div className="userInfo">
                      <div className="avatar">
                        {professional.image ? (
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${IMAGE_S3_URL}/${professional.image})`,
                            }}
                          ></div>
                        ) : (
                          <Image
                            src="assets/img/avatar.png"
                            alt="Logo"
                            roundedCircle
                            fluid
                          />
                        )}
                      </div>
                      <div className="info">
                        <div className="text">
                          {professional.lastname} {professional.firstname}{" "}
                        </div>
                        <div className="notes">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                        <div className="text">{professional.mobilite} </div>
                        <div className="text">{professional.ville} </div>
                      </div>
                    </div>
                    {professional.description && (
                      <div>
                        <h4 className="title">A propos</h4>
                        <p>{professional.description}</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <center>
                    <Spinner animation="grow" />
                  </center>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="aside-right">
                <div className="avis animate__animated animate__fadeIn">
                  <div className="album">
                    <h4 className="title">Prestations</h4>
                    <div className="content">
                      <div className="row">
                        {prestation.data &&
                          prestation.data.map((item, index) => (
                            <div
                              className="col-6 col-lg-4 animate__animated animate__fadeIn service_container"
                              key={index}
                            >
                              <div
                                className="blc"
                                style={{
                                  backgroundImage: `url(${IMAGE_S3_URL}/${item.image})`,
                                }}
                              >
                                {user ? (
                                  <Link
                                    to={`/reservation/${state.firstname}`}
                                    state={{
                                      professionalId: state.professionalId,
                                      firstname: state.firstname,
                                      lastname: state.lastname,
                                      email: professional.email,
                                      phone: professional.phone,
                                      adresse: professional.adresse,
                                      mobilite: professional.mobilite,
                                      prestation: item,
                                    }}
                                    className="btn btn-primary btn-reserver"
                                  >
                                    Réserver
                                  </Link>
                                ) : (
                                  <Button
                                    className="btn btn-primary btn-reserver"
                                    onClick={() => setIsAuth(true)}
                                  >
                                    Réserver
                                  </Button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="auth"
          centered
          open={isAuth}
          footer={null}
          closable={true}
          onCancel={() => setIsAuth(false)}
        >
          <div className="modal-body">
            <LoginForm handleClose={setIsAuth} />
          </div>
        </Modal>
      </div>
    </>
  );
}
export default FicheCoiffeusePage;
