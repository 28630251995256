import React, { useState } from "react";
import { Button, Modal, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { API_ROOT } from "../../../utils/api-config";
import "./styles/style.scss";
import { useSelector } from "react-redux";
const { Dragger } = Upload;

export function CatalogueCreate({ handleCancel, status, handleCreate }) {
  const [fileName, setFileName] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const props = {
    name: "file",
    multiple: true,
    action: `${API_ROOT}/api/v1/upload`,
    headers: { Authorization: `Bearer ${token}` },

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        const data = [];
        for (let index = 0; index < info.fileList.length; index++) {
          data.push(info.fileList[index].name);
        }
        setFileName(data);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      className="catalogue"
      centered
      open={status}
      footer={null}
      closable={false}
      handleCreate={handleCreate}
      onCancel={handleCancel}
    >
      <div className="modal-body">
        <div className="modal-title">Ajouter</div>
        <div className="form-wrapper">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Ajouter un ou plusieurs fichiers</p>
          </Dragger>

          <br />
          <Button
            type="primary"
            htmlType="submit"
            block
            onClick={() => handleCreate(fileName)}
          >
            Ajouter
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CatalogueCreate;
