import React, { useState } from "react";
import { Alert, Button, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  useOrderCreateMutation,
  useOrderPaymentMutation,
  useOrderSendMailMutation,
} from "../account/orders/service/order-api";
import { useCartCreateMutation } from "../cart/service/cart-api";
import { clearCart } from "./slice/cart-slice";

function BoutiquePaiements({ customer, handleClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [orderNumber, setOrderNumber] = useState();
  const [orderCreate] = useOrderCreateMutation();
  const [orderPayment] = useOrderPaymentMutation();
  const [orderSendMail] = useOrderSendMailMutation();
  const [cartCreate] = useCartCreateMutation();
  const [paiementSuccess, setPaiementSuccess] = useState(false);
  const cart = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [error, setError] = useState("");

  const orderNumberGenerate = () => {
    let now = Date.now().toString();
    now += now + Math.floor(Math.random() * 10);
    return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join("-");
  };

  const handlePay = async (event) => {
    event.preventDefault();
    setError("");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error && paymentMethod) {
      setIsLoading(true);
      const data = {
        ...customer,
        order_number: orderNumberGenerate(),
        colis_number: "",
        status: "En attente",
        user: user.id,
      };
      const paiementData = {
        amount: cart.cartTotalAmount * 100,
        currency: "eur",
        description: `Type: Commande - Cliente: ${user.firstname} ${user.lastname} - Email: ${user.email} - Phone: ${user.phone}`,
        payment_method: paymentMethod.id,
        confirm: true,
      };

      const mailData = {
        coordonnees: `${user.firstname} - ${user.lastname} - Email: ${user.email} - Phone: ${user.phone}`,
      };

      orderPayment(paiementData)
        .unwrap()
        .then(() => {
          orderCreate(data)
            .unwrap()
            .then((res) => {
              setOrderNumber(res.order_number);
              for (let index = 0; index < cart.cartItems.length; index++) {
                cartCreate({ ...cart.cartItems[index], order: res.id })
                  .unwrap()
                  .then(() => {
                    setIsLoading(false);
                    setPaiementSuccess(true);
                    dispatch(clearCart());
                    orderSendMail({
                      ...mailData,
                      libelle: cart.cartItems[index].libelle,
                      order: res.order_number,
                    });
                  })
                  .catch((error) => {
                    console.log("error: ===>", error);
                    setIsLoading(false);
                  });
              }
            })
            .catch((error) => {
              console.log("error: ===>", error);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setError(error.message);
    }
  };

  return (
    <div>
      {!paiementSuccess ? (
        <div>
          <div className="img-stripe">
            <Image
              src={window.location.origin + `/assets/img/stripe-payment.png`}
              alt="img"
              preview={false}
              fluid
            />
          </div>
          <div className="total">
            <span>Total:</span>
            <span>{cart.cartTotalAmount} €</span>
          </div>
          <form onSubmit={handlePay}>
            <div className="input-stripe">
              <CardElement />
            </div>

            <button
              className="btn btn-primary btn-lg mt-4"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? `Chargement...` : `Payer`}
            </button>
            {error && <Alert message={error} type="error" showIcon />}
          </form>
        </div>
      ) : (
        <div className="modal-body">
          <img
            src="assets/img/icon-professionalisme.png"
            alt="icon-success"
            style={{ width: "35%" }}
          />
          <p className="my-4">
            MERCI! Votre commande N° {orderNumber} a été enregistrée. Un e-mail
            de confirmation devrait vous parvenir dans les prochaines 24h.
          </p>
          <Button className="btn btn-primary" onClick={() => handleClose()}>
            Fermer
          </Button>
        </div>
      )}
    </div>
  );
}
export default BoutiquePaiements;
