import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";

export const adresseCreateAsync = createAsyncThunk(
    "adresse/adresseCreateAsync",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/adresse/`,
                data: payload
            });
            dispatch(adresseFetchAsync({ id: payload.userId }))
            dispatch(showAlert({ isSuccess: true, successMessage: "Ajouté à votre liste d'adresses" }))
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);


export const adresseDeleteAsync = createAsyncThunk(
    "adresse/adresseDeleteAsync",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${baseURL}api/adresse/delete/`,
                data: payload
            });
            dispatch(adresseFetchAsync({ id: payload.userId }))
            dispatch(showAlert({ isSuccess: true, successMessage: "Supprimé de votre liste d'adresses" }))
            return response.data;
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

export const adresseFetchAsync = createAsyncThunk(
    "adresse/adresseFetchAsync",
    async (payload) => {
        try {
            const response = await axios.get(baseURL + "api/adresse/" + payload.id);
            return response.data.adresses;
        } catch (error) {
            console.log(error);
        }
    }
);

const adresseSlice = createSlice({
    name: "adresse",
    initialState: {
        status: 'idle',
        adresse: "",
        adresses: [],
        isSuccess: false,
        isFetching: false,
    },
    extraReducers: {
        [adresseCreateAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [adresseCreateAsync.fulfilled]: (state) => {
            state.status = "success";
            state.isFetching = false
        },
        [adresseCreateAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },

        [adresseFetchAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [adresseFetchAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.isFetching = false;
            state.adresses = payload;
        },
        [adresseFetchAsync.rejected]: (state) => {
            state.status = 'failed'
            state.isFetching = false
        },

        [adresseDeleteAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [adresseDeleteAsync.fulfilled]: (state) => {
            state.status = "success";
            state.isFetching = false
        },
        [adresseDeleteAsync.rejected]: (state) => {
            state.status = 'failed'
            state.isFetching = false
        }
    },
});

export default adresseSlice.reducer;
