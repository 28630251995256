import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    authLogin: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authLoginCustomer: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/auth/login/customer",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authLoginProfessional: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/auth/login/professional",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authRegister: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/auth/register",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authFetchToken: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/auth`,
        method: "GET",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authConfirmUser: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/auth/confirm-user",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authGetAdmin: builder.query({
      query: (payload) => ({
        url: "/api/v1/auth/admin",
        method: "GET",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});
export const {
  useAuthLoginMutation,
  useAuthLoginCustomerMutation,
  useAuthLoginProfessionalMutation,
  useAuthRegisterMutation,
  useAuthFetchTokenMutation,
  useAuthConfirmUserMutation,
  useAuthGetAdminQuery
} = authApi;
