import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import "./styles/style.scss";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./styles/style.scss";
import { useAuthGetAdminQuery } from "../auth/service/auth-api";
import ConversationList from "./components/ConversationList/ConversationList";
import ChatBox from "../../components/chatBox/ChatBox";
import { message } from "antd";

const Message = () => {
  const { user } = useSelector((state) => state.auth);
  const [activeConversation, setActiveConversation] = useState(null);
  const { data } = useAuthGetAdminQuery();
  const chatClient = useContext(UserContext);

  const handleContactAdmin = async () => {
    if (data.chatAccount) {
      let conversation = null;
      try {
        setActiveConversation(null)
        conversation = await chatClient.getConversationByUniqueName(
          `${data.firstname + "-" + data.lastname} avec ${user.firstname + "-" + user.lastname}`
        );
        setActiveConversation(conversation);
       
      } catch (error) {
        console.log('handleContactAdmin', error);
        conversation = await chatClient.createConversation({
          attributes: {
            user1: {
              id: user.id,
              name: `${user.firstname} ${user.lastname}`,
              email: user.email,
            },
            user2: {
              id: data.id,
              name: `${data.firstname} ${data.lastname}`,
              email: data.email,
            },
            admin: true,
          },
          friendlyName: "Admin-Chat",
          uniqueName: `${data.firstname + "-" + data.lastname} avec ${user.firstname + "-" + user.lastname}`,
        });
        await Promise.all([
          conversation.join(),
          conversation.add(`${data.email}`),
        ]);
        setActiveConversation(conversation);
      }
    } else {
      message.error("Other User has not activated their chat account yet!.");
    }
  };

  const updateUnreadMessages = async () => {
    activeConversation.lastMessage &&
      (await activeConversation.updateLastReadMessageIndex(
        activeConversation.lastMessage?.index
      ));
  };

  useEffect(() => {
    if (activeConversation) updateUnreadMessages();
    // eslint-disable-next-line
  }, [activeConversation]);

  return (
    <div>
      {activeConversation && (
        <button
          className="back_button"
          onClick={() => setActiveConversation(null)}
        >
          <ArrowLeftOutlined /> Back
        </button>
      )}

      {!activeConversation ? (
        <ConversationList
          setActiveConversation={setActiveConversation}
          user={user}
        />
      ) : (
        <ChatBox
          activeConversation={activeConversation}
          chatClient={chatClient}
          closeButton={false}
        />
      )}
      <button className="contact_admin_button" onClick={handleContactAdmin}>
        Contacter Admin
      </button>
    </div>
  );
};

export default Message;
