const cities = [
    {
        "id": 39234,
        "name": "Abbaretz",
        "latitude": "47.55314000",
        "longitude": "-1.53200000"
    },
    {
        "id": 39235,
        "name": "Abbeville",
        "latitude": "50.10521000",
        "longitude": "1.83547000"
    },
    {
        "id": 39236,
        "name": "Abeilhan",
        "latitude": "43.44925000",
        "longitude": "3.29529000"
    },
    {
        "id": 39237,
        "name": "Abilly",
        "latitude": "46.93333000",
        "longitude": "0.73333000"
    },
    {
        "id": 39238,
        "name": "Ablain-Saint-Nazaire",
        "latitude": "50.39320000",
        "longitude": "2.70880000"
    },
    {
        "id": 39239,
        "name": "Ableiges",
        "latitude": "49.08932000",
        "longitude": "1.98154000"
    },
    {
        "id": 39240,
        "name": "Ablis",
        "latitude": "48.51720000",
        "longitude": "1.83624000"
    },
    {
        "id": 39241,
        "name": "Ablon",
        "latitude": "49.39214000",
        "longitude": "0.29584000"
    },
    {
        "id": 39242,
        "name": "Ablon-sur-Seine",
        "latitude": "48.72732000",
        "longitude": "2.42686000"
    },
    {
        "id": 39243,
        "name": "Abondance",
        "latitude": "46.27874000",
        "longitude": "6.72105000"
    },
    {
        "id": 39244,
        "name": "Abondant",
        "latitude": "48.78590000",
        "longitude": "1.44006000"
    },
    {
        "id": 39245,
        "name": "Abreschviller",
        "latitude": "48.63698000",
        "longitude": "7.09607000"
    },
    {
        "id": 39246,
        "name": "Abrest",
        "latitude": "46.09859000",
        "longitude": "3.44461000"
    },
    {
        "id": 39247,
        "name": "Abscon",
        "latitude": "50.33333000",
        "longitude": "3.30000000"
    },
    {
        "id": 39248,
        "name": "Abzac",
        "latitude": "45.01667000",
        "longitude": "-0.13333000"
    },
    {
        "id": 39249,
        "name": "Achenheim",
        "latitude": "48.58070000",
        "longitude": "7.62803000"
    },
    {
        "id": 39252,
        "name": "Achères",
        "latitude": "48.96115000",
        "longitude": "2.06882000"
    },
    {
        "id": 39253,
        "name": "Achères-la-Forêt",
        "latitude": "48.35458000",
        "longitude": "2.57035000"
    },
    {
        "id": 39250,
        "name": "Achicourt",
        "latitude": "50.27429000",
        "longitude": "2.75779000"
    },
    {
        "id": 39251,
        "name": "Achiet-le-Grand",
        "latitude": "50.13067000",
        "longitude": "2.78119000"
    },
    {
        "id": 39254,
        "name": "Acigné",
        "latitude": "48.13333000",
        "longitude": "-1.53704000"
    },
    {
        "id": 39255,
        "name": "Acquigny",
        "latitude": "49.17350000",
        "longitude": "1.17650000"
    },
    {
        "id": 39256,
        "name": "Afa",
        "latitude": "41.98396000",
        "longitude": "8.79833000"
    },
    {
        "id": 39257,
        "name": "Agde",
        "latitude": "43.31083000",
        "longitude": "3.47583000"
    },
    {
        "id": 39258,
        "name": "Agen",
        "latitude": "44.19991000",
        "longitude": "0.62664000"
    },
    {
        "id": 39259,
        "name": "Agneaux",
        "latitude": "49.11905000",
        "longitude": "-1.10610000"
    },
    {
        "id": 39260,
        "name": "Agnetz",
        "latitude": "49.38118000",
        "longitude": "2.38621000"
    },
    {
        "id": 39261,
        "name": "Agny",
        "latitude": "50.25914000",
        "longitude": "2.76002000"
    },
    {
        "id": 39262,
        "name": "Agon-Coutainville",
        "latitude": "49.04167000",
        "longitude": "-1.57500000"
    },
    {
        "id": 39263,
        "name": "Agonac",
        "latitude": "45.29248000",
        "longitude": "0.75025000"
    },
    {
        "id": 39264,
        "name": "Ahetze",
        "latitude": "43.40432000",
        "longitude": "-1.57191000"
    },
    {
        "id": 39265,
        "name": "Ahuillé",
        "latitude": "48.02086000",
        "longitude": "-0.86906000"
    },
    {
        "id": 39266,
        "name": "Ahun",
        "latitude": "46.08594000",
        "longitude": "2.04479000"
    },
    {
        "id": 39267,
        "name": "Ahuy",
        "latitude": "47.36944000",
        "longitude": "5.02089000"
    },
    {
        "id": 39268,
        "name": "Aiffres",
        "latitude": "46.29449000",
        "longitude": "-0.42101000"
    },
    {
        "id": 39269,
        "name": "Aiglemont",
        "latitude": "49.78031000",
        "longitude": "4.76483000"
    },
    {
        "id": 39270,
        "name": "Aigné",
        "latitude": "48.06471000",
        "longitude": "0.11908000"
    },
    {
        "id": 39271,
        "name": "Aigre",
        "latitude": "45.89377000",
        "longitude": "0.00963000"
    },
    {
        "id": 39272,
        "name": "Aigrefeuille-sur-Maine",
        "latitude": "47.07888000",
        "longitude": "-1.40254000"
    },
    {
        "id": 39273,
        "name": "Aigueblanche",
        "latitude": "45.50455000",
        "longitude": "6.50184000"
    },
    {
        "id": 39274,
        "name": "Aiguefonde",
        "latitude": "43.49394000",
        "longitude": "2.31686000"
    },
    {
        "id": 39275,
        "name": "Aigueperse",
        "latitude": "46.02255000",
        "longitude": "3.20228000"
    },
    {
        "id": 39276,
        "name": "Aigues-Mortes",
        "latitude": "43.56683000",
        "longitude": "4.19068000"
    },
    {
        "id": 39277,
        "name": "Aigues-Vives",
        "latitude": "43.73790000",
        "longitude": "4.18066000"
    },
    {
        "id": 39278,
        "name": "Aiguillon",
        "latitude": "44.29893000",
        "longitude": "0.34020000"
    },
    {
        "id": 39279,
        "name": "Aigurande",
        "latitude": "46.43397000",
        "longitude": "1.83026000"
    },
    {
        "id": 39280,
        "name": "Aillant-sur-Tholon",
        "latitude": "47.87426000",
        "longitude": "3.35049000"
    },
    {
        "id": 39281,
        "name": "Aillevillers-et-Lyaumont",
        "latitude": "47.92033000",
        "longitude": "6.33775000"
    },
    {
        "id": 39282,
        "name": "Ailly-sur-Noye",
        "latitude": "49.75707000",
        "longitude": "2.36367000"
    },
    {
        "id": 39283,
        "name": "Ailly-sur-Somme",
        "latitude": "49.92886000",
        "longitude": "2.19615000"
    },
    {
        "id": 39284,
        "name": "Aimargues",
        "latitude": "43.68448000",
        "longitude": "4.20999000"
    },
    {
        "id": 39285,
        "name": "Aime",
        "latitude": "45.55559000",
        "longitude": "6.65042000"
    },
    {
        "id": 39286,
        "name": "Ainay-le-Château",
        "latitude": "46.71125000",
        "longitude": "2.69238000"
    },
    {
        "id": 39287,
        "name": "Airaines",
        "latitude": "49.96826000",
        "longitude": "1.94266000"
    },
    {
        "id": 39288,
        "name": "Aire-sur-la-Lys",
        "latitude": "50.63871000",
        "longitude": "2.39876000"
    },
    {
        "id": 39289,
        "name": "Airvault",
        "latitude": "46.82533000",
        "longitude": "-0.13634000"
    },
    {
        "id": 39290,
        "name": "Aiserey",
        "latitude": "47.17229000",
        "longitude": "5.16260000"
    },
    {
        "id": 39291,
        "name": "Aiton",
        "latitude": "45.56123000",
        "longitude": "6.25955000"
    },
    {
        "id": 39293,
        "name": "Aix-en-Othe",
        "latitude": "48.22391000",
        "longitude": "3.73425000"
    },
    {
        "id": 39294,
        "name": "Aix-en-Provence",
        "latitude": "43.52830000",
        "longitude": "5.44973000"
    },
    {
        "id": 39295,
        "name": "Aix-les-Bains",
        "latitude": "45.69173000",
        "longitude": "5.90863000"
    },
    {
        "id": 39292,
        "name": "Aix-Noulette",
        "latitude": "50.42568000",
        "longitude": "2.70554000"
    },
    {
        "id": 39296,
        "name": "Aixe-sur-Vienne",
        "latitude": "45.79862000",
        "longitude": "1.13884000"
    },
    {
        "id": 39297,
        "name": "Aizenay",
        "latitude": "46.74004000",
        "longitude": "-1.60834000"
    },
    {
        "id": 39298,
        "name": "Ajaccio",
        "latitude": "41.91886000",
        "longitude": "8.73812000"
    },
    {
        "id": 39299,
        "name": "Ajain",
        "latitude": "46.20635000",
        "longitude": "1.99850000"
    },
    {
        "id": 39300,
        "name": "Alata",
        "latitude": "41.97636000",
        "longitude": "8.74208000"
    },
    {
        "id": 39301,
        "name": "Alba-la-Romaine",
        "latitude": "44.55537000",
        "longitude": "4.59846000"
    },
    {
        "id": 39302,
        "name": "Albens",
        "latitude": "45.78786000",
        "longitude": "5.94528000"
    },
    {
        "id": 39303,
        "name": "Albert",
        "latitude": "50.00091000",
        "longitude": "2.65096000"
    },
    {
        "id": 39304,
        "name": "Albertville",
        "latitude": "45.67452000",
        "longitude": "6.39061000"
    },
    {
        "id": 39305,
        "name": "Albi",
        "latitude": "43.92980000",
        "longitude": "2.14800000"
    },
    {
        "id": 39306,
        "name": "Albias",
        "latitude": "44.09049000",
        "longitude": "1.44821000"
    },
    {
        "id": 39307,
        "name": "Albigny-sur-Saône",
        "latitude": "45.86667000",
        "longitude": "4.83333000"
    },
    {
        "id": 39308,
        "name": "Albitreccia",
        "latitude": "41.86301000",
        "longitude": "8.94262000"
    },
    {
        "id": 39309,
        "name": "Alby-sur-Chéran",
        "latitude": "45.81667000",
        "longitude": "6.01667000"
    },
    {
        "id": 39310,
        "name": "Alençon",
        "latitude": "48.43476000",
        "longitude": "0.09311000"
    },
    {
        "id": 39342,
        "name": "Alénya",
        "latitude": "42.63875000",
        "longitude": "2.98148000"
    },
    {
        "id": 39343,
        "name": "Aléria",
        "latitude": "42.10431000",
        "longitude": "9.51265000"
    },
    {
        "id": 39341,
        "name": "Alès",
        "latitude": "44.12489000",
        "longitude": "4.08082000"
    },
    {
        "id": 39311,
        "name": "Alfortville",
        "latitude": "48.80575000",
        "longitude": "2.42040000"
    },
    {
        "id": 39312,
        "name": "Algolsheim",
        "latitude": "48.00629000",
        "longitude": "7.55945000"
    },
    {
        "id": 39313,
        "name": "Algrange",
        "latitude": "49.36276000",
        "longitude": "6.05094000"
    },
    {
        "id": 39314,
        "name": "Alignan-du-Vent",
        "latitude": "43.46945000",
        "longitude": "3.34165000"
    },
    {
        "id": 39315,
        "name": "Alissas",
        "latitude": "44.71253000",
        "longitude": "4.62936000"
    },
    {
        "id": 39316,
        "name": "Alixan",
        "latitude": "44.97426000",
        "longitude": "5.02850000"
    },
    {
        "id": 39317,
        "name": "Alizay",
        "latitude": "49.32158000",
        "longitude": "1.17854000"
    },
    {
        "id": 39318,
        "name": "Allaire",
        "latitude": "47.63752000",
        "longitude": "-2.16324000"
    },
    {
        "id": 39319,
        "name": "Allan",
        "latitude": "44.49713000",
        "longitude": "4.79068000"
    },
    {
        "id": 39320,
        "name": "Allanche",
        "latitude": "45.22907000",
        "longitude": "2.93449000"
    },
    {
        "id": 39321,
        "name": "Allassac",
        "latitude": "45.25840000",
        "longitude": "1.47550000"
    },
    {
        "id": 39322,
        "name": "Allauch",
        "latitude": "43.33573000",
        "longitude": "5.48201000"
    },
    {
        "id": 39333,
        "name": "Allègre",
        "latitude": "45.19959000",
        "longitude": "3.71174000"
    },
    {
        "id": 39323,
        "name": "Alleins",
        "latitude": "43.70387000",
        "longitude": "5.16203000"
    },
    {
        "id": 39324,
        "name": "Allennes-les-Marais",
        "latitude": "50.53754000",
        "longitude": "2.95361000"
    },
    {
        "id": 39325,
        "name": "Allevard",
        "latitude": "45.39449000",
        "longitude": "6.07519000"
    },
    {
        "id": 39326,
        "name": "Allex",
        "latitude": "44.76765000",
        "longitude": "4.89837000"
    },
    {
        "id": 39328,
        "name": "Allières-et-Risset",
        "latitude": "45.09934000",
        "longitude": "5.67924000"
    },
    {
        "id": 39327,
        "name": "Allinges",
        "latitude": "46.33547000",
        "longitude": "6.46350000"
    },
    {
        "id": 39329,
        "name": "Allonne",
        "latitude": "49.40863000",
        "longitude": "2.11427000"
    },
    {
        "id": 39330,
        "name": "Allonnes",
        "latitude": "47.29338000",
        "longitude": "0.02458000"
    },
    {
        "id": 39331,
        "name": "Allonzier-la-Caille",
        "latitude": "46.00117000",
        "longitude": "6.11865000"
    },
    {
        "id": 39332,
        "name": "Allouagne",
        "latitude": "50.53167000",
        "longitude": "2.51194000"
    },
    {
        "id": 39335,
        "name": "Alpes-de-Haute-Provence",
        "latitude": "44.09829000",
        "longitude": "6.26537000"
    },
    {
        "id": 39334,
        "name": "Alpes-Maritimes",
        "latitude": "43.91307000",
        "longitude": "7.20436000"
    },
    {
        "id": 39336,
        "name": "Alsting",
        "latitude": "49.18175000",
        "longitude": "6.99255000"
    },
    {
        "id": 39337,
        "name": "Althen-des-Paluds",
        "latitude": "44.00405000",
        "longitude": "4.95861000"
    },
    {
        "id": 39338,
        "name": "Altkirch",
        "latitude": "47.62417000",
        "longitude": "7.23954000"
    },
    {
        "id": 39339,
        "name": "Altorf",
        "latitude": "48.52166000",
        "longitude": "7.52787000"
    },
    {
        "id": 39340,
        "name": "Alzonne",
        "latitude": "43.25338000",
        "longitude": "2.17808000"
    },
    {
        "id": 39344,
        "name": "Amancy",
        "latitude": "46.07294000",
        "longitude": "6.32890000"
    },
    {
        "id": 39345,
        "name": "Amanlis",
        "latitude": "48.00752000",
        "longitude": "-1.47677000"
    },
    {
        "id": 39346,
        "name": "Amanvillers",
        "latitude": "49.16784000",
        "longitude": "6.04367000"
    },
    {
        "id": 39347,
        "name": "Ambarès-et-Lagrave",
        "latitude": "44.91667000",
        "longitude": "-0.48333000"
    },
    {
        "id": 39348,
        "name": "Ambazac",
        "latitude": "45.95983000",
        "longitude": "1.40063000"
    },
    {
        "id": 39360,
        "name": "Ambérieu-en-Bugey",
        "latitude": "45.95932000",
        "longitude": "5.35160000"
    },
    {
        "id": 39361,
        "name": "Ambérieux-en-Dombes",
        "latitude": "45.99894000",
        "longitude": "4.90126000"
    },
    {
        "id": 39349,
        "name": "Ambert",
        "latitude": "45.54944000",
        "longitude": "3.74164000"
    },
    {
        "id": 39359,
        "name": "Ambès",
        "latitude": "45.01127000",
        "longitude": "-0.53219000"
    },
    {
        "id": 39350,
        "name": "Ambierle",
        "latitude": "46.10558000",
        "longitude": "3.89323000"
    },
    {
        "id": 39351,
        "name": "Ambillou",
        "latitude": "47.45100000",
        "longitude": "0.44730000"
    },
    {
        "id": 39352,
        "name": "Amblainville",
        "latitude": "49.20443000",
        "longitude": "2.12242000"
    },
    {
        "id": 39353,
        "name": "Ambleny",
        "latitude": "49.38079000",
        "longitude": "3.18447000"
    },
    {
        "id": 39354,
        "name": "Ambleteuse",
        "latitude": "50.80729000",
        "longitude": "1.60388000"
    },
    {
        "id": 39355,
        "name": "Amboise",
        "latitude": "47.41249000",
        "longitude": "0.98266000"
    },
    {
        "id": 39356,
        "name": "Ambon",
        "latitude": "47.55367000",
        "longitude": "-2.55512000"
    },
    {
        "id": 39357,
        "name": "Ambrières-les-Vallées",
        "latitude": "48.40000000",
        "longitude": "-0.63333000"
    },
    {
        "id": 39358,
        "name": "Ambronay",
        "latitude": "46.00467000",
        "longitude": "5.36273000"
    },
    {
        "id": 39362,
        "name": "Amfreville",
        "latitude": "49.25000000",
        "longitude": "-0.23333000"
    },
    {
        "id": 39363,
        "name": "Amfreville-la-Mi-Voie",
        "latitude": "49.40755000",
        "longitude": "1.13871000"
    },
    {
        "id": 39364,
        "name": "Amiens",
        "latitude": "49.90000000",
        "longitude": "2.30000000"
    },
    {
        "id": 39365,
        "name": "Amilly",
        "latitude": "47.97281000",
        "longitude": "2.77186000"
    },
    {
        "id": 39366,
        "name": "Ammerschwihr",
        "latitude": "48.12548000",
        "longitude": "7.28282000"
    },
    {
        "id": 39367,
        "name": "Amnéville",
        "latitude": "49.25671000",
        "longitude": "6.13414000"
    },
    {
        "id": 39368,
        "name": "Amou",
        "latitude": "43.59257000",
        "longitude": "-0.74676000"
    },
    {
        "id": 39369,
        "name": "Amplepuis",
        "latitude": "45.97260000",
        "longitude": "4.33030000"
    },
    {
        "id": 39370,
        "name": "Ampuis",
        "latitude": "45.48925000",
        "longitude": "4.81001000"
    },
    {
        "id": 39371,
        "name": "Ancenis",
        "latitude": "47.36667000",
        "longitude": "-1.16667000"
    },
    {
        "id": 39372,
        "name": "Ancerville",
        "latitude": "48.63574000",
        "longitude": "5.02091000"
    },
    {
        "id": 39375,
        "name": "Ancône",
        "latitude": "44.58107000",
        "longitude": "4.72711000"
    },
    {
        "id": 39373,
        "name": "Ancy-le-Franc",
        "latitude": "47.77586000",
        "longitude": "4.16361000"
    },
    {
        "id": 39374,
        "name": "Ancy-sur-Moselle",
        "latitude": "49.05689000",
        "longitude": "6.05775000"
    },
    {
        "id": 39376,
        "name": "Andance",
        "latitude": "45.24059000",
        "longitude": "4.79916000"
    },
    {
        "id": 39377,
        "name": "Andancette",
        "latitude": "45.24858000",
        "longitude": "4.80860000"
    },
    {
        "id": 39378,
        "name": "Andard",
        "latitude": "47.45659000",
        "longitude": "-0.39752000"
    },
    {
        "id": 39393,
        "name": "Andé",
        "latitude": "49.23163000",
        "longitude": "1.24088000"
    },
    {
        "id": 39379,
        "name": "Andelnans",
        "latitude": "47.60267000",
        "longitude": "6.86621000"
    },
    {
        "id": 39380,
        "name": "Andernos-les-Bains",
        "latitude": "44.74572000",
        "longitude": "-1.10355000"
    },
    {
        "id": 39381,
        "name": "Andeville",
        "latitude": "49.26078000",
        "longitude": "2.16427000"
    },
    {
        "id": 39382,
        "name": "Andilly",
        "latitude": "46.25425000",
        "longitude": "-1.02672000"
    },
    {
        "id": 39383,
        "name": "Andilly",
        "latitude": "49.00935000",
        "longitude": "2.30240000"
    },
    {
        "id": 39384,
        "name": "Andlau",
        "latitude": "48.38607000",
        "longitude": "7.41697000"
    },
    {
        "id": 39385,
        "name": "Andolsheim",
        "latitude": "48.06179000",
        "longitude": "7.41637000"
    },
    {
        "id": 39386,
        "name": "Andouillé",
        "latitude": "48.17685000",
        "longitude": "-0.78364000"
    },
    {
        "id": 39387,
        "name": "Andres",
        "latitude": "50.86556000",
        "longitude": "1.92094000"
    },
    {
        "id": 39388,
        "name": "Andrest",
        "latitude": "43.31998000",
        "longitude": "0.06405000"
    },
    {
        "id": 39390,
        "name": "Andrésy",
        "latitude": "48.98235000",
        "longitude": "2.05687000"
    },
    {
        "id": 39389,
        "name": "Andrezé",
        "latitude": "47.17155000",
        "longitude": "-0.95239000"
    },
    {
        "id": 39391,
        "name": "Andrézieux-Bouthéon",
        "latitude": "45.52625000",
        "longitude": "4.26021000"
    },
    {
        "id": 39392,
        "name": "Anduze",
        "latitude": "44.05409000",
        "longitude": "3.98545000"
    },
    {
        "id": 39394,
        "name": "Anet",
        "latitude": "48.85642000",
        "longitude": "1.43981000"
    },
    {
        "id": 39395,
        "name": "Anetz",
        "latitude": "47.38059000",
        "longitude": "-1.10583000"
    },
    {
        "id": 39396,
        "name": "Angers",
        "latitude": "47.47381000",
        "longitude": "-0.54774000"
    },
    {
        "id": 39397,
        "name": "Angerville",
        "latitude": "48.31354000",
        "longitude": "1.99935000"
    },
    {
        "id": 39398,
        "name": "Angerville-l’Orcher",
        "latitude": "49.58833000",
        "longitude": "0.28191000"
    },
    {
        "id": 39399,
        "name": "Angervilliers",
        "latitude": "48.59263000",
        "longitude": "2.06541000"
    },
    {
        "id": 39400,
        "name": "Angevillers",
        "latitude": "49.38815000",
        "longitude": "6.04430000"
    },
    {
        "id": 39401,
        "name": "Angicourt",
        "latitude": "49.31175000",
        "longitude": "2.50419000"
    },
    {
        "id": 39402,
        "name": "Angles",
        "latitude": "46.40839000",
        "longitude": "-1.40389000"
    },
    {
        "id": 39403,
        "name": "Anglet",
        "latitude": "43.47664000",
        "longitude": "-1.51346000"
    },
    {
        "id": 39405,
        "name": "Angoulême",
        "latitude": "45.65000000",
        "longitude": "0.15000000"
    },
    {
        "id": 39404,
        "name": "Angoulins",
        "latitude": "46.10526000",
        "longitude": "-1.10713000"
    },
    {
        "id": 39406,
        "name": "Angres",
        "latitude": "50.40572000",
        "longitude": "2.76054000"
    },
    {
        "id": 39407,
        "name": "Angresse",
        "latitude": "43.65809000",
        "longitude": "-1.37191000"
    },
    {
        "id": 39408,
        "name": "Angy",
        "latitude": "49.32975000",
        "longitude": "2.32854000"
    },
    {
        "id": 39409,
        "name": "Anhiers",
        "latitude": "50.40737000",
        "longitude": "3.15512000"
    },
    {
        "id": 39410,
        "name": "Aniane",
        "latitude": "43.68448000",
        "longitude": "3.58747000"
    },
    {
        "id": 39411,
        "name": "Aniche",
        "latitude": "50.32995000",
        "longitude": "3.25346000"
    },
    {
        "id": 39412,
        "name": "Anizy-le-Château",
        "latitude": "49.50678000",
        "longitude": "3.45119000"
    },
    {
        "id": 39413,
        "name": "Annay",
        "latitude": "50.46303000",
        "longitude": "2.88122000"
    },
    {
        "id": 39414,
        "name": "Annecy",
        "latitude": "45.90878000",
        "longitude": "6.12565000"
    },
    {
        "id": 39415,
        "name": "Annecy-le-Vieux",
        "latitude": "45.91971000",
        "longitude": "6.14393000"
    },
    {
        "id": 39416,
        "name": "Annemasse",
        "latitude": "46.19439000",
        "longitude": "6.23775000"
    },
    {
        "id": 39417,
        "name": "Annequin",
        "latitude": "50.50525000",
        "longitude": "2.72019000"
    },
    {
        "id": 39418,
        "name": "Annesse-et-Beaulieu",
        "latitude": "45.16416000",
        "longitude": "0.57193000"
    },
    {
        "id": 39419,
        "name": "Annet-sur-Marne",
        "latitude": "48.92669000",
        "longitude": "2.71959000"
    },
    {
        "id": 39420,
        "name": "Anneyron",
        "latitude": "45.27220000",
        "longitude": "4.88626000"
    },
    {
        "id": 39421,
        "name": "Annezin",
        "latitude": "50.53192000",
        "longitude": "2.61785000"
    },
    {
        "id": 39422,
        "name": "Annoeullin",
        "latitude": "50.52925000",
        "longitude": "2.93180000"
    },
    {
        "id": 39423,
        "name": "Annonay",
        "latitude": "45.23992000",
        "longitude": "4.67070000"
    },
    {
        "id": 39424,
        "name": "Annot",
        "latitude": "43.96528000",
        "longitude": "6.66879000"
    },
    {
        "id": 39425,
        "name": "Anor",
        "latitude": "49.98999000",
        "longitude": "4.10049000"
    },
    {
        "id": 39426,
        "name": "Anould",
        "latitude": "48.18526000",
        "longitude": "6.94597000"
    },
    {
        "id": 39427,
        "name": "Ansauvillers",
        "latitude": "49.56523000",
        "longitude": "2.38742000"
    },
    {
        "id": 39428,
        "name": "Anse",
        "latitude": "45.93553000",
        "longitude": "4.71933000"
    },
    {
        "id": 39429,
        "name": "Ansouis",
        "latitude": "43.73771000",
        "longitude": "5.46356000"
    },
    {
        "id": 39430,
        "name": "Anstaing",
        "latitude": "50.60473000",
        "longitude": "3.19079000"
    },
    {
        "id": 39431,
        "name": "Anthy-sur-Léman",
        "latitude": "46.35534000",
        "longitude": "6.42735000"
    },
    {
        "id": 39432,
        "name": "Antibes",
        "latitude": "43.58579000",
        "longitude": "7.10831000"
    },
    {
        "id": 39433,
        "name": "Antigny",
        "latitude": "46.62095000",
        "longitude": "-0.76988000"
    },
    {
        "id": 39434,
        "name": "Antonne-et-Trigonant",
        "latitude": "45.21271000",
        "longitude": "0.83006000"
    },
    {
        "id": 39435,
        "name": "Antony",
        "latitude": "48.75329000",
        "longitude": "2.29668000"
    },
    {
        "id": 39436,
        "name": "Antrain",
        "latitude": "48.46031000",
        "longitude": "-1.48354000"
    },
    {
        "id": 39437,
        "name": "Antran",
        "latitude": "46.85350000",
        "longitude": "0.54317000"
    },
    {
        "id": 39438,
        "name": "Anzin",
        "latitude": "50.37201000",
        "longitude": "3.50387000"
    },
    {
        "id": 39439,
        "name": "Anzin-Saint-Aubin",
        "latitude": "50.31313000",
        "longitude": "2.74732000"
    },
    {
        "id": 39440,
        "name": "Aoste",
        "latitude": "45.59423000",
        "longitude": "5.60712000"
    },
    {
        "id": 39441,
        "name": "Aouste-sur-Sye",
        "latitude": "44.71813000",
        "longitude": "5.05422000"
    },
    {
        "id": 39442,
        "name": "Appietto",
        "latitude": "42.01426000",
        "longitude": "8.76855000"
    },
    {
        "id": 39443,
        "name": "Appoigny",
        "latitude": "47.87467000",
        "longitude": "3.52524000"
    },
    {
        "id": 39444,
        "name": "Apprieu",
        "latitude": "45.39591000",
        "longitude": "5.49993000"
    },
    {
        "id": 39445,
        "name": "Apremont",
        "latitude": "46.74850000",
        "longitude": "-1.74075000"
    },
    {
        "id": 39446,
        "name": "Apt",
        "latitude": "43.87638000",
        "longitude": "5.39635000"
    },
    {
        "id": 39537,
        "name": "Arâches-la-Frasse",
        "latitude": "46.04297000",
        "longitude": "6.63145000"
    },
    {
        "id": 39447,
        "name": "Aramon",
        "latitude": "43.89174000",
        "longitude": "4.68096000"
    },
    {
        "id": 39448,
        "name": "Arbent",
        "latitude": "46.29221000",
        "longitude": "5.67890000"
    },
    {
        "id": 39449,
        "name": "Arbois",
        "latitude": "46.90311000",
        "longitude": "5.77454000"
    },
    {
        "id": 39450,
        "name": "Arbonne",
        "latitude": "43.43248000",
        "longitude": "-1.55118000"
    },
    {
        "id": 39451,
        "name": "Arbonne-la-Forêt",
        "latitude": "48.41405000",
        "longitude": "2.56677000"
    },
    {
        "id": 39452,
        "name": "Arbouans",
        "latitude": "47.49944000",
        "longitude": "6.79505000"
    },
    {
        "id": 39453,
        "name": "Arbus",
        "latitude": "43.33333000",
        "longitude": "-0.50000000"
    },
    {
        "id": 39454,
        "name": "Arc-et-Senans",
        "latitude": "47.03127000",
        "longitude": "5.77027000"
    },
    {
        "id": 39455,
        "name": "Arc-lès-Gray",
        "latitude": "47.45701000",
        "longitude": "5.58547000"
    },
    {
        "id": 39456,
        "name": "Arc-sur-Tille",
        "latitude": "47.34349000",
        "longitude": "5.18666000"
    },
    {
        "id": 39457,
        "name": "Arcachon",
        "latitude": "44.65854000",
        "longitude": "-1.16879000"
    },
    {
        "id": 39458,
        "name": "Arcangues",
        "latitude": "43.43124000",
        "longitude": "-1.52237000"
    },
    {
        "id": 39459,
        "name": "Arcey",
        "latitude": "47.52258000",
        "longitude": "6.66222000"
    },
    {
        "id": 39460,
        "name": "Archamps",
        "latitude": "46.13195000",
        "longitude": "6.12551000"
    },
    {
        "id": 39461,
        "name": "Arches",
        "latitude": "48.11856000",
        "longitude": "6.52806000"
    },
    {
        "id": 39462,
        "name": "Archettes",
        "latitude": "48.12320000",
        "longitude": "6.53723000"
    },
    {
        "id": 39463,
        "name": "Archigny",
        "latitude": "46.67411000",
        "longitude": "0.65372000"
    },
    {
        "id": 39464,
        "name": "Arcis-sur-Aube",
        "latitude": "48.53387000",
        "longitude": "4.14085000"
    },
    {
        "id": 39538,
        "name": "Arçonnay",
        "latitude": "48.39678000",
        "longitude": "0.08620000"
    },
    {
        "id": 39465,
        "name": "Arcueil",
        "latitude": "48.79993000",
        "longitude": "2.33256000"
    },
    {
        "id": 39466,
        "name": "Ardennes",
        "latitude": "49.63202000",
        "longitude": "4.65369000"
    },
    {
        "id": 39467,
        "name": "Ardentes",
        "latitude": "46.74191000",
        "longitude": "1.83428000"
    },
    {
        "id": 39468,
        "name": "Ardin",
        "latitude": "46.47512000",
        "longitude": "-0.55416000"
    },
    {
        "id": 39469,
        "name": "Ardres",
        "latitude": "50.85786000",
        "longitude": "1.98168000"
    },
    {
        "id": 39470,
        "name": "Arenc",
        "latitude": "43.31583000",
        "longitude": "5.36698000"
    },
    {
        "id": 39471,
        "name": "Arenthon",
        "latitude": "46.10697000",
        "longitude": "6.33316000"
    },
    {
        "id": 39539,
        "name": "Arès",
        "latitude": "44.76667000",
        "longitude": "-1.13333000"
    },
    {
        "id": 39472,
        "name": "Arette",
        "latitude": "43.10000000",
        "longitude": "-0.71667000"
    },
    {
        "id": 39473,
        "name": "Argancy",
        "latitude": "49.19543000",
        "longitude": "6.20157000"
    },
    {
        "id": 39474,
        "name": "Argelers",
        "latitude": "42.54714000",
        "longitude": "3.02253000"
    },
    {
        "id": 39476,
        "name": "Argelès-Gazost",
        "latitude": "43.00258000",
        "longitude": "-0.09855000"
    },
    {
        "id": 39475,
        "name": "Argeliers",
        "latitude": "43.31217000",
        "longitude": "2.91046000"
    },
    {
        "id": 39477,
        "name": "Argences",
        "latitude": "49.12635000",
        "longitude": "-0.16644000"
    },
    {
        "id": 39478,
        "name": "Argent-sur-Sauldre",
        "latitude": "47.55847000",
        "longitude": "2.44410000"
    },
    {
        "id": 39479,
        "name": "Argentan",
        "latitude": "48.74441000",
        "longitude": "-0.02023000"
    },
    {
        "id": 39480,
        "name": "Argentat",
        "latitude": "45.09325000",
        "longitude": "1.93778000"
    },
    {
        "id": 39481,
        "name": "Argenteuil",
        "latitude": "48.94788000",
        "longitude": "2.24744000"
    },
    {
        "id": 39482,
        "name": "Argenton-les-Vallées",
        "latitude": "46.98333000",
        "longitude": "-0.45000000"
    },
    {
        "id": 39483,
        "name": "Argenton-sur-Creuse",
        "latitude": "46.58997000",
        "longitude": "1.51981000"
    },
    {
        "id": 39484,
        "name": "Argentré",
        "latitude": "48.08362000",
        "longitude": "-0.64150000"
    },
    {
        "id": 39485,
        "name": "Argentré-du-Plessis",
        "latitude": "48.05697000",
        "longitude": "-1.14601000"
    },
    {
        "id": 39486,
        "name": "Arinthod",
        "latitude": "46.39339000",
        "longitude": "5.56616000"
    },
    {
        "id": 39487,
        "name": "Arlanc",
        "latitude": "45.41389000",
        "longitude": "3.72528000"
    },
    {
        "id": 39488,
        "name": "Arles",
        "latitude": "42.45654000",
        "longitude": "2.63457000"
    },
    {
        "id": 39489,
        "name": "Arles",
        "latitude": "43.67681000",
        "longitude": "4.63031000"
    },
    {
        "id": 39490,
        "name": "Arleux",
        "latitude": "50.28037000",
        "longitude": "3.10448000"
    },
    {
        "id": 39491,
        "name": "Armbouts-Cappel",
        "latitude": "50.97682000",
        "longitude": "2.35352000"
    },
    {
        "id": 39492,
        "name": "Armenonville-les-Gâtineaux",
        "latitude": "48.54365000",
        "longitude": "1.64750000"
    },
    {
        "id": 39493,
        "name": "Armentières",
        "latitude": "50.68568000",
        "longitude": "2.88214000"
    },
    {
        "id": 39494,
        "name": "Armentières-en-Brie",
        "latitude": "48.97775000",
        "longitude": "3.02073000"
    },
    {
        "id": 39495,
        "name": "Armissan",
        "latitude": "43.18778000",
        "longitude": "3.09660000"
    },
    {
        "id": 39496,
        "name": "Armoy",
        "latitude": "46.35079000",
        "longitude": "6.51703000"
    },
    {
        "id": 39498,
        "name": "Arnac-la-Poste",
        "latitude": "46.26597000",
        "longitude": "1.37375000"
    },
    {
        "id": 39497,
        "name": "Arnac-Pompadour",
        "latitude": "45.40975000",
        "longitude": "1.36993000"
    },
    {
        "id": 39499,
        "name": "Arnage",
        "latitude": "47.92616000",
        "longitude": "0.18782000"
    },
    {
        "id": 39500,
        "name": "Arnas",
        "latitude": "46.02361000",
        "longitude": "4.70830000"
    },
    {
        "id": 39501,
        "name": "Arnay-le-Duc",
        "latitude": "47.13202000",
        "longitude": "4.48595000"
    },
    {
        "id": 39504,
        "name": "Arnèke",
        "latitude": "50.83219000",
        "longitude": "2.40943000"
    },
    {
        "id": 39502,
        "name": "Arnières-sur-Iton",
        "latitude": "48.99678000",
        "longitude": "1.10384000"
    },
    {
        "id": 39503,
        "name": "Arnouville",
        "latitude": "48.98333000",
        "longitude": "2.41667000"
    },
    {
        "id": 39505,
        "name": "Aron",
        "latitude": "48.29800000",
        "longitude": "-0.55980000"
    },
    {
        "id": 39506,
        "name": "Arpajon",
        "latitude": "48.58875000",
        "longitude": "2.24672000"
    },
    {
        "id": 39507,
        "name": "Arpajon-sur-Cère",
        "latitude": "44.90391000",
        "longitude": "2.45664000"
    },
    {
        "id": 39508,
        "name": "Arques",
        "latitude": "50.73375000",
        "longitude": "2.30237000"
    },
    {
        "id": 39509,
        "name": "Arques-la-Bataille",
        "latitude": "49.88122000",
        "longitude": "1.12875000"
    },
    {
        "id": 39510,
        "name": "Arradon",
        "latitude": "47.62709000",
        "longitude": "-2.82150000"
    },
    {
        "id": 39511,
        "name": "Arras",
        "latitude": "50.29301000",
        "longitude": "2.78186000"
    },
    {
        "id": 39512,
        "name": "Arrou",
        "latitude": "48.09768000",
        "longitude": "1.12851000"
    },
    {
        "id": 39513,
        "name": "Ars-en-Ré",
        "latitude": "46.20555000",
        "longitude": "-1.52720000"
    },
    {
        "id": 39514,
        "name": "Ars-sur-Formans",
        "latitude": "45.99491000",
        "longitude": "4.81986000"
    },
    {
        "id": 39515,
        "name": "Ars-sur-Moselle",
        "latitude": "49.07791000",
        "longitude": "6.07420000"
    },
    {
        "id": 39516,
        "name": "Arsac",
        "latitude": "44.99688000",
        "longitude": "-0.68976000"
    },
    {
        "id": 39517,
        "name": "Art-sur-Meurthe",
        "latitude": "48.65750000",
        "longitude": "6.26708000"
    },
    {
        "id": 39518,
        "name": "Artannes-sur-Indre",
        "latitude": "47.27451000",
        "longitude": "0.60036000"
    },
    {
        "id": 39519,
        "name": "Artas",
        "latitude": "45.53717000",
        "longitude": "5.16352000"
    },
    {
        "id": 39520,
        "name": "Artemare",
        "latitude": "45.87441000",
        "longitude": "5.69366000"
    },
    {
        "id": 39521,
        "name": "Artenay",
        "latitude": "48.08246000",
        "longitude": "1.88098000"
    },
    {
        "id": 39522,
        "name": "Arthaz-Pont-Notre-Dame",
        "latitude": "46.15941000",
        "longitude": "6.26598000"
    },
    {
        "id": 39526,
        "name": "Arthès",
        "latitude": "43.95745000",
        "longitude": "2.21130000"
    },
    {
        "id": 39523,
        "name": "Arthez-de-Béarn",
        "latitude": "43.46667000",
        "longitude": "-0.60000000"
    },
    {
        "id": 39524,
        "name": "Arthon",
        "latitude": "46.69361000",
        "longitude": "1.69857000"
    },
    {
        "id": 39525,
        "name": "Arthon-en-Retz",
        "latitude": "47.11586000",
        "longitude": "-1.94260000"
    },
    {
        "id": 39527,
        "name": "Artiguelouve",
        "latitude": "43.31667000",
        "longitude": "-0.46667000"
    },
    {
        "id": 39528,
        "name": "Artigues-près-Bordeaux",
        "latitude": "44.85000000",
        "longitude": "-0.51667000"
    },
    {
        "id": 39529,
        "name": "Artix",
        "latitude": "43.39667000",
        "longitude": "-0.57256000"
    },
    {
        "id": 39530,
        "name": "Artres",
        "latitude": "50.29482000",
        "longitude": "3.54013000"
    },
    {
        "id": 39531,
        "name": "Arudy",
        "latitude": "43.10640000",
        "longitude": "-0.43333000"
    },
    {
        "id": 39532,
        "name": "Arvert",
        "latitude": "45.74486000",
        "longitude": "-1.12573000"
    },
    {
        "id": 39533,
        "name": "Arveyres",
        "latitude": "44.88333000",
        "longitude": "-0.28333000"
    },
    {
        "id": 39534,
        "name": "Arzano",
        "latitude": "47.90136000",
        "longitude": "-3.44032000"
    },
    {
        "id": 39535,
        "name": "Arzens",
        "latitude": "43.19838000",
        "longitude": "2.20954000"
    },
    {
        "id": 39536,
        "name": "Arzon",
        "latitude": "47.54852000",
        "longitude": "-2.88989000"
    },
    {
        "id": 39540,
        "name": "Ascain",
        "latitude": "43.34521000",
        "longitude": "-1.62073000"
    },
    {
        "id": 39541,
        "name": "Aschères-le-Marché",
        "latitude": "48.11008000",
        "longitude": "2.00725000"
    },
    {
        "id": 39542,
        "name": "Asnières-sur-Nouère",
        "latitude": "45.71667000",
        "longitude": "0.05000000"
    },
    {
        "id": 39543,
        "name": "Asnières-sur-Oise",
        "latitude": "49.13369000",
        "longitude": "2.35551000"
    },
    {
        "id": 39544,
        "name": "Asnières-sur-Seine",
        "latitude": "48.91667000",
        "longitude": "2.28333000"
    },
    {
        "id": 39545,
        "name": "Aspach",
        "latitude": "47.64234000",
        "longitude": "7.23353000"
    },
    {
        "id": 39546,
        "name": "Aspach-le-Bas",
        "latitude": "47.76135000",
        "longitude": "7.15104000"
    },
    {
        "id": 39547,
        "name": "Aspach-le-Haut",
        "latitude": "47.77653000",
        "longitude": "7.13145000"
    },
    {
        "id": 39548,
        "name": "Aspet",
        "latitude": "43.01457000",
        "longitude": "0.80294000"
    },
    {
        "id": 39549,
        "name": "Aspiran",
        "latitude": "43.56586000",
        "longitude": "3.45031000"
    },
    {
        "id": 39550,
        "name": "Aspremont",
        "latitude": "43.78342000",
        "longitude": "7.24406000"
    },
    {
        "id": 39551,
        "name": "Assas",
        "latitude": "43.70278000",
        "longitude": "3.90000000"
    },
    {
        "id": 39552,
        "name": "Assat",
        "latitude": "43.25000000",
        "longitude": "-0.30000000"
    },
    {
        "id": 39554,
        "name": "Assérac",
        "latitude": "47.42881000",
        "longitude": "-2.38916000"
    },
    {
        "id": 39553,
        "name": "Asson",
        "latitude": "43.15000000",
        "longitude": "-0.25000000"
    },
    {
        "id": 39555,
        "name": "Astaffort",
        "latitude": "44.06426000",
        "longitude": "0.65141000"
    },
    {
        "id": 39559,
        "name": "Athée-sur-Cher",
        "latitude": "47.32023000",
        "longitude": "0.91659000"
    },
    {
        "id": 39556,
        "name": "Athies-sous-Laon",
        "latitude": "49.57369000",
        "longitude": "3.68417000"
    },
    {
        "id": 39558,
        "name": "Athis-de-l'Orne",
        "latitude": "48.81667000",
        "longitude": "-0.50000000"
    },
    {
        "id": 39557,
        "name": "Athis-Mons",
        "latitude": "48.70522000",
        "longitude": "2.39147000"
    },
    {
        "id": 39560,
        "name": "Attainville",
        "latitude": "49.05779000",
        "longitude": "2.34497000"
    },
    {
        "id": 39561,
        "name": "Attiches",
        "latitude": "50.52220000",
        "longitude": "3.06222000"
    },
    {
        "id": 39562,
        "name": "Attichy",
        "latitude": "49.41121000",
        "longitude": "3.04949000"
    },
    {
        "id": 39563,
        "name": "Attignat",
        "latitude": "46.28562000",
        "longitude": "5.16164000"
    },
    {
        "id": 39564,
        "name": "Attigny",
        "latitude": "49.47846000",
        "longitude": "4.57803000"
    },
    {
        "id": 39565,
        "name": "Atur",
        "latitude": "45.14086000",
        "longitude": "0.74701000"
    },
    {
        "id": 39566,
        "name": "Aubagne",
        "latitude": "43.29276000",
        "longitude": "5.57067000"
    },
    {
        "id": 39567,
        "name": "Aubais",
        "latitude": "43.75402000",
        "longitude": "4.14567000"
    },
    {
        "id": 39568,
        "name": "Aube-sur-Rîle",
        "latitude": "48.73920000",
        "longitude": "0.55161000"
    },
    {
        "id": 39569,
        "name": "Aubenas",
        "latitude": "44.61975000",
        "longitude": "4.39033000"
    },
    {
        "id": 39570,
        "name": "Auberchicourt",
        "latitude": "50.33333000",
        "longitude": "3.23333000"
    },
    {
        "id": 39571,
        "name": "Aubergenville",
        "latitude": "48.95996000",
        "longitude": "1.85467000"
    },
    {
        "id": 39572,
        "name": "Auberives-sur-Varèze",
        "latitude": "45.42080000",
        "longitude": "4.81914000"
    },
    {
        "id": 39573,
        "name": "Aubers",
        "latitude": "50.59543000",
        "longitude": "2.82666000"
    },
    {
        "id": 39574,
        "name": "Aubervilliers",
        "latitude": "48.91667000",
        "longitude": "2.38333000"
    },
    {
        "id": 39575,
        "name": "Aubevoye",
        "latitude": "49.17097000",
        "longitude": "1.33537000"
    },
    {
        "id": 39576,
        "name": "Aubie-et-Espessas",
        "latitude": "45.01869000",
        "longitude": "-0.40297000"
    },
    {
        "id": 39585,
        "name": "Aubière",
        "latitude": "45.75082000",
        "longitude": "3.11078000"
    },
    {
        "id": 39577,
        "name": "Aubiet",
        "latitude": "43.64631000",
        "longitude": "0.78441000"
    },
    {
        "id": 39578,
        "name": "Aubignan",
        "latitude": "44.09971000",
        "longitude": "5.02526000"
    },
    {
        "id": 39583,
        "name": "Aubigné-Racan",
        "latitude": "47.69091000",
        "longitude": "0.26870000"
    },
    {
        "id": 39579,
        "name": "Aubigny",
        "latitude": "46.59653000",
        "longitude": "-1.45364000"
    },
    {
        "id": 39580,
        "name": "Aubigny-au-Bac",
        "latitude": "50.26370000",
        "longitude": "3.16448000"
    },
    {
        "id": 39581,
        "name": "Aubigny-en-Artois",
        "latitude": "50.35000000",
        "longitude": "2.58333000"
    },
    {
        "id": 39582,
        "name": "Aubigny-sur-Nère",
        "latitude": "47.48819000",
        "longitude": "2.43895000"
    },
    {
        "id": 39584,
        "name": "Aubin",
        "latitude": "44.52809000",
        "longitude": "2.24439000"
    },
    {
        "id": 39586,
        "name": "Aubord",
        "latitude": "43.75827000",
        "longitude": "4.31105000"
    },
    {
        "id": 39587,
        "name": "Auboué",
        "latitude": "49.21198000",
        "longitude": "5.97663000"
    },
    {
        "id": 39588,
        "name": "Aubry-du-Hainaut",
        "latitude": "50.36667000",
        "longitude": "3.46667000"
    },
    {
        "id": 39589,
        "name": "Aubusson",
        "latitude": "45.95606000",
        "longitude": "2.16760000"
    },
    {
        "id": 39590,
        "name": "Auby",
        "latitude": "50.41457000",
        "longitude": "3.05396000"
    },
    {
        "id": 39591,
        "name": "Aucamville",
        "latitude": "43.67020000",
        "longitude": "1.42808000"
    },
    {
        "id": 39592,
        "name": "Auch",
        "latitude": "43.64540000",
        "longitude": "0.58793000"
    },
    {
        "id": 39593,
        "name": "Auchel",
        "latitude": "50.50345000",
        "longitude": "2.47346000"
    },
    {
        "id": 39595,
        "name": "Auchy-lès-Hesdin",
        "latitude": "50.40114000",
        "longitude": "2.10145000"
    },
    {
        "id": 39594,
        "name": "Auchy-les-Mines",
        "latitude": "50.51349000",
        "longitude": "2.78283000"
    },
    {
        "id": 39596,
        "name": "Audenge",
        "latitude": "44.68686000",
        "longitude": "-1.01345000"
    },
    {
        "id": 39597,
        "name": "Audierne",
        "latitude": "48.01640000",
        "longitude": "-4.53838000"
    },
    {
        "id": 39598,
        "name": "Audincourt",
        "latitude": "47.48327000",
        "longitude": "6.85341000"
    },
    {
        "id": 39599,
        "name": "Audruicq",
        "latitude": "50.87795000",
        "longitude": "2.07975000"
    },
    {
        "id": 39600,
        "name": "Audun-le-Roman",
        "latitude": "49.36977000",
        "longitude": "5.89545000"
    },
    {
        "id": 39601,
        "name": "Audun-le-Tiche",
        "latitude": "49.47217000",
        "longitude": "5.95550000"
    },
    {
        "id": 39602,
        "name": "Auffargis",
        "latitude": "48.70049000",
        "longitude": "1.88696000"
    },
    {
        "id": 39603,
        "name": "Auffay",
        "latitude": "49.71881000",
        "longitude": "1.09911000"
    },
    {
        "id": 39604,
        "name": "Augan",
        "latitude": "47.91943000",
        "longitude": "-2.27905000"
    },
    {
        "id": 39605,
        "name": "Augny",
        "latitude": "49.06110000",
        "longitude": "6.11820000"
    },
    {
        "id": 39606,
        "name": "Augy",
        "latitude": "47.76640000",
        "longitude": "3.61051000"
    },
    {
        "id": 39607,
        "name": "Aulnat",
        "latitude": "45.79929000",
        "longitude": "3.16743000"
    },
    {
        "id": 39608,
        "name": "Aulnay",
        "latitude": "46.02082000",
        "longitude": "-0.34778000"
    },
    {
        "id": 39609,
        "name": "Aulnay-sous-Bois",
        "latitude": "48.93814000",
        "longitude": "2.49402000"
    },
    {
        "id": 39610,
        "name": "Aulnay-sur-Mauldre",
        "latitude": "48.92950000",
        "longitude": "1.84113000"
    },
    {
        "id": 39611,
        "name": "Aulnois-sous-Laon",
        "latitude": "49.61429000",
        "longitude": "3.60547000"
    },
    {
        "id": 39612,
        "name": "Aulnoy-lez-Valenciennes",
        "latitude": "50.33333000",
        "longitude": "3.53333000"
    },
    {
        "id": 39613,
        "name": "Aulnoye-Aymeries",
        "latitude": "50.20141000",
        "longitude": "3.83844000"
    },
    {
        "id": 39614,
        "name": "Ault",
        "latitude": "50.10404000",
        "longitude": "1.45000000"
    },
    {
        "id": 39615,
        "name": "Aumale",
        "latitude": "49.76985000",
        "longitude": "1.75587000"
    },
    {
        "id": 39616,
        "name": "Aumetz",
        "latitude": "49.41787000",
        "longitude": "5.94418000"
    },
    {
        "id": 39617,
        "name": "Aumont-Aubrac",
        "latitude": "44.72205000",
        "longitude": "3.28466000"
    },
    {
        "id": 39618,
        "name": "Aunay-sous-Auneau",
        "latitude": "48.44140000",
        "longitude": "1.81094000"
    },
    {
        "id": 39619,
        "name": "Aunay-sur-Odon",
        "latitude": "49.02012000",
        "longitude": "-0.63238000"
    },
    {
        "id": 39620,
        "name": "Auneau",
        "latitude": "48.46361000",
        "longitude": "1.77263000"
    },
    {
        "id": 39621,
        "name": "Auneuil",
        "latitude": "49.37026000",
        "longitude": "1.99712000"
    },
    {
        "id": 39622,
        "name": "Aups",
        "latitude": "43.62841000",
        "longitude": "6.22477000"
    },
    {
        "id": 39623,
        "name": "Auray",
        "latitude": "47.67025000",
        "longitude": "-2.99183000"
    },
    {
        "id": 39624,
        "name": "Aurec-sur-Loire",
        "latitude": "45.36908000",
        "longitude": "4.20161000"
    },
    {
        "id": 39625,
        "name": "Aureilhan",
        "latitude": "43.24337000",
        "longitude": "0.09581000"
    },
    {
        "id": 39626,
        "name": "Aureille",
        "latitude": "43.70821000",
        "longitude": "4.94728000"
    },
    {
        "id": 39627,
        "name": "Auriac-sur-Vendinelle",
        "latitude": "43.52416000",
        "longitude": "1.82640000"
    },
    {
        "id": 39628,
        "name": "Auribeau-sur-Siagne",
        "latitude": "43.60063000",
        "longitude": "6.90992000"
    },
    {
        "id": 39629,
        "name": "Aurignac",
        "latitude": "43.21695000",
        "longitude": "0.88176000"
    },
    {
        "id": 39630,
        "name": "Aurillac",
        "latitude": "44.92539000",
        "longitude": "2.43983000"
    },
    {
        "id": 39631,
        "name": "Auriol",
        "latitude": "43.37161000",
        "longitude": "5.63410000"
    },
    {
        "id": 39632,
        "name": "Aussillon",
        "latitude": "43.50293000",
        "longitude": "2.36791000"
    },
    {
        "id": 39633,
        "name": "Aussonne",
        "latitude": "43.68162000",
        "longitude": "1.31886000"
    },
    {
        "id": 39634,
        "name": "Auterive",
        "latitude": "43.35103000",
        "longitude": "1.47797000"
    },
    {
        "id": 39635,
        "name": "Authie",
        "latitude": "49.20615000",
        "longitude": "-0.43191000"
    },
    {
        "id": 39636,
        "name": "Authon-du-Perche",
        "latitude": "48.19615000",
        "longitude": "0.89189000"
    },
    {
        "id": 39637,
        "name": "Autrans",
        "latitude": "45.17605000",
        "longitude": "5.54271000"
    },
    {
        "id": 39638,
        "name": "Autry-le-Châtel",
        "latitude": "47.59886000",
        "longitude": "2.60200000"
    },
    {
        "id": 39639,
        "name": "Autun",
        "latitude": "46.95104000",
        "longitude": "4.29869000"
    },
    {
        "id": 39641,
        "name": "Auvers-le-Hamon",
        "latitude": "47.90246000",
        "longitude": "-0.35170000"
    },
    {
        "id": 39640,
        "name": "Auvers-Saint-Georges",
        "latitude": "48.49276000",
        "longitude": "2.22045000"
    },
    {
        "id": 39642,
        "name": "Auvers-sur-Oise",
        "latitude": "49.07158000",
        "longitude": "2.16978000"
    },
    {
        "id": 39643,
        "name": "Auvillar",
        "latitude": "44.06863000",
        "longitude": "0.90192000"
    },
    {
        "id": 39644,
        "name": "Auxerre",
        "latitude": "47.79960000",
        "longitude": "3.57033000"
    },
    {
        "id": 39645,
        "name": "Auxi-le-Château",
        "latitude": "50.23101000",
        "longitude": "2.11593000"
    },
    {
        "id": 39646,
        "name": "Auxon-Dessous",
        "latitude": "47.30000000",
        "longitude": "5.95000000"
    },
    {
        "id": 39647,
        "name": "Auxonne",
        "latitude": "47.19255000",
        "longitude": "5.38726000"
    },
    {
        "id": 39648,
        "name": "Auxy",
        "latitude": "46.94940000",
        "longitude": "4.40440000"
    },
    {
        "id": 39649,
        "name": "Auzances",
        "latitude": "46.02849000",
        "longitude": "2.50042000"
    },
    {
        "id": 39650,
        "name": "Auzat-la-Combelle",
        "latitude": "45.45224000",
        "longitude": "3.31825000"
    },
    {
        "id": 39651,
        "name": "Auzebosc",
        "latitude": "49.59565000",
        "longitude": "0.72850000"
    },
    {
        "id": 39652,
        "name": "Auzeville-Tolosane",
        "latitude": "43.52777000",
        "longitude": "1.48240000"
    },
    {
        "id": 39653,
        "name": "Auzielle",
        "latitude": "43.54165000",
        "longitude": "1.56574000"
    },
    {
        "id": 39654,
        "name": "Auzouer-en-Touraine",
        "latitude": "47.54230000",
        "longitude": "0.92067000"
    },
    {
        "id": 39655,
        "name": "Availles-Limouzine",
        "latitude": "46.12104000",
        "longitude": "0.65558000"
    },
    {
        "id": 39656,
        "name": "Avallon",
        "latitude": "47.49002000",
        "longitude": "3.90832000"
    },
    {
        "id": 39657,
        "name": "Avanne-Aveney",
        "latitude": "47.20000000",
        "longitude": "5.96667000"
    },
    {
        "id": 39658,
        "name": "Avanton",
        "latitude": "46.66369000",
        "longitude": "0.30862000"
    },
    {
        "id": 39659,
        "name": "Aveize",
        "latitude": "45.68243000",
        "longitude": "4.47786000"
    },
    {
        "id": 39660,
        "name": "Aveizieux",
        "latitude": "45.56500000",
        "longitude": "4.37071000"
    },
    {
        "id": 39661,
        "name": "Avelin",
        "latitude": "50.53968000",
        "longitude": "3.08222000"
    },
    {
        "id": 39662,
        "name": "Avensan",
        "latitude": "45.03444000",
        "longitude": "-0.75568000"
    },
    {
        "id": 39663,
        "name": "Avermes",
        "latitude": "46.58774000",
        "longitude": "3.30720000"
    },
    {
        "id": 39664,
        "name": "Avesnelles",
        "latitude": "50.11782000",
        "longitude": "3.94674000"
    },
    {
        "id": 39665,
        "name": "Avesnes-le-Comte",
        "latitude": "50.27767000",
        "longitude": "2.52957000"
    },
    {
        "id": 39666,
        "name": "Avesnes-le-Sec",
        "latitude": "50.25100000",
        "longitude": "3.37768000"
    },
    {
        "id": 39667,
        "name": "Avesnes-les-Aubert",
        "latitude": "50.20000000",
        "longitude": "3.38333000"
    },
    {
        "id": 39668,
        "name": "Avessac",
        "latitude": "47.65324000",
        "longitude": "-1.98961000"
    },
    {
        "id": 39681,
        "name": "Avèze",
        "latitude": "43.97116000",
        "longitude": "3.60097000"
    },
    {
        "id": 39669,
        "name": "Avignon",
        "latitude": "43.94834000",
        "longitude": "4.80892000"
    },
    {
        "id": 39670,
        "name": "Avignonet-Lauragais",
        "latitude": "43.36667000",
        "longitude": "1.80000000"
    },
    {
        "id": 39671,
        "name": "Avilly-Saint-Léonard",
        "latitude": "49.19449000",
        "longitude": "2.54148000"
    },
    {
        "id": 39672,
        "name": "Avion",
        "latitude": "50.41038000",
        "longitude": "2.83053000"
    },
    {
        "id": 39673,
        "name": "Aviron",
        "latitude": "49.05000000",
        "longitude": "1.11667000"
    },
    {
        "id": 39674,
        "name": "Avize",
        "latitude": "48.97352000",
        "longitude": "4.01438000"
    },
    {
        "id": 39675,
        "name": "Avoine",
        "latitude": "47.20533000",
        "longitude": "0.18253000"
    },
    {
        "id": 39676,
        "name": "Avon",
        "latitude": "48.40219000",
        "longitude": "2.72022000"
    },
    {
        "id": 39677,
        "name": "Avord",
        "latitude": "47.03514000",
        "longitude": "2.65295000"
    },
    {
        "id": 39678,
        "name": "Avranches",
        "latitude": "48.68436000",
        "longitude": "-1.35686000"
    },
    {
        "id": 39679,
        "name": "Avrechy",
        "latitude": "49.44796000",
        "longitude": "2.42710000"
    },
    {
        "id": 39680,
        "name": "Avrillé",
        "latitude": "47.50000000",
        "longitude": "-0.58333000"
    },
    {
        "id": 39682,
        "name": "Ax-les-Thermes",
        "latitude": "42.71968000",
        "longitude": "1.83845000"
    },
    {
        "id": 39683,
        "name": "Ay",
        "latitude": "49.05457000",
        "longitude": "4.00343000"
    },
    {
        "id": 39684,
        "name": "Ay-sur-Moselle",
        "latitude": "49.24326000",
        "longitude": "6.20627000"
    },
    {
        "id": 39685,
        "name": "Aydat",
        "latitude": "45.66022000",
        "longitude": "2.97316000"
    },
    {
        "id": 39686,
        "name": "Aydoilles",
        "latitude": "48.21026000",
        "longitude": "6.57389000"
    },
    {
        "id": 39687,
        "name": "Ayguemorte-les-Graves",
        "latitude": "44.70988000",
        "longitude": "-0.48060000"
    },
    {
        "id": 39688,
        "name": "Ayguesvives",
        "latitude": "43.43636000",
        "longitude": "1.59505000"
    },
    {
        "id": 39689,
        "name": "Ayron",
        "latitude": "46.66026000",
        "longitude": "0.07545000"
    },
    {
        "id": 39690,
        "name": "Ayse",
        "latitude": "46.08135000",
        "longitude": "6.44550000"
    },
    {
        "id": 39691,
        "name": "Aytré",
        "latitude": "46.13570000",
        "longitude": "-1.11452000"
    },
    {
        "id": 39692,
        "name": "Azay-le-Brûlé",
        "latitude": "46.40011000",
        "longitude": "-0.24914000"
    },
    {
        "id": 39693,
        "name": "Azay-le-Ferron",
        "latitude": "46.85097000",
        "longitude": "1.07084000"
    },
    {
        "id": 39694,
        "name": "Azay-le-Rideau",
        "latitude": "47.26405000",
        "longitude": "0.47132000"
    },
    {
        "id": 39695,
        "name": "Azay-sur-Cher",
        "latitude": "47.34966000",
        "longitude": "0.84562000"
    },
    {
        "id": 39697,
        "name": "Azé",
        "latitude": "47.82220000",
        "longitude": "-0.68333000"
    },
    {
        "id": 39698,
        "name": "Azé",
        "latitude": "47.85141000",
        "longitude": "0.99829000"
    },
    {
        "id": 39696,
        "name": "Azille",
        "latitude": "43.27691000",
        "longitude": "2.65981000"
    },
    {
        "id": 39699,
        "name": "Baccarat",
        "latitude": "48.44984000",
        "longitude": "6.73946000"
    },
    {
        "id": 39700,
        "name": "Bachant",
        "latitude": "50.21540000",
        "longitude": "3.86835000"
    },
    {
        "id": 39701,
        "name": "Bachy",
        "latitude": "50.55079000",
        "longitude": "3.25976000"
    },
    {
        "id": 39702,
        "name": "Bacqueville-en-Caux",
        "latitude": "49.78761000",
        "longitude": "0.99927000"
    },
    {
        "id": 39703,
        "name": "Baden",
        "latitude": "47.61734000",
        "longitude": "-2.91987000"
    },
    {
        "id": 39704,
        "name": "Badonviller",
        "latitude": "48.50116000",
        "longitude": "6.89218000"
    },
    {
        "id": 39705,
        "name": "Bagard",
        "latitude": "44.07126000",
        "longitude": "4.05225000"
    },
    {
        "id": 40409,
        "name": "Bâgé-la-Ville",
        "latitude": "46.31567000",
        "longitude": "4.94086000"
    },
    {
        "id": 39706,
        "name": "Bages",
        "latitude": "42.60588000",
        "longitude": "2.89350000"
    },
    {
        "id": 39707,
        "name": "Bagnac-sur-Célé",
        "latitude": "44.66667000",
        "longitude": "2.16667000"
    },
    {
        "id": 39708,
        "name": "Bagneaux-sur-Loing",
        "latitude": "48.23310000",
        "longitude": "2.70675000"
    },
    {
        "id": 39714,
        "name": "Bagnères-de-Bigorre",
        "latitude": "43.06416000",
        "longitude": "0.14970000"
    },
    {
        "id": 39715,
        "name": "Bagnères-de-Luchon",
        "latitude": "42.79079000",
        "longitude": "0.59315000"
    },
    {
        "id": 39709,
        "name": "Bagneux",
        "latitude": "48.79565000",
        "longitude": "2.30796000"
    },
    {
        "id": 39710,
        "name": "Bagnoles-de-l'Orne",
        "latitude": "48.55720000",
        "longitude": "-0.41383000"
    },
    {
        "id": 39711,
        "name": "Bagnolet",
        "latitude": "48.86667000",
        "longitude": "2.41667000"
    },
    {
        "id": 39712,
        "name": "Bagnols-en-Forêt",
        "latitude": "43.53752000",
        "longitude": "6.69814000"
    },
    {
        "id": 39713,
        "name": "Bagnols-sur-Cèze",
        "latitude": "44.15990000",
        "longitude": "4.61776000"
    },
    {
        "id": 39716,
        "name": "Baguer-Morvan",
        "latitude": "48.52531000",
        "longitude": "-1.77332000"
    },
    {
        "id": 39717,
        "name": "Baguer-Pican",
        "latitude": "48.55253000",
        "longitude": "-1.69837000"
    },
    {
        "id": 39718,
        "name": "Baho",
        "latitude": "42.70000000",
        "longitude": "2.82204000"
    },
    {
        "id": 39719,
        "name": "Baignes-Sainte-Radegonde",
        "latitude": "45.38333000",
        "longitude": "-0.23333000"
    },
    {
        "id": 39720,
        "name": "Baillargues",
        "latitude": "43.66267000",
        "longitude": "4.01681000"
    },
    {
        "id": 39721,
        "name": "Baille",
        "latitude": "43.28770000",
        "longitude": "5.39804000"
    },
    {
        "id": 39723,
        "name": "Bailleau-l’Évêque",
        "latitude": "48.48960000",
        "longitude": "1.39665000"
    },
    {
        "id": 39722,
        "name": "Bailleau-le-Pin",
        "latitude": "48.36698000",
        "longitude": "1.32948000"
    },
    {
        "id": 39724,
        "name": "Baillet-en-France",
        "latitude": "49.06187000",
        "longitude": "2.29880000"
    },
    {
        "id": 39725,
        "name": "Bailleul",
        "latitude": "50.73592000",
        "longitude": "2.73594000"
    },
    {
        "id": 39726,
        "name": "Bailleul-Sir-Berthoult",
        "latitude": "50.33752000",
        "longitude": "2.85129000"
    },
    {
        "id": 39727,
        "name": "Bailleul-sur-Thérain",
        "latitude": "49.38526000",
        "longitude": "2.22279000"
    },
    {
        "id": 39728,
        "name": "Bailleval",
        "latitude": "49.34605000",
        "longitude": "2.45695000"
    },
    {
        "id": 39729,
        "name": "Bailly",
        "latitude": "48.84168000",
        "longitude": "2.07673000"
    },
    {
        "id": 39730,
        "name": "Bailly-Carrois",
        "latitude": "48.58029000",
        "longitude": "2.99047000"
    },
    {
        "id": 39731,
        "name": "Bailly-Romainvilliers",
        "latitude": "48.84729000",
        "longitude": "2.82352000"
    },
    {
        "id": 39732,
        "name": "Bain-de-Bretagne",
        "latitude": "47.84418000",
        "longitude": "-1.68486000"
    },
    {
        "id": 39733,
        "name": "Baincthun",
        "latitude": "50.70994000",
        "longitude": "1.68075000"
    },
    {
        "id": 39734,
        "name": "Bains",
        "latitude": "45.00956000",
        "longitude": "3.77503000"
    },
    {
        "id": 39735,
        "name": "Bains-les-Bains",
        "latitude": "48.00113000",
        "longitude": "6.26492000"
    },
    {
        "id": 39736,
        "name": "Bains-sur-Oust",
        "latitude": "47.70362000",
        "longitude": "-2.07047000"
    },
    {
        "id": 39737,
        "name": "Bainville-sur-Madon",
        "latitude": "48.58725000",
        "longitude": "6.09580000"
    },
    {
        "id": 39738,
        "name": "Bais",
        "latitude": "48.00959000",
        "longitude": "-1.28983000"
    },
    {
        "id": 39739,
        "name": "Bais",
        "latitude": "48.25280000",
        "longitude": "-0.36493000"
    },
    {
        "id": 39740,
        "name": "Baisieux",
        "latitude": "50.60841000",
        "longitude": "3.25251000"
    },
    {
        "id": 39741,
        "name": "Baixas",
        "latitude": "42.74969000",
        "longitude": "2.81002000"
    },
    {
        "id": 39742,
        "name": "Balagny-sur-Thérain",
        "latitude": "49.29605000",
        "longitude": "2.33645000"
    },
    {
        "id": 39743,
        "name": "Balan",
        "latitude": "49.68881000",
        "longitude": "4.96478000"
    },
    {
        "id": 39744,
        "name": "Balan",
        "latitude": "45.83416000",
        "longitude": "5.09930000"
    },
    {
        "id": 39745,
        "name": "Balaruc-le-Vieux",
        "latitude": "43.46140000",
        "longitude": "3.68530000"
    },
    {
        "id": 39746,
        "name": "Balaruc-les-Bains",
        "latitude": "43.44170000",
        "longitude": "3.67780000"
    },
    {
        "id": 39747,
        "name": "Balazé",
        "latitude": "48.16797000",
        "longitude": "-1.19157000"
    },
    {
        "id": 39748,
        "name": "Balbigny",
        "latitude": "45.81913000",
        "longitude": "4.19030000"
    },
    {
        "id": 39749,
        "name": "Baldersheim",
        "latitude": "47.80136000",
        "longitude": "7.38078000"
    },
    {
        "id": 39750,
        "name": "Ballainvilliers",
        "latitude": "48.67481000",
        "longitude": "2.30057000"
    },
    {
        "id": 39751,
        "name": "Ballaison",
        "latitude": "46.29842000",
        "longitude": "6.32765000"
    },
    {
        "id": 39752,
        "name": "Ballan-Miré",
        "latitude": "47.34057000",
        "longitude": "0.61466000"
    },
    {
        "id": 39753,
        "name": "Ballancourt-sur-Essonne",
        "latitude": "48.52525000",
        "longitude": "2.38604000"
    },
    {
        "id": 39754,
        "name": "Ballon",
        "latitude": "48.17317000",
        "longitude": "0.23814000"
    },
    {
        "id": 39755,
        "name": "Ballots",
        "latitude": "47.89617000",
        "longitude": "-1.04759000"
    },
    {
        "id": 39756,
        "name": "Balma",
        "latitude": "43.61111000",
        "longitude": "1.49944000"
    },
    {
        "id": 39757,
        "name": "Balzac",
        "latitude": "45.70000000",
        "longitude": "0.11667000"
    },
    {
        "id": 39758,
        "name": "Ban-de-Laveline",
        "latitude": "48.24498000",
        "longitude": "7.06593000"
    },
    {
        "id": 39759,
        "name": "Bandol",
        "latitude": "43.14247000",
        "longitude": "5.74718000"
    },
    {
        "id": 39760,
        "name": "Bannalec",
        "latitude": "47.93229000",
        "longitude": "-3.69759000"
    },
    {
        "id": 39761,
        "name": "Bantzenheim",
        "latitude": "47.82452000",
        "longitude": "7.51445000"
    },
    {
        "id": 39762,
        "name": "Banyuls de la Marenda",
        "latitude": "42.48375000",
        "longitude": "3.12897000"
    },
    {
        "id": 39763,
        "name": "Banyuls-dels-Aspres",
        "latitude": "42.56567000",
        "longitude": "2.86667000"
    },
    {
        "id": 39764,
        "name": "Bapaume",
        "latitude": "50.10379000",
        "longitude": "2.84966000"
    },
    {
        "id": 39765,
        "name": "Bar-le-Duc",
        "latitude": "48.77275000",
        "longitude": "5.16108000"
    },
    {
        "id": 39766,
        "name": "Bar-sur-Aube",
        "latitude": "48.23315000",
        "longitude": "4.70640000"
    },
    {
        "id": 39767,
        "name": "Bar-sur-Seine",
        "latitude": "48.11295000",
        "longitude": "4.37656000"
    },
    {
        "id": 39768,
        "name": "Baraqueville",
        "latitude": "44.27655000",
        "longitude": "2.43184000"
    },
    {
        "id": 39769,
        "name": "Barbaste",
        "latitude": "44.16905000",
        "longitude": "0.28659000"
    },
    {
        "id": 39777,
        "name": "Barbâtre",
        "latitude": "46.94116000",
        "longitude": "-2.17752000"
    },
    {
        "id": 39770,
        "name": "Barbazan-Debat",
        "latitude": "43.19580000",
        "longitude": "0.12060000"
    },
    {
        "id": 39771,
        "name": "Barbechat",
        "latitude": "47.27750000",
        "longitude": "-1.28524000"
    },
    {
        "id": 39772,
        "name": "Barbentane",
        "latitude": "43.89939000",
        "longitude": "4.74756000"
    },
    {
        "id": 39773,
        "name": "Barberaz",
        "latitude": "45.56179000",
        "longitude": "5.94306000"
    },
    {
        "id": 39774,
        "name": "Barbezieux-Saint-Hilaire",
        "latitude": "45.47265000",
        "longitude": "-0.15218000"
    },
    {
        "id": 39775,
        "name": "Barbizon",
        "latitude": "48.44346000",
        "longitude": "2.60313000"
    },
    {
        "id": 39776,
        "name": "Barby",
        "latitude": "45.56810000",
        "longitude": "5.97966000"
    },
    {
        "id": 39778,
        "name": "Barcelonne-du-Gers",
        "latitude": "43.70360000",
        "longitude": "-0.23619000"
    },
    {
        "id": 39779,
        "name": "Barcelonnette",
        "latitude": "44.38691000",
        "longitude": "6.65179000"
    },
    {
        "id": 39780,
        "name": "Bardos",
        "latitude": "43.47392000",
        "longitude": "-1.20347000"
    },
    {
        "id": 39781,
        "name": "Barentin",
        "latitude": "49.54533000",
        "longitude": "0.95515000"
    },
    {
        "id": 39782,
        "name": "Barenton",
        "latitude": "48.60027000",
        "longitude": "-0.83258000"
    },
    {
        "id": 39783,
        "name": "Bargemon",
        "latitude": "43.61899000",
        "longitude": "6.54957000"
    },
    {
        "id": 39784,
        "name": "Barjac",
        "latitude": "44.30743000",
        "longitude": "4.35146000"
    },
    {
        "id": 39785,
        "name": "Barjols",
        "latitude": "43.55805000",
        "longitude": "6.00752000"
    },
    {
        "id": 39786,
        "name": "Barjouville",
        "latitude": "48.41002000",
        "longitude": "1.47639000"
    },
    {
        "id": 39787,
        "name": "Barlin",
        "latitude": "50.45238000",
        "longitude": "2.61955000"
    },
    {
        "id": 39788,
        "name": "Barneville-Carteret",
        "latitude": "49.38333000",
        "longitude": "-1.75000000"
    },
    {
        "id": 39789,
        "name": "Barneville-Plage",
        "latitude": "49.36786000",
        "longitude": "-1.76512000"
    },
    {
        "id": 39790,
        "name": "Barr",
        "latitude": "48.40755000",
        "longitude": "7.44873000"
    },
    {
        "id": 39791,
        "name": "Barraux",
        "latitude": "45.43176000",
        "longitude": "5.97964000"
    },
    {
        "id": 39792,
        "name": "Barsac",
        "latitude": "44.60745000",
        "longitude": "-0.31527000"
    },
    {
        "id": 39793,
        "name": "Bart",
        "latitude": "47.48861000",
        "longitude": "6.77090000"
    },
    {
        "id": 39794,
        "name": "Bartenheim",
        "latitude": "47.63364000",
        "longitude": "7.47951000"
    },
    {
        "id": 39796,
        "name": "Bas-en-Basset",
        "latitude": "45.30599000",
        "longitude": "4.10912000"
    },
    {
        "id": 39795,
        "name": "Bas-Rhin",
        "latitude": "48.65693000",
        "longitude": "7.56346000"
    },
    {
        "id": 39797,
        "name": "Bassan",
        "latitude": "43.41060000",
        "longitude": "3.25396000"
    },
    {
        "id": 39798,
        "name": "Basse-Goulaine",
        "latitude": "47.21167000",
        "longitude": "-1.46748000"
    },
    {
        "id": 39799,
        "name": "Basse-Ham",
        "latitude": "49.38626000",
        "longitude": "6.24180000"
    },
    {
        "id": 39800,
        "name": "Bassens",
        "latitude": "45.57555000",
        "longitude": "5.93900000"
    },
    {
        "id": 39801,
        "name": "Bassens",
        "latitude": "44.90226000",
        "longitude": "-0.51631000"
    },
    {
        "id": 39802,
        "name": "Bassillac",
        "latitude": "45.19305000",
        "longitude": "0.81528000"
    },
    {
        "id": 39803,
        "name": "Bassussarry",
        "latitude": "43.44272000",
        "longitude": "-1.51647000"
    },
    {
        "id": 39804,
        "name": "Bastia",
        "latitude": "42.70278000",
        "longitude": "9.45000000"
    },
    {
        "id": 39805,
        "name": "Batilly",
        "latitude": "49.17372000",
        "longitude": "5.96869000"
    },
    {
        "id": 39806,
        "name": "Battenheim",
        "latitude": "47.81999000",
        "longitude": "7.38170000"
    },
    {
        "id": 39807,
        "name": "Batz-sur-Mer",
        "latitude": "47.27750000",
        "longitude": "-2.48027000"
    },
    {
        "id": 39808,
        "name": "Baud",
        "latitude": "47.87474000",
        "longitude": "-3.01760000"
    },
    {
        "id": 39810,
        "name": "Baugé-en-Anjou",
        "latitude": "47.54447000",
        "longitude": "-0.10653000"
    },
    {
        "id": 39809,
        "name": "Baugy",
        "latitude": "47.08181000",
        "longitude": "2.72848000"
    },
    {
        "id": 39811,
        "name": "Baule",
        "latitude": "47.81084000",
        "longitude": "1.67259000"
    },
    {
        "id": 39812,
        "name": "Baulne",
        "latitude": "48.49303000",
        "longitude": "2.36230000"
    },
    {
        "id": 39813,
        "name": "Baulon",
        "latitude": "47.98590000",
        "longitude": "-1.93114000"
    },
    {
        "id": 39814,
        "name": "Baume-les-Dames",
        "latitude": "47.35295000",
        "longitude": "6.36117000"
    },
    {
        "id": 39815,
        "name": "Bauné",
        "latitude": "47.49954000",
        "longitude": "-0.31906000"
    },
    {
        "id": 39816,
        "name": "Bauvin",
        "latitude": "50.51714000",
        "longitude": "2.89404000"
    },
    {
        "id": 39817,
        "name": "Bavans",
        "latitude": "47.48528000",
        "longitude": "6.73324000"
    },
    {
        "id": 39818,
        "name": "Bavay",
        "latitude": "50.29828000",
        "longitude": "3.79372000"
    },
    {
        "id": 39819,
        "name": "Bavent",
        "latitude": "49.22996000",
        "longitude": "-0.18675000"
    },
    {
        "id": 39820,
        "name": "Bavilliers",
        "latitude": "47.62235000",
        "longitude": "6.83543000"
    },
    {
        "id": 39821,
        "name": "Bayard-sur-Marne",
        "latitude": "48.55410000",
        "longitude": "5.07680000"
    },
    {
        "id": 39822,
        "name": "Bayeux",
        "latitude": "49.27732000",
        "longitude": "-0.70390000"
    },
    {
        "id": 39823,
        "name": "Bayon",
        "latitude": "48.47425000",
        "longitude": "6.31631000"
    },
    {
        "id": 39824,
        "name": "Bayonne",
        "latitude": "43.49257000",
        "longitude": "-1.47624000"
    },
    {
        "id": 39825,
        "name": "Bazainville",
        "latitude": "48.80435000",
        "longitude": "1.66732000"
    },
    {
        "id": 39826,
        "name": "Bazancourt",
        "latitude": "49.36562000",
        "longitude": "4.17051000"
    },
    {
        "id": 39827,
        "name": "Bazas",
        "latitude": "44.43202000",
        "longitude": "-0.21327000"
    },
    {
        "id": 39828,
        "name": "Bazeilles",
        "latitude": "49.67650000",
        "longitude": "4.97752000"
    },
    {
        "id": 39829,
        "name": "Bazemont",
        "latitude": "48.92726000",
        "longitude": "1.86651000"
    },
    {
        "id": 39830,
        "name": "Bazet",
        "latitude": "43.29145000",
        "longitude": "0.06728000"
    },
    {
        "id": 39831,
        "name": "Baziège",
        "latitude": "43.45454000",
        "longitude": "1.61399000"
    },
    {
        "id": 39832,
        "name": "Bazoches-les-Gallerandes",
        "latitude": "48.16540000",
        "longitude": "2.04319000"
    },
    {
        "id": 39833,
        "name": "Bazoges-en-Pareds",
        "latitude": "46.65702000",
        "longitude": "-0.91654000"
    },
    {
        "id": 39834,
        "name": "Bazouges-la-Pérouse",
        "latitude": "48.42606000",
        "longitude": "-1.57431000"
    },
    {
        "id": 39835,
        "name": "Bazouges-sur-le-Loir",
        "latitude": "47.68928000",
        "longitude": "-0.16883000"
    },
    {
        "id": 39836,
        "name": "Beaucaire",
        "latitude": "43.80806000",
        "longitude": "4.64417000"
    },
    {
        "id": 39837,
        "name": "Beaucamps-le-Vieux",
        "latitude": "49.84642000",
        "longitude": "1.78272000"
    },
    {
        "id": 39844,
        "name": "Beaucé",
        "latitude": "48.33890000",
        "longitude": "-1.15804000"
    },
    {
        "id": 39838,
        "name": "Beauchamp",
        "latitude": "49.01667000",
        "longitude": "2.20000000"
    },
    {
        "id": 39839,
        "name": "Beauchamps",
        "latitude": "50.01202000",
        "longitude": "1.51764000"
    },
    {
        "id": 39840,
        "name": "Beauchastel",
        "latitude": "44.82587000",
        "longitude": "4.80305000"
    },
    {
        "id": 39841,
        "name": "Beaucourt",
        "latitude": "47.48878000",
        "longitude": "6.92214000"
    },
    {
        "id": 39842,
        "name": "Beaucouzé",
        "latitude": "47.47444000",
        "longitude": "-0.63016000"
    },
    {
        "id": 39843,
        "name": "Beaucroissant",
        "latitude": "45.34276000",
        "longitude": "5.47102000"
    },
    {
        "id": 39845,
        "name": "Beaufay",
        "latitude": "48.14664000",
        "longitude": "0.36224000"
    },
    {
        "id": 39846,
        "name": "Beaufort",
        "latitude": "45.71915000",
        "longitude": "6.57331000"
    },
    {
        "id": 39847,
        "name": "Beaufort-en-Vallée",
        "latitude": "47.43965000",
        "longitude": "-0.21890000"
    },
    {
        "id": 39848,
        "name": "Beaugency",
        "latitude": "47.78019000",
        "longitude": "1.62705000"
    },
    {
        "id": 39849,
        "name": "Beaujeu",
        "latitude": "46.15428000",
        "longitude": "4.58826000"
    },
    {
        "id": 39850,
        "name": "Beaulieu",
        "latitude": "43.72861000",
        "longitude": "4.02194000"
    },
    {
        "id": 39851,
        "name": "Beaulieu-lès-Loches",
        "latitude": "47.12526000",
        "longitude": "1.01585000"
    },
    {
        "id": 39852,
        "name": "Beaulieu-sous-la-Roche",
        "latitude": "46.67647000",
        "longitude": "-1.61129000"
    },
    {
        "id": 39853,
        "name": "Beaulieu-sur-Dordogne",
        "latitude": "44.97832000",
        "longitude": "1.83834000"
    },
    {
        "id": 39854,
        "name": "Beaulieu-sur-Layon",
        "latitude": "47.31095000",
        "longitude": "-0.58988000"
    },
    {
        "id": 39855,
        "name": "Beaulieu-sur-Mer",
        "latitude": "43.70692000",
        "longitude": "7.33135000"
    },
    {
        "id": 39856,
        "name": "Beaulon",
        "latitude": "46.60156000",
        "longitude": "3.67314000"
    },
    {
        "id": 39857,
        "name": "Beaumes-de-Venise",
        "latitude": "44.12227000",
        "longitude": "5.03065000"
    },
    {
        "id": 39858,
        "name": "Beaumetz-lès-Loges",
        "latitude": "50.23897000",
        "longitude": "2.65818000"
    },
    {
        "id": 39859,
        "name": "Beaumont",
        "latitude": "45.75165000",
        "longitude": "3.08294000"
    },
    {
        "id": 39860,
        "name": "Beaumont",
        "latitude": "46.73773000",
        "longitude": "0.42961000"
    },
    {
        "id": 39863,
        "name": "Beaumont-de-Lomagne",
        "latitude": "43.88285000",
        "longitude": "0.98762000"
    },
    {
        "id": 39864,
        "name": "Beaumont-de-Pertuis",
        "latitude": "43.73737000",
        "longitude": "5.68959000"
    },
    {
        "id": 39865,
        "name": "Beaumont-du-Gâtinais",
        "latitude": "48.13860000",
        "longitude": "2.47913000"
    },
    {
        "id": 39866,
        "name": "Beaumont-du-Périgord",
        "latitude": "44.76662000",
        "longitude": "0.76916000"
    },
    {
        "id": 39867,
        "name": "Beaumont-en-Véron",
        "latitude": "47.19397000",
        "longitude": "0.18436000"
    },
    {
        "id": 39861,
        "name": "Beaumont-Hague",
        "latitude": "49.66387000",
        "longitude": "-1.83822000"
    },
    {
        "id": 39868,
        "name": "Beaumont-la-Ronce",
        "latitude": "47.56948000",
        "longitude": "0.67017000"
    },
    {
        "id": 39869,
        "name": "Beaumont-le-Roger",
        "latitude": "49.07839000",
        "longitude": "0.78081000"
    },
    {
        "id": 39870,
        "name": "Beaumont-lès-Valence",
        "latitude": "44.86203000",
        "longitude": "4.94309000"
    },
    {
        "id": 39862,
        "name": "Beaumont-Monteux",
        "latitude": "45.01980000",
        "longitude": "4.91883000"
    },
    {
        "id": 39871,
        "name": "Beaumont-sur-Lèze",
        "latitude": "43.38095000",
        "longitude": "1.35826000"
    },
    {
        "id": 39872,
        "name": "Beaumont-sur-Oise",
        "latitude": "49.14232000",
        "longitude": "2.28705000"
    },
    {
        "id": 39873,
        "name": "Beaumont-sur-Sarthe",
        "latitude": "48.22772000",
        "longitude": "0.13186000"
    },
    {
        "id": 39874,
        "name": "Beaune",
        "latitude": "47.02413000",
        "longitude": "4.83887000"
    },
    {
        "id": 39875,
        "name": "Beaune-la-Rolande",
        "latitude": "48.07124000",
        "longitude": "2.43140000"
    },
    {
        "id": 39876,
        "name": "Beaupréau",
        "latitude": "47.20464000",
        "longitude": "-0.98703000"
    },
    {
        "id": 39877,
        "name": "Beaupuy",
        "latitude": "44.53549000",
        "longitude": "0.14900000"
    },
    {
        "id": 39878,
        "name": "Beaupuy",
        "latitude": "43.64713000",
        "longitude": "1.55517000"
    },
    {
        "id": 39879,
        "name": "Beauquesne",
        "latitude": "50.08526000",
        "longitude": "2.39276000"
    },
    {
        "id": 39880,
        "name": "Beaurains",
        "latitude": "50.26599000",
        "longitude": "2.79467000"
    },
    {
        "id": 39881,
        "name": "Beaurainville",
        "latitude": "50.42432000",
        "longitude": "1.89938000"
    },
    {
        "id": 39882,
        "name": "Beaurepaire",
        "latitude": "45.33658000",
        "longitude": "5.04878000"
    },
    {
        "id": 39883,
        "name": "Beaurepaire",
        "latitude": "46.90977000",
        "longitude": "-1.08928000"
    },
    {
        "id": 39884,
        "name": "Beaurevoir",
        "latitude": "49.99714000",
        "longitude": "3.30855000"
    },
    {
        "id": 39885,
        "name": "Beausemblant",
        "latitude": "45.21706000",
        "longitude": "4.83241000"
    },
    {
        "id": 39886,
        "name": "Beausoleil",
        "latitude": "43.74311000",
        "longitude": "7.42250000"
    },
    {
        "id": 39887,
        "name": "Beautiran",
        "latitude": "44.70393000",
        "longitude": "-0.45202000"
    },
    {
        "id": 39888,
        "name": "Beautor",
        "latitude": "49.65214000",
        "longitude": "3.34475000"
    },
    {
        "id": 39889,
        "name": "Beauvais",
        "latitude": "49.43333000",
        "longitude": "2.08333000"
    },
    {
        "id": 39890,
        "name": "Beauval",
        "latitude": "50.10789000",
        "longitude": "2.33269000"
    },
    {
        "id": 39891,
        "name": "Beauvallon",
        "latitude": "44.85593000",
        "longitude": "4.90756000"
    },
    {
        "id": 39892,
        "name": "Beauvoir-de-Marc",
        "latitude": "45.52009000",
        "longitude": "5.07906000"
    },
    {
        "id": 39893,
        "name": "Beauvoir-sur-Mer",
        "latitude": "46.91274000",
        "longitude": "-2.04156000"
    },
    {
        "id": 39894,
        "name": "Beauvoir-sur-Niort",
        "latitude": "46.18017000",
        "longitude": "-0.47178000"
    },
    {
        "id": 39895,
        "name": "Beauvois-en-Cambrésis",
        "latitude": "50.13735000",
        "longitude": "3.37870000"
    },
    {
        "id": 39896,
        "name": "Beauvoisin",
        "latitude": "43.71835000",
        "longitude": "4.32339000"
    },
    {
        "id": 39897,
        "name": "Beauzac",
        "latitude": "45.25913000",
        "longitude": "4.09874000"
    },
    {
        "id": 39898,
        "name": "Beauzelle",
        "latitude": "43.66713000",
        "longitude": "1.37518000"
    },
    {
        "id": 40411,
        "name": "Bécon-les-Granits",
        "latitude": "47.50134000",
        "longitude": "-0.80000000"
    },
    {
        "id": 40412,
        "name": "Bédarieux",
        "latitude": "43.61553000",
        "longitude": "3.15714000"
    },
    {
        "id": 40413,
        "name": "Bédarrides",
        "latitude": "44.04022000",
        "longitude": "4.89819000"
    },
    {
        "id": 40415,
        "name": "Bédée",
        "latitude": "48.17953000",
        "longitude": "-1.94477000"
    },
    {
        "id": 40414,
        "name": "Bédoin",
        "latitude": "44.12432000",
        "longitude": "5.18040000"
    },
    {
        "id": 40416,
        "name": "Bégaar",
        "latitude": "43.82363000",
        "longitude": "-0.84904000"
    },
    {
        "id": 40417,
        "name": "Béganne",
        "latitude": "47.59670000",
        "longitude": "-2.24162000"
    },
    {
        "id": 40418,
        "name": "Bégard",
        "latitude": "48.62754000",
        "longitude": "-3.30067000"
    },
    {
        "id": 40410,
        "name": "Bègles",
        "latitude": "44.80845000",
        "longitude": "-0.54809000"
    },
    {
        "id": 40419,
        "name": "Bégrolles-en-Mauges",
        "latitude": "47.14048000",
        "longitude": "-0.94000000"
    },
    {
        "id": 39899,
        "name": "Behren-lès-Forbach",
        "latitude": "49.16949000",
        "longitude": "6.93933000"
    },
    {
        "id": 39900,
        "name": "Beignon",
        "latitude": "47.97200000",
        "longitude": "-2.16933000"
    },
    {
        "id": 40422,
        "name": "Bélâbre",
        "latitude": "46.55121000",
        "longitude": "1.15791000"
    },
    {
        "id": 39901,
        "name": "Belberaud",
        "latitude": "43.50557000",
        "longitude": "1.56725000"
    },
    {
        "id": 39902,
        "name": "Belbeuf",
        "latitude": "49.38766000",
        "longitude": "1.14245000"
    },
    {
        "id": 39903,
        "name": "Belcodène",
        "latitude": "43.42612000",
        "longitude": "5.58878000"
    },
    {
        "id": 40420,
        "name": "Bélesta",
        "latitude": "42.90228000",
        "longitude": "1.93325000"
    },
    {
        "id": 39904,
        "name": "Belfort",
        "latitude": "47.64218000",
        "longitude": "6.85385000"
    },
    {
        "id": 39905,
        "name": "Belgentier",
        "latitude": "43.24535000",
        "longitude": "5.99933000"
    },
    {
        "id": 40421,
        "name": "Béligneux",
        "latitude": "45.86775000",
        "longitude": "5.12747000"
    },
    {
        "id": 39906,
        "name": "Belin-Béliet",
        "latitude": "44.50000000",
        "longitude": "-0.78333000"
    },
    {
        "id": 39907,
        "name": "Bellac",
        "latitude": "46.12209000",
        "longitude": "1.04931000"
    },
    {
        "id": 39908,
        "name": "Bellaing",
        "latitude": "50.36811000",
        "longitude": "3.42515000"
    },
    {
        "id": 39909,
        "name": "Belle de Mai",
        "latitude": "43.31184000",
        "longitude": "5.38541000"
    },
    {
        "id": 39910,
        "name": "Belle-Isle-en-Terre",
        "latitude": "48.54478000",
        "longitude": "-3.39500000"
    },
    {
        "id": 39911,
        "name": "Belle-Plagne",
        "latitude": "45.50932000",
        "longitude": "6.70685000"
    },
    {
        "id": 39912,
        "name": "Bellegarde",
        "latitude": "47.98333000",
        "longitude": "2.43333000"
    },
    {
        "id": 39913,
        "name": "Bellegarde",
        "latitude": "43.75329000",
        "longitude": "4.51654000"
    },
    {
        "id": 39914,
        "name": "Bellegarde-en-Forez",
        "latitude": "45.64469000",
        "longitude": "4.29721000"
    },
    {
        "id": 39915,
        "name": "Bellegarde-sur-Valserine",
        "latitude": "46.10787000",
        "longitude": "5.82421000"
    },
    {
        "id": 39931,
        "name": "Bellême",
        "latitude": "48.37329000",
        "longitude": "0.57005000"
    },
    {
        "id": 39916,
        "name": "Bellenaves",
        "latitude": "46.20045000",
        "longitude": "3.07995000"
    },
    {
        "id": 39917,
        "name": "Belleneuve",
        "latitude": "47.36224000",
        "longitude": "5.26393000"
    },
    {
        "id": 39918,
        "name": "Bellengreville",
        "latitude": "49.12425000",
        "longitude": "-0.20961000"
    },
    {
        "id": 39919,
        "name": "Bellerive-sur-Allier",
        "latitude": "46.11652000",
        "longitude": "3.40406000"
    },
    {
        "id": 39920,
        "name": "Belleu",
        "latitude": "49.35917000",
        "longitude": "3.33556000"
    },
    {
        "id": 39921,
        "name": "Bellevaux",
        "latitude": "46.25875000",
        "longitude": "6.53351000"
    },
    {
        "id": 39922,
        "name": "Belleville",
        "latitude": "46.10867000",
        "longitude": "4.74920000"
    },
    {
        "id": 39923,
        "name": "Belleville",
        "latitude": "48.81817000",
        "longitude": "6.10294000"
    },
    {
        "id": 39924,
        "name": "Belleville-sur-Loire",
        "latitude": "47.50000000",
        "longitude": "2.85000000"
    },
    {
        "id": 39925,
        "name": "Belleville-sur-Meuse",
        "latitude": "49.17863000",
        "longitude": "5.37190000"
    },
    {
        "id": 39926,
        "name": "Belleville-sur-Vie",
        "latitude": "46.78369000",
        "longitude": "-1.42905000"
    },
    {
        "id": 39927,
        "name": "Belley",
        "latitude": "45.75917000",
        "longitude": "5.68813000"
    },
    {
        "id": 39928,
        "name": "Bellignat",
        "latitude": "46.24237000",
        "longitude": "5.62843000"
    },
    {
        "id": 39929,
        "name": "Belligné",
        "latitude": "47.46774000",
        "longitude": "-1.02780000"
    },
    {
        "id": 39930,
        "name": "Belloy-en-France",
        "latitude": "49.08837000",
        "longitude": "2.37159000"
    },
    {
        "id": 39932,
        "name": "Belmont-de-la-Loire",
        "latitude": "46.16596000",
        "longitude": "4.34737000"
    },
    {
        "id": 39933,
        "name": "Belmont-sur-Rance",
        "latitude": "43.81981000",
        "longitude": "2.75524000"
    },
    {
        "id": 39934,
        "name": "Belpech",
        "latitude": "43.19957000",
        "longitude": "1.75157000"
    },
    {
        "id": 39935,
        "name": "Belsunce",
        "latitude": "43.29834000",
        "longitude": "5.37660000"
    },
    {
        "id": 39936,
        "name": "Belvès",
        "latitude": "44.77632000",
        "longitude": "1.00588000"
    },
    {
        "id": 39937,
        "name": "Belz",
        "latitude": "47.67506000",
        "longitude": "-3.16800000"
    },
    {
        "id": 40427,
        "name": "Bénéjacq",
        "latitude": "43.20000000",
        "longitude": "-0.21667000"
    },
    {
        "id": 40423,
        "name": "Bénesse-Maremne",
        "latitude": "43.63678000",
        "longitude": "-1.35773000"
    },
    {
        "id": 39938,
        "name": "Benet",
        "latitude": "46.36972000",
        "longitude": "-0.59333000"
    },
    {
        "id": 39939,
        "name": "Benfeld",
        "latitude": "48.37062000",
        "longitude": "7.59370000"
    },
    {
        "id": 40424,
        "name": "Béning-lès-Saint-Avold",
        "latitude": "49.13504000",
        "longitude": "6.83902000"
    },
    {
        "id": 39940,
        "name": "Bennecourt",
        "latitude": "49.04148000",
        "longitude": "1.55469000"
    },
    {
        "id": 39941,
        "name": "Bennwihr",
        "latitude": "48.14456000",
        "longitude": "7.32445000"
    },
    {
        "id": 40425,
        "name": "Bénodet",
        "latitude": "47.87531000",
        "longitude": "-4.10580000"
    },
    {
        "id": 40426,
        "name": "Bénouville",
        "latitude": "49.24151000",
        "longitude": "-0.28246000"
    },
    {
        "id": 39942,
        "name": "Benquet",
        "latitude": "43.82992000",
        "longitude": "-0.50102000"
    },
    {
        "id": 40428,
        "name": "Bérat",
        "latitude": "43.37857000",
        "longitude": "1.17572000"
    },
    {
        "id": 39943,
        "name": "Berck",
        "latitude": "50.40000000",
        "longitude": "1.60000000"
    },
    {
        "id": 39944,
        "name": "Berck-Plage",
        "latitude": "50.40704000",
        "longitude": "1.56446000"
    },
    {
        "id": 39945,
        "name": "Bergerac",
        "latitude": "44.85118000",
        "longitude": "0.48200000"
    },
    {
        "id": 39946,
        "name": "Bergheim",
        "latitude": "48.20540000",
        "longitude": "7.36299000"
    },
    {
        "id": 39947,
        "name": "Bergholtz",
        "latitude": "47.91670000",
        "longitude": "7.24651000"
    },
    {
        "id": 39948,
        "name": "Bergues",
        "latitude": "50.96882000",
        "longitude": "2.43242000"
    },
    {
        "id": 39949,
        "name": "Berlaimont",
        "latitude": "50.20155000",
        "longitude": "3.81343000"
    },
    {
        "id": 39950,
        "name": "Bernardswiller",
        "latitude": "48.45035000",
        "longitude": "7.46238000"
    },
    {
        "id": 39951,
        "name": "Bernaville",
        "latitude": "50.13232000",
        "longitude": "2.16300000"
    },
    {
        "id": 39952,
        "name": "Bernay",
        "latitude": "49.08888000",
        "longitude": "0.59858000"
    },
    {
        "id": 39958,
        "name": "Berné",
        "latitude": "47.99470000",
        "longitude": "-3.39421000"
    },
    {
        "id": 39953,
        "name": "Bernes-sur-Oise",
        "latitude": "49.16128000",
        "longitude": "2.30000000"
    },
    {
        "id": 39954,
        "name": "Berneval-le-Grand",
        "latitude": "49.95328000",
        "longitude": "1.18755000"
    },
    {
        "id": 39957,
        "name": "Bernières-sur-Mer",
        "latitude": "49.33057000",
        "longitude": "-0.42299000"
    },
    {
        "id": 39955,
        "name": "Bernin",
        "latitude": "45.26772000",
        "longitude": "5.86457000"
    },
    {
        "id": 39956,
        "name": "Bernis",
        "latitude": "43.76913000",
        "longitude": "4.28713000"
    },
    {
        "id": 39959,
        "name": "Berre-l'Étang",
        "latitude": "43.47809000",
        "longitude": "5.17044000"
    },
    {
        "id": 39960,
        "name": "Berre-les-Alpes",
        "latitude": "43.83052000",
        "longitude": "7.32877000"
    },
    {
        "id": 39961,
        "name": "Berric",
        "latitude": "47.63326000",
        "longitude": "-2.52250000"
    },
    {
        "id": 39962,
        "name": "Berrien",
        "latitude": "48.40278000",
        "longitude": "-3.75266000"
    },
    {
        "id": 39963,
        "name": "Berrwiller",
        "latitude": "47.84984000",
        "longitude": "7.21922000"
    },
    {
        "id": 39966,
        "name": "Bersée",
        "latitude": "50.47978000",
        "longitude": "3.14765000"
    },
    {
        "id": 39964,
        "name": "Berson",
        "latitude": "45.10679000",
        "longitude": "-0.58774000"
    },
    {
        "id": 39965,
        "name": "Berstett",
        "latitude": "48.67900000",
        "longitude": "7.65721000"
    },
    {
        "id": 39967,
        "name": "Berteaucourt-les-Dames",
        "latitude": "50.04697000",
        "longitude": "2.15750000"
    },
    {
        "id": 39968,
        "name": "Berthecourt",
        "latitude": "49.35097000",
        "longitude": "2.22471000"
    },
    {
        "id": 39969,
        "name": "Bertrange",
        "latitude": "49.31368000",
        "longitude": "6.19208000"
    },
    {
        "id": 39970,
        "name": "Bertry",
        "latitude": "50.08718000",
        "longitude": "3.44298000"
    },
    {
        "id": 40429,
        "name": "Béruges",
        "latitude": "46.56738000",
        "longitude": "0.20741000"
    },
    {
        "id": 39971,
        "name": "Besançon",
        "latitude": "47.24878000",
        "longitude": "6.01815000"
    },
    {
        "id": 39972,
        "name": "Besné",
        "latitude": "47.40000000",
        "longitude": "-2.08976000"
    },
    {
        "id": 39973,
        "name": "Bessan",
        "latitude": "43.36196000",
        "longitude": "3.42288000"
    },
    {
        "id": 39974,
        "name": "Bessancourt",
        "latitude": "49.03765000",
        "longitude": "2.20936000"
    },
    {
        "id": 39975,
        "name": "Bessay-sur-Allier",
        "latitude": "46.44199000",
        "longitude": "3.36257000"
    },
    {
        "id": 39976,
        "name": "Besse-et-Saint-Anastaise",
        "latitude": "45.51667000",
        "longitude": "2.93333000"
    },
    {
        "id": 39983,
        "name": "Bessé-sur-Braye",
        "latitude": "47.83333000",
        "longitude": "0.75000000"
    },
    {
        "id": 39977,
        "name": "Besse-sur-Issole",
        "latitude": "43.34892000",
        "longitude": "6.17656000"
    },
    {
        "id": 39982,
        "name": "Bessèges",
        "latitude": "44.29230000",
        "longitude": "4.09661000"
    },
    {
        "id": 39978,
        "name": "Bessenay",
        "latitude": "45.77661000",
        "longitude": "4.55441000"
    },
    {
        "id": 39981,
        "name": "Bessières",
        "latitude": "43.79861000",
        "longitude": "1.60624000"
    },
    {
        "id": 39979,
        "name": "Bessines",
        "latitude": "46.30207000",
        "longitude": "-0.51294000"
    },
    {
        "id": 39980,
        "name": "Bessines-sur-Gartempe",
        "latitude": "46.10799000",
        "longitude": "1.36865000"
    },
    {
        "id": 40430,
        "name": "Béthencourt-sur-Mer",
        "latitude": "50.07756000",
        "longitude": "1.50348000"
    },
    {
        "id": 40431,
        "name": "Bétheny",
        "latitude": "49.28498000",
        "longitude": "4.05495000"
    },
    {
        "id": 40432,
        "name": "Béthisy-Saint-Martin",
        "latitude": "49.29480000",
        "longitude": "2.81743000"
    },
    {
        "id": 40433,
        "name": "Béthisy-Saint-Pierre",
        "latitude": "49.30482000",
        "longitude": "2.79636000"
    },
    {
        "id": 39984,
        "name": "Bethoncourt",
        "latitude": "47.53512000",
        "longitude": "6.80504000"
    },
    {
        "id": 40434,
        "name": "Béthune",
        "latitude": "50.52965000",
        "longitude": "2.64003000"
    },
    {
        "id": 39985,
        "name": "Betschdorf",
        "latitude": "48.89825000",
        "longitude": "7.90196000"
    },
    {
        "id": 39986,
        "name": "Bettancourt-la-Ferrée",
        "latitude": "48.64898000",
        "longitude": "4.96971000"
    },
    {
        "id": 39987,
        "name": "Betton",
        "latitude": "48.18048000",
        "longitude": "-1.63829000"
    },
    {
        "id": 39988,
        "name": "Betz",
        "latitude": "49.15558000",
        "longitude": "2.95584000"
    },
    {
        "id": 39989,
        "name": "Beure",
        "latitude": "47.20690000",
        "longitude": "6.00548000"
    },
    {
        "id": 39990,
        "name": "Beuville",
        "latitude": "49.24364000",
        "longitude": "-0.32585000"
    },
    {
        "id": 39991,
        "name": "Beuvillers",
        "latitude": "49.12961000",
        "longitude": "0.25492000"
    },
    {
        "id": 39992,
        "name": "Beuvrages",
        "latitude": "50.38414000",
        "longitude": "3.49420000"
    },
    {
        "id": 39993,
        "name": "Beuvry",
        "latitude": "50.51674000",
        "longitude": "2.68541000"
    },
    {
        "id": 39994,
        "name": "Beuzec-Cap-Sizun",
        "latitude": "48.07567000",
        "longitude": "-4.51200000"
    },
    {
        "id": 39995,
        "name": "Beuzeville",
        "latitude": "49.34483000",
        "longitude": "0.34254000"
    },
    {
        "id": 39996,
        "name": "Beuzeville-la-Grenier",
        "latitude": "49.59157000",
        "longitude": "0.42684000"
    },
    {
        "id": 40435,
        "name": "Béville-le-Comte",
        "latitude": "48.43558000",
        "longitude": "1.71305000"
    },
    {
        "id": 39997,
        "name": "Beychac-et-Caillau",
        "latitude": "44.87781000",
        "longitude": "-0.40219000"
    },
    {
        "id": 39998,
        "name": "Beynat",
        "latitude": "45.12444000",
        "longitude": "1.72323000"
    },
    {
        "id": 39999,
        "name": "Beynes",
        "latitude": "48.85626000",
        "longitude": "1.87261000"
    },
    {
        "id": 40000,
        "name": "Beynost",
        "latitude": "45.83569000",
        "longitude": "4.99910000"
    },
    {
        "id": 40001,
        "name": "Bezannes",
        "latitude": "49.22339000",
        "longitude": "3.98892000"
    },
    {
        "id": 40436,
        "name": "Béziers",
        "latitude": "43.34122000",
        "longitude": "3.21402000"
    },
    {
        "id": 40002,
        "name": "Bezons",
        "latitude": "48.92426000",
        "longitude": "2.21280000"
    },
    {
        "id": 40003,
        "name": "Bezouce",
        "latitude": "43.88229000",
        "longitude": "4.49072000"
    },
    {
        "id": 40437,
        "name": "Bézu-Saint-Éloi",
        "latitude": "49.29524000",
        "longitude": "1.70404000"
    },
    {
        "id": 40004,
        "name": "Biache-Saint-Vaast",
        "latitude": "50.30869000",
        "longitude": "2.94777000"
    },
    {
        "id": 40005,
        "name": "Biard",
        "latitude": "46.57889000",
        "longitude": "0.30812000"
    },
    {
        "id": 40006,
        "name": "Biarritz",
        "latitude": "43.48012000",
        "longitude": "-1.55558000"
    },
    {
        "id": 40007,
        "name": "Biars-sur-Cère",
        "latitude": "44.92629000",
        "longitude": "1.85403000"
    },
    {
        "id": 40008,
        "name": "Bias",
        "latitude": "44.41655000",
        "longitude": "0.66977000"
    },
    {
        "id": 40009,
        "name": "Bidache",
        "latitude": "43.48299000",
        "longitude": "-1.14121000"
    },
    {
        "id": 40010,
        "name": "Bidart",
        "latitude": "43.43760000",
        "longitude": "-1.59127000"
    },
    {
        "id": 40011,
        "name": "Bienville",
        "latitude": "48.57582000",
        "longitude": "5.04579000"
    },
    {
        "id": 40012,
        "name": "Bierne",
        "latitude": "50.96232000",
        "longitude": "2.40963000"
    },
    {
        "id": 40013,
        "name": "Biesheim",
        "latitude": "48.04118000",
        "longitude": "7.54474000"
    },
    {
        "id": 40014,
        "name": "Biesles",
        "latitude": "48.08597000",
        "longitude": "5.29409000"
    },
    {
        "id": 40041,
        "name": "Biéville-Beuville",
        "latitude": "49.24293000",
        "longitude": "-0.32762000"
    },
    {
        "id": 40040,
        "name": "Bièvres",
        "latitude": "48.75772000",
        "longitude": "2.21881000"
    },
    {
        "id": 40015,
        "name": "Biganos",
        "latitude": "44.64504000",
        "longitude": "-0.97367000"
    },
    {
        "id": 40016,
        "name": "Bignan",
        "latitude": "47.87935000",
        "longitude": "-2.77153000"
    },
    {
        "id": 40017,
        "name": "Bignoux",
        "latitude": "46.60008000",
        "longitude": "0.46932000"
    },
    {
        "id": 40018,
        "name": "Biguglia",
        "latitude": "42.62692000",
        "longitude": "9.42018000"
    },
    {
        "id": 40019,
        "name": "Bihorel",
        "latitude": "49.45468000",
        "longitude": "1.12230000"
    },
    {
        "id": 40020,
        "name": "Bilieu",
        "latitude": "45.44809000",
        "longitude": "5.54268000"
    },
    {
        "id": 40025,
        "name": "Billère",
        "latitude": "43.30000000",
        "longitude": "-0.40000000"
    },
    {
        "id": 40021,
        "name": "Billom",
        "latitude": "45.72267000",
        "longitude": "3.33869000"
    },
    {
        "id": 40022,
        "name": "Billy-Berclau",
        "latitude": "50.51783000",
        "longitude": "2.86613000"
    },
    {
        "id": 40023,
        "name": "Billy-Montigny",
        "latitude": "50.41711000",
        "longitude": "2.90286000"
    },
    {
        "id": 40024,
        "name": "Billy-sur-Aisne",
        "latitude": "49.35632000",
        "longitude": "3.38357000"
    },
    {
        "id": 40026,
        "name": "Binic",
        "latitude": "48.60074000",
        "longitude": "-2.82602000"
    },
    {
        "id": 40027,
        "name": "Bining",
        "latitude": "49.03729000",
        "longitude": "7.25273000"
    },
    {
        "id": 40028,
        "name": "Biol",
        "latitude": "45.49140000",
        "longitude": "5.38550000"
    },
    {
        "id": 40029,
        "name": "Biot",
        "latitude": "43.62852000",
        "longitude": "7.09530000"
    },
    {
        "id": 40030,
        "name": "Biscarrosse",
        "latitude": "44.39454000",
        "longitude": "-1.16721000"
    },
    {
        "id": 40031,
        "name": "Bischheim",
        "latitude": "48.61612000",
        "longitude": "7.75343000"
    },
    {
        "id": 40032,
        "name": "Bischoffsheim",
        "latitude": "48.48703000",
        "longitude": "7.48967000"
    },
    {
        "id": 40033,
        "name": "Bischwiller",
        "latitude": "48.76826000",
        "longitude": "7.85406000"
    },
    {
        "id": 40034,
        "name": "Bitche",
        "latitude": "49.05232000",
        "longitude": "7.42992000"
    },
    {
        "id": 40035,
        "name": "Bitschwiller-lès-Thann",
        "latitude": "47.82969000",
        "longitude": "7.07911000"
    },
    {
        "id": 40036,
        "name": "Biviers",
        "latitude": "45.23333000",
        "longitude": "5.80000000"
    },
    {
        "id": 40037,
        "name": "Bizanet",
        "latitude": "43.16419000",
        "longitude": "2.87034000"
    },
    {
        "id": 40038,
        "name": "Bizanos",
        "latitude": "43.28333000",
        "longitude": "-0.35000000"
    },
    {
        "id": 40039,
        "name": "Bize-Minervois",
        "latitude": "43.31656000",
        "longitude": "2.87134000"
    },
    {
        "id": 40042,
        "name": "Blacé",
        "latitude": "46.03152000",
        "longitude": "4.64448000"
    },
    {
        "id": 40043,
        "name": "Blaesheim",
        "latitude": "48.50648000",
        "longitude": "7.60923000"
    },
    {
        "id": 40044,
        "name": "Blagnac",
        "latitude": "43.63276000",
        "longitude": "1.39399000"
    },
    {
        "id": 40045,
        "name": "Blagny",
        "latitude": "49.62167000",
        "longitude": "5.19194000"
    },
    {
        "id": 40046,
        "name": "Blain",
        "latitude": "47.47655000",
        "longitude": "-1.76285000"
    },
    {
        "id": 40047,
        "name": "Blaincourt-lès-Précy",
        "latitude": "49.23333000",
        "longitude": "2.35000000"
    },
    {
        "id": 40048,
        "name": "Blainville-Crevon",
        "latitude": "49.50395000",
        "longitude": "1.29952000"
    },
    {
        "id": 40049,
        "name": "Blainville-sur-Mer",
        "latitude": "49.06599000",
        "longitude": "-1.58340000"
    },
    {
        "id": 40050,
        "name": "Blainville-sur-Orne",
        "latitude": "49.22913000",
        "longitude": "-0.30061000"
    },
    {
        "id": 40051,
        "name": "Blaison-Gohier",
        "latitude": "47.39951000",
        "longitude": "-0.37723000"
    },
    {
        "id": 40052,
        "name": "Blamont",
        "latitude": "47.38513000",
        "longitude": "6.84800000"
    },
    {
        "id": 40053,
        "name": "Blancafort",
        "latitude": "47.53219000",
        "longitude": "2.52981000"
    },
    {
        "id": 40054,
        "name": "Blangy-sur-Bresle",
        "latitude": "49.93211000",
        "longitude": "1.62514000"
    },
    {
        "id": 40055,
        "name": "Blanquefort",
        "latitude": "44.91248000",
        "longitude": "-0.63663000"
    },
    {
        "id": 40056,
        "name": "Blanzat",
        "latitude": "45.82975000",
        "longitude": "3.07794000"
    },
    {
        "id": 40057,
        "name": "Blanzy",
        "latitude": "46.70012000",
        "longitude": "4.38833000"
    },
    {
        "id": 40058,
        "name": "Blaringhem",
        "latitude": "50.69110000",
        "longitude": "2.40321000"
    },
    {
        "id": 40059,
        "name": "Blausasc",
        "latitude": "43.80572000",
        "longitude": "7.36477000"
    },
    {
        "id": 40060,
        "name": "Blauzac",
        "latitude": "43.96151000",
        "longitude": "4.36930000"
    },
    {
        "id": 40061,
        "name": "Blavozy",
        "latitude": "45.05720000",
        "longitude": "3.97993000"
    },
    {
        "id": 40062,
        "name": "Blaye",
        "latitude": "45.12782000",
        "longitude": "-0.66230000"
    },
    {
        "id": 40063,
        "name": "Blaye-les-Mines",
        "latitude": "44.03073000",
        "longitude": "2.13166000"
    },
    {
        "id": 40064,
        "name": "Blendecques",
        "latitude": "50.71843000",
        "longitude": "2.28601000"
    },
    {
        "id": 40072,
        "name": "Bléneau",
        "latitude": "47.70000000",
        "longitude": "2.95000000"
    },
    {
        "id": 40073,
        "name": "Blénod-lès-Pont-à-Mousson",
        "latitude": "48.88487000",
        "longitude": "6.04844000"
    },
    {
        "id": 40074,
        "name": "Blénod-lès-Toul",
        "latitude": "48.59882000",
        "longitude": "5.83685000"
    },
    {
        "id": 40075,
        "name": "Blérancourt",
        "latitude": "49.51667000",
        "longitude": "3.15000000"
    },
    {
        "id": 40076,
        "name": "Bléré",
        "latitude": "47.32738000",
        "longitude": "0.99186000"
    },
    {
        "id": 40065,
        "name": "Bletterans",
        "latitude": "46.74673000",
        "longitude": "5.45444000"
    },
    {
        "id": 40066,
        "name": "Bliesbruck",
        "latitude": "49.11543000",
        "longitude": "7.18112000"
    },
    {
        "id": 40067,
        "name": "Bligny-lès-Beaune",
        "latitude": "46.98631000",
        "longitude": "4.82620000"
    },
    {
        "id": 40068,
        "name": "Blodelsheim",
        "latitude": "47.88538000",
        "longitude": "7.53635000"
    },
    {
        "id": 40069,
        "name": "Blois",
        "latitude": "47.59432000",
        "longitude": "1.32912000"
    },
    {
        "id": 40070,
        "name": "Blonville-sur-Mer",
        "latitude": "49.33709000",
        "longitude": "0.02709000"
    },
    {
        "id": 40071,
        "name": "Blotzheim",
        "latitude": "47.60260000",
        "longitude": "7.49654000"
    },
    {
        "id": 40077,
        "name": "Bobigny",
        "latitude": "48.90982000",
        "longitude": "2.45012000"
    },
    {
        "id": 40078,
        "name": "Bodilis",
        "latitude": "48.52978000",
        "longitude": "-4.11567000"
    },
    {
        "id": 40244,
        "name": "Boé",
        "latitude": "44.16007000",
        "longitude": "0.62905000"
    },
    {
        "id": 40245,
        "name": "Boëge",
        "latitude": "46.20711000",
        "longitude": "6.40428000"
    },
    {
        "id": 40079,
        "name": "Boeil-Bezing",
        "latitude": "43.21667000",
        "longitude": "-0.26667000"
    },
    {
        "id": 40246,
        "name": "Boën-sur-Lignon",
        "latitude": "45.75114000",
        "longitude": "4.00725000"
    },
    {
        "id": 40439,
        "name": "Bœrsch",
        "latitude": "48.47656000",
        "longitude": "7.43998000"
    },
    {
        "id": 40080,
        "name": "Boeschepe",
        "latitude": "50.80000000",
        "longitude": "2.70000000"
    },
    {
        "id": 40081,
        "name": "Bohain-en-Vermandois",
        "latitude": "49.98730000",
        "longitude": "3.45300000"
    },
    {
        "id": 40082,
        "name": "Bohars",
        "latitude": "48.42983000",
        "longitude": "-4.51292000"
    },
    {
        "id": 40083,
        "name": "Boigny-sur-Bionne",
        "latitude": "47.93333000",
        "longitude": "2.01667000"
    },
    {
        "id": 40084,
        "name": "Bois-Colombes",
        "latitude": "48.91936000",
        "longitude": "2.27485000"
    },
    {
        "id": 40088,
        "name": "Bois-d’Amont",
        "latitude": "46.53280000",
        "longitude": "6.13750000"
    },
    {
        "id": 40089,
        "name": "Bois-d’Arcy",
        "latitude": "48.79966000",
        "longitude": "2.02325000"
    },
    {
        "id": 40087,
        "name": "Bois-de-Cené",
        "latitude": "46.93689000",
        "longitude": "-1.88656000"
    },
    {
        "id": 40085,
        "name": "Bois-Grenier",
        "latitude": "50.64985000",
        "longitude": "2.87409000"
    },
    {
        "id": 40086,
        "name": "Bois-Guillaume",
        "latitude": "49.46020000",
        "longitude": "1.12219000"
    },
    {
        "id": 40090,
        "name": "Bois-le-Roi",
        "latitude": "48.47348000",
        "longitude": "2.70464000"
    },
    {
        "id": 40091,
        "name": "Boiscommun",
        "latitude": "48.03576000",
        "longitude": "2.38333000"
    },
    {
        "id": 40092,
        "name": "Boisgervilly",
        "latitude": "48.16692000",
        "longitude": "-2.06426000"
    },
    {
        "id": 40093,
        "name": "Boismé",
        "latitude": "46.77393000",
        "longitude": "-0.43476000"
    },
    {
        "id": 40094,
        "name": "Boisseron",
        "latitude": "43.75795000",
        "longitude": "4.07970000"
    },
    {
        "id": 40095,
        "name": "Boisset-et-Gaujac",
        "latitude": "44.04749000",
        "longitude": "4.00861000"
    },
    {
        "id": 40096,
        "name": "Boisseuil",
        "latitude": "45.76977000",
        "longitude": "1.33333000"
    },
    {
        "id": 40097,
        "name": "Boissise-le-Roi",
        "latitude": "48.52479000",
        "longitude": "2.56971000"
    },
    {
        "id": 40099,
        "name": "Boissy-le-Châtel",
        "latitude": "48.82073000",
        "longitude": "3.13651000"
    },
    {
        "id": 40100,
        "name": "Boissy-le-Cutté",
        "latitude": "48.47020000",
        "longitude": "2.28326000"
    },
    {
        "id": 40098,
        "name": "Boissy-Saint-Léger",
        "latitude": "48.75149000",
        "longitude": "2.51163000"
    },
    {
        "id": 40101,
        "name": "Boissy-sous-Saint-Yon",
        "latitude": "48.55379000",
        "longitude": "2.21212000"
    },
    {
        "id": 40102,
        "name": "Bolbec",
        "latitude": "49.57321000",
        "longitude": "0.47339000"
    },
    {
        "id": 40105,
        "name": "Bollène",
        "latitude": "44.28124000",
        "longitude": "4.74891000"
    },
    {
        "id": 40103,
        "name": "Bollezeele",
        "latitude": "50.86687000",
        "longitude": "2.34751000"
    },
    {
        "id": 40104,
        "name": "Bollwiller",
        "latitude": "47.85832000",
        "longitude": "7.26179000"
    },
    {
        "id": 40106,
        "name": "Bologne",
        "latitude": "48.20005000",
        "longitude": "5.14209000"
    },
    {
        "id": 40107,
        "name": "Bompas",
        "latitude": "42.73333000",
        "longitude": "2.93333000"
    },
    {
        "id": 40108,
        "name": "Bon-Encontre",
        "latitude": "44.18518000",
        "longitude": "0.66759000"
    },
    {
        "id": 40109,
        "name": "Bon-Secours",
        "latitude": "43.31923000",
        "longitude": "5.38426000"
    },
    {
        "id": 40110,
        "name": "Bonchamp-lès-Laval",
        "latitude": "48.07357000",
        "longitude": "-0.70000000"
    },
    {
        "id": 40111,
        "name": "Bondoufle",
        "latitude": "48.61294000",
        "longitude": "2.37775000"
    },
    {
        "id": 40112,
        "name": "Bondues",
        "latitude": "50.70196000",
        "longitude": "3.09497000"
    },
    {
        "id": 40113,
        "name": "Bondy",
        "latitude": "48.90180000",
        "longitude": "2.48931000"
    },
    {
        "id": 40114,
        "name": "Bonifacio",
        "latitude": "41.38740000",
        "longitude": "9.15941000"
    },
    {
        "id": 40115,
        "name": "Bonnac-la-Côte",
        "latitude": "45.94212000",
        "longitude": "1.28417000"
    },
    {
        "id": 40116,
        "name": "Bonnat",
        "latitude": "46.33333000",
        "longitude": "1.90000000"
    },
    {
        "id": 40117,
        "name": "Bonne",
        "latitude": "46.17207000",
        "longitude": "6.32443000"
    },
    {
        "id": 40118,
        "name": "Bonnefamille",
        "latitude": "45.59956000",
        "longitude": "5.12489000"
    },
    {
        "id": 40119,
        "name": "Bonnelles",
        "latitude": "48.61816000",
        "longitude": "2.02922000"
    },
    {
        "id": 40120,
        "name": "Bonnemain",
        "latitude": "48.46652000",
        "longitude": "-1.76774000"
    },
    {
        "id": 40121,
        "name": "Bonnes",
        "latitude": "46.60386000",
        "longitude": "0.59791000"
    },
    {
        "id": 40130,
        "name": "Bonnétable",
        "latitude": "48.17908000",
        "longitude": "0.42570000"
    },
    {
        "id": 40122,
        "name": "Bonneuil-Matours",
        "latitude": "46.68155000",
        "longitude": "0.57063000"
    },
    {
        "id": 40123,
        "name": "Bonneuil-sur-Marne",
        "latitude": "48.76950000",
        "longitude": "2.47930000"
    },
    {
        "id": 40124,
        "name": "Bonneval",
        "latitude": "48.18312000",
        "longitude": "1.38524000"
    },
    {
        "id": 40125,
        "name": "Bonneveine",
        "latitude": "43.25000000",
        "longitude": "5.38333000"
    },
    {
        "id": 40126,
        "name": "Bonneville",
        "latitude": "46.08020000",
        "longitude": "6.40726000"
    },
    {
        "id": 40128,
        "name": "Bonnières-sur-Seine",
        "latitude": "49.03525000",
        "longitude": "1.57830000"
    },
    {
        "id": 40127,
        "name": "Bonnieux",
        "latitude": "43.82396000",
        "longitude": "5.30759000"
    },
    {
        "id": 40129,
        "name": "Bonny-sur-Loire",
        "latitude": "47.56172000",
        "longitude": "2.83933000"
    },
    {
        "id": 40131,
        "name": "Bons-en-Chablais",
        "latitude": "46.26486000",
        "longitude": "6.37129000"
    },
    {
        "id": 40132,
        "name": "Bonson",
        "latitude": "45.52291000",
        "longitude": "4.21270000"
    },
    {
        "id": 40133,
        "name": "Boofzheim",
        "latitude": "48.33186000",
        "longitude": "7.68071000"
    },
    {
        "id": 40134,
        "name": "Boos",
        "latitude": "49.38849000",
        "longitude": "1.20348000"
    },
    {
        "id": 40135,
        "name": "Boran-sur-Oise",
        "latitude": "49.16715000",
        "longitude": "2.35803000"
    },
    {
        "id": 40136,
        "name": "Bordeaux",
        "latitude": "44.84044000",
        "longitude": "-0.58050000"
    },
    {
        "id": 40137,
        "name": "Bordes",
        "latitude": "43.23333000",
        "longitude": "-0.28333000"
    },
    {
        "id": 40138,
        "name": "Bords",
        "latitude": "45.89722000",
        "longitude": "-0.79528000"
    },
    {
        "id": 40139,
        "name": "Borel",
        "latitude": "43.36486000",
        "longitude": "5.36681000"
    },
    {
        "id": 40140,
        "name": "Borgo",
        "latitude": "42.55488000",
        "longitude": "9.42636000"
    },
    {
        "id": 40141,
        "name": "Bormes-les-Mimosas",
        "latitude": "43.15169000",
        "longitude": "6.34220000"
    },
    {
        "id": 40142,
        "name": "Bornel",
        "latitude": "49.19820000",
        "longitude": "2.20912000"
    },
    {
        "id": 40143,
        "name": "Bort-les-Orgues",
        "latitude": "45.39980000",
        "longitude": "2.49579000"
    },
    {
        "id": 40144,
        "name": "Bosc-le-Hard",
        "latitude": "49.62734000",
        "longitude": "1.17483000"
    },
    {
        "id": 40145,
        "name": "Bosdarros",
        "latitude": "43.21667000",
        "longitude": "-0.36667000"
    },
    {
        "id": 40146,
        "name": "Bosmie-l'Aiguille",
        "latitude": "45.75000000",
        "longitude": "1.20000000"
    },
    {
        "id": 40147,
        "name": "Bouafle",
        "latitude": "48.96463000",
        "longitude": "1.90120000"
    },
    {
        "id": 40148,
        "name": "Bouaye",
        "latitude": "47.14263000",
        "longitude": "-1.69306000"
    },
    {
        "id": 40149,
        "name": "Bouc-Bel-Air",
        "latitude": "43.45217000",
        "longitude": "5.41300000"
    },
    {
        "id": 40150,
        "name": "Boucau",
        "latitude": "43.52770000",
        "longitude": "-1.46556000"
    },
    {
        "id": 40151,
        "name": "Bouchain",
        "latitude": "50.28519000",
        "longitude": "3.31491000"
    },
    {
        "id": 40152,
        "name": "Bouchemaine",
        "latitude": "47.42234000",
        "longitude": "-0.60888000"
    },
    {
        "id": 40238,
        "name": "Boué",
        "latitude": "50.01141000",
        "longitude": "3.69608000"
    },
    {
        "id": 40153,
        "name": "Bouffémont",
        "latitude": "49.04377000",
        "longitude": "2.29796000"
    },
    {
        "id": 40154,
        "name": "Boufféré",
        "latitude": "46.96179000",
        "longitude": "-1.33965000"
    },
    {
        "id": 40155,
        "name": "Bougival",
        "latitude": "48.86223000",
        "longitude": "2.14148000"
    },
    {
        "id": 40156,
        "name": "Bouguenais",
        "latitude": "47.17762000",
        "longitude": "-1.62143000"
    },
    {
        "id": 40157,
        "name": "Bouillargues",
        "latitude": "43.79733000",
        "longitude": "4.42853000"
    },
    {
        "id": 40159,
        "name": "Bouillé-Loretz",
        "latitude": "47.07911000",
        "longitude": "-0.27178000"
    },
    {
        "id": 40158,
        "name": "Bouilly",
        "latitude": "48.19674000",
        "longitude": "4.00011000"
    },
    {
        "id": 40160,
        "name": "Bouin",
        "latitude": "46.97314000",
        "longitude": "-1.99830000"
    },
    {
        "id": 40161,
        "name": "Boujan-sur-Libron",
        "latitude": "43.36996000",
        "longitude": "3.24759000"
    },
    {
        "id": 40162,
        "name": "Boulange",
        "latitude": "49.38229000",
        "longitude": "5.95000000"
    },
    {
        "id": 40163,
        "name": "Boulay-Moselle",
        "latitude": "49.18333000",
        "longitude": "6.50000000"
    },
    {
        "id": 40164,
        "name": "Boulazac",
        "latitude": "45.11667000",
        "longitude": "0.75000000"
    },
    {
        "id": 40165,
        "name": "Boulbon",
        "latitude": "43.86232000",
        "longitude": "4.69391000"
    },
    {
        "id": 40166,
        "name": "Bouleurs",
        "latitude": "48.88181000",
        "longitude": "2.90728000"
    },
    {
        "id": 40167,
        "name": "Bouliac",
        "latitude": "44.81724000",
        "longitude": "-0.50248000"
    },
    {
        "id": 40168,
        "name": "Boulieu-lès-Annonay",
        "latitude": "45.27065000",
        "longitude": "4.66645000"
    },
    {
        "id": 40169,
        "name": "Bouligny",
        "latitude": "49.29189000",
        "longitude": "5.74248000"
    },
    {
        "id": 40170,
        "name": "Boulleret",
        "latitude": "47.42419000",
        "longitude": "2.87343000"
    },
    {
        "id": 40171,
        "name": "Bouloc",
        "latitude": "43.78163000",
        "longitude": "1.40522000"
    },
    {
        "id": 40172,
        "name": "Boulogne-Billancourt",
        "latitude": "48.83545000",
        "longitude": "2.24128000"
    },
    {
        "id": 40173,
        "name": "Boulogne-sur-Gesse",
        "latitude": "43.30000000",
        "longitude": "0.65000000"
    },
    {
        "id": 40174,
        "name": "Boulogne-sur-Mer",
        "latitude": "50.72571000",
        "longitude": "1.61392000"
    },
    {
        "id": 40175,
        "name": "Bouloire",
        "latitude": "47.97385000",
        "longitude": "0.55009000"
    },
    {
        "id": 40176,
        "name": "Boult-sur-Suippe",
        "latitude": "49.37149000",
        "longitude": "4.14632000"
    },
    {
        "id": 40177,
        "name": "Bouray-sur-Juine",
        "latitude": "48.51981000",
        "longitude": "2.30001000"
    },
    {
        "id": 40179,
        "name": "Bourbon-l’Archambault",
        "latitude": "46.58324000",
        "longitude": "3.05652000"
    },
    {
        "id": 40178,
        "name": "Bourbon-Lancy",
        "latitude": "46.62214000",
        "longitude": "3.76953000"
    },
    {
        "id": 40180,
        "name": "Bourbonne-les-Bains",
        "latitude": "47.95305000",
        "longitude": "5.74801000"
    },
    {
        "id": 40181,
        "name": "Bourbourg",
        "latitude": "50.94783000",
        "longitude": "2.19576000"
    },
    {
        "id": 40182,
        "name": "Bourbriac",
        "latitude": "48.47384000",
        "longitude": "-3.18758000"
    },
    {
        "id": 40183,
        "name": "Bourcefranc-le-Chapus",
        "latitude": "45.85000000",
        "longitude": "-1.15000000"
    },
    {
        "id": 40184,
        "name": "Bourg",
        "latitude": "45.04062000",
        "longitude": "-0.55893000"
    },
    {
        "id": 40185,
        "name": "Bourg de Joué-sur-Erdre",
        "latitude": "47.49596000",
        "longitude": "-1.42047000"
    },
    {
        "id": 40186,
        "name": "Bourg-Achard",
        "latitude": "49.35322000",
        "longitude": "0.81623000"
    },
    {
        "id": 40187,
        "name": "Bourg-Argental",
        "latitude": "45.29899000",
        "longitude": "4.56830000"
    },
    {
        "id": 40188,
        "name": "Bourg-Blanc",
        "latitude": "48.49867000",
        "longitude": "-4.50406000"
    },
    {
        "id": 40191,
        "name": "Bourg-de-Péage",
        "latitude": "45.03151000",
        "longitude": "5.04993000"
    },
    {
        "id": 40192,
        "name": "Bourg-de-Thizy",
        "latitude": "46.03371000",
        "longitude": "4.29904000"
    },
    {
        "id": 40193,
        "name": "Bourg-des-Comptes",
        "latitude": "47.92934000",
        "longitude": "-1.74534000"
    },
    {
        "id": 40194,
        "name": "Bourg-en-Bresse",
        "latitude": "46.20574000",
        "longitude": "5.22580000"
    },
    {
        "id": 40195,
        "name": "Bourg-la-Reine",
        "latitude": "48.77888000",
        "longitude": "2.31781000"
    },
    {
        "id": 40196,
        "name": "Bourg-lès-Valence",
        "latitude": "44.94703000",
        "longitude": "4.89463000"
    },
    {
        "id": 40189,
        "name": "Bourg-Saint-Andéol",
        "latitude": "44.37338000",
        "longitude": "4.64413000"
    },
    {
        "id": 40190,
        "name": "Bourg-Saint-Maurice",
        "latitude": "45.61463000",
        "longitude": "6.76845000"
    },
    {
        "id": 40197,
        "name": "Bourganeuf",
        "latitude": "45.95268000",
        "longitude": "1.75520000"
    },
    {
        "id": 40198,
        "name": "Bourgbarré",
        "latitude": "47.99515000",
        "longitude": "-1.61419000"
    },
    {
        "id": 40199,
        "name": "Bourges",
        "latitude": "47.08333000",
        "longitude": "2.40000000"
    },
    {
        "id": 40200,
        "name": "Bourghelles",
        "latitude": "50.56501000",
        "longitude": "3.24447000"
    },
    {
        "id": 40201,
        "name": "Bourgneuf",
        "latitude": "46.16766000",
        "longitude": "-1.02141000"
    },
    {
        "id": 40202,
        "name": "Bourgneuf-en-Retz",
        "latitude": "47.04122000",
        "longitude": "-1.95019000"
    },
    {
        "id": 40203,
        "name": "Bourgogne",
        "latitude": "49.34962000",
        "longitude": "4.07111000"
    },
    {
        "id": 40204,
        "name": "Bourgoin-Jallieu",
        "latitude": "45.58611000",
        "longitude": "5.27361000"
    },
    {
        "id": 40205,
        "name": "Bourgtheroulde-Infreville",
        "latitude": "49.30000000",
        "longitude": "0.88333000"
    },
    {
        "id": 40207,
        "name": "Bourguébus",
        "latitude": "49.12187000",
        "longitude": "-0.29786000"
    },
    {
        "id": 40206,
        "name": "Bourgueil",
        "latitude": "47.28333000",
        "longitude": "0.16612000"
    },
    {
        "id": 40208,
        "name": "Bourlon",
        "latitude": "50.17692000",
        "longitude": "3.11425000"
    },
    {
        "id": 40209,
        "name": "Bournezeau",
        "latitude": "46.63714000",
        "longitude": "-1.17107000"
    },
    {
        "id": 40210,
        "name": "Bournoncle-Saint-Pierre",
        "latitude": "45.34351000",
        "longitude": "3.31830000"
    },
    {
        "id": 40211,
        "name": "Bourogne",
        "latitude": "47.56307000",
        "longitude": "6.91654000"
    },
    {
        "id": 40212,
        "name": "Bourron-Marlotte",
        "latitude": "48.34051000",
        "longitude": "2.70041000"
    },
    {
        "id": 40213,
        "name": "Bourth",
        "latitude": "48.76846000",
        "longitude": "0.80911000"
    },
    {
        "id": 40214,
        "name": "Bousbecque",
        "latitude": "50.77123000",
        "longitude": "3.08459000"
    },
    {
        "id": 40215,
        "name": "Bousies",
        "latitude": "50.15097000",
        "longitude": "3.61752000"
    },
    {
        "id": 40216,
        "name": "Boussac",
        "latitude": "46.34941000",
        "longitude": "2.21474000"
    },
    {
        "id": 40217,
        "name": "Boussay",
        "latitude": "47.04476000",
        "longitude": "-1.18476000"
    },
    {
        "id": 40218,
        "name": "Bousse",
        "latitude": "49.27786000",
        "longitude": "6.19672000"
    },
    {
        "id": 40219,
        "name": "Boussières",
        "latitude": "47.15866000",
        "longitude": "5.90314000"
    },
    {
        "id": 40220,
        "name": "Boussois",
        "latitude": "50.28907000",
        "longitude": "4.04117000"
    },
    {
        "id": 40221,
        "name": "Boussy-Saint-Antoine",
        "latitude": "48.69101000",
        "longitude": "2.53060000"
    },
    {
        "id": 40222,
        "name": "Bout-du-Pont-de-Larn",
        "latitude": "43.49738000",
        "longitude": "2.41642000"
    },
    {
        "id": 40223,
        "name": "Boutiers-Saint-Trojan",
        "latitude": "45.71667000",
        "longitude": "-0.30000000"
    },
    {
        "id": 40224,
        "name": "Boutigny-sur-Essonne",
        "latitude": "48.43333000",
        "longitude": "2.38333000"
    },
    {
        "id": 40225,
        "name": "Bouttencourt",
        "latitude": "49.93725000",
        "longitude": "1.63431000"
    },
    {
        "id": 40226,
        "name": "Bouvesse-Quirieu",
        "latitude": "45.79310000",
        "longitude": "5.41496000"
    },
    {
        "id": 40227,
        "name": "Bouvignies",
        "latitude": "50.43627000",
        "longitude": "3.24361000"
    },
    {
        "id": 40228,
        "name": "Bouvigny-Boyeffles",
        "latitude": "50.42146000",
        "longitude": "2.67209000"
    },
    {
        "id": 40229,
        "name": "Bouville",
        "latitude": "49.56193000",
        "longitude": "0.89514000"
    },
    {
        "id": 40230,
        "name": "Bouvron",
        "latitude": "47.41726000",
        "longitude": "-1.84679000"
    },
    {
        "id": 40231,
        "name": "Bouxières-aux-Chênes",
        "latitude": "48.77215000",
        "longitude": "6.26152000"
    },
    {
        "id": 40232,
        "name": "Bouxières-aux-Dames",
        "latitude": "48.75441000",
        "longitude": "6.16294000"
    },
    {
        "id": 40233,
        "name": "Bouxwiller",
        "latitude": "48.82495000",
        "longitude": "7.48117000"
    },
    {
        "id": 40234,
        "name": "Bouzigues",
        "latitude": "43.44810000",
        "longitude": "3.65781000"
    },
    {
        "id": 40235,
        "name": "Bouzillé",
        "latitude": "47.33750000",
        "longitude": "-1.11143000"
    },
    {
        "id": 40236,
        "name": "Bouzonville",
        "latitude": "49.29188000",
        "longitude": "6.53386000"
    },
    {
        "id": 40237,
        "name": "Bouzy-la-Forêt",
        "latitude": "47.85120000",
        "longitude": "2.37773000"
    },
    {
        "id": 40239,
        "name": "Boves",
        "latitude": "49.84645000",
        "longitude": "2.39605000"
    },
    {
        "id": 40240,
        "name": "Boyard-Ville",
        "latitude": "45.96717000",
        "longitude": "-1.24289000"
    },
    {
        "id": 40241,
        "name": "Boynes",
        "latitude": "48.11822000",
        "longitude": "2.36006000"
    },
    {
        "id": 40242,
        "name": "Bozel",
        "latitude": "45.44288000",
        "longitude": "6.64896000"
    },
    {
        "id": 40243,
        "name": "Bozouls",
        "latitude": "44.47050000",
        "longitude": "2.72432000"
    },
    {
        "id": 40247,
        "name": "Bracieux",
        "latitude": "47.54895000",
        "longitude": "1.54120000"
    },
    {
        "id": 40248,
        "name": "Brain-sur-Allonnes",
        "latitude": "47.30325000",
        "longitude": "0.06514000"
    },
    {
        "id": 40249,
        "name": "Braine",
        "latitude": "49.34261000",
        "longitude": "3.53262000"
    },
    {
        "id": 40250,
        "name": "Brains",
        "latitude": "47.16850000",
        "longitude": "-1.72290000"
    },
    {
        "id": 40251,
        "name": "Bram",
        "latitude": "43.24376000",
        "longitude": "2.11341000"
    },
    {
        "id": 40253,
        "name": "Brandérion",
        "latitude": "47.79412000",
        "longitude": "-3.19474000"
    },
    {
        "id": 40252,
        "name": "Brando",
        "latitude": "42.76667000",
        "longitude": "9.45000000"
    },
    {
        "id": 40254,
        "name": "Branges",
        "latitude": "46.64441000",
        "longitude": "5.18465000"
    },
    {
        "id": 40255,
        "name": "Branne",
        "latitude": "44.83333000",
        "longitude": "-0.18333000"
    },
    {
        "id": 40256,
        "name": "Branoux-les-Taillades",
        "latitude": "44.21941000",
        "longitude": "3.99647000"
    },
    {
        "id": 40257,
        "name": "Brantôme",
        "latitude": "45.36091000",
        "longitude": "0.65398000"
    },
    {
        "id": 40258,
        "name": "Bras",
        "latitude": "43.47163000",
        "longitude": "5.95486000"
    },
    {
        "id": 40259,
        "name": "Brasles",
        "latitude": "49.04810000",
        "longitude": "3.43000000"
    },
    {
        "id": 40260,
        "name": "Brasparts",
        "latitude": "48.30167000",
        "longitude": "-3.95516000"
    },
    {
        "id": 40261,
        "name": "Brassac",
        "latitude": "43.62959000",
        "longitude": "2.49763000"
    },
    {
        "id": 40262,
        "name": "Brassac-les-Mines",
        "latitude": "45.41407000",
        "longitude": "3.32900000"
    },
    {
        "id": 40263,
        "name": "Braud-et-Saint-Louis",
        "latitude": "45.24703000",
        "longitude": "-0.62438000"
    },
    {
        "id": 40264,
        "name": "Brax",
        "latitude": "43.61793000",
        "longitude": "1.23957000"
    },
    {
        "id": 40265,
        "name": "Brax",
        "latitude": "44.20277000",
        "longitude": "0.55163000"
    },
    {
        "id": 40266,
        "name": "Bray-Dunes",
        "latitude": "51.07786000",
        "longitude": "2.51673000"
    },
    {
        "id": 40267,
        "name": "Bray-en-Val",
        "latitude": "47.82856000",
        "longitude": "2.36644000"
    },
    {
        "id": 40268,
        "name": "Bray-sur-Seine",
        "latitude": "48.41371000",
        "longitude": "3.23852000"
    },
    {
        "id": 40269,
        "name": "Bray-sur-Somme",
        "latitude": "49.94085000",
        "longitude": "2.71847000"
    },
    {
        "id": 40270,
        "name": "Brazey-en-Plaine",
        "latitude": "47.13784000",
        "longitude": "5.21538000"
    },
    {
        "id": 40354,
        "name": "Bréal-sous-Montfort",
        "latitude": "48.04876000",
        "longitude": "-1.86438000"
    },
    {
        "id": 40355,
        "name": "Bréauté",
        "latitude": "49.62805000",
        "longitude": "0.40005000"
    },
    {
        "id": 40271,
        "name": "Brebières",
        "latitude": "50.33333000",
        "longitude": "3.01667000"
    },
    {
        "id": 40357,
        "name": "Brécé",
        "latitude": "48.10839000",
        "longitude": "-1.48086000"
    },
    {
        "id": 40356,
        "name": "Brécey",
        "latitude": "48.72413000",
        "longitude": "-1.16647000"
    },
    {
        "id": 40272,
        "name": "Brech",
        "latitude": "47.72134000",
        "longitude": "-2.99862000"
    },
    {
        "id": 40358,
        "name": "Bréhal",
        "latitude": "48.89915000",
        "longitude": "-1.51225000"
    },
    {
        "id": 40359,
        "name": "Bréhand",
        "latitude": "48.40333000",
        "longitude": "-2.57412000"
    },
    {
        "id": 40273,
        "name": "Breil-sur-Roya",
        "latitude": "43.93755000",
        "longitude": "7.51472000"
    },
    {
        "id": 40365,
        "name": "Brêmes",
        "latitude": "50.85483000",
        "longitude": "1.97687000"
    },
    {
        "id": 40274,
        "name": "Brenouille",
        "latitude": "49.30551000",
        "longitude": "2.54437000"
    },
    {
        "id": 40275,
        "name": "Brens",
        "latitude": "43.88725000",
        "longitude": "1.90716000"
    },
    {
        "id": 40276,
        "name": "Bresles",
        "latitude": "49.41044000",
        "longitude": "2.25024000"
    },
    {
        "id": 40277,
        "name": "Bressols",
        "latitude": "43.96796000",
        "longitude": "1.33839000"
    },
    {
        "id": 40278,
        "name": "Bressuire",
        "latitude": "46.84012000",
        "longitude": "-0.48851000"
    },
    {
        "id": 40279,
        "name": "Brest",
        "latitude": "48.39029000",
        "longitude": "-4.48628000"
    },
    {
        "id": 40280,
        "name": "Breteil",
        "latitude": "48.14534000",
        "longitude": "-1.89886000"
    },
    {
        "id": 40281,
        "name": "Bretenoux",
        "latitude": "44.91468000",
        "longitude": "1.84007000"
    },
    {
        "id": 40282,
        "name": "Breteuil",
        "latitude": "48.83333000",
        "longitude": "0.91667000"
    },
    {
        "id": 40283,
        "name": "Breteuil",
        "latitude": "49.63357000",
        "longitude": "2.29509000"
    },
    {
        "id": 40284,
        "name": "Bretignolles-sur-Mer",
        "latitude": "46.63333000",
        "longitude": "-1.86667000"
    },
    {
        "id": 40360,
        "name": "Brétigny-sur-Orge",
        "latitude": "48.61135000",
        "longitude": "2.30593000"
    },
    {
        "id": 40285,
        "name": "Bretoncelles",
        "latitude": "48.43122000",
        "longitude": "0.88775000"
    },
    {
        "id": 40286,
        "name": "Brette-les-Pins",
        "latitude": "47.91062000",
        "longitude": "0.33649000"
    },
    {
        "id": 40287,
        "name": "Bretteville",
        "latitude": "49.65440000",
        "longitude": "-1.52884000"
    },
    {
        "id": 40288,
        "name": "Bretteville-du-Grand-Caux",
        "latitude": "49.66667000",
        "longitude": "0.40000000"
    },
    {
        "id": 40289,
        "name": "Bretteville-l’Orgueilleuse",
        "latitude": "49.21189000",
        "longitude": "-0.51428000"
    },
    {
        "id": 40290,
        "name": "Bretteville-sur-Laize",
        "latitude": "49.04466000",
        "longitude": "-0.32639000"
    },
    {
        "id": 40291,
        "name": "Bretteville-sur-Odon",
        "latitude": "49.16627000",
        "longitude": "-0.41662000"
    },
    {
        "id": 40293,
        "name": "Breuil-le-Sec",
        "latitude": "49.37135000",
        "longitude": "2.45084000"
    },
    {
        "id": 40294,
        "name": "Breuil-le-Vert",
        "latitude": "49.36176000",
        "longitude": "2.43633000"
    },
    {
        "id": 40292,
        "name": "Breuil-Magné",
        "latitude": "45.98478000",
        "longitude": "-0.96000000"
    },
    {
        "id": 40295,
        "name": "Breuillet",
        "latitude": "45.69143000",
        "longitude": "-1.05175000"
    },
    {
        "id": 40296,
        "name": "Breuillet",
        "latitude": "48.57064000",
        "longitude": "2.17424000"
    },
    {
        "id": 40297,
        "name": "Breuilpont",
        "latitude": "48.96398000",
        "longitude": "1.42919000"
    },
    {
        "id": 40298,
        "name": "Breuschwickersheim",
        "latitude": "48.58007000",
        "longitude": "7.60159000"
    },
    {
        "id": 40361,
        "name": "Bréval",
        "latitude": "48.94545000",
        "longitude": "1.53309000"
    },
    {
        "id": 40362,
        "name": "Bréviandes",
        "latitude": "48.25693000",
        "longitude": "4.09531000"
    },
    {
        "id": 40364,
        "name": "Brézé",
        "latitude": "47.17357000",
        "longitude": "-0.06059000"
    },
    {
        "id": 40363,
        "name": "Brézins",
        "latitude": "45.35213000",
        "longitude": "5.30539000"
    },
    {
        "id": 40299,
        "name": "Brezolles",
        "latitude": "48.69033000",
        "longitude": "1.07404000"
    },
    {
        "id": 40300,
        "name": "Briançon",
        "latitude": "44.89978000",
        "longitude": "6.64201000"
    },
    {
        "id": 40301,
        "name": "Briare",
        "latitude": "47.63343000",
        "longitude": "2.74380000"
    },
    {
        "id": 40302,
        "name": "Briatexte",
        "latitude": "43.75237000",
        "longitude": "1.90879000"
    },
    {
        "id": 40303,
        "name": "Bricquebec",
        "latitude": "49.47008000",
        "longitude": "-1.63254000"
    },
    {
        "id": 40304,
        "name": "Bricy",
        "latitude": "47.99847000",
        "longitude": "1.77937000"
    },
    {
        "id": 40305,
        "name": "Brie",
        "latitude": "45.73804000",
        "longitude": "0.24107000"
    },
    {
        "id": 40306,
        "name": "Brie-Comte-Robert",
        "latitude": "48.69247000",
        "longitude": "2.61090000"
    },
    {
        "id": 40330,
        "name": "Brié-et-Angonnes",
        "latitude": "45.12418000",
        "longitude": "5.78374000"
    },
    {
        "id": 40307,
        "name": "Briec",
        "latitude": "48.10167000",
        "longitude": "-3.99833000"
    },
    {
        "id": 40308,
        "name": "Brienne-le-Château",
        "latitude": "48.39319000",
        "longitude": "4.52637000"
    },
    {
        "id": 40309,
        "name": "Briennon",
        "latitude": "46.15127000",
        "longitude": "4.07690000"
    },
    {
        "id": 40310,
        "name": "Brienon-sur-Armançon",
        "latitude": "47.99009000",
        "longitude": "3.61628000"
    },
    {
        "id": 40311,
        "name": "Briey",
        "latitude": "49.24920000",
        "longitude": "5.93975000"
    },
    {
        "id": 40312,
        "name": "Brignais",
        "latitude": "45.67383000",
        "longitude": "4.75418000"
    },
    {
        "id": 40313,
        "name": "Brignoles",
        "latitude": "43.40580000",
        "longitude": "6.06172000"
    },
    {
        "id": 40314,
        "name": "Brigueuil",
        "latitude": "45.95337000",
        "longitude": "0.86065000"
    },
    {
        "id": 40315,
        "name": "Briis-sous-Forges",
        "latitude": "48.62399000",
        "longitude": "2.12112000"
    },
    {
        "id": 40316,
        "name": "Brindas",
        "latitude": "45.72111000",
        "longitude": "4.69349000"
    },
    {
        "id": 40317,
        "name": "Brinon-sur-Sauldre",
        "latitude": "47.56600000",
        "longitude": "2.25647000"
    },
    {
        "id": 40318,
        "name": "Briollay",
        "latitude": "47.56478000",
        "longitude": "-0.50681000"
    },
    {
        "id": 40319,
        "name": "Brion",
        "latitude": "47.44121000",
        "longitude": "-0.15616000"
    },
    {
        "id": 40320,
        "name": "Brionne",
        "latitude": "49.19553000",
        "longitude": "0.71510000"
    },
    {
        "id": 40321,
        "name": "Brioude",
        "latitude": "45.29419000",
        "longitude": "3.38423000"
    },
    {
        "id": 40322,
        "name": "Brioux-sur-Boutonne",
        "latitude": "46.14171000",
        "longitude": "-0.22182000"
    },
    {
        "id": 40323,
        "name": "Briouze",
        "latitude": "48.69848000",
        "longitude": "-0.36806000"
    },
    {
        "id": 40324,
        "name": "Briscous",
        "latitude": "43.45958000",
        "longitude": "-1.33353000"
    },
    {
        "id": 40325,
        "name": "Brison-Saint-Innocent",
        "latitude": "45.72440000",
        "longitude": "5.88895000"
    },
    {
        "id": 40326,
        "name": "Brissac-Quincé",
        "latitude": "47.35575000",
        "longitude": "-0.44924000"
    },
    {
        "id": 40327,
        "name": "Brive-la-Gaillarde",
        "latitude": "45.15890000",
        "longitude": "1.53326000"
    },
    {
        "id": 40328,
        "name": "Brives-Charensac",
        "latitude": "45.04780000",
        "longitude": "3.92878000"
    },
    {
        "id": 40329,
        "name": "Brix",
        "latitude": "49.54512000",
        "longitude": "-1.58012000"
    },
    {
        "id": 40331,
        "name": "Broglie",
        "latitude": "49.00911000",
        "longitude": "0.52915000"
    },
    {
        "id": 40332,
        "name": "Bron",
        "latitude": "45.73865000",
        "longitude": "4.91303000"
    },
    {
        "id": 40333,
        "name": "Broons",
        "latitude": "48.31748000",
        "longitude": "-2.25952000"
    },
    {
        "id": 40334,
        "name": "Brou",
        "latitude": "48.21719000",
        "longitude": "1.16539000"
    },
    {
        "id": 40335,
        "name": "Brou-sur-Chantereine",
        "latitude": "48.88333000",
        "longitude": "2.63333000"
    },
    {
        "id": 40336,
        "name": "Brouckerque",
        "latitude": "50.95476000",
        "longitude": "2.29378000"
    },
    {
        "id": 40337,
        "name": "Broût-Vernet",
        "latitude": "46.18756000",
        "longitude": "3.27324000"
    },
    {
        "id": 40338,
        "name": "Bruay-la-Buissière",
        "latitude": "50.48333000",
        "longitude": "2.55000000"
    },
    {
        "id": 40339,
        "name": "Bruay-sur-l’Escaut",
        "latitude": "50.39918000",
        "longitude": "3.54379000"
    },
    {
        "id": 40340,
        "name": "Bruges",
        "latitude": "44.87981000",
        "longitude": "-0.61219000"
    },
    {
        "id": 40341,
        "name": "Brugheas",
        "latitude": "46.07676000",
        "longitude": "3.36780000"
    },
    {
        "id": 40342,
        "name": "Bruguières",
        "latitude": "43.72720000",
        "longitude": "1.40762000"
    },
    {
        "id": 40344,
        "name": "Bruille-lez-Marchiennes",
        "latitude": "50.36068000",
        "longitude": "3.24424000"
    },
    {
        "id": 40343,
        "name": "Bruille-Saint-Amand",
        "latitude": "50.46605000",
        "longitude": "3.50013000"
    },
    {
        "id": 40366,
        "name": "Brûlon",
        "latitude": "47.96667000",
        "longitude": "-0.23333000"
    },
    {
        "id": 40345,
        "name": "Brumath",
        "latitude": "48.73398000",
        "longitude": "7.71095000"
    },
    {
        "id": 40346,
        "name": "Brunoy",
        "latitude": "48.69420000",
        "longitude": "2.49223000"
    },
    {
        "id": 40347,
        "name": "Brunstatt",
        "latitude": "47.72131000",
        "longitude": "7.32009000"
    },
    {
        "id": 40348,
        "name": "Bruyères",
        "latitude": "48.20709000",
        "longitude": "6.71845000"
    },
    {
        "id": 40349,
        "name": "Bruyères-et-Montbérault",
        "latitude": "49.52535000",
        "longitude": "3.66369000"
    },
    {
        "id": 40350,
        "name": "Bruyères-le-Châtel",
        "latitude": "48.58868000",
        "longitude": "2.18991000"
    },
    {
        "id": 40351,
        "name": "Bruyères-sur-Oise",
        "latitude": "49.15756000",
        "longitude": "2.32577000"
    },
    {
        "id": 40352,
        "name": "Bruz",
        "latitude": "48.02420000",
        "longitude": "-1.74591000"
    },
    {
        "id": 40353,
        "name": "Bry-sur-Marne",
        "latitude": "48.83811000",
        "longitude": "2.52488000"
    },
    {
        "id": 40438,
        "name": "Bû",
        "latitude": "48.79705000",
        "longitude": "1.49702000"
    },
    {
        "id": 40367,
        "name": "Bubry",
        "latitude": "47.96336000",
        "longitude": "-3.17108000"
    },
    {
        "id": 40368,
        "name": "Buc",
        "latitude": "48.77627000",
        "longitude": "2.12577000"
    },
    {
        "id": 40369,
        "name": "Buchelay",
        "latitude": "48.97926000",
        "longitude": "1.67026000"
    },
    {
        "id": 40371,
        "name": "Buchères",
        "latitude": "48.23508000",
        "longitude": "4.11310000"
    },
    {
        "id": 40370,
        "name": "Buchy",
        "latitude": "49.58513000",
        "longitude": "1.35852000"
    },
    {
        "id": 40372,
        "name": "Bucquoy",
        "latitude": "50.13973000",
        "longitude": "2.71036000"
    },
    {
        "id": 40373,
        "name": "Bucy-le-Long",
        "latitude": "49.39072000",
        "longitude": "3.39582000"
    },
    {
        "id": 40374,
        "name": "Bueil",
        "latitude": "48.93189000",
        "longitude": "1.44257000"
    },
    {
        "id": 40375,
        "name": "Buellas",
        "latitude": "46.21110000",
        "longitude": "5.13220000"
    },
    {
        "id": 40376,
        "name": "Bugeat",
        "latitude": "45.59809000",
        "longitude": "1.92727000"
    },
    {
        "id": 40377,
        "name": "Buhl",
        "latitude": "47.92806000",
        "longitude": "7.18719000"
    },
    {
        "id": 40378,
        "name": "Buironfosse",
        "latitude": "49.96659000",
        "longitude": "3.83587000"
    },
    {
        "id": 40379,
        "name": "Buis-les-Baronnies",
        "latitude": "44.27647000",
        "longitude": "5.27187000"
    },
    {
        "id": 40380,
        "name": "Bulgnéville",
        "latitude": "48.20694000",
        "longitude": "5.83430000"
    },
    {
        "id": 40381,
        "name": "Bullion",
        "latitude": "48.62285000",
        "longitude": "1.99024000"
    },
    {
        "id": 40382,
        "name": "Bully",
        "latitude": "49.10155000",
        "longitude": "-0.40825000"
    },
    {
        "id": 40383,
        "name": "Bully",
        "latitude": "45.85113000",
        "longitude": "4.58328000"
    },
    {
        "id": 40384,
        "name": "Bully-les-Mines",
        "latitude": "50.44380000",
        "longitude": "2.72703000"
    },
    {
        "id": 40385,
        "name": "Burbure",
        "latitude": "50.53663000",
        "longitude": "2.46897000"
    },
    {
        "id": 40386,
        "name": "Bures-sur-Yvette",
        "latitude": "48.69981000",
        "longitude": "2.17064000"
    },
    {
        "id": 40387,
        "name": "Burie",
        "latitude": "45.77273000",
        "longitude": "-0.42289000"
    },
    {
        "id": 40388,
        "name": "Burlats",
        "latitude": "43.63633000",
        "longitude": "2.31879000"
    },
    {
        "id": 40389,
        "name": "Burnhaupt-le-Bas",
        "latitude": "47.71764000",
        "longitude": "7.16148000"
    },
    {
        "id": 40390,
        "name": "Burnhaupt-le-Haut",
        "latitude": "47.73162000",
        "longitude": "7.14437000"
    },
    {
        "id": 40391,
        "name": "Buros",
        "latitude": "43.35000000",
        "longitude": "-0.30000000"
    },
    {
        "id": 40392,
        "name": "Bury",
        "latitude": "49.31307000",
        "longitude": "2.34401000"
    },
    {
        "id": 40393,
        "name": "Busigny",
        "latitude": "50.03424000",
        "longitude": "3.46713000"
    },
    {
        "id": 40394,
        "name": "Busnes",
        "latitude": "50.58781000",
        "longitude": "2.51827000"
    },
    {
        "id": 40395,
        "name": "Bussac-sur-Charente",
        "latitude": "45.78333000",
        "longitude": "-0.63333000"
    },
    {
        "id": 40396,
        "name": "Bussang",
        "latitude": "47.88449000",
        "longitude": "6.85272000"
    },
    {
        "id": 40397,
        "name": "Bussière-Dunoise",
        "latitude": "46.25898000",
        "longitude": "1.76216000"
    },
    {
        "id": 40398,
        "name": "Bussière-Galant",
        "latitude": "45.62702000",
        "longitude": "1.03640000"
    },
    {
        "id": 40399,
        "name": "Bussière-Poitevine",
        "latitude": "46.23543000",
        "longitude": "0.90530000"
    },
    {
        "id": 40400,
        "name": "Bussières",
        "latitude": "45.83570000",
        "longitude": "4.27123000"
    },
    {
        "id": 40401,
        "name": "Bussy-Saint-Georges",
        "latitude": "48.84099000",
        "longitude": "2.70165000"
    },
    {
        "id": 40402,
        "name": "Butry-sur-Oise",
        "latitude": "49.08837000",
        "longitude": "2.19916000"
    },
    {
        "id": 40403,
        "name": "Buxerolles",
        "latitude": "46.61667000",
        "longitude": "0.48333000"
    },
    {
        "id": 40404,
        "name": "Buxières-les-Mines",
        "latitude": "46.46807000",
        "longitude": "2.95994000"
    },
    {
        "id": 40405,
        "name": "Buxy",
        "latitude": "46.71369000",
        "longitude": "4.70427000"
    },
    {
        "id": 40406,
        "name": "Buzançais",
        "latitude": "46.88877000",
        "longitude": "1.41950000"
    },
    {
        "id": 40407,
        "name": "Buzet-sur-Baïse",
        "latitude": "44.25811000",
        "longitude": "0.29809000"
    },
    {
        "id": 40408,
        "name": "Buzet-sur-Tarn",
        "latitude": "43.77977000",
        "longitude": "1.63301000"
    },
    {
        "id": 40440,
        "name": "Cabanac-et-Villagrains",
        "latitude": "44.60000000",
        "longitude": "-0.55000000"
    },
    {
        "id": 40441,
        "name": "Cabannes",
        "latitude": "43.86015000",
        "longitude": "4.95192000"
    },
    {
        "id": 40442,
        "name": "Cabariot",
        "latitude": "45.92645000",
        "longitude": "-0.85741000"
    },
    {
        "id": 40443,
        "name": "Cabasse",
        "latitude": "43.42646000",
        "longitude": "6.21917000"
    },
    {
        "id": 40444,
        "name": "Cabestany",
        "latitude": "42.68132000",
        "longitude": "2.94122000"
    },
    {
        "id": 40445,
        "name": "Cabourg",
        "latitude": "49.29110000",
        "longitude": "-0.11330000"
    },
    {
        "id": 40447,
        "name": "Cabrières",
        "latitude": "43.90389000",
        "longitude": "4.47094000"
    },
    {
        "id": 40448,
        "name": "Cabriès",
        "latitude": "43.44127000",
        "longitude": "5.37884000"
    },
    {
        "id": 40446,
        "name": "Cabris",
        "latitude": "43.65617000",
        "longitude": "6.87358000"
    },
    {
        "id": 40449,
        "name": "Cachan",
        "latitude": "48.79632000",
        "longitude": "2.33661000"
    },
    {
        "id": 40450,
        "name": "Cadalen",
        "latitude": "43.84933000",
        "longitude": "1.98089000"
    },
    {
        "id": 40451,
        "name": "Cadaujac",
        "latitude": "44.75437000",
        "longitude": "-0.53128000"
    },
    {
        "id": 40452,
        "name": "Caden",
        "latitude": "47.63124000",
        "longitude": "-2.28704000"
    },
    {
        "id": 40453,
        "name": "Cadenet",
        "latitude": "43.73500000",
        "longitude": "5.37339000"
    },
    {
        "id": 40454,
        "name": "Caderousse",
        "latitude": "44.10327000",
        "longitude": "4.75672000"
    },
    {
        "id": 40455,
        "name": "Cadillac",
        "latitude": "44.63631000",
        "longitude": "-0.31702000"
    },
    {
        "id": 40456,
        "name": "Cadolive",
        "latitude": "43.39694000",
        "longitude": "5.54526000"
    },
    {
        "id": 40457,
        "name": "Caen",
        "latitude": "49.18585000",
        "longitude": "-0.35912000"
    },
    {
        "id": 40630,
        "name": "Caëstre",
        "latitude": "50.75838000",
        "longitude": "2.60274000"
    },
    {
        "id": 40458,
        "name": "Cagnac-les-Mines",
        "latitude": "43.98445000",
        "longitude": "2.14227000"
    },
    {
        "id": 40459,
        "name": "Cagnes-sur-Mer",
        "latitude": "43.66352000",
        "longitude": "7.14790000"
    },
    {
        "id": 40460,
        "name": "Cagny",
        "latitude": "49.86217000",
        "longitude": "2.34266000"
    },
    {
        "id": 40461,
        "name": "Cagny",
        "latitude": "49.14580000",
        "longitude": "-0.25630000"
    },
    {
        "id": 40462,
        "name": "Cahagnes",
        "latitude": "49.06631000",
        "longitude": "-0.76869000"
    },
    {
        "id": 40463,
        "name": "Cahors",
        "latitude": "44.44910000",
        "longitude": "1.43663000"
    },
    {
        "id": 40464,
        "name": "Cahuzac-sur-Vère",
        "latitude": "43.98268000",
        "longitude": "1.91052000"
    },
    {
        "id": 40465,
        "name": "Cailloux-sur-Fontaines",
        "latitude": "45.85259000",
        "longitude": "4.87473000"
    },
    {
        "id": 40466,
        "name": "Cairon",
        "latitude": "49.24017000",
        "longitude": "-0.45046000"
    },
    {
        "id": 40467,
        "name": "Caissargues",
        "latitude": "43.79509000",
        "longitude": "4.37955000"
    },
    {
        "id": 40468,
        "name": "Cajarc",
        "latitude": "44.48546000",
        "longitude": "1.84261000"
    },
    {
        "id": 40469,
        "name": "Calais",
        "latitude": "50.95194000",
        "longitude": "1.85635000"
    },
    {
        "id": 40470,
        "name": "Calenzana",
        "latitude": "42.50855000",
        "longitude": "8.85538000"
    },
    {
        "id": 40471,
        "name": "Callac",
        "latitude": "48.40292000",
        "longitude": "-3.42800000"
    },
    {
        "id": 40472,
        "name": "Callas",
        "latitude": "43.59284000",
        "longitude": "6.53840000"
    },
    {
        "id": 40473,
        "name": "Callian",
        "latitude": "43.62210000",
        "longitude": "6.75269000"
    },
    {
        "id": 40474,
        "name": "Calmont",
        "latitude": "43.28718000",
        "longitude": "1.63031000"
    },
    {
        "id": 40475,
        "name": "Calonne-Ricouart",
        "latitude": "50.48504000",
        "longitude": "2.47335000"
    },
    {
        "id": 40476,
        "name": "Calonne-sur-la-Lys",
        "latitude": "50.62292000",
        "longitude": "2.61664000"
    },
    {
        "id": 40477,
        "name": "Caluire-et-Cuire",
        "latitude": "45.79462000",
        "longitude": "4.84640000"
    },
    {
        "id": 40478,
        "name": "Calvados",
        "latitude": "49.09011000",
        "longitude": "-0.30608000"
    },
    {
        "id": 40479,
        "name": "Calvi",
        "latitude": "42.56604000",
        "longitude": "8.75713000"
    },
    {
        "id": 40480,
        "name": "Calvisson",
        "latitude": "43.78696000",
        "longitude": "4.19626000"
    },
    {
        "id": 40483,
        "name": "Camarès",
        "latitude": "43.82208000",
        "longitude": "2.88005000"
    },
    {
        "id": 40481,
        "name": "Camaret-sur-Aigues",
        "latitude": "44.16375000",
        "longitude": "4.87310000"
    },
    {
        "id": 40482,
        "name": "Camaret-sur-Mer",
        "latitude": "48.27497000",
        "longitude": "-4.59615000"
    },
    {
        "id": 40484,
        "name": "Cambes",
        "latitude": "44.73179000",
        "longitude": "-0.46261000"
    },
    {
        "id": 40485,
        "name": "Cambes-en-Plaine",
        "latitude": "49.23161000",
        "longitude": "-0.38540000"
    },
    {
        "id": 40486,
        "name": "Camblain-Châtelain",
        "latitude": "50.48355000",
        "longitude": "2.45521000"
    },
    {
        "id": 40487,
        "name": "Camblanes-et-Meynac",
        "latitude": "44.76551000",
        "longitude": "-0.48653000"
    },
    {
        "id": 40488,
        "name": "Cambo-les-Bains",
        "latitude": "43.35851000",
        "longitude": "-1.40105000"
    },
    {
        "id": 40489,
        "name": "Cambrai",
        "latitude": "50.17596000",
        "longitude": "3.23472000"
    },
    {
        "id": 40490,
        "name": "Cambremer",
        "latitude": "49.15192000",
        "longitude": "0.04760000"
    },
    {
        "id": 40491,
        "name": "Cambronne-lès-Clermont",
        "latitude": "49.33022000",
        "longitude": "2.39928000"
    },
    {
        "id": 40492,
        "name": "Cambronne-lès-Ribécourt",
        "latitude": "49.50711000",
        "longitude": "2.89797000"
    },
    {
        "id": 40493,
        "name": "Camiers",
        "latitude": "50.57153000",
        "longitude": "1.61325000"
    },
    {
        "id": 40494,
        "name": "Camon",
        "latitude": "49.88764000",
        "longitude": "2.34486000"
    },
    {
        "id": 40495,
        "name": "Camors",
        "latitude": "47.84739000",
        "longitude": "-2.99981000"
    },
    {
        "id": 40496,
        "name": "Campagne-lès-Hesdin",
        "latitude": "50.39724000",
        "longitude": "1.87494000"
    },
    {
        "id": 40497,
        "name": "Campan",
        "latitude": "43.01587000",
        "longitude": "0.17846000"
    },
    {
        "id": 40498,
        "name": "Campbon",
        "latitude": "47.41310000",
        "longitude": "-1.96857000"
    },
    {
        "id": 40503,
        "name": "Campénéac",
        "latitude": "47.95733000",
        "longitude": "-2.29277000"
    },
    {
        "id": 40499,
        "name": "Camphin-en-Carembault",
        "latitude": "50.51196000",
        "longitude": "2.98710000"
    },
    {
        "id": 40500,
        "name": "Camphin-en-Pévèle",
        "latitude": "50.59561000",
        "longitude": "3.26082000"
    },
    {
        "id": 40501,
        "name": "Camps-la-Source",
        "latitude": "43.38626000",
        "longitude": "6.09607000"
    },
    {
        "id": 40502,
        "name": "Campsas",
        "latitude": "43.89591000",
        "longitude": "1.32677000"
    },
    {
        "id": 40504,
        "name": "Cancale",
        "latitude": "48.67601000",
        "longitude": "-1.85231000"
    },
    {
        "id": 40505,
        "name": "Cancon",
        "latitude": "44.53543000",
        "longitude": "0.62520000"
    },
    {
        "id": 40507,
        "name": "Candé",
        "latitude": "47.56158000",
        "longitude": "-1.03619000"
    },
    {
        "id": 40508,
        "name": "Candé-sur-Beuvron",
        "latitude": "47.49499000",
        "longitude": "1.25937000"
    },
    {
        "id": 40506,
        "name": "Candillargues",
        "latitude": "43.62052000",
        "longitude": "4.06924000"
    },
    {
        "id": 40523,
        "name": "Canéjan",
        "latitude": "44.76667000",
        "longitude": "-0.63333000"
    },
    {
        "id": 40509,
        "name": "Canet",
        "latitude": "43.22744000",
        "longitude": "2.84800000"
    },
    {
        "id": 40510,
        "name": "Canet-en-Roussillon",
        "latitude": "42.70000000",
        "longitude": "3.01667000"
    },
    {
        "id": 40511,
        "name": "Cangey",
        "latitude": "47.46718000",
        "longitude": "1.06051000"
    },
    {
        "id": 40512,
        "name": "Cannes",
        "latitude": "43.55135000",
        "longitude": "7.01275000"
    },
    {
        "id": 40513,
        "name": "Cannes-Écluse",
        "latitude": "48.36303000",
        "longitude": "2.98748000"
    },
    {
        "id": 40514,
        "name": "Canohès",
        "latitude": "42.65461000",
        "longitude": "2.83633000"
    },
    {
        "id": 40515,
        "name": "Canon",
        "latitude": "49.07380000",
        "longitude": "-0.09219000"
    },
    {
        "id": 40516,
        "name": "Cantal",
        "latitude": "45.13985000",
        "longitude": "2.64947000"
    },
    {
        "id": 40517,
        "name": "Cantaron",
        "latitude": "43.76203000",
        "longitude": "7.31756000"
    },
    {
        "id": 40518,
        "name": "Canteleu",
        "latitude": "49.44065000",
        "longitude": "1.02459000"
    },
    {
        "id": 40519,
        "name": "Cantenac",
        "latitude": "45.02816000",
        "longitude": "-0.65312000"
    },
    {
        "id": 40520,
        "name": "Cantenay-Épinard",
        "latitude": "47.53503000",
        "longitude": "-0.56899000"
    },
    {
        "id": 40521,
        "name": "Cantin",
        "latitude": "50.30885000",
        "longitude": "3.12765000"
    },
    {
        "id": 40522,
        "name": "Cany-Barville",
        "latitude": "49.78885000",
        "longitude": "0.63704000"
    },
    {
        "id": 40524,
        "name": "Cap-d’Ail",
        "latitude": "43.72126000",
        "longitude": "7.40556000"
    },
    {
        "id": 40525,
        "name": "Capbreton",
        "latitude": "43.64216000",
        "longitude": "-1.42816000"
    },
    {
        "id": 40526,
        "name": "Capdenac-Gare",
        "latitude": "44.57567000",
        "longitude": "2.08079000"
    },
    {
        "id": 40527,
        "name": "Capendu",
        "latitude": "43.18596000",
        "longitude": "2.55677000"
    },
    {
        "id": 40528,
        "name": "Capestang",
        "latitude": "43.32791000",
        "longitude": "3.04447000"
    },
    {
        "id": 40529,
        "name": "Capinghem",
        "latitude": "50.64551000",
        "longitude": "2.96193000"
    },
    {
        "id": 40530,
        "name": "Cappelle-en-Pévèle",
        "latitude": "50.51011000",
        "longitude": "3.17133000"
    },
    {
        "id": 40531,
        "name": "Cappelle-la-Grande",
        "latitude": "50.99979000",
        "longitude": "2.35848000"
    },
    {
        "id": 40532,
        "name": "Captieux",
        "latitude": "44.29240000",
        "longitude": "-0.26196000"
    },
    {
        "id": 40533,
        "name": "Capvern",
        "latitude": "43.10357000",
        "longitude": "0.31604000"
    },
    {
        "id": 40534,
        "name": "Caraman",
        "latitude": "43.53271000",
        "longitude": "1.76002000"
    },
    {
        "id": 40535,
        "name": "Carantec",
        "latitude": "48.66811000",
        "longitude": "-3.91545000"
    },
    {
        "id": 40536,
        "name": "Carbon-Blanc",
        "latitude": "44.89642000",
        "longitude": "-0.50107000"
    },
    {
        "id": 40537,
        "name": "Carbonne",
        "latitude": "43.29857000",
        "longitude": "1.22520000"
    },
    {
        "id": 40538,
        "name": "Carcans",
        "latitude": "45.07869000",
        "longitude": "-1.04429000"
    },
    {
        "id": 40539,
        "name": "Carcassonne",
        "latitude": "43.21351000",
        "longitude": "2.35162000"
    },
    {
        "id": 40540,
        "name": "Carcès",
        "latitude": "43.47585000",
        "longitude": "6.18257000"
    },
    {
        "id": 40541,
        "name": "Carentan",
        "latitude": "49.30312000",
        "longitude": "-1.24806000"
    },
    {
        "id": 40542,
        "name": "Carentoir",
        "latitude": "47.81667000",
        "longitude": "-2.13333000"
    },
    {
        "id": 40543,
        "name": "Cargèse",
        "latitude": "42.13629000",
        "longitude": "8.59586000"
    },
    {
        "id": 40544,
        "name": "Carhaix-Plouguer",
        "latitude": "48.27594000",
        "longitude": "-3.57326000"
    },
    {
        "id": 40545,
        "name": "Carignan",
        "latitude": "49.63159000",
        "longitude": "5.16796000"
    },
    {
        "id": 40546,
        "name": "Carignan-de-Bordeaux",
        "latitude": "44.80000000",
        "longitude": "-0.48333000"
    },
    {
        "id": 40547,
        "name": "Carlepont",
        "latitude": "49.51438000",
        "longitude": "3.02327000"
    },
    {
        "id": 40548,
        "name": "Carling",
        "latitude": "49.16635000",
        "longitude": "6.71563000"
    },
    {
        "id": 40549,
        "name": "Carmaux",
        "latitude": "44.05099000",
        "longitude": "2.15795000"
    },
    {
        "id": 40550,
        "name": "Carnac",
        "latitude": "47.58351000",
        "longitude": "-3.07884000"
    },
    {
        "id": 40551,
        "name": "Carnoules",
        "latitude": "43.30187000",
        "longitude": "6.18733000"
    },
    {
        "id": 40552,
        "name": "Carnoux-en-Provence",
        "latitude": "43.25639000",
        "longitude": "5.56444000"
    },
    {
        "id": 40553,
        "name": "Caro",
        "latitude": "47.86429000",
        "longitude": "-2.31924000"
    },
    {
        "id": 40554,
        "name": "Caromb",
        "latitude": "44.11106000",
        "longitude": "5.10738000"
    },
    {
        "id": 40555,
        "name": "Carpentras",
        "latitude": "44.05507000",
        "longitude": "5.04813000"
    },
    {
        "id": 40556,
        "name": "Carpiquet",
        "latitude": "49.18522000",
        "longitude": "-0.44623000"
    },
    {
        "id": 40557,
        "name": "Carquefou",
        "latitude": "47.29821000",
        "longitude": "-1.49024000"
    },
    {
        "id": 40558,
        "name": "Carqueiranne",
        "latitude": "43.09495000",
        "longitude": "6.07349000"
    },
    {
        "id": 40559,
        "name": "Carrières-sous-Poissy",
        "latitude": "48.94952000",
        "longitude": "2.04068000"
    },
    {
        "id": 40560,
        "name": "Carrières-sur-Seine",
        "latitude": "48.90687000",
        "longitude": "2.17920000"
    },
    {
        "id": 40561,
        "name": "Carros",
        "latitude": "43.79246000",
        "longitude": "7.18745000"
    },
    {
        "id": 40562,
        "name": "Carry-le-Rouet",
        "latitude": "43.33139000",
        "longitude": "5.15237000"
    },
    {
        "id": 40563,
        "name": "Cars",
        "latitude": "45.12917000",
        "longitude": "-0.61952000"
    },
    {
        "id": 40564,
        "name": "Carsac-Aillac",
        "latitude": "44.83333000",
        "longitude": "1.25000000"
    },
    {
        "id": 40565,
        "name": "Carspach",
        "latitude": "47.61608000",
        "longitude": "7.21018000"
    },
    {
        "id": 40566,
        "name": "Cartignies",
        "latitude": "50.09290000",
        "longitude": "3.84473000"
    },
    {
        "id": 40567,
        "name": "Carvin",
        "latitude": "50.49235000",
        "longitude": "2.95815000"
    },
    {
        "id": 40568,
        "name": "Cassagnes-Bégonhès",
        "latitude": "44.16893000",
        "longitude": "2.53084000"
    },
    {
        "id": 40569,
        "name": "Cassel",
        "latitude": "50.80109000",
        "longitude": "2.48527000"
    },
    {
        "id": 40570,
        "name": "Casseneuil",
        "latitude": "44.44310000",
        "longitude": "0.62095000"
    },
    {
        "id": 40571,
        "name": "Cassis",
        "latitude": "43.21571000",
        "longitude": "5.53855000"
    },
    {
        "id": 40572,
        "name": "Casson",
        "latitude": "47.38761000",
        "longitude": "-1.55654000"
    },
    {
        "id": 40573,
        "name": "Cast",
        "latitude": "48.15712000",
        "longitude": "-4.13889000"
    },
    {
        "id": 40574,
        "name": "Castagniers",
        "latitude": "43.79134000",
        "longitude": "7.23162000"
    },
    {
        "id": 40575,
        "name": "Castanet-Tolosan",
        "latitude": "43.51591000",
        "longitude": "1.49864000"
    },
    {
        "id": 40576,
        "name": "Castelculier",
        "latitude": "44.17486000",
        "longitude": "0.69142000"
    },
    {
        "id": 40577,
        "name": "Castelginest",
        "latitude": "43.69382000",
        "longitude": "1.43440000"
    },
    {
        "id": 40578,
        "name": "Casteljaloux",
        "latitude": "44.31762000",
        "longitude": "0.08605000"
    },
    {
        "id": 40579,
        "name": "Castellane",
        "latitude": "43.84707000",
        "longitude": "6.51283000"
    },
    {
        "id": 40580,
        "name": "Castelmaurou",
        "latitude": "43.67778000",
        "longitude": "1.53222000"
    },
    {
        "id": 40581,
        "name": "Castelmoron-sur-Lot",
        "latitude": "44.39810000",
        "longitude": "0.49572000"
    },
    {
        "id": 40583,
        "name": "Castelnau-d'Estrétefonds",
        "latitude": "43.78367000",
        "longitude": "1.35904000"
    },
    {
        "id": 40584,
        "name": "Castelnau-de-Guers",
        "latitude": "43.43398000",
        "longitude": "3.43708000"
    },
    {
        "id": 40585,
        "name": "Castelnau-de-Lévis",
        "latitude": "43.93975000",
        "longitude": "2.08491000"
    },
    {
        "id": 40586,
        "name": "Castelnau-de-Médoc",
        "latitude": "45.02934000",
        "longitude": "-0.79828000"
    },
    {
        "id": 40587,
        "name": "Castelnau-le-Lez",
        "latitude": "43.63333000",
        "longitude": "3.90000000"
    },
    {
        "id": 40582,
        "name": "Castelnau-Montratier",
        "latitude": "44.26667000",
        "longitude": "1.36667000"
    },
    {
        "id": 40588,
        "name": "Castelnaudary",
        "latitude": "43.31829000",
        "longitude": "1.95449000"
    },
    {
        "id": 40589,
        "name": "Castelsarrasin",
        "latitude": "44.04022000",
        "longitude": "1.10702000"
    },
    {
        "id": 40590,
        "name": "Castets",
        "latitude": "43.88233000",
        "longitude": "-1.14572000"
    },
    {
        "id": 40591,
        "name": "Castets-en-Dorthe",
        "latitude": "44.56146000",
        "longitude": "-0.15226000"
    },
    {
        "id": 40592,
        "name": "Castillon-du-Gard",
        "latitude": "43.96457000",
        "longitude": "4.55337000"
    },
    {
        "id": 40593,
        "name": "Castillon-la-Bataille",
        "latitude": "44.85000000",
        "longitude": "-0.03333000"
    },
    {
        "id": 40594,
        "name": "Castillonnès",
        "latitude": "44.65215000",
        "longitude": "0.59222000"
    },
    {
        "id": 40595,
        "name": "Castres",
        "latitude": "43.60589000",
        "longitude": "2.23992000"
    },
    {
        "id": 40596,
        "name": "Castres-Gironde",
        "latitude": "44.69464000",
        "longitude": "-0.44679000"
    },
    {
        "id": 40597,
        "name": "Castries",
        "latitude": "43.67972000",
        "longitude": "3.98222000"
    },
    {
        "id": 40598,
        "name": "Catenoy",
        "latitude": "49.36824000",
        "longitude": "2.51054000"
    },
    {
        "id": 40599,
        "name": "Cattenom",
        "latitude": "49.40627000",
        "longitude": "6.24297000"
    },
    {
        "id": 40600,
        "name": "Cauchy-à-la-Tour",
        "latitude": "50.50394000",
        "longitude": "2.44606000"
    },
    {
        "id": 40601,
        "name": "Caudan",
        "latitude": "47.80877000",
        "longitude": "-3.34250000"
    },
    {
        "id": 40602,
        "name": "Caudebec-en-Caux",
        "latitude": "49.52577000",
        "longitude": "0.72561000"
    },
    {
        "id": 40603,
        "name": "Caudebec-lès-Elbeuf",
        "latitude": "49.28082000",
        "longitude": "1.02195000"
    },
    {
        "id": 40604,
        "name": "Caudry",
        "latitude": "50.12490000",
        "longitude": "3.41186000"
    },
    {
        "id": 40605,
        "name": "Cauffry",
        "latitude": "49.31978000",
        "longitude": "2.44581000"
    },
    {
        "id": 40606,
        "name": "Caulnes",
        "latitude": "48.28804000",
        "longitude": "-2.15518000"
    },
    {
        "id": 40607,
        "name": "Caumont",
        "latitude": "49.36642000",
        "longitude": "0.89591000"
    },
    {
        "id": 40608,
        "name": "Caumont-l'Éventé",
        "latitude": "49.09017000",
        "longitude": "-0.80501000"
    },
    {
        "id": 40609,
        "name": "Caumont-sur-Durance",
        "latitude": "43.89356000",
        "longitude": "4.94745000"
    },
    {
        "id": 40610,
        "name": "Caunes-Minervois",
        "latitude": "43.32555000",
        "longitude": "2.52541000"
    },
    {
        "id": 40611,
        "name": "Cauro",
        "latitude": "41.91756000",
        "longitude": "8.91480000"
    },
    {
        "id": 40612,
        "name": "Caussade",
        "latitude": "44.16080000",
        "longitude": "1.53913000"
    },
    {
        "id": 40613,
        "name": "Cauterets",
        "latitude": "42.88333000",
        "longitude": "-0.11667000"
    },
    {
        "id": 40614,
        "name": "Cauvigny",
        "latitude": "49.30180000",
        "longitude": "2.24844000"
    },
    {
        "id": 40615,
        "name": "Cauville-sur-Mer",
        "latitude": "49.60000000",
        "longitude": "0.13333000"
    },
    {
        "id": 40616,
        "name": "Caux",
        "latitude": "43.50621000",
        "longitude": "3.36709000"
    },
    {
        "id": 40617,
        "name": "Cavaillon",
        "latitude": "43.83125000",
        "longitude": "5.03586000"
    },
    {
        "id": 40618,
        "name": "Cavalaire-sur-Mer",
        "latitude": "43.17261000",
        "longitude": "6.52959000"
    },
    {
        "id": 40619,
        "name": "Cavan",
        "latitude": "48.67242000",
        "longitude": "-3.34606000"
    },
    {
        "id": 40620,
        "name": "Caveirac",
        "latitude": "43.82437000",
        "longitude": "4.26664000"
    },
    {
        "id": 40621,
        "name": "Cavignac",
        "latitude": "45.10083000",
        "longitude": "-0.38976000"
    },
    {
        "id": 40622,
        "name": "Cayeux-sur-Mer",
        "latitude": "50.18200000",
        "longitude": "1.49400000"
    },
    {
        "id": 40623,
        "name": "Caylus",
        "latitude": "44.23607000",
        "longitude": "1.77168000"
    },
    {
        "id": 40624,
        "name": "Cazaubon",
        "latitude": "43.93431000",
        "longitude": "-0.06814000"
    },
    {
        "id": 40628,
        "name": "Cazères",
        "latitude": "43.20710000",
        "longitude": "1.08633000"
    },
    {
        "id": 40629,
        "name": "Cazères-sur-l’Adour",
        "latitude": "43.76154000",
        "longitude": "-0.31546000"
    },
    {
        "id": 40625,
        "name": "Cazes-Mondenard",
        "latitude": "44.22689000",
        "longitude": "1.20299000"
    },
    {
        "id": 40626,
        "name": "Cazilhac",
        "latitude": "43.18205000",
        "longitude": "2.36085000"
    },
    {
        "id": 40627,
        "name": "Cazouls-lès-Béziers",
        "latitude": "43.39218000",
        "longitude": "3.10100000"
    },
    {
        "id": 40631,
        "name": "Ceaucé",
        "latitude": "48.49436000",
        "longitude": "-0.62526000"
    },
    {
        "id": 41389,
        "name": "Cébazat",
        "latitude": "45.83140000",
        "longitude": "3.09992000"
    },
    {
        "id": 40632,
        "name": "Celle-Lévescault",
        "latitude": "46.42406000",
        "longitude": "0.18810000"
    },
    {
        "id": 40633,
        "name": "Celles-sur-Belle",
        "latitude": "46.26223000",
        "longitude": "-0.21274000"
    },
    {
        "id": 40634,
        "name": "Celles-sur-Durolle",
        "latitude": "45.85829000",
        "longitude": "3.63540000"
    },
    {
        "id": 40635,
        "name": "Cellettes",
        "latitude": "47.52758000",
        "longitude": "1.38102000"
    },
    {
        "id": 40636,
        "name": "Cellieu",
        "latitude": "45.51866000",
        "longitude": "4.54332000"
    },
    {
        "id": 41390,
        "name": "Cély",
        "latitude": "48.45959000",
        "longitude": "2.53245000"
    },
    {
        "id": 41391,
        "name": "Cénac",
        "latitude": "44.78003000",
        "longitude": "-0.45999000"
    },
    {
        "id": 41392,
        "name": "Cénac-et-Saint-Julien",
        "latitude": "44.79968000",
        "longitude": "1.20535000"
    },
    {
        "id": 40637,
        "name": "Cendras",
        "latitude": "44.15000000",
        "longitude": "4.06667000"
    },
    {
        "id": 40638,
        "name": "Cenon",
        "latitude": "44.85590000",
        "longitude": "-0.51839000"
    },
    {
        "id": 40639,
        "name": "Cenon-sur-Vienne",
        "latitude": "46.77426000",
        "longitude": "0.53698000"
    },
    {
        "id": 41393,
        "name": "Cépet",
        "latitude": "43.74885000",
        "longitude": "1.43168000"
    },
    {
        "id": 40640,
        "name": "Cepoy",
        "latitude": "48.04795000",
        "longitude": "2.73782000"
    },
    {
        "id": 41394,
        "name": "Cérans-Foulletourte",
        "latitude": "47.82651000",
        "longitude": "0.07724000"
    },
    {
        "id": 40641,
        "name": "Cercoux",
        "latitude": "45.13333000",
        "longitude": "-0.20000000"
    },
    {
        "id": 40642,
        "name": "Cercy-la-Tour",
        "latitude": "46.86203000",
        "longitude": "3.64652000"
    },
    {
        "id": 40643,
        "name": "Cerdon",
        "latitude": "47.63571000",
        "longitude": "2.36277000"
    },
    {
        "id": 40644,
        "name": "Cerelles",
        "latitude": "47.50168000",
        "longitude": "0.68333000"
    },
    {
        "id": 41395,
        "name": "Cérences",
        "latitude": "48.91667000",
        "longitude": "-1.43470000"
    },
    {
        "id": 41396,
        "name": "Céreste",
        "latitude": "43.85580000",
        "longitude": "5.58685000"
    },
    {
        "id": 40645,
        "name": "Ceret",
        "latitude": "42.48533000",
        "longitude": "2.74804000"
    },
    {
        "id": 40646,
        "name": "Cergy",
        "latitude": "49.03645000",
        "longitude": "2.07613000"
    },
    {
        "id": 40647,
        "name": "Cergy-Pontoise",
        "latitude": "49.03894000",
        "longitude": "2.07805000"
    },
    {
        "id": 41397,
        "name": "Cérilly",
        "latitude": "46.61791000",
        "longitude": "2.82080000"
    },
    {
        "id": 40648,
        "name": "Cerisy-la-Salle",
        "latitude": "49.02622000",
        "longitude": "-1.28283000"
    },
    {
        "id": 40649,
        "name": "Cerizay",
        "latitude": "46.82154000",
        "longitude": "-0.66726000"
    },
    {
        "id": 40650,
        "name": "Cernay",
        "latitude": "47.80970000",
        "longitude": "7.17699000"
    },
    {
        "id": 40651,
        "name": "Cernay-la-Ville",
        "latitude": "48.67324000",
        "longitude": "1.97422000"
    },
    {
        "id": 40652,
        "name": "Cernay-lès-Reims",
        "latitude": "49.26375000",
        "longitude": "4.10216000"
    },
    {
        "id": 40653,
        "name": "Cerny",
        "latitude": "48.47796000",
        "longitude": "2.32815000"
    },
    {
        "id": 41398,
        "name": "Cérons",
        "latitude": "44.63572000",
        "longitude": "-0.33351000"
    },
    {
        "id": 40654,
        "name": "Cers",
        "latitude": "43.32352000",
        "longitude": "3.30450000"
    },
    {
        "id": 40655,
        "name": "Certines",
        "latitude": "46.13244000",
        "longitude": "5.26525000"
    },
    {
        "id": 40656,
        "name": "Cervera de la Marenda",
        "latitude": "42.44094000",
        "longitude": "3.16518000"
    },
    {
        "id": 40657,
        "name": "Cervione",
        "latitude": "42.32835000",
        "longitude": "9.49343000"
    },
    {
        "id": 40658,
        "name": "Cessenon-sur-Orb",
        "latitude": "43.45011000",
        "longitude": "3.05154000"
    },
    {
        "id": 40659,
        "name": "Cessieu",
        "latitude": "45.56371000",
        "longitude": "5.37607000"
    },
    {
        "id": 40660,
        "name": "Cesson",
        "latitude": "48.56204000",
        "longitude": "2.60816000"
    },
    {
        "id": 40661,
        "name": "Cesson-Sévigné",
        "latitude": "48.12120000",
        "longitude": "-1.60300000"
    },
    {
        "id": 40662,
        "name": "Cessy",
        "latitude": "46.32032000",
        "longitude": "6.07477000"
    },
    {
        "id": 40663,
        "name": "Cestas",
        "latitude": "44.74345000",
        "longitude": "-0.67905000"
    },
    {
        "id": 40664,
        "name": "Ceton",
        "latitude": "48.22631000",
        "longitude": "0.74968000"
    },
    {
        "id": 40665,
        "name": "Ceyrat",
        "latitude": "45.73265000",
        "longitude": "3.06323000"
    },
    {
        "id": 40666,
        "name": "Ceyreste",
        "latitude": "43.21352000",
        "longitude": "5.62946000"
    },
    {
        "id": 40667,
        "name": "Ceyzériat",
        "latitude": "46.18126000",
        "longitude": "5.31977000"
    },
    {
        "id": 41399,
        "name": "Cézac",
        "latitude": "45.09019000",
        "longitude": "-0.41963000"
    },
    {
        "id": 41400,
        "name": "Cézy",
        "latitude": "47.99265000",
        "longitude": "3.34067000"
    },
    {
        "id": 40668,
        "name": "Chabanais",
        "latitude": "45.87339000",
        "longitude": "0.71763000"
    },
    {
        "id": 40669,
        "name": "Chabeuil",
        "latitude": "44.89843000",
        "longitude": "5.01438000"
    },
    {
        "id": 40670,
        "name": "Chablis",
        "latitude": "47.81386000",
        "longitude": "3.79835000"
    },
    {
        "id": 40931,
        "name": "Châbons",
        "latitude": "45.44282000",
        "longitude": "5.42542000"
    },
    {
        "id": 40671,
        "name": "Chabreloche",
        "latitude": "45.87900000",
        "longitude": "3.69664000"
    },
    {
        "id": 40672,
        "name": "Chabris",
        "latitude": "47.25343000",
        "longitude": "1.65181000"
    },
    {
        "id": 40673,
        "name": "Chacé",
        "latitude": "47.21435000",
        "longitude": "-0.07179000"
    },
    {
        "id": 40674,
        "name": "Chadrac",
        "latitude": "45.06181000",
        "longitude": "3.90261000"
    },
    {
        "id": 40675,
        "name": "Chagny",
        "latitude": "46.90953000",
        "longitude": "4.75190000"
    },
    {
        "id": 40676,
        "name": "Chaillac",
        "latitude": "46.43382000",
        "longitude": "1.29889000"
    },
    {
        "id": 40677,
        "name": "Chailland",
        "latitude": "48.22516000",
        "longitude": "-0.87387000"
    },
    {
        "id": 40682,
        "name": "Chaillé-les-Marais",
        "latitude": "46.39628000",
        "longitude": "-1.02369000"
    },
    {
        "id": 40678,
        "name": "Chailles",
        "latitude": "47.54160000",
        "longitude": "1.31220000"
    },
    {
        "id": 40679,
        "name": "Chaillevette",
        "latitude": "45.73030000",
        "longitude": "-1.05810000"
    },
    {
        "id": 40680,
        "name": "Chailly-en-Bière",
        "latitude": "48.46701000",
        "longitude": "2.60785000"
    },
    {
        "id": 40681,
        "name": "Chailly-en-Brie",
        "latitude": "48.79006000",
        "longitude": "3.12453000"
    },
    {
        "id": 40683,
        "name": "Chaingy",
        "latitude": "47.88326000",
        "longitude": "1.77059000"
    },
    {
        "id": 40684,
        "name": "Chalabre",
        "latitude": "42.98248000",
        "longitude": "2.00538000"
    },
    {
        "id": 40685,
        "name": "Chalais",
        "latitude": "45.27338000",
        "longitude": "0.03880000"
    },
    {
        "id": 40686,
        "name": "Chalamont",
        "latitude": "45.99734000",
        "longitude": "5.16865000"
    },
    {
        "id": 40687,
        "name": "Chalampé",
        "latitude": "47.82019000",
        "longitude": "7.54113000"
    },
    {
        "id": 40688,
        "name": "Chaleins",
        "latitude": "46.03165000",
        "longitude": "4.80789000"
    },
    {
        "id": 40932,
        "name": "Châlette-sur-Loing",
        "latitude": "48.01337000",
        "longitude": "2.73587000"
    },
    {
        "id": 40689,
        "name": "Chalifert",
        "latitude": "48.88993000",
        "longitude": "2.77339000"
    },
    {
        "id": 40690,
        "name": "Chaligny",
        "latitude": "48.62422000",
        "longitude": "6.08262000"
    },
    {
        "id": 40691,
        "name": "Chalindrey",
        "latitude": "47.80308000",
        "longitude": "5.42797000"
    },
    {
        "id": 40692,
        "name": "Challans",
        "latitude": "46.84363000",
        "longitude": "-1.87491000"
    },
    {
        "id": 40693,
        "name": "Challes",
        "latitude": "47.93033000",
        "longitude": "0.41511000"
    },
    {
        "id": 40694,
        "name": "Challes-les-Eaux",
        "latitude": "45.54685000",
        "longitude": "5.98098000"
    },
    {
        "id": 40695,
        "name": "Challex",
        "latitude": "46.18458000",
        "longitude": "5.97639000"
    },
    {
        "id": 40696,
        "name": "Challuy",
        "latitude": "46.94971000",
        "longitude": "3.14807000"
    },
    {
        "id": 40697,
        "name": "Chalo-Saint-Mars",
        "latitude": "48.42328000",
        "longitude": "2.06491000"
    },
    {
        "id": 40698,
        "name": "Chalon-sur-Saône",
        "latitude": "46.78112000",
        "longitude": "4.85372000"
    },
    {
        "id": 40699,
        "name": "Chalonnes-sur-Loire",
        "latitude": "47.35160000",
        "longitude": "-0.76310000"
    },
    {
        "id": 40933,
        "name": "Châlons-en-Champagne",
        "latitude": "48.95393000",
        "longitude": "4.36724000"
    },
    {
        "id": 40934,
        "name": "Châlonvillars",
        "latitude": "47.64055000",
        "longitude": "6.78407000"
    },
    {
        "id": 40935,
        "name": "Châlus",
        "latitude": "45.65440000",
        "longitude": "0.98011000"
    },
    {
        "id": 40700,
        "name": "Chamagnieu",
        "latitude": "45.68249000",
        "longitude": "5.16947000"
    },
    {
        "id": 40701,
        "name": "Chamalières",
        "latitude": "45.77364000",
        "longitude": "3.06703000"
    },
    {
        "id": 40702,
        "name": "Chamant",
        "latitude": "49.21990000",
        "longitude": "2.61176000"
    },
    {
        "id": 40703,
        "name": "Chamarande",
        "latitude": "48.51715000",
        "longitude": "2.21710000"
    },
    {
        "id": 40704,
        "name": "Chamarandes-Choignes",
        "latitude": "48.08333000",
        "longitude": "5.15000000"
    },
    {
        "id": 40705,
        "name": "Chamberet",
        "latitude": "45.58345000",
        "longitude": "1.71980000"
    },
    {
        "id": 40713,
        "name": "Chambéry",
        "latitude": "45.56628000",
        "longitude": "5.92079000"
    },
    {
        "id": 40706,
        "name": "Chambly",
        "latitude": "49.16667000",
        "longitude": "2.25000000"
    },
    {
        "id": 40714,
        "name": "Chambœuf",
        "latitude": "45.58333000",
        "longitude": "4.31667000"
    },
    {
        "id": 40707,
        "name": "Chambon-sur-Voueize",
        "latitude": "46.18938000",
        "longitude": "2.42568000"
    },
    {
        "id": 40708,
        "name": "Chamboulive",
        "latitude": "45.43215000",
        "longitude": "1.70441000"
    },
    {
        "id": 40709,
        "name": "Chambourcy",
        "latitude": "48.90655000",
        "longitude": "2.04100000"
    },
    {
        "id": 40710,
        "name": "Chambourg-sur-Indre",
        "latitude": "47.18159000",
        "longitude": "0.96863000"
    },
    {
        "id": 40711,
        "name": "Chambray-lès-Tours",
        "latitude": "47.33537000",
        "longitude": "0.70286000"
    },
    {
        "id": 40712,
        "name": "Chambretaud",
        "latitude": "46.92166000",
        "longitude": "-0.96405000"
    },
    {
        "id": 40715,
        "name": "Chameyrat",
        "latitude": "45.23407000",
        "longitude": "1.69811000"
    },
    {
        "id": 40716,
        "name": "Chamigny",
        "latitude": "48.97242000",
        "longitude": "3.15165000"
    },
    {
        "id": 40717,
        "name": "Chamonix-Mont-Blanc",
        "latitude": "45.92375000",
        "longitude": "6.86933000"
    },
    {
        "id": 40718,
        "name": "Champ-sur-Drac",
        "latitude": "45.06940000",
        "longitude": "5.73151000"
    },
    {
        "id": 40719,
        "name": "Champagnac",
        "latitude": "45.35660000",
        "longitude": "2.39942000"
    },
    {
        "id": 40727,
        "name": "Champagné",
        "latitude": "48.02185000",
        "longitude": "0.33096000"
    },
    {
        "id": 40721,
        "name": "Champagne-au-Mont-d’Or",
        "latitude": "45.79475000",
        "longitude": "4.79079000"
    },
    {
        "id": 40728,
        "name": "Champagné-les-Marais",
        "latitude": "46.38081000",
        "longitude": "-1.12112000"
    },
    {
        "id": 40720,
        "name": "Champagne-Mouton",
        "latitude": "45.99078000",
        "longitude": "0.41051000"
    },
    {
        "id": 40722,
        "name": "Champagne-sur-Oise",
        "latitude": "49.14052000",
        "longitude": "2.24233000"
    },
    {
        "id": 40723,
        "name": "Champagne-sur-Seine",
        "latitude": "48.39794000",
        "longitude": "2.79785000"
    },
    {
        "id": 40724,
        "name": "Champagney",
        "latitude": "47.70504000",
        "longitude": "6.68173000"
    },
    {
        "id": 40725,
        "name": "Champagnier",
        "latitude": "45.11155000",
        "longitude": "5.72716000"
    },
    {
        "id": 40726,
        "name": "Champagnole",
        "latitude": "46.74452000",
        "longitude": "5.91354000"
    },
    {
        "id": 40729,
        "name": "Champcevinel",
        "latitude": "45.21630000",
        "longitude": "0.72796000"
    },
    {
        "id": 40730,
        "name": "Champcueil",
        "latitude": "48.51594000",
        "longitude": "2.44674000"
    },
    {
        "id": 40731,
        "name": "Champdeniers-Saint-Denis",
        "latitude": "46.48333000",
        "longitude": "-0.40000000"
    },
    {
        "id": 40732,
        "name": "Champdieu",
        "latitude": "45.64533000",
        "longitude": "4.04705000"
    },
    {
        "id": 40733,
        "name": "Champeix",
        "latitude": "45.58863000",
        "longitude": "3.12878000"
    },
    {
        "id": 40734,
        "name": "Champenoux",
        "latitude": "48.74257000",
        "longitude": "6.34830000"
    },
    {
        "id": 40735,
        "name": "Champfleur",
        "latitude": "48.38668000",
        "longitude": "0.12942000"
    },
    {
        "id": 40736,
        "name": "Champforgeuil",
        "latitude": "46.81857000",
        "longitude": "4.83357000"
    },
    {
        "id": 40737,
        "name": "Champhol",
        "latitude": "48.46813000",
        "longitude": "1.50281000"
    },
    {
        "id": 40738,
        "name": "Champier",
        "latitude": "45.45540000",
        "longitude": "5.29237000"
    },
    {
        "id": 40743,
        "name": "Champigné",
        "latitude": "47.66332000",
        "longitude": "-0.57160000"
    },
    {
        "id": 40739,
        "name": "Champignelles",
        "latitude": "47.78009000",
        "longitude": "3.07457000"
    },
    {
        "id": 40740,
        "name": "Champigneulles",
        "latitude": "48.73404000",
        "longitude": "6.16181000"
    },
    {
        "id": 40741,
        "name": "Champigny",
        "latitude": "48.31667000",
        "longitude": "3.13333000"
    },
    {
        "id": 40742,
        "name": "Champigny-sur-Marne",
        "latitude": "48.81642000",
        "longitude": "2.49366000"
    },
    {
        "id": 40744,
        "name": "Champlan",
        "latitude": "48.70823000",
        "longitude": "2.27975000"
    },
    {
        "id": 40745,
        "name": "Champlitte",
        "latitude": "47.61667000",
        "longitude": "5.51667000"
    },
    {
        "id": 40746,
        "name": "Champlitte-la-Ville",
        "latitude": "47.61333000",
        "longitude": "5.53191000"
    },
    {
        "id": 40747,
        "name": "Champniers",
        "latitude": "45.71451000",
        "longitude": "0.20436000"
    },
    {
        "id": 40748,
        "name": "Champs-sur-Marne",
        "latitude": "48.85000000",
        "longitude": "2.60000000"
    },
    {
        "id": 40749,
        "name": "Champs-sur-Tarentaine-Marchal",
        "latitude": "45.40000000",
        "longitude": "2.56667000"
    },
    {
        "id": 40750,
        "name": "Champs-sur-Yonne",
        "latitude": "47.73333000",
        "longitude": "3.60000000"
    },
    {
        "id": 40751,
        "name": "Champsecret",
        "latitude": "48.60925000",
        "longitude": "-0.55058000"
    },
    {
        "id": 40753,
        "name": "Champtocé-sur-Loire",
        "latitude": "47.41239000",
        "longitude": "-0.86452000"
    },
    {
        "id": 40752,
        "name": "Champtoceaux",
        "latitude": "47.33476000",
        "longitude": "-1.26131000"
    },
    {
        "id": 40754,
        "name": "Champvans",
        "latitude": "47.10466000",
        "longitude": "5.43760000"
    },
    {
        "id": 40755,
        "name": "Chanac",
        "latitude": "44.46614000",
        "longitude": "3.34327000"
    },
    {
        "id": 40756,
        "name": "Chanas",
        "latitude": "45.31881000",
        "longitude": "4.81849000"
    },
    {
        "id": 40757,
        "name": "Chanceaux-sur-Choisille",
        "latitude": "47.47145000",
        "longitude": "0.70539000"
    },
    {
        "id": 40758,
        "name": "Chancelade",
        "latitude": "45.20049000",
        "longitude": "0.67261000"
    },
    {
        "id": 40759,
        "name": "Chancenay",
        "latitude": "48.67001000",
        "longitude": "4.98715000"
    },
    {
        "id": 40760,
        "name": "Chandon",
        "latitude": "46.14925000",
        "longitude": "4.21393000"
    },
    {
        "id": 40763,
        "name": "Changé",
        "latitude": "48.09918000",
        "longitude": "-0.79292000"
    },
    {
        "id": 40761,
        "name": "Changis-sur-Marne",
        "latitude": "48.95816000",
        "longitude": "3.02191000"
    },
    {
        "id": 40762,
        "name": "Changy-les-Bois",
        "latitude": "47.85793000",
        "longitude": "2.68543000"
    },
    {
        "id": 40764,
        "name": "Chaniers",
        "latitude": "45.71858000",
        "longitude": "-0.55779000"
    },
    {
        "id": 40765,
        "name": "Chanonat",
        "latitude": "45.69299000",
        "longitude": "3.09351000"
    },
    {
        "id": 40766,
        "name": "Chanos-Curson",
        "latitude": "45.06057000",
        "longitude": "4.92381000"
    },
    {
        "id": 40767,
        "name": "Chanteau",
        "latitude": "47.96651000",
        "longitude": "1.97129000"
    },
    {
        "id": 40768,
        "name": "Chanteheux",
        "latitude": "48.59722000",
        "longitude": "6.52783000"
    },
    {
        "id": 40769,
        "name": "Chantelle",
        "latitude": "46.23806000",
        "longitude": "3.15318000"
    },
    {
        "id": 40770,
        "name": "Chanteloup",
        "latitude": "47.96574000",
        "longitude": "-1.61517000"
    },
    {
        "id": 40771,
        "name": "Chanteloup-en-Brie",
        "latitude": "48.85478000",
        "longitude": "2.73929000"
    },
    {
        "id": 40772,
        "name": "Chanteloup-les-Vignes",
        "latitude": "48.97614000",
        "longitude": "2.03261000"
    },
    {
        "id": 40773,
        "name": "Chantenay-Saint-Imbert",
        "latitude": "46.73333000",
        "longitude": "3.18333000"
    },
    {
        "id": 40774,
        "name": "Chantepie",
        "latitude": "48.08885000",
        "longitude": "-1.61524000"
    },
    {
        "id": 40775,
        "name": "Chantilly",
        "latitude": "49.19461000",
        "longitude": "2.47124000"
    },
    {
        "id": 40778,
        "name": "Chantôme",
        "latitude": "46.41067000",
        "longitude": "1.55590000"
    },
    {
        "id": 40776,
        "name": "Chantonnay",
        "latitude": "46.68702000",
        "longitude": "-1.05024000"
    },
    {
        "id": 40777,
        "name": "Chantraine",
        "latitude": "48.17157000",
        "longitude": "6.43538000"
    },
    {
        "id": 40779,
        "name": "Chanu",
        "latitude": "48.72914000",
        "longitude": "-0.67603000"
    },
    {
        "id": 40780,
        "name": "Chaource",
        "latitude": "48.05915000",
        "longitude": "4.13738000"
    },
    {
        "id": 40781,
        "name": "Chapareillan",
        "latitude": "45.46513000",
        "longitude": "5.99171000"
    },
    {
        "id": 40782,
        "name": "Chapdes-Beaufort",
        "latitude": "45.89277000",
        "longitude": "2.86362000"
    },
    {
        "id": 40783,
        "name": "Chapet",
        "latitude": "48.96667000",
        "longitude": "1.93333000"
    },
    {
        "id": 40784,
        "name": "Chaponnay",
        "latitude": "45.62762000",
        "longitude": "4.93615000"
    },
    {
        "id": 40785,
        "name": "Chaponost",
        "latitude": "45.71020000",
        "longitude": "4.74221000"
    },
    {
        "id": 40786,
        "name": "Chappes",
        "latitude": "45.86848000",
        "longitude": "3.22068000"
    },
    {
        "id": 40787,
        "name": "Chaptelat",
        "latitude": "45.90962000",
        "longitude": "1.26018000"
    },
    {
        "id": 40788,
        "name": "Charantonnay",
        "latitude": "45.53705000",
        "longitude": "5.11005000"
    },
    {
        "id": 40789,
        "name": "Charavines",
        "latitude": "45.42891000",
        "longitude": "5.51492000"
    },
    {
        "id": 40790,
        "name": "Charbonnières-les-Bains",
        "latitude": "45.78053000",
        "longitude": "4.74637000"
    },
    {
        "id": 40791,
        "name": "Charbonnières-les-Varennes",
        "latitude": "45.90786000",
        "longitude": "2.99976000"
    },
    {
        "id": 40792,
        "name": "Charbuy",
        "latitude": "47.82282000",
        "longitude": "3.46617000"
    },
    {
        "id": 40793,
        "name": "Charentay",
        "latitude": "46.08897000",
        "longitude": "4.67921000"
    },
    {
        "id": 40794,
        "name": "Charente",
        "latitude": "45.70598000",
        "longitude": "0.18162000"
    },
    {
        "id": 40795,
        "name": "Charente-Maritime",
        "latitude": "45.75556000",
        "longitude": "-0.71314000"
    },
    {
        "id": 40796,
        "name": "Charentilly",
        "latitude": "47.46981000",
        "longitude": "0.60903000"
    },
    {
        "id": 40797,
        "name": "Charenton-du-Cher",
        "latitude": "46.73007000",
        "longitude": "2.64438000"
    },
    {
        "id": 40798,
        "name": "Charenton-le-Pont",
        "latitude": "48.82209000",
        "longitude": "2.41217000"
    },
    {
        "id": 40799,
        "name": "Chargé",
        "latitude": "47.43270000",
        "longitude": "1.03037000"
    },
    {
        "id": 40800,
        "name": "Charleval",
        "latitude": "43.71864000",
        "longitude": "5.24546000"
    },
    {
        "id": 40801,
        "name": "Charleval",
        "latitude": "49.37290000",
        "longitude": "1.38369000"
    },
    {
        "id": 40802,
        "name": "Charleville-Mézières",
        "latitude": "49.76850000",
        "longitude": "4.72487000"
    },
    {
        "id": 40803,
        "name": "Charlieu",
        "latitude": "46.16202000",
        "longitude": "4.17228000"
    },
    {
        "id": 40804,
        "name": "Charly",
        "latitude": "45.64887000",
        "longitude": "4.79461000"
    },
    {
        "id": 40805,
        "name": "Charly-sur-Marne",
        "latitude": "48.97749000",
        "longitude": "3.28464000"
    },
    {
        "id": 40806,
        "name": "Charmes",
        "latitude": "48.37220000",
        "longitude": "6.29117000"
    },
    {
        "id": 40807,
        "name": "Charmes",
        "latitude": "49.65345000",
        "longitude": "3.37857000"
    },
    {
        "id": 40808,
        "name": "Charmes-sur-Rhône",
        "latitude": "44.86367000",
        "longitude": "4.83533000"
    },
    {
        "id": 40809,
        "name": "Charnay",
        "latitude": "45.89058000",
        "longitude": "4.66821000"
    },
    {
        "id": 40810,
        "name": "Charnay-lès-Mâcon",
        "latitude": "46.30751000",
        "longitude": "4.78479000"
    },
    {
        "id": 40813,
        "name": "Charnècles",
        "latitude": "45.34398000",
        "longitude": "5.52799000"
    },
    {
        "id": 40811,
        "name": "Charny",
        "latitude": "47.88661000",
        "longitude": "3.09583000"
    },
    {
        "id": 40812,
        "name": "Charny",
        "latitude": "48.97098000",
        "longitude": "2.76121000"
    },
    {
        "id": 40814,
        "name": "Charolles",
        "latitude": "46.43451000",
        "longitude": "4.27527000"
    },
    {
        "id": 40936,
        "name": "Chârost",
        "latitude": "46.99390000",
        "longitude": "2.11639000"
    },
    {
        "id": 40815,
        "name": "Charquemont",
        "latitude": "47.21417000",
        "longitude": "6.81980000"
    },
    {
        "id": 40816,
        "name": "Charron",
        "latitude": "46.29433000",
        "longitude": "-1.10572000"
    },
    {
        "id": 40817,
        "name": "Charroux",
        "latitude": "46.14410000",
        "longitude": "0.40354000"
    },
    {
        "id": 40818,
        "name": "Chars",
        "latitude": "49.16032000",
        "longitude": "1.93669000"
    },
    {
        "id": 40819,
        "name": "Chartres",
        "latitude": "48.44685000",
        "longitude": "1.48925000"
    },
    {
        "id": 40820,
        "name": "Chartres-de-Bretagne",
        "latitude": "48.03924000",
        "longitude": "-1.70533000"
    },
    {
        "id": 40821,
        "name": "Chartrettes",
        "latitude": "48.48808000",
        "longitude": "2.70083000"
    },
    {
        "id": 40822,
        "name": "Charvieu-Chavagneux",
        "latitude": "45.75000000",
        "longitude": "5.15000000"
    },
    {
        "id": 40823,
        "name": "Chasné-sur-Illet",
        "latitude": "48.24247000",
        "longitude": "-1.56138000"
    },
    {
        "id": 40824,
        "name": "Chassagny",
        "latitude": "45.60670000",
        "longitude": "4.73214000"
    },
    {
        "id": 40825,
        "name": "Chasse-sur-Rhône",
        "latitude": "45.57850000",
        "longitude": "4.80985000"
    },
    {
        "id": 40826,
        "name": "Chasselay",
        "latitude": "45.87440000",
        "longitude": "4.77237000"
    },
    {
        "id": 40827,
        "name": "Chasseneuil-du-Poitou",
        "latitude": "46.65112000",
        "longitude": "0.37329000"
    },
    {
        "id": 40828,
        "name": "Chasseneuil-sur-Bonnieure",
        "latitude": "45.81667000",
        "longitude": "0.45000000"
    },
    {
        "id": 40829,
        "name": "Chassieu",
        "latitude": "45.74584000",
        "longitude": "4.97088000"
    },
    {
        "id": 40830,
        "name": "Chassors",
        "latitude": "45.70000000",
        "longitude": "-0.21667000"
    },
    {
        "id": 40937,
        "name": "Château-Chinon(Ville)",
        "latitude": "47.06667000",
        "longitude": "3.93333000"
    },
    {
        "id": 40948,
        "name": "Château-d’Olonne",
        "latitude": "46.50382000",
        "longitude": "-1.74097000"
    },
    {
        "id": 40947,
        "name": "Château-du-Loir",
        "latitude": "47.69492000",
        "longitude": "0.41851000"
    },
    {
        "id": 40938,
        "name": "Château-Gaillard",
        "latitude": "45.97239000",
        "longitude": "5.30436000"
    },
    {
        "id": 40939,
        "name": "Château-Gontier",
        "latitude": "47.82872000",
        "longitude": "-0.70265000"
    },
    {
        "id": 40940,
        "name": "Château-Guibert",
        "latitude": "46.58110000",
        "longitude": "-1.23656000"
    },
    {
        "id": 40949,
        "name": "Château-la-Vallière",
        "latitude": "47.54665000",
        "longitude": "0.32458000"
    },
    {
        "id": 40941,
        "name": "Château-Landon",
        "latitude": "48.14721000",
        "longitude": "2.69754000"
    },
    {
        "id": 40942,
        "name": "Château-Porcien",
        "latitude": "49.52740000",
        "longitude": "4.24533000"
    },
    {
        "id": 40943,
        "name": "Château-Renard",
        "latitude": "47.93333000",
        "longitude": "2.93333000"
    },
    {
        "id": 40944,
        "name": "Château-Renault",
        "latitude": "47.59188000",
        "longitude": "0.91143000"
    },
    {
        "id": 40945,
        "name": "Château-Salins",
        "latitude": "48.81885000",
        "longitude": "6.51455000"
    },
    {
        "id": 40946,
        "name": "Château-Thierry",
        "latitude": "49.04636000",
        "longitude": "3.40304000"
    },
    {
        "id": 40950,
        "name": "Châteaubernard",
        "latitude": "45.66667000",
        "longitude": "-0.33333000"
    },
    {
        "id": 40951,
        "name": "Châteaubourg",
        "latitude": "48.11119000",
        "longitude": "-1.40019000"
    },
    {
        "id": 40952,
        "name": "Châteaubriant",
        "latitude": "47.71712000",
        "longitude": "-1.37624000"
    },
    {
        "id": 40953,
        "name": "Châteaudun",
        "latitude": "48.07086000",
        "longitude": "1.33783000"
    },
    {
        "id": 40954,
        "name": "Châteaufort",
        "latitude": "48.73584000",
        "longitude": "2.09054000"
    },
    {
        "id": 40955,
        "name": "Châteaugay",
        "latitude": "45.85117000",
        "longitude": "3.08482000"
    },
    {
        "id": 40956,
        "name": "Châteaugiron",
        "latitude": "48.04629000",
        "longitude": "-1.50438000"
    },
    {
        "id": 40957,
        "name": "Châteaulin",
        "latitude": "48.19677000",
        "longitude": "-4.09008000"
    },
    {
        "id": 40958,
        "name": "Châteaumeillant",
        "latitude": "46.56219000",
        "longitude": "2.19515000"
    },
    {
        "id": 40959,
        "name": "Châteauneuf",
        "latitude": "45.52457000",
        "longitude": "4.64044000"
    },
    {
        "id": 40966,
        "name": "Châteauneuf-d’Ille-et-Vilaine",
        "latitude": "48.56083000",
        "longitude": "-1.92838000"
    },
    {
        "id": 40961,
        "name": "Châteauneuf-de-Gadagne",
        "latitude": "43.92683000",
        "longitude": "4.94453000"
    },
    {
        "id": 40962,
        "name": "Châteauneuf-de-Galaure",
        "latitude": "45.22977000",
        "longitude": "4.95777000"
    },
    {
        "id": 40963,
        "name": "Châteauneuf-du-Faou",
        "latitude": "48.18755000",
        "longitude": "-3.81789000"
    },
    {
        "id": 40964,
        "name": "Châteauneuf-du-Pape",
        "latitude": "44.05638000",
        "longitude": "4.83244000"
    },
    {
        "id": 40965,
        "name": "Châteauneuf-du-Rhône",
        "latitude": "44.48906000",
        "longitude": "4.71706000"
    },
    {
        "id": 40967,
        "name": "Châteauneuf-en-Thymerais",
        "latitude": "48.58112000",
        "longitude": "1.24085000"
    },
    {
        "id": 40960,
        "name": "Châteauneuf-Grasse",
        "latitude": "43.66667000",
        "longitude": "6.98333000"
    },
    {
        "id": 40968,
        "name": "Châteauneuf-la-Forêt",
        "latitude": "45.71436000",
        "longitude": "1.60610000"
    },
    {
        "id": 40969,
        "name": "Châteauneuf-le-Rouge",
        "latitude": "43.48936000",
        "longitude": "5.56921000"
    },
    {
        "id": 40970,
        "name": "Châteauneuf-les-Martigues",
        "latitude": "43.38383000",
        "longitude": "5.16403000"
    },
    {
        "id": 40971,
        "name": "Châteauneuf-sur-Charente",
        "latitude": "45.60000000",
        "longitude": "-0.05000000"
    },
    {
        "id": 40972,
        "name": "Châteauneuf-sur-Cher",
        "latitude": "46.85778000",
        "longitude": "2.31710000"
    },
    {
        "id": 40973,
        "name": "Châteauneuf-sur-Isère",
        "latitude": "45.01667000",
        "longitude": "4.93333000"
    },
    {
        "id": 40974,
        "name": "Châteauneuf-sur-Loire",
        "latitude": "47.86575000",
        "longitude": "2.21903000"
    },
    {
        "id": 40975,
        "name": "Châteauneuf-sur-Sarthe",
        "latitude": "47.68145000",
        "longitude": "-0.48652000"
    },
    {
        "id": 40976,
        "name": "Châteauponsac",
        "latitude": "46.13536000",
        "longitude": "1.27623000"
    },
    {
        "id": 40977,
        "name": "Châteaurenard",
        "latitude": "43.88169000",
        "longitude": "4.85493000"
    },
    {
        "id": 40978,
        "name": "Châteauroux",
        "latitude": "46.81248000",
        "longitude": "1.69362000"
    },
    {
        "id": 40979,
        "name": "Châteauvillain",
        "latitude": "48.03655000",
        "longitude": "4.91823000"
    },
    {
        "id": 40980,
        "name": "Châtel",
        "latitude": "46.26495000",
        "longitude": "6.84030000"
    },
    {
        "id": 40981,
        "name": "Châtel-Guyon",
        "latitude": "45.92258000",
        "longitude": "3.06423000"
    },
    {
        "id": 40982,
        "name": "Châtel-Saint-Germain",
        "latitude": "49.12255000",
        "longitude": "6.08006000"
    },
    {
        "id": 40983,
        "name": "Châtel-sur-Moselle",
        "latitude": "48.31430000",
        "longitude": "6.39403000"
    },
    {
        "id": 40984,
        "name": "Châtelaillon-Plage",
        "latitude": "46.07190000",
        "longitude": "-1.08926000"
    },
    {
        "id": 40985,
        "name": "Châtellerault",
        "latitude": "46.81712000",
        "longitude": "0.54536000"
    },
    {
        "id": 40986,
        "name": "Châtenay-Malabry",
        "latitude": "48.76507000",
        "longitude": "2.26655000"
    },
    {
        "id": 40987,
        "name": "Châtenay-sur-Seine",
        "latitude": "48.41839000",
        "longitude": "3.09474000"
    },
    {
        "id": 40988,
        "name": "Châtenois",
        "latitude": "48.27201000",
        "longitude": "7.40109000"
    },
    {
        "id": 40989,
        "name": "Châtenois-les-Forges",
        "latitude": "47.55875000",
        "longitude": "6.84871000"
    },
    {
        "id": 40990,
        "name": "Châtenoy-le-Royal",
        "latitude": "46.79797000",
        "longitude": "4.81190000"
    },
    {
        "id": 40991,
        "name": "Châtillon",
        "latitude": "45.80091000",
        "longitude": "5.84352000"
    },
    {
        "id": 40992,
        "name": "Châtillon",
        "latitude": "48.80240000",
        "longitude": "2.29346000"
    },
    {
        "id": 40993,
        "name": "Châtillon-Coligny",
        "latitude": "47.82266000",
        "longitude": "2.84563000"
    },
    {
        "id": 40994,
        "name": "Châtillon-en-Bazois",
        "latitude": "47.05464000",
        "longitude": "3.65893000"
    },
    {
        "id": 40995,
        "name": "Châtillon-en-Michaille",
        "latitude": "46.14320000",
        "longitude": "5.79950000"
    },
    {
        "id": 40996,
        "name": "Châtillon-en-Vendelais",
        "latitude": "48.22409000",
        "longitude": "-1.17695000"
    },
    {
        "id": 40997,
        "name": "Châtillon-la-Palud",
        "latitude": "45.97171000",
        "longitude": "5.25290000"
    },
    {
        "id": 40998,
        "name": "Châtillon-le-Duc",
        "latitude": "47.30486000",
        "longitude": "6.00792000"
    },
    {
        "id": 40999,
        "name": "Châtillon-sur-Chalaronne",
        "latitude": "46.11834000",
        "longitude": "4.95656000"
    },
    {
        "id": 41000,
        "name": "Châtillon-sur-Cher",
        "latitude": "47.27571000",
        "longitude": "1.49424000"
    },
    {
        "id": 41001,
        "name": "Châtillon-sur-Cluses",
        "latitude": "46.08765000",
        "longitude": "6.58041000"
    },
    {
        "id": 41002,
        "name": "Châtillon-sur-Indre",
        "latitude": "46.98735000",
        "longitude": "1.17218000"
    },
    {
        "id": 41003,
        "name": "Châtillon-sur-Marne",
        "latitude": "49.10048000",
        "longitude": "3.76023000"
    },
    {
        "id": 41004,
        "name": "Châtillon-sur-Seiche",
        "latitude": "48.03448000",
        "longitude": "-1.67114000"
    },
    {
        "id": 41005,
        "name": "Châtillon-sur-Seine",
        "latitude": "47.85851000",
        "longitude": "4.57375000"
    },
    {
        "id": 41006,
        "name": "Châtillon-sur-Thouet",
        "latitude": "46.66176000",
        "longitude": "-0.23489000"
    },
    {
        "id": 41007,
        "name": "Châtonnay",
        "latitude": "45.48734000",
        "longitude": "5.21168000"
    },
    {
        "id": 40831,
        "name": "Chatou",
        "latitude": "48.88980000",
        "longitude": "2.15863000"
    },
    {
        "id": 41008,
        "name": "Châtres-sur-Cher",
        "latitude": "47.26505000",
        "longitude": "1.90591000"
    },
    {
        "id": 40832,
        "name": "Chatte",
        "latitude": "45.14248000",
        "longitude": "5.28224000"
    },
    {
        "id": 40833,
        "name": "Chatuzange-le-Goubet",
        "latitude": "45.00359000",
        "longitude": "5.09079000"
    },
    {
        "id": 40834,
        "name": "Chauché",
        "latitude": "46.82963000",
        "longitude": "-1.27178000"
    },
    {
        "id": 40835,
        "name": "Chauconin-Neufmontiers",
        "latitude": "48.96667000",
        "longitude": "2.85000000"
    },
    {
        "id": 40836,
        "name": "Chaudes-Aigues",
        "latitude": "44.85455000",
        "longitude": "3.00406000"
    },
    {
        "id": 40837,
        "name": "Chaudon",
        "latitude": "48.66276000",
        "longitude": "1.49670000"
    },
    {
        "id": 40838,
        "name": "Chaudron-en-Mauges",
        "latitude": "47.28809000",
        "longitude": "-0.98547000"
    },
    {
        "id": 40839,
        "name": "Chauffailles",
        "latitude": "46.20726000",
        "longitude": "4.33932000"
    },
    {
        "id": 40840,
        "name": "Chaulgnes",
        "latitude": "47.12889000",
        "longitude": "3.10348000"
    },
    {
        "id": 40841,
        "name": "Chaulnes",
        "latitude": "49.81699000",
        "longitude": "2.80064000"
    },
    {
        "id": 40842,
        "name": "Chaumes-en-Brie",
        "latitude": "48.66853000",
        "longitude": "2.84015000"
    },
    {
        "id": 40843,
        "name": "Chaumont",
        "latitude": "48.11121000",
        "longitude": "5.14134000"
    },
    {
        "id": 40844,
        "name": "Chaumont-en-Vexin",
        "latitude": "49.26595000",
        "longitude": "1.88597000"
    },
    {
        "id": 40845,
        "name": "Chaumont-sur-Loire",
        "latitude": "47.48108000",
        "longitude": "1.18929000"
    },
    {
        "id": 40846,
        "name": "Chaumont-sur-Tharonne",
        "latitude": "47.61039000",
        "longitude": "1.90514000"
    },
    {
        "id": 40847,
        "name": "Chaumontel",
        "latitude": "49.12470000",
        "longitude": "2.43237000"
    },
    {
        "id": 40848,
        "name": "Chaunay",
        "latitude": "46.20759000",
        "longitude": "0.16084000"
    },
    {
        "id": 40849,
        "name": "Chauny",
        "latitude": "49.61514000",
        "longitude": "3.21857000"
    },
    {
        "id": 40850,
        "name": "Chauray",
        "latitude": "46.35997000",
        "longitude": "-0.37859000"
    },
    {
        "id": 40851,
        "name": "Chauriat",
        "latitude": "45.75127000",
        "longitude": "3.27895000"
    },
    {
        "id": 40852,
        "name": "Chaussin",
        "latitude": "46.96612000",
        "longitude": "5.40791000"
    },
    {
        "id": 40854,
        "name": "Chauvé",
        "latitude": "47.15174000",
        "longitude": "-1.98489000"
    },
    {
        "id": 40853,
        "name": "Chauvigny",
        "latitude": "46.56747000",
        "longitude": "0.64928000"
    },
    {
        "id": 40855,
        "name": "Chavagne",
        "latitude": "48.05438000",
        "longitude": "-1.78571000"
    },
    {
        "id": 40856,
        "name": "Chavagnes-en-Paillers",
        "latitude": "46.89167000",
        "longitude": "-1.25214000"
    },
    {
        "id": 40857,
        "name": "Chavanay",
        "latitude": "45.41647000",
        "longitude": "4.72602000"
    },
    {
        "id": 40858,
        "name": "Chavanod",
        "latitude": "45.89005000",
        "longitude": "6.03928000"
    },
    {
        "id": 40859,
        "name": "Chavanoz",
        "latitude": "45.76846000",
        "longitude": "5.18808000"
    },
    {
        "id": 40860,
        "name": "Chavelot",
        "latitude": "48.23459000",
        "longitude": "6.43809000"
    },
    {
        "id": 40861,
        "name": "Chavenay",
        "latitude": "48.85437000",
        "longitude": "1.99163000"
    },
    {
        "id": 40862,
        "name": "Chavigny",
        "latitude": "48.62899000",
        "longitude": "6.12317000"
    },
    {
        "id": 40863,
        "name": "Chaville",
        "latitude": "48.80565000",
        "longitude": "2.18864000"
    },
    {
        "id": 40864,
        "name": "Chazelles",
        "latitude": "45.64713000",
        "longitude": "0.36748000"
    },
    {
        "id": 40865,
        "name": "Chazelles-sur-Lyon",
        "latitude": "45.63779000",
        "longitude": "4.38890000"
    },
    {
        "id": 40866,
        "name": "Chazey-sur-Ain",
        "latitude": "45.89300000",
        "longitude": "5.25352000"
    },
    {
        "id": 41010,
        "name": "Chécy",
        "latitude": "47.89402000",
        "longitude": "2.02304000"
    },
    {
        "id": 40867,
        "name": "Chef-Boutonne",
        "latitude": "46.10859000",
        "longitude": "-0.07083000"
    },
    {
        "id": 40868,
        "name": "Cheillé",
        "latitude": "47.26114000",
        "longitude": "0.40553000"
    },
    {
        "id": 40869,
        "name": "Chelles",
        "latitude": "48.88109000",
        "longitude": "2.59295000"
    },
    {
        "id": 40870,
        "name": "Chemaudin",
        "latitude": "47.22392000",
        "longitude": "5.89419000"
    },
    {
        "id": 40871,
        "name": "Chemazé",
        "latitude": "47.78690000",
        "longitude": "-0.77523000"
    },
    {
        "id": 41011,
        "name": "Chéméré",
        "latitude": "47.11667000",
        "longitude": "-1.91667000"
    },
    {
        "id": 40872,
        "name": "Chemillé-Melay",
        "latitude": "47.21476000",
        "longitude": "-0.72488000"
    },
    {
        "id": 41018,
        "name": "Chênehutte-Trèves-Cunault",
        "latitude": "47.31084000",
        "longitude": "-0.16042000"
    },
    {
        "id": 40873,
        "name": "Cheniménil",
        "latitude": "48.13880000",
        "longitude": "6.60346000"
    },
    {
        "id": 40874,
        "name": "Chennevières-sur-Marne",
        "latitude": "48.79702000",
        "longitude": "2.54046000"
    },
    {
        "id": 40875,
        "name": "Chenoise",
        "latitude": "48.61462000",
        "longitude": "3.19459000"
    },
    {
        "id": 40878,
        "name": "Chenôve",
        "latitude": "47.29323000",
        "longitude": "5.00457000"
    },
    {
        "id": 40876,
        "name": "Chens-sur-Léman",
        "latitude": "46.32459000",
        "longitude": "6.27075000"
    },
    {
        "id": 40877,
        "name": "Cheny",
        "latitude": "47.95166000",
        "longitude": "3.53340000"
    },
    {
        "id": 40879,
        "name": "Cheptainville",
        "latitude": "48.55090000",
        "longitude": "2.27665000"
    },
    {
        "id": 41012,
        "name": "Chépy",
        "latitude": "50.06361000",
        "longitude": "1.64694000"
    },
    {
        "id": 40880,
        "name": "Cher",
        "latitude": "47.11304000",
        "longitude": "2.50983000"
    },
    {
        "id": 41013,
        "name": "Chérac",
        "latitude": "45.70456000",
        "longitude": "-0.43859000"
    },
    {
        "id": 41014,
        "name": "Chéraute",
        "latitude": "43.23096000",
        "longitude": "-0.86831000"
    },
    {
        "id": 40881,
        "name": "Cherbourg-Octeville",
        "latitude": "49.63984000",
        "longitude": "-1.61636000"
    },
    {
        "id": 41015,
        "name": "Chéreng",
        "latitude": "50.61059000",
        "longitude": "3.20666000"
    },
    {
        "id": 40882,
        "name": "Cherisy",
        "latitude": "48.75000000",
        "longitude": "1.43333000"
    },
    {
        "id": 40883,
        "name": "Chermignac",
        "latitude": "45.68578000",
        "longitude": "-0.67349000"
    },
    {
        "id": 41016,
        "name": "Chéroy",
        "latitude": "48.20076000",
        "longitude": "3.00011000"
    },
    {
        "id": 40885,
        "name": "Cherré",
        "latitude": "48.17290000",
        "longitude": "0.65781000"
    },
    {
        "id": 40884,
        "name": "Cherrueix",
        "latitude": "48.60629000",
        "longitude": "-1.70405000"
    },
    {
        "id": 40886,
        "name": "Cherves-Richemont",
        "latitude": "45.74345000",
        "longitude": "-0.35096000"
    },
    {
        "id": 40887,
        "name": "Cherveux",
        "latitude": "46.41543000",
        "longitude": "-0.35706000"
    },
    {
        "id": 40888,
        "name": "Chessy",
        "latitude": "45.88716000",
        "longitude": "4.62339000"
    },
    {
        "id": 40889,
        "name": "Chessy",
        "latitude": "48.88333000",
        "longitude": "2.76667000"
    },
    {
        "id": 40890,
        "name": "Cheux",
        "latitude": "49.16611000",
        "longitude": "-0.52544000"
    },
    {
        "id": 40891,
        "name": "Chevaigné",
        "latitude": "48.21153000",
        "longitude": "-1.62933000"
    },
    {
        "id": 40892,
        "name": "Cheval-Blanc",
        "latitude": "43.80189000",
        "longitude": "5.06229000"
    },
    {
        "id": 40893,
        "name": "Chevanceaux",
        "latitude": "45.30000000",
        "longitude": "-0.23333000"
    },
    {
        "id": 40894,
        "name": "Chevannes",
        "latitude": "48.53259000",
        "longitude": "2.44388000"
    },
    {
        "id": 40895,
        "name": "Cheverny",
        "latitude": "47.50079000",
        "longitude": "1.45951000"
    },
    {
        "id": 40896,
        "name": "Chevigny-Saint-Sauveur",
        "latitude": "47.29908000",
        "longitude": "5.13367000"
    },
    {
        "id": 40897,
        "name": "Chevillon",
        "latitude": "48.52869000",
        "longitude": "5.13086000"
    },
    {
        "id": 40898,
        "name": "Chevillon-sur-Huillard",
        "latitude": "47.96197000",
        "longitude": "2.62601000"
    },
    {
        "id": 40899,
        "name": "Chevilly",
        "latitude": "48.02973000",
        "longitude": "1.87402000"
    },
    {
        "id": 40900,
        "name": "Chevilly-Larue",
        "latitude": "48.76476000",
        "longitude": "2.35030000"
    },
    {
        "id": 41009,
        "name": "Chèvremont",
        "latitude": "47.62912000",
        "longitude": "6.92056000"
    },
    {
        "id": 40901,
        "name": "Chevreuse",
        "latitude": "48.70662000",
        "longitude": "2.03329000"
    },
    {
        "id": 40902,
        "name": "Chevrières",
        "latitude": "49.34645000",
        "longitude": "2.68219000"
    },
    {
        "id": 40903,
        "name": "Chevry",
        "latitude": "46.28136000",
        "longitude": "6.03873000"
    },
    {
        "id": 40904,
        "name": "Chevry-Cossigny",
        "latitude": "48.72465000",
        "longitude": "2.66106000"
    },
    {
        "id": 41017,
        "name": "Chézy-sur-Marne",
        "latitude": "48.98881000",
        "longitude": "3.36786000"
    },
    {
        "id": 40905,
        "name": "Chiché",
        "latitude": "46.79643000",
        "longitude": "-0.35560000"
    },
    {
        "id": 40906,
        "name": "Chierry",
        "latitude": "49.03940000",
        "longitude": "3.42976000"
    },
    {
        "id": 40907,
        "name": "Chilleurs-aux-Bois",
        "latitude": "48.07220000",
        "longitude": "2.13540000"
    },
    {
        "id": 40908,
        "name": "Chilly",
        "latitude": "45.99185000",
        "longitude": "5.95477000"
    },
    {
        "id": 40909,
        "name": "Chilly-Mazarin",
        "latitude": "48.71489000",
        "longitude": "2.31638000"
    },
    {
        "id": 40910,
        "name": "Chimilin",
        "latitude": "45.57490000",
        "longitude": "5.59569000"
    },
    {
        "id": 40911,
        "name": "Chindrieux",
        "latitude": "45.81948000",
        "longitude": "5.85024000"
    },
    {
        "id": 40912,
        "name": "Chinon",
        "latitude": "47.16701000",
        "longitude": "0.24284000"
    },
    {
        "id": 40913,
        "name": "Chirac",
        "latitude": "44.52289000",
        "longitude": "3.26652000"
    },
    {
        "id": 40914,
        "name": "Chirens",
        "latitude": "45.41024000",
        "longitude": "5.55634000"
    },
    {
        "id": 40915,
        "name": "Chiry-Ourscamp",
        "latitude": "49.54378000",
        "longitude": "2.94721000"
    },
    {
        "id": 40916,
        "name": "Chissay-en-Touraine",
        "latitude": "47.33747000",
        "longitude": "1.13362000"
    },
    {
        "id": 40917,
        "name": "Chitenay",
        "latitude": "47.49753000",
        "longitude": "1.37139000"
    },
    {
        "id": 40918,
        "name": "Chocques",
        "latitude": "50.54084000",
        "longitude": "2.57193000"
    },
    {
        "id": 40919,
        "name": "Choisey",
        "latitude": "47.06389000",
        "longitude": "5.45911000"
    },
    {
        "id": 40920,
        "name": "Choisy",
        "latitude": "45.99359000",
        "longitude": "6.05866000"
    },
    {
        "id": 40921,
        "name": "Choisy-au-Bac",
        "latitude": "49.43777000",
        "longitude": "2.87739000"
    },
    {
        "id": 40922,
        "name": "Choisy-en-Brie",
        "latitude": "48.75867000",
        "longitude": "3.21705000"
    },
    {
        "id": 40923,
        "name": "Choisy-le-Roi",
        "latitude": "48.76846000",
        "longitude": "2.41874000"
    },
    {
        "id": 40924,
        "name": "Cholet",
        "latitude": "47.05905000",
        "longitude": "-0.87953000"
    },
    {
        "id": 40925,
        "name": "Chomérac",
        "latitude": "44.70752000",
        "longitude": "4.66164000"
    },
    {
        "id": 40926,
        "name": "Chorges",
        "latitude": "44.54879000",
        "longitude": "6.27727000"
    },
    {
        "id": 40928,
        "name": "Chouzé-sur-Loire",
        "latitude": "47.23673000",
        "longitude": "0.12364000"
    },
    {
        "id": 40927,
        "name": "Chouzy-sur-Cisse",
        "latitude": "47.52576000",
        "longitude": "1.24661000"
    },
    {
        "id": 40929,
        "name": "Chutes-Lavie",
        "latitude": "43.31017000",
        "longitude": "5.39464000"
    },
    {
        "id": 40930,
        "name": "Chuzelles",
        "latitude": "45.58481000",
        "longitude": "4.87703000"
    },
    {
        "id": 41019,
        "name": "Ciboure",
        "latitude": "43.38287000",
        "longitude": "-1.67600000"
    },
    {
        "id": 41020,
        "name": "Cinq Avenues",
        "latitude": "43.30375000",
        "longitude": "5.39761000"
    },
    {
        "id": 41021,
        "name": "Cinq-Mars-la-Pile",
        "latitude": "47.34638000",
        "longitude": "0.45873000"
    },
    {
        "id": 41022,
        "name": "Cinqueux",
        "latitude": "49.31739000",
        "longitude": "2.52997000"
    },
    {
        "id": 41023,
        "name": "Cintegabelle",
        "latitude": "43.31316000",
        "longitude": "1.53333000"
    },
    {
        "id": 41024,
        "name": "Cintré",
        "latitude": "48.10504000",
        "longitude": "-1.87162000"
    },
    {
        "id": 41028,
        "name": "Ciré-d’Aunis",
        "latitude": "46.05544000",
        "longitude": "-0.93056000"
    },
    {
        "id": 41025,
        "name": "Cires-lès-Mello",
        "latitude": "49.27242000",
        "longitude": "2.35840000"
    },
    {
        "id": 41026,
        "name": "Cirey-sur-Vezouze",
        "latitude": "48.58093000",
        "longitude": "6.94573000"
    },
    {
        "id": 41027,
        "name": "Ciry-le-Noble",
        "latitude": "46.60607000",
        "longitude": "4.29869000"
    },
    {
        "id": 41029,
        "name": "Cissé",
        "latitude": "46.64583000",
        "longitude": "0.22870000"
    },
    {
        "id": 41030,
        "name": "Civens",
        "latitude": "45.77964000",
        "longitude": "4.25170000"
    },
    {
        "id": 41031,
        "name": "Civray",
        "latitude": "46.14710000",
        "longitude": "0.29509000"
    },
    {
        "id": 41032,
        "name": "Civray-de-Touraine",
        "latitude": "47.33253000",
        "longitude": "1.04952000"
    },
    {
        "id": 41033,
        "name": "Civrieux",
        "latitude": "45.92086000",
        "longitude": "4.88249000"
    },
    {
        "id": 41034,
        "name": "Claira",
        "latitude": "42.76036000",
        "longitude": "2.95572000"
    },
    {
        "id": 41035,
        "name": "Clairac",
        "latitude": "44.36011000",
        "longitude": "0.37893000"
    },
    {
        "id": 41036,
        "name": "Clairoix",
        "latitude": "49.44264000",
        "longitude": "2.84628000"
    },
    {
        "id": 41037,
        "name": "Clairvaux-les-Lacs",
        "latitude": "46.57473000",
        "longitude": "5.74825000"
    },
    {
        "id": 41038,
        "name": "Claix",
        "latitude": "45.11994000",
        "longitude": "5.67292000"
    },
    {
        "id": 41039,
        "name": "Clamart",
        "latitude": "48.80299000",
        "longitude": "2.26692000"
    },
    {
        "id": 41040,
        "name": "Clamecy",
        "latitude": "47.46017000",
        "longitude": "3.51940000"
    },
    {
        "id": 41041,
        "name": "Clapiers",
        "latitude": "43.65833000",
        "longitude": "3.88917000"
    },
    {
        "id": 41042,
        "name": "Clarensac",
        "latitude": "43.82536000",
        "longitude": "4.22047000"
    },
    {
        "id": 41043,
        "name": "Claret",
        "latitude": "43.86244000",
        "longitude": "3.90522000"
    },
    {
        "id": 41044,
        "name": "Clary",
        "latitude": "50.07789000",
        "longitude": "3.39943000"
    },
    {
        "id": 41045,
        "name": "Claville",
        "latitude": "49.04844000",
        "longitude": "1.01954000"
    },
    {
        "id": 41046,
        "name": "Claye-Souilly",
        "latitude": "48.94492000",
        "longitude": "2.68566000"
    },
    {
        "id": 41066,
        "name": "Clécy",
        "latitude": "48.91718000",
        "longitude": "-0.48041000"
    },
    {
        "id": 41067,
        "name": "Cléden-Poher",
        "latitude": "48.23644000",
        "longitude": "-3.66911000"
    },
    {
        "id": 41068,
        "name": "Cléder",
        "latitude": "48.66300000",
        "longitude": "-4.10200000"
    },
    {
        "id": 41069,
        "name": "Cléguer",
        "latitude": "47.85407000",
        "longitude": "-3.38219000"
    },
    {
        "id": 41070,
        "name": "Cléguérec",
        "latitude": "48.12577000",
        "longitude": "-3.07162000"
    },
    {
        "id": 41071,
        "name": "Cléon",
        "latitude": "49.31235000",
        "longitude": "1.02950000"
    },
    {
        "id": 41072,
        "name": "Clérac",
        "latitude": "45.18333000",
        "longitude": "-0.21667000"
    },
    {
        "id": 41075,
        "name": "Cléré-les-Pins",
        "latitude": "47.42550000",
        "longitude": "0.38963000"
    },
    {
        "id": 41065,
        "name": "Clères",
        "latitude": "49.60000000",
        "longitude": "1.11667000"
    },
    {
        "id": 41073,
        "name": "Clérieux",
        "latitude": "45.07591000",
        "longitude": "4.95983000"
    },
    {
        "id": 41047,
        "name": "Clermont",
        "latitude": "49.37897000",
        "longitude": "2.41258000"
    },
    {
        "id": 41048,
        "name": "Clermont-Créans",
        "latitude": "47.71831000",
        "longitude": "-0.01459000"
    },
    {
        "id": 41050,
        "name": "Clermont-en-Argonne",
        "latitude": "49.10711000",
        "longitude": "5.07002000"
    },
    {
        "id": 41049,
        "name": "Clermont-Ferrand",
        "latitude": "45.77969000",
        "longitude": "3.08682000"
    },
    {
        "id": 41051,
        "name": "Clerval",
        "latitude": "47.39167000",
        "longitude": "6.49925000"
    },
    {
        "id": 41074,
        "name": "Cléry-Saint-André",
        "latitude": "47.82218000",
        "longitude": "1.75091000"
    },
    {
        "id": 41052,
        "name": "Clichy",
        "latitude": "48.90018000",
        "longitude": "2.30952000"
    },
    {
        "id": 41053,
        "name": "Clichy-sous-Bois",
        "latitude": "48.91020000",
        "longitude": "2.55323000"
    },
    {
        "id": 41054,
        "name": "Clinchamps-sur-Orne",
        "latitude": "49.07857000",
        "longitude": "-0.40156000"
    },
    {
        "id": 41055,
        "name": "Clion",
        "latitude": "46.94085000",
        "longitude": "1.23214000"
    },
    {
        "id": 41056,
        "name": "Clisson",
        "latitude": "47.08714000",
        "longitude": "-1.28286000"
    },
    {
        "id": 41057,
        "name": "Clohars-Carnoët",
        "latitude": "47.79606000",
        "longitude": "-3.58502000"
    },
    {
        "id": 41058,
        "name": "Clohars-Fouesnant",
        "latitude": "47.89657000",
        "longitude": "-4.06396000"
    },
    {
        "id": 41059,
        "name": "Clonas-sur-Varèze",
        "latitude": "45.41382000",
        "longitude": "4.79077000"
    },
    {
        "id": 41060,
        "name": "Clouange",
        "latitude": "49.26240000",
        "longitude": "6.09723000"
    },
    {
        "id": 41061,
        "name": "Cloyes-sur-le-Loir",
        "latitude": "47.99726000",
        "longitude": "1.23711000"
    },
    {
        "id": 41062,
        "name": "Cluis",
        "latitude": "46.54486000",
        "longitude": "1.74933000"
    },
    {
        "id": 41063,
        "name": "Cluny",
        "latitude": "46.43318000",
        "longitude": "4.65845000"
    },
    {
        "id": 41064,
        "name": "Cluses",
        "latitude": "46.06251000",
        "longitude": "6.57497000"
    },
    {
        "id": 41076,
        "name": "Coarraze",
        "latitude": "43.16667000",
        "longitude": "-0.23333000"
    },
    {
        "id": 41077,
        "name": "Cocheren",
        "latitude": "49.14384000",
        "longitude": "6.85649000"
    },
    {
        "id": 41078,
        "name": "Codognan",
        "latitude": "43.73033000",
        "longitude": "4.22120000"
    },
    {
        "id": 41291,
        "name": "Coësmes",
        "latitude": "47.88325000",
        "longitude": "-1.44074000"
    },
    {
        "id": 41292,
        "name": "Coëtmieux",
        "latitude": "48.49117000",
        "longitude": "-2.60067000"
    },
    {
        "id": 41293,
        "name": "Coëx",
        "latitude": "46.69808000",
        "longitude": "-1.75956000"
    },
    {
        "id": 41079,
        "name": "Cognac",
        "latitude": "45.69583000",
        "longitude": "-0.32916000"
    },
    {
        "id": 41080,
        "name": "Cognac-la-Forêt",
        "latitude": "45.83333000",
        "longitude": "1.00000000"
    },
    {
        "id": 41081,
        "name": "Cognin",
        "latitude": "45.55952000",
        "longitude": "5.89113000"
    },
    {
        "id": 41082,
        "name": "Cogny",
        "latitude": "45.98747000",
        "longitude": "4.62475000"
    },
    {
        "id": 41083,
        "name": "Cogolin",
        "latitude": "43.25294000",
        "longitude": "6.52981000"
    },
    {
        "id": 41084,
        "name": "Coignières",
        "latitude": "48.75010000",
        "longitude": "1.92082000"
    },
    {
        "id": 41085,
        "name": "Coincy",
        "latitude": "49.16036000",
        "longitude": "3.42202000"
    },
    {
        "id": 41086,
        "name": "Coise",
        "latitude": "45.52822000",
        "longitude": "6.14389000"
    },
    {
        "id": 41087,
        "name": "Colayrac-Saint-Cirq",
        "latitude": "44.22095000",
        "longitude": "0.55061000"
    },
    {
        "id": 41088,
        "name": "Coligny",
        "latitude": "46.38252000",
        "longitude": "5.34554000"
    },
    {
        "id": 41095,
        "name": "Collégien",
        "latitude": "48.83571000",
        "longitude": "2.67365000"
    },
    {
        "id": 41089,
        "name": "Colleret",
        "latitude": "50.25693000",
        "longitude": "4.08083000"
    },
    {
        "id": 41090,
        "name": "Colleville-Montgomery",
        "latitude": "49.27528000",
        "longitude": "-0.30052000"
    },
    {
        "id": 41091,
        "name": "Collioure",
        "latitude": "42.52462000",
        "longitude": "3.08235000"
    },
    {
        "id": 41092,
        "name": "Collobrières",
        "latitude": "43.23718000",
        "longitude": "6.30901000"
    },
    {
        "id": 41093,
        "name": "Collonges",
        "latitude": "46.13819000",
        "longitude": "5.90506000"
    },
    {
        "id": 41094,
        "name": "Collonges-sous-Salève",
        "latitude": "46.14160000",
        "longitude": "6.15372000"
    },
    {
        "id": 41096,
        "name": "Colmar",
        "latitude": "48.08078000",
        "longitude": "7.35584000"
    },
    {
        "id": 41097,
        "name": "Colomars",
        "latitude": "43.76320000",
        "longitude": "7.22191000"
    },
    {
        "id": 41098,
        "name": "Colombe",
        "latitude": "45.40024000",
        "longitude": "5.45441000"
    },
    {
        "id": 41099,
        "name": "Colombelles",
        "latitude": "49.20490000",
        "longitude": "-0.29571000"
    },
    {
        "id": 41100,
        "name": "Colombes",
        "latitude": "48.91882000",
        "longitude": "2.25404000"
    },
    {
        "id": 41101,
        "name": "Colombey-les-Belles",
        "latitude": "48.52920000",
        "longitude": "5.89451000"
    },
    {
        "id": 41102,
        "name": "Colombier-Fontaine",
        "latitude": "47.45224000",
        "longitude": "6.69010000"
    },
    {
        "id": 41103,
        "name": "Colombiers",
        "latitude": "43.31424000",
        "longitude": "3.13947000"
    },
    {
        "id": 41104,
        "name": "Colombiers",
        "latitude": "46.77158000",
        "longitude": "0.42388000"
    },
    {
        "id": 41105,
        "name": "Colombiès",
        "latitude": "44.34414000",
        "longitude": "2.33772000"
    },
    {
        "id": 41106,
        "name": "Colomiers",
        "latitude": "43.61058000",
        "longitude": "1.33467000"
    },
    {
        "id": 41107,
        "name": "Colpo",
        "latitude": "47.81778000",
        "longitude": "-2.81002000"
    },
    {
        "id": 41108,
        "name": "Combaillaux",
        "latitude": "43.67191000",
        "longitude": "3.76767000"
    },
    {
        "id": 41109,
        "name": "Combloux",
        "latitude": "45.89790000",
        "longitude": "6.64420000"
    },
    {
        "id": 41110,
        "name": "Combourg",
        "latitude": "48.41267000",
        "longitude": "-1.74424000"
    },
    {
        "id": 41111,
        "name": "Combrand",
        "latitude": "46.86405000",
        "longitude": "-0.68869000"
    },
    {
        "id": 41114,
        "name": "Combrée",
        "latitude": "47.70446000",
        "longitude": "-1.03003000"
    },
    {
        "id": 41112,
        "name": "Combrit",
        "latitude": "47.88734000",
        "longitude": "-4.15817000"
    },
    {
        "id": 41113,
        "name": "Combronde",
        "latitude": "45.98099000",
        "longitude": "3.08807000"
    },
    {
        "id": 41115,
        "name": "Combs-la-Ville",
        "latitude": "48.66497000",
        "longitude": "2.56957000"
    },
    {
        "id": 41116,
        "name": "Comines",
        "latitude": "50.76150000",
        "longitude": "3.01063000"
    },
    {
        "id": 41117,
        "name": "Commelle",
        "latitude": "46.00086000",
        "longitude": "4.05794000"
    },
    {
        "id": 41118,
        "name": "Commentry",
        "latitude": "46.28876000",
        "longitude": "2.74163000"
    },
    {
        "id": 41119,
        "name": "Commequiers",
        "latitude": "46.76049000",
        "longitude": "-1.83901000"
    },
    {
        "id": 41120,
        "name": "Commer",
        "latitude": "48.23333000",
        "longitude": "-0.61667000"
    },
    {
        "id": 41121,
        "name": "Commercy",
        "latitude": "48.76128000",
        "longitude": "5.59067000"
    },
    {
        "id": 41122,
        "name": "Communay",
        "latitude": "45.60442000",
        "longitude": "4.83488000"
    },
    {
        "id": 41123,
        "name": "Compertrix",
        "latitude": "48.94179000",
        "longitude": "4.34631000"
    },
    {
        "id": 41124,
        "name": "Compiègne",
        "latitude": "49.41794000",
        "longitude": "2.82606000"
    },
    {
        "id": 41125,
        "name": "Compreignac",
        "latitude": "45.99162000",
        "longitude": "1.27561000"
    },
    {
        "id": 41126,
        "name": "Comps",
        "latitude": "43.85304000",
        "longitude": "4.60567000"
    },
    {
        "id": 41127,
        "name": "Concarneau",
        "latitude": "47.87502000",
        "longitude": "-3.92245000"
    },
    {
        "id": 41128,
        "name": "Conches-en-Ouche",
        "latitude": "48.95768000",
        "longitude": "0.94052000"
    },
    {
        "id": 41129,
        "name": "Conches-sur-Gondoire",
        "latitude": "48.85624000",
        "longitude": "2.71783000"
    },
    {
        "id": 41130,
        "name": "Condat",
        "latitude": "45.34093000",
        "longitude": "2.75791000"
    },
    {
        "id": 41131,
        "name": "Condat-sur-Vienne",
        "latitude": "45.78648000",
        "longitude": "1.28454000"
    },
    {
        "id": 41135,
        "name": "Condé-Sainte-Libiaire",
        "latitude": "48.89695000",
        "longitude": "2.83904000"
    },
    {
        "id": 41136,
        "name": "Condé-sur-Huisne",
        "latitude": "48.38103000",
        "longitude": "0.85093000"
    },
    {
        "id": 41141,
        "name": "Condé-sur-l’Escaut",
        "latitude": "50.45436000",
        "longitude": "3.58884000"
    },
    {
        "id": 41137,
        "name": "Condé-sur-Noireau",
        "latitude": "48.84881000",
        "longitude": "-0.55214000"
    },
    {
        "id": 41138,
        "name": "Condé-sur-Sarthe",
        "latitude": "48.43197000",
        "longitude": "0.03398000"
    },
    {
        "id": 41139,
        "name": "Condé-sur-Vesgre",
        "latitude": "48.74199000",
        "longitude": "1.66069000"
    },
    {
        "id": 41140,
        "name": "Condé-sur-Vire",
        "latitude": "49.05000000",
        "longitude": "-1.03333000"
    },
    {
        "id": 41132,
        "name": "Condette",
        "latitude": "50.65817000",
        "longitude": "1.63386000"
    },
    {
        "id": 41133,
        "name": "Condom",
        "latitude": "43.95816000",
        "longitude": "0.37199000"
    },
    {
        "id": 41134,
        "name": "Condrieu",
        "latitude": "45.46300000",
        "longitude": "4.76765000"
    },
    {
        "id": 41143,
        "name": "Conflans-en-Jarnisy",
        "latitude": "49.16725000",
        "longitude": "5.85515000"
    },
    {
        "id": 41142,
        "name": "Conflans-Sainte-Honorine",
        "latitude": "49.00158000",
        "longitude": "2.09694000"
    },
    {
        "id": 41144,
        "name": "Confolens",
        "latitude": "46.01363000",
        "longitude": "0.67231000"
    },
    {
        "id": 41147,
        "name": "Congénies",
        "latitude": "43.76667000",
        "longitude": "4.16667000"
    },
    {
        "id": 41145,
        "name": "Congis-sur-Thérouanne",
        "latitude": "49.00000000",
        "longitude": "2.98333000"
    },
    {
        "id": 41146,
        "name": "Congrier",
        "latitude": "47.80989000",
        "longitude": "-1.11700000"
    },
    {
        "id": 41148,
        "name": "Conlie",
        "latitude": "48.12334000",
        "longitude": "-0.01739000"
    },
    {
        "id": 41149,
        "name": "Connantre",
        "latitude": "48.72657000",
        "longitude": "3.92403000"
    },
    {
        "id": 41150,
        "name": "Connaux",
        "latitude": "44.08919000",
        "longitude": "4.59387000"
    },
    {
        "id": 41151,
        "name": "Connerré",
        "latitude": "48.05698000",
        "longitude": "0.49344000"
    },
    {
        "id": 41152,
        "name": "Conquereuil",
        "latitude": "47.62524000",
        "longitude": "-1.75105000"
    },
    {
        "id": 41153,
        "name": "Conques-sur-Orbiel",
        "latitude": "43.26667000",
        "longitude": "2.41667000"
    },
    {
        "id": 41154,
        "name": "Contamine-sur-Arve",
        "latitude": "46.14223000",
        "longitude": "6.33215000"
    },
    {
        "id": 41155,
        "name": "Contes",
        "latitude": "43.81278000",
        "longitude": "7.31444000"
    },
    {
        "id": 41156,
        "name": "Contres",
        "latitude": "47.41754000",
        "longitude": "1.42849000"
    },
    {
        "id": 41157,
        "name": "Contrexéville",
        "latitude": "48.18424000",
        "longitude": "5.89572000"
    },
    {
        "id": 41158,
        "name": "Conty",
        "latitude": "49.74100000",
        "longitude": "2.15120000"
    },
    {
        "id": 41159,
        "name": "Coquelles",
        "latitude": "50.93461000",
        "longitude": "1.79880000"
    },
    {
        "id": 41160,
        "name": "Coray",
        "latitude": "48.05934000",
        "longitude": "-3.83056000"
    },
    {
        "id": 41161,
        "name": "Corbarieu",
        "latitude": "43.94415000",
        "longitude": "1.36881000"
    },
    {
        "id": 41162,
        "name": "Corbas",
        "latitude": "45.66798000",
        "longitude": "4.90198000"
    },
    {
        "id": 41163,
        "name": "Corbehem",
        "latitude": "50.33141000",
        "longitude": "3.04995000"
    },
    {
        "id": 41164,
        "name": "Corbeil-Essonnes",
        "latitude": "48.60603000",
        "longitude": "2.48757000"
    },
    {
        "id": 41165,
        "name": "Corbeilles",
        "latitude": "48.07201000",
        "longitude": "2.55030000"
    },
    {
        "id": 41166,
        "name": "Corbelin",
        "latitude": "45.60733000",
        "longitude": "5.54261000"
    },
    {
        "id": 41167,
        "name": "Corbenay",
        "latitude": "47.89275000",
        "longitude": "6.33047000"
    },
    {
        "id": 41168,
        "name": "Corbie",
        "latitude": "49.90672000",
        "longitude": "2.50682000"
    },
    {
        "id": 41169,
        "name": "Corbigny",
        "latitude": "47.25678000",
        "longitude": "3.68285000"
    },
    {
        "id": 41170,
        "name": "Corbreuse",
        "latitude": "48.50065000",
        "longitude": "1.95913000"
    },
    {
        "id": 41171,
        "name": "Corcieux",
        "latitude": "48.17236000",
        "longitude": "6.88148000"
    },
    {
        "id": 41172,
        "name": "Corcoué-sur-Logne",
        "latitude": "46.96667000",
        "longitude": "-1.58333000"
    },
    {
        "id": 41173,
        "name": "Cordemais",
        "latitude": "47.29107000",
        "longitude": "-1.87869000"
    },
    {
        "id": 41174,
        "name": "Cordes-sur-Ciel",
        "latitude": "44.06667000",
        "longitude": "1.95000000"
    },
    {
        "id": 41175,
        "name": "Cordon",
        "latitude": "45.92099000",
        "longitude": "6.60536000"
    },
    {
        "id": 41176,
        "name": "Corenc",
        "latitude": "45.21889000",
        "longitude": "5.76497000"
    },
    {
        "id": 41177,
        "name": "Corlay",
        "latitude": "48.31725000",
        "longitude": "-3.05733000"
    },
    {
        "id": 41178,
        "name": "Corme-Royal",
        "latitude": "45.74395000",
        "longitude": "-0.81471000"
    },
    {
        "id": 41179,
        "name": "Cormeilles",
        "latitude": "49.24803000",
        "longitude": "0.37654000"
    },
    {
        "id": 41180,
        "name": "Cormeilles-en-Parisis",
        "latitude": "48.97111000",
        "longitude": "2.20491000"
    },
    {
        "id": 41181,
        "name": "Cormelles-le-Royal",
        "latitude": "49.15398000",
        "longitude": "-0.33062000"
    },
    {
        "id": 41182,
        "name": "Cormeray",
        "latitude": "47.49195000",
        "longitude": "1.40610000"
    },
    {
        "id": 41183,
        "name": "Cormery",
        "latitude": "47.26812000",
        "longitude": "0.83583000"
    },
    {
        "id": 41184,
        "name": "Cormicy",
        "latitude": "49.37071000",
        "longitude": "3.89595000"
    },
    {
        "id": 41185,
        "name": "Cormontreuil",
        "latitude": "49.21667000",
        "longitude": "4.05000000"
    },
    {
        "id": 41186,
        "name": "Cornas",
        "latitude": "44.96382000",
        "longitude": "4.84839000"
    },
    {
        "id": 41197,
        "name": "Corné",
        "latitude": "47.47091000",
        "longitude": "-0.34992000"
    },
    {
        "id": 41187,
        "name": "Cornebarrieu",
        "latitude": "43.64895000",
        "longitude": "1.32407000"
    },
    {
        "id": 41188,
        "name": "Corneilhan",
        "latitude": "43.39957000",
        "longitude": "3.19147000"
    },
    {
        "id": 41189,
        "name": "Corneilla-del-Vercol",
        "latitude": "42.62390000",
        "longitude": "2.95216000"
    },
    {
        "id": 41190,
        "name": "Corneilla-la-Rivière",
        "latitude": "42.69675000",
        "longitude": "2.72962000"
    },
    {
        "id": 41191,
        "name": "Corneville-sur-Risle",
        "latitude": "49.34128000",
        "longitude": "0.58628000"
    },
    {
        "id": 41192,
        "name": "Cornier",
        "latitude": "46.09304000",
        "longitude": "6.29895000"
    },
    {
        "id": 41193,
        "name": "Cornil",
        "latitude": "45.21062000",
        "longitude": "1.69173000"
    },
    {
        "id": 41194,
        "name": "Cornillon-Confoux",
        "latitude": "43.56267000",
        "longitude": "5.07162000"
    },
    {
        "id": 41195,
        "name": "Cornimont",
        "latitude": "47.95998000",
        "longitude": "6.83038000"
    },
    {
        "id": 41196,
        "name": "Corny-sur-Moselle",
        "latitude": "49.03557000",
        "longitude": "6.06084000"
    },
    {
        "id": 41198,
        "name": "Coron",
        "latitude": "47.12726000",
        "longitude": "-0.64512000"
    },
    {
        "id": 41199,
        "name": "Corpeau",
        "latitude": "46.92917000",
        "longitude": "4.75226000"
    },
    {
        "id": 41200,
        "name": "Corps-Nuds",
        "latitude": "47.97915000",
        "longitude": "-1.58409000"
    },
    {
        "id": 41201,
        "name": "Corquilleroy",
        "latitude": "48.04212000",
        "longitude": "2.70382000"
    },
    {
        "id": 41202,
        "name": "Corrèze",
        "latitude": "45.37244000",
        "longitude": "1.87513000"
    },
    {
        "id": 41203,
        "name": "Corsept",
        "latitude": "47.27703000",
        "longitude": "-2.05904000"
    },
    {
        "id": 41204,
        "name": "Corseul",
        "latitude": "48.48180000",
        "longitude": "-2.16947000"
    },
    {
        "id": 41205,
        "name": "Corte",
        "latitude": "42.30956000",
        "longitude": "9.14917000"
    },
    {
        "id": 41206,
        "name": "Corzé",
        "latitude": "47.55956000",
        "longitude": "-0.39062000"
    },
    {
        "id": 41207,
        "name": "Cosnac",
        "latitude": "45.13423000",
        "longitude": "1.58544000"
    },
    {
        "id": 41208,
        "name": "Cosne-Cours-sur-Loire",
        "latitude": "47.41101000",
        "longitude": "2.92528000"
    },
    {
        "id": 41209,
        "name": "Cosnes",
        "latitude": "49.51964000",
        "longitude": "5.71210000"
    },
    {
        "id": 41210,
        "name": "Cossé-le-Vivien",
        "latitude": "47.94599000",
        "longitude": "-0.91185000"
    },
    {
        "id": 41211,
        "name": "Cotignac",
        "latitude": "43.52876000",
        "longitude": "6.14955000"
    },
    {
        "id": 41212,
        "name": "Coubert",
        "latitude": "48.67186000",
        "longitude": "2.69733000"
    },
    {
        "id": 41213,
        "name": "Coublevie",
        "latitude": "45.35856000",
        "longitude": "5.61146000"
    },
    {
        "id": 41214,
        "name": "Coubon",
        "latitude": "44.99731000",
        "longitude": "3.91783000"
    },
    {
        "id": 41215,
        "name": "Coubron",
        "latitude": "48.91667000",
        "longitude": "2.58333000"
    },
    {
        "id": 41216,
        "name": "Couches",
        "latitude": "46.86667000",
        "longitude": "4.56667000"
    },
    {
        "id": 41217,
        "name": "Couchey",
        "latitude": "47.25991000",
        "longitude": "4.98257000"
    },
    {
        "id": 41218,
        "name": "Coucy-le-Château-Auffrique",
        "latitude": "49.52083000",
        "longitude": "3.32381000"
    },
    {
        "id": 41219,
        "name": "Coudekerque-Branche",
        "latitude": "51.02288000",
        "longitude": "2.39359000"
    },
    {
        "id": 41220,
        "name": "Coudekerque-Village",
        "latitude": "51.00000000",
        "longitude": "2.41667000"
    },
    {
        "id": 41221,
        "name": "Coudoux",
        "latitude": "43.55800000",
        "longitude": "5.24889000"
    },
    {
        "id": 41222,
        "name": "Coudun",
        "latitude": "49.46146000",
        "longitude": "2.81248000"
    },
    {
        "id": 41288,
        "name": "Couëron",
        "latitude": "47.21508000",
        "longitude": "-1.72171000"
    },
    {
        "id": 41223,
        "name": "Couffé",
        "latitude": "47.39120000",
        "longitude": "-1.29321000"
    },
    {
        "id": 41224,
        "name": "Coufouleux",
        "latitude": "43.81713000",
        "longitude": "1.73078000"
    },
    {
        "id": 41225,
        "name": "Couhé",
        "latitude": "46.29911000",
        "longitude": "0.18174000"
    },
    {
        "id": 41226,
        "name": "Couilly-Pont-aux-Dames",
        "latitude": "48.88473000",
        "longitude": "2.85677000"
    },
    {
        "id": 41227,
        "name": "Couiza",
        "latitude": "42.94198000",
        "longitude": "2.25453000"
    },
    {
        "id": 41228,
        "name": "Coulaines",
        "latitude": "48.02409000",
        "longitude": "0.20411000"
    },
    {
        "id": 41229,
        "name": "Coulanges-lès-Nevers",
        "latitude": "47.00509000",
        "longitude": "3.18756000"
    },
    {
        "id": 41230,
        "name": "Coulans-sur-Gée",
        "latitude": "48.02070000",
        "longitude": "0.00974000"
    },
    {
        "id": 41231,
        "name": "Coullons",
        "latitude": "47.62105000",
        "longitude": "2.49258000"
    },
    {
        "id": 41232,
        "name": "Coulogne",
        "latitude": "50.92463000",
        "longitude": "1.88137000"
    },
    {
        "id": 41233,
        "name": "Coulombiers",
        "latitude": "46.48324000",
        "longitude": "0.18494000"
    },
    {
        "id": 41234,
        "name": "Coulombs",
        "latitude": "48.65253000",
        "longitude": "1.54646000"
    },
    {
        "id": 41235,
        "name": "Coulommiers",
        "latitude": "48.81451000",
        "longitude": "3.08498000"
    },
    {
        "id": 41236,
        "name": "Coulon",
        "latitude": "46.32328000",
        "longitude": "-0.58561000"
    },
    {
        "id": 41237,
        "name": "Coulonges-sur-l’Autize",
        "latitude": "46.48333000",
        "longitude": "-0.59845000"
    },
    {
        "id": 41238,
        "name": "Coulounieix-Chamiers",
        "latitude": "45.15289000",
        "longitude": "0.68852000"
    },
    {
        "id": 41239,
        "name": "Coupvray",
        "latitude": "48.89289000",
        "longitude": "2.79670000"
    },
    {
        "id": 41240,
        "name": "Cour-Cheverny",
        "latitude": "47.51033000",
        "longitude": "1.45583000"
    },
    {
        "id": 41241,
        "name": "Courbevoie",
        "latitude": "48.89672000",
        "longitude": "2.25666000"
    },
    {
        "id": 41242,
        "name": "Courcelles-Chaussy",
        "latitude": "49.10940000",
        "longitude": "6.40153000"
    },
    {
        "id": 41243,
        "name": "Courcelles-lès-Lens",
        "latitude": "50.41667000",
        "longitude": "3.01667000"
    },
    {
        "id": 41244,
        "name": "Courcelles-lès-Montbéliard",
        "latitude": "47.50113000",
        "longitude": "6.78461000"
    },
    {
        "id": 41245,
        "name": "Courcelles-sur-Seine",
        "latitude": "49.18285000",
        "longitude": "1.36008000"
    },
    {
        "id": 41246,
        "name": "Courchelettes",
        "latitude": "50.34043000",
        "longitude": "3.05938000"
    },
    {
        "id": 41247,
        "name": "Courchevel",
        "latitude": "45.41538000",
        "longitude": "6.63643000"
    },
    {
        "id": 41248,
        "name": "Courcité",
        "latitude": "48.30633000",
        "longitude": "-0.24961000"
    },
    {
        "id": 41274,
        "name": "Courçon",
        "latitude": "46.24389000",
        "longitude": "-0.81300000"
    },
    {
        "id": 41249,
        "name": "Courcouronnes",
        "latitude": "48.61429000",
        "longitude": "2.40762000"
    },
    {
        "id": 41250,
        "name": "Courcy",
        "latitude": "49.32361000",
        "longitude": "4.00257000"
    },
    {
        "id": 41251,
        "name": "Courdimanche",
        "latitude": "49.03513000",
        "longitude": "2.00096000"
    },
    {
        "id": 41252,
        "name": "Courlay",
        "latitude": "46.77941000",
        "longitude": "-0.56607000"
    },
    {
        "id": 41253,
        "name": "Courlon-sur-Yonne",
        "latitude": "48.33927000",
        "longitude": "3.16660000"
    },
    {
        "id": 41254,
        "name": "Courmelles",
        "latitude": "49.34595000",
        "longitude": "3.31215000"
    },
    {
        "id": 41255,
        "name": "Cournon-d’Auvergne",
        "latitude": "45.74123000",
        "longitude": "3.19643000"
    },
    {
        "id": 41256,
        "name": "Cournonsec",
        "latitude": "43.54944000",
        "longitude": "3.70556000"
    },
    {
        "id": 41257,
        "name": "Cournonterral",
        "latitude": "43.55889000",
        "longitude": "3.72000000"
    },
    {
        "id": 41258,
        "name": "Courpalay",
        "latitude": "48.64947000",
        "longitude": "2.96116000"
    },
    {
        "id": 41259,
        "name": "Courpière",
        "latitude": "45.75689000",
        "longitude": "3.54216000"
    },
    {
        "id": 41260,
        "name": "Courrières",
        "latitude": "50.45701000",
        "longitude": "2.94725000"
    },
    {
        "id": 41261,
        "name": "Cours-de-Pile",
        "latitude": "44.83608000",
        "longitude": "0.54656000"
    },
    {
        "id": 41262,
        "name": "Cours-la-Ville",
        "latitude": "46.10000000",
        "longitude": "4.31667000"
    },
    {
        "id": 41263,
        "name": "Cours-les-Barres",
        "latitude": "47.02513000",
        "longitude": "3.03167000"
    },
    {
        "id": 41264,
        "name": "Coursac",
        "latitude": "45.12881000",
        "longitude": "0.63919000"
    },
    {
        "id": 41265,
        "name": "Coursan",
        "latitude": "43.23370000",
        "longitude": "3.05712000"
    },
    {
        "id": 41266,
        "name": "Courseulles-sur-Mer",
        "latitude": "49.33027000",
        "longitude": "-0.45612000"
    },
    {
        "id": 41267,
        "name": "Courteilles",
        "latitude": "48.77495000",
        "longitude": "-0.19942000"
    },
    {
        "id": 41268,
        "name": "Courtenay",
        "latitude": "48.03901000",
        "longitude": "3.05851000"
    },
    {
        "id": 41269,
        "name": "Courthézon",
        "latitude": "44.08760000",
        "longitude": "4.88407000"
    },
    {
        "id": 41270,
        "name": "Courtisols",
        "latitude": "48.98670000",
        "longitude": "4.51700000"
    },
    {
        "id": 41271,
        "name": "Courtry",
        "latitude": "48.91906000",
        "longitude": "2.60431000"
    },
    {
        "id": 41272,
        "name": "Courville-sur-Eure",
        "latitude": "48.44899000",
        "longitude": "1.24085000"
    },
    {
        "id": 41273,
        "name": "Courzieu",
        "latitude": "45.74270000",
        "longitude": "4.57084000"
    },
    {
        "id": 41275,
        "name": "Cousance",
        "latitude": "46.53324000",
        "longitude": "5.39214000"
    },
    {
        "id": 41276,
        "name": "Cousolre",
        "latitude": "50.24607000",
        "longitude": "4.14941000"
    },
    {
        "id": 41277,
        "name": "Coussac-Bonneval",
        "latitude": "45.51199000",
        "longitude": "1.32261000"
    },
    {
        "id": 41278,
        "name": "Coutances",
        "latitude": "49.04541000",
        "longitude": "-1.44518000"
    },
    {
        "id": 41279,
        "name": "Couterne",
        "latitude": "48.51318000",
        "longitude": "-0.41538000"
    },
    {
        "id": 41280,
        "name": "Couternon",
        "latitude": "47.33333000",
        "longitude": "5.15000000"
    },
    {
        "id": 41281,
        "name": "Coutiches",
        "latitude": "50.45520000",
        "longitude": "3.20384000"
    },
    {
        "id": 41282,
        "name": "Coutouvre",
        "latitude": "46.07303000",
        "longitude": "4.20535000"
    },
    {
        "id": 41283,
        "name": "Coutras",
        "latitude": "45.03333000",
        "longitude": "-0.13333000"
    },
    {
        "id": 41284,
        "name": "Couvron-et-Aumencourt",
        "latitude": "49.64476000",
        "longitude": "3.51857000"
    },
    {
        "id": 41285,
        "name": "Coux",
        "latitude": "44.73484000",
        "longitude": "4.62057000"
    },
    {
        "id": 41286,
        "name": "Couzeix",
        "latitude": "45.87047000",
        "longitude": "1.23828000"
    },
    {
        "id": 41287,
        "name": "Couzon-au-Mont-d’Or",
        "latitude": "45.84436000",
        "longitude": "4.82883000"
    },
    {
        "id": 41289,
        "name": "Coye-la-Forêt",
        "latitude": "49.14233000",
        "longitude": "2.47038000"
    },
    {
        "id": 41290,
        "name": "Cozes",
        "latitude": "45.58375000",
        "longitude": "-0.83178000"
    },
    {
        "id": 41294,
        "name": "Crach",
        "latitude": "47.61700000",
        "longitude": "-3.00170000"
    },
    {
        "id": 41295,
        "name": "Cran-Gevrier",
        "latitude": "45.90000000",
        "longitude": "6.10000000"
    },
    {
        "id": 41296,
        "name": "Cransac",
        "latitude": "44.52411000",
        "longitude": "2.28370000"
    },
    {
        "id": 41297,
        "name": "Cranves-Sales",
        "latitude": "46.18799000",
        "longitude": "6.29903000"
    },
    {
        "id": 41298,
        "name": "Craon",
        "latitude": "47.84687000",
        "longitude": "-0.94929000"
    },
    {
        "id": 41299,
        "name": "Craponne",
        "latitude": "45.74526000",
        "longitude": "4.72322000"
    },
    {
        "id": 41300,
        "name": "Craponne-sur-Arzon",
        "latitude": "45.33092000",
        "longitude": "3.84817000"
    },
    {
        "id": 41301,
        "name": "Cravanche",
        "latitude": "47.65346000",
        "longitude": "6.83197000"
    },
    {
        "id": 41340,
        "name": "Créances",
        "latitude": "49.19873000",
        "longitude": "-1.56938000"
    },
    {
        "id": 41353,
        "name": "Crêches-sur-Saône",
        "latitude": "46.24475000",
        "longitude": "4.78678000"
    },
    {
        "id": 41341,
        "name": "Crécy-en-Ponthieu",
        "latitude": "50.25193000",
        "longitude": "1.88085000"
    },
    {
        "id": 41342,
        "name": "Crécy-sur-Serre",
        "latitude": "49.69594000",
        "longitude": "3.62453000"
    },
    {
        "id": 41343,
        "name": "Crédin",
        "latitude": "48.03462000",
        "longitude": "-2.76630000"
    },
    {
        "id": 41344,
        "name": "Crégy-lès-Meaux",
        "latitude": "48.97648000",
        "longitude": "2.87483000"
    },
    {
        "id": 41345,
        "name": "Créhange",
        "latitude": "49.05897000",
        "longitude": "6.58178000"
    },
    {
        "id": 41346,
        "name": "Créhen",
        "latitude": "48.54546000",
        "longitude": "-2.21307000"
    },
    {
        "id": 41302,
        "name": "Creil",
        "latitude": "49.25672000",
        "longitude": "2.48477000"
    },
    {
        "id": 41303,
        "name": "Creissan",
        "latitude": "43.37524000",
        "longitude": "3.01196000"
    },
    {
        "id": 41304,
        "name": "Creissels",
        "latitude": "44.08588000",
        "longitude": "3.06071000"
    },
    {
        "id": 41347,
        "name": "Crémieu",
        "latitude": "45.72557000",
        "longitude": "5.24911000"
    },
    {
        "id": 41305,
        "name": "Creney-près-Troyes",
        "latitude": "48.33333000",
        "longitude": "4.13333000"
    },
    {
        "id": 41348,
        "name": "Créon",
        "latitude": "44.77457000",
        "longitude": "-0.34800000"
    },
    {
        "id": 41349,
        "name": "Crépy",
        "latitude": "49.60000000",
        "longitude": "3.51667000"
    },
    {
        "id": 41350,
        "name": "Crépy-en-Valois",
        "latitude": "49.23359000",
        "longitude": "2.88807000"
    },
    {
        "id": 41307,
        "name": "Crespières",
        "latitude": "48.88317000",
        "longitude": "1.92151000"
    },
    {
        "id": 41306,
        "name": "Crespin",
        "latitude": "50.42110000",
        "longitude": "3.66137000"
    },
    {
        "id": 41308,
        "name": "Cresserons",
        "latitude": "49.28701000",
        "longitude": "-0.35569000"
    },
    {
        "id": 41309,
        "name": "Crest",
        "latitude": "44.72639000",
        "longitude": "5.01517000"
    },
    {
        "id": 41351,
        "name": "Créteil",
        "latitude": "48.79266000",
        "longitude": "2.46569000"
    },
    {
        "id": 41310,
        "name": "Creully",
        "latitude": "49.28478000",
        "longitude": "-0.53976000"
    },
    {
        "id": 41311,
        "name": "Creuse",
        "latitude": "46.07523000",
        "longitude": "2.05476000"
    },
    {
        "id": 41312,
        "name": "Creutzwald",
        "latitude": "49.20531000",
        "longitude": "6.69668000"
    },
    {
        "id": 41313,
        "name": "Creuzier-le-Vieux",
        "latitude": "46.16253000",
        "longitude": "3.43311000"
    },
    {
        "id": 41339,
        "name": "Crèvecœur-le-Grand",
        "latitude": "49.60000000",
        "longitude": "2.08333000"
    },
    {
        "id": 41314,
        "name": "Crevin",
        "latitude": "47.93333000",
        "longitude": "-1.66667000"
    },
    {
        "id": 41315,
        "name": "Creys-Mépieu",
        "latitude": "45.73333000",
        "longitude": "5.48333000"
    },
    {
        "id": 41316,
        "name": "Creysse",
        "latitude": "44.85474000",
        "longitude": "0.56583000"
    },
    {
        "id": 41352,
        "name": "Crézancy",
        "latitude": "49.04833000",
        "longitude": "3.51047000"
    },
    {
        "id": 41317,
        "name": "Criel-sur-Mer",
        "latitude": "50.01508000",
        "longitude": "1.31459000"
    },
    {
        "id": 41318,
        "name": "Criquebeuf-sur-Seine",
        "latitude": "49.30563000",
        "longitude": "1.09964000"
    },
    {
        "id": 41319,
        "name": "Criquetot-l’Esneval",
        "latitude": "49.64555000",
        "longitude": "0.26571000"
    },
    {
        "id": 41320,
        "name": "Crisolles",
        "latitude": "49.62250000",
        "longitude": "3.01451000"
    },
    {
        "id": 41321,
        "name": "Crissey",
        "latitude": "46.81652000",
        "longitude": "4.88185000"
    },
    {
        "id": 41322,
        "name": "Croisilles",
        "latitude": "50.19978000",
        "longitude": "2.87935000"
    },
    {
        "id": 41323,
        "name": "Croissy-Beaubourg",
        "latitude": "48.82830000",
        "longitude": "2.66964000"
    },
    {
        "id": 41324,
        "name": "Croissy-sur-Seine",
        "latitude": "48.87925000",
        "longitude": "2.13836000"
    },
    {
        "id": 41325,
        "name": "Croix",
        "latitude": "50.67846000",
        "longitude": "3.14930000"
    },
    {
        "id": 41326,
        "name": "Crolles",
        "latitude": "45.27724000",
        "longitude": "5.87837000"
    },
    {
        "id": 41327,
        "name": "Crosne",
        "latitude": "48.71921000",
        "longitude": "2.45728000"
    },
    {
        "id": 41328,
        "name": "Crossac",
        "latitude": "47.41119000",
        "longitude": "-2.16952000"
    },
    {
        "id": 41329,
        "name": "Croth",
        "latitude": "48.84557000",
        "longitude": "1.37861000"
    },
    {
        "id": 41330,
        "name": "Crottet",
        "latitude": "46.27602000",
        "longitude": "4.89388000"
    },
    {
        "id": 41331,
        "name": "Crouy",
        "latitude": "49.40274000",
        "longitude": "3.35834000"
    },
    {
        "id": 41332,
        "name": "Crouy-en-Thelle",
        "latitude": "49.21487000",
        "longitude": "2.32146000"
    },
    {
        "id": 41333,
        "name": "Crouy-sur-Ourcq",
        "latitude": "49.08980000",
        "longitude": "3.07530000"
    },
    {
        "id": 41334,
        "name": "Crozon",
        "latitude": "48.24643000",
        "longitude": "-4.48993000"
    },
    {
        "id": 41335,
        "name": "Cruas",
        "latitude": "44.65706000",
        "longitude": "4.76293000"
    },
    {
        "id": 41336,
        "name": "Cruet",
        "latitude": "45.52890000",
        "longitude": "6.09220000"
    },
    {
        "id": 41337,
        "name": "Cruseilles",
        "latitude": "46.02980000",
        "longitude": "6.10831000"
    },
    {
        "id": 41338,
        "name": "Crusnes",
        "latitude": "49.43406000",
        "longitude": "5.91557000"
    },
    {
        "id": 41354,
        "name": "Cublac",
        "latitude": "45.14488000",
        "longitude": "1.30609000"
    },
    {
        "id": 41355,
        "name": "Cublize",
        "latitude": "46.01810000",
        "longitude": "4.37738000"
    },
    {
        "id": 41356,
        "name": "Cubzac-les-Ponts",
        "latitude": "44.97119000",
        "longitude": "-0.44976000"
    },
    {
        "id": 41357,
        "name": "Cucq",
        "latitude": "50.47733000",
        "longitude": "1.62018000"
    },
    {
        "id": 41358,
        "name": "Cucuron",
        "latitude": "43.77298000",
        "longitude": "5.43858000"
    },
    {
        "id": 41359,
        "name": "Cuers",
        "latitude": "43.23754000",
        "longitude": "6.07178000"
    },
    {
        "id": 41360,
        "name": "Cuffies",
        "latitude": "49.40358000",
        "longitude": "3.32119000"
    },
    {
        "id": 41361,
        "name": "Cuffy",
        "latitude": "46.96275000",
        "longitude": "3.05238000"
    },
    {
        "id": 41362,
        "name": "Cugand",
        "latitude": "47.06318000",
        "longitude": "-1.25270000"
    },
    {
        "id": 41363,
        "name": "Cuges-les-Pins",
        "latitude": "43.27607000",
        "longitude": "5.69955000"
    },
    {
        "id": 41364,
        "name": "Cugnaux",
        "latitude": "43.53635000",
        "longitude": "1.34428000"
    },
    {
        "id": 41365,
        "name": "Cuinchy",
        "latitude": "50.51750000",
        "longitude": "2.74880000"
    },
    {
        "id": 41366,
        "name": "Cuincy",
        "latitude": "50.37933000",
        "longitude": "3.05301000"
    },
    {
        "id": 41367,
        "name": "Cuise-la-Motte",
        "latitude": "49.38642000",
        "longitude": "3.00588000"
    },
    {
        "id": 41368,
        "name": "Cuiseaux",
        "latitude": "46.49473000",
        "longitude": "5.38931000"
    },
    {
        "id": 41369,
        "name": "Cuisery",
        "latitude": "46.55696000",
        "longitude": "4.99797000"
    },
    {
        "id": 41370,
        "name": "Cuisiat",
        "latitude": "46.30021000",
        "longitude": "5.38809000"
    },
    {
        "id": 41371,
        "name": "Culhat",
        "latitude": "45.86259000",
        "longitude": "3.33676000"
    },
    {
        "id": 41372,
        "name": "Culoz",
        "latitude": "45.84886000",
        "longitude": "5.78537000"
    },
    {
        "id": 41373,
        "name": "Cunac",
        "latitude": "43.93010000",
        "longitude": "2.21878000"
    },
    {
        "id": 41374,
        "name": "Cunlhat",
        "latitude": "45.63198000",
        "longitude": "3.55927000"
    },
    {
        "id": 41375,
        "name": "Curgies",
        "latitude": "50.32975000",
        "longitude": "3.60299000"
    },
    {
        "id": 41376,
        "name": "Curgy",
        "latitude": "46.98714000",
        "longitude": "4.38452000"
    },
    {
        "id": 41377,
        "name": "Curtin",
        "latitude": "45.64353000",
        "longitude": "5.48982000"
    },
    {
        "id": 41378,
        "name": "Cussac",
        "latitude": "45.70666000",
        "longitude": "0.85124000"
    },
    {
        "id": 41379,
        "name": "Cussac-Fort-Médoc",
        "latitude": "45.11667000",
        "longitude": "-0.73333000"
    },
    {
        "id": 41380,
        "name": "Cussac-sur-Loire",
        "latitude": "44.98807000",
        "longitude": "3.88416000"
    },
    {
        "id": 41381,
        "name": "Cusset",
        "latitude": "46.13452000",
        "longitude": "3.45639000"
    },
    {
        "id": 41382,
        "name": "Custines",
        "latitude": "48.79127000",
        "longitude": "6.14461000"
    },
    {
        "id": 41383,
        "name": "Cusy",
        "latitude": "45.76603000",
        "longitude": "6.02825000"
    },
    {
        "id": 41384,
        "name": "Cuttoli-Corticchiato",
        "latitude": "41.98333000",
        "longitude": "8.91667000"
    },
    {
        "id": 41385,
        "name": "Cuverville",
        "latitude": "49.18998000",
        "longitude": "-0.26474000"
    },
    {
        "id": 41386,
        "name": "Cuxac-Cabardès",
        "latitude": "43.37087000",
        "longitude": "2.28369000"
    },
    {
        "id": 41387,
        "name": "Cuzieu",
        "latitude": "45.60764000",
        "longitude": "4.25761000"
    },
    {
        "id": 41388,
        "name": "Cysoing",
        "latitude": "50.56939000",
        "longitude": "3.21627000"
    },
    {
        "id": 41401,
        "name": "Dabo",
        "latitude": "48.65373000",
        "longitude": "7.23611000"
    },
    {
        "id": 41402,
        "name": "Dachstein",
        "latitude": "48.56127000",
        "longitude": "7.53233000"
    },
    {
        "id": 41403,
        "name": "Dadonville",
        "latitude": "48.15776000",
        "longitude": "2.27150000"
    },
    {
        "id": 41404,
        "name": "Dagneux",
        "latitude": "45.85140000",
        "longitude": "5.07780000"
    },
    {
        "id": 41405,
        "name": "Dainville",
        "latitude": "50.28097000",
        "longitude": "2.72603000"
    },
    {
        "id": 41406,
        "name": "Daix",
        "latitude": "47.35081000",
        "longitude": "5.00052000"
    },
    {
        "id": 41407,
        "name": "Dallet",
        "latitude": "45.77047000",
        "longitude": "3.23875000"
    },
    {
        "id": 41408,
        "name": "Damazan",
        "latitude": "44.29068000",
        "longitude": "0.27694000"
    },
    {
        "id": 41409,
        "name": "Dambach-la-Ville",
        "latitude": "48.32379000",
        "longitude": "7.42547000"
    },
    {
        "id": 41410,
        "name": "Damelevières",
        "latitude": "48.55930000",
        "longitude": "6.38453000"
    },
    {
        "id": 41411,
        "name": "Damery",
        "latitude": "49.07238000",
        "longitude": "3.88036000"
    },
    {
        "id": 41412,
        "name": "Damgan",
        "latitude": "47.51799000",
        "longitude": "-2.57698000"
    },
    {
        "id": 41413,
        "name": "Damigny",
        "latitude": "48.45000000",
        "longitude": "0.06667000"
    },
    {
        "id": 41414,
        "name": "Dammarie",
        "latitude": "48.34314000",
        "longitude": "1.49444000"
    },
    {
        "id": 41415,
        "name": "Dammarie-les-Lys",
        "latitude": "48.51667000",
        "longitude": "2.65000000"
    },
    {
        "id": 41416,
        "name": "Dammartin-en-Goële",
        "latitude": "49.05423000",
        "longitude": "2.67777000"
    },
    {
        "id": 41417,
        "name": "Damparis",
        "latitude": "47.07556000",
        "longitude": "5.41398000"
    },
    {
        "id": 41418,
        "name": "Dampierre",
        "latitude": "47.15498000",
        "longitude": "5.74167000"
    },
    {
        "id": 41419,
        "name": "Dampierre-en-Burly",
        "latitude": "47.76143000",
        "longitude": "2.51962000"
    },
    {
        "id": 41420,
        "name": "Dampierre-en-Yvelines",
        "latitude": "48.70000000",
        "longitude": "1.98333000"
    },
    {
        "id": 41421,
        "name": "Dampierre-les-Bois",
        "latitude": "47.50639000",
        "longitude": "6.91279000"
    },
    {
        "id": 41422,
        "name": "Dampierre-sur-Salon",
        "latitude": "47.55719000",
        "longitude": "5.67970000"
    },
    {
        "id": 41423,
        "name": "Dampmart",
        "latitude": "48.88854000",
        "longitude": "2.74095000"
    },
    {
        "id": 41424,
        "name": "Damprichard",
        "latitude": "47.24413000",
        "longitude": "6.88121000"
    },
    {
        "id": 41425,
        "name": "Damville",
        "latitude": "48.86930000",
        "longitude": "1.07458000"
    },
    {
        "id": 41426,
        "name": "Dangé-Saint-Romain",
        "latitude": "46.93333000",
        "longitude": "0.60000000"
    },
    {
        "id": 41427,
        "name": "Danjoutin",
        "latitude": "47.61822000",
        "longitude": "6.86204000"
    },
    {
        "id": 41428,
        "name": "Dannemarie",
        "latitude": "47.63034000",
        "longitude": "7.11903000"
    },
    {
        "id": 41429,
        "name": "Dannemarie-sur-Crête",
        "latitude": "47.20488000",
        "longitude": "5.87018000"
    },
    {
        "id": 41430,
        "name": "Dannes",
        "latitude": "50.58799000",
        "longitude": "1.61381000"
    },
    {
        "id": 41431,
        "name": "Daoulas",
        "latitude": "48.36089000",
        "longitude": "-4.25977000"
    },
    {
        "id": 41432,
        "name": "Dardilly",
        "latitude": "45.80558000",
        "longitude": "4.75319000"
    },
    {
        "id": 41433,
        "name": "Dargnies",
        "latitude": "50.04144000",
        "longitude": "1.52526000"
    },
    {
        "id": 41436,
        "name": "Darnétal",
        "latitude": "49.44533000",
        "longitude": "1.15144000"
    },
    {
        "id": 41434,
        "name": "Darney",
        "latitude": "48.08660000",
        "longitude": "6.04917000"
    },
    {
        "id": 41435,
        "name": "Darnieulles",
        "latitude": "48.19950000",
        "longitude": "6.34929000"
    },
    {
        "id": 41437,
        "name": "Darvoy",
        "latitude": "47.85839000",
        "longitude": "2.10033000"
    },
    {
        "id": 41438,
        "name": "Dasle",
        "latitude": "47.47843000",
        "longitude": "6.89728000"
    },
    {
        "id": 41439,
        "name": "Dauendorf",
        "latitude": "48.82987000",
        "longitude": "7.65532000"
    },
    {
        "id": 41440,
        "name": "Daumeray",
        "latitude": "47.70070000",
        "longitude": "-0.36119000"
    },
    {
        "id": 41441,
        "name": "Daux",
        "latitude": "43.69506000",
        "longitude": "1.26892000"
    },
    {
        "id": 41442,
        "name": "Davézieux",
        "latitude": "45.25520000",
        "longitude": "4.70744000"
    },
    {
        "id": 41443,
        "name": "Dax",
        "latitude": "43.71032000",
        "longitude": "-1.05366000"
    },
    {
        "id": 41444,
        "name": "Deauville",
        "latitude": "49.35700000",
        "longitude": "0.06995000"
    },
    {
        "id": 41445,
        "name": "Decazeville",
        "latitude": "44.56045000",
        "longitude": "2.25091000"
    },
    {
        "id": 41446,
        "name": "Dechy",
        "latitude": "50.35000000",
        "longitude": "3.11667000"
    },
    {
        "id": 41600,
        "name": "Décines-Charpieu",
        "latitude": "45.76873000",
        "longitude": "4.95883000"
    },
    {
        "id": 41447,
        "name": "Decize",
        "latitude": "46.82852000",
        "longitude": "3.46192000"
    },
    {
        "id": 41448,
        "name": "Delle",
        "latitude": "47.50797000",
        "longitude": "6.99975000"
    },
    {
        "id": 41449,
        "name": "Demigny",
        "latitude": "46.93048000",
        "longitude": "4.83381000"
    },
    {
        "id": 41601,
        "name": "Démouville",
        "latitude": "49.18001000",
        "longitude": "-0.26947000"
    },
    {
        "id": 41450,
        "name": "Denain",
        "latitude": "50.32930000",
        "longitude": "3.39430000"
    },
    {
        "id": 41453,
        "name": "Denée",
        "latitude": "47.37885000",
        "longitude": "-0.60816000"
    },
    {
        "id": 41451,
        "name": "Denguin",
        "latitude": "43.36667000",
        "longitude": "-0.50000000"
    },
    {
        "id": 41452,
        "name": "Denicé",
        "latitude": "46.00158000",
        "longitude": "4.64555000"
    },
    {
        "id": 41602,
        "name": "Déols",
        "latitude": "46.82934000",
        "longitude": "1.70428000"
    },
    {
        "id": 41603,
        "name": "Département d'Eure-et-Loir",
        "latitude": "48.50000000",
        "longitude": "1.50000000"
    },
    {
        "id": 41604,
        "name": "Département d'Ille-et-Vilaine",
        "latitude": "48.16667000",
        "longitude": "-1.50000000"
    },
    {
        "id": 41605,
        "name": "Département d'Indre-et-Loire",
        "latitude": "47.25000000",
        "longitude": "0.75000000"
    },
    {
        "id": 41608,
        "name": "Département de l'Ain",
        "latitude": "46.16667000",
        "longitude": "5.33333000"
    },
    {
        "id": 41609,
        "name": "Département de l'Aisne",
        "latitude": "49.50000000",
        "longitude": "3.50000000"
    },
    {
        "id": 41610,
        "name": "Département de l'Allier",
        "latitude": "46.50000000",
        "longitude": "3.00000000"
    },
    {
        "id": 41611,
        "name": "Département de l'Ardèche",
        "latitude": "44.66667000",
        "longitude": "4.33333000"
    },
    {
        "id": 41612,
        "name": "Département de l'Ariège",
        "latitude": "43.00000000",
        "longitude": "1.50000000"
    },
    {
        "id": 41613,
        "name": "Département de l'Aube",
        "latitude": "48.25000000",
        "longitude": "4.08333000"
    },
    {
        "id": 41614,
        "name": "Département de l'Aude",
        "latitude": "43.08333000",
        "longitude": "2.50000000"
    },
    {
        "id": 41615,
        "name": "Département de l'Aveyron",
        "latitude": "44.25000000",
        "longitude": "2.50000000"
    },
    {
        "id": 41616,
        "name": "Département de l'Essonne",
        "latitude": "48.50000000",
        "longitude": "2.25000000"
    },
    {
        "id": 41617,
        "name": "Département de l'Eure",
        "latitude": "49.16667000",
        "longitude": "1.00000000"
    },
    {
        "id": 41618,
        "name": "Département de l'Hérault",
        "latitude": "43.66667000",
        "longitude": "3.50000000"
    },
    {
        "id": 41619,
        "name": "Département de l'Indre",
        "latitude": "46.83333000",
        "longitude": "1.66667000"
    },
    {
        "id": 41620,
        "name": "Département de l'Isère",
        "latitude": "45.16667000",
        "longitude": "5.83333000"
    },
    {
        "id": 41621,
        "name": "Département de l'Oise",
        "latitude": "49.50000000",
        "longitude": "2.50000000"
    },
    {
        "id": 41622,
        "name": "Département de l'Orne",
        "latitude": "48.66667000",
        "longitude": "0.08333000"
    },
    {
        "id": 41623,
        "name": "Département de l'Yonne",
        "latitude": "47.91667000",
        "longitude": "3.75000000"
    },
    {
        "id": 41624,
        "name": "Département de la Corrèze",
        "latitude": "45.34754000",
        "longitude": "1.87319000"
    },
    {
        "id": 41625,
        "name": "Département de la Corse-du-Sud",
        "latitude": "41.91667000",
        "longitude": "9.16667000"
    },
    {
        "id": 41626,
        "name": "Département de la Côte-d'Or",
        "latitude": "47.50000000",
        "longitude": "4.83333000"
    },
    {
        "id": 41627,
        "name": "Département de la Drôme",
        "latitude": "44.69971000",
        "longitude": "5.16521000"
    },
    {
        "id": 41628,
        "name": "Département de la Haute-Corse",
        "latitude": "42.41667000",
        "longitude": "9.16667000"
    },
    {
        "id": 41629,
        "name": "Département de la Haute-Saône",
        "latitude": "47.67625000",
        "longitude": "6.10066000"
    },
    {
        "id": 41630,
        "name": "Département de la Loire",
        "latitude": "45.47169000",
        "longitude": "4.43298000"
    },
    {
        "id": 41631,
        "name": "Département de la Lozère",
        "latitude": "44.50000000",
        "longitude": "3.50000000"
    },
    {
        "id": 41632,
        "name": "Département de la Nièvre",
        "latitude": "47.11695000",
        "longitude": "3.49192000"
    },
    {
        "id": 41633,
        "name": "Département de la Vendée",
        "latitude": "46.64169000",
        "longitude": "-1.30407000"
    },
    {
        "id": 41606,
        "name": "Département de Maine-et-Loire",
        "latitude": "47.50000000",
        "longitude": "-0.33333000"
    },
    {
        "id": 41607,
        "name": "Département de Saône-et-Loire",
        "latitude": "46.63646000",
        "longitude": "4.58819000"
    },
    {
        "id": 41634,
        "name": "Département des Bouches-du-Rhône",
        "latitude": "43.52366000",
        "longitude": "5.42450000"
    },
    {
        "id": 41635,
        "name": "Département des Côtes-d’Armor",
        "latitude": "48.34295000",
        "longitude": "-2.78216000"
    },
    {
        "id": 41636,
        "name": "Département des Deux-Sèvres",
        "latitude": "46.53918000",
        "longitude": "-0.30838000"
    },
    {
        "id": 41637,
        "name": "Département des Hautes-Pyrénées",
        "latitude": "43.03686000",
        "longitude": "0.18632000"
    },
    {
        "id": 41638,
        "name": "Département des Pyrénées-Atlantiques",
        "latitude": "43.23587000",
        "longitude": "-0.81642000"
    },
    {
        "id": 41639,
        "name": "Département des Pyrénées-Orientales",
        "latitude": "42.60075000",
        "longitude": "2.58889000"
    },
    {
        "id": 41640,
        "name": "Département du Finistère",
        "latitude": "48.25224000",
        "longitude": "-3.99263000"
    },
    {
        "id": 41641,
        "name": "Département du Loir-et-Cher",
        "latitude": "47.50000000",
        "longitude": "1.50000000"
    },
    {
        "id": 41642,
        "name": "Département du Lot-et-Garonne",
        "latitude": "44.33333000",
        "longitude": "0.50000000"
    },
    {
        "id": 41643,
        "name": "Département du Puy-de-Dôme",
        "latitude": "45.70549000",
        "longitude": "3.14600000"
    },
    {
        "id": 41644,
        "name": "Département du Rhône",
        "latitude": "45.89126000",
        "longitude": "4.53039000"
    },
    {
        "id": 41645,
        "name": "Département du Tarn-et-Garonne",
        "latitude": "44.00000000",
        "longitude": "1.16667000"
    },
    {
        "id": 41646,
        "name": "Département du Val-d’Oise",
        "latitude": "49.07891000",
        "longitude": "2.17673000"
    },
    {
        "id": 41647,
        "name": "Département du Vaucluse",
        "latitude": "44.00000000",
        "longitude": "5.16667000"
    },
    {
        "id": 41454,
        "name": "Derval",
        "latitude": "47.66724000",
        "longitude": "-1.66990000"
    },
    {
        "id": 41648,
        "name": "Désaignes",
        "latitude": "44.99466000",
        "longitude": "4.51695000"
    },
    {
        "id": 41455,
        "name": "Descartes",
        "latitude": "46.96667000",
        "longitude": "0.70000000"
    },
    {
        "id": 41649,
        "name": "Désertines",
        "latitude": "46.35456000",
        "longitude": "2.61924000"
    },
    {
        "id": 41456,
        "name": "Dessenheim",
        "latitude": "47.97767000",
        "longitude": "7.48891000"
    },
    {
        "id": 41457,
        "name": "Desvres",
        "latitude": "50.66884000",
        "longitude": "1.83478000"
    },
    {
        "id": 41458,
        "name": "Dettwiller",
        "latitude": "48.75415000",
        "longitude": "7.46633000"
    },
    {
        "id": 41459,
        "name": "Deuil-la-Barre",
        "latitude": "48.97674000",
        "longitude": "2.32722000"
    },
    {
        "id": 41463,
        "name": "Deûlémont",
        "latitude": "50.73333000",
        "longitude": "2.95000000"
    },
    {
        "id": 41460,
        "name": "Devecey",
        "latitude": "47.32169000",
        "longitude": "6.01900000"
    },
    {
        "id": 41461,
        "name": "Deville",
        "latitude": "49.87893000",
        "longitude": "4.70610000"
    },
    {
        "id": 41650,
        "name": "Déville-lès-Rouen",
        "latitude": "49.46942000",
        "longitude": "1.05214000"
    },
    {
        "id": 41462,
        "name": "Deyvillers",
        "latitude": "48.20013000",
        "longitude": "6.51533000"
    },
    {
        "id": 41464,
        "name": "Dhuizon",
        "latitude": "47.58723000",
        "longitude": "1.65809000"
    },
    {
        "id": 41465,
        "name": "Didenheim",
        "latitude": "47.71866000",
        "longitude": "7.30157000"
    },
    {
        "id": 41466,
        "name": "Die",
        "latitude": "44.75360000",
        "longitude": "5.37033000"
    },
    {
        "id": 41467,
        "name": "Diebling",
        "latitude": "49.10966000",
        "longitude": "6.93974000"
    },
    {
        "id": 41468,
        "name": "Diemeringen",
        "latitude": "48.94013000",
        "longitude": "7.18839000"
    },
    {
        "id": 41499,
        "name": "Diémoz",
        "latitude": "45.59099000",
        "longitude": "5.08884000"
    },
    {
        "id": 41469,
        "name": "Dieppe",
        "latitude": "49.92160000",
        "longitude": "1.07772000"
    },
    {
        "id": 41470,
        "name": "Diesen",
        "latitude": "49.17818000",
        "longitude": "6.67798000"
    },
    {
        "id": 41471,
        "name": "Dietwiller",
        "latitude": "47.69278000",
        "longitude": "7.40300000"
    },
    {
        "id": 41472,
        "name": "Dieue-sur-Meuse",
        "latitude": "49.07200000",
        "longitude": "5.42248000"
    },
    {
        "id": 41473,
        "name": "Dieulefit",
        "latitude": "44.52563000",
        "longitude": "5.06180000"
    },
    {
        "id": 41474,
        "name": "Dieulouard",
        "latitude": "48.84137000",
        "longitude": "6.06782000"
    },
    {
        "id": 41475,
        "name": "Dieuze",
        "latitude": "48.81263000",
        "longitude": "6.71780000"
    },
    {
        "id": 41476,
        "name": "Diges",
        "latitude": "47.72862000",
        "longitude": "3.39786000"
    },
    {
        "id": 41477,
        "name": "Dignac",
        "latitude": "45.55000000",
        "longitude": "0.28333000"
    },
    {
        "id": 41478,
        "name": "Digne-les-Bains",
        "latitude": "44.09252000",
        "longitude": "6.23199000"
    },
    {
        "id": 41479,
        "name": "Digoin",
        "latitude": "46.48124000",
        "longitude": "3.97946000"
    },
    {
        "id": 41480,
        "name": "Digosville",
        "latitude": "49.63104000",
        "longitude": "-1.52627000"
    },
    {
        "id": 41481,
        "name": "Dijon",
        "latitude": "47.31667000",
        "longitude": "5.01667000"
    },
    {
        "id": 41482,
        "name": "Dinan",
        "latitude": "48.45551000",
        "longitude": "-2.05019000"
    },
    {
        "id": 41483,
        "name": "Dinard",
        "latitude": "48.63143000",
        "longitude": "-2.06144000"
    },
    {
        "id": 41488,
        "name": "Dinéault",
        "latitude": "48.21922000",
        "longitude": "-4.16411000"
    },
    {
        "id": 41486,
        "name": "Dingé",
        "latitude": "48.35702000",
        "longitude": "-1.71489000"
    },
    {
        "id": 41484,
        "name": "Dingsheim",
        "latitude": "48.63053000",
        "longitude": "7.66932000"
    },
    {
        "id": 41485,
        "name": "Dingy-Saint-Clair",
        "latitude": "45.90664000",
        "longitude": "6.22554000"
    },
    {
        "id": 41487,
        "name": "Dinsheim-sur-Bruche",
        "latitude": "48.54258000",
        "longitude": "7.42727000"
    },
    {
        "id": 41489,
        "name": "Diou",
        "latitude": "46.53432000",
        "longitude": "3.74453000"
    },
    {
        "id": 41490,
        "name": "Dirac",
        "latitude": "45.60000000",
        "longitude": "0.25000000"
    },
    {
        "id": 41491,
        "name": "Dirinon",
        "latitude": "48.39775000",
        "longitude": "-4.27017000"
    },
    {
        "id": 41492,
        "name": "Dissay",
        "latitude": "46.70007000",
        "longitude": "0.43311000"
    },
    {
        "id": 41494,
        "name": "Distré",
        "latitude": "47.22281000",
        "longitude": "-0.11071000"
    },
    {
        "id": 41493,
        "name": "Distroff",
        "latitude": "49.33295000",
        "longitude": "6.26662000"
    },
    {
        "id": 41495,
        "name": "Dives-sur-Mer",
        "latitude": "49.28176000",
        "longitude": "-0.10125000"
    },
    {
        "id": 41496,
        "name": "Divion",
        "latitude": "50.47169000",
        "longitude": "2.50546000"
    },
    {
        "id": 41497,
        "name": "Divonne-les-Bains",
        "latitude": "46.35710000",
        "longitude": "6.13494000"
    },
    {
        "id": 41498,
        "name": "Dizy",
        "latitude": "49.06667000",
        "longitude": "3.96667000"
    },
    {
        "id": 41500,
        "name": "Docelles",
        "latitude": "48.14475000",
        "longitude": "6.61289000"
    },
    {
        "id": 41501,
        "name": "Dogneville",
        "latitude": "48.22251000",
        "longitude": "6.45944000"
    },
    {
        "id": 41502,
        "name": "Doingt",
        "latitude": "49.92113000",
        "longitude": "2.96766000"
    },
    {
        "id": 41503,
        "name": "Dol-de-Bretagne",
        "latitude": "48.54765000",
        "longitude": "-1.75018000"
    },
    {
        "id": 41504,
        "name": "Dole",
        "latitude": "47.09225000",
        "longitude": "5.48966000"
    },
    {
        "id": 41505,
        "name": "Dollon",
        "latitude": "48.03879000",
        "longitude": "0.58686000"
    },
    {
        "id": 41506,
        "name": "Dolomieu",
        "latitude": "45.60540000",
        "longitude": "5.48571000"
    },
    {
        "id": 41507,
        "name": "Dolus-d'Oléron",
        "latitude": "45.91667000",
        "longitude": "-1.26667000"
    },
    {
        "id": 41508,
        "name": "Dom-le-Mesnil",
        "latitude": "49.69012000",
        "longitude": "4.80363000"
    },
    {
        "id": 41509,
        "name": "Domagné",
        "latitude": "48.07081000",
        "longitude": "-1.39291000"
    },
    {
        "id": 41510,
        "name": "Domalain",
        "latitude": "47.99617000",
        "longitude": "-1.24250000"
    },
    {
        "id": 41511,
        "name": "Domancy",
        "latitude": "45.91205000",
        "longitude": "6.65271000"
    },
    {
        "id": 41512,
        "name": "Domarin",
        "latitude": "45.58662000",
        "longitude": "5.24599000"
    },
    {
        "id": 41513,
        "name": "Domart-en-Ponthieu",
        "latitude": "50.07437000",
        "longitude": "2.12596000"
    },
    {
        "id": 41514,
        "name": "Dombasle-sur-Meurthe",
        "latitude": "48.61861000",
        "longitude": "6.35538000"
    },
    {
        "id": 41526,
        "name": "Domène",
        "latitude": "45.20289000",
        "longitude": "5.83335000"
    },
    {
        "id": 41527,
        "name": "Domérat",
        "latitude": "46.36036000",
        "longitude": "2.53455000"
    },
    {
        "id": 41515,
        "name": "Domessin",
        "latitude": "45.54739000",
        "longitude": "5.70495000"
    },
    {
        "id": 41516,
        "name": "Domfront",
        "latitude": "48.59208000",
        "longitude": "-0.64588000"
    },
    {
        "id": 41517,
        "name": "Domgermain",
        "latitude": "48.64276000",
        "longitude": "5.82957000"
    },
    {
        "id": 41518,
        "name": "Dommartin-lès-Remiremont",
        "latitude": "47.99902000",
        "longitude": "6.64048000"
    },
    {
        "id": 41519,
        "name": "Dommartin-lès-Toul",
        "latitude": "48.66949000",
        "longitude": "5.91005000"
    },
    {
        "id": 41520,
        "name": "Domme",
        "latitude": "44.80218000",
        "longitude": "1.21459000"
    },
    {
        "id": 41521,
        "name": "Domont",
        "latitude": "49.02782000",
        "longitude": "2.32638000"
    },
    {
        "id": 41522,
        "name": "Dompierre-sur-Besbre",
        "latitude": "46.52214000",
        "longitude": "3.68106000"
    },
    {
        "id": 41523,
        "name": "Dompierre-sur-Mer",
        "latitude": "46.18817000",
        "longitude": "-1.06351000"
    },
    {
        "id": 41524,
        "name": "Dompierre-sur-Veyle",
        "latitude": "46.07108000",
        "longitude": "5.20232000"
    },
    {
        "id": 41525,
        "name": "Dompierre-sur-Yon",
        "latitude": "46.73857000",
        "longitude": "-1.38463000"
    },
    {
        "id": 41528,
        "name": "Don",
        "latitude": "50.54912000",
        "longitude": "2.91817000"
    },
    {
        "id": 41529,
        "name": "Donchery",
        "latitude": "49.69584000",
        "longitude": "4.87417000"
    },
    {
        "id": 41530,
        "name": "Doncourt-lès-Conflans",
        "latitude": "49.14250000",
        "longitude": "5.93368000"
    },
    {
        "id": 41531,
        "name": "Donges",
        "latitude": "47.31824000",
        "longitude": "-2.07538000"
    },
    {
        "id": 41532,
        "name": "Donnemarie-Dontilly",
        "latitude": "48.47719000",
        "longitude": "3.13162000"
    },
    {
        "id": 41533,
        "name": "Donnery",
        "latitude": "47.91486000",
        "longitude": "2.10299000"
    },
    {
        "id": 41534,
        "name": "Donville-les-Bains",
        "latitude": "48.84608000",
        "longitude": "-1.58315000"
    },
    {
        "id": 41535,
        "name": "Donzenac",
        "latitude": "45.22731000",
        "longitude": "1.52400000"
    },
    {
        "id": 41537,
        "name": "Donzère",
        "latitude": "44.44246000",
        "longitude": "4.71134000"
    },
    {
        "id": 41536,
        "name": "Donzy",
        "latitude": "47.37066000",
        "longitude": "3.12548000"
    },
    {
        "id": 41538,
        "name": "Dordives",
        "latitude": "48.14253000",
        "longitude": "2.76775000"
    },
    {
        "id": 41539,
        "name": "Dordogne",
        "latitude": "45.12735000",
        "longitude": "0.73504000"
    },
    {
        "id": 41540,
        "name": "Dorlisheim",
        "latitude": "48.52485000",
        "longitude": "7.48624000"
    },
    {
        "id": 41541,
        "name": "Dormans",
        "latitude": "49.07392000",
        "longitude": "3.63819000"
    },
    {
        "id": 41542,
        "name": "Dornes",
        "latitude": "46.71600000",
        "longitude": "3.35343000"
    },
    {
        "id": 41543,
        "name": "Dortan",
        "latitude": "46.31973000",
        "longitude": "5.66028000"
    },
    {
        "id": 41544,
        "name": "Dossenheim-sur-Zinsel",
        "latitude": "48.80590000",
        "longitude": "7.40273000"
    },
    {
        "id": 41545,
        "name": "Douai",
        "latitude": "50.37069000",
        "longitude": "3.07922000"
    },
    {
        "id": 41546,
        "name": "Douarnenez",
        "latitude": "48.09542000",
        "longitude": "-4.32904000"
    },
    {
        "id": 41547,
        "name": "Doubs",
        "latitude": "47.19967000",
        "longitude": "6.43487000"
    },
    {
        "id": 41548,
        "name": "Douchy",
        "latitude": "47.94282000",
        "longitude": "3.05392000"
    },
    {
        "id": 41549,
        "name": "Douchy-les-Mines",
        "latitude": "50.30080000",
        "longitude": "3.39270000"
    },
    {
        "id": 41550,
        "name": "Doudeville",
        "latitude": "49.72268000",
        "longitude": "0.78479000"
    },
    {
        "id": 41551,
        "name": "Doue",
        "latitude": "48.86641000",
        "longitude": "3.16269000"
    },
    {
        "id": 41562,
        "name": "Doué-la-Fontaine",
        "latitude": "47.19372000",
        "longitude": "-0.27492000"
    },
    {
        "id": 41552,
        "name": "Doulaincourt-Saucourt",
        "latitude": "48.31667000",
        "longitude": "5.20000000"
    },
    {
        "id": 41553,
        "name": "Doullens",
        "latitude": "50.15724000",
        "longitude": "2.34019000"
    },
    {
        "id": 41554,
        "name": "Dourdan",
        "latitude": "48.52772000",
        "longitude": "2.01113000"
    },
    {
        "id": 41555,
        "name": "Dourges",
        "latitude": "50.43636000",
        "longitude": "2.98589000"
    },
    {
        "id": 41556,
        "name": "Dourgne",
        "latitude": "43.48381000",
        "longitude": "2.13989000"
    },
    {
        "id": 41557,
        "name": "Doussard",
        "latitude": "45.77512000",
        "longitude": "6.22553000"
    },
    {
        "id": 41558,
        "name": "Douvaine",
        "latitude": "46.30544000",
        "longitude": "6.30375000"
    },
    {
        "id": 41559,
        "name": "Douvres-la-Délivrande",
        "latitude": "49.29472000",
        "longitude": "-0.38039000"
    },
    {
        "id": 41560,
        "name": "Douvrin",
        "latitude": "50.50916000",
        "longitude": "2.83053000"
    },
    {
        "id": 41561,
        "name": "Douzy",
        "latitude": "49.67080000",
        "longitude": "5.04156000"
    },
    {
        "id": 41563,
        "name": "Doyet",
        "latitude": "46.33558000",
        "longitude": "2.79718000"
    },
    {
        "id": 41564,
        "name": "Dozulé",
        "latitude": "49.23132000",
        "longitude": "-0.04454000"
    },
    {
        "id": 41565,
        "name": "Dracy-le-Fort",
        "latitude": "46.79750000",
        "longitude": "4.76215000"
    },
    {
        "id": 41566,
        "name": "Draguignan",
        "latitude": "43.53692000",
        "longitude": "6.46458000"
    },
    {
        "id": 41567,
        "name": "Drain",
        "latitude": "47.33655000",
        "longitude": "-1.20773000"
    },
    {
        "id": 41568,
        "name": "Drancy",
        "latitude": "48.92578000",
        "longitude": "2.44533000"
    },
    {
        "id": 41569,
        "name": "Drap",
        "latitude": "43.75508000",
        "longitude": "7.32152000"
    },
    {
        "id": 41570,
        "name": "Draveil",
        "latitude": "48.68466000",
        "longitude": "2.41418000"
    },
    {
        "id": 41571,
        "name": "Drefféac",
        "latitude": "47.47464000",
        "longitude": "-2.05774000"
    },
    {
        "id": 41583,
        "name": "Drémil-Lafage",
        "latitude": "43.59624000",
        "longitude": "1.60117000"
    },
    {
        "id": 41572,
        "name": "Dreslincourt",
        "latitude": "49.52809000",
        "longitude": "2.92699000"
    },
    {
        "id": 41573,
        "name": "Dreuil-lès-Amiens",
        "latitude": "49.91483000",
        "longitude": "2.22893000"
    },
    {
        "id": 41574,
        "name": "Dreux",
        "latitude": "48.73649000",
        "longitude": "1.36566000"
    },
    {
        "id": 41575,
        "name": "Drocourt",
        "latitude": "50.39002000",
        "longitude": "2.92425000"
    },
    {
        "id": 41577,
        "name": "Droué",
        "latitude": "48.04023000",
        "longitude": "1.07534000"
    },
    {
        "id": 41576,
        "name": "Droue-sur-Drouette",
        "latitude": "48.60053000",
        "longitude": "1.70113000"
    },
    {
        "id": 41578,
        "name": "Druelle",
        "latitude": "44.36006000",
        "longitude": "2.50505000"
    },
    {
        "id": 41579,
        "name": "Drulingen",
        "latitude": "48.86804000",
        "longitude": "7.18956000"
    },
    {
        "id": 41580,
        "name": "Drumettaz",
        "latitude": "45.66136000",
        "longitude": "5.92191000"
    },
    {
        "id": 41581,
        "name": "Drusenheim",
        "latitude": "48.76207000",
        "longitude": "7.95326000"
    },
    {
        "id": 41582,
        "name": "Dry",
        "latitude": "47.79612000",
        "longitude": "1.71330000"
    },
    {
        "id": 41584,
        "name": "Ducey",
        "latitude": "48.61843000",
        "longitude": "-1.29052000"
    },
    {
        "id": 41585,
        "name": "Duclair",
        "latitude": "49.48385000",
        "longitude": "0.87617000"
    },
    {
        "id": 41586,
        "name": "Dugny",
        "latitude": "48.95371000",
        "longitude": "2.41734000"
    },
    {
        "id": 41587,
        "name": "Dugny-sur-Meuse",
        "latitude": "49.10953000",
        "longitude": "5.38550000"
    },
    {
        "id": 41588,
        "name": "Duisans",
        "latitude": "50.30633000",
        "longitude": "2.68653000"
    },
    {
        "id": 41589,
        "name": "Dun-le-Palestel",
        "latitude": "46.30000000",
        "longitude": "1.66667000"
    },
    {
        "id": 41590,
        "name": "Dun-sur-Auron",
        "latitude": "46.88490000",
        "longitude": "2.57345000"
    },
    {
        "id": 41591,
        "name": "Dunières",
        "latitude": "45.21558000",
        "longitude": "4.34471000"
    },
    {
        "id": 41592,
        "name": "Dunkerque",
        "latitude": "51.03297000",
        "longitude": "2.37700000"
    },
    {
        "id": 41593,
        "name": "Duppigheim",
        "latitude": "48.52866000",
        "longitude": "7.59421000"
    },
    {
        "id": 41594,
        "name": "Duras",
        "latitude": "44.67618000",
        "longitude": "0.18247000"
    },
    {
        "id": 41595,
        "name": "Durrenbach",
        "latitude": "48.89681000",
        "longitude": "7.76769000"
    },
    {
        "id": 41596,
        "name": "Durtal",
        "latitude": "47.67247000",
        "longitude": "-0.23393000"
    },
    {
        "id": 41597,
        "name": "Durtol",
        "latitude": "45.79621000",
        "longitude": "3.05156000"
    },
    {
        "id": 41598,
        "name": "Dury",
        "latitude": "49.84731000",
        "longitude": "2.27070000"
    },
    {
        "id": 41599,
        "name": "Duttlenheim",
        "latitude": "48.52553000",
        "longitude": "7.56572000"
    },
    {
        "id": 41651,
        "name": "Eaubonne",
        "latitude": "48.99712000",
        "longitude": "2.28249000"
    },
    {
        "id": 41652,
        "name": "Eaunes",
        "latitude": "43.42079000",
        "longitude": "1.35397000"
    },
    {
        "id": 41653,
        "name": "Eauze",
        "latitude": "43.86055000",
        "longitude": "0.10199000"
    },
    {
        "id": 41654,
        "name": "Ebersheim",
        "latitude": "48.30438000",
        "longitude": "7.49903000"
    },
    {
        "id": 48036,
        "name": "Ébreuil",
        "latitude": "46.11548000",
        "longitude": "3.08677000"
    },
    {
        "id": 48037,
        "name": "Écaillon",
        "latitude": "50.35034000",
        "longitude": "3.21664000"
    },
    {
        "id": 48038,
        "name": "Échalas",
        "latitude": "45.55203000",
        "longitude": "4.71544000"
    },
    {
        "id": 48039,
        "name": "Échenevex",
        "latitude": "46.30923000",
        "longitude": "6.03963000"
    },
    {
        "id": 48040,
        "name": "Échenoz-la-Méline",
        "latitude": "47.60086000",
        "longitude": "6.13544000"
    },
    {
        "id": 48041,
        "name": "Échillais",
        "latitude": "45.90072000",
        "longitude": "-0.95211000"
    },
    {
        "id": 48043,
        "name": "Échiré",
        "latitude": "46.38748000",
        "longitude": "-0.41511000"
    },
    {
        "id": 48042,
        "name": "Échirolles",
        "latitude": "45.14603000",
        "longitude": "5.71441000"
    },
    {
        "id": 41655,
        "name": "Eckbolsheim",
        "latitude": "48.58075000",
        "longitude": "7.68768000"
    },
    {
        "id": 41656,
        "name": "Eckwersheim",
        "latitude": "48.68167000",
        "longitude": "7.69687000"
    },
    {
        "id": 48044,
        "name": "École-Valentin",
        "latitude": "47.26667000",
        "longitude": "5.98333000"
    },
    {
        "id": 48045,
        "name": "Écommoy",
        "latitude": "47.82565000",
        "longitude": "0.27422000"
    },
    {
        "id": 48046,
        "name": "Écouché",
        "latitude": "48.71751000",
        "longitude": "-0.12409000"
    },
    {
        "id": 48047,
        "name": "Écouen",
        "latitude": "49.02062000",
        "longitude": "2.38309000"
    },
    {
        "id": 48048,
        "name": "Écouflant",
        "latitude": "47.53101000",
        "longitude": "-0.52780000"
    },
    {
        "id": 48049,
        "name": "Écourt-Saint-Quentin",
        "latitude": "50.25226000",
        "longitude": "3.07331000"
    },
    {
        "id": 48050,
        "name": "Écoyeux",
        "latitude": "45.82231000",
        "longitude": "-0.50539000"
    },
    {
        "id": 41657,
        "name": "Ecques",
        "latitude": "50.66998000",
        "longitude": "2.28633000"
    },
    {
        "id": 41658,
        "name": "Ecquevilly",
        "latitude": "48.95192000",
        "longitude": "1.92338000"
    },
    {
        "id": 48051,
        "name": "Écrainville",
        "latitude": "49.64943000",
        "longitude": "0.32488000"
    },
    {
        "id": 48052,
        "name": "Écrouves",
        "latitude": "48.67986000",
        "longitude": "5.84267000"
    },
    {
        "id": 48053,
        "name": "Écueillé",
        "latitude": "47.08462000",
        "longitude": "1.34668000"
    },
    {
        "id": 48054,
        "name": "Écuelles",
        "latitude": "48.35636000",
        "longitude": "2.82335000"
    },
    {
        "id": 48055,
        "name": "Écuisses",
        "latitude": "46.75631000",
        "longitude": "4.53845000"
    },
    {
        "id": 48056,
        "name": "Écully",
        "latitude": "45.77437000",
        "longitude": "4.77758000"
    },
    {
        "id": 41659,
        "name": "Edern",
        "latitude": "48.10000000",
        "longitude": "-3.98333000"
    },
    {
        "id": 48057,
        "name": "Égletons",
        "latitude": "45.40637000",
        "longitude": "2.04518000"
    },
    {
        "id": 48058,
        "name": "Égly",
        "latitude": "48.57828000",
        "longitude": "2.22417000"
    },
    {
        "id": 48059,
        "name": "Égreville",
        "latitude": "48.17606000",
        "longitude": "2.87278000"
    },
    {
        "id": 48060,
        "name": "Égriselles-le-Bocage",
        "latitude": "48.12103000",
        "longitude": "3.18233000"
    },
    {
        "id": 48061,
        "name": "Éguilles",
        "latitude": "43.56867000",
        "longitude": "5.35575000"
    },
    {
        "id": 41660,
        "name": "Eguisheim",
        "latitude": "48.04280000",
        "longitude": "7.30617000"
    },
    {
        "id": 48062,
        "name": "Éguzon-Chantôme",
        "latitude": "46.45000000",
        "longitude": "1.58333000"
    },
    {
        "id": 41661,
        "name": "Einville-au-Jard",
        "latitude": "48.65569000",
        "longitude": "6.48447000"
    },
    {
        "id": 48031,
        "name": "el Voló",
        "latitude": "42.52424000",
        "longitude": "2.83336000"
    },
    {
        "id": 48063,
        "name": "Élancourt",
        "latitude": "48.78421000",
        "longitude": "1.95520000"
    },
    {
        "id": 41662,
        "name": "Elbeuf",
        "latitude": "49.28669000",
        "longitude": "1.00288000"
    },
    {
        "id": 48064,
        "name": "Éleu-dit-Leauwette",
        "latitude": "50.42147000",
        "longitude": "2.81079000"
    },
    {
        "id": 41663,
        "name": "Elliant",
        "latitude": "47.99417000",
        "longitude": "-3.88966000"
    },
    {
        "id": 41664,
        "name": "Elne",
        "latitude": "42.60031000",
        "longitude": "2.97146000"
    },
    {
        "id": 48065,
        "name": "Éloyes",
        "latitude": "48.09793000",
        "longitude": "6.60653000"
    },
    {
        "id": 48032,
        "name": "els Banys d'Arles",
        "latitude": "42.47289000",
        "longitude": "2.66916000"
    },
    {
        "id": 41665,
        "name": "Elven",
        "latitude": "47.73230000",
        "longitude": "-2.58956000"
    },
    {
        "id": 41666,
        "name": "Embrun",
        "latitude": "44.56387000",
        "longitude": "6.49526000"
    },
    {
        "id": 48066,
        "name": "Émerainville",
        "latitude": "48.81276000",
        "longitude": "2.62139000"
    },
    {
        "id": 48067,
        "name": "Émerchicourt",
        "latitude": "50.30786000",
        "longitude": "3.24537000"
    },
    {
        "id": 41667,
        "name": "Emmerin",
        "latitude": "50.59475000",
        "longitude": "3.00124000"
    },
    {
        "id": 41668,
        "name": "Enchenberg",
        "latitude": "49.01354000",
        "longitude": "7.33868000"
    },
    {
        "id": 41669,
        "name": "Enghien-les-Bains",
        "latitude": "48.96667000",
        "longitude": "2.31667000"
    },
    {
        "id": 41670,
        "name": "Englefontaine",
        "latitude": "50.19091000",
        "longitude": "3.64401000"
    },
    {
        "id": 41671,
        "name": "Ennery",
        "latitude": "49.22619000",
        "longitude": "6.21778000"
    },
    {
        "id": 41672,
        "name": "Ennery",
        "latitude": "49.07505000",
        "longitude": "2.10599000"
    },
    {
        "id": 41673,
        "name": "Ennetières-en-Weppes",
        "latitude": "50.63530000",
        "longitude": "2.94012000"
    },
    {
        "id": 41674,
        "name": "Ennevelin",
        "latitude": "50.54121000",
        "longitude": "3.12972000"
    },
    {
        "id": 41675,
        "name": "Ennezat",
        "latitude": "45.89772000",
        "longitude": "3.22348000"
    },
    {
        "id": 41676,
        "name": "Ensisheim",
        "latitude": "47.86584000",
        "longitude": "7.35052000"
    },
    {
        "id": 41677,
        "name": "Ensuès-la-Redonne",
        "latitude": "43.35464000",
        "longitude": "5.20357000"
    },
    {
        "id": 41678,
        "name": "Entraigues-sur-la-Sorgue",
        "latitude": "44.00320000",
        "longitude": "4.92657000"
    },
    {
        "id": 41679,
        "name": "Entrammes",
        "latitude": "47.99759000",
        "longitude": "-0.71399000"
    },
    {
        "id": 41680,
        "name": "Entrange",
        "latitude": "49.41300000",
        "longitude": "6.10501000"
    },
    {
        "id": 41681,
        "name": "Entraygues-sur-Truyère",
        "latitude": "44.64606000",
        "longitude": "2.56745000"
    },
    {
        "id": 41682,
        "name": "Entre-Deux-Guiers",
        "latitude": "45.43061000",
        "longitude": "5.75209000"
    },
    {
        "id": 41683,
        "name": "Entzheim",
        "latitude": "48.53424000",
        "longitude": "7.63772000"
    },
    {
        "id": 41684,
        "name": "Enval",
        "latitude": "45.89921000",
        "longitude": "3.04981000"
    },
    {
        "id": 41685,
        "name": "Envermeu",
        "latitude": "49.89560000",
        "longitude": "1.26493000"
    },
    {
        "id": 48068,
        "name": "Éoures",
        "latitude": "43.30045000",
        "longitude": "5.52193000"
    },
    {
        "id": 48069,
        "name": "Épagny",
        "latitude": "45.93584000",
        "longitude": "6.08302000"
    },
    {
        "id": 48070,
        "name": "Épaignes",
        "latitude": "49.27945000",
        "longitude": "0.43980000"
    },
    {
        "id": 48071,
        "name": "Épehy",
        "latitude": "50.00000000",
        "longitude": "3.13333000"
    },
    {
        "id": 48072,
        "name": "Éperlecques",
        "latitude": "50.80566000",
        "longitude": "2.15207000"
    },
    {
        "id": 48073,
        "name": "Épernay",
        "latitude": "49.04000000",
        "longitude": "3.95922000"
    },
    {
        "id": 48074,
        "name": "Épernon",
        "latitude": "48.61031000",
        "longitude": "1.67218000"
    },
    {
        "id": 48075,
        "name": "Épervans",
        "latitude": "46.75247000",
        "longitude": "4.89930000"
    },
    {
        "id": 41686,
        "name": "Epfig",
        "latitude": "48.35933000",
        "longitude": "7.46427000"
    },
    {
        "id": 48076,
        "name": "Épieds-en-Beauce",
        "latitude": "47.95066000",
        "longitude": "1.61732000"
    },
    {
        "id": 48077,
        "name": "Épinac",
        "latitude": "46.98333000",
        "longitude": "4.51667000"
    },
    {
        "id": 48078,
        "name": "Épinal",
        "latitude": "48.18324000",
        "longitude": "6.45304000"
    },
    {
        "id": 48079,
        "name": "Épinay-sous-Sénart",
        "latitude": "48.68970000",
        "longitude": "2.51186000"
    },
    {
        "id": 48080,
        "name": "Épinay-sur-Orge",
        "latitude": "48.67338000",
        "longitude": "2.31074000"
    },
    {
        "id": 48081,
        "name": "Épinay-sur-Seine",
        "latitude": "48.95350000",
        "longitude": "2.31514000"
    },
    {
        "id": 41687,
        "name": "Epiniac",
        "latitude": "48.50000000",
        "longitude": "-1.70000000"
    },
    {
        "id": 48082,
        "name": "Épinouze",
        "latitude": "45.31003000",
        "longitude": "4.92936000"
    },
    {
        "id": 48085,
        "name": "Épône",
        "latitude": "48.95476000",
        "longitude": "1.82233000"
    },
    {
        "id": 48083,
        "name": "Épouville",
        "latitude": "49.56349000",
        "longitude": "0.22373000"
    },
    {
        "id": 41688,
        "name": "Eppeville",
        "latitude": "49.74069000",
        "longitude": "3.05114000"
    },
    {
        "id": 48084,
        "name": "Épron",
        "latitude": "49.22174000",
        "longitude": "-0.37085000"
    },
    {
        "id": 48086,
        "name": "Équemauville",
        "latitude": "49.39406000",
        "longitude": "0.20760000"
    },
    {
        "id": 48087,
        "name": "Équeurdreville-Hainneville",
        "latitude": "49.64868000",
        "longitude": "-1.65306000"
    },
    {
        "id": 48088,
        "name": "Équihen-Plage",
        "latitude": "50.67557000",
        "longitude": "1.57225000"
    },
    {
        "id": 48089,
        "name": "Éragny",
        "latitude": "49.01667000",
        "longitude": "2.10000000"
    },
    {
        "id": 41689,
        "name": "Erbray",
        "latitude": "47.65492000",
        "longitude": "-1.31783000"
    },
    {
        "id": 41690,
        "name": "Erbrée",
        "latitude": "48.09745000",
        "longitude": "-1.12479000"
    },
    {
        "id": 41692,
        "name": "Ercé-en-Lamée",
        "latitude": "47.83048000",
        "longitude": "-1.55867000"
    },
    {
        "id": 41693,
        "name": "Ercé-près-Liffré",
        "latitude": "48.25454000",
        "longitude": "-1.51560000"
    },
    {
        "id": 41691,
        "name": "Ercuis",
        "latitude": "49.23465000",
        "longitude": "2.30407000"
    },
    {
        "id": 41694,
        "name": "Erdeven",
        "latitude": "47.64210000",
        "longitude": "-3.15706000"
    },
    {
        "id": 41695,
        "name": "Ergué-Gabéric",
        "latitude": "47.99562000",
        "longitude": "-4.02223000"
    },
    {
        "id": 41696,
        "name": "Ermont",
        "latitude": "48.99004000",
        "longitude": "2.25804000"
    },
    {
        "id": 41698,
        "name": "Ernée",
        "latitude": "48.29764000",
        "longitude": "-0.93143000"
    },
    {
        "id": 41697,
        "name": "Ernolsheim-Bruche",
        "latitude": "48.56531000",
        "longitude": "7.56503000"
    },
    {
        "id": 41699,
        "name": "Erquinghem-Lys",
        "latitude": "50.67601000",
        "longitude": "2.84505000"
    },
    {
        "id": 41700,
        "name": "Erquy",
        "latitude": "48.63186000",
        "longitude": "-2.46280000"
    },
    {
        "id": 41701,
        "name": "Erre",
        "latitude": "50.36252000",
        "longitude": "3.31561000"
    },
    {
        "id": 41702,
        "name": "Erstein",
        "latitude": "48.42373000",
        "longitude": "7.66262000"
    },
    {
        "id": 41703,
        "name": "Ervy-le-Châtel",
        "latitude": "48.04116000",
        "longitude": "3.90988000"
    },
    {
        "id": 41704,
        "name": "Esbly",
        "latitude": "48.90520000",
        "longitude": "2.81235000"
    },
    {
        "id": 41705,
        "name": "Escalquens",
        "latitude": "43.51744000",
        "longitude": "1.55893000"
    },
    {
        "id": 41706,
        "name": "Escaudain",
        "latitude": "50.33315000",
        "longitude": "3.34430000"
    },
    {
        "id": 41707,
        "name": "Escaudœuvres",
        "latitude": "50.20000000",
        "longitude": "3.26667000"
    },
    {
        "id": 41708,
        "name": "Escautpont",
        "latitude": "50.41875000",
        "longitude": "3.55341000"
    },
    {
        "id": 41709,
        "name": "Eschau",
        "latitude": "48.48897000",
        "longitude": "7.71644000"
    },
    {
        "id": 41710,
        "name": "Eschbach",
        "latitude": "48.87465000",
        "longitude": "7.73609000"
    },
    {
        "id": 41711,
        "name": "Eschentzwiller",
        "latitude": "47.71270000",
        "longitude": "7.39773000"
    },
    {
        "id": 41712,
        "name": "Esches",
        "latitude": "49.22086000",
        "longitude": "2.16595000"
    },
    {
        "id": 41713,
        "name": "Escoutoux",
        "latitude": "45.81942000",
        "longitude": "3.56336000"
    },
    {
        "id": 41714,
        "name": "Eslettes",
        "latitude": "49.54820000",
        "longitude": "1.05515000"
    },
    {
        "id": 41715,
        "name": "Esnandes",
        "latitude": "46.25033000",
        "longitude": "-1.11566000"
    },
    {
        "id": 41716,
        "name": "Espalion",
        "latitude": "44.52237000",
        "longitude": "2.76265000"
    },
    {
        "id": 41717,
        "name": "Espaly-Saint-Marcel",
        "latitude": "45.04790000",
        "longitude": "3.86557000"
    },
    {
        "id": 41718,
        "name": "Espelette",
        "latitude": "43.34015000",
        "longitude": "-1.44737000"
    },
    {
        "id": 41719,
        "name": "Espéraza",
        "latitude": "42.93225000",
        "longitude": "2.22006000"
    },
    {
        "id": 41723,
        "name": "Esquéhéries",
        "latitude": "49.98391000",
        "longitude": "3.74743000"
    },
    {
        "id": 41720,
        "name": "Esquelbecq",
        "latitude": "50.88694000",
        "longitude": "2.43215000"
    },
    {
        "id": 41721,
        "name": "Esquerdes",
        "latitude": "50.70414000",
        "longitude": "2.18851000"
    },
    {
        "id": 41722,
        "name": "Esquibien",
        "latitude": "48.02506000",
        "longitude": "-4.56139000"
    },
    {
        "id": 41724,
        "name": "Essars",
        "latitude": "50.54868000",
        "longitude": "2.66620000"
    },
    {
        "id": 41725,
        "name": "Essert",
        "latitude": "47.63342000",
        "longitude": "6.81702000"
    },
    {
        "id": 41726,
        "name": "Essey-lès-Nancy",
        "latitude": "48.70500000",
        "longitude": "6.22691000"
    },
    {
        "id": 41727,
        "name": "Essigny-le-Grand",
        "latitude": "49.77865000",
        "longitude": "3.27774000"
    },
    {
        "id": 41728,
        "name": "Essômes-sur-Marne",
        "latitude": "49.02881000",
        "longitude": "3.37571000"
    },
    {
        "id": 41729,
        "name": "Estagel",
        "latitude": "42.77314000",
        "longitude": "2.69665000"
    },
    {
        "id": 41730,
        "name": "Estaires",
        "latitude": "50.64574000",
        "longitude": "2.72782000"
    },
    {
        "id": 41731,
        "name": "Esternay",
        "latitude": "48.73232000",
        "longitude": "3.56159000"
    },
    {
        "id": 41732,
        "name": "Estevelles",
        "latitude": "50.47653000",
        "longitude": "2.90928000"
    },
    {
        "id": 41733,
        "name": "Estillac",
        "latitude": "44.15766000",
        "longitude": "0.56383000"
    },
    {
        "id": 41734,
        "name": "Estissac",
        "latitude": "48.26920000",
        "longitude": "3.80515000"
    },
    {
        "id": 41735,
        "name": "Estivareilles",
        "latitude": "46.42526000",
        "longitude": "2.61872000"
    },
    {
        "id": 41736,
        "name": "Estrablin",
        "latitude": "45.51546000",
        "longitude": "4.96173000"
    },
    {
        "id": 41737,
        "name": "Estrées",
        "latitude": "50.30079000",
        "longitude": "3.06980000"
    },
    {
        "id": 41738,
        "name": "Estrées-Saint-Denis",
        "latitude": "49.42602000",
        "longitude": "2.64293000"
    },
    {
        "id": 41739,
        "name": "Esvres",
        "latitude": "47.28537000",
        "longitude": "0.78588000"
    },
    {
        "id": 48090,
        "name": "Étables-sur-Mer",
        "latitude": "48.63333000",
        "longitude": "-2.83333000"
    },
    {
        "id": 48091,
        "name": "Étagnac",
        "latitude": "45.89506000",
        "longitude": "0.77897000"
    },
    {
        "id": 48092,
        "name": "Étain",
        "latitude": "49.21259000",
        "longitude": "5.64022000"
    },
    {
        "id": 48093,
        "name": "Étainhus",
        "latitude": "49.56648000",
        "longitude": "0.31285000"
    },
    {
        "id": 48094,
        "name": "Étalans",
        "latitude": "47.15125000",
        "longitude": "6.27315000"
    },
    {
        "id": 48095,
        "name": "Étampes",
        "latitude": "48.43507000",
        "longitude": "2.16233000"
    },
    {
        "id": 48096,
        "name": "Étampes-sur-Marne",
        "latitude": "49.03464000",
        "longitude": "3.41893000"
    },
    {
        "id": 48097,
        "name": "Étang-sur-Arroux",
        "latitude": "46.86560000",
        "longitude": "4.18988000"
    },
    {
        "id": 48098,
        "name": "Étaples",
        "latitude": "50.52194000",
        "longitude": "1.63319000"
    },
    {
        "id": 48099,
        "name": "Étaules",
        "latitude": "45.73407000",
        "longitude": "-1.09918000"
    },
    {
        "id": 48100,
        "name": "Étauliers",
        "latitude": "45.22582000",
        "longitude": "-0.57243000"
    },
    {
        "id": 41740,
        "name": "Etaux",
        "latitude": "46.06835000",
        "longitude": "6.29533000"
    },
    {
        "id": 48101,
        "name": "Étel",
        "latitude": "47.65614000",
        "longitude": "-3.20018000"
    },
    {
        "id": 48102,
        "name": "Éterville",
        "latitude": "49.14423000",
        "longitude": "-0.42512000"
    },
    {
        "id": 48103,
        "name": "Étiolles",
        "latitude": "48.63248000",
        "longitude": "2.48226000"
    },
    {
        "id": 48104,
        "name": "Étival-Clairefontaine",
        "latitude": "48.36519000",
        "longitude": "6.86118000"
    },
    {
        "id": 48105,
        "name": "Étival-lès-le-Mans",
        "latitude": "47.95000000",
        "longitude": "0.08333000"
    },
    {
        "id": 48106,
        "name": "Étoile-sur-Rhône",
        "latitude": "44.83883000",
        "longitude": "4.89050000"
    },
    {
        "id": 48113,
        "name": "Étréchy",
        "latitude": "48.49465000",
        "longitude": "2.19489000"
    },
    {
        "id": 48107,
        "name": "Étreillers",
        "latitude": "49.83059000",
        "longitude": "3.16029000"
    },
    {
        "id": 48108,
        "name": "Étrelles",
        "latitude": "48.06031000",
        "longitude": "-1.19402000"
    },
    {
        "id": 48109,
        "name": "Étrembières",
        "latitude": "46.17923000",
        "longitude": "6.22725000"
    },
    {
        "id": 48114,
        "name": "Étrépagny",
        "latitude": "49.30623000",
        "longitude": "1.61139000"
    },
    {
        "id": 48110,
        "name": "Étretat",
        "latitude": "49.70669000",
        "longitude": "0.20523000"
    },
    {
        "id": 48111,
        "name": "Étreux",
        "latitude": "49.98333000",
        "longitude": "3.65000000"
    },
    {
        "id": 48112,
        "name": "Étriché",
        "latitude": "47.65087000",
        "longitude": "-0.44377000"
    },
    {
        "id": 48115,
        "name": "Étupes",
        "latitude": "47.50525000",
        "longitude": "6.87075000"
    },
    {
        "id": 41741,
        "name": "Etzling",
        "latitude": "49.17943000",
        "longitude": "6.95864000"
    },
    {
        "id": 41742,
        "name": "Eu",
        "latitude": "50.04606000",
        "longitude": "1.42079000"
    },
    {
        "id": 41743,
        "name": "Eulmont",
        "latitude": "48.75026000",
        "longitude": "6.22731000"
    },
    {
        "id": 41744,
        "name": "Eurre",
        "latitude": "44.75825000",
        "longitude": "4.98843000"
    },
    {
        "id": 41745,
        "name": "Eurville-Bienville",
        "latitude": "48.58333000",
        "longitude": "5.03333000"
    },
    {
        "id": 41746,
        "name": "Euville",
        "latitude": "48.75030000",
        "longitude": "5.62603000"
    },
    {
        "id": 48116,
        "name": "Évaux-les-Bains",
        "latitude": "46.17346000",
        "longitude": "2.48463000"
    },
    {
        "id": 48117,
        "name": "Évenos",
        "latitude": "43.16365000",
        "longitude": "5.84628000"
    },
    {
        "id": 48118,
        "name": "Évian-les-Bains",
        "latitude": "46.40111000",
        "longitude": "6.58793000"
    },
    {
        "id": 48119,
        "name": "Évin-Malmaison",
        "latitude": "50.43923000",
        "longitude": "3.03139000"
    },
    {
        "id": 48120,
        "name": "Évires",
        "latitude": "46.03783000",
        "longitude": "6.22453000"
    },
    {
        "id": 48121,
        "name": "Évran",
        "latitude": "48.37987000",
        "longitude": "-1.98076000"
    },
    {
        "id": 48122,
        "name": "Évrecy",
        "latitude": "49.09904000",
        "longitude": "-0.50421000"
    },
    {
        "id": 48123,
        "name": "Évreux",
        "latitude": "49.02414000",
        "longitude": "1.15082000"
    },
    {
        "id": 48124,
        "name": "Évron",
        "latitude": "48.15642000",
        "longitude": "-0.39970000"
    },
    {
        "id": 48125,
        "name": "Évry",
        "latitude": "48.63280000",
        "longitude": "2.44049000"
    },
    {
        "id": 41747,
        "name": "Excideuil",
        "latitude": "45.33635000",
        "longitude": "1.04754000"
    },
    {
        "id": 41748,
        "name": "Exideuil",
        "latitude": "45.88639000",
        "longitude": "0.67318000"
    },
    {
        "id": 41749,
        "name": "Exincourt",
        "latitude": "47.49465000",
        "longitude": "6.83169000"
    },
    {
        "id": 41750,
        "name": "Exireuil",
        "latitude": "46.43382000",
        "longitude": "-0.19251000"
    },
    {
        "id": 41751,
        "name": "Eybens",
        "latitude": "45.14771000",
        "longitude": "5.75014000"
    },
    {
        "id": 41752,
        "name": "Eygalières",
        "latitude": "43.76099000",
        "longitude": "4.94968000"
    },
    {
        "id": 41753,
        "name": "Eyguières",
        "latitude": "43.69481000",
        "longitude": "5.03131000"
    },
    {
        "id": 41754,
        "name": "Eymet",
        "latitude": "44.66812000",
        "longitude": "0.39961000"
    },
    {
        "id": 41755,
        "name": "Eymoutiers",
        "latitude": "45.73790000",
        "longitude": "1.74189000"
    },
    {
        "id": 41756,
        "name": "Eyragues",
        "latitude": "43.84103000",
        "longitude": "4.84231000"
    },
    {
        "id": 41757,
        "name": "Eysines",
        "latitude": "44.88352000",
        "longitude": "-0.64686000"
    },
    {
        "id": 41758,
        "name": "Eyvigues-et-Eybènes",
        "latitude": "44.93333000",
        "longitude": "1.35000000"
    },
    {
        "id": 41759,
        "name": "Eyzin-Pinet",
        "latitude": "45.47377000",
        "longitude": "4.99845000"
    },
    {
        "id": 48126,
        "name": "Ézanville",
        "latitude": "49.02794000",
        "longitude": "2.36787000"
    },
    {
        "id": 48035,
        "name": "Èze",
        "latitude": "43.72799000",
        "longitude": "7.36194000"
    },
    {
        "id": 48127,
        "name": "Ézy-sur-Eure",
        "latitude": "48.86667000",
        "longitude": "1.41667000"
    },
    {
        "id": 41761,
        "name": "Fabrègues",
        "latitude": "43.55057000",
        "longitude": "3.77637000"
    },
    {
        "id": 41760,
        "name": "Fabrezan",
        "latitude": "43.13581000",
        "longitude": "2.69814000"
    },
    {
        "id": 41762,
        "name": "Faches-Thumesnil",
        "latitude": "50.58333000",
        "longitude": "3.06667000"
    },
    {
        "id": 41763,
        "name": "Fagnières",
        "latitude": "48.96385000",
        "longitude": "4.31692000"
    },
    {
        "id": 41764,
        "name": "Fains-Véel",
        "latitude": "48.78333000",
        "longitude": "5.13333000"
    },
    {
        "id": 41765,
        "name": "Falaise",
        "latitude": "48.89217000",
        "longitude": "-0.19527000"
    },
    {
        "id": 41766,
        "name": "Falck",
        "latitude": "49.22695000",
        "longitude": "6.63373000"
    },
    {
        "id": 41767,
        "name": "Falicon",
        "latitude": "43.74861000",
        "longitude": "7.27856000"
    },
    {
        "id": 41768,
        "name": "Falleron",
        "latitude": "46.88160000",
        "longitude": "-1.70208000"
    },
    {
        "id": 41769,
        "name": "Famars",
        "latitude": "50.31743000",
        "longitude": "3.51945000"
    },
    {
        "id": 41770,
        "name": "Fameck",
        "latitude": "49.29895000",
        "longitude": "6.10915000"
    },
    {
        "id": 41771,
        "name": "Fampoux",
        "latitude": "50.30173000",
        "longitude": "2.87310000"
    },
    {
        "id": 41777,
        "name": "Farébersviller",
        "latitude": "49.11898000",
        "longitude": "6.86914000"
    },
    {
        "id": 41772,
        "name": "Fareins",
        "latitude": "46.01913000",
        "longitude": "4.76136000"
    },
    {
        "id": 41773,
        "name": "Faremoutiers",
        "latitude": "48.79962000",
        "longitude": "2.99607000"
    },
    {
        "id": 41774,
        "name": "Fargues-Saint-Hilaire",
        "latitude": "44.82304000",
        "longitude": "-0.44676000"
    },
    {
        "id": 41775,
        "name": "Farnay",
        "latitude": "45.49622000",
        "longitude": "4.58311000"
    },
    {
        "id": 41776,
        "name": "Farschviller",
        "latitude": "49.09406000",
        "longitude": "6.89517000"
    },
    {
        "id": 41778,
        "name": "Faulquemont",
        "latitude": "49.04910000",
        "longitude": "6.59732000"
    },
    {
        "id": 41779,
        "name": "Faulx",
        "latitude": "48.79266000",
        "longitude": "6.19554000"
    },
    {
        "id": 41780,
        "name": "Faumont",
        "latitude": "50.46017000",
        "longitude": "3.13713000"
    },
    {
        "id": 41781,
        "name": "Fauville-en-Caux",
        "latitude": "49.65257000",
        "longitude": "0.59197000"
    },
    {
        "id": 41782,
        "name": "Faverges",
        "latitude": "45.75116000",
        "longitude": "6.29151000"
    },
    {
        "id": 41783,
        "name": "Faverges-de-la-Tour",
        "latitude": "45.59068000",
        "longitude": "5.52136000"
    },
    {
        "id": 41784,
        "name": "Faverney",
        "latitude": "47.76713000",
        "longitude": "6.10428000"
    },
    {
        "id": 41785,
        "name": "Faverolles-sur-Cher",
        "latitude": "47.31732000",
        "longitude": "1.19045000"
    },
    {
        "id": 41786,
        "name": "Favières",
        "latitude": "48.76324000",
        "longitude": "2.77470000"
    },
    {
        "id": 41787,
        "name": "Fay-aux-Loges",
        "latitude": "47.92724000",
        "longitude": "2.14012000"
    },
    {
        "id": 41788,
        "name": "Fay-de-Bretagne",
        "latitude": "47.41453000",
        "longitude": "-1.79155000"
    },
    {
        "id": 41789,
        "name": "Fayence",
        "latitude": "43.62570000",
        "longitude": "6.69531000"
    },
    {
        "id": 41790,
        "name": "Fayl-Billot",
        "latitude": "47.78199000",
        "longitude": "5.59917000"
    },
    {
        "id": 41999,
        "name": "Fécamp",
        "latitude": "49.75787000",
        "longitude": "0.37457000"
    },
    {
        "id": 42000,
        "name": "Féchain",
        "latitude": "50.26638000",
        "longitude": "3.21024000"
    },
    {
        "id": 41791,
        "name": "Fegersheim",
        "latitude": "48.49016000",
        "longitude": "7.68107000"
    },
    {
        "id": 42001,
        "name": "Fégréac",
        "latitude": "47.58476000",
        "longitude": "-2.04410000"
    },
    {
        "id": 41793,
        "name": "Feigères",
        "latitude": "46.11228000",
        "longitude": "6.07937000"
    },
    {
        "id": 41792,
        "name": "Feignies",
        "latitude": "50.29806000",
        "longitude": "3.91534000"
    },
    {
        "id": 41794,
        "name": "Feillens",
        "latitude": "46.33426000",
        "longitude": "4.89146000"
    },
    {
        "id": 42002,
        "name": "Félines",
        "latitude": "45.31668000",
        "longitude": "4.72836000"
    },
    {
        "id": 41795,
        "name": "Fellering",
        "latitude": "47.89659000",
        "longitude": "6.98552000"
    },
    {
        "id": 41796,
        "name": "Felletin",
        "latitude": "45.88333000",
        "longitude": "2.17431000"
    },
    {
        "id": 41797,
        "name": "Fenain",
        "latitude": "50.36667000",
        "longitude": "3.30000000"
    },
    {
        "id": 42003,
        "name": "Fénay",
        "latitude": "47.23185000",
        "longitude": "5.06211000"
    },
    {
        "id": 41798,
        "name": "Feneu",
        "latitude": "47.57211000",
        "longitude": "-0.59422000"
    },
    {
        "id": 41799,
        "name": "Fenouillet",
        "latitude": "43.68041000",
        "longitude": "1.39200000"
    },
    {
        "id": 41997,
        "name": "Fère-Champenoise",
        "latitude": "48.75431000",
        "longitude": "3.99069000"
    },
    {
        "id": 41998,
        "name": "Fère-en-Tardenois",
        "latitude": "49.20000000",
        "longitude": "3.51667000"
    },
    {
        "id": 42004,
        "name": "Férel",
        "latitude": "47.48203000",
        "longitude": "-2.34227000"
    },
    {
        "id": 42005,
        "name": "Férin",
        "latitude": "50.32732000",
        "longitude": "3.07415000"
    },
    {
        "id": 41800,
        "name": "Fermanville",
        "latitude": "49.68673000",
        "longitude": "-1.46284000"
    },
    {
        "id": 41801,
        "name": "Ferney-Voltaire",
        "latitude": "46.25858000",
        "longitude": "6.11063000"
    },
    {
        "id": 42006,
        "name": "Férolles",
        "latitude": "47.83475000",
        "longitude": "2.11113000"
    },
    {
        "id": 42007,
        "name": "Férolles-Attilly",
        "latitude": "48.73184000",
        "longitude": "2.63088000"
    },
    {
        "id": 41802,
        "name": "Ferques",
        "latitude": "50.83002000",
        "longitude": "1.75994000"
    },
    {
        "id": 41803,
        "name": "Ferrals-les-Corbières",
        "latitude": "43.15000000",
        "longitude": "2.73333000"
    },
    {
        "id": 41804,
        "name": "Ferrette",
        "latitude": "47.49436000",
        "longitude": "7.31372000"
    },
    {
        "id": 41805,
        "name": "Ferrière-la-Grande",
        "latitude": "50.25521000",
        "longitude": "3.99288000"
    },
    {
        "id": 41806,
        "name": "Ferrières-en-Brie",
        "latitude": "48.82352000",
        "longitude": "2.70664000"
    },
    {
        "id": 41807,
        "name": "Fesches-le-Châtel",
        "latitude": "47.52415000",
        "longitude": "6.90535000"
    },
    {
        "id": 41808,
        "name": "Fessenheim",
        "latitude": "47.91565000",
        "longitude": "7.53499000"
    },
    {
        "id": 41809,
        "name": "Festubert",
        "latitude": "50.54250000",
        "longitude": "2.73593000"
    },
    {
        "id": 42008,
        "name": "Féternes",
        "latitude": "46.35582000",
        "longitude": "6.54829000"
    },
    {
        "id": 41810,
        "name": "Feucherolles",
        "latitude": "48.87002000",
        "longitude": "1.97402000"
    },
    {
        "id": 41811,
        "name": "Feuchy",
        "latitude": "50.29439000",
        "longitude": "2.84335000"
    },
    {
        "id": 41812,
        "name": "Feuquières",
        "latitude": "49.64677000",
        "longitude": "1.84784000"
    },
    {
        "id": 41813,
        "name": "Feuquières-en-Vimeu",
        "latitude": "50.05982000",
        "longitude": "1.60465000"
    },
    {
        "id": 41814,
        "name": "Feurs",
        "latitude": "45.73337000",
        "longitude": "4.22755000"
    },
    {
        "id": 41815,
        "name": "Feytiat",
        "latitude": "45.80905000",
        "longitude": "1.33033000"
    },
    {
        "id": 41816,
        "name": "Feyzin",
        "latitude": "45.67287000",
        "longitude": "4.85894000"
    },
    {
        "id": 41817,
        "name": "Figanières",
        "latitude": "43.56882000",
        "longitude": "6.49722000"
    },
    {
        "id": 41818,
        "name": "Figari",
        "latitude": "41.48792000",
        "longitude": "9.13013000"
    },
    {
        "id": 41819,
        "name": "Figeac",
        "latitude": "44.60880000",
        "longitude": "2.03187000"
    },
    {
        "id": 41821,
        "name": "Fillé",
        "latitude": "47.90011000",
        "longitude": "0.12543000"
    },
    {
        "id": 41820,
        "name": "Fillinges",
        "latitude": "46.15944000",
        "longitude": "6.34237000"
    },
    {
        "id": 41822,
        "name": "Finhan",
        "latitude": "43.91335000",
        "longitude": "1.22120000"
    },
    {
        "id": 41823,
        "name": "Firmi",
        "latitude": "44.54106000",
        "longitude": "2.30764000"
    },
    {
        "id": 41824,
        "name": "Firminy",
        "latitude": "45.38956000",
        "longitude": "4.28860000"
    },
    {
        "id": 41825,
        "name": "Fismes",
        "latitude": "49.30773000",
        "longitude": "3.68607000"
    },
    {
        "id": 41826,
        "name": "Fitilieu",
        "latitude": "45.54744000",
        "longitude": "5.56194000"
    },
    {
        "id": 41827,
        "name": "Fitz-James",
        "latitude": "49.39113000",
        "longitude": "2.43070000"
    },
    {
        "id": 41828,
        "name": "Flamanville",
        "latitude": "49.53274000",
        "longitude": "-1.86560000"
    },
    {
        "id": 41829,
        "name": "Flassans-sur-Issole",
        "latitude": "43.36856000",
        "longitude": "6.22154000"
    },
    {
        "id": 41830,
        "name": "Flaviac",
        "latitude": "44.74777000",
        "longitude": "4.67434000"
    },
    {
        "id": 41831,
        "name": "Flavigny-sur-Moselle",
        "latitude": "48.56567000",
        "longitude": "6.18878000"
    },
    {
        "id": 41832,
        "name": "Flavin",
        "latitude": "44.28890000",
        "longitude": "2.60513000"
    },
    {
        "id": 41833,
        "name": "Flavy-le-Martel",
        "latitude": "49.71299000",
        "longitude": "3.19150000"
    },
    {
        "id": 41834,
        "name": "Flaxlanden",
        "latitude": "47.69547000",
        "longitude": "7.31484000"
    },
    {
        "id": 41835,
        "name": "Flayosc",
        "latitude": "43.53396000",
        "longitude": "6.39660000"
    },
    {
        "id": 41864,
        "name": "Fléac",
        "latitude": "45.66667000",
        "longitude": "0.10000000"
    },
    {
        "id": 41836,
        "name": "Flers",
        "latitude": "48.73722000",
        "longitude": "-0.57466000"
    },
    {
        "id": 41837,
        "name": "Flers-en-Escrebieux",
        "latitude": "50.39790000",
        "longitude": "3.06038000"
    },
    {
        "id": 41838,
        "name": "Flesselles",
        "latitude": "50.00202000",
        "longitude": "2.26119000"
    },
    {
        "id": 41839,
        "name": "Fleurance",
        "latitude": "43.84824000",
        "longitude": "0.66302000"
    },
    {
        "id": 41840,
        "name": "Fleurbaix",
        "latitude": "50.65061000",
        "longitude": "2.83305000"
    },
    {
        "id": 41841,
        "name": "Fleurey-sur-Ouche",
        "latitude": "47.31182000",
        "longitude": "4.85911000"
    },
    {
        "id": 41842,
        "name": "Fleurie",
        "latitude": "46.19219000",
        "longitude": "4.69747000"
    },
    {
        "id": 41843,
        "name": "Fleurieu-sur-Saône",
        "latitude": "45.86095000",
        "longitude": "4.84633000"
    },
    {
        "id": 41844,
        "name": "Fleurines",
        "latitude": "49.25901000",
        "longitude": "2.58385000"
    },
    {
        "id": 41845,
        "name": "Fleury",
        "latitude": "43.23095000",
        "longitude": "3.13745000"
    },
    {
        "id": 41846,
        "name": "Fleury",
        "latitude": "49.04181000",
        "longitude": "6.19329000"
    },
    {
        "id": 41848,
        "name": "Fleury-la-Vallée",
        "latitude": "47.86672000",
        "longitude": "3.44908000"
    },
    {
        "id": 41849,
        "name": "Fleury-les-Aubrais",
        "latitude": "47.93328000",
        "longitude": "1.91811000"
    },
    {
        "id": 41847,
        "name": "Fleury-Mérogis",
        "latitude": "48.63730000",
        "longitude": "2.36378000"
    },
    {
        "id": 41850,
        "name": "Fleury-sur-Andelle",
        "latitude": "49.36176000",
        "longitude": "1.35599000"
    },
    {
        "id": 41851,
        "name": "Fleury-sur-Orne",
        "latitude": "49.14851000",
        "longitude": "-0.37508000"
    },
    {
        "id": 41865,
        "name": "Fléville-devant-Nancy",
        "latitude": "48.62495000",
        "longitude": "6.20325000"
    },
    {
        "id": 41853,
        "name": "Flines-lès-Mortagne",
        "latitude": "50.50281000",
        "longitude": "3.46495000"
    },
    {
        "id": 41852,
        "name": "Flines-lez-Raches",
        "latitude": "50.41667000",
        "longitude": "3.18333000"
    },
    {
        "id": 41854,
        "name": "Flins-sur-Seine",
        "latitude": "48.96523000",
        "longitude": "1.87314000"
    },
    {
        "id": 41855,
        "name": "Flixecourt",
        "latitude": "50.01465000",
        "longitude": "2.08095000"
    },
    {
        "id": 41856,
        "name": "Flize",
        "latitude": "49.69875000",
        "longitude": "4.77171000"
    },
    {
        "id": 41857,
        "name": "Flogny-la-Chapelle",
        "latitude": "47.95000000",
        "longitude": "3.86667000"
    },
    {
        "id": 41858,
        "name": "Floing",
        "latitude": "49.72216000",
        "longitude": "4.92947000"
    },
    {
        "id": 41859,
        "name": "Floirac",
        "latitude": "44.83238000",
        "longitude": "-0.51411000"
    },
    {
        "id": 41860,
        "name": "Florac",
        "latitude": "44.32632000",
        "longitude": "3.59301000"
    },
    {
        "id": 41861,
        "name": "Florange",
        "latitude": "49.32373000",
        "longitude": "6.12120000"
    },
    {
        "id": 41862,
        "name": "Florensac",
        "latitude": "43.38301000",
        "longitude": "3.46638000"
    },
    {
        "id": 41863,
        "name": "Flourens",
        "latitude": "43.59273000",
        "longitude": "1.56259000"
    },
    {
        "id": 41941,
        "name": "Foëcy",
        "latitude": "47.17618000",
        "longitude": "2.16257000"
    },
    {
        "id": 41866,
        "name": "Foissiat",
        "latitude": "46.37150000",
        "longitude": "5.17525000"
    },
    {
        "id": 41867,
        "name": "Foix",
        "latitude": "42.96046000",
        "longitude": "1.60787000"
    },
    {
        "id": 41868,
        "name": "Folembray",
        "latitude": "49.54334000",
        "longitude": "3.29119000"
    },
    {
        "id": 41869,
        "name": "Folkling",
        "latitude": "49.14756000",
        "longitude": "6.89482000"
    },
    {
        "id": 41870,
        "name": "Follainville-Dennemont",
        "latitude": "49.02194000",
        "longitude": "1.71331000"
    },
    {
        "id": 41871,
        "name": "Folschviller",
        "latitude": "49.07732000",
        "longitude": "6.68358000"
    },
    {
        "id": 41872,
        "name": "Fonbeauzard",
        "latitude": "43.67830000",
        "longitude": "1.43440000"
    },
    {
        "id": 41873,
        "name": "Fondettes",
        "latitude": "47.40350000",
        "longitude": "0.59686000"
    },
    {
        "id": 41874,
        "name": "Fonsorbes",
        "latitude": "43.53524000",
        "longitude": "1.22937000"
    },
    {
        "id": 41875,
        "name": "Font-Romeu-Odeillo-Via",
        "latitude": "42.50552000",
        "longitude": "2.04011000"
    },
    {
        "id": 41876,
        "name": "Fontaine",
        "latitude": "45.19275000",
        "longitude": "5.68821000"
    },
    {
        "id": 41878,
        "name": "Fontaine-au-Pire",
        "latitude": "50.13250000",
        "longitude": "3.37667000"
    },
    {
        "id": 41886,
        "name": "Fontaine-Étoupefour",
        "latitude": "49.14600000",
        "longitude": "-0.45522000"
    },
    {
        "id": 41879,
        "name": "Fontaine-la-Guyon",
        "latitude": "48.47270000",
        "longitude": "1.31417000"
    },
    {
        "id": 41880,
        "name": "Fontaine-la-Mallet",
        "latitude": "49.53600000",
        "longitude": "0.14625000"
    },
    {
        "id": 41881,
        "name": "Fontaine-le-Bourg",
        "latitude": "49.56451000",
        "longitude": "1.16391000"
    },
    {
        "id": 41882,
        "name": "Fontaine-le-Comte",
        "latitude": "46.53217000",
        "longitude": "0.26176000"
    },
    {
        "id": 41883,
        "name": "Fontaine-le-Dun",
        "latitude": "49.81182000",
        "longitude": "0.85095000"
    },
    {
        "id": 41884,
        "name": "Fontaine-lès-Dijon",
        "latitude": "47.34238000",
        "longitude": "5.02007000"
    },
    {
        "id": 41885,
        "name": "Fontaine-lès-Luxeuil",
        "latitude": "47.85592000",
        "longitude": "6.33482000"
    },
    {
        "id": 41877,
        "name": "Fontaine-Notre-Dame",
        "latitude": "50.16681000",
        "longitude": "3.15812000"
    },
    {
        "id": 41887,
        "name": "Fontainebleau",
        "latitude": "48.40908000",
        "longitude": "2.70177000"
    },
    {
        "id": 41888,
        "name": "Fontaines",
        "latitude": "46.84905000",
        "longitude": "4.77036000"
    },
    {
        "id": 41889,
        "name": "Fontaines-Saint-Martin",
        "latitude": "45.84420000",
        "longitude": "4.85305000"
    },
    {
        "id": 41890,
        "name": "Fontaines-sur-Saône",
        "latitude": "45.83572000",
        "longitude": "4.84490000"
    },
    {
        "id": 41891,
        "name": "Fontanil-Cornillon",
        "latitude": "45.25280000",
        "longitude": "5.66308000"
    },
    {
        "id": 41892,
        "name": "Fontannes",
        "latitude": "45.30149000",
        "longitude": "3.76366000"
    },
    {
        "id": 41893,
        "name": "Fontcouverte",
        "latitude": "45.76708000",
        "longitude": "-0.58682000"
    },
    {
        "id": 41894,
        "name": "Fontenay",
        "latitude": "49.55993000",
        "longitude": "0.18391000"
    },
    {
        "id": 41896,
        "name": "Fontenay-aux-Roses",
        "latitude": "48.79325000",
        "longitude": "2.29275000"
    },
    {
        "id": 41897,
        "name": "Fontenay-en-Parisis",
        "latitude": "49.05371000",
        "longitude": "2.45156000"
    },
    {
        "id": 41898,
        "name": "Fontenay-le-Comte",
        "latitude": "46.46720000",
        "longitude": "-0.80645000"
    },
    {
        "id": 41899,
        "name": "Fontenay-le-Fleury",
        "latitude": "48.81253000",
        "longitude": "2.04863000"
    },
    {
        "id": 41900,
        "name": "Fontenay-le-Marmion",
        "latitude": "49.09346000",
        "longitude": "-0.35294000"
    },
    {
        "id": 41901,
        "name": "Fontenay-lès-Briis",
        "latitude": "48.61962000",
        "longitude": "2.15276000"
    },
    {
        "id": 41902,
        "name": "Fontenay-sous-Bois",
        "latitude": "48.85442000",
        "longitude": "2.48268000"
    },
    {
        "id": 41903,
        "name": "Fontenay-sur-Loing",
        "latitude": "48.10365000",
        "longitude": "2.77542000"
    },
    {
        "id": 41895,
        "name": "Fontenay-Trésigny",
        "latitude": "48.70647000",
        "longitude": "2.87047000"
    },
    {
        "id": 41904,
        "name": "Fontenilles",
        "latitude": "43.55286000",
        "longitude": "1.19096000"
    },
    {
        "id": 41905,
        "name": "Fontevraud-l'Abbaye",
        "latitude": "47.18333000",
        "longitude": "0.05000000"
    },
    {
        "id": 41906,
        "name": "Fontoy",
        "latitude": "49.35597000",
        "longitude": "5.99250000"
    },
    {
        "id": 41907,
        "name": "Fontvieille",
        "latitude": "43.72806000",
        "longitude": "4.70953000"
    },
    {
        "id": 41908,
        "name": "Forbach",
        "latitude": "49.18848000",
        "longitude": "6.89255000"
    },
    {
        "id": 41909,
        "name": "Forcalqueiret",
        "latitude": "43.33618000",
        "longitude": "6.08346000"
    },
    {
        "id": 41910,
        "name": "Forcalquier",
        "latitude": "43.95927000",
        "longitude": "5.77945000"
    },
    {
        "id": 41911,
        "name": "Forest-sur-Marque",
        "latitude": "50.63341000",
        "longitude": "3.18939000"
    },
    {
        "id": 41912,
        "name": "Forges-les-Bains",
        "latitude": "48.62942000",
        "longitude": "2.10264000"
    },
    {
        "id": 41913,
        "name": "Forges-les-Eaux",
        "latitude": "49.61391000",
        "longitude": "1.54449000"
    },
    {
        "id": 41914,
        "name": "Formerie",
        "latitude": "49.64928000",
        "longitude": "1.73106000"
    },
    {
        "id": 41915,
        "name": "Fors",
        "latitude": "46.23570000",
        "longitude": "-0.40904000"
    },
    {
        "id": 41916,
        "name": "Fort-Mahon-Plage",
        "latitude": "50.33955000",
        "longitude": "1.55984000"
    },
    {
        "id": 41917,
        "name": "Fort-Mardyck",
        "latitude": "51.02899000",
        "longitude": "2.30724000"
    },
    {
        "id": 41918,
        "name": "Fortschwihr",
        "latitude": "48.08825000",
        "longitude": "7.45050000"
    },
    {
        "id": 41919,
        "name": "Fos-sur-Mer",
        "latitude": "43.43774000",
        "longitude": "4.94457000"
    },
    {
        "id": 41920,
        "name": "Fosses",
        "latitude": "49.09808000",
        "longitude": "2.50957000"
    },
    {
        "id": 41921,
        "name": "Foucarmont",
        "latitude": "49.84682000",
        "longitude": "1.56899000"
    },
    {
        "id": 41922,
        "name": "Foucherans",
        "latitude": "47.08094000",
        "longitude": "5.45503000"
    },
    {
        "id": 41923,
        "name": "Fouesnant",
        "latitude": "47.89187000",
        "longitude": "-4.01484000"
    },
    {
        "id": 41924,
        "name": "Foug",
        "latitude": "48.68385000",
        "longitude": "5.78735000"
    },
    {
        "id": 41927,
        "name": "Fougères",
        "latitude": "48.35185000",
        "longitude": "-1.19989000"
    },
    {
        "id": 41925,
        "name": "Fougerolles",
        "latitude": "47.88542000",
        "longitude": "6.40454000"
    },
    {
        "id": 41926,
        "name": "Fougerolles-du-Plessis",
        "latitude": "48.47424000",
        "longitude": "-0.97255000"
    },
    {
        "id": 41928,
        "name": "Fouillard",
        "latitude": "48.15820000",
        "longitude": "-1.57915000"
    },
    {
        "id": 41929,
        "name": "Foulayronnes",
        "latitude": "44.24029000",
        "longitude": "0.64516000"
    },
    {
        "id": 41930,
        "name": "Fouquereuil",
        "latitude": "50.51885000",
        "longitude": "2.60024000"
    },
    {
        "id": 41931,
        "name": "Fouquières-lès-Béthune",
        "latitude": "50.51534000",
        "longitude": "2.60999000"
    },
    {
        "id": 41932,
        "name": "Fouquières-lès-Lens",
        "latitude": "50.42842000",
        "longitude": "2.91321000"
    },
    {
        "id": 41933,
        "name": "Fouras",
        "latitude": "45.98736000",
        "longitude": "-1.09275000"
    },
    {
        "id": 41934,
        "name": "Fourchambault",
        "latitude": "47.01667000",
        "longitude": "3.08333000"
    },
    {
        "id": 41935,
        "name": "Fourmies",
        "latitude": "50.01532000",
        "longitude": "4.04784000"
    },
    {
        "id": 41936,
        "name": "Fournes-en-Weppes",
        "latitude": "50.58497000",
        "longitude": "2.88793000"
    },
    {
        "id": 41937,
        "name": "Fourques",
        "latitude": "43.69450000",
        "longitude": "4.60932000"
    },
    {
        "id": 41938,
        "name": "Fourques-sur-Garonne",
        "latitude": "44.44798000",
        "longitude": "0.15703000"
    },
    {
        "id": 41939,
        "name": "Fourqueux",
        "latitude": "48.88693000",
        "longitude": "2.06367000"
    },
    {
        "id": 41940,
        "name": "Foussais-Payré",
        "latitude": "46.53333000",
        "longitude": "-0.68333000"
    },
    {
        "id": 41942,
        "name": "Frahier-et-Chatebier",
        "latitude": "47.66667000",
        "longitude": "6.75000000"
    },
    {
        "id": 41943,
        "name": "Fraisans",
        "latitude": "47.14857000",
        "longitude": "5.76051000"
    },
    {
        "id": 41944,
        "name": "Fraisses",
        "latitude": "45.38838000",
        "longitude": "4.26373000"
    },
    {
        "id": 41945,
        "name": "Fraize",
        "latitude": "48.18660000",
        "longitude": "6.99787000"
    },
    {
        "id": 41946,
        "name": "Francheleins",
        "latitude": "46.07440000",
        "longitude": "4.80920000"
    },
    {
        "id": 41947,
        "name": "Francheville",
        "latitude": "45.73637000",
        "longitude": "4.76358000"
    },
    {
        "id": 41948,
        "name": "Francheville",
        "latitude": "48.78647000",
        "longitude": "0.84962000"
    },
    {
        "id": 41949,
        "name": "Franconville",
        "latitude": "48.98333000",
        "longitude": "2.23333000"
    },
    {
        "id": 41950,
        "name": "Frangy",
        "latitude": "46.02000000",
        "longitude": "5.93220000"
    },
    {
        "id": 41951,
        "name": "Franois",
        "latitude": "47.23055000",
        "longitude": "5.92869000"
    },
    {
        "id": 41952,
        "name": "Frans",
        "latitude": "45.99291000",
        "longitude": "4.77820000"
    },
    {
        "id": 41953,
        "name": "Frasne",
        "latitude": "46.85641000",
        "longitude": "6.15940000"
    },
    {
        "id": 41983,
        "name": "Fréhel",
        "latitude": "48.63333000",
        "longitude": "-2.36667000"
    },
    {
        "id": 41954,
        "name": "Freigné",
        "latitude": "47.54858000",
        "longitude": "-1.12274000"
    },
    {
        "id": 41984,
        "name": "Fréjairolles",
        "latitude": "43.88094000",
        "longitude": "2.23151000"
    },
    {
        "id": 41985,
        "name": "Fréjus",
        "latitude": "43.43325000",
        "longitude": "6.73555000"
    },
    {
        "id": 41986,
        "name": "Fréland",
        "latitude": "48.17215000",
        "longitude": "7.19167000"
    },
    {
        "id": 41955,
        "name": "Frelinghien",
        "latitude": "50.71667000",
        "longitude": "2.93333000"
    },
    {
        "id": 41956,
        "name": "Freneuse",
        "latitude": "49.04832000",
        "longitude": "1.60168000"
    },
    {
        "id": 41987,
        "name": "Frépillon",
        "latitude": "49.05216000",
        "longitude": "2.20528000"
    },
    {
        "id": 41957,
        "name": "Fresnay-sur-Sarthe",
        "latitude": "48.28199000",
        "longitude": "0.02288000"
    },
    {
        "id": 41958,
        "name": "Fresnes",
        "latitude": "48.75568000",
        "longitude": "2.32241000"
    },
    {
        "id": 41959,
        "name": "Fresnes-sur-Escaut",
        "latitude": "50.43382000",
        "longitude": "3.57752000"
    },
    {
        "id": 41960,
        "name": "Fresnoy-le-Grand",
        "latitude": "49.94757000",
        "longitude": "3.41841000"
    },
    {
        "id": 41961,
        "name": "Fresse-sur-Moselle",
        "latitude": "47.87589000",
        "longitude": "6.78589000"
    },
    {
        "id": 41962,
        "name": "Fressenneville",
        "latitude": "50.06838000",
        "longitude": "1.57816000"
    },
    {
        "id": 41988,
        "name": "Fréthun",
        "latitude": "50.91729000",
        "longitude": "1.82505000"
    },
    {
        "id": 41963,
        "name": "Fretin",
        "latitude": "50.55745000",
        "longitude": "3.13668000"
    },
    {
        "id": 41989,
        "name": "Frévent",
        "latitude": "50.27608000",
        "longitude": "2.28725000"
    },
    {
        "id": 41964,
        "name": "Freyming-Merlebach",
        "latitude": "49.15000000",
        "longitude": "6.78333000"
    },
    {
        "id": 41965,
        "name": "Frignicourt",
        "latitude": "48.69966000",
        "longitude": "4.59153000"
    },
    {
        "id": 41966,
        "name": "Friville-Escarbotin",
        "latitude": "50.08602000",
        "longitude": "1.54560000"
    },
    {
        "id": 41967,
        "name": "Froges",
        "latitude": "45.27377000",
        "longitude": "5.92098000"
    },
    {
        "id": 41968,
        "name": "Froideconche",
        "latitude": "47.82121000",
        "longitude": "6.41523000"
    },
    {
        "id": 41969,
        "name": "Froidfond",
        "latitude": "46.86898000",
        "longitude": "-1.75740000"
    },
    {
        "id": 41970,
        "name": "Fromelennes",
        "latitude": "50.12333000",
        "longitude": "4.85952000"
    },
    {
        "id": 41971,
        "name": "Froncles",
        "latitude": "48.29756000",
        "longitude": "5.14586000"
    },
    {
        "id": 41972,
        "name": "Fronsac",
        "latitude": "44.91667000",
        "longitude": "-0.26667000"
    },
    {
        "id": 41973,
        "name": "Frontenay-Rohan-Rohan",
        "latitude": "46.25276000",
        "longitude": "-0.53833000"
    },
    {
        "id": 41974,
        "name": "Frontenex",
        "latitude": "45.63335000",
        "longitude": "6.31168000"
    },
    {
        "id": 41975,
        "name": "Frontignan",
        "latitude": "43.44848000",
        "longitude": "3.75400000"
    },
    {
        "id": 41976,
        "name": "Fronton",
        "latitude": "43.83931000",
        "longitude": "1.38931000"
    },
    {
        "id": 41977,
        "name": "Frontonas",
        "latitude": "45.64487000",
        "longitude": "5.19701000"
    },
    {
        "id": 41978,
        "name": "Frossay",
        "latitude": "47.24451000",
        "longitude": "-1.93557000"
    },
    {
        "id": 41979,
        "name": "Frotey-lès-Vesoul",
        "latitude": "47.62078000",
        "longitude": "6.18831000"
    },
    {
        "id": 41980,
        "name": "Frouard",
        "latitude": "48.75994000",
        "longitude": "6.13036000"
    },
    {
        "id": 41981,
        "name": "Frouzins",
        "latitude": "43.51482000",
        "longitude": "1.32523000"
    },
    {
        "id": 41982,
        "name": "Fruges",
        "latitude": "50.51501000",
        "longitude": "2.13292000"
    },
    {
        "id": 41990,
        "name": "Fublaines",
        "latitude": "48.93816000",
        "longitude": "2.93655000"
    },
    {
        "id": 41991,
        "name": "Fumay",
        "latitude": "49.99132000",
        "longitude": "4.70771000"
    },
    {
        "id": 41992,
        "name": "Fumel",
        "latitude": "44.49862000",
        "longitude": "0.96506000"
    },
    {
        "id": 41993,
        "name": "Furdenheim",
        "latitude": "48.61129000",
        "longitude": "7.56100000"
    },
    {
        "id": 41994,
        "name": "Furiani",
        "latitude": "42.65847000",
        "longitude": "9.41446000"
    },
    {
        "id": 41995,
        "name": "Fussy",
        "latitude": "47.14372000",
        "longitude": "2.42953000"
    },
    {
        "id": 41996,
        "name": "Fuveau",
        "latitude": "43.45578000",
        "longitude": "5.56149000"
    },
    {
        "id": 42009,
        "name": "Gabarret",
        "latitude": "43.98779000",
        "longitude": "0.00978000"
    },
    {
        "id": 42010,
        "name": "Gacé",
        "latitude": "48.79344000",
        "longitude": "0.29624000"
    },
    {
        "id": 42057,
        "name": "Gaël",
        "latitude": "48.13209000",
        "longitude": "-2.22267000"
    },
    {
        "id": 42011,
        "name": "Gagnac-sur-Garonne",
        "latitude": "43.69990000",
        "longitude": "1.37535000"
    },
    {
        "id": 42012,
        "name": "Gagny",
        "latitude": "48.88333000",
        "longitude": "2.53333000"
    },
    {
        "id": 42013,
        "name": "Gaillac",
        "latitude": "43.90160000",
        "longitude": "1.89686000"
    },
    {
        "id": 42014,
        "name": "Gaillac-Toulza",
        "latitude": "43.25536000",
        "longitude": "1.47141000"
    },
    {
        "id": 42015,
        "name": "Gaillan-en-Médoc",
        "latitude": "45.32133000",
        "longitude": "-0.95794000"
    },
    {
        "id": 42016,
        "name": "Gaillard",
        "latitude": "46.18530000",
        "longitude": "6.20693000"
    },
    {
        "id": 42017,
        "name": "Gaillefontaine",
        "latitude": "49.65371000",
        "longitude": "1.61547000"
    },
    {
        "id": 42018,
        "name": "Gaillon",
        "latitude": "49.16104000",
        "longitude": "1.34016000"
    },
    {
        "id": 42019,
        "name": "Gainneville",
        "latitude": "49.51845000",
        "longitude": "0.26164000"
    },
    {
        "id": 42020,
        "name": "Galgon",
        "latitude": "44.98333000",
        "longitude": "-0.26667000"
    },
    {
        "id": 42021,
        "name": "Gallardon",
        "latitude": "48.52622000",
        "longitude": "1.69307000"
    },
    {
        "id": 42022,
        "name": "Gallargues-le-Montueux",
        "latitude": "43.71667000",
        "longitude": "4.16667000"
    },
    {
        "id": 42023,
        "name": "Galluis",
        "latitude": "48.79657000",
        "longitude": "1.79414000"
    },
    {
        "id": 42024,
        "name": "Gamaches",
        "latitude": "49.98615000",
        "longitude": "1.55624000"
    },
    {
        "id": 42025,
        "name": "Gambais",
        "latitude": "48.77351000",
        "longitude": "1.67196000"
    },
    {
        "id": 42026,
        "name": "Gambsheim",
        "latitude": "48.69209000",
        "longitude": "7.88286000"
    },
    {
        "id": 42027,
        "name": "Gan",
        "latitude": "43.23333000",
        "longitude": "-0.38333000"
    },
    {
        "id": 42028,
        "name": "Gandrange",
        "latitude": "49.27133000",
        "longitude": "6.12536000"
    },
    {
        "id": 42029,
        "name": "Ganges",
        "latitude": "43.93380000",
        "longitude": "3.70784000"
    },
    {
        "id": 42030,
        "name": "Gannat",
        "latitude": "46.09987000",
        "longitude": "3.19842000"
    },
    {
        "id": 42031,
        "name": "Gap",
        "latitude": "44.55858000",
        "longitude": "6.07868000"
    },
    {
        "id": 42032,
        "name": "Garancières",
        "latitude": "48.82271000",
        "longitude": "1.75512000"
    },
    {
        "id": 42033,
        "name": "Garat",
        "latitude": "45.63333000",
        "longitude": "0.26667000"
    },
    {
        "id": 42034,
        "name": "Garches",
        "latitude": "48.84226000",
        "longitude": "2.18232000"
    },
    {
        "id": 42035,
        "name": "Garchizy",
        "latitude": "47.04786000",
        "longitude": "3.09625000"
    },
    {
        "id": 42036,
        "name": "Gard",
        "latitude": "44.01790000",
        "longitude": "4.28751000"
    },
    {
        "id": 42037,
        "name": "Gardanne",
        "latitude": "43.45491000",
        "longitude": "5.46913000"
    },
    {
        "id": 42038,
        "name": "Gardonne",
        "latitude": "44.83333000",
        "longitude": "0.35000000"
    },
    {
        "id": 42039,
        "name": "Gardouch",
        "latitude": "43.39096000",
        "longitude": "1.68313000"
    },
    {
        "id": 42040,
        "name": "Garennes-sur-Eure",
        "latitude": "48.91116000",
        "longitude": "1.43836000"
    },
    {
        "id": 42048,
        "name": "Garéoult",
        "latitude": "43.32833000",
        "longitude": "6.04616000"
    },
    {
        "id": 42041,
        "name": "Gargas",
        "latitude": "43.90196000",
        "longitude": "5.35814000"
    },
    {
        "id": 42042,
        "name": "Gargenville",
        "latitude": "48.98802000",
        "longitude": "1.81176000"
    },
    {
        "id": 42043,
        "name": "Garges-lès-Gonesse",
        "latitude": "48.96791000",
        "longitude": "2.39781000"
    },
    {
        "id": 42044,
        "name": "Garidech",
        "latitude": "43.71076000",
        "longitude": "1.56036000"
    },
    {
        "id": 42045,
        "name": "Garlin",
        "latitude": "43.55927000",
        "longitude": "-0.27321000"
    },
    {
        "id": 42046,
        "name": "Garnay",
        "latitude": "48.70489000",
        "longitude": "1.33706000"
    },
    {
        "id": 42047,
        "name": "Garons",
        "latitude": "43.76861000",
        "longitude": "4.42753000"
    },
    {
        "id": 42049,
        "name": "Gasny",
        "latitude": "49.09184000",
        "longitude": "1.60336000"
    },
    {
        "id": 42050,
        "name": "Gassin",
        "latitude": "43.22882000",
        "longitude": "6.58549000"
    },
    {
        "id": 42051,
        "name": "Gasville-Oisème",
        "latitude": "48.46973000",
        "longitude": "1.53843000"
    },
    {
        "id": 42052,
        "name": "Gattières",
        "latitude": "43.75951000",
        "longitude": "7.17574000"
    },
    {
        "id": 42053,
        "name": "Gauchy",
        "latitude": "49.82765000",
        "longitude": "3.27371000"
    },
    {
        "id": 42054,
        "name": "Gauriaguet",
        "latitude": "45.03904000",
        "longitude": "-0.39191000"
    },
    {
        "id": 42055,
        "name": "Gavray",
        "latitude": "48.91108000",
        "longitude": "-1.35059000"
    },
    {
        "id": 42056,
        "name": "Gazeran",
        "latitude": "48.63264000",
        "longitude": "1.77149000"
    },
    {
        "id": 42058,
        "name": "Geispolsheim",
        "latitude": "48.51603000",
        "longitude": "7.64825000"
    },
    {
        "id": 42059,
        "name": "Gelles",
        "latitude": "45.76947000",
        "longitude": "2.76309000"
    },
    {
        "id": 42060,
        "name": "Gelos",
        "latitude": "43.28333000",
        "longitude": "-0.36667000"
    },
    {
        "id": 42293,
        "name": "Gémenos",
        "latitude": "43.29751000",
        "longitude": "5.62843000"
    },
    {
        "id": 42294,
        "name": "Gémozac",
        "latitude": "45.56896000",
        "longitude": "-0.67574000"
    },
    {
        "id": 42061,
        "name": "Genas",
        "latitude": "45.73131000",
        "longitude": "5.00211000"
    },
    {
        "id": 42062,
        "name": "Genay",
        "latitude": "45.89681000",
        "longitude": "4.84091000"
    },
    {
        "id": 42063,
        "name": "Genech",
        "latitude": "50.53122000",
        "longitude": "3.21651000"
    },
    {
        "id": 42295,
        "name": "Génelard",
        "latitude": "46.58119000",
        "longitude": "4.23619000"
    },
    {
        "id": 42298,
        "name": "Générac",
        "latitude": "43.72830000",
        "longitude": "4.35000000"
    },
    {
        "id": 42064,
        "name": "Geneston",
        "latitude": "47.05639000",
        "longitude": "-1.51139000"
    },
    {
        "id": 42065,
        "name": "Genillé",
        "latitude": "47.18333000",
        "longitude": "1.10000000"
    },
    {
        "id": 42296,
        "name": "Génissac",
        "latitude": "44.85000000",
        "longitude": "-0.25000000"
    },
    {
        "id": 42297,
        "name": "Génissieux",
        "latitude": "45.08413000",
        "longitude": "5.08133000"
    },
    {
        "id": 42066,
        "name": "Genlis",
        "latitude": "47.24203000",
        "longitude": "5.22415000"
    },
    {
        "id": 42067,
        "name": "Gennes",
        "latitude": "47.34010000",
        "longitude": "-0.23149000"
    },
    {
        "id": 42068,
        "name": "Gennevilliers",
        "latitude": "48.93333000",
        "longitude": "2.30000000"
    },
    {
        "id": 42069,
        "name": "Gensac-la-Pallue",
        "latitude": "45.65000000",
        "longitude": "-0.25000000"
    },
    {
        "id": 42070,
        "name": "Gentilly",
        "latitude": "48.81294000",
        "longitude": "2.34170000"
    },
    {
        "id": 42071,
        "name": "Ger",
        "latitude": "43.25000000",
        "longitude": "-0.05000000"
    },
    {
        "id": 42299,
        "name": "Gérardmer",
        "latitude": "48.07346000",
        "longitude": "6.87787000"
    },
    {
        "id": 42072,
        "name": "Gerbéviller",
        "latitude": "48.49618000",
        "longitude": "6.51075000"
    },
    {
        "id": 42073,
        "name": "Gerde",
        "latitude": "43.05567000",
        "longitude": "0.16688000"
    },
    {
        "id": 42074,
        "name": "Gergy",
        "latitude": "46.87557000",
        "longitude": "4.94527000"
    },
    {
        "id": 42075,
        "name": "Gers",
        "latitude": "43.71731000",
        "longitude": "0.45422000"
    },
    {
        "id": 42076,
        "name": "Gerstheim",
        "latitude": "48.38215000",
        "longitude": "7.70395000"
    },
    {
        "id": 42077,
        "name": "Gerzat",
        "latitude": "45.82581000",
        "longitude": "3.14473000"
    },
    {
        "id": 42078,
        "name": "Gespunsart",
        "latitude": "49.82143000",
        "longitude": "4.82926000"
    },
    {
        "id": 42080,
        "name": "Gesté",
        "latitude": "47.18179000",
        "longitude": "-1.10917000"
    },
    {
        "id": 42079,
        "name": "Gestel",
        "latitude": "47.80361000",
        "longitude": "-3.44245000"
    },
    {
        "id": 42300,
        "name": "Gétigné",
        "latitude": "47.07650000",
        "longitude": "-1.24810000"
    },
    {
        "id": 42081,
        "name": "Geudertheim",
        "latitude": "48.72415000",
        "longitude": "7.75188000"
    },
    {
        "id": 42301,
        "name": "Gévezé",
        "latitude": "48.21933000",
        "longitude": "-1.78952000"
    },
    {
        "id": 42082,
        "name": "Gevrey-Chambertin",
        "latitude": "47.22614000",
        "longitude": "4.96806000"
    },
    {
        "id": 42083,
        "name": "Gex",
        "latitude": "46.33323000",
        "longitude": "6.05766000"
    },
    {
        "id": 42084,
        "name": "Ghisonaccia",
        "latitude": "42.01582000",
        "longitude": "9.40507000"
    },
    {
        "id": 42085,
        "name": "Ghyvelde",
        "latitude": "51.05275000",
        "longitude": "2.52642000"
    },
    {
        "id": 42086,
        "name": "Giberville",
        "latitude": "49.18158000",
        "longitude": "-0.28386000"
    },
    {
        "id": 42087,
        "name": "Gidy",
        "latitude": "47.98539000",
        "longitude": "1.83816000"
    },
    {
        "id": 42088,
        "name": "Gien",
        "latitude": "47.69332000",
        "longitude": "2.63094000"
    },
    {
        "id": 42111,
        "name": "Gières",
        "latitude": "45.17997000",
        "longitude": "5.78935000"
    },
    {
        "id": 42112,
        "name": "Gièvres",
        "latitude": "47.27847000",
        "longitude": "1.66943000"
    },
    {
        "id": 42089,
        "name": "Gif-sur-Yvette",
        "latitude": "48.68333000",
        "longitude": "2.13333000"
    },
    {
        "id": 42090,
        "name": "Gigean",
        "latitude": "43.50028000",
        "longitude": "3.71167000"
    },
    {
        "id": 42091,
        "name": "Gignac",
        "latitude": "43.65200000",
        "longitude": "3.55090000"
    },
    {
        "id": 42092,
        "name": "Gignac-la-Nerthe",
        "latitude": "43.39287000",
        "longitude": "5.23586000"
    },
    {
        "id": 42093,
        "name": "Gilette",
        "latitude": "43.84976000",
        "longitude": "7.16346000"
    },
    {
        "id": 42094,
        "name": "Gilley",
        "latitude": "47.04740000",
        "longitude": "6.48257000"
    },
    {
        "id": 42095,
        "name": "Gillonnay",
        "latitude": "45.39251000",
        "longitude": "5.29413000"
    },
    {
        "id": 42096,
        "name": "Gilly-sur-Isère",
        "latitude": "45.65917000",
        "longitude": "6.35096000"
    },
    {
        "id": 42097,
        "name": "Gimont",
        "latitude": "43.62627000",
        "longitude": "0.87655000"
    },
    {
        "id": 42098,
        "name": "Ginasservis",
        "latitude": "43.67088000",
        "longitude": "5.84911000"
    },
    {
        "id": 42099,
        "name": "Ginestas",
        "latitude": "43.26645000",
        "longitude": "2.87038000"
    },
    {
        "id": 42100,
        "name": "Giraumont",
        "latitude": "49.17076000",
        "longitude": "5.91448000"
    },
    {
        "id": 42101,
        "name": "Giromagny",
        "latitude": "47.74272000",
        "longitude": "6.82733000"
    },
    {
        "id": 42102,
        "name": "Gironde",
        "latitude": "44.69306000",
        "longitude": "-0.41400000"
    },
    {
        "id": 42103,
        "name": "Gironde-sur-Dropt",
        "latitude": "44.58333000",
        "longitude": "-0.08333000"
    },
    {
        "id": 42104,
        "name": "Giroussens",
        "latitude": "43.76199000",
        "longitude": "1.77608000"
    },
    {
        "id": 42105,
        "name": "Gisors",
        "latitude": "49.28178000",
        "longitude": "1.78010000"
    },
    {
        "id": 42106,
        "name": "Givenchy-en-Gohelle",
        "latitude": "50.39080000",
        "longitude": "2.77320000"
    },
    {
        "id": 42107,
        "name": "Givet",
        "latitude": "50.13796000",
        "longitude": "4.82545000"
    },
    {
        "id": 42108,
        "name": "Givors",
        "latitude": "45.59063000",
        "longitude": "4.76878000"
    },
    {
        "id": 42109,
        "name": "Givrand",
        "latitude": "46.67083000",
        "longitude": "-1.88612000"
    },
    {
        "id": 42110,
        "name": "Givry",
        "latitude": "46.78202000",
        "longitude": "4.74262000"
    },
    {
        "id": 42113,
        "name": "Gleizé",
        "latitude": "45.98916000",
        "longitude": "4.69708000"
    },
    {
        "id": 42114,
        "name": "Glomel",
        "latitude": "48.22300000",
        "longitude": "-3.39696000"
    },
    {
        "id": 42115,
        "name": "Goderville",
        "latitude": "49.64566000",
        "longitude": "0.36593000"
    },
    {
        "id": 42116,
        "name": "Godewaersvelde",
        "latitude": "50.79399000",
        "longitude": "2.64456000"
    },
    {
        "id": 42302,
        "name": "Gœrsdorf",
        "latitude": "48.95000000",
        "longitude": "7.76667000"
    },
    {
        "id": 42117,
        "name": "Goetzenbruck",
        "latitude": "48.97778000",
        "longitude": "7.37960000"
    },
    {
        "id": 42303,
        "name": "Gœulzin",
        "latitude": "50.31667000",
        "longitude": "3.10000000"
    },
    {
        "id": 42118,
        "name": "Goincourt",
        "latitude": "49.42614000",
        "longitude": "2.03621000"
    },
    {
        "id": 42119,
        "name": "Golbey",
        "latitude": "48.19764000",
        "longitude": "6.43966000"
    },
    {
        "id": 42120,
        "name": "Gometz-la-Ville",
        "latitude": "48.67219000",
        "longitude": "2.12868000"
    },
    {
        "id": 42121,
        "name": "Gometz-le-Châtel",
        "latitude": "48.67837000",
        "longitude": "2.13792000"
    },
    {
        "id": 42122,
        "name": "Gommegnies",
        "latitude": "50.27155000",
        "longitude": "3.70625000"
    },
    {
        "id": 42123,
        "name": "Goncelin",
        "latitude": "45.34428000",
        "longitude": "5.97896000"
    },
    {
        "id": 42124,
        "name": "Gond-Pontouvre",
        "latitude": "45.68333000",
        "longitude": "0.16667000"
    },
    {
        "id": 42125,
        "name": "Gondecourt",
        "latitude": "50.54469000",
        "longitude": "2.98378000"
    },
    {
        "id": 42126,
        "name": "Gondrecourt-le-Château",
        "latitude": "48.51366000",
        "longitude": "5.51058000"
    },
    {
        "id": 42127,
        "name": "Gondreville",
        "latitude": "48.69373000",
        "longitude": "5.96467000"
    },
    {
        "id": 42128,
        "name": "Gondrin",
        "latitude": "43.88466000",
        "longitude": "0.23737000"
    },
    {
        "id": 42129,
        "name": "Gonesse",
        "latitude": "48.98693000",
        "longitude": "2.44892000"
    },
    {
        "id": 42130,
        "name": "Gonfaron",
        "latitude": "43.32036000",
        "longitude": "6.28929000"
    },
    {
        "id": 42131,
        "name": "Gonnehem",
        "latitude": "50.56061000",
        "longitude": "2.57277000"
    },
    {
        "id": 42132,
        "name": "Gonneville-la-Mallet",
        "latitude": "49.63950000",
        "longitude": "0.22245000"
    },
    {
        "id": 42133,
        "name": "Gontaud-de-Nogaret",
        "latitude": "44.45000000",
        "longitude": "0.30000000"
    },
    {
        "id": 42134,
        "name": "Gorbio",
        "latitude": "43.78678000",
        "longitude": "7.44375000"
    },
    {
        "id": 42135,
        "name": "Gorcy",
        "latitude": "49.53487000",
        "longitude": "5.68487000"
    },
    {
        "id": 42136,
        "name": "Gordes",
        "latitude": "43.91290000",
        "longitude": "5.19892000"
    },
    {
        "id": 42137,
        "name": "Gorges",
        "latitude": "47.09917000",
        "longitude": "-1.30024000"
    },
    {
        "id": 42138,
        "name": "Gorron",
        "latitude": "48.41095000",
        "longitude": "-0.81267000"
    },
    {
        "id": 42139,
        "name": "Gorze",
        "latitude": "49.05236000",
        "longitude": "6.00072000"
    },
    {
        "id": 42140,
        "name": "Gosnay",
        "latitude": "50.50622000",
        "longitude": "2.58904000"
    },
    {
        "id": 42141,
        "name": "Gosné",
        "latitude": "48.24674000",
        "longitude": "-1.46558000"
    },
    {
        "id": 42142,
        "name": "Gouaix",
        "latitude": "48.48539000",
        "longitude": "3.29336000"
    },
    {
        "id": 42143,
        "name": "Gouarec",
        "latitude": "48.22725000",
        "longitude": "-3.17994000"
    },
    {
        "id": 42144,
        "name": "Goudargues",
        "latitude": "44.21376000",
        "longitude": "4.46652000"
    },
    {
        "id": 42145,
        "name": "Goudelin",
        "latitude": "48.60375000",
        "longitude": "-3.01842000"
    },
    {
        "id": 42146,
        "name": "Gouesnach",
        "latitude": "47.91040000",
        "longitude": "-4.11450000"
    },
    {
        "id": 42147,
        "name": "Gouesnou",
        "latitude": "48.45267000",
        "longitude": "-4.46456000"
    },
    {
        "id": 42161,
        "name": "Gouézec",
        "latitude": "48.16911000",
        "longitude": "-3.97277000"
    },
    {
        "id": 42148,
        "name": "Goult",
        "latitude": "43.86297000",
        "longitude": "5.24390000"
    },
    {
        "id": 42149,
        "name": "Gourdan-Polignan",
        "latitude": "43.07092000",
        "longitude": "0.57482000"
    },
    {
        "id": 42150,
        "name": "Gourdon",
        "latitude": "44.73742000",
        "longitude": "1.38297000"
    },
    {
        "id": 42151,
        "name": "Gourin",
        "latitude": "48.13866000",
        "longitude": "-3.60755000"
    },
    {
        "id": 42152,
        "name": "Gournay-en-Bray",
        "latitude": "49.48285000",
        "longitude": "1.72471000"
    },
    {
        "id": 42153,
        "name": "Gournay-sur-Marne",
        "latitude": "48.86223000",
        "longitude": "2.58452000"
    },
    {
        "id": 42154,
        "name": "Goussainville",
        "latitude": "49.01367000",
        "longitude": "2.46595000"
    },
    {
        "id": 42155,
        "name": "Gouvernes",
        "latitude": "48.86007000",
        "longitude": "2.69074000"
    },
    {
        "id": 42156,
        "name": "Gouvieux",
        "latitude": "49.18705000",
        "longitude": "2.41439000"
    },
    {
        "id": 42157,
        "name": "Gouville-sur-Mer",
        "latitude": "49.09611000",
        "longitude": "-1.57908000"
    },
    {
        "id": 42158,
        "name": "Gouy-sous-Bellonne",
        "latitude": "50.31139000",
        "longitude": "3.05657000"
    },
    {
        "id": 42159,
        "name": "Gouzeaucourt",
        "latitude": "50.05606000",
        "longitude": "3.12351000"
    },
    {
        "id": 42160,
        "name": "Gouzon",
        "latitude": "46.19286000",
        "longitude": "2.23876000"
    },
    {
        "id": 42162,
        "name": "Goven",
        "latitude": "48.00638000",
        "longitude": "-1.84629000"
    },
    {
        "id": 42163,
        "name": "Grabels",
        "latitude": "43.64797000",
        "longitude": "3.79865000"
    },
    {
        "id": 42194,
        "name": "Graçay",
        "latitude": "47.14371000",
        "longitude": "1.84733000"
    },
    {
        "id": 42232,
        "name": "Grâces",
        "latitude": "48.55651000",
        "longitude": "-3.18533000"
    },
    {
        "id": 42164,
        "name": "Gradignan",
        "latitude": "44.77262000",
        "longitude": "-0.61393000"
    },
    {
        "id": 42165,
        "name": "Gragnague",
        "latitude": "43.68091000",
        "longitude": "1.58461000"
    },
    {
        "id": 42166,
        "name": "Grainville-la-Teinturière",
        "latitude": "49.74773000",
        "longitude": "0.64048000"
    },
    {
        "id": 42167,
        "name": "Grainville-sur-Odon",
        "latitude": "49.13936000",
        "longitude": "-0.53046000"
    },
    {
        "id": 42168,
        "name": "Gramat",
        "latitude": "44.78075000",
        "longitude": "1.71957000"
    },
    {
        "id": 42169,
        "name": "Grambois",
        "latitude": "43.76233000",
        "longitude": "5.58860000"
    },
    {
        "id": 42170,
        "name": "Grand-Champ",
        "latitude": "47.75804000",
        "longitude": "-2.84635000"
    },
    {
        "id": 42171,
        "name": "Grand-Charmont",
        "latitude": "47.52674000",
        "longitude": "6.82604000"
    },
    {
        "id": 42172,
        "name": "Grand-Couronne",
        "latitude": "49.35563000",
        "longitude": "1.00647000"
    },
    {
        "id": 42173,
        "name": "Grand-Fort-Philippe",
        "latitude": "50.99961000",
        "longitude": "2.10784000"
    },
    {
        "id": 42174,
        "name": "Grand-Fougeray",
        "latitude": "47.72390000",
        "longitude": "-1.73210000"
    },
    {
        "id": 42175,
        "name": "Grandcamp-Maisy",
        "latitude": "49.38333000",
        "longitude": "-1.03333000"
    },
    {
        "id": 42176,
        "name": "Grande-Synthe",
        "latitude": "51.01540000",
        "longitude": "2.29975000"
    },
    {
        "id": 42177,
        "name": "Grandfontaine",
        "latitude": "47.19763000",
        "longitude": "5.90079000"
    },
    {
        "id": 42178,
        "name": "Grandfresnoy",
        "latitude": "49.37218000",
        "longitude": "2.65216000"
    },
    {
        "id": 42179,
        "name": "Grandris",
        "latitude": "46.03780000",
        "longitude": "4.47526000"
    },
    {
        "id": 42180,
        "name": "Grandvillars",
        "latitude": "47.53929000",
        "longitude": "6.97100000"
    },
    {
        "id": 42181,
        "name": "Grandvilliers",
        "latitude": "49.66547000",
        "longitude": "1.94088000"
    },
    {
        "id": 42182,
        "name": "Grane",
        "latitude": "44.73255000",
        "longitude": "4.92203000"
    },
    {
        "id": 42183,
        "name": "Granges-sur-Vologne",
        "latitude": "48.14517000",
        "longitude": "6.79095000"
    },
    {
        "id": 42184,
        "name": "Grans",
        "latitude": "43.60862000",
        "longitude": "5.06290000"
    },
    {
        "id": 42185,
        "name": "Granville",
        "latitude": "48.83792000",
        "longitude": "-1.59714000"
    },
    {
        "id": 42186,
        "name": "Grasse",
        "latitude": "43.65783000",
        "longitude": "6.92537000"
    },
    {
        "id": 42187,
        "name": "Gratentour",
        "latitude": "43.72360000",
        "longitude": "1.43234000"
    },
    {
        "id": 42188,
        "name": "Graulhet",
        "latitude": "43.76688000",
        "longitude": "1.98938000"
    },
    {
        "id": 42189,
        "name": "Gravelines",
        "latitude": "50.98651000",
        "longitude": "2.12807000"
    },
    {
        "id": 42190,
        "name": "Graveson",
        "latitude": "43.85051000",
        "longitude": "4.77361000"
    },
    {
        "id": 42191,
        "name": "Gravigny",
        "latitude": "49.05310000",
        "longitude": "1.16962000"
    },
    {
        "id": 42192,
        "name": "Gray",
        "latitude": "47.44575000",
        "longitude": "5.59215000"
    },
    {
        "id": 42193,
        "name": "Gray-la-Ville",
        "latitude": "47.43932000",
        "longitude": "5.57252000"
    },
    {
        "id": 42233,
        "name": "Gréasque",
        "latitude": "43.43310000",
        "longitude": "5.54449000"
    },
    {
        "id": 42195,
        "name": "Grenade",
        "latitude": "43.76667000",
        "longitude": "1.28333000"
    },
    {
        "id": 42196,
        "name": "Grenade-sur-l’Adour",
        "latitude": "43.77753000",
        "longitude": "-0.42961000"
    },
    {
        "id": 42197,
        "name": "Grenay",
        "latitude": "45.66347000",
        "longitude": "5.08031000"
    },
    {
        "id": 42198,
        "name": "Grenay",
        "latitude": "50.44962000",
        "longitude": "2.75168000"
    },
    {
        "id": 42199,
        "name": "Grendelbruch",
        "latitude": "48.49272000",
        "longitude": "7.32239000"
    },
    {
        "id": 42200,
        "name": "Grenoble",
        "latitude": "45.17869000",
        "longitude": "5.71479000"
    },
    {
        "id": 42234,
        "name": "Gréoux-les-Bains",
        "latitude": "43.75844000",
        "longitude": "5.88351000"
    },
    {
        "id": 42201,
        "name": "Gresswiller",
        "latitude": "48.53542000",
        "longitude": "7.43251000"
    },
    {
        "id": 42235,
        "name": "Grésy-sur-Aix",
        "latitude": "45.72311000",
        "longitude": "5.92522000"
    },
    {
        "id": 42236,
        "name": "Grésy-sur-Isère",
        "latitude": "45.59898000",
        "longitude": "6.25358000"
    },
    {
        "id": 42202,
        "name": "Gretz-Armainvilliers",
        "latitude": "48.74120000",
        "longitude": "2.73105000"
    },
    {
        "id": 42203,
        "name": "Grez-Neuville",
        "latitude": "47.60288000",
        "longitude": "-0.68193000"
    },
    {
        "id": 42204,
        "name": "Grez-sur-Loing",
        "latitude": "48.31754000",
        "longitude": "2.68848000"
    },
    {
        "id": 42237,
        "name": "Grézieu-la-Varenne",
        "latitude": "45.74736000",
        "longitude": "4.69037000"
    },
    {
        "id": 42217,
        "name": "Grièges",
        "latitude": "46.25619000",
        "longitude": "4.84856000"
    },
    {
        "id": 42205,
        "name": "Gries",
        "latitude": "48.75370000",
        "longitude": "7.81403000"
    },
    {
        "id": 42206,
        "name": "Griesheim-près-Molsheim",
        "latitude": "48.50296000",
        "longitude": "7.53027000"
    },
    {
        "id": 42207,
        "name": "Griesheim-sur-Souffel",
        "latitude": "48.63591000",
        "longitude": "7.66923000"
    },
    {
        "id": 42208,
        "name": "Grignan",
        "latitude": "44.41967000",
        "longitude": "4.90785000"
    },
    {
        "id": 42209,
        "name": "Grignols",
        "latitude": "44.38842000",
        "longitude": "-0.04287000"
    },
    {
        "id": 42210,
        "name": "Grignon",
        "latitude": "45.65122000",
        "longitude": "6.37795000"
    },
    {
        "id": 42211,
        "name": "Grigny",
        "latitude": "45.60843000",
        "longitude": "4.78976000"
    },
    {
        "id": 42212,
        "name": "Grigny",
        "latitude": "48.65412000",
        "longitude": "2.39343000"
    },
    {
        "id": 42213,
        "name": "Grillon",
        "latitude": "44.39508000",
        "longitude": "4.92954000"
    },
    {
        "id": 42214,
        "name": "Grimaud",
        "latitude": "43.27329000",
        "longitude": "6.52171000"
    },
    {
        "id": 42215,
        "name": "Grisolles",
        "latitude": "43.82920000",
        "longitude": "1.29673000"
    },
    {
        "id": 42216,
        "name": "Grisy-Suisnes",
        "latitude": "48.68538000",
        "longitude": "2.66781000"
    },
    {
        "id": 42218,
        "name": "Groffliers",
        "latitude": "50.38444000",
        "longitude": "1.61474000"
    },
    {
        "id": 42219,
        "name": "Groissiat",
        "latitude": "46.22213000",
        "longitude": "5.60775000"
    },
    {
        "id": 42220,
        "name": "Groisy",
        "latitude": "46.01008000",
        "longitude": "6.16983000"
    },
    {
        "id": 42221,
        "name": "Groix",
        "latitude": "47.63887000",
        "longitude": "-3.45430000"
    },
    {
        "id": 42222,
        "name": "Gron",
        "latitude": "48.15967000",
        "longitude": "3.26345000"
    },
    {
        "id": 42223,
        "name": "Gros-Réderching",
        "latitude": "49.06835000",
        "longitude": "7.21914000"
    },
    {
        "id": 42224,
        "name": "Grosbliederstroff",
        "latitude": "49.15808000",
        "longitude": "7.02413000"
    },
    {
        "id": 42225,
        "name": "Grosbreuil",
        "latitude": "46.53946000",
        "longitude": "-1.61655000"
    },
    {
        "id": 42226,
        "name": "Groslay",
        "latitude": "48.98561000",
        "longitude": "2.34736000"
    },
    {
        "id": 42227,
        "name": "Grosseto-Prugna",
        "latitude": "41.87097000",
        "longitude": "8.96403000"
    },
    {
        "id": 42228,
        "name": "Gruchet-le-Valasse",
        "latitude": "49.55466000",
        "longitude": "0.48684000"
    },
    {
        "id": 42229,
        "name": "Gruffy",
        "latitude": "45.78897000",
        "longitude": "6.05648000"
    },
    {
        "id": 42230,
        "name": "Gruissan",
        "latitude": "43.10759000",
        "longitude": "3.08651000"
    },
    {
        "id": 42231,
        "name": "Gruson",
        "latitude": "50.59583000",
        "longitude": "3.20777000"
    },
    {
        "id": 42238,
        "name": "Guarbecque",
        "latitude": "50.61162000",
        "longitude": "2.48895000"
    },
    {
        "id": 42239,
        "name": "Guebwiller",
        "latitude": "47.91667000",
        "longitude": "7.20000000"
    },
    {
        "id": 42277,
        "name": "Guécélard",
        "latitude": "47.87751000",
        "longitude": "0.12930000"
    },
    {
        "id": 42278,
        "name": "Guégon",
        "latitude": "47.93707000",
        "longitude": "-2.56450000"
    },
    {
        "id": 42279,
        "name": "Guémar",
        "latitude": "48.18869000",
        "longitude": "7.39706000"
    },
    {
        "id": 42280,
        "name": "Guémené-Penfao",
        "latitude": "47.63333000",
        "longitude": "-1.83333000"
    },
    {
        "id": 42281,
        "name": "Guémené-sur-Scorff",
        "latitude": "48.06667000",
        "longitude": "-3.20000000"
    },
    {
        "id": 42282,
        "name": "Guénange",
        "latitude": "49.29945000",
        "longitude": "6.20535000"
    },
    {
        "id": 42240,
        "name": "Guengat",
        "latitude": "48.04193000",
        "longitude": "-4.20470000"
    },
    {
        "id": 42283,
        "name": "Guénin",
        "latitude": "47.90757000",
        "longitude": "-2.97941000"
    },
    {
        "id": 42241,
        "name": "Guenrouet",
        "latitude": "47.51881000",
        "longitude": "-1.95381000"
    },
    {
        "id": 42242,
        "name": "Guer",
        "latitude": "47.90619000",
        "longitude": "-2.12314000"
    },
    {
        "id": 42284,
        "name": "Guérande",
        "latitude": "47.32911000",
        "longitude": "-2.42829000"
    },
    {
        "id": 42285,
        "name": "Guérard",
        "latitude": "48.82086000",
        "longitude": "2.95969000"
    },
    {
        "id": 42286,
        "name": "Guéreins",
        "latitude": "46.10346000",
        "longitude": "4.77275000"
    },
    {
        "id": 42287,
        "name": "Guéret",
        "latitude": "46.17234000",
        "longitude": "1.87456000"
    },
    {
        "id": 42288,
        "name": "Guérigny",
        "latitude": "47.08703000",
        "longitude": "3.20182000"
    },
    {
        "id": 42243,
        "name": "Guerlesquin",
        "latitude": "48.51758000",
        "longitude": "-3.58583000"
    },
    {
        "id": 42244,
        "name": "Guermantes",
        "latitude": "48.85303000",
        "longitude": "2.70495000"
    },
    {
        "id": 42245,
        "name": "Guern",
        "latitude": "48.02964000",
        "longitude": "-3.09145000"
    },
    {
        "id": 42246,
        "name": "Guerville",
        "latitude": "48.94388000",
        "longitude": "1.73429000"
    },
    {
        "id": 42247,
        "name": "Guesnain",
        "latitude": "50.35000000",
        "longitude": "3.15000000"
    },
    {
        "id": 42289,
        "name": "Guéthary",
        "latitude": "43.42285000",
        "longitude": "-1.61073000"
    },
    {
        "id": 42248,
        "name": "Gueugnon",
        "latitude": "46.60357000",
        "longitude": "4.06286000"
    },
    {
        "id": 42249,
        "name": "Gueux",
        "latitude": "49.25048000",
        "longitude": "3.91024000"
    },
    {
        "id": 42250,
        "name": "Guewenheim",
        "latitude": "47.75283000",
        "longitude": "7.09253000"
    },
    {
        "id": 42251,
        "name": "Guichainville",
        "latitude": "49.00139000",
        "longitude": "1.19305000"
    },
    {
        "id": 42252,
        "name": "Guichen",
        "latitude": "47.96762000",
        "longitude": "-1.79534000"
    },
    {
        "id": 42253,
        "name": "Guiclan",
        "latitude": "48.54967000",
        "longitude": "-3.96211000"
    },
    {
        "id": 42254,
        "name": "Guidel-Plage",
        "latitude": "47.76768000",
        "longitude": "-3.52180000"
    },
    {
        "id": 42255,
        "name": "Guignen",
        "latitude": "47.92009000",
        "longitude": "-1.86161000"
    },
    {
        "id": 42256,
        "name": "Guignes",
        "latitude": "48.63333000",
        "longitude": "2.80000000"
    },
    {
        "id": 42257,
        "name": "Guignicourt",
        "latitude": "49.43714000",
        "longitude": "3.96755000"
    },
    {
        "id": 42258,
        "name": "Guilers",
        "latitude": "48.42545000",
        "longitude": "-4.55801000"
    },
    {
        "id": 42259,
        "name": "Guilherand-Granges",
        "latitude": "44.93278000",
        "longitude": "4.87372000"
    },
    {
        "id": 42260,
        "name": "Guillac",
        "latitude": "47.91096000",
        "longitude": "-2.46571000"
    },
    {
        "id": 42261,
        "name": "Guillestre",
        "latitude": "44.65950000",
        "longitude": "6.64948000"
    },
    {
        "id": 42262,
        "name": "Guilliers",
        "latitude": "48.04286000",
        "longitude": "-2.40562000"
    },
    {
        "id": 42263,
        "name": "Guilvinec",
        "latitude": "47.79861000",
        "longitude": "-4.28111000"
    },
    {
        "id": 42290,
        "name": "Guînes",
        "latitude": "50.86708000",
        "longitude": "1.87025000"
    },
    {
        "id": 42264,
        "name": "Guingamp",
        "latitude": "48.56251000",
        "longitude": "-3.15096000"
    },
    {
        "id": 42265,
        "name": "Guipavas",
        "latitude": "48.43522000",
        "longitude": "-4.39722000"
    },
    {
        "id": 42266,
        "name": "Guipel",
        "latitude": "48.29937000",
        "longitude": "-1.71866000"
    },
    {
        "id": 42267,
        "name": "Guipry",
        "latitude": "47.82637000",
        "longitude": "-1.84218000"
    },
    {
        "id": 42268,
        "name": "Guiscard",
        "latitude": "49.65660000",
        "longitude": "3.05127000"
    },
    {
        "id": 42269,
        "name": "Guiscriff",
        "latitude": "48.04944000",
        "longitude": "-3.64356000"
    },
    {
        "id": 42270,
        "name": "Guise",
        "latitude": "49.90055000",
        "longitude": "3.62801000"
    },
    {
        "id": 42271,
        "name": "Guissény",
        "latitude": "48.63292000",
        "longitude": "-4.40884000"
    },
    {
        "id": 42291,
        "name": "Guîtres",
        "latitude": "45.03333000",
        "longitude": "-0.18333000"
    },
    {
        "id": 42272,
        "name": "Gujan-Mestras",
        "latitude": "44.63333000",
        "longitude": "-1.06667000"
    },
    {
        "id": 42273,
        "name": "Gumbrechtshoffen",
        "latitude": "48.90580000",
        "longitude": "7.62915000"
    },
    {
        "id": 42274,
        "name": "Gundershoffen",
        "latitude": "48.90648000",
        "longitude": "7.66096000"
    },
    {
        "id": 42275,
        "name": "Gurgy",
        "latitude": "47.86712000",
        "longitude": "3.56341000"
    },
    {
        "id": 42276,
        "name": "Guyancourt",
        "latitude": "48.77334000",
        "longitude": "2.07393000"
    },
    {
        "id": 42292,
        "name": "Gy",
        "latitude": "47.40588000",
        "longitude": "5.81226000"
    },
    {
        "id": 42304,
        "name": "Habas",
        "latitude": "43.57180000",
        "longitude": "-0.92976000"
    },
    {
        "id": 42305,
        "name": "Habsheim",
        "latitude": "47.72975000",
        "longitude": "7.41801000"
    },
    {
        "id": 42306,
        "name": "Hadol",
        "latitude": "48.08983000",
        "longitude": "6.47662000"
    },
    {
        "id": 42307,
        "name": "Hagenthal-le-Bas",
        "latitude": "47.52497000",
        "longitude": "7.47783000"
    },
    {
        "id": 42308,
        "name": "Hagetmau",
        "latitude": "43.65893000",
        "longitude": "-0.59172000"
    },
    {
        "id": 42309,
        "name": "Hagondange",
        "latitude": "49.24879000",
        "longitude": "6.16374000"
    },
    {
        "id": 42310,
        "name": "Haguenau",
        "latitude": "48.81557000",
        "longitude": "7.79051000"
    },
    {
        "id": 42311,
        "name": "Haillicourt",
        "latitude": "50.47519000",
        "longitude": "2.57458000"
    },
    {
        "id": 42312,
        "name": "Haisnes",
        "latitude": "50.50776000",
        "longitude": "2.79416000"
    },
    {
        "id": 42313,
        "name": "Hallencourt",
        "latitude": "49.99201000",
        "longitude": "1.87649000"
    },
    {
        "id": 42314,
        "name": "Hallennes-lez-Haubourdin",
        "latitude": "50.61667000",
        "longitude": "2.96667000"
    },
    {
        "id": 42315,
        "name": "Hallines",
        "latitude": "50.71087000",
        "longitude": "2.22629000"
    },
    {
        "id": 42316,
        "name": "Halluin",
        "latitude": "50.78628000",
        "longitude": "3.12611000"
    },
    {
        "id": 42317,
        "name": "Ham",
        "latitude": "49.74721000",
        "longitude": "3.07301000"
    },
    {
        "id": 42318,
        "name": "Ham-sous-Varsberg",
        "latitude": "49.18014000",
        "longitude": "6.64702000"
    },
    {
        "id": 42319,
        "name": "Hambach",
        "latitude": "49.05942000",
        "longitude": "7.03530000"
    },
    {
        "id": 42320,
        "name": "Hambye",
        "latitude": "48.94798000",
        "longitude": "-1.26417000"
    },
    {
        "id": 42321,
        "name": "Hames-Boucres",
        "latitude": "50.88178000",
        "longitude": "1.84338000"
    },
    {
        "id": 42322,
        "name": "Hanches",
        "latitude": "48.60115000",
        "longitude": "1.65137000"
    },
    {
        "id": 42323,
        "name": "Hangenbieten",
        "latitude": "48.55985000",
        "longitude": "7.61464000"
    },
    {
        "id": 42324,
        "name": "Hanvec",
        "latitude": "48.32692000",
        "longitude": "-4.15951000"
    },
    {
        "id": 42325,
        "name": "Harbonnières",
        "latitude": "49.84958000",
        "longitude": "2.66812000"
    },
    {
        "id": 42326,
        "name": "Hardinghen",
        "latitude": "50.80414000",
        "longitude": "1.82122000"
    },
    {
        "id": 42327,
        "name": "Hardricourt",
        "latitude": "49.00779000",
        "longitude": "1.89389000"
    },
    {
        "id": 42328,
        "name": "Harfleur",
        "latitude": "49.50660000",
        "longitude": "0.19827000"
    },
    {
        "id": 42329,
        "name": "Hargarten-aux-Mines",
        "latitude": "49.22436000",
        "longitude": "6.61459000"
    },
    {
        "id": 42330,
        "name": "Harly",
        "latitude": "49.83766000",
        "longitude": "3.31001000"
    },
    {
        "id": 42331,
        "name": "Harnes",
        "latitude": "50.44643000",
        "longitude": "2.90481000"
    },
    {
        "id": 42332,
        "name": "Hasnon",
        "latitude": "50.42472000",
        "longitude": "3.38657000"
    },
    {
        "id": 42333,
        "name": "Hasparren",
        "latitude": "43.38377000",
        "longitude": "-1.30499000"
    },
    {
        "id": 42334,
        "name": "Haspres",
        "latitude": "50.25829000",
        "longitude": "3.41695000"
    },
    {
        "id": 42335,
        "name": "Hatten",
        "latitude": "48.90123000",
        "longitude": "7.97968000"
    },
    {
        "id": 42336,
        "name": "Haubourdin",
        "latitude": "50.60826000",
        "longitude": "2.99143000"
    },
    {
        "id": 42337,
        "name": "Haucourt",
        "latitude": "49.48982000",
        "longitude": "5.80361000"
    },
    {
        "id": 42338,
        "name": "Haulchin",
        "latitude": "50.31720000",
        "longitude": "3.43599000"
    },
    {
        "id": 42339,
        "name": "Haussy",
        "latitude": "50.21830000",
        "longitude": "3.47683000"
    },
    {
        "id": 42340,
        "name": "Haut-Rhin",
        "latitude": "47.87603000",
        "longitude": "7.27190000"
    },
    {
        "id": 42341,
        "name": "Haute-Garonne",
        "latitude": "43.38724000",
        "longitude": "1.22191000"
    },
    {
        "id": 42342,
        "name": "Haute-Goulaine",
        "latitude": "47.19905000",
        "longitude": "-1.42941000"
    },
    {
        "id": 42343,
        "name": "Haute-Loire",
        "latitude": "45.11001000",
        "longitude": "3.83940000"
    },
    {
        "id": 42344,
        "name": "Haute-Marne",
        "latitude": "48.12019000",
        "longitude": "5.24038000"
    },
    {
        "id": 42345,
        "name": "Haute-Rivoire",
        "latitude": "45.71559000",
        "longitude": "4.39633000"
    },
    {
        "id": 42346,
        "name": "Haute-Savoie",
        "latitude": "46.06143000",
        "longitude": "6.45374000"
    },
    {
        "id": 42347,
        "name": "Haute-Vienne",
        "latitude": "45.88341000",
        "longitude": "1.21781000"
    },
    {
        "id": 42348,
        "name": "Hautefort",
        "latitude": "45.25953000",
        "longitude": "1.14879000"
    },
    {
        "id": 42349,
        "name": "Hauterive",
        "latitude": "46.08834000",
        "longitude": "3.44626000"
    },
    {
        "id": 42350,
        "name": "Hauterives",
        "latitude": "45.25565000",
        "longitude": "5.01904000"
    },
    {
        "id": 42351,
        "name": "Hautes-Alpes",
        "latitude": "44.68055000",
        "longitude": "6.28969000"
    },
    {
        "id": 42353,
        "name": "Hauteville-lès-Dijon",
        "latitude": "47.36602000",
        "longitude": "4.99375000"
    },
    {
        "id": 42352,
        "name": "Hauteville-Lompnes",
        "latitude": "45.97794000",
        "longitude": "5.60114000"
    },
    {
        "id": 42354,
        "name": "Hautmont",
        "latitude": "50.25077000",
        "longitude": "3.92143000"
    },
    {
        "id": 42355,
        "name": "Hautot-sur-Mer",
        "latitude": "49.89789000",
        "longitude": "1.02884000"
    },
    {
        "id": 42356,
        "name": "Hauts-de-Seine",
        "latitude": "48.85000000",
        "longitude": "2.19293000"
    },
    {
        "id": 42357,
        "name": "Hauville",
        "latitude": "49.39645000",
        "longitude": "0.77232000"
    },
    {
        "id": 42358,
        "name": "Haveluy",
        "latitude": "50.35289000",
        "longitude": "3.40389000"
    },
    {
        "id": 42359,
        "name": "Haverskerque",
        "latitude": "50.64089000",
        "longitude": "2.54162000"
    },
    {
        "id": 42360,
        "name": "Hayange",
        "latitude": "49.32881000",
        "longitude": "6.06278000"
    },
    {
        "id": 42361,
        "name": "Haybes",
        "latitude": "50.00458000",
        "longitude": "4.70496000"
    },
    {
        "id": 42362,
        "name": "Hazebrouck",
        "latitude": "50.72374000",
        "longitude": "2.53729000"
    },
    {
        "id": 42449,
        "name": "Hédé-Bazouges",
        "latitude": "48.30000000",
        "longitude": "-1.80000000"
    },
    {
        "id": 42450,
        "name": "Hégenheim",
        "latitude": "47.56299000",
        "longitude": "7.52401000"
    },
    {
        "id": 42363,
        "name": "Heillecourt",
        "latitude": "48.65049000",
        "longitude": "6.19512000"
    },
    {
        "id": 42364,
        "name": "Heimsbrunn",
        "latitude": "47.72669000",
        "longitude": "7.22699000"
    },
    {
        "id": 42451,
        "name": "Hélesmes",
        "latitude": "50.37036000",
        "longitude": "3.35918000"
    },
    {
        "id": 42365,
        "name": "Helfaut",
        "latitude": "50.69783000",
        "longitude": "2.24234000"
    },
    {
        "id": 42366,
        "name": "Hem",
        "latitude": "50.65256000",
        "longitude": "3.18681000"
    },
    {
        "id": 42452,
        "name": "Hénanbihen",
        "latitude": "48.56072000",
        "longitude": "-2.37602000"
    },
    {
        "id": 42367,
        "name": "Hendaye",
        "latitude": "43.37172000",
        "longitude": "-1.77382000"
    },
    {
        "id": 42453,
        "name": "Hénin-Beaumont",
        "latitude": "50.41359000",
        "longitude": "2.96485000"
    },
    {
        "id": 42368,
        "name": "Hennebont",
        "latitude": "47.80512000",
        "longitude": "-3.27337000"
    },
    {
        "id": 42454,
        "name": "Hénon",
        "latitude": "48.38433000",
        "longitude": "-2.68334000"
    },
    {
        "id": 42455,
        "name": "Hénouville",
        "latitude": "49.47890000",
        "longitude": "0.96367000"
    },
    {
        "id": 42369,
        "name": "Henrichemont",
        "latitude": "47.30333000",
        "longitude": "2.52458000"
    },
    {
        "id": 42370,
        "name": "Henvic",
        "latitude": "48.63256000",
        "longitude": "-3.92623000"
    },
    {
        "id": 42371,
        "name": "Herbault",
        "latitude": "47.60501000",
        "longitude": "1.13934000"
    },
    {
        "id": 42372,
        "name": "Herbeys",
        "latitude": "45.14012000",
        "longitude": "5.79289000"
    },
    {
        "id": 42373,
        "name": "Herbignac",
        "latitude": "47.44822000",
        "longitude": "-2.31810000"
    },
    {
        "id": 42374,
        "name": "Herbitzheim",
        "latitude": "49.01541000",
        "longitude": "7.08188000"
    },
    {
        "id": 42375,
        "name": "Herblay",
        "latitude": "48.98994000",
        "longitude": "2.16990000"
    },
    {
        "id": 42464,
        "name": "Hérépian",
        "latitude": "43.59300000",
        "longitude": "3.11595000"
    },
    {
        "id": 42376,
        "name": "Hergnies",
        "latitude": "50.47287000",
        "longitude": "3.52612000"
    },
    {
        "id": 42456,
        "name": "Héric",
        "latitude": "47.41417000",
        "longitude": "-1.65309000"
    },
    {
        "id": 42457,
        "name": "Héricourt",
        "latitude": "47.57305000",
        "longitude": "6.76139000"
    },
    {
        "id": 42458,
        "name": "Héricy",
        "latitude": "48.44848000",
        "longitude": "2.76445000"
    },
    {
        "id": 42459,
        "name": "Hérimoncourt",
        "latitude": "47.44284000",
        "longitude": "6.88242000"
    },
    {
        "id": 42460,
        "name": "Hérin",
        "latitude": "50.35557000",
        "longitude": "3.45309000"
    },
    {
        "id": 42377,
        "name": "Herlies",
        "latitude": "50.57753000",
        "longitude": "2.85285000"
    },
    {
        "id": 42378,
        "name": "Hermanville-sur-Mer",
        "latitude": "49.28464000",
        "longitude": "-0.31544000"
    },
    {
        "id": 42379,
        "name": "Hermes",
        "latitude": "49.36032000",
        "longitude": "2.24461000"
    },
    {
        "id": 42380,
        "name": "Hermies",
        "latitude": "50.11126000",
        "longitude": "3.04202000"
    },
    {
        "id": 42381,
        "name": "Hermonville",
        "latitude": "49.33655000",
        "longitude": "3.91011000"
    },
    {
        "id": 42461,
        "name": "Hérouville-Saint-Clair",
        "latitude": "49.21088000",
        "longitude": "-0.30653000"
    },
    {
        "id": 42462,
        "name": "Hérouvillette",
        "latitude": "49.22130000",
        "longitude": "-0.24348000"
    },
    {
        "id": 42382,
        "name": "Herrlisheim",
        "latitude": "48.73049000",
        "longitude": "7.90535000"
    },
    {
        "id": 42383,
        "name": "Herrlisheim-près-Colmar",
        "latitude": "48.01835000",
        "longitude": "7.32668000"
    },
    {
        "id": 42384,
        "name": "Herry",
        "latitude": "47.21667000",
        "longitude": "2.95385000"
    },
    {
        "id": 42385,
        "name": "Herserange",
        "latitude": "49.52047000",
        "longitude": "5.78994000"
    },
    {
        "id": 42386,
        "name": "Hersin-Coupigny",
        "latitude": "50.44796000",
        "longitude": "2.64940000"
    },
    {
        "id": 42463,
        "name": "Héry",
        "latitude": "47.90146000",
        "longitude": "3.62868000"
    },
    {
        "id": 42387,
        "name": "Herzeele",
        "latitude": "50.88584000",
        "longitude": "2.53510000"
    },
    {
        "id": 42388,
        "name": "Hesdin",
        "latitude": "50.37349000",
        "longitude": "2.03820000"
    },
    {
        "id": 42465,
        "name": "Hésingue",
        "latitude": "47.57818000",
        "longitude": "7.51911000"
    },
    {
        "id": 42389,
        "name": "Hettange-Grande",
        "latitude": "49.40650000",
        "longitude": "6.15233000"
    },
    {
        "id": 42390,
        "name": "Heudreville-sur-Eure",
        "latitude": "49.14089000",
        "longitude": "1.18813000"
    },
    {
        "id": 42391,
        "name": "Heugas",
        "latitude": "43.64334000",
        "longitude": "-1.08143000"
    },
    {
        "id": 42392,
        "name": "Heuringhem",
        "latitude": "50.69638000",
        "longitude": "2.28333000"
    },
    {
        "id": 42393,
        "name": "Heyrieux",
        "latitude": "45.63186000",
        "longitude": "5.06284000"
    },
    {
        "id": 42405,
        "name": "Hières-sur-Amby",
        "latitude": "45.79654000",
        "longitude": "5.29333000"
    },
    {
        "id": 42394,
        "name": "Hiersac",
        "latitude": "45.66667000",
        "longitude": "0.00000000"
    },
    {
        "id": 42395,
        "name": "Hillion",
        "latitude": "48.51429000",
        "longitude": "-2.66634000"
    },
    {
        "id": 42396,
        "name": "Hilsenheim",
        "latitude": "48.28761000",
        "longitude": "7.56348000"
    },
    {
        "id": 42397,
        "name": "Hindisheim",
        "latitude": "48.46837000",
        "longitude": "7.63859000"
    },
    {
        "id": 42398,
        "name": "Hinges",
        "latitude": "50.56674000",
        "longitude": "2.62160000"
    },
    {
        "id": 42399,
        "name": "Hinx",
        "latitude": "43.70190000",
        "longitude": "-0.92591000"
    },
    {
        "id": 42400,
        "name": "Hirel",
        "latitude": "48.60502000",
        "longitude": "-1.80224000"
    },
    {
        "id": 42401,
        "name": "Hirsingue",
        "latitude": "47.58768000",
        "longitude": "7.25299000"
    },
    {
        "id": 42402,
        "name": "Hirson",
        "latitude": "49.92262000",
        "longitude": "4.08259000"
    },
    {
        "id": 42403,
        "name": "Hirtzbach",
        "latitude": "47.59839000",
        "longitude": "7.22222000"
    },
    {
        "id": 42404,
        "name": "Hirtzfelden",
        "latitude": "47.91131000",
        "longitude": "7.44610000"
    },
    {
        "id": 42406,
        "name": "Hochfelden",
        "latitude": "48.75738000",
        "longitude": "7.56769000"
    },
    {
        "id": 42407,
        "name": "Hochstatt",
        "latitude": "47.70389000",
        "longitude": "7.27650000"
    },
    {
        "id": 42467,
        "name": "Hœnheim",
        "latitude": "48.62224000",
        "longitude": "7.75549000"
    },
    {
        "id": 42468,
        "name": "Hœrdt",
        "latitude": "48.70000000",
        "longitude": "7.78333000"
    },
    {
        "id": 42408,
        "name": "Holnon",
        "latitude": "49.86086000",
        "longitude": "3.21509000"
    },
    {
        "id": 42409,
        "name": "Holtzheim",
        "latitude": "48.55820000",
        "longitude": "7.64434000"
    },
    {
        "id": 42410,
        "name": "Holtzwihr",
        "latitude": "48.11288000",
        "longitude": "7.42080000"
    },
    {
        "id": 42411,
        "name": "Holving",
        "latitude": "49.01162000",
        "longitude": "6.96556000"
    },
    {
        "id": 42412,
        "name": "Hombleux",
        "latitude": "49.73942000",
        "longitude": "2.98574000"
    },
    {
        "id": 42413,
        "name": "Homblières",
        "latitude": "49.85031000",
        "longitude": "3.36456000"
    },
    {
        "id": 42414,
        "name": "Hombourg-Haut",
        "latitude": "49.12819000",
        "longitude": "6.77066000"
    },
    {
        "id": 42415,
        "name": "Homécourt",
        "latitude": "49.22071000",
        "longitude": "5.99242000"
    },
    {
        "id": 42416,
        "name": "Hondschoote",
        "latitude": "50.97800000",
        "longitude": "2.58372000"
    },
    {
        "id": 42417,
        "name": "Honfleur",
        "latitude": "49.41985000",
        "longitude": "0.23294000"
    },
    {
        "id": 42466,
        "name": "Hôpital-Camfrout",
        "latitude": "48.32789000",
        "longitude": "-4.24155000"
    },
    {
        "id": 42418,
        "name": "Horbourg-Wihr",
        "latitude": "48.08106000",
        "longitude": "7.39380000"
    },
    {
        "id": 42419,
        "name": "Hordain",
        "latitude": "50.26306000",
        "longitude": "3.31358000"
    },
    {
        "id": 42420,
        "name": "Horgues",
        "latitude": "43.18865000",
        "longitude": "0.08733000"
    },
    {
        "id": 42421,
        "name": "Hornaing",
        "latitude": "50.36835000",
        "longitude": "3.33707000"
    },
    {
        "id": 42422,
        "name": "Hornoy-le-Bourg",
        "latitude": "49.85000000",
        "longitude": "1.90000000"
    },
    {
        "id": 42423,
        "name": "Houdain",
        "latitude": "50.45040000",
        "longitude": "2.53777000"
    },
    {
        "id": 42424,
        "name": "Houdan",
        "latitude": "48.79044000",
        "longitude": "1.60007000"
    },
    {
        "id": 42425,
        "name": "Houdemont",
        "latitude": "48.64407000",
        "longitude": "6.17579000"
    },
    {
        "id": 42426,
        "name": "Houilles",
        "latitude": "48.92161000",
        "longitude": "2.19263000"
    },
    {
        "id": 42427,
        "name": "Houlbec-Cocherel",
        "latitude": "49.07350000",
        "longitude": "1.36563000"
    },
    {
        "id": 42428,
        "name": "Houlgate",
        "latitude": "49.29900000",
        "longitude": "-0.08153000"
    },
    {
        "id": 42429,
        "name": "Houplin-Ancoisne",
        "latitude": "50.56284000",
        "longitude": "3.00251000"
    },
    {
        "id": 42430,
        "name": "Houplines",
        "latitude": "50.69499000",
        "longitude": "2.91518000"
    },
    {
        "id": 42431,
        "name": "Houppeville",
        "latitude": "49.51259000",
        "longitude": "1.07962000"
    },
    {
        "id": 42432,
        "name": "Hourtin",
        "latitude": "45.18570000",
        "longitude": "-1.05719000"
    },
    {
        "id": 42433,
        "name": "Houssen",
        "latitude": "48.12579000",
        "longitude": "7.37735000"
    },
    {
        "id": 42434,
        "name": "Hoymille",
        "latitude": "50.97187000",
        "longitude": "2.44738000"
    },
    {
        "id": 42435,
        "name": "Huelgoat",
        "latitude": "48.36111000",
        "longitude": "-3.74578000"
    },
    {
        "id": 42436,
        "name": "Huez",
        "latitude": "45.08240000",
        "longitude": "6.05878000"
    },
    {
        "id": 42437,
        "name": "Huismes",
        "latitude": "47.23319000",
        "longitude": "0.25116000"
    },
    {
        "id": 42438,
        "name": "Huisseau-sur-Cosson",
        "latitude": "47.59335000",
        "longitude": "1.45333000"
    },
    {
        "id": 42439,
        "name": "Huisseau-sur-Mauves",
        "latitude": "47.89319000",
        "longitude": "1.70274000"
    },
    {
        "id": 42440,
        "name": "Hulluch",
        "latitude": "50.48961000",
        "longitude": "2.81232000"
    },
    {
        "id": 42441,
        "name": "Hundling",
        "latitude": "49.10860000",
        "longitude": "6.97989000"
    },
    {
        "id": 42442,
        "name": "Huningue",
        "latitude": "47.60477000",
        "longitude": "7.57782000"
    },
    {
        "id": 42443,
        "name": "Huriel",
        "latitude": "46.37299000",
        "longitude": "2.47679000"
    },
    {
        "id": 42444,
        "name": "Hurigny",
        "latitude": "46.34722000",
        "longitude": "4.79676000"
    },
    {
        "id": 42445,
        "name": "Husseren-Wesserling",
        "latitude": "47.88351000",
        "longitude": "6.98994000"
    },
    {
        "id": 42446,
        "name": "Hussigny-Godbrange",
        "latitude": "49.49437000",
        "longitude": "5.86677000"
    },
    {
        "id": 42447,
        "name": "Huttenheim",
        "latitude": "48.35864000",
        "longitude": "7.57833000"
    },
    {
        "id": 42448,
        "name": "Hyères",
        "latitude": "43.12038000",
        "longitude": "6.12857000"
    },
    {
        "id": 42469,
        "name": "Ibos",
        "latitude": "43.23333000",
        "longitude": "0.00000000"
    },
    {
        "id": 42470,
        "name": "Idron",
        "latitude": "43.28333000",
        "longitude": "-0.31667000"
    },
    {
        "id": 42471,
        "name": "Iffendic",
        "latitude": "48.12981000",
        "longitude": "-2.03486000"
    },
    {
        "id": 42472,
        "name": "Ifs",
        "latitude": "49.14000000",
        "longitude": "-0.34899000"
    },
    {
        "id": 42473,
        "name": "Igney",
        "latitude": "48.27358000",
        "longitude": "6.39664000"
    },
    {
        "id": 42474,
        "name": "Igny",
        "latitude": "48.74437000",
        "longitude": "2.22428000"
    },
    {
        "id": 42475,
        "name": "Igon",
        "latitude": "43.16667000",
        "longitude": "-0.23333000"
    },
    {
        "id": 42476,
        "name": "Igoville",
        "latitude": "49.31990000",
        "longitude": "1.14832000"
    },
    {
        "id": 42477,
        "name": "Illange",
        "latitude": "49.32617000",
        "longitude": "6.17953000"
    },
    {
        "id": 42478,
        "name": "Illats",
        "latitude": "44.59714000",
        "longitude": "-0.37238000"
    },
    {
        "id": 42479,
        "name": "Ille-sur-Têt",
        "latitude": "42.67069000",
        "longitude": "2.62162000"
    },
    {
        "id": 42480,
        "name": "Illfurth",
        "latitude": "47.67410000",
        "longitude": "7.26607000"
    },
    {
        "id": 42481,
        "name": "Illies",
        "latitude": "50.56144000",
        "longitude": "2.83011000"
    },
    {
        "id": 42482,
        "name": "Illkirch-Graffenstaden",
        "latitude": "48.52894000",
        "longitude": "7.71523000"
    },
    {
        "id": 42483,
        "name": "Illzach",
        "latitude": "47.77420000",
        "longitude": "7.35175000"
    },
    {
        "id": 42484,
        "name": "Imphy",
        "latitude": "46.93619000",
        "longitude": "3.26037000"
    },
    {
        "id": 42485,
        "name": "Incheville",
        "latitude": "50.01239000",
        "longitude": "1.49438000"
    },
    {
        "id": 42486,
        "name": "Indre",
        "latitude": "47.20000000",
        "longitude": "-1.66667000"
    },
    {
        "id": 42487,
        "name": "Ingersheim",
        "latitude": "48.09803000",
        "longitude": "7.30308000"
    },
    {
        "id": 42488,
        "name": "Ingrandes",
        "latitude": "47.40500000",
        "longitude": "-0.92336000"
    },
    {
        "id": 42489,
        "name": "Ingré",
        "latitude": "47.91995000",
        "longitude": "1.82778000"
    },
    {
        "id": 42490,
        "name": "Inguiniel",
        "latitude": "47.97658000",
        "longitude": "-3.28072000"
    },
    {
        "id": 42491,
        "name": "Ingwiller",
        "latitude": "48.87284000",
        "longitude": "7.47980000"
    },
    {
        "id": 42492,
        "name": "Innenheim",
        "latitude": "48.49659000",
        "longitude": "7.57439000"
    },
    {
        "id": 42493,
        "name": "Irigny",
        "latitude": "45.67313000",
        "longitude": "4.82243000"
    },
    {
        "id": 42494,
        "name": "Irmstett",
        "latitude": "48.58801000",
        "longitude": "7.47837000"
    },
    {
        "id": 42495,
        "name": "Irodouër",
        "latitude": "48.24932000",
        "longitude": "-1.94959000"
    },
    {
        "id": 42496,
        "name": "Irvillac",
        "latitude": "48.37011000",
        "longitude": "-4.21223000"
    },
    {
        "id": 42497,
        "name": "Is-sur-Tille",
        "latitude": "47.52670000",
        "longitude": "5.10649000"
    },
    {
        "id": 42498,
        "name": "Isbergues",
        "latitude": "50.62328000",
        "longitude": "2.45902000"
    },
    {
        "id": 42499,
        "name": "Isigny-le-Buat",
        "latitude": "48.61705000",
        "longitude": "-1.16993000"
    },
    {
        "id": 42500,
        "name": "Isigny-sur-Mer",
        "latitude": "49.31604000",
        "longitude": "-1.10384000"
    },
    {
        "id": 42501,
        "name": "Isle",
        "latitude": "45.80272000",
        "longitude": "1.21213000"
    },
    {
        "id": 42502,
        "name": "Isneauville",
        "latitude": "49.49832000",
        "longitude": "1.15431000"
    },
    {
        "id": 42503,
        "name": "Isques",
        "latitude": "50.67391000",
        "longitude": "1.65445000"
    },
    {
        "id": 42509,
        "name": "Issé",
        "latitude": "47.62419000",
        "longitude": "-1.45152000"
    },
    {
        "id": 42504,
        "name": "Issenheim",
        "latitude": "47.90357000",
        "longitude": "7.25235000"
    },
    {
        "id": 42505,
        "name": "Issoire",
        "latitude": "45.54422000",
        "longitude": "3.24901000"
    },
    {
        "id": 42506,
        "name": "Issou",
        "latitude": "48.98994000",
        "longitude": "1.79292000"
    },
    {
        "id": 42507,
        "name": "Issoudun",
        "latitude": "46.94848000",
        "longitude": "1.99362000"
    },
    {
        "id": 42508,
        "name": "Issy-les-Moulineaux",
        "latitude": "48.82104000",
        "longitude": "2.27718000"
    },
    {
        "id": 42510,
        "name": "Istres",
        "latitude": "43.51345000",
        "longitude": "4.98747000"
    },
    {
        "id": 42511,
        "name": "Itancourt",
        "latitude": "49.80664000",
        "longitude": "3.34427000"
    },
    {
        "id": 42512,
        "name": "Iteuil",
        "latitude": "46.48808000",
        "longitude": "0.31212000"
    },
    {
        "id": 42513,
        "name": "Ittenheim",
        "latitude": "48.60508000",
        "longitude": "7.59372000"
    },
    {
        "id": 42514,
        "name": "Itteville",
        "latitude": "48.51536000",
        "longitude": "2.34377000"
    },
    {
        "id": 42515,
        "name": "Itxassou",
        "latitude": "43.32893000",
        "longitude": "-1.40617000"
    },
    {
        "id": 42516,
        "name": "Ivry-la-Bataille",
        "latitude": "48.88333000",
        "longitude": "1.45948000"
    },
    {
        "id": 42517,
        "name": "Ivry-sur-Seine",
        "latitude": "48.81568000",
        "longitude": "2.38487000"
    },
    {
        "id": 42518,
        "name": "Iwuy",
        "latitude": "50.23300000",
        "longitude": "3.32058000"
    },
    {
        "id": 42519,
        "name": "Izeaux",
        "latitude": "45.33473000",
        "longitude": "5.42486000"
    },
    {
        "id": 42520,
        "name": "Izernore",
        "latitude": "46.21352000",
        "longitude": "5.55200000"
    },
    {
        "id": 42521,
        "name": "Izon",
        "latitude": "44.92416000",
        "longitude": "-0.36322000"
    },
    {
        "id": 42522,
        "name": "Jacob-Bellecombette",
        "latitude": "45.55763000",
        "longitude": "5.91484000"
    },
    {
        "id": 42523,
        "name": "Jacou",
        "latitude": "43.66139000",
        "longitude": "3.91222000"
    },
    {
        "id": 42524,
        "name": "Jallais",
        "latitude": "47.19691000",
        "longitude": "-0.86738000"
    },
    {
        "id": 42525,
        "name": "Janneyrias",
        "latitude": "45.75221000",
        "longitude": "5.11277000"
    },
    {
        "id": 42526,
        "name": "Jans",
        "latitude": "47.62029000",
        "longitude": "-1.61438000"
    },
    {
        "id": 42527,
        "name": "Janville",
        "latitude": "48.20284000",
        "longitude": "1.88020000"
    },
    {
        "id": 42528,
        "name": "Janville-sur-Juine",
        "latitude": "48.51352000",
        "longitude": "2.27064000"
    },
    {
        "id": 42529,
        "name": "Janzé",
        "latitude": "47.95915000",
        "longitude": "-1.49829000"
    },
    {
        "id": 42530,
        "name": "Jard-sur-Mer",
        "latitude": "46.41451000",
        "longitude": "-1.57639000"
    },
    {
        "id": 42531,
        "name": "Jardin",
        "latitude": "45.49638000",
        "longitude": "4.90847000"
    },
    {
        "id": 42532,
        "name": "Jargeau",
        "latitude": "47.86316000",
        "longitude": "2.12648000"
    },
    {
        "id": 42533,
        "name": "Jarnac",
        "latitude": "45.68160000",
        "longitude": "-0.17329000"
    },
    {
        "id": 42534,
        "name": "Jarny",
        "latitude": "49.15873000",
        "longitude": "5.87640000"
    },
    {
        "id": 42535,
        "name": "Jarrie",
        "latitude": "45.11732000",
        "longitude": "5.75957000"
    },
    {
        "id": 42536,
        "name": "Jarville-la-Malgrange",
        "latitude": "48.66697000",
        "longitude": "6.20269000"
    },
    {
        "id": 42537,
        "name": "Jarzé",
        "latitude": "47.55563000",
        "longitude": "-0.23183000"
    },
    {
        "id": 42538,
        "name": "Jassans-Riottier",
        "latitude": "45.98134000",
        "longitude": "4.76140000"
    },
    {
        "id": 42539,
        "name": "Jasseron",
        "latitude": "46.21316000",
        "longitude": "5.32579000"
    },
    {
        "id": 42540,
        "name": "Jaujac",
        "latitude": "44.63695000",
        "longitude": "4.25638000"
    },
    {
        "id": 42541,
        "name": "Jaunay-Clan",
        "latitude": "46.68453000",
        "longitude": "0.37128000"
    },
    {
        "id": 42542,
        "name": "Jausiers",
        "latitude": "44.41705000",
        "longitude": "6.72947000"
    },
    {
        "id": 42543,
        "name": "Jaux",
        "latitude": "49.39120000",
        "longitude": "2.77809000"
    },
    {
        "id": 42544,
        "name": "Javené",
        "latitude": "48.31933000",
        "longitude": "-1.21630000"
    },
    {
        "id": 42545,
        "name": "Javron-les-Chapelles",
        "latitude": "48.41797000",
        "longitude": "-0.33742000"
    },
    {
        "id": 42546,
        "name": "Jeanménil",
        "latitude": "48.33560000",
        "longitude": "6.68769000"
    },
    {
        "id": 42547,
        "name": "Jebsheim",
        "latitude": "48.12375000",
        "longitude": "7.47578000"
    },
    {
        "id": 42548,
        "name": "Jegun",
        "latitude": "43.76667000",
        "longitude": "0.46667000"
    },
    {
        "id": 42549,
        "name": "Jenlain",
        "latitude": "50.31164000",
        "longitude": "3.62872000"
    },
    {
        "id": 42550,
        "name": "Jeumont",
        "latitude": "50.29658000",
        "longitude": "4.10108000"
    },
    {
        "id": 42551,
        "name": "Job",
        "latitude": "45.61607000",
        "longitude": "3.74542000"
    },
    {
        "id": 42603,
        "name": "Jœuf",
        "latitude": "49.23333000",
        "longitude": "6.01667000"
    },
    {
        "id": 42552,
        "name": "Joigny",
        "latitude": "47.98288000",
        "longitude": "3.40096000"
    },
    {
        "id": 42553,
        "name": "Joinville",
        "latitude": "48.44363000",
        "longitude": "5.14144000"
    },
    {
        "id": 42554,
        "name": "Joinville-le-Pont",
        "latitude": "48.82538000",
        "longitude": "2.47458000"
    },
    {
        "id": 42555,
        "name": "Jonage",
        "latitude": "45.79638000",
        "longitude": "5.04664000"
    },
    {
        "id": 42556,
        "name": "Joncherey",
        "latitude": "47.52895000",
        "longitude": "7.00323000"
    },
    {
        "id": 42557,
        "name": "Jonchery-sur-Vesle",
        "latitude": "49.28952000",
        "longitude": "3.81345000"
    },
    {
        "id": 42558,
        "name": "Jonquerettes",
        "latitude": "43.94655000",
        "longitude": "4.93286000"
    },
    {
        "id": 42559,
        "name": "Jonquières",
        "latitude": "44.11603000",
        "longitude": "4.89989000"
    },
    {
        "id": 42560,
        "name": "Jonquières-Saint-Vincent",
        "latitude": "43.82809000",
        "longitude": "4.56327000"
    },
    {
        "id": 42561,
        "name": "Jons",
        "latitude": "45.80823000",
        "longitude": "5.08070000"
    },
    {
        "id": 42562,
        "name": "Jonzac",
        "latitude": "45.44668000",
        "longitude": "-0.43370000"
    },
    {
        "id": 42563,
        "name": "Jonzieux",
        "latitude": "45.31355000",
        "longitude": "4.36158000"
    },
    {
        "id": 42564,
        "name": "Josselin",
        "latitude": "47.95720000",
        "longitude": "-2.54713000"
    },
    {
        "id": 42565,
        "name": "Jouarre",
        "latitude": "48.92661000",
        "longitude": "3.13168000"
    },
    {
        "id": 42566,
        "name": "Jouars-Pontchartrain",
        "latitude": "48.78888000",
        "longitude": "1.89898000"
    },
    {
        "id": 42567,
        "name": "Joudreville",
        "latitude": "49.29145000",
        "longitude": "5.77903000"
    },
    {
        "id": 42579,
        "name": "Joué-lès-Tours",
        "latitude": "47.35223000",
        "longitude": "0.66905000"
    },
    {
        "id": 42568,
        "name": "Jouet-sur-l'Aubois",
        "latitude": "47.04562000",
        "longitude": "2.98725000"
    },
    {
        "id": 42569,
        "name": "Jougne",
        "latitude": "46.76291000",
        "longitude": "6.38838000"
    },
    {
        "id": 42570,
        "name": "Jouques",
        "latitude": "43.63710000",
        "longitude": "5.63672000"
    },
    {
        "id": 42571,
        "name": "Joux-la-Ville",
        "latitude": "47.62241000",
        "longitude": "3.86217000"
    },
    {
        "id": 42572,
        "name": "Jouy",
        "latitude": "48.51005000",
        "longitude": "1.54501000"
    },
    {
        "id": 42573,
        "name": "Jouy-aux-Arches",
        "latitude": "49.06148000",
        "longitude": "6.07925000"
    },
    {
        "id": 42574,
        "name": "Jouy-en-Josas",
        "latitude": "48.75909000",
        "longitude": "2.16966000"
    },
    {
        "id": 42575,
        "name": "Jouy-le-Châtel",
        "latitude": "48.66651000",
        "longitude": "3.13036000"
    },
    {
        "id": 42576,
        "name": "Jouy-le-Moutier",
        "latitude": "49.01068000",
        "longitude": "2.04028000"
    },
    {
        "id": 42577,
        "name": "Jouy-le-Potier",
        "latitude": "47.74553000",
        "longitude": "1.81115000"
    },
    {
        "id": 42578,
        "name": "Jouy-sur-Morin",
        "latitude": "48.79500000",
        "longitude": "3.27238000"
    },
    {
        "id": 42580,
        "name": "Joyeuse",
        "latitude": "44.47951000",
        "longitude": "4.23778000"
    },
    {
        "id": 42581,
        "name": "Jugon-les-Lacs",
        "latitude": "48.41667000",
        "longitude": "-2.33333000"
    },
    {
        "id": 42582,
        "name": "Juigné",
        "latitude": "47.71878000",
        "longitude": "-0.39405000"
    },
    {
        "id": 42583,
        "name": "Juigné-sur-Loire",
        "latitude": "47.40741000",
        "longitude": "-0.47682000"
    },
    {
        "id": 42584,
        "name": "Juigné-sur-Sarthe",
        "latitude": "47.86499000",
        "longitude": "-0.28624000"
    },
    {
        "id": 42585,
        "name": "Juillac",
        "latitude": "45.31808000",
        "longitude": "1.32257000"
    },
    {
        "id": 42586,
        "name": "Juillan",
        "latitude": "43.19937000",
        "longitude": "0.02570000"
    },
    {
        "id": 42587,
        "name": "Juilly",
        "latitude": "49.01375000",
        "longitude": "2.70563000"
    },
    {
        "id": 42588,
        "name": "Jujurieux",
        "latitude": "46.04122000",
        "longitude": "5.40855000"
    },
    {
        "id": 42589,
        "name": "Jullouville",
        "latitude": "48.77576000",
        "longitude": "-1.56366000"
    },
    {
        "id": 42590,
        "name": "Jumelles",
        "latitude": "47.43511000",
        "longitude": "-0.10370000"
    },
    {
        "id": 42592,
        "name": "Jumièges",
        "latitude": "49.43324000",
        "longitude": "0.81918000"
    },
    {
        "id": 42591,
        "name": "Jumilhac-le-Grand",
        "latitude": "45.49432000",
        "longitude": "1.06339000"
    },
    {
        "id": 42593,
        "name": "Jura",
        "latitude": "46.73431000",
        "longitude": "5.72349000"
    },
    {
        "id": 42594,
        "name": "Jurançon",
        "latitude": "43.28830000",
        "longitude": "-0.38694000"
    },
    {
        "id": 42595,
        "name": "Jussac",
        "latitude": "44.98764000",
        "longitude": "2.42308000"
    },
    {
        "id": 42596,
        "name": "Jussey",
        "latitude": "47.82536000",
        "longitude": "5.90193000"
    },
    {
        "id": 42597,
        "name": "Jussy",
        "latitude": "49.71984000",
        "longitude": "3.23270000"
    },
    {
        "id": 42598,
        "name": "Juvignac",
        "latitude": "43.61389000",
        "longitude": "3.81056000"
    },
    {
        "id": 42600,
        "name": "Juvigné",
        "latitude": "48.22891000",
        "longitude": "-1.03408000"
    },
    {
        "id": 42599,
        "name": "Juvigny-sous-Andaine",
        "latitude": "48.55194000",
        "longitude": "-0.50878000"
    },
    {
        "id": 42601,
        "name": "Juvisy-sur-Orge",
        "latitude": "48.68333000",
        "longitude": "2.38333000"
    },
    {
        "id": 42602,
        "name": "Juziers",
        "latitude": "48.99137000",
        "longitude": "1.84760000"
    },
    {
        "id": 42604,
        "name": "Kaltenhouse",
        "latitude": "48.79110000",
        "longitude": "7.83217000"
    },
    {
        "id": 42605,
        "name": "Kaysersberg",
        "latitude": "48.13803000",
        "longitude": "7.26385000"
    },
    {
        "id": 42623,
        "name": "Kédange-sur-Canner",
        "latitude": "49.30887000",
        "longitude": "6.33805000"
    },
    {
        "id": 42606,
        "name": "Kembs",
        "latitude": "47.68948000",
        "longitude": "7.50323000"
    },
    {
        "id": 42607,
        "name": "Kerbach",
        "latitude": "49.16682000",
        "longitude": "6.96549000"
    },
    {
        "id": 42608,
        "name": "Kerlouan",
        "latitude": "48.64500000",
        "longitude": "-4.36556000"
    },
    {
        "id": 42609,
        "name": "Kernilis",
        "latitude": "48.57075000",
        "longitude": "-4.41800000"
    },
    {
        "id": 42610,
        "name": "Kersaint-Plabennec",
        "latitude": "48.47166000",
        "longitude": "-4.37233000"
    },
    {
        "id": 42611,
        "name": "Kertzfeld",
        "latitude": "48.37990000",
        "longitude": "7.56816000"
    },
    {
        "id": 42612,
        "name": "Kervignac",
        "latitude": "47.76289000",
        "longitude": "-3.23778000"
    },
    {
        "id": 42613,
        "name": "Keskastel",
        "latitude": "48.97132000",
        "longitude": "7.04412000"
    },
    {
        "id": 42614,
        "name": "Killem",
        "latitude": "50.95779000",
        "longitude": "2.56068000"
    },
    {
        "id": 42615,
        "name": "Kilstett",
        "latitude": "48.67600000",
        "longitude": "7.85471000"
    },
    {
        "id": 42616,
        "name": "Kingersheim",
        "latitude": "47.79100000",
        "longitude": "7.32647000"
    },
    {
        "id": 42617,
        "name": "Kintzheim",
        "latitude": "48.25480000",
        "longitude": "7.39491000"
    },
    {
        "id": 42618,
        "name": "Knutange",
        "latitude": "49.33895000",
        "longitude": "6.03898000"
    },
    {
        "id": 42624,
        "name": "Kœnigsmacker",
        "latitude": "49.40000000",
        "longitude": "6.28333000"
    },
    {
        "id": 42619,
        "name": "Krautergersheim",
        "latitude": "48.47634000",
        "longitude": "7.56718000"
    },
    {
        "id": 42620,
        "name": "Kruth",
        "latitude": "47.93101000",
        "longitude": "6.96467000"
    },
    {
        "id": 42621,
        "name": "Kunheim",
        "latitude": "48.07657000",
        "longitude": "7.53489000"
    },
    {
        "id": 42622,
        "name": "Kuntzig",
        "latitude": "49.34731000",
        "longitude": "6.23710000"
    },
    {
        "id": 42625,
        "name": "L'Estaque",
        "latitude": "43.36385000",
        "longitude": "5.30854000"
    },
    {
        "id": 42626,
        "name": "L'Haÿ-les-Roses",
        "latitude": "48.78333000",
        "longitude": "2.33333000"
    },
    {
        "id": 42628,
        "name": "L'Île-Bouchard",
        "latitude": "47.11667000",
        "longitude": "0.41667000"
    },
    {
        "id": 42629,
        "name": "L'Île-Rousse",
        "latitude": "42.63371000",
        "longitude": "8.93764000"
    },
    {
        "id": 42627,
        "name": "L'Union",
        "latitude": "43.66042000",
        "longitude": "1.48264000"
    },
    {
        "id": 43701,
        "name": "L’Escale",
        "latitude": "44.08162000",
        "longitude": "6.02379000"
    },
    {
        "id": 43702,
        "name": "L’Escarène",
        "latitude": "43.83492000",
        "longitude": "7.35542000"
    },
    {
        "id": 43708,
        "name": "L’Étang-la-Ville",
        "latitude": "48.86954000",
        "longitude": "2.05732000"
    },
    {
        "id": 43709,
        "name": "L’Étrat",
        "latitude": "45.48610000",
        "longitude": "4.37586000"
    },
    {
        "id": 43703,
        "name": "L’Isle-Adam",
        "latitude": "49.10744000",
        "longitude": "2.22818000"
    },
    {
        "id": 43705,
        "name": "L’Isle-d’Abeau",
        "latitude": "45.61960000",
        "longitude": "5.22466000"
    },
    {
        "id": 43706,
        "name": "L’Isle-en-Dodon",
        "latitude": "43.38428000",
        "longitude": "0.83513000"
    },
    {
        "id": 43704,
        "name": "L’Isle-Jourdain",
        "latitude": "43.61218000",
        "longitude": "1.08219000"
    },
    {
        "id": 43707,
        "name": "L’Isle-sur-la-Sorgue",
        "latitude": "43.91971000",
        "longitude": "5.05141000"
    },
    {
        "id": 42630,
        "name": "La Baconnière",
        "latitude": "48.18012000",
        "longitude": "-0.89202000"
    },
    {
        "id": 42631,
        "name": "La Balme-de-Sillingy",
        "latitude": "45.96890000",
        "longitude": "6.04187000"
    },
    {
        "id": 42632,
        "name": "La Barasse",
        "latitude": "43.28433000",
        "longitude": "5.48664000"
    },
    {
        "id": 42633,
        "name": "La Barre-de-Monts",
        "latitude": "46.88059000",
        "longitude": "-2.12168000"
    },
    {
        "id": 42634,
        "name": "La Barthe-de-Neste",
        "latitude": "43.08123000",
        "longitude": "0.38438000"
    },
    {
        "id": 42635,
        "name": "La Bassée",
        "latitude": "50.53424000",
        "longitude": "2.80620000"
    },
    {
        "id": 42636,
        "name": "La Bastide-des-Jourdans",
        "latitude": "43.78531000",
        "longitude": "5.63446000"
    },
    {
        "id": 42658,
        "name": "La Bâthie",
        "latitude": "45.62829000",
        "longitude": "6.44900000"
    },
    {
        "id": 42659,
        "name": "La Bâtie-Montgascon",
        "latitude": "45.57745000",
        "longitude": "5.52873000"
    },
    {
        "id": 42660,
        "name": "La Bâtie-Neuve",
        "latitude": "44.56680000",
        "longitude": "6.19587000"
    },
    {
        "id": 42637,
        "name": "La Baule-Escoublac",
        "latitude": "47.29221000",
        "longitude": "-2.36395000"
    },
    {
        "id": 42638,
        "name": "La Bazoche-Gouet",
        "latitude": "48.13985000",
        "longitude": "0.98163000"
    },
    {
        "id": 42639,
        "name": "La Bazoge",
        "latitude": "48.09689000",
        "longitude": "0.15534000"
    },
    {
        "id": 42661,
        "name": "La Bégude-de-Mazenc",
        "latitude": "44.54298000",
        "longitude": "4.93403000"
    },
    {
        "id": 42640,
        "name": "La Bernardière",
        "latitude": "47.04952000",
        "longitude": "-1.26587000"
    },
    {
        "id": 42641,
        "name": "La Bernerie-en-Retz",
        "latitude": "47.08040000",
        "longitude": "-2.03642000"
    },
    {
        "id": 42642,
        "name": "La Biolle",
        "latitude": "45.75846000",
        "longitude": "5.92866000"
    },
    {
        "id": 42643,
        "name": "La Blancarde",
        "latitude": "43.30047000",
        "longitude": "5.40563000"
    },
    {
        "id": 42644,
        "name": "La Bohalle",
        "latitude": "47.42095000",
        "longitude": "-0.39723000"
    },
    {
        "id": 42645,
        "name": "La Boisse",
        "latitude": "45.84278000",
        "longitude": "5.03639000"
    },
    {
        "id": 42646,
        "name": "La Boissière-de-Montaigu",
        "latitude": "46.95012000",
        "longitude": "-1.19047000"
    },
    {
        "id": 42647,
        "name": "La Boissière-des-Landes",
        "latitude": "46.56398000",
        "longitude": "-1.46172000"
    },
    {
        "id": 42648,
        "name": "La Bonneville-sur-Iton",
        "latitude": "49.00000000",
        "longitude": "1.03333000"
    },
    {
        "id": 42652,
        "name": "La Bouëxière",
        "latitude": "48.18333000",
        "longitude": "-1.43333000"
    },
    {
        "id": 42649,
        "name": "La Bouilladisse",
        "latitude": "43.39619000",
        "longitude": "5.59307000"
    },
    {
        "id": 42650,
        "name": "La Bourboule",
        "latitude": "45.58829000",
        "longitude": "2.73920000"
    },
    {
        "id": 42651,
        "name": "La Boussac",
        "latitude": "48.51260000",
        "longitude": "-1.66088000"
    },
    {
        "id": 42653,
        "name": "La Bresse",
        "latitude": "47.99974000",
        "longitude": "6.86499000"
    },
    {
        "id": 42654,
        "name": "La Bridoire",
        "latitude": "45.52504000",
        "longitude": "5.74073000"
    },
    {
        "id": 42655,
        "name": "La Broque",
        "latitude": "48.47298000",
        "longitude": "7.21639000"
    },
    {
        "id": 42656,
        "name": "La Bruffière",
        "latitude": "47.01492000",
        "longitude": "-1.19730000"
    },
    {
        "id": 42657,
        "name": "La Buisse",
        "latitude": "45.34644000",
        "longitude": "5.61435000"
    },
    {
        "id": 42662,
        "name": "La Cabucelle",
        "latitude": "43.33189000",
        "longitude": "5.36111000"
    },
    {
        "id": 42663,
        "name": "La Calade",
        "latitude": "43.33895000",
        "longitude": "5.35111000"
    },
    {
        "id": 42664,
        "name": "La Calmette",
        "latitude": "43.92283000",
        "longitude": "4.26617000"
    },
    {
        "id": 42665,
        "name": "La Canourgue",
        "latitude": "44.43133000",
        "longitude": "3.21584000"
    },
    {
        "id": 42666,
        "name": "La Capelette",
        "latitude": "43.28086000",
        "longitude": "5.40600000"
    },
    {
        "id": 42667,
        "name": "La Capelle",
        "latitude": "49.96667000",
        "longitude": "3.91667000"
    },
    {
        "id": 42668,
        "name": "La Capelle-lès-Boulogne",
        "latitude": "50.73302000",
        "longitude": "1.70147000"
    },
    {
        "id": 42669,
        "name": "La Cavalerie",
        "latitude": "44.00961000",
        "longitude": "3.15840000"
    },
    {
        "id": 42670,
        "name": "La Celle",
        "latitude": "43.39523000",
        "longitude": "6.03750000"
    },
    {
        "id": 42671,
        "name": "La Celle-Saint-Avant",
        "latitude": "47.02200000",
        "longitude": "0.60488000"
    },
    {
        "id": 42672,
        "name": "La Celle-Saint-Cloud",
        "latitude": "48.85029000",
        "longitude": "2.14523000"
    },
    {
        "id": 42673,
        "name": "La Celle-sur-Morin",
        "latitude": "48.81146000",
        "longitude": "2.96921000"
    },
    {
        "id": 42674,
        "name": "La Cerlangue",
        "latitude": "49.50592000",
        "longitude": "0.41332000"
    },
    {
        "id": 42675,
        "name": "La Chaize-le-Vicomte",
        "latitude": "46.67166000",
        "longitude": "-1.29084000"
    },
    {
        "id": 42676,
        "name": "La Chambre",
        "latitude": "45.35862000",
        "longitude": "6.29978000"
    },
    {
        "id": 42677,
        "name": "La Chapelle-Achard",
        "latitude": "46.58862000",
        "longitude": "-1.64757000"
    },
    {
        "id": 42678,
        "name": "La Chapelle-Basse-Mer",
        "latitude": "47.27086000",
        "longitude": "-1.33913000"
    },
    {
        "id": 42691,
        "name": "La Chapelle-de-Guinchay",
        "latitude": "46.20841000",
        "longitude": "4.76245000"
    },
    {
        "id": 42692,
        "name": "La Chapelle-de-la-Tour",
        "latitude": "45.58484000",
        "longitude": "5.46360000"
    },
    {
        "id": 42693,
        "name": "La Chapelle-des-Fougeretz",
        "latitude": "48.17705000",
        "longitude": "-1.73143000"
    },
    {
        "id": 42694,
        "name": "La Chapelle-des-Marais",
        "latitude": "47.44845000",
        "longitude": "-2.23834000"
    },
    {
        "id": 42695,
        "name": "La Chapelle-du-Genêt",
        "latitude": "47.18203000",
        "longitude": "-1.01857000"
    },
    {
        "id": 42696,
        "name": "La Chapelle-du-Noyer",
        "latitude": "48.03191000",
        "longitude": "1.30953000"
    },
    {
        "id": 42697,
        "name": "La Chapelle-en-Serval",
        "latitude": "49.12842000",
        "longitude": "2.53405000"
    },
    {
        "id": 42679,
        "name": "La Chapelle-Gauthier",
        "latitude": "48.54947000",
        "longitude": "2.89776000"
    },
    {
        "id": 42680,
        "name": "La Chapelle-Heulin",
        "latitude": "47.17667000",
        "longitude": "-1.34000000"
    },
    {
        "id": 42681,
        "name": "La Chapelle-Janson",
        "latitude": "48.34775000",
        "longitude": "-1.10209000"
    },
    {
        "id": 42698,
        "name": "La Chapelle-la-Reine",
        "latitude": "48.31813000",
        "longitude": "2.57152000"
    },
    {
        "id": 42682,
        "name": "La Chapelle-Launay",
        "latitude": "47.37226000",
        "longitude": "-1.97071000"
    },
    {
        "id": 42683,
        "name": "La Chapelle-Réanville",
        "latitude": "49.09752000",
        "longitude": "1.38209000"
    },
    {
        "id": 42684,
        "name": "La Chapelle-Saint-Aubin",
        "latitude": "48.03595000",
        "longitude": "0.15624000"
    },
    {
        "id": 42685,
        "name": "La Chapelle-Saint-Florent",
        "latitude": "47.33393000",
        "longitude": "-1.05631000"
    },
    {
        "id": 42686,
        "name": "La Chapelle-Saint-Laurent",
        "latitude": "46.74643000",
        "longitude": "-0.47643000"
    },
    {
        "id": 42687,
        "name": "La Chapelle-Saint-Luc",
        "latitude": "48.31734000",
        "longitude": "4.03988000"
    },
    {
        "id": 42688,
        "name": "La Chapelle-Saint-Mesmin",
        "latitude": "47.88648000",
        "longitude": "1.83450000"
    },
    {
        "id": 42689,
        "name": "La Chapelle-Saint-Ursin",
        "latitude": "47.06248000",
        "longitude": "2.32447000"
    },
    {
        "id": 42699,
        "name": "La Chapelle-sur-Crécy",
        "latitude": "48.85879000",
        "longitude": "2.92597000"
    },
    {
        "id": 42700,
        "name": "La Chapelle-sur-Erdre",
        "latitude": "47.29583000",
        "longitude": "-1.55309000"
    },
    {
        "id": 42701,
        "name": "La Chapelle-sur-Loire",
        "latitude": "47.25063000",
        "longitude": "0.21887000"
    },
    {
        "id": 42690,
        "name": "La Chapelle-Thouarault",
        "latitude": "48.12486000",
        "longitude": "-1.86619000"
    },
    {
        "id": 42702,
        "name": "La Charité-sur-Loire",
        "latitude": "47.18333000",
        "longitude": "3.01667000"
    },
    {
        "id": 42703,
        "name": "La Chartre-sur-le-Loir",
        "latitude": "47.73117000",
        "longitude": "0.56852000"
    },
    {
        "id": 42706,
        "name": "La Châtaigneraie",
        "latitude": "46.64786000",
        "longitude": "-0.73916000"
    },
    {
        "id": 42707,
        "name": "La Châtre",
        "latitude": "46.58228000",
        "longitude": "1.98734000"
    },
    {
        "id": 42704,
        "name": "La Chaussée-Saint-Victor",
        "latitude": "47.61621000",
        "longitude": "1.36765000"
    },
    {
        "id": 42705,
        "name": "La Chevrolière",
        "latitude": "47.09124000",
        "longitude": "-1.60973000"
    },
    {
        "id": 42708,
        "name": "La Ciotat",
        "latitude": "43.17476000",
        "longitude": "5.60449000"
    },
    {
        "id": 42709,
        "name": "La Clayette",
        "latitude": "46.29002000",
        "longitude": "4.30422000"
    },
    {
        "id": 42710,
        "name": "La Clusaz",
        "latitude": "45.90675000",
        "longitude": "6.43445000"
    },
    {
        "id": 42711,
        "name": "La Cluse-et-Mijoux",
        "latitude": "46.88333000",
        "longitude": "6.38333000"
    },
    {
        "id": 42712,
        "name": "La Colle-sur-Loup",
        "latitude": "43.68648000",
        "longitude": "7.10376000"
    },
    {
        "id": 42713,
        "name": "La Conception",
        "latitude": "43.29174000",
        "longitude": "5.39391000"
    },
    {
        "id": 42714,
        "name": "La Coquille",
        "latitude": "45.54201000",
        "longitude": "0.97675000"
    },
    {
        "id": 42726,
        "name": "La Côte-Saint-André",
        "latitude": "45.39447000",
        "longitude": "5.25908000"
    },
    {
        "id": 42715,
        "name": "La Couarde-sur-Mer",
        "latitude": "46.19411000",
        "longitude": "-1.42522000"
    },
    {
        "id": 42716,
        "name": "La Courneuve",
        "latitude": "48.92805000",
        "longitude": "2.39627000"
    },
    {
        "id": 42717,
        "name": "La Couronne",
        "latitude": "45.61128000",
        "longitude": "0.09948000"
    },
    {
        "id": 42718,
        "name": "La Courtine",
        "latitude": "45.70000000",
        "longitude": "2.26667000"
    },
    {
        "id": 42719,
        "name": "La Couture",
        "latitude": "50.58212000",
        "longitude": "2.70710000"
    },
    {
        "id": 42720,
        "name": "La Couture-Boussey",
        "latitude": "48.89581000",
        "longitude": "1.40491000"
    },
    {
        "id": 42721,
        "name": "La Crau",
        "latitude": "43.14962000",
        "longitude": "6.07399000"
    },
    {
        "id": 42725,
        "name": "La Crèche",
        "latitude": "46.36667000",
        "longitude": "-0.30000000"
    },
    {
        "id": 42724,
        "name": "La Croix-en-Touraine",
        "latitude": "47.34031000",
        "longitude": "0.99024000"
    },
    {
        "id": 42722,
        "name": "La Croix-Rouge",
        "latitude": "43.33329000",
        "longitude": "5.45403000"
    },
    {
        "id": 42723,
        "name": "La Croix-Valmer",
        "latitude": "43.20755000",
        "longitude": "6.56796000"
    },
    {
        "id": 42727,
        "name": "La Daguenière",
        "latitude": "47.41934000",
        "longitude": "-0.43128000"
    },
    {
        "id": 42728,
        "name": "La Defense",
        "latitude": "48.89198000",
        "longitude": "2.23881000"
    },
    {
        "id": 42729,
        "name": "La Delorme",
        "latitude": "43.34405000",
        "longitude": "5.37251000"
    },
    {
        "id": 42730,
        "name": "La Destrousse",
        "latitude": "43.37505000",
        "longitude": "5.60560000"
    },
    {
        "id": 42731,
        "name": "La Dominelais",
        "latitude": "47.76229000",
        "longitude": "-1.68819000"
    },
    {
        "id": 42732,
        "name": "La Fare-les-Oliviers",
        "latitude": "43.55104000",
        "longitude": "5.19430000"
    },
    {
        "id": 42733,
        "name": "La Farlède",
        "latitude": "43.16866000",
        "longitude": "6.04323000"
    },
    {
        "id": 42759,
        "name": "La Fère",
        "latitude": "49.66286000",
        "longitude": "3.36631000"
    },
    {
        "id": 42734,
        "name": "La Ferrière",
        "latitude": "46.71453000",
        "longitude": "-1.31417000"
    },
    {
        "id": 42735,
        "name": "La Ferrière-aux-Étangs",
        "latitude": "48.66227000",
        "longitude": "-0.51955000"
    },
    {
        "id": 42736,
        "name": "La Ferté-Alais",
        "latitude": "48.48306000",
        "longitude": "2.34802000"
    },
    {
        "id": 42737,
        "name": "La Ferté-Bernard",
        "latitude": "48.18633000",
        "longitude": "0.65357000"
    },
    {
        "id": 42738,
        "name": "La Ferté-Gaucher",
        "latitude": "48.78310000",
        "longitude": "3.30678000"
    },
    {
        "id": 42739,
        "name": "La Ferté-Imbault",
        "latitude": "47.38467000",
        "longitude": "1.95344000"
    },
    {
        "id": 42740,
        "name": "La Ferté-Macé",
        "latitude": "48.58999000",
        "longitude": "-0.35800000"
    },
    {
        "id": 42741,
        "name": "La Ferté-Milon",
        "latitude": "49.17453000",
        "longitude": "3.12885000"
    },
    {
        "id": 42742,
        "name": "La Ferté-Saint-Aubin",
        "latitude": "47.71695000",
        "longitude": "1.93904000"
    },
    {
        "id": 42743,
        "name": "La Ferté-sous-Jouarre",
        "latitude": "48.95140000",
        "longitude": "3.12724000"
    },
    {
        "id": 42744,
        "name": "La Feuillie",
        "latitude": "49.46415000",
        "longitude": "1.51463000"
    },
    {
        "id": 42745,
        "name": "La Flamengrie",
        "latitude": "50.00175000",
        "longitude": "3.92081000"
    },
    {
        "id": 42748,
        "name": "La Flèche",
        "latitude": "47.69815000",
        "longitude": "-0.07553000"
    },
    {
        "id": 42746,
        "name": "La Flocellière",
        "latitude": "46.83202000",
        "longitude": "-0.86215000"
    },
    {
        "id": 42747,
        "name": "La Flotte",
        "latitude": "46.18771000",
        "longitude": "-1.32815000"
    },
    {
        "id": 42749,
        "name": "La Force",
        "latitude": "44.86902000",
        "longitude": "0.37541000"
    },
    {
        "id": 42750,
        "name": "La Forest-Landerneau",
        "latitude": "48.42589000",
        "longitude": "-4.31578000"
    },
    {
        "id": 42751,
        "name": "La Forêt-Fouesnant",
        "latitude": "47.91010000",
        "longitude": "-3.97858000"
    },
    {
        "id": 42752,
        "name": "La Forêt-sur-Sèvre",
        "latitude": "46.76905000",
        "longitude": "-0.64964000"
    },
    {
        "id": 42753,
        "name": "La Fouillade",
        "latitude": "44.23072000",
        "longitude": "2.03989000"
    },
    {
        "id": 42754,
        "name": "La Fouillouse",
        "latitude": "45.50462000",
        "longitude": "4.31524000"
    },
    {
        "id": 42755,
        "name": "La Fourragère",
        "latitude": "43.30382000",
        "longitude": "5.43138000"
    },
    {
        "id": 42756,
        "name": "La Francheville",
        "latitude": "49.72923000",
        "longitude": "4.71273000"
    },
    {
        "id": 42757,
        "name": "La Fresnais",
        "latitude": "48.59478000",
        "longitude": "-1.84186000"
    },
    {
        "id": 42758,
        "name": "La Frette-sur-Seine",
        "latitude": "48.98057000",
        "longitude": "2.17866000"
    },
    {
        "id": 42760,
        "name": "La Gacilly",
        "latitude": "47.76562000",
        "longitude": "-2.13220000"
    },
    {
        "id": 42761,
        "name": "La Garde",
        "latitude": "43.12468000",
        "longitude": "6.01033000"
    },
    {
        "id": 42762,
        "name": "La Garde-Adhémar",
        "latitude": "44.39699000",
        "longitude": "4.75759000"
    },
    {
        "id": 42763,
        "name": "La Garde-Freinet",
        "latitude": "43.31710000",
        "longitude": "6.46946000"
    },
    {
        "id": 42764,
        "name": "La Garenne-Colombes",
        "latitude": "48.90472000",
        "longitude": "2.24690000"
    },
    {
        "id": 42765,
        "name": "La Garnache",
        "latitude": "46.89176000",
        "longitude": "-1.83163000"
    },
    {
        "id": 42766,
        "name": "La Gaubretière",
        "latitude": "46.94143000",
        "longitude": "-1.06215000"
    },
    {
        "id": 42767,
        "name": "La Gaude",
        "latitude": "43.72209000",
        "longitude": "7.15296000"
    },
    {
        "id": 42778,
        "name": "La Génétouze",
        "latitude": "46.73333000",
        "longitude": "-1.51667000"
    },
    {
        "id": 42768,
        "name": "La Glacerie",
        "latitude": "49.60517000",
        "longitude": "-1.58185000"
    },
    {
        "id": 42769,
        "name": "La Gorgue",
        "latitude": "50.63790000",
        "longitude": "2.71502000"
    },
    {
        "id": 42770,
        "name": "La Gouesnière",
        "latitude": "48.60467000",
        "longitude": "-1.89400000"
    },
    {
        "id": 42771,
        "name": "La Grand-Croix",
        "latitude": "45.50383000",
        "longitude": "4.55915000"
    },
    {
        "id": 42772,
        "name": "La Grande-Motte",
        "latitude": "43.56667000",
        "longitude": "4.08333000"
    },
    {
        "id": 42773,
        "name": "La Grande-Paroisse",
        "latitude": "48.38682000",
        "longitude": "2.90157000"
    },
    {
        "id": 42774,
        "name": "La Grigonnais",
        "latitude": "47.52724000",
        "longitude": "-1.66781000"
    },
    {
        "id": 42775,
        "name": "La Guerche-de-Bretagne",
        "latitude": "47.94112000",
        "longitude": "-1.22869000"
    },
    {
        "id": 42777,
        "name": "La Guérinière",
        "latitude": "46.96667000",
        "longitude": "-2.23333000"
    },
    {
        "id": 48033,
        "name": "la Guingueta d'Ix",
        "latitude": "42.43416000",
        "longitude": "1.94391000"
    },
    {
        "id": 42776,
        "name": "La Guyonnière",
        "latitude": "46.96476000",
        "longitude": "-1.25059000"
    },
    {
        "id": 42779,
        "name": "La Haie-Fouassière",
        "latitude": "47.15492000",
        "longitude": "-1.39794000"
    },
    {
        "id": 42782,
        "name": "La Haye-du-Puits",
        "latitude": "49.28973000",
        "longitude": "-1.54324000"
    },
    {
        "id": 42780,
        "name": "La Haye-Malherbe",
        "latitude": "49.22553000",
        "longitude": "1.06774000"
    },
    {
        "id": 42781,
        "name": "La Haye-Pesnel",
        "latitude": "48.79561000",
        "longitude": "-1.39655000"
    },
    {
        "id": 42783,
        "name": "La Houssaye-en-Brie",
        "latitude": "48.75381000",
        "longitude": "2.86552000"
    },
    {
        "id": 42784,
        "name": "La Jarne",
        "latitude": "46.12773000",
        "longitude": "-1.07259000"
    },
    {
        "id": 42785,
        "name": "La Jarrie",
        "latitude": "46.12879000",
        "longitude": "-1.00896000"
    },
    {
        "id": 42786,
        "name": "La Joliette",
        "latitude": "43.30485000",
        "longitude": "5.36654000"
    },
    {
        "id": 42787,
        "name": "La Jubaudière",
        "latitude": "47.17202000",
        "longitude": "-0.89215000"
    },
    {
        "id": 42788,
        "name": "La Jumellière",
        "latitude": "47.27976000",
        "longitude": "-0.72952000"
    },
    {
        "id": 42790,
        "name": "La Lande-de-Fronsac",
        "latitude": "44.98036000",
        "longitude": "-0.38048000"
    },
    {
        "id": 42789,
        "name": "La Lande-Patry",
        "latitude": "48.76202000",
        "longitude": "-0.59876000"
    },
    {
        "id": 42791,
        "name": "La Limouzinière",
        "latitude": "46.99274000",
        "longitude": "-1.59722000"
    },
    {
        "id": 42792,
        "name": "La Londe-les-Maures",
        "latitude": "43.13839000",
        "longitude": "6.23362000"
    },
    {
        "id": 42793,
        "name": "La Longueville",
        "latitude": "50.28939000",
        "longitude": "3.85672000"
    },
    {
        "id": 42794,
        "name": "La Loubière",
        "latitude": "44.37143000",
        "longitude": "2.66825000"
    },
    {
        "id": 42795,
        "name": "La Loupe",
        "latitude": "48.47320000",
        "longitude": "1.01585000"
    },
    {
        "id": 42796,
        "name": "La Machine",
        "latitude": "46.89226000",
        "longitude": "3.46426000"
    },
    {
        "id": 42797,
        "name": "La Madeleine",
        "latitude": "50.64603000",
        "longitude": "3.07585000"
    },
    {
        "id": 42798,
        "name": "La Madeleine-de-Nonancourt",
        "latitude": "48.77298000",
        "longitude": "1.20253000"
    },
    {
        "id": 42799,
        "name": "La Mailleraye-sur-Seine",
        "latitude": "49.48308000",
        "longitude": "0.77030000"
    },
    {
        "id": 42800,
        "name": "La Meauffe",
        "latitude": "49.17643000",
        "longitude": "-1.11119000"
    },
    {
        "id": 42821,
        "name": "La Méaugon",
        "latitude": "48.49933000",
        "longitude": "-2.83850000"
    },
    {
        "id": 42801,
        "name": "La Meignanne",
        "latitude": "47.51700000",
        "longitude": "-0.66861000"
    },
    {
        "id": 42802,
        "name": "La Meilleraie-Tillay",
        "latitude": "46.74059000",
        "longitude": "-0.84500000"
    },
    {
        "id": 42803,
        "name": "La Meilleraye-de-Bretagne",
        "latitude": "47.55941000",
        "longitude": "-1.40200000"
    },
    {
        "id": 42804,
        "name": "La Membrolle-sur-Choisille",
        "latitude": "47.43719000",
        "longitude": "0.63163000"
    },
    {
        "id": 42805,
        "name": "La Membrolle-sur-Longuenée",
        "latitude": "47.55991000",
        "longitude": "-0.67236000"
    },
    {
        "id": 42822,
        "name": "La Ménitré",
        "latitude": "47.40000000",
        "longitude": "-0.26667000"
    },
    {
        "id": 42823,
        "name": "La Mézière",
        "latitude": "48.21829000",
        "longitude": "-1.75514000"
    },
    {
        "id": 42806,
        "name": "La Milesse",
        "latitude": "48.06314000",
        "longitude": "0.13428000"
    },
    {
        "id": 42807,
        "name": "La Millère",
        "latitude": "43.28128000",
        "longitude": "5.49804000"
    },
    {
        "id": 42808,
        "name": "La Monnerie-le-Montel",
        "latitude": "45.87106000",
        "longitude": "3.60110000"
    },
    {
        "id": 42809,
        "name": "La Montagne",
        "latitude": "47.18651000",
        "longitude": "-1.68272000"
    },
    {
        "id": 42810,
        "name": "La Mothe-Achard",
        "latitude": "46.61586000",
        "longitude": "-1.65938000"
    },
    {
        "id": 42811,
        "name": "La Mothe-Saint-Héray",
        "latitude": "46.35934000",
        "longitude": "-0.11236000"
    },
    {
        "id": 42812,
        "name": "La Motte",
        "latitude": "48.23381000",
        "longitude": "-2.73271000"
    },
    {
        "id": 42813,
        "name": "La Motte",
        "latitude": "43.49403000",
        "longitude": "6.53519000"
    },
    {
        "id": 42817,
        "name": "La Motte-d’Aveillans",
        "latitude": "44.96015000",
        "longitude": "5.74409000"
    },
    {
        "id": 42814,
        "name": "La Motte-Saint-Jean",
        "latitude": "46.49594000",
        "longitude": "3.96396000"
    },
    {
        "id": 42815,
        "name": "La Motte-Saint-Martin",
        "latitude": "44.95131000",
        "longitude": "5.71814000"
    },
    {
        "id": 42816,
        "name": "La Motte-Servolex",
        "latitude": "45.59702000",
        "longitude": "5.86724000"
    },
    {
        "id": 42818,
        "name": "La Mulatière",
        "latitude": "45.72818000",
        "longitude": "4.81213000"
    },
    {
        "id": 42819,
        "name": "La Mure",
        "latitude": "44.90145000",
        "longitude": "5.78874000"
    },
    {
        "id": 42820,
        "name": "La Murette",
        "latitude": "45.37891000",
        "longitude": "5.54200000"
    },
    {
        "id": 42824,
        "name": "La Norville",
        "latitude": "48.58243000",
        "longitude": "2.26180000"
    },
    {
        "id": 42825,
        "name": "La Pacaudière",
        "latitude": "46.17879000",
        "longitude": "3.86252000"
    },
    {
        "id": 42826,
        "name": "La Page",
        "latitude": "43.26627000",
        "longitude": "5.37377000"
    },
    {
        "id": 42827,
        "name": "La Palme",
        "latitude": "42.97518000",
        "longitude": "2.99243000"
    },
    {
        "id": 42828,
        "name": "La Panouse",
        "latitude": "43.25576000",
        "longitude": "5.42963000"
    },
    {
        "id": 42829,
        "name": "La Penne-sur-Huveaune",
        "latitude": "43.28208000",
        "longitude": "5.51642000"
    },
    {
        "id": 42830,
        "name": "La Peyratte",
        "latitude": "46.67453000",
        "longitude": "-0.14912000"
    },
    {
        "id": 42831,
        "name": "La Plaine-sur-Mer",
        "latitude": "47.13619000",
        "longitude": "-2.19182000"
    },
    {
        "id": 42832,
        "name": "La Planche",
        "latitude": "47.01619000",
        "longitude": "-1.43159000"
    },
    {
        "id": 42833,
        "name": "La Pointe Rouge",
        "latitude": "43.23853000",
        "longitude": "5.37679000"
    },
    {
        "id": 42834,
        "name": "La Pomme",
        "latitude": "43.28987000",
        "longitude": "5.44153000"
    },
    {
        "id": 42835,
        "name": "La Pommeraie-sur-Sèvre",
        "latitude": "46.83535000",
        "longitude": "-0.77785000"
    },
    {
        "id": 42836,
        "name": "La Pommeraye",
        "latitude": "47.35562000",
        "longitude": "-0.85892000"
    },
    {
        "id": 42837,
        "name": "La Possonnière",
        "latitude": "47.37485000",
        "longitude": "-0.68539000"
    },
    {
        "id": 42838,
        "name": "La Pouëze",
        "latitude": "47.55357000",
        "longitude": "-0.80928000"
    },
    {
        "id": 42839,
        "name": "La Queue-en-Brie",
        "latitude": "48.78520000",
        "longitude": "2.58112000"
    },
    {
        "id": 42840,
        "name": "La Queue-les-Yvelines",
        "latitude": "48.80000000",
        "longitude": "1.76667000"
    },
    {
        "id": 42841,
        "name": "La Ravoire",
        "latitude": "45.56266000",
        "longitude": "5.95826000"
    },
    {
        "id": 42842,
        "name": "La Regrippière",
        "latitude": "47.18167000",
        "longitude": "-1.17620000"
    },
    {
        "id": 42843,
        "name": "La Remaudière",
        "latitude": "47.23762000",
        "longitude": "-1.24358000"
    },
    {
        "id": 42844,
        "name": "La Remuée",
        "latitude": "49.52773000",
        "longitude": "0.40283000"
    },
    {
        "id": 42869,
        "name": "La Réole",
        "latitude": "44.58201000",
        "longitude": "-0.03691000"
    },
    {
        "id": 42845,
        "name": "La Ricamarie",
        "latitude": "45.40536000",
        "longitude": "4.36944000"
    },
    {
        "id": 42846,
        "name": "La Richardais",
        "latitude": "48.60641000",
        "longitude": "-2.03639000"
    },
    {
        "id": 42847,
        "name": "La Riche",
        "latitude": "47.38996000",
        "longitude": "0.67072000"
    },
    {
        "id": 42849,
        "name": "La Rivière-de-Corps",
        "latitude": "48.28840000",
        "longitude": "4.01947000"
    },
    {
        "id": 42848,
        "name": "La Rivière-Saint-Sauveur",
        "latitude": "49.40821000",
        "longitude": "0.27030000"
    },
    {
        "id": 48034,
        "name": "la Roca d'Albera",
        "latitude": "42.52130000",
        "longitude": "2.93374000"
    },
    {
        "id": 42850,
        "name": "La Roche-Blanche",
        "latitude": "45.70062000",
        "longitude": "3.12629000"
    },
    {
        "id": 42851,
        "name": "La Roche-Chalais",
        "latitude": "45.15000000",
        "longitude": "0.01667000"
    },
    {
        "id": 42856,
        "name": "La Roche-de-Glun",
        "latitude": "45.01336000",
        "longitude": "4.84488000"
    },
    {
        "id": 42852,
        "name": "La Roche-Derrien",
        "latitude": "48.74627000",
        "longitude": "-3.26142000"
    },
    {
        "id": 42857,
        "name": "La Roche-des-Arnauds",
        "latitude": "44.56188000",
        "longitude": "5.95541000"
    },
    {
        "id": 42853,
        "name": "La Roche-Maurice",
        "latitude": "48.47455000",
        "longitude": "-4.20211000"
    },
    {
        "id": 42854,
        "name": "La Roche-Posay",
        "latitude": "46.78654000",
        "longitude": "0.81354000"
    },
    {
        "id": 42858,
        "name": "La Roche-sur-Foron",
        "latitude": "46.07111000",
        "longitude": "6.30450000"
    },
    {
        "id": 42859,
        "name": "La Roche-sur-Yon",
        "latitude": "46.66667000",
        "longitude": "-1.43333000"
    },
    {
        "id": 42855,
        "name": "La Roche-Vineuse",
        "latitude": "46.34524000",
        "longitude": "4.72023000"
    },
    {
        "id": 42860,
        "name": "La Rochefoucauld",
        "latitude": "45.74048000",
        "longitude": "0.38564000"
    },
    {
        "id": 42861,
        "name": "La Rochelle",
        "latitude": "46.16667000",
        "longitude": "-1.15000000"
    },
    {
        "id": 42862,
        "name": "La Rochette",
        "latitude": "45.45758000",
        "longitude": "6.11356000"
    },
    {
        "id": 42863,
        "name": "La Rochette",
        "latitude": "48.50882000",
        "longitude": "2.66357000"
    },
    {
        "id": 42864,
        "name": "La Romagne",
        "latitude": "47.06119000",
        "longitude": "-1.02000000"
    },
    {
        "id": 42865,
        "name": "La Roque-d’Anthéron",
        "latitude": "43.71544000",
        "longitude": "5.30973000"
    },
    {
        "id": 42866,
        "name": "La Roquebrussanne",
        "latitude": "43.34030000",
        "longitude": "5.97603000"
    },
    {
        "id": 42867,
        "name": "La Roquette-sur-Siagne",
        "latitude": "43.59873000",
        "longitude": "6.95671000"
    },
    {
        "id": 42868,
        "name": "La Rose",
        "latitude": "43.32889000",
        "longitude": "5.42859000"
    },
    {
        "id": 42870,
        "name": "La Salle-et-Chapelle-Aubry",
        "latitude": "47.25013000",
        "longitude": "-0.97512000"
    },
    {
        "id": 42871,
        "name": "La Salvetat-Peyralès",
        "latitude": "44.21940000",
        "longitude": "2.20297000"
    },
    {
        "id": 42872,
        "name": "La Salvetat-Saint-Gilles",
        "latitude": "43.57622000",
        "longitude": "1.27192000"
    },
    {
        "id": 42873,
        "name": "La Salvetat-sur-Agout",
        "latitude": "43.60000000",
        "longitude": "2.71667000"
    },
    {
        "id": 42874,
        "name": "La Saussaye",
        "latitude": "49.25936000",
        "longitude": "0.98094000"
    },
    {
        "id": 42875,
        "name": "La Sauve",
        "latitude": "44.76667000",
        "longitude": "-0.31667000"
    },
    {
        "id": 42881,
        "name": "La Séauve-sur-Semène",
        "latitude": "45.29554000",
        "longitude": "4.25023000"
    },
    {
        "id": 42882,
        "name": "La Séguinière",
        "latitude": "47.06357000",
        "longitude": "-0.93857000"
    },
    {
        "id": 42876,
        "name": "La Selle-la-Forge",
        "latitude": "48.73295000",
        "longitude": "-0.54103000"
    },
    {
        "id": 42877,
        "name": "La Sentinelle",
        "latitude": "50.35115000",
        "longitude": "3.48425000"
    },
    {
        "id": 42878,
        "name": "La Seyne-sur-Mer",
        "latitude": "43.10322000",
        "longitude": "5.87816000"
    },
    {
        "id": 42879,
        "name": "La Souterraine",
        "latitude": "46.23714000",
        "longitude": "1.48701000"
    },
    {
        "id": 42880,
        "name": "La Suze-sur-Sarthe",
        "latitude": "47.88753000",
        "longitude": "0.02519000"
    },
    {
        "id": 42883,
        "name": "La Talaudière",
        "latitude": "45.47603000",
        "longitude": "4.42607000"
    },
    {
        "id": 42884,
        "name": "La Tardière",
        "latitude": "46.66096000",
        "longitude": "-0.73143000"
    },
    {
        "id": 42885,
        "name": "La Terrasse",
        "latitude": "45.32052000",
        "longitude": "5.92761000"
    },
    {
        "id": 42886,
        "name": "La Tessoualle",
        "latitude": "47.00476000",
        "longitude": "-0.85119000"
    },
    {
        "id": 42887,
        "name": "La Teste-de-Buch",
        "latitude": "44.62875000",
        "longitude": "-1.14059000"
    },
    {
        "id": 42888,
        "name": "La Timone",
        "latitude": "43.28847000",
        "longitude": "5.41447000"
    },
    {
        "id": 42889,
        "name": "La Tour",
        "latitude": "46.13312000",
        "longitude": "6.43109000"
    },
    {
        "id": 42890,
        "name": "La Tour-de-Salvagny",
        "latitude": "45.81374000",
        "longitude": "4.71670000"
    },
    {
        "id": 42891,
        "name": "La Tour-du-Crieu",
        "latitude": "43.10471000",
        "longitude": "1.65275000"
    },
    {
        "id": 42892,
        "name": "La Tour-du-Pin",
        "latitude": "45.56600000",
        "longitude": "5.44487000"
    },
    {
        "id": 42893,
        "name": "La Tour-en-Jarez",
        "latitude": "45.48347000",
        "longitude": "4.39064000"
    },
    {
        "id": 42894,
        "name": "La Tour-sur-Orb",
        "latitude": "43.65186000",
        "longitude": "3.15071000"
    },
    {
        "id": 42895,
        "name": "La Tourlandry",
        "latitude": "47.14375000",
        "longitude": "-0.69688000"
    },
    {
        "id": 42896,
        "name": "La Tranche-sur-Mer",
        "latitude": "46.34300000",
        "longitude": "-1.43700000"
    },
    {
        "id": 42897,
        "name": "La Tremblade",
        "latitude": "45.76806000",
        "longitude": "-1.14265000"
    },
    {
        "id": 42898,
        "name": "La Trinité",
        "latitude": "43.74050000",
        "longitude": "7.31400000"
    },
    {
        "id": 42899,
        "name": "La Trinité-sur-Mer",
        "latitude": "47.58680000",
        "longitude": "-3.02994000"
    },
    {
        "id": 42900,
        "name": "La Tronche",
        "latitude": "45.20507000",
        "longitude": "5.74629000"
    },
    {
        "id": 42901,
        "name": "La Turballe",
        "latitude": "47.34686000",
        "longitude": "-2.50734000"
    },
    {
        "id": 42902,
        "name": "La Turbie",
        "latitude": "43.74540000",
        "longitude": "7.40046000"
    },
    {
        "id": 42903,
        "name": "La Valbarelle",
        "latitude": "43.28494000",
        "longitude": "5.45387000"
    },
    {
        "id": 42904,
        "name": "La Valentine",
        "latitude": "43.29757000",
        "longitude": "5.48432000"
    },
    {
        "id": 42905,
        "name": "La Valette-du-Var",
        "latitude": "43.13763000",
        "longitude": "5.98317000"
    },
    {
        "id": 42906,
        "name": "La Varenne",
        "latitude": "47.31107000",
        "longitude": "-1.31976000"
    },
    {
        "id": 42907,
        "name": "La Vaupalière",
        "latitude": "49.48789000",
        "longitude": "0.99630000"
    },
    {
        "id": 42908,
        "name": "La Verpillière",
        "latitude": "45.63417000",
        "longitude": "5.14560000"
    },
    {
        "id": 42909,
        "name": "La Verrie",
        "latitude": "46.96131000",
        "longitude": "-0.99596000"
    },
    {
        "id": 42910,
        "name": "La Verrière",
        "latitude": "48.75200000",
        "longitude": "1.94649000"
    },
    {
        "id": 42911,
        "name": "La Vespière",
        "latitude": "49.01975000",
        "longitude": "0.41250000"
    },
    {
        "id": 42912,
        "name": "La Ville-aux-Clercs",
        "latitude": "47.91835000",
        "longitude": "1.08521000"
    },
    {
        "id": 42913,
        "name": "La Ville-aux-Dames",
        "latitude": "47.39551000",
        "longitude": "0.76837000"
    },
    {
        "id": 42914,
        "name": "La Ville-du-Bois",
        "latitude": "48.65521000",
        "longitude": "2.26833000"
    },
    {
        "id": 42915,
        "name": "La Villedieu-du-Clain",
        "latitude": "46.45559000",
        "longitude": "0.36917000"
    },
    {
        "id": 42916,
        "name": "La Villette",
        "latitude": "43.31181000",
        "longitude": "5.37391000"
    },
    {
        "id": 42917,
        "name": "La Viste",
        "latitude": "43.35936000",
        "longitude": "5.35649000"
    },
    {
        "id": 42918,
        "name": "La Voulte-sur-Rhône",
        "latitude": "44.79958000",
        "longitude": "4.77925000"
    },
    {
        "id": 42919,
        "name": "La Vraie-Croix",
        "latitude": "47.68954000",
        "longitude": "-2.54274000"
    },
    {
        "id": 42920,
        "name": "La Wantzenau",
        "latitude": "48.65786000",
        "longitude": "7.83068000"
    },
    {
        "id": 42921,
        "name": "Labaroche",
        "latitude": "48.11076000",
        "longitude": "7.19244000"
    },
    {
        "id": 42922,
        "name": "Labarthe-Rivière",
        "latitude": "43.08100000",
        "longitude": "0.67134000"
    },
    {
        "id": 42923,
        "name": "Labarthe-sur-Lèze",
        "latitude": "43.45206000",
        "longitude": "1.39968000"
    },
    {
        "id": 42924,
        "name": "Labastide-Beauvoir",
        "latitude": "43.48148000",
        "longitude": "1.66543000"
    },
    {
        "id": 42925,
        "name": "Labastide-Rouairoux",
        "latitude": "43.47566000",
        "longitude": "2.63929000"
    },
    {
        "id": 42926,
        "name": "Labastide-Saint-Georges",
        "latitude": "43.69962000",
        "longitude": "1.84585000"
    },
    {
        "id": 42927,
        "name": "Labastide-Saint-Pierre",
        "latitude": "43.91769000",
        "longitude": "1.36628000"
    },
    {
        "id": 42928,
        "name": "Labastide-Saint-Sernin",
        "latitude": "43.73743000",
        "longitude": "1.46985000"
    },
    {
        "id": 42929,
        "name": "Labastidette",
        "latitude": "43.45952000",
        "longitude": "1.24525000"
    },
    {
        "id": 42930,
        "name": "Labatut",
        "latitude": "43.55000000",
        "longitude": "-0.98333000"
    },
    {
        "id": 42939,
        "name": "Labège",
        "latitude": "43.53039000",
        "longitude": "1.53596000"
    },
    {
        "id": 42940,
        "name": "Labégude",
        "latitude": "44.64496000",
        "longitude": "4.36939000"
    },
    {
        "id": 42931,
        "name": "Labenne",
        "latitude": "43.59464000",
        "longitude": "-1.42559000"
    },
    {
        "id": 42932,
        "name": "Labeuvrière",
        "latitude": "50.51915000",
        "longitude": "2.56268000"
    },
    {
        "id": 42933,
        "name": "Lablachère",
        "latitude": "44.46355000",
        "longitude": "4.21439000"
    },
    {
        "id": 42934,
        "name": "Laboissière-en-Thelle",
        "latitude": "49.29329000",
        "longitude": "2.16150000"
    },
    {
        "id": 42935,
        "name": "Labouheyre",
        "latitude": "44.21297000",
        "longitude": "-0.92062000"
    },
    {
        "id": 42936,
        "name": "Labourse",
        "latitude": "50.49883000",
        "longitude": "2.68121000"
    },
    {
        "id": 42937,
        "name": "Labruguière",
        "latitude": "43.53858000",
        "longitude": "2.26392000"
    },
    {
        "id": 42938,
        "name": "Labry",
        "latitude": "49.17289000",
        "longitude": "5.88087000"
    },
    {
        "id": 42941,
        "name": "Lacanau",
        "latitude": "44.97779000",
        "longitude": "-1.07621000"
    },
    {
        "id": 42942,
        "name": "Lacapelle-Marival",
        "latitude": "44.72773000",
        "longitude": "1.92465000"
    },
    {
        "id": 42943,
        "name": "Lacasse",
        "latitude": "43.39873000",
        "longitude": "1.26951000"
    },
    {
        "id": 42944,
        "name": "Lacaune",
        "latitude": "43.70660000",
        "longitude": "2.69293000"
    },
    {
        "id": 42945,
        "name": "Lachapelle-aux-Pots",
        "latitude": "49.44905000",
        "longitude": "1.90228000"
    },
    {
        "id": 42946,
        "name": "Lachapelle-sous-Aubenas",
        "latitude": "44.56421000",
        "longitude": "4.36393000"
    },
    {
        "id": 42947,
        "name": "Lacroix-Falgarde",
        "latitude": "43.49968000",
        "longitude": "1.40985000"
    },
    {
        "id": 42948,
        "name": "Lacroix-Saint-Ouen",
        "latitude": "49.35685000",
        "longitude": "2.78803000"
    },
    {
        "id": 42949,
        "name": "Lacrouzette",
        "latitude": "43.66030000",
        "longitude": "2.34838000"
    },
    {
        "id": 42950,
        "name": "Ladignac-le-Long",
        "latitude": "45.58256000",
        "longitude": "1.11359000"
    },
    {
        "id": 42951,
        "name": "Ladoix-Serrigny",
        "latitude": "47.06667000",
        "longitude": "4.88333000"
    },
    {
        "id": 42952,
        "name": "Ladon",
        "latitude": "48.00259000",
        "longitude": "2.53724000"
    },
    {
        "id": 42953,
        "name": "Lafrançaise",
        "latitude": "44.12791000",
        "longitude": "1.24141000"
    },
    {
        "id": 42954,
        "name": "Lagardelle-sur-Lèze",
        "latitude": "43.41143000",
        "longitude": "1.38920000"
    },
    {
        "id": 42955,
        "name": "Lagarrigue",
        "latitude": "43.57765000",
        "longitude": "2.27784000"
    },
    {
        "id": 42956,
        "name": "Lagnes",
        "latitude": "43.89348000",
        "longitude": "5.11454000"
    },
    {
        "id": 42957,
        "name": "Lagnieu",
        "latitude": "45.90237000",
        "longitude": "5.34399000"
    },
    {
        "id": 42958,
        "name": "Lagny-le-Sec",
        "latitude": "49.08744000",
        "longitude": "2.74502000"
    },
    {
        "id": 42959,
        "name": "Lagny-sur-Marne",
        "latitude": "48.86667000",
        "longitude": "2.71667000"
    },
    {
        "id": 42960,
        "name": "Lagor",
        "latitude": "43.38333000",
        "longitude": "-0.65000000"
    },
    {
        "id": 42961,
        "name": "Lagorce",
        "latitude": "45.06667000",
        "longitude": "-0.13333000"
    },
    {
        "id": 42962,
        "name": "Lagord",
        "latitude": "46.18822000",
        "longitude": "-1.15355000"
    },
    {
        "id": 42963,
        "name": "Lagrave",
        "latitude": "43.89734000",
        "longitude": "1.99268000"
    },
    {
        "id": 42964,
        "name": "Laguenne",
        "latitude": "45.24218000",
        "longitude": "1.78135000"
    },
    {
        "id": 42965,
        "name": "Laguiole",
        "latitude": "44.68465000",
        "longitude": "2.84666000"
    },
    {
        "id": 42966,
        "name": "Lahonce",
        "latitude": "43.48248000",
        "longitude": "-1.39101000"
    },
    {
        "id": 42968,
        "name": "Laigné-en-Belin",
        "latitude": "47.87706000",
        "longitude": "0.22795000"
    },
    {
        "id": 42967,
        "name": "Laigneville",
        "latitude": "49.29149000",
        "longitude": "2.44581000"
    },
    {
        "id": 42970,
        "name": "Laillé",
        "latitude": "47.97848000",
        "longitude": "-1.71676000"
    },
    {
        "id": 42969,
        "name": "Lailly-en-Val",
        "latitude": "47.76962000",
        "longitude": "1.68657000"
    },
    {
        "id": 42971,
        "name": "Laissac",
        "latitude": "44.38085000",
        "longitude": "2.82154000"
    },
    {
        "id": 42972,
        "name": "Laiz",
        "latitude": "46.25054000",
        "longitude": "4.88955000"
    },
    {
        "id": 42973,
        "name": "Lalbenque",
        "latitude": "44.33929000",
        "longitude": "1.54501000"
    },
    {
        "id": 42974,
        "name": "Lalevade-d’Ardèche",
        "latitude": "44.64780000",
        "longitude": "4.32303000"
    },
    {
        "id": 42975,
        "name": "Lalinde",
        "latitude": "44.83621000",
        "longitude": "0.73075000"
    },
    {
        "id": 42976,
        "name": "Lallaing",
        "latitude": "50.39012000",
        "longitude": "3.16949000"
    },
    {
        "id": 42977,
        "name": "Laloubère",
        "latitude": "43.20430000",
        "longitude": "0.07296000"
    },
    {
        "id": 42978,
        "name": "Lamagistère",
        "latitude": "44.12488000",
        "longitude": "0.82486000"
    },
    {
        "id": 42979,
        "name": "Lamalou-les-Bains",
        "latitude": "43.59786000",
        "longitude": "3.08052000"
    },
    {
        "id": 42980,
        "name": "Lamanon",
        "latitude": "43.69979000",
        "longitude": "5.08871000"
    },
    {
        "id": 42981,
        "name": "Lamarche",
        "latitude": "48.06967000",
        "longitude": "5.78141000"
    },
    {
        "id": 42982,
        "name": "Lamarche-sur-Saône",
        "latitude": "47.26946000",
        "longitude": "5.38589000"
    },
    {
        "id": 42983,
        "name": "Lamarque",
        "latitude": "45.09536000",
        "longitude": "-0.71892000"
    },
    {
        "id": 42984,
        "name": "Lamastre",
        "latitude": "44.98672000",
        "longitude": "4.57975000"
    },
    {
        "id": 42985,
        "name": "Lamballe",
        "latitude": "48.46762000",
        "longitude": "-2.51436000"
    },
    {
        "id": 42986,
        "name": "Lambersart",
        "latitude": "50.65000000",
        "longitude": "3.03333000"
    },
    {
        "id": 42987,
        "name": "Lambesc",
        "latitude": "43.65382000",
        "longitude": "5.26211000"
    },
    {
        "id": 42988,
        "name": "Lambres-lez-Douai",
        "latitude": "50.35000000",
        "longitude": "3.06667000"
    },
    {
        "id": 42989,
        "name": "Lamonzie-Saint-Martin",
        "latitude": "44.84713000",
        "longitude": "0.39102000"
    },
    {
        "id": 42990,
        "name": "Lamorlaye",
        "latitude": "49.16246000",
        "longitude": "2.44687000"
    },
    {
        "id": 42991,
        "name": "Lamothe-Montravel",
        "latitude": "44.85000000",
        "longitude": "0.03333000"
    },
    {
        "id": 42992,
        "name": "Lamotte-Beuvron",
        "latitude": "47.60200000",
        "longitude": "2.02837000"
    },
    {
        "id": 42993,
        "name": "Lampaul-Guimiliau",
        "latitude": "48.49156000",
        "longitude": "-4.04144000"
    },
    {
        "id": 42994,
        "name": "Lampaul-Plouarzel",
        "latitude": "48.44727000",
        "longitude": "-4.76045000"
    },
    {
        "id": 42995,
        "name": "Lampertheim",
        "latitude": "48.65149000",
        "longitude": "7.69876000"
    },
    {
        "id": 42996,
        "name": "Lamure-sur-Azergues",
        "latitude": "46.06255000",
        "longitude": "4.49195000"
    },
    {
        "id": 42997,
        "name": "Lancieux",
        "latitude": "48.60767000",
        "longitude": "-2.15030000"
    },
    {
        "id": 43056,
        "name": "Lançon-Provence",
        "latitude": "43.59266000",
        "longitude": "5.12789000"
    },
    {
        "id": 42998,
        "name": "Lancrans",
        "latitude": "46.12729000",
        "longitude": "5.83277000"
    },
    {
        "id": 42999,
        "name": "Landas",
        "latitude": "50.47269000",
        "longitude": "3.30367000"
    },
    {
        "id": 43000,
        "name": "Landaul",
        "latitude": "47.74785000",
        "longitude": "-3.07694000"
    },
    {
        "id": 43015,
        "name": "Landéan",
        "latitude": "48.41253000",
        "longitude": "-1.15245000"
    },
    {
        "id": 43016,
        "name": "Landéda",
        "latitude": "48.58733000",
        "longitude": "-4.57100000"
    },
    {
        "id": 43017,
        "name": "Landéhen",
        "latitude": "48.43022000",
        "longitude": "-2.53999000"
    },
    {
        "id": 43001,
        "name": "Landeleau",
        "latitude": "48.22734000",
        "longitude": "-3.72850000"
    },
    {
        "id": 43002,
        "name": "Landemont",
        "latitude": "47.26536000",
        "longitude": "-1.23989000"
    },
    {
        "id": 43003,
        "name": "Landerneau",
        "latitude": "48.45252000",
        "longitude": "-4.25252000"
    },
    {
        "id": 43004,
        "name": "Landeronde",
        "latitude": "46.65722000",
        "longitude": "-1.56953000"
    },
    {
        "id": 43005,
        "name": "Landes",
        "latitude": "43.97543000",
        "longitude": "-0.74241000"
    },
    {
        "id": 43018,
        "name": "Landévant",
        "latitude": "47.76499000",
        "longitude": "-3.12221000"
    },
    {
        "id": 43006,
        "name": "Landiras",
        "latitude": "44.56702000",
        "longitude": "-0.41536000"
    },
    {
        "id": 43007,
        "name": "Landivisiau",
        "latitude": "48.50784000",
        "longitude": "-4.06833000"
    },
    {
        "id": 43008,
        "name": "Landivy",
        "latitude": "48.47868000",
        "longitude": "-1.03320000"
    },
    {
        "id": 43009,
        "name": "Landrecies",
        "latitude": "50.12499000",
        "longitude": "3.69171000"
    },
    {
        "id": 43010,
        "name": "Landres",
        "latitude": "49.32044000",
        "longitude": "5.80060000"
    },
    {
        "id": 43011,
        "name": "Landrévarzec",
        "latitude": "48.09177000",
        "longitude": "-4.06044000"
    },
    {
        "id": 43012,
        "name": "Landser",
        "latitude": "47.68501000",
        "longitude": "7.38998000"
    },
    {
        "id": 43013,
        "name": "Landudec",
        "latitude": "47.99983000",
        "longitude": "-4.33641000"
    },
    {
        "id": 43014,
        "name": "Landunvez",
        "latitude": "48.53466000",
        "longitude": "-4.72545000"
    },
    {
        "id": 43019,
        "name": "Lanester",
        "latitude": "47.76132000",
        "longitude": "-3.33965000"
    },
    {
        "id": 43020,
        "name": "Laneuveville-devant-Nancy",
        "latitude": "48.65659000",
        "longitude": "6.22658000"
    },
    {
        "id": 43021,
        "name": "Langeac",
        "latitude": "45.10028000",
        "longitude": "3.49420000"
    },
    {
        "id": 43022,
        "name": "Langeais",
        "latitude": "47.32587000",
        "longitude": "0.40136000"
    },
    {
        "id": 43023,
        "name": "Langlade",
        "latitude": "43.80284000",
        "longitude": "4.25232000"
    },
    {
        "id": 43024,
        "name": "Langoat",
        "latitude": "48.75061000",
        "longitude": "-3.28121000"
    },
    {
        "id": 43025,
        "name": "Langogne",
        "latitude": "44.72726000",
        "longitude": "3.85539000"
    },
    {
        "id": 43026,
        "name": "Langoiran",
        "latitude": "44.70869000",
        "longitude": "-0.39368000"
    },
    {
        "id": 43027,
        "name": "Langon",
        "latitude": "47.72057000",
        "longitude": "-1.85010000"
    },
    {
        "id": 43028,
        "name": "Langon",
        "latitude": "44.55310000",
        "longitude": "-0.24951000"
    },
    {
        "id": 43029,
        "name": "Langonnet",
        "latitude": "48.10511000",
        "longitude": "-3.49356000"
    },
    {
        "id": 43030,
        "name": "Langres",
        "latitude": "47.86263000",
        "longitude": "5.33308000"
    },
    {
        "id": 43031,
        "name": "Langrune-sur-Mer",
        "latitude": "49.32346000",
        "longitude": "-0.37346000"
    },
    {
        "id": 43032,
        "name": "Langueux",
        "latitude": "48.49500000",
        "longitude": "-2.71850000"
    },
    {
        "id": 43033,
        "name": "Languidic",
        "latitude": "47.83266000",
        "longitude": "-3.15821000"
    },
    {
        "id": 43034,
        "name": "Lanmeur",
        "latitude": "48.64733000",
        "longitude": "-3.71645000"
    },
    {
        "id": 43035,
        "name": "Lannemezan",
        "latitude": "43.12517000",
        "longitude": "0.38401000"
    },
    {
        "id": 43036,
        "name": "Lannilis",
        "latitude": "48.57091000",
        "longitude": "-4.52233000"
    },
    {
        "id": 43037,
        "name": "Lannion",
        "latitude": "48.73255000",
        "longitude": "-3.45542000"
    },
    {
        "id": 43038,
        "name": "Lannoy",
        "latitude": "50.66674000",
        "longitude": "3.21012000"
    },
    {
        "id": 43039,
        "name": "Lanobre",
        "latitude": "45.43889000",
        "longitude": "2.53443000"
    },
    {
        "id": 43040,
        "name": "Lanouaille",
        "latitude": "45.39517000",
        "longitude": "1.13968000"
    },
    {
        "id": 43041,
        "name": "Lanouée",
        "latitude": "48.00225000",
        "longitude": "-2.58157000"
    },
    {
        "id": 43042,
        "name": "Lanquetot",
        "latitude": "49.58527000",
        "longitude": "0.52637000"
    },
    {
        "id": 43043,
        "name": "Lanrivoaré",
        "latitude": "48.47310000",
        "longitude": "-4.63870000"
    },
    {
        "id": 43044,
        "name": "Lanrodec",
        "latitude": "48.51622000",
        "longitude": "-3.03122000"
    },
    {
        "id": 43045,
        "name": "Lans-en-Vercors",
        "latitude": "45.12852000",
        "longitude": "5.58599000"
    },
    {
        "id": 43046,
        "name": "Lansargues",
        "latitude": "43.65157000",
        "longitude": "4.07495000"
    },
    {
        "id": 43047,
        "name": "Lanta",
        "latitude": "43.56667000",
        "longitude": "1.66667000"
    },
    {
        "id": 43048,
        "name": "Lantic",
        "latitude": "48.60641000",
        "longitude": "-2.88200000"
    },
    {
        "id": 43049,
        "name": "Lanton",
        "latitude": "44.70478000",
        "longitude": "-1.03562000"
    },
    {
        "id": 43050,
        "name": "Lantosque",
        "latitude": "43.97446000",
        "longitude": "7.31174000"
    },
    {
        "id": 43051,
        "name": "Lantriac",
        "latitude": "45.00050000",
        "longitude": "4.00444000"
    },
    {
        "id": 43052,
        "name": "Lanvallay",
        "latitude": "48.45543000",
        "longitude": "-2.02803000"
    },
    {
        "id": 43054,
        "name": "Lanvénégen",
        "latitude": "47.99811000",
        "longitude": "-3.54134000"
    },
    {
        "id": 43055,
        "name": "Lanvéoc",
        "latitude": "48.28799000",
        "longitude": "-4.46277000"
    },
    {
        "id": 43053,
        "name": "Lanvollon",
        "latitude": "48.63117000",
        "longitude": "-2.98641000"
    },
    {
        "id": 43057,
        "name": "Laon",
        "latitude": "49.56310000",
        "longitude": "3.62714000"
    },
    {
        "id": 43058,
        "name": "Lapalisse",
        "latitude": "46.24835000",
        "longitude": "3.63605000"
    },
    {
        "id": 43059,
        "name": "Lapalud",
        "latitude": "44.30780000",
        "longitude": "4.68946000"
    },
    {
        "id": 43060,
        "name": "Lapeyrouse-Fossat",
        "latitude": "43.69973000",
        "longitude": "1.51049000"
    },
    {
        "id": 43061,
        "name": "Laplume",
        "latitude": "44.11292000",
        "longitude": "0.52975000"
    },
    {
        "id": 43062,
        "name": "Lapoutroie",
        "latitude": "48.15398000",
        "longitude": "7.16714000"
    },
    {
        "id": 43063,
        "name": "Lapte",
        "latitude": "45.18593000",
        "longitude": "4.21699000"
    },
    {
        "id": 43064,
        "name": "Lapugnoy",
        "latitude": "50.51635000",
        "longitude": "2.53460000"
    },
    {
        "id": 43065,
        "name": "Laragne-Montéglin",
        "latitude": "44.31667000",
        "longitude": "5.81667000"
    },
    {
        "id": 43066,
        "name": "Larajasse",
        "latitude": "45.61399000",
        "longitude": "4.50113000"
    },
    {
        "id": 43083,
        "name": "Larçay",
        "latitude": "47.36649000",
        "longitude": "0.78145000"
    },
    {
        "id": 43067,
        "name": "Larchamp",
        "latitude": "48.36131000",
        "longitude": "-0.99900000"
    },
    {
        "id": 43068,
        "name": "Larche",
        "latitude": "45.12048000",
        "longitude": "1.41566000"
    },
    {
        "id": 43069,
        "name": "Lardy",
        "latitude": "48.51847000",
        "longitude": "2.27360000"
    },
    {
        "id": 43070,
        "name": "Larequille",
        "latitude": "46.25189000",
        "longitude": "2.70163000"
    },
    {
        "id": 43071,
        "name": "Largentière",
        "latitude": "44.54272000",
        "longitude": "4.29342000"
    },
    {
        "id": 43072,
        "name": "Larmor-Baden",
        "latitude": "47.58341000",
        "longitude": "-2.89440000"
    },
    {
        "id": 43073,
        "name": "Larmor-Plage",
        "latitude": "47.70646000",
        "longitude": "-3.38339000"
    },
    {
        "id": 43074,
        "name": "Laroche-Saint-Cydroine",
        "latitude": "47.96801000",
        "longitude": "3.48467000"
    },
    {
        "id": 43075,
        "name": "Laroque",
        "latitude": "43.92320000",
        "longitude": "3.72397000"
    },
    {
        "id": 43077,
        "name": "Laroque-d’Olmes",
        "latitude": "42.96785000",
        "longitude": "1.86677000"
    },
    {
        "id": 43076,
        "name": "Laroque-Timbaut",
        "latitude": "44.28238000",
        "longitude": "0.76274000"
    },
    {
        "id": 43078,
        "name": "Laroquebrou",
        "latitude": "44.96855000",
        "longitude": "2.19158000"
    },
    {
        "id": 43079,
        "name": "Larressore",
        "latitude": "43.36792000",
        "longitude": "-1.43976000"
    },
    {
        "id": 43080,
        "name": "Larringes",
        "latitude": "46.36919000",
        "longitude": "6.57125000"
    },
    {
        "id": 43081,
        "name": "Laruns",
        "latitude": "42.98826000",
        "longitude": "-0.42658000"
    },
    {
        "id": 43082,
        "name": "Laruscade",
        "latitude": "45.11667000",
        "longitude": "-0.33333000"
    },
    {
        "id": 43084,
        "name": "Lasalle",
        "latitude": "44.04530000",
        "longitude": "3.85477000"
    },
    {
        "id": 43085,
        "name": "Lassay-les-Châteaux",
        "latitude": "48.43835000",
        "longitude": "-0.49758000"
    },
    {
        "id": 43086,
        "name": "Lasseube",
        "latitude": "43.23333000",
        "longitude": "-0.48333000"
    },
    {
        "id": 43087,
        "name": "Lassigny",
        "latitude": "49.58828000",
        "longitude": "2.84289000"
    },
    {
        "id": 43088,
        "name": "Lassy",
        "latitude": "47.97829000",
        "longitude": "-1.87190000"
    },
    {
        "id": 43089,
        "name": "Lathus-Saint-Rémy",
        "latitude": "46.33333000",
        "longitude": "0.96667000"
    },
    {
        "id": 43090,
        "name": "Latillé",
        "latitude": "46.61818000",
        "longitude": "0.07630000"
    },
    {
        "id": 43091,
        "name": "Latour-Bas-Elne",
        "latitude": "42.60646000",
        "longitude": "3.00201000"
    },
    {
        "id": 43092,
        "name": "Latresne",
        "latitude": "44.78601000",
        "longitude": "-0.48994000"
    },
    {
        "id": 43093,
        "name": "Lattes",
        "latitude": "43.56752000",
        "longitude": "3.90460000"
    },
    {
        "id": 43094,
        "name": "Laudun-l'Ardoise",
        "latitude": "44.10000000",
        "longitude": "4.66667000"
    },
    {
        "id": 43095,
        "name": "Launac",
        "latitude": "43.74357000",
        "longitude": "1.18172000"
    },
    {
        "id": 43096,
        "name": "Launaguet",
        "latitude": "43.67818000",
        "longitude": "1.45603000"
    },
    {
        "id": 43097,
        "name": "Laure-Minervois",
        "latitude": "43.27106000",
        "longitude": "2.52031000"
    },
    {
        "id": 43098,
        "name": "Laurens",
        "latitude": "43.52238000",
        "longitude": "3.19714000"
    },
    {
        "id": 43099,
        "name": "Lauris",
        "latitude": "43.74716000",
        "longitude": "5.31346000"
    },
    {
        "id": 43100,
        "name": "Laussonne",
        "latitude": "44.96990000",
        "longitude": "4.05204000"
    },
    {
        "id": 43101,
        "name": "Lautenbach",
        "latitude": "47.94055000",
        "longitude": "7.15768000"
    },
    {
        "id": 43102,
        "name": "Lautenbachzell",
        "latitude": "47.94124000",
        "longitude": "7.14643000"
    },
    {
        "id": 43103,
        "name": "Lauterbourg",
        "latitude": "48.97515000",
        "longitude": "8.17846000"
    },
    {
        "id": 43104,
        "name": "Lautrec",
        "latitude": "43.70591000",
        "longitude": "2.13925000"
    },
    {
        "id": 43105,
        "name": "Lauwin-Planque",
        "latitude": "50.39024000",
        "longitude": "3.04510000"
    },
    {
        "id": 43106,
        "name": "Lauzerte",
        "latitude": "44.25535000",
        "longitude": "1.13817000"
    },
    {
        "id": 43107,
        "name": "Laval",
        "latitude": "48.07320000",
        "longitude": "-0.76340000"
    },
    {
        "id": 43108,
        "name": "Laval-Pradel",
        "latitude": "44.20665000",
        "longitude": "4.06591000"
    },
    {
        "id": 43109,
        "name": "Lavalette",
        "latitude": "43.18428000",
        "longitude": "2.26825000"
    },
    {
        "id": 43110,
        "name": "Lavans-lès-Saint-Claude",
        "latitude": "46.38484000",
        "longitude": "5.78172000"
    },
    {
        "id": 43111,
        "name": "Lavardac",
        "latitude": "44.17785000",
        "longitude": "0.29825000"
    },
    {
        "id": 43112,
        "name": "Lavault-Sainte-Anne",
        "latitude": "46.30999000",
        "longitude": "2.60027000"
    },
    {
        "id": 43113,
        "name": "Lavaur",
        "latitude": "43.69847000",
        "longitude": "1.81858000"
    },
    {
        "id": 43114,
        "name": "Lavelanet",
        "latitude": "42.93127000",
        "longitude": "1.84656000"
    },
    {
        "id": 43115,
        "name": "Laventie",
        "latitude": "50.62818000",
        "longitude": "2.77076000"
    },
    {
        "id": 43116,
        "name": "Lavernose-Lacasse",
        "latitude": "43.39700000",
        "longitude": "1.26205000"
    },
    {
        "id": 43120,
        "name": "Lavérune",
        "latitude": "43.58639000",
        "longitude": "3.80611000"
    },
    {
        "id": 43117,
        "name": "Lavilledieu",
        "latitude": "44.57545000",
        "longitude": "4.45314000"
    },
    {
        "id": 43118,
        "name": "Lavit",
        "latitude": "43.95674000",
        "longitude": "0.92010000"
    },
    {
        "id": 43119,
        "name": "Lavoux",
        "latitude": "46.59566000",
        "longitude": "0.53012000"
    },
    {
        "id": 43121,
        "name": "Laxou",
        "latitude": "48.68372000",
        "longitude": "6.14929000"
    },
    {
        "id": 43122,
        "name": "Lay-Saint-Christophe",
        "latitude": "48.74926000",
        "longitude": "6.19735000"
    },
    {
        "id": 43123,
        "name": "Layrac",
        "latitude": "44.13449000",
        "longitude": "0.66176000"
    },
    {
        "id": 43124,
        "name": "Le Ban Saint-Martin",
        "latitude": "49.12250000",
        "longitude": "6.15111000"
    },
    {
        "id": 43125,
        "name": "Le Bar-sur-Loup",
        "latitude": "43.70000000",
        "longitude": "6.98333000"
    },
    {
        "id": 43126,
        "name": "Le Barcarès",
        "latitude": "42.78773000",
        "longitude": "3.03656000"
    },
    {
        "id": 43127,
        "name": "Le Barp",
        "latitude": "44.60833000",
        "longitude": "-0.76948000"
    },
    {
        "id": 43128,
        "name": "Le Beausset",
        "latitude": "43.19822000",
        "longitude": "5.80267000"
    },
    {
        "id": 43129,
        "name": "Le Bignon",
        "latitude": "47.09722000",
        "longitude": "-1.49167000"
    },
    {
        "id": 43130,
        "name": "Le Blanc",
        "latitude": "46.63371000",
        "longitude": "1.06272000"
    },
    {
        "id": 43131,
        "name": "Le Blanc-Mesnil",
        "latitude": "48.93872000",
        "longitude": "2.46138000"
    },
    {
        "id": 43133,
        "name": "Le Bois-d'Oingt",
        "latitude": "45.91718000",
        "longitude": "4.58259000"
    },
    {
        "id": 43132,
        "name": "Le Bois-Plage-en-Ré",
        "latitude": "46.18659000",
        "longitude": "-1.39267000"
    },
    {
        "id": 43134,
        "name": "Le Bono",
        "latitude": "47.64129000",
        "longitude": "-2.94902000"
    },
    {
        "id": 43135,
        "name": "Le Bosc-Roger-en-Roumois",
        "latitude": "49.28333000",
        "longitude": "0.93333000"
    },
    {
        "id": 43136,
        "name": "Le Boupère",
        "latitude": "46.79595000",
        "longitude": "-0.92654000"
    },
    {
        "id": 43137,
        "name": "Le Bourg-d’Oisans",
        "latitude": "45.05483000",
        "longitude": "6.03366000"
    },
    {
        "id": 43138,
        "name": "Le Bourget",
        "latitude": "48.93405000",
        "longitude": "2.43584000"
    },
    {
        "id": 43139,
        "name": "Le Bourget-du-Lac",
        "latitude": "45.65362000",
        "longitude": "5.85490000"
    },
    {
        "id": 43140,
        "name": "Le Bourgneuf-la-Forêt",
        "latitude": "48.16325000",
        "longitude": "-0.96963000"
    },
    {
        "id": 43141,
        "name": "Le Bouscat",
        "latitude": "44.86600000",
        "longitude": "-0.59411000"
    },
    {
        "id": 43142,
        "name": "Le Bousquet-d’Orb",
        "latitude": "43.69242000",
        "longitude": "3.16746000"
    },
    {
        "id": 43143,
        "name": "Le Breil-sur-Mérize",
        "latitude": "48.00755000",
        "longitude": "0.47808000"
    },
    {
        "id": 43144,
        "name": "Le Breuil",
        "latitude": "46.80654000",
        "longitude": "4.46953000"
    },
    {
        "id": 43145,
        "name": "Le Breuil-sur-Couze",
        "latitude": "45.46860000",
        "longitude": "3.26310000"
    },
    {
        "id": 43146,
        "name": "Le Broc",
        "latitude": "43.80878000",
        "longitude": "7.16882000"
    },
    {
        "id": 43147,
        "name": "Le Brusquet",
        "latitude": "44.16126000",
        "longitude": "6.30925000"
    },
    {
        "id": 43148,
        "name": "Le Bugue",
        "latitude": "44.91847000",
        "longitude": "0.92714000"
    },
    {
        "id": 43149,
        "name": "Le Buisson-de-Cadouin",
        "latitude": "44.85000000",
        "longitude": "0.91667000"
    },
    {
        "id": 43150,
        "name": "Le Cabot",
        "latitude": "43.25884000",
        "longitude": "5.41729000"
    },
    {
        "id": 43151,
        "name": "Le Cailar",
        "latitude": "43.67923000",
        "longitude": "4.23576000"
    },
    {
        "id": 43152,
        "name": "Le Camas",
        "latitude": "43.29469000",
        "longitude": "5.39415000"
    },
    {
        "id": 43153,
        "name": "Le Canet",
        "latitude": "43.32611000",
        "longitude": "5.37230000"
    },
    {
        "id": 43154,
        "name": "Le Cannet",
        "latitude": "43.57662000",
        "longitude": "7.01912000"
    },
    {
        "id": 43155,
        "name": "Le Cannet-des-Maures",
        "latitude": "43.39236000",
        "longitude": "6.33966000"
    },
    {
        "id": 43156,
        "name": "Le Cap d'Agde",
        "latitude": "43.27796000",
        "longitude": "3.51357000"
    },
    {
        "id": 43157,
        "name": "Le Castellet",
        "latitude": "43.20291000",
        "longitude": "5.77657000"
    },
    {
        "id": 43158,
        "name": "Le Cellier",
        "latitude": "47.31988000",
        "longitude": "-1.34584000"
    },
    {
        "id": 43159,
        "name": "Le Cendre",
        "latitude": "45.72240000",
        "longitude": "3.18711000"
    },
    {
        "id": 43160,
        "name": "Le Chambon-Feugerolles",
        "latitude": "45.39145000",
        "longitude": "4.32149000"
    },
    {
        "id": 43161,
        "name": "Le Chambon-sur-Lignon",
        "latitude": "45.06097000",
        "longitude": "4.30241000"
    },
    {
        "id": 43163,
        "name": "Le Champ-près-Froges",
        "latitude": "45.28045000",
        "longitude": "5.93977000"
    },
    {
        "id": 43162,
        "name": "Le Champ-Saint-Père",
        "latitude": "46.50773000",
        "longitude": "-1.34726000"
    },
    {
        "id": 43164,
        "name": "Le Chapitre",
        "latitude": "43.30053000",
        "longitude": "5.38433000"
    },
    {
        "id": 43168,
        "name": "Le Châtelet",
        "latitude": "46.64247000",
        "longitude": "2.28229000"
    },
    {
        "id": 43169,
        "name": "Le Châtelet-en-Brie",
        "latitude": "48.50695000",
        "longitude": "2.79163000"
    },
    {
        "id": 43165,
        "name": "Le Chesnay",
        "latitude": "48.82220000",
        "longitude": "2.12213000"
    },
    {
        "id": 43166,
        "name": "Le Cheylard",
        "latitude": "44.90623000",
        "longitude": "4.42406000"
    },
    {
        "id": 43167,
        "name": "Le Cheylas",
        "latitude": "45.37064000",
        "longitude": "5.99277000"
    },
    {
        "id": 43170,
        "name": "Le Conquet",
        "latitude": "48.36053000",
        "longitude": "-4.77086000"
    },
    {
        "id": 43171,
        "name": "Le Coteau",
        "latitude": "46.01919000",
        "longitude": "4.09230000"
    },
    {
        "id": 43172,
        "name": "Le Coudray",
        "latitude": "48.42115000",
        "longitude": "1.50057000"
    },
    {
        "id": 43173,
        "name": "Le Coudray-Montceaux",
        "latitude": "48.56376000",
        "longitude": "2.50013000"
    },
    {
        "id": 43178,
        "name": "Le Crès",
        "latitude": "43.64807000",
        "longitude": "3.93976000"
    },
    {
        "id": 43174,
        "name": "Le Crest",
        "latitude": "45.68666000",
        "longitude": "3.12735000"
    },
    {
        "id": 43175,
        "name": "Le Creusot",
        "latitude": "46.80714000",
        "longitude": "4.41632000"
    },
    {
        "id": 43176,
        "name": "Le Croisic",
        "latitude": "47.29189000",
        "longitude": "-2.51380000"
    },
    {
        "id": 43177,
        "name": "Le Crotoy",
        "latitude": "50.21600000",
        "longitude": "1.62500000"
    },
    {
        "id": 43179,
        "name": "Le Donjon",
        "latitude": "46.35058000",
        "longitude": "3.79269000"
    },
    {
        "id": 43180,
        "name": "Le Dorat",
        "latitude": "46.21514000",
        "longitude": "1.08153000"
    },
    {
        "id": 43181,
        "name": "Le Doulieu",
        "latitude": "50.68194000",
        "longitude": "2.71741000"
    },
    {
        "id": 43182,
        "name": "Le Drennec",
        "latitude": "48.53475000",
        "longitude": "-4.37008000"
    },
    {
        "id": 43183,
        "name": "Le Faou",
        "latitude": "48.29456000",
        "longitude": "-4.17927000"
    },
    {
        "id": 43184,
        "name": "Le Faouët",
        "latitude": "48.03299000",
        "longitude": "-3.49048000"
    },
    {
        "id": 43185,
        "name": "Le Fauga",
        "latitude": "43.39619000",
        "longitude": "1.29571000"
    },
    {
        "id": 43186,
        "name": "Le Fenouiller",
        "latitude": "46.71901000",
        "longitude": "-1.90175000"
    },
    {
        "id": 43187,
        "name": "Le Fief-Sauvin",
        "latitude": "47.22143000",
        "longitude": "-1.04214000"
    },
    {
        "id": 43188,
        "name": "Le Fleix",
        "latitude": "44.86667000",
        "longitude": "0.25000000"
    },
    {
        "id": 43192,
        "name": "Le Fœil",
        "latitude": "48.43333000",
        "longitude": "-2.91667000"
    },
    {
        "id": 43189,
        "name": "Le Folgoët",
        "latitude": "48.56359000",
        "longitude": "-4.33641000"
    },
    {
        "id": 43190,
        "name": "Le Fousseret",
        "latitude": "43.28205000",
        "longitude": "1.06624000"
    },
    {
        "id": 43191,
        "name": "Le Fuilet",
        "latitude": "47.28345000",
        "longitude": "-1.11310000"
    },
    {
        "id": 43193,
        "name": "Le Garric",
        "latitude": "44.00932000",
        "longitude": "2.16431000"
    },
    {
        "id": 43203,
        "name": "Le Gâvre",
        "latitude": "47.52131000",
        "longitude": "-1.74905000"
    },
    {
        "id": 43194,
        "name": "Le Grand-Bornand",
        "latitude": "45.94211000",
        "longitude": "6.43077000"
    },
    {
        "id": 43195,
        "name": "Le Grand-Bourg",
        "latitude": "46.16018000",
        "longitude": "1.64465000"
    },
    {
        "id": 43196,
        "name": "Le Grand-Lemps",
        "latitude": "45.39646000",
        "longitude": "5.42010000"
    },
    {
        "id": 43197,
        "name": "Le Grand-Lucé",
        "latitude": "47.86618000",
        "longitude": "0.46919000"
    },
    {
        "id": 43198,
        "name": "Le Grand-Pressigny",
        "latitude": "46.92017000",
        "longitude": "0.80380000"
    },
    {
        "id": 43199,
        "name": "Le Grand-Quevilly",
        "latitude": "49.40076000",
        "longitude": "1.04582000"
    },
    {
        "id": 43200,
        "name": "Le Grau-du-Roi",
        "latitude": "43.53881000",
        "longitude": "4.13559000"
    },
    {
        "id": 43201,
        "name": "Le Gua",
        "latitude": "45.72614000",
        "longitude": "-0.94468000"
    },
    {
        "id": 43202,
        "name": "Le Gua",
        "latitude": "45.01667000",
        "longitude": "5.61667000"
    },
    {
        "id": 43204,
        "name": "Le Haillan",
        "latitude": "44.87225000",
        "longitude": "-0.67965000"
    },
    {
        "id": 43205,
        "name": "Le Havre",
        "latitude": "49.49380000",
        "longitude": "0.10767000"
    },
    {
        "id": 43206,
        "name": "Le Houga",
        "latitude": "43.77464000",
        "longitude": "-0.17833000"
    },
    {
        "id": 43207,
        "name": "Le Houlme",
        "latitude": "49.50972000",
        "longitude": "1.03537000"
    },
    {
        "id": 43208,
        "name": "Le Kremlin-Bicêtre",
        "latitude": "48.81471000",
        "longitude": "2.36073000"
    },
    {
        "id": 43209,
        "name": "Le Landreau",
        "latitude": "47.20512000",
        "longitude": "-1.30452000"
    },
    {
        "id": 43210,
        "name": "Le Langon",
        "latitude": "46.43850000",
        "longitude": "-0.94767000"
    },
    {
        "id": 43211,
        "name": "Le Lardin-Saint-Lazare",
        "latitude": "45.13333000",
        "longitude": "1.21667000"
    },
    {
        "id": 43212,
        "name": "Le Lavandou",
        "latitude": "43.13700000",
        "longitude": "6.36600000"
    },
    {
        "id": 43213,
        "name": "Le Longeron",
        "latitude": "47.01845000",
        "longitude": "-1.05763000"
    },
    {
        "id": 43214,
        "name": "Le Loroux-Bottereau",
        "latitude": "47.23763000",
        "longitude": "-1.34952000"
    },
    {
        "id": 43215,
        "name": "Le Louroux-Béconnais",
        "latitude": "47.52211000",
        "longitude": "-0.88663000"
    },
    {
        "id": 43216,
        "name": "Le Luart",
        "latitude": "48.07053000",
        "longitude": "0.58564000"
    },
    {
        "id": 43217,
        "name": "Le Luc",
        "latitude": "43.39453000",
        "longitude": "6.31253000"
    },
    {
        "id": 43218,
        "name": "Le Lude",
        "latitude": "47.64648000",
        "longitude": "0.15664000"
    },
    {
        "id": 43219,
        "name": "Le Malzieu-Ville",
        "latitude": "44.85699000",
        "longitude": "3.33302000"
    },
    {
        "id": 43220,
        "name": "Le Manoir",
        "latitude": "49.31355000",
        "longitude": "1.20455000"
    },
    {
        "id": 43221,
        "name": "Le Mans",
        "latitude": "48.00039000",
        "longitude": "0.20471000"
    },
    {
        "id": 43222,
        "name": "Le Mas-d’Agenais",
        "latitude": "44.41033000",
        "longitude": "0.21869000"
    },
    {
        "id": 43223,
        "name": "Le May-sur-Èvre",
        "latitude": "47.13714000",
        "longitude": "-0.89227000"
    },
    {
        "id": 43224,
        "name": "Le Mayet-de-Montagne",
        "latitude": "46.07091000",
        "longitude": "3.66665000"
    },
    {
        "id": 43239,
        "name": "Le Mée-sur-Seine",
        "latitude": "48.53157000",
        "longitude": "2.62829000"
    },
    {
        "id": 43240,
        "name": "Le Ménil",
        "latitude": "47.90477000",
        "longitude": "6.78209000"
    },
    {
        "id": 43225,
        "name": "Le Merlan",
        "latitude": "43.33875000",
        "longitude": "5.40773000"
    },
    {
        "id": 43228,
        "name": "Le Mesnil-en-Thelle",
        "latitude": "49.17830000",
        "longitude": "2.28573000"
    },
    {
        "id": 43229,
        "name": "Le Mesnil-en-Vallée",
        "latitude": "47.36551000",
        "longitude": "-0.93485000"
    },
    {
        "id": 43226,
        "name": "Le Mesnil-Esnard",
        "latitude": "49.41319000",
        "longitude": "1.14521000"
    },
    {
        "id": 43230,
        "name": "Le Mesnil-le-Roi",
        "latitude": "48.93825000",
        "longitude": "2.12554000"
    },
    {
        "id": 43227,
        "name": "Le Mesnil-Saint-Denis",
        "latitude": "48.74485000",
        "longitude": "1.95594000"
    },
    {
        "id": 43231,
        "name": "Le Mesnil-sur-Oger",
        "latitude": "48.94708000",
        "longitude": "4.02263000"
    },
    {
        "id": 43232,
        "name": "Le Meux",
        "latitude": "49.36718000",
        "longitude": "2.74374000"
    },
    {
        "id": 43233,
        "name": "Le Minihic-sur-Rance",
        "latitude": "48.57835000",
        "longitude": "-2.00876000"
    },
    {
        "id": 43234,
        "name": "Le Molay-Littry",
        "latitude": "49.24265000",
        "longitude": "-0.87238000"
    },
    {
        "id": 43236,
        "name": "Le Monastère",
        "latitude": "44.34165000",
        "longitude": "2.57956000"
    },
    {
        "id": 43235,
        "name": "Le Monastier-sur-Gazeille",
        "latitude": "44.93333000",
        "longitude": "4.00000000"
    },
    {
        "id": 43237,
        "name": "Le Monêtier-les-Bains",
        "latitude": "44.97604000",
        "longitude": "6.50898000"
    },
    {
        "id": 43238,
        "name": "Le Muy",
        "latitude": "43.47280000",
        "longitude": "6.56637000"
    },
    {
        "id": 43241,
        "name": "Le Neubourg",
        "latitude": "49.15048000",
        "longitude": "0.91020000"
    },
    {
        "id": 43242,
        "name": "Le Nouvion-en-Thiérache",
        "latitude": "50.01625000",
        "longitude": "3.78509000"
    },
    {
        "id": 43243,
        "name": "Le Palais",
        "latitude": "47.34590000",
        "longitude": "-3.15488000"
    },
    {
        "id": 43244,
        "name": "Le Palais-sur-Vienne",
        "latitude": "45.86380000",
        "longitude": "1.32207000"
    },
    {
        "id": 43245,
        "name": "Le Pallet",
        "latitude": "47.13809000",
        "longitude": "-1.33476000"
    },
    {
        "id": 43246,
        "name": "Le Passage",
        "latitude": "44.20143000",
        "longitude": "0.60275000"
    },
    {
        "id": 43288,
        "name": "Le Péage-de-Roussillon",
        "latitude": "45.37380000",
        "longitude": "4.79727000"
    },
    {
        "id": 43289,
        "name": "Le Pêchereau",
        "latitude": "46.57677000",
        "longitude": "1.54743000"
    },
    {
        "id": 43247,
        "name": "Le Pecq",
        "latitude": "48.89317000",
        "longitude": "2.10371000"
    },
    {
        "id": 43248,
        "name": "Le Pellerin",
        "latitude": "47.19915000",
        "longitude": "-1.75514000"
    },
    {
        "id": 43249,
        "name": "Le Perray-en-Yvelines",
        "latitude": "48.69441000",
        "longitude": "1.85643000"
    },
    {
        "id": 43252,
        "name": "Le Perréon",
        "latitude": "46.06316000",
        "longitude": "4.60045000"
    },
    {
        "id": 43250,
        "name": "Le Perreux-sur-Marne",
        "latitude": "48.85000000",
        "longitude": "2.50000000"
    },
    {
        "id": 43251,
        "name": "Le Perrier",
        "latitude": "46.81932000",
        "longitude": "-1.99306000"
    },
    {
        "id": 43253,
        "name": "Le Pertre",
        "latitude": "48.03373000",
        "longitude": "-1.03735000"
    },
    {
        "id": 43254,
        "name": "Le Petit-Quevilly",
        "latitude": "49.41338000",
        "longitude": "1.06155000"
    },
    {
        "id": 43255,
        "name": "Le Pharo",
        "latitude": "43.29140000",
        "longitude": "5.35829000"
    },
    {
        "id": 43256,
        "name": "Le Pian-Médoc",
        "latitude": "44.95537000",
        "longitude": "-0.66227000"
    },
    {
        "id": 43257,
        "name": "Le Pin",
        "latitude": "48.91523000",
        "longitude": "2.62841000"
    },
    {
        "id": 43258,
        "name": "Le Pin",
        "latitude": "45.45582000",
        "longitude": "5.50497000"
    },
    {
        "id": 43259,
        "name": "Le Pin",
        "latitude": "46.86179000",
        "longitude": "-0.65440000"
    },
    {
        "id": 43260,
        "name": "Le Pin-en-Mauges",
        "latitude": "47.25357000",
        "longitude": "-0.89917000"
    },
    {
        "id": 43261,
        "name": "Le Pizou",
        "latitude": "45.01667000",
        "longitude": "0.06667000"
    },
    {
        "id": 43262,
        "name": "Le Plan-de-la-Tour",
        "latitude": "43.33333000",
        "longitude": "6.55000000"
    },
    {
        "id": 43263,
        "name": "Le Plessis-Belleville",
        "latitude": "49.09501000",
        "longitude": "2.75614000"
    },
    {
        "id": 43264,
        "name": "Le Plessis-Bouchard",
        "latitude": "49.00000000",
        "longitude": "2.23333000"
    },
    {
        "id": 43265,
        "name": "Le Plessis-Brion",
        "latitude": "49.46419000",
        "longitude": "2.89096000"
    },
    {
        "id": 43266,
        "name": "Le Plessis-Grammoire",
        "latitude": "47.49884000",
        "longitude": "-0.43021000"
    },
    {
        "id": 43267,
        "name": "Le Plessis-Macé",
        "latitude": "47.54304000",
        "longitude": "-0.67436000"
    },
    {
        "id": 43268,
        "name": "Le Plessis-Pâté",
        "latitude": "48.61078000",
        "longitude": "2.32318000"
    },
    {
        "id": 43269,
        "name": "Le Plessis-Robinson",
        "latitude": "48.78889000",
        "longitude": "2.27078000"
    },
    {
        "id": 43270,
        "name": "Le Plessis-Trévise",
        "latitude": "48.81074000",
        "longitude": "2.57363000"
    },
    {
        "id": 43271,
        "name": "Le Poinçonnet",
        "latitude": "46.76410000",
        "longitude": "1.71790000"
    },
    {
        "id": 43272,
        "name": "Le Poiré-sur-Vie",
        "latitude": "46.76921000",
        "longitude": "-1.50938000"
    },
    {
        "id": 43273,
        "name": "Le Pont-de-Beauvoisin",
        "latitude": "45.53694000",
        "longitude": "5.67333000"
    },
    {
        "id": 43274,
        "name": "Le Pont-de-Claix",
        "latitude": "45.12321000",
        "longitude": "5.69782000"
    },
    {
        "id": 43275,
        "name": "Le Pontet",
        "latitude": "43.96119000",
        "longitude": "4.86008000"
    },
    {
        "id": 43276,
        "name": "Le Porge",
        "latitude": "44.87277000",
        "longitude": "-1.08889000"
    },
    {
        "id": 43277,
        "name": "Le Port-Marly",
        "latitude": "48.89016000",
        "longitude": "2.11139000"
    },
    {
        "id": 43278,
        "name": "Le Portel",
        "latitude": "50.70559000",
        "longitude": "1.57574000"
    },
    {
        "id": 43279,
        "name": "Le Pouget",
        "latitude": "43.59195000",
        "longitude": "3.52423000"
    },
    {
        "id": 43280,
        "name": "Le Poujol-sur-Orb",
        "latitude": "43.57919000",
        "longitude": "3.06156000"
    },
    {
        "id": 43281,
        "name": "Le Pouliguen",
        "latitude": "47.26973000",
        "longitude": "-2.42780000"
    },
    {
        "id": 43282,
        "name": "Le Pouzin",
        "latitude": "44.75173000",
        "longitude": "4.74798000"
    },
    {
        "id": 43283,
        "name": "Le Pradet",
        "latitude": "43.10545000",
        "longitude": "6.02350000"
    },
    {
        "id": 43284,
        "name": "Le Pré-Saint-Gervais",
        "latitude": "48.88549000",
        "longitude": "2.40422000"
    },
    {
        "id": 43287,
        "name": "Le Puy-en-Velay",
        "latitude": "45.04366000",
        "longitude": "3.88523000"
    },
    {
        "id": 43285,
        "name": "Le Puy-Notre-Dame",
        "latitude": "47.12551000",
        "longitude": "-0.23572000"
    },
    {
        "id": 43286,
        "name": "Le Puy-Sainte-Réparade",
        "latitude": "43.66393000",
        "longitude": "5.43493000"
    },
    {
        "id": 43290,
        "name": "Le Quesnoy",
        "latitude": "50.24797000",
        "longitude": "3.63656000"
    },
    {
        "id": 43291,
        "name": "Le Raincy",
        "latitude": "48.89916000",
        "longitude": "2.52298000"
    },
    {
        "id": 43292,
        "name": "Le Redon",
        "latitude": "43.24604000",
        "longitude": "5.42889000"
    },
    {
        "id": 43293,
        "name": "Le Relecq-Kerhuon",
        "latitude": "48.40691000",
        "longitude": "-4.39359000"
    },
    {
        "id": 43294,
        "name": "Le Revest-les-Eaux",
        "latitude": "43.17612000",
        "longitude": "5.92667000"
    },
    {
        "id": 43295,
        "name": "Le Rheu",
        "latitude": "48.10228000",
        "longitude": "-1.79733000"
    },
    {
        "id": 43296,
        "name": "Le Rouret",
        "latitude": "43.67873000",
        "longitude": "7.01521000"
    },
    {
        "id": 43297,
        "name": "Le Rove",
        "latitude": "43.36987000",
        "longitude": "5.25112000"
    },
    {
        "id": 43298,
        "name": "Le Russey",
        "latitude": "47.16055000",
        "longitude": "6.72912000"
    },
    {
        "id": 43299,
        "name": "Le Sappey-en-Chartreuse",
        "latitude": "45.26235000",
        "longitude": "5.77476000"
    },
    {
        "id": 43300,
        "name": "Le Sequestre",
        "latitude": "43.91076000",
        "longitude": "2.11804000"
    },
    {
        "id": 43301,
        "name": "Le Soler",
        "latitude": "42.68101000",
        "longitude": "2.79335000"
    },
    {
        "id": 43302,
        "name": "Le Sourn",
        "latitude": "48.04262000",
        "longitude": "-2.98790000"
    },
    {
        "id": 43303,
        "name": "Le Syndicat",
        "latitude": "48.01733000",
        "longitude": "6.68436000"
    },
    {
        "id": 43304,
        "name": "Le Taillan-Médoc",
        "latitude": "44.90521000",
        "longitude": "-0.67060000"
    },
    {
        "id": 43305,
        "name": "Le Tallud",
        "latitude": "46.62911000",
        "longitude": "-0.29979000"
    },
    {
        "id": 43306,
        "name": "Le Teich",
        "latitude": "44.63177000",
        "longitude": "-1.02155000"
    },
    {
        "id": 43307,
        "name": "Le Teil",
        "latitude": "44.54531000",
        "longitude": "4.68223000"
    },
    {
        "id": 43308,
        "name": "Le Teilleul",
        "latitude": "48.53758000",
        "longitude": "-0.87304000"
    },
    {
        "id": 43309,
        "name": "Le Temple-de-Bretagne",
        "latitude": "47.32737000",
        "longitude": "-1.78904000"
    },
    {
        "id": 43310,
        "name": "Le Theil-Bocage",
        "latitude": "48.88333000",
        "longitude": "-0.71667000"
    },
    {
        "id": 43311,
        "name": "Le Theil-de-Bretagne",
        "latitude": "47.91950000",
        "longitude": "-1.42975000"
    },
    {
        "id": 43312,
        "name": "Le Thillay",
        "latitude": "49.00659000",
        "longitude": "2.47218000"
    },
    {
        "id": 43313,
        "name": "Le Thillot",
        "latitude": "47.87941000",
        "longitude": "6.76279000"
    },
    {
        "id": 43314,
        "name": "Le Tholonet",
        "latitude": "43.52176000",
        "longitude": "5.51115000"
    },
    {
        "id": 43315,
        "name": "Le Tholy",
        "latitude": "48.08229000",
        "longitude": "6.74351000"
    },
    {
        "id": 43316,
        "name": "Le Thor",
        "latitude": "43.92943000",
        "longitude": "4.99543000"
    },
    {
        "id": 43317,
        "name": "Le Thoronet",
        "latitude": "43.45174000",
        "longitude": "6.30391000"
    },
    {
        "id": 43318,
        "name": "Le Thou",
        "latitude": "46.08333000",
        "longitude": "-0.91667000"
    },
    {
        "id": 43319,
        "name": "Le Thuit-Signol",
        "latitude": "49.26487000",
        "longitude": "0.93910000"
    },
    {
        "id": 43320,
        "name": "Le Tignet",
        "latitude": "43.63979000",
        "longitude": "6.84625000"
    },
    {
        "id": 43321,
        "name": "Le Touquet-Paris-Plage",
        "latitude": "50.52432000",
        "longitude": "1.58571000"
    },
    {
        "id": 43322,
        "name": "Le Touvet",
        "latitude": "45.35909000",
        "longitude": "5.95283000"
    },
    {
        "id": 43323,
        "name": "Le Trait",
        "latitude": "49.46895000",
        "longitude": "0.81634000"
    },
    {
        "id": 43324,
        "name": "Le Tréport",
        "latitude": "50.05979000",
        "longitude": "1.37583000"
    },
    {
        "id": 43325,
        "name": "Le Trévoux",
        "latitude": "47.89478000",
        "longitude": "-3.64080000"
    },
    {
        "id": 43326,
        "name": "Le Val",
        "latitude": "43.43928000",
        "longitude": "6.07335000"
    },
    {
        "id": 43327,
        "name": "Le Val-Saint-Germain",
        "latitude": "48.56601000",
        "longitude": "2.06471000"
    },
    {
        "id": 43328,
        "name": "Le Val-Saint-Père",
        "latitude": "48.66177000",
        "longitude": "-1.37653000"
    },
    {
        "id": 43329,
        "name": "Le Vaudreuil",
        "latitude": "49.25407000",
        "longitude": "1.20960000"
    },
    {
        "id": 43330,
        "name": "Le Verdon-sur-Mer",
        "latitude": "45.54490000",
        "longitude": "-1.06225000"
    },
    {
        "id": 43331,
        "name": "Le Versoud",
        "latitude": "45.21988000",
        "longitude": "5.86502000"
    },
    {
        "id": 43337,
        "name": "Le Vésinet",
        "latitude": "48.89281000",
        "longitude": "2.13308000"
    },
    {
        "id": 43332,
        "name": "Le Vieil-Baugé",
        "latitude": "47.53193000",
        "longitude": "-0.11888000"
    },
    {
        "id": 43333,
        "name": "Le Vieux-Marché",
        "latitude": "48.60000000",
        "longitude": "-3.45000000"
    },
    {
        "id": 43334,
        "name": "Le Vigan",
        "latitude": "44.74075000",
        "longitude": "1.43963000"
    },
    {
        "id": 43335,
        "name": "Le Vigen",
        "latitude": "45.75149000",
        "longitude": "1.28865000"
    },
    {
        "id": 43336,
        "name": "Le Vivier-sur-Mer",
        "latitude": "48.60169000",
        "longitude": "-1.77303000"
    },
    {
        "id": 43338,
        "name": "Lecelles",
        "latitude": "50.46779000",
        "longitude": "3.40096000"
    },
    {
        "id": 43681,
        "name": "Lécluse",
        "latitude": "50.27671000",
        "longitude": "3.04045000"
    },
    {
        "id": 43682,
        "name": "Lécousse",
        "latitude": "48.36458000",
        "longitude": "-1.21289000"
    },
    {
        "id": 43339,
        "name": "Lectoure",
        "latitude": "43.93464000",
        "longitude": "0.62107000"
    },
    {
        "id": 43683,
        "name": "Lédenon",
        "latitude": "43.91349000",
        "longitude": "4.51444000"
    },
    {
        "id": 43340,
        "name": "Ledeuix",
        "latitude": "43.21667000",
        "longitude": "-0.61667000"
    },
    {
        "id": 43684,
        "name": "Lédignan",
        "latitude": "43.98690000",
        "longitude": "4.10644000"
    },
    {
        "id": 43341,
        "name": "Leers",
        "latitude": "50.68217000",
        "longitude": "3.24429000"
    },
    {
        "id": 43342,
        "name": "Leffrinckoucke",
        "latitude": "51.03457000",
        "longitude": "2.46452000"
    },
    {
        "id": 43343,
        "name": "Leforest",
        "latitude": "50.43866000",
        "longitude": "3.06480000"
    },
    {
        "id": 43344,
        "name": "Legé",
        "latitude": "46.88484000",
        "longitude": "-1.60127000"
    },
    {
        "id": 43685,
        "name": "Léguevin",
        "latitude": "43.60028000",
        "longitude": "1.23236000"
    },
    {
        "id": 43686,
        "name": "Léhon",
        "latitude": "48.44432000",
        "longitude": "-2.04578000"
    },
    {
        "id": 43345,
        "name": "Lembach",
        "latitude": "49.00362000",
        "longitude": "7.78986000"
    },
    {
        "id": 43346,
        "name": "Lemberg",
        "latitude": "49.00199000",
        "longitude": "7.37889000"
    },
    {
        "id": 43347,
        "name": "Lembras",
        "latitude": "44.88431000",
        "longitude": "0.52657000"
    },
    {
        "id": 43348,
        "name": "Lempdes-sur-Allagnon",
        "latitude": "45.38333000",
        "longitude": "3.28333000"
    },
    {
        "id": 43349,
        "name": "Lencloître",
        "latitude": "46.81622000",
        "longitude": "0.32827000"
    },
    {
        "id": 43350,
        "name": "Lens",
        "latitude": "50.43302000",
        "longitude": "2.82791000"
    },
    {
        "id": 43351,
        "name": "Lent",
        "latitude": "46.12027000",
        "longitude": "5.19352000"
    },
    {
        "id": 43352,
        "name": "Lentigny",
        "latitude": "45.99391000",
        "longitude": "3.97918000"
    },
    {
        "id": 43353,
        "name": "Lentilly",
        "latitude": "45.81873000",
        "longitude": "4.66304000"
    },
    {
        "id": 43687,
        "name": "Léognan",
        "latitude": "44.73548000",
        "longitude": "-0.59738000"
    },
    {
        "id": 43688,
        "name": "Léon",
        "latitude": "43.87676000",
        "longitude": "-1.30057000"
    },
    {
        "id": 43354,
        "name": "Lepuix",
        "latitude": "47.76050000",
        "longitude": "6.81434000"
    },
    {
        "id": 43691,
        "name": "Léré",
        "latitude": "47.46867000",
        "longitude": "2.86981000"
    },
    {
        "id": 43689,
        "name": "Lérouville",
        "latitude": "48.79261000",
        "longitude": "5.54063000"
    },
    {
        "id": 43690,
        "name": "Léry",
        "latitude": "49.28585000",
        "longitude": "1.20768000"
    },
    {
        "id": 43355,
        "name": "Les Abrets",
        "latitude": "45.54466000",
        "longitude": "5.58021000"
    },
    {
        "id": 43356,
        "name": "Les Accates",
        "latitude": "43.29989000",
        "longitude": "5.49726000"
    },
    {
        "id": 43357,
        "name": "Les Ageux",
        "latitude": "49.31667000",
        "longitude": "2.60000000"
    },
    {
        "id": 43358,
        "name": "Les Aix-d’Angillon",
        "latitude": "47.19652000",
        "longitude": "2.56506000"
    },
    {
        "id": 43359,
        "name": "Les Allues",
        "latitude": "45.43255000",
        "longitude": "6.55558000"
    },
    {
        "id": 43360,
        "name": "Les Alluets-le-Roi",
        "latitude": "48.91379000",
        "longitude": "1.91810000"
    },
    {
        "id": 43361,
        "name": "Les Ancizes",
        "latitude": "45.92524000",
        "longitude": "2.81265000"
    },
    {
        "id": 43362,
        "name": "Les Andelys",
        "latitude": "49.24557000",
        "longitude": "1.41168000"
    },
    {
        "id": 43363,
        "name": "Les Angles",
        "latitude": "43.95806000",
        "longitude": "4.76342000"
    },
    {
        "id": 43364,
        "name": "Les Arcs",
        "latitude": "43.46328000",
        "longitude": "6.47876000"
    },
    {
        "id": 43365,
        "name": "Les Arnavaux",
        "latitude": "43.33506000",
        "longitude": "5.37969000"
    },
    {
        "id": 43366,
        "name": "Les Artigues-de-Lussac",
        "latitude": "44.96667000",
        "longitude": "-0.15000000"
    },
    {
        "id": 43367,
        "name": "Les Attaques",
        "latitude": "50.90962000",
        "longitude": "1.92961000"
    },
    {
        "id": 43368,
        "name": "Les Authieux-sur-le-Port-Saint-Ouen",
        "latitude": "49.34100000",
        "longitude": "1.13465000"
    },
    {
        "id": 43369,
        "name": "Les Avenières",
        "latitude": "45.63632000",
        "longitude": "5.56144000"
    },
    {
        "id": 43370,
        "name": "Les Aygalades",
        "latitude": "43.35711000",
        "longitude": "5.37116000"
    },
    {
        "id": 43371,
        "name": "Les Baumettes",
        "latitude": "43.22641000",
        "longitude": "5.41642000"
    },
    {
        "id": 43372,
        "name": "Les Brouzils",
        "latitude": "46.88571000",
        "longitude": "-1.32095000"
    },
    {
        "id": 43373,
        "name": "Les Caillols",
        "latitude": "43.30221000",
        "longitude": "5.44811000"
    },
    {
        "id": 43374,
        "name": "Les Camoins",
        "latitude": "43.30607000",
        "longitude": "5.51745000"
    },
    {
        "id": 43375,
        "name": "Les Champs-Géraux",
        "latitude": "48.41647000",
        "longitude": "-1.97087000"
    },
    {
        "id": 43376,
        "name": "Les Chartreux",
        "latitude": "43.31112000",
        "longitude": "5.40480000"
    },
    {
        "id": 43377,
        "name": "Les Chères",
        "latitude": "45.88960000",
        "longitude": "4.74261000"
    },
    {
        "id": 43378,
        "name": "Les Clayes-sous-Bois",
        "latitude": "48.82206000",
        "longitude": "1.98677000"
    },
    {
        "id": 43379,
        "name": "Les Clouzeaux",
        "latitude": "46.62881000",
        "longitude": "-1.50947000"
    },
    {
        "id": 43380,
        "name": "Les Contamines-Montjoie",
        "latitude": "45.82164000",
        "longitude": "6.72865000"
    },
    {
        "id": 43382,
        "name": "Les Côtes-d'Arey",
        "latitude": "45.45640000",
        "longitude": "4.86660000"
    },
    {
        "id": 43381,
        "name": "Les Crottes",
        "latitude": "43.32302000",
        "longitude": "5.36809000"
    },
    {
        "id": 43383,
        "name": "Les Deux Alpes",
        "latitude": "45.01160000",
        "longitude": "6.12548000"
    },
    {
        "id": 43434,
        "name": "Les Échelles",
        "latitude": "45.44058000",
        "longitude": "5.74866000"
    },
    {
        "id": 43435,
        "name": "Les Églisottes-et-Chalaures",
        "latitude": "45.09811000",
        "longitude": "-0.03893000"
    },
    {
        "id": 43384,
        "name": "Les Epesses",
        "latitude": "46.88333000",
        "longitude": "-0.90000000"
    },
    {
        "id": 43385,
        "name": "Les Essarts",
        "latitude": "46.77440000",
        "longitude": "-1.22834000"
    },
    {
        "id": 43386,
        "name": "Les Essarts-le-Roi",
        "latitude": "48.71673000",
        "longitude": "1.90089000"
    },
    {
        "id": 43387,
        "name": "Les Fins",
        "latitude": "47.07764000",
        "longitude": "6.63002000"
    },
    {
        "id": 43388,
        "name": "Les Forges",
        "latitude": "48.17747000",
        "longitude": "6.38846000"
    },
    {
        "id": 43389,
        "name": "Les Fourgs",
        "latitude": "46.83455000",
        "longitude": "6.39953000"
    },
    {
        "id": 43390,
        "name": "Les Gets",
        "latitude": "46.15522000",
        "longitude": "6.66492000"
    },
    {
        "id": 43391,
        "name": "Les Gonds",
        "latitude": "45.71437000",
        "longitude": "-0.61408000"
    },
    {
        "id": 43392,
        "name": "Les Grandes-Ventes",
        "latitude": "49.78544000",
        "longitude": "1.22921000"
    },
    {
        "id": 43393,
        "name": "Les Grands Carmes",
        "latitude": "43.30038000",
        "longitude": "5.37275000"
    },
    {
        "id": 43394,
        "name": "Les Hautes-Rivières",
        "latitude": "49.88595000",
        "longitude": "4.84186000"
    },
    {
        "id": 43395,
        "name": "Les Herbiers",
        "latitude": "46.86667000",
        "longitude": "-1.01667000"
    },
    {
        "id": 43396,
        "name": "Les Houches",
        "latitude": "45.89267000",
        "longitude": "6.80637000"
    },
    {
        "id": 43397,
        "name": "Les Landes-Genusson",
        "latitude": "46.96667000",
        "longitude": "-1.11667000"
    },
    {
        "id": 43398,
        "name": "Les Lilas",
        "latitude": "48.87992000",
        "longitude": "2.42057000"
    },
    {
        "id": 43399,
        "name": "Les Loges",
        "latitude": "49.69860000",
        "longitude": "0.28403000"
    },
    {
        "id": 43401,
        "name": "Les Loges-en-Josas",
        "latitude": "48.76377000",
        "longitude": "2.14002000"
    },
    {
        "id": 43400,
        "name": "Les Loges-Marchis",
        "latitude": "48.54132000",
        "longitude": "-1.08754000"
    },
    {
        "id": 43402,
        "name": "Les Lucs-sur-Boulogne",
        "latitude": "46.84478000",
        "longitude": "-1.49445000"
    },
    {
        "id": 43403,
        "name": "Les Mages",
        "latitude": "44.22862000",
        "longitude": "4.16946000"
    },
    {
        "id": 43404,
        "name": "Les Magnils-Reigniers",
        "latitude": "46.48333000",
        "longitude": "-1.21667000"
    },
    {
        "id": 43405,
        "name": "Les Marches",
        "latitude": "45.50042000",
        "longitude": "6.00381000"
    },
    {
        "id": 43406,
        "name": "Les Martres-d'Artière",
        "latitude": "45.83333000",
        "longitude": "3.26667000"
    },
    {
        "id": 43407,
        "name": "Les Matelles",
        "latitude": "43.72905000",
        "longitude": "3.81360000"
    },
    {
        "id": 43408,
        "name": "Les Mathes",
        "latitude": "45.71390000",
        "longitude": "-1.15497000"
    },
    {
        "id": 43413,
        "name": "Les Médecins",
        "latitude": "43.35417000",
        "longitude": "5.45483000"
    },
    {
        "id": 43414,
        "name": "Les Mées",
        "latitude": "44.02961000",
        "longitude": "5.97635000"
    },
    {
        "id": 43409,
        "name": "Les Molières",
        "latitude": "48.67306000",
        "longitude": "2.06959000"
    },
    {
        "id": 43410,
        "name": "Les Montils",
        "latitude": "47.49499000",
        "longitude": "1.29775000"
    },
    {
        "id": 43411,
        "name": "Les Moutiers-en-Retz",
        "latitude": "47.06375000",
        "longitude": "-1.99900000"
    },
    {
        "id": 43412,
        "name": "Les Mureaux",
        "latitude": "48.99173000",
        "longitude": "1.90972000"
    },
    {
        "id": 43415,
        "name": "Les Noës-près-Troyes",
        "latitude": "48.30358000",
        "longitude": "4.04552000"
    },
    {
        "id": 43416,
        "name": "Les Olives",
        "latitude": "43.32393000",
        "longitude": "5.45840000"
    },
    {
        "id": 43417,
        "name": "Les Ormes",
        "latitude": "46.97478000",
        "longitude": "0.60484000"
    },
    {
        "id": 43418,
        "name": "Les Pavillons-sous-Bois",
        "latitude": "48.90683000",
        "longitude": "2.50648000"
    },
    {
        "id": 43419,
        "name": "Les Peintures",
        "latitude": "45.06667000",
        "longitude": "-0.10000000"
    },
    {
        "id": 43420,
        "name": "Les Pennes-Mirabeau",
        "latitude": "43.41012000",
        "longitude": "5.30838000"
    },
    {
        "id": 43421,
        "name": "Les Pieux",
        "latitude": "49.51286000",
        "longitude": "-1.80714000"
    },
    {
        "id": 43422,
        "name": "Les Ponts-de-Cé",
        "latitude": "47.42315000",
        "longitude": "-0.52477000"
    },
    {
        "id": 43423,
        "name": "Les Riceys",
        "latitude": "47.99390000",
        "longitude": "4.36986000"
    },
    {
        "id": 43424,
        "name": "Les Roches-de-Condrieu",
        "latitude": "45.45281000",
        "longitude": "4.76718000"
    },
    {
        "id": 43425,
        "name": "Les Rosiers-sur-Loire",
        "latitude": "47.35000000",
        "longitude": "-0.21667000"
    },
    {
        "id": 43426,
        "name": "Les Rousses",
        "latitude": "46.48412000",
        "longitude": "6.06330000"
    },
    {
        "id": 43427,
        "name": "Les Sables-d’Olonne",
        "latitude": "46.49645000",
        "longitude": "-1.78472000"
    },
    {
        "id": 43428,
        "name": "Les Salles-du-Gardon",
        "latitude": "44.20790000",
        "longitude": "4.03689000"
    },
    {
        "id": 43429,
        "name": "Les Sorinières",
        "latitude": "47.14831000",
        "longitude": "-1.52932000"
    },
    {
        "id": 43430,
        "name": "Les Touches",
        "latitude": "47.44249000",
        "longitude": "-1.43097000"
    },
    {
        "id": 43431,
        "name": "Les Trois-Lucs",
        "latitude": "43.31371000",
        "longitude": "5.46248000"
    },
    {
        "id": 43432,
        "name": "Les Ulis",
        "latitude": "48.68167000",
        "longitude": "2.16944000"
    },
    {
        "id": 43433,
        "name": "Les Vans",
        "latitude": "44.40522000",
        "longitude": "4.13198000"
    },
    {
        "id": 43436,
        "name": "Lescar",
        "latitude": "43.33333000",
        "longitude": "-0.41667000"
    },
    {
        "id": 43692,
        "name": "Lésigny",
        "latitude": "48.74374000",
        "longitude": "2.61518000"
    },
    {
        "id": 43437,
        "name": "Lesneven",
        "latitude": "48.57233000",
        "longitude": "-4.32133000"
    },
    {
        "id": 43438,
        "name": "Lesparre-Médoc",
        "latitude": "45.30719000",
        "longitude": "-0.93764000"
    },
    {
        "id": 43439,
        "name": "Lespignan",
        "latitude": "43.27366000",
        "longitude": "3.17224000"
    },
    {
        "id": 43440,
        "name": "Lespinasse",
        "latitude": "43.71206000",
        "longitude": "1.38462000"
    },
    {
        "id": 43441,
        "name": "Lesquin",
        "latitude": "50.58232000",
        "longitude": "3.11900000"
    },
    {
        "id": 43442,
        "name": "Lessay",
        "latitude": "49.21703000",
        "longitude": "-1.53089000"
    },
    {
        "id": 43443,
        "name": "Lestelle-Bétharram",
        "latitude": "43.13333000",
        "longitude": "-0.21667000"
    },
    {
        "id": 43444,
        "name": "Lestrem",
        "latitude": "50.61987000",
        "longitude": "2.68646000"
    },
    {
        "id": 43445,
        "name": "Leucate",
        "latitude": "42.91056000",
        "longitude": "3.02944000"
    },
    {
        "id": 43446,
        "name": "Leudeville",
        "latitude": "48.56591000",
        "longitude": "2.32676000"
    },
    {
        "id": 43447,
        "name": "Leuville-sur-Orge",
        "latitude": "48.61730000",
        "longitude": "2.26685000"
    },
    {
        "id": 43448,
        "name": "Leval",
        "latitude": "50.18106000",
        "longitude": "3.83093000"
    },
    {
        "id": 43449,
        "name": "Levallois-Perret",
        "latitude": "48.89389000",
        "longitude": "2.28864000"
    },
    {
        "id": 43450,
        "name": "Levens",
        "latitude": "43.85948000",
        "longitude": "7.22583000"
    },
    {
        "id": 43680,
        "name": "Lèves",
        "latitude": "48.47065000",
        "longitude": "1.48194000"
    },
    {
        "id": 43451,
        "name": "Levet",
        "latitude": "46.92605000",
        "longitude": "2.40732000"
    },
    {
        "id": 43452,
        "name": "Levier",
        "latitude": "46.95302000",
        "longitude": "6.12059000"
    },
    {
        "id": 43693,
        "name": "Lévignac",
        "latitude": "43.66667000",
        "longitude": "1.20000000"
    },
    {
        "id": 43694,
        "name": "Lévis-Saint-Nom",
        "latitude": "48.71667000",
        "longitude": "1.95000000"
    },
    {
        "id": 43453,
        "name": "Levroux",
        "latitude": "46.97860000",
        "longitude": "1.61243000"
    },
    {
        "id": 43454,
        "name": "Lewarde",
        "latitude": "50.34173000",
        "longitude": "3.16782000"
    },
    {
        "id": 43455,
        "name": "Lexy",
        "latitude": "49.49996000",
        "longitude": "5.72985000"
    },
    {
        "id": 43456,
        "name": "Leyme",
        "latitude": "44.78622000",
        "longitude": "1.89897000"
    },
    {
        "id": 43457,
        "name": "Leymen",
        "latitude": "47.49632000",
        "longitude": "7.48517000"
    },
    {
        "id": 43458,
        "name": "Leyment",
        "latitude": "45.92390000",
        "longitude": "5.29241000"
    },
    {
        "id": 43695,
        "name": "Lézan",
        "latitude": "44.01667000",
        "longitude": "4.05000000"
    },
    {
        "id": 43696,
        "name": "Lézardrieux",
        "latitude": "48.78510000",
        "longitude": "-3.10588000"
    },
    {
        "id": 43697,
        "name": "Lézat-sur-Lèze",
        "latitude": "43.27734000",
        "longitude": "1.34686000"
    },
    {
        "id": 43459,
        "name": "Lezay",
        "latitude": "46.26437000",
        "longitude": "-0.00925000"
    },
    {
        "id": 43460,
        "name": "Lezennes",
        "latitude": "50.61553000",
        "longitude": "3.11354000"
    },
    {
        "id": 43698,
        "name": "Lézignan-Corbières",
        "latitude": "43.19765000",
        "longitude": "2.76142000"
    },
    {
        "id": 43699,
        "name": "Lézignan-la-Cèbe",
        "latitude": "43.49368000",
        "longitude": "3.43708000"
    },
    {
        "id": 43700,
        "name": "Lézigneux",
        "latitude": "45.56589000",
        "longitude": "4.06542000"
    },
    {
        "id": 43461,
        "name": "Lezoux",
        "latitude": "45.82689000",
        "longitude": "3.37924000"
    },
    {
        "id": 43462,
        "name": "Lherm",
        "latitude": "43.43127000",
        "longitude": "1.22239000"
    },
    {
        "id": 43463,
        "name": "Liancourt",
        "latitude": "49.33034000",
        "longitude": "2.46595000"
    },
    {
        "id": 43464,
        "name": "Libercourt",
        "latitude": "50.48322000",
        "longitude": "3.01584000"
    },
    {
        "id": 43465,
        "name": "Libourne",
        "latitude": "44.91530000",
        "longitude": "-0.24394000"
    },
    {
        "id": 43466,
        "name": "Licques",
        "latitude": "50.78416000",
        "longitude": "1.93844000"
    },
    {
        "id": 43524,
        "name": "Lièpvre",
        "latitude": "48.27166000",
        "longitude": "7.28229000"
    },
    {
        "id": 43467,
        "name": "Liergues",
        "latitude": "45.97138000",
        "longitude": "4.66231000"
    },
    {
        "id": 43468,
        "name": "Liesse-Notre-Dame",
        "latitude": "49.61667000",
        "longitude": "3.80000000"
    },
    {
        "id": 43469,
        "name": "Lieu-Saint-Amand",
        "latitude": "50.27318000",
        "longitude": "3.34624000"
    },
    {
        "id": 43470,
        "name": "Lieuran-lès-Béziers",
        "latitude": "43.41860000",
        "longitude": "3.23719000"
    },
    {
        "id": 43471,
        "name": "Lieurey",
        "latitude": "49.23019000",
        "longitude": "0.49879000"
    },
    {
        "id": 43472,
        "name": "Lieusaint",
        "latitude": "48.63476000",
        "longitude": "2.54806000"
    },
    {
        "id": 43525,
        "name": "Liévin",
        "latitude": "50.41980000",
        "longitude": "2.78068000"
    },
    {
        "id": 43473,
        "name": "Liffol-le-Grand",
        "latitude": "48.31771000",
        "longitude": "5.58125000"
    },
    {
        "id": 43474,
        "name": "Liffré",
        "latitude": "48.21324000",
        "longitude": "-1.50838000"
    },
    {
        "id": 43475,
        "name": "Lignan-sur-Orb",
        "latitude": "43.38462000",
        "longitude": "3.16891000"
    },
    {
        "id": 43481,
        "name": "Ligné",
        "latitude": "47.41096000",
        "longitude": "-1.37726000"
    },
    {
        "id": 43476,
        "name": "Lignières",
        "latitude": "46.75152000",
        "longitude": "2.17381000"
    },
    {
        "id": 43477,
        "name": "Ligny-en-Barrois",
        "latitude": "48.68861000",
        "longitude": "5.32543000"
    },
    {
        "id": 43478,
        "name": "Ligny-en-Cambrésis",
        "latitude": "50.10123000",
        "longitude": "3.37841000"
    },
    {
        "id": 43479,
        "name": "Ligny-le-Châtel",
        "latitude": "47.90022000",
        "longitude": "3.75760000"
    },
    {
        "id": 43480,
        "name": "Ligny-le-Ribault",
        "latitude": "47.68419000",
        "longitude": "1.78153000"
    },
    {
        "id": 43482,
        "name": "Ligré",
        "latitude": "47.11185000",
        "longitude": "0.27562000"
    },
    {
        "id": 43483,
        "name": "Ligueil",
        "latitude": "47.04210000",
        "longitude": "0.81893000"
    },
    {
        "id": 43484,
        "name": "Ligugé",
        "latitude": "46.52035000",
        "longitude": "0.32617000"
    },
    {
        "id": 43485,
        "name": "Lille",
        "latitude": "50.63297000",
        "longitude": "3.05858000"
    },
    {
        "id": 43486,
        "name": "Lillebonne",
        "latitude": "49.52030000",
        "longitude": "0.53617000"
    },
    {
        "id": 43487,
        "name": "Lillers",
        "latitude": "50.56345000",
        "longitude": "2.48042000"
    },
    {
        "id": 43488,
        "name": "Limas",
        "latitude": "45.97558000",
        "longitude": "4.70550000"
    },
    {
        "id": 43489,
        "name": "Limay",
        "latitude": "48.99553000",
        "longitude": "1.74081000"
    },
    {
        "id": 43490,
        "name": "Limeil-Brévannes",
        "latitude": "48.74480000",
        "longitude": "2.48705000"
    },
    {
        "id": 43491,
        "name": "Limerzel",
        "latitude": "47.63619000",
        "longitude": "-2.35448000"
    },
    {
        "id": 43497,
        "name": "Limésy",
        "latitude": "49.61271000",
        "longitude": "0.92483000"
    },
    {
        "id": 43492,
        "name": "Limetz-Villez",
        "latitude": "49.06667000",
        "longitude": "1.55000000"
    },
    {
        "id": 43493,
        "name": "Limoges",
        "latitude": "45.83153000",
        "longitude": "1.25781000"
    },
    {
        "id": 43494,
        "name": "Limonest",
        "latitude": "45.83702000",
        "longitude": "4.77188000"
    },
    {
        "id": 43495,
        "name": "Limours",
        "latitude": "48.64625000",
        "longitude": "2.07688000"
    },
    {
        "id": 43496,
        "name": "Limoux",
        "latitude": "43.05487000",
        "longitude": "2.22173000"
    },
    {
        "id": 43498,
        "name": "Linards",
        "latitude": "45.70083000",
        "longitude": "1.53259000"
    },
    {
        "id": 43499,
        "name": "Linars",
        "latitude": "45.65000000",
        "longitude": "0.08333000"
    },
    {
        "id": 43500,
        "name": "Linas",
        "latitude": "48.63041000",
        "longitude": "2.26266000"
    },
    {
        "id": 43501,
        "name": "Lingolsheim",
        "latitude": "48.55752000",
        "longitude": "7.68253000"
    },
    {
        "id": 43502,
        "name": "Linguizzetta",
        "latitude": "42.26384000",
        "longitude": "9.47297000"
    },
    {
        "id": 43503,
        "name": "Linselles",
        "latitude": "50.73708000",
        "longitude": "3.07878000"
    },
    {
        "id": 43504,
        "name": "Linxe",
        "latitude": "43.91984000",
        "longitude": "-1.24619000"
    },
    {
        "id": 43505,
        "name": "Lion-sur-Mer",
        "latitude": "49.30018000",
        "longitude": "-0.32157000"
    },
    {
        "id": 43506,
        "name": "Lipsheim",
        "latitude": "48.49164000",
        "longitude": "7.66751000"
    },
    {
        "id": 43507,
        "name": "Liré",
        "latitude": "47.34322000",
        "longitude": "-1.16536000"
    },
    {
        "id": 43508,
        "name": "Lisieux",
        "latitude": "49.14660000",
        "longitude": "0.22925000"
    },
    {
        "id": 43509,
        "name": "Lisle-sur-Tarn",
        "latitude": "43.85249000",
        "longitude": "1.81099000"
    },
    {
        "id": 43510,
        "name": "Lisses",
        "latitude": "48.60222000",
        "longitude": "2.42245000"
    },
    {
        "id": 43511,
        "name": "Lissieu",
        "latitude": "45.86487000",
        "longitude": "4.74221000"
    },
    {
        "id": 43512,
        "name": "Listrac-Médoc",
        "latitude": "45.07410000",
        "longitude": "-0.79132000"
    },
    {
        "id": 43513,
        "name": "Lit-et-Mixe",
        "latitude": "44.03308000",
        "longitude": "-1.25330000"
    },
    {
        "id": 43514,
        "name": "Livarot",
        "latitude": "49.00500000",
        "longitude": "0.15020000"
    },
    {
        "id": 43515,
        "name": "Liverdun",
        "latitude": "48.74973000",
        "longitude": "6.06372000"
    },
    {
        "id": 43516,
        "name": "Liverdy-en-Brie",
        "latitude": "48.69987000",
        "longitude": "2.77606000"
    },
    {
        "id": 43517,
        "name": "Livet-et-Gavet",
        "latitude": "45.10782000",
        "longitude": "5.93333000"
    },
    {
        "id": 43518,
        "name": "Livinhac-le-Haut",
        "latitude": "44.59212000",
        "longitude": "2.23117000"
    },
    {
        "id": 43522,
        "name": "Livré-sur-Changeon",
        "latitude": "48.21937000",
        "longitude": "-1.34438000"
    },
    {
        "id": 43519,
        "name": "Livron-sur-Drôme",
        "latitude": "44.77689000",
        "longitude": "4.84180000"
    },
    {
        "id": 43520,
        "name": "Livry-Gargan",
        "latitude": "48.91930000",
        "longitude": "2.54305000"
    },
    {
        "id": 43521,
        "name": "Livry-sur-Seine",
        "latitude": "48.51771000",
        "longitude": "2.67879000"
    },
    {
        "id": 43523,
        "name": "Lizy-sur-Ourcq",
        "latitude": "49.02454000",
        "longitude": "3.02178000"
    },
    {
        "id": 43526,
        "name": "Llupia",
        "latitude": "42.62074000",
        "longitude": "2.76924000"
    },
    {
        "id": 43527,
        "name": "Loches",
        "latitude": "47.12858000",
        "longitude": "0.99522000"
    },
    {
        "id": 43528,
        "name": "Locmariaquer",
        "latitude": "47.56915000",
        "longitude": "-2.94468000"
    },
    {
        "id": 43529,
        "name": "Locminé",
        "latitude": "47.88619000",
        "longitude": "-2.83536000"
    },
    {
        "id": 43530,
        "name": "Locmiquélic",
        "latitude": "47.72499000",
        "longitude": "-3.34153000"
    },
    {
        "id": 43531,
        "name": "Locoal-Mendon",
        "latitude": "47.70520000",
        "longitude": "-3.10796000"
    },
    {
        "id": 43532,
        "name": "Locon",
        "latitude": "50.57029000",
        "longitude": "2.66629000"
    },
    {
        "id": 43534,
        "name": "Locquémeau",
        "latitude": "48.72474000",
        "longitude": "-3.56275000"
    },
    {
        "id": 43533,
        "name": "Locquirec",
        "latitude": "48.69243000",
        "longitude": "-3.64554000"
    },
    {
        "id": 43535,
        "name": "Loctudy",
        "latitude": "47.83333000",
        "longitude": "-4.16667000"
    },
    {
        "id": 43537,
        "name": "Lodève",
        "latitude": "43.73166000",
        "longitude": "3.31941000"
    },
    {
        "id": 43536,
        "name": "Lodi",
        "latitude": "43.28663000",
        "longitude": "5.38882000"
    },
    {
        "id": 43538,
        "name": "Lognes",
        "latitude": "48.83541000",
        "longitude": "2.62998000"
    },
    {
        "id": 43539,
        "name": "Logonna-Daoulas",
        "latitude": "48.32308000",
        "longitude": "-4.29675000"
    },
    {
        "id": 43540,
        "name": "Loire-Atlantique",
        "latitude": "47.32863000",
        "longitude": "-1.65764000"
    },
    {
        "id": 43541,
        "name": "Loire-sur-Rhône",
        "latitude": "45.55000000",
        "longitude": "4.80000000"
    },
    {
        "id": 43542,
        "name": "Loiret",
        "latitude": "47.93598000",
        "longitude": "2.30173000"
    },
    {
        "id": 43543,
        "name": "Loiron",
        "latitude": "48.06667000",
        "longitude": "-0.93333000"
    },
    {
        "id": 43544,
        "name": "Loisin",
        "latitude": "46.29227000",
        "longitude": "6.31010000"
    },
    {
        "id": 43545,
        "name": "Loison-sous-Lens",
        "latitude": "50.43796000",
        "longitude": "2.85322000"
    },
    {
        "id": 43546,
        "name": "Loivre",
        "latitude": "49.34619000",
        "longitude": "3.97978000"
    },
    {
        "id": 43547,
        "name": "Lombez",
        "latitude": "43.47501000",
        "longitude": "0.91119000"
    },
    {
        "id": 43548,
        "name": "Lombron",
        "latitude": "48.07879000",
        "longitude": "0.41869000"
    },
    {
        "id": 43549,
        "name": "Lomme",
        "latitude": "50.64358000",
        "longitude": "2.98715000"
    },
    {
        "id": 43550,
        "name": "Lompret",
        "latitude": "50.66931000",
        "longitude": "2.98968000"
    },
    {
        "id": 43551,
        "name": "Londinières",
        "latitude": "49.83187000",
        "longitude": "1.40232000"
    },
    {
        "id": 43552,
        "name": "Longages",
        "latitude": "43.35398000",
        "longitude": "1.23905000"
    },
    {
        "id": 43553,
        "name": "Longchamp",
        "latitude": "47.25973000",
        "longitude": "5.28694000"
    },
    {
        "id": 43554,
        "name": "Longchaumois",
        "latitude": "46.46228000",
        "longitude": "5.93052000"
    },
    {
        "id": 43555,
        "name": "Longecourt-en-Plaine",
        "latitude": "47.19783000",
        "longitude": "5.14956000"
    },
    {
        "id": 43556,
        "name": "Longeville-en-Barrois",
        "latitude": "48.74242000",
        "longitude": "5.20905000"
    },
    {
        "id": 43557,
        "name": "Longeville-lès-Metz",
        "latitude": "49.11403000",
        "longitude": "6.13612000"
    },
    {
        "id": 43558,
        "name": "Longeville-lès-Saint-Avold",
        "latitude": "49.11689000",
        "longitude": "6.64327000"
    },
    {
        "id": 43559,
        "name": "Longeville-sur-Mer",
        "latitude": "46.41667000",
        "longitude": "-1.50000000"
    },
    {
        "id": 43560,
        "name": "Longfossé",
        "latitude": "50.65217000",
        "longitude": "1.80693000"
    },
    {
        "id": 43561,
        "name": "Longjumeau",
        "latitude": "48.69503000",
        "longitude": "2.30735000"
    },
    {
        "id": 43562,
        "name": "Longlaville",
        "latitude": "49.53443000",
        "longitude": "5.80048000"
    },
    {
        "id": 43563,
        "name": "Longnes",
        "latitude": "48.92002000",
        "longitude": "1.58705000"
    },
    {
        "id": 43564,
        "name": "Longny-au-Perche",
        "latitude": "48.52984000",
        "longitude": "0.75239000"
    },
    {
        "id": 43565,
        "name": "Longperrier",
        "latitude": "49.04844000",
        "longitude": "2.66571000"
    },
    {
        "id": 43566,
        "name": "Longpont-sur-Orge",
        "latitude": "48.64171000",
        "longitude": "2.29278000"
    },
    {
        "id": 43567,
        "name": "Longpré-les-Corps-Saints",
        "latitude": "50.01239000",
        "longitude": "1.99287000"
    },
    {
        "id": 43568,
        "name": "Longueau",
        "latitude": "49.87226000",
        "longitude": "2.35880000"
    },
    {
        "id": 43569,
        "name": "Longueil-Annel",
        "latitude": "49.46908000",
        "longitude": "2.86464000"
    },
    {
        "id": 43570,
        "name": "Longueil-Sainte-Marie",
        "latitude": "49.35723000",
        "longitude": "2.71844000"
    },
    {
        "id": 43571,
        "name": "Longuenesse",
        "latitude": "50.73395000",
        "longitude": "2.23520000"
    },
    {
        "id": 43572,
        "name": "Longueville",
        "latitude": "48.51503000",
        "longitude": "3.24677000"
    },
    {
        "id": 43573,
        "name": "Longuyon",
        "latitude": "49.44181000",
        "longitude": "5.60508000"
    },
    {
        "id": 43574,
        "name": "Longvic",
        "latitude": "47.28754000",
        "longitude": "5.06341000"
    },
    {
        "id": 43575,
        "name": "Longwy",
        "latitude": "49.52170000",
        "longitude": "5.76192000"
    },
    {
        "id": 43576,
        "name": "Lons",
        "latitude": "43.31667000",
        "longitude": "-0.40000000"
    },
    {
        "id": 43577,
        "name": "Lons-le-Saunier",
        "latitude": "46.67535000",
        "longitude": "5.55575000"
    },
    {
        "id": 43578,
        "name": "Looberghe",
        "latitude": "50.91694000",
        "longitude": "2.27439000"
    },
    {
        "id": 43579,
        "name": "Loon-Plage",
        "latitude": "50.99647000",
        "longitude": "2.21770000"
    },
    {
        "id": 43580,
        "name": "Loos",
        "latitude": "50.60982000",
        "longitude": "3.01874000"
    },
    {
        "id": 43581,
        "name": "Loos-en-Gohelle",
        "latitude": "50.45786000",
        "longitude": "2.79215000"
    },
    {
        "id": 43583,
        "name": "Lopérec",
        "latitude": "48.27734000",
        "longitude": "-4.04756000"
    },
    {
        "id": 43582,
        "name": "Loperhet",
        "latitude": "48.37725000",
        "longitude": "-4.30608000"
    },
    {
        "id": 43584,
        "name": "Lorette",
        "latitude": "45.51105000",
        "longitude": "4.58242000"
    },
    {
        "id": 43585,
        "name": "Lorgies",
        "latitude": "50.56880000",
        "longitude": "2.79034000"
    },
    {
        "id": 43586,
        "name": "Lorgues",
        "latitude": "43.49325000",
        "longitude": "6.36150000"
    },
    {
        "id": 43587,
        "name": "Lorient",
        "latitude": "47.74589000",
        "longitude": "-3.36643000"
    },
    {
        "id": 43588,
        "name": "Loriol-du-Comtat",
        "latitude": "44.07653000",
        "longitude": "5.00065000"
    },
    {
        "id": 43589,
        "name": "Loriol-sur-Drôme",
        "latitude": "44.74703000",
        "longitude": "4.81719000"
    },
    {
        "id": 43590,
        "name": "Lormaison",
        "latitude": "49.25647000",
        "longitude": "2.10575000"
    },
    {
        "id": 43591,
        "name": "Lormes",
        "latitude": "47.28964000",
        "longitude": "3.81714000"
    },
    {
        "id": 43592,
        "name": "Lormont",
        "latitude": "44.87495000",
        "longitude": "-0.51782000"
    },
    {
        "id": 43593,
        "name": "Lorquin",
        "latitude": "48.67102000",
        "longitude": "6.99915000"
    },
    {
        "id": 43594,
        "name": "Lorrez-le-Bocage-Préaux",
        "latitude": "48.23333000",
        "longitude": "2.90000000"
    },
    {
        "id": 43595,
        "name": "Lorris",
        "latitude": "47.88950000",
        "longitude": "2.51478000"
    },
    {
        "id": 43596,
        "name": "Losne",
        "latitude": "47.09850000",
        "longitude": "5.26216000"
    },
    {
        "id": 43597,
        "name": "Lot",
        "latitude": "44.62703000",
        "longitude": "1.63461000"
    },
    {
        "id": 43598,
        "name": "Louannec",
        "latitude": "48.79423000",
        "longitude": "-3.41200000"
    },
    {
        "id": 43599,
        "name": "Louargat",
        "latitude": "48.56576000",
        "longitude": "-3.33750000"
    },
    {
        "id": 43600,
        "name": "Loubert",
        "latitude": "45.91422000",
        "longitude": "0.58617000"
    },
    {
        "id": 43602,
        "name": "Loudéac",
        "latitude": "48.17826000",
        "longitude": "-2.75433000"
    },
    {
        "id": 43601,
        "name": "Loudun",
        "latitude": "47.00788000",
        "longitude": "0.08296000"
    },
    {
        "id": 43621,
        "name": "Loué",
        "latitude": "47.99575000",
        "longitude": "-0.15450000"
    },
    {
        "id": 43603,
        "name": "Louhans",
        "latitude": "46.62637000",
        "longitude": "5.22468000"
    },
    {
        "id": 43604,
        "name": "Loupiac",
        "latitude": "44.61667000",
        "longitude": "-0.30000000"
    },
    {
        "id": 43605,
        "name": "Loupian",
        "latitude": "43.44886000",
        "longitude": "3.61381000"
    },
    {
        "id": 43606,
        "name": "Louplande",
        "latitude": "47.95000000",
        "longitude": "-0.05000000"
    },
    {
        "id": 43607,
        "name": "Lourches",
        "latitude": "50.31354000",
        "longitude": "3.35258000"
    },
    {
        "id": 43608,
        "name": "Lourdes",
        "latitude": "43.10000000",
        "longitude": "-0.05000000"
    },
    {
        "id": 43609,
        "name": "Lourmarin",
        "latitude": "43.76385000",
        "longitude": "5.36264000"
    },
    {
        "id": 43610,
        "name": "Loury",
        "latitude": "47.99944000",
        "longitude": "2.08474000"
    },
    {
        "id": 43611,
        "name": "Louveciennes",
        "latitude": "48.86115000",
        "longitude": "2.11463000"
    },
    {
        "id": 43612,
        "name": "Louverné",
        "latitude": "48.12178000",
        "longitude": "-0.71721000"
    },
    {
        "id": 43613,
        "name": "Louvie-Juzon",
        "latitude": "43.08646000",
        "longitude": "-0.41928000"
    },
    {
        "id": 43614,
        "name": "Louviers",
        "latitude": "49.21667000",
        "longitude": "1.16667000"
    },
    {
        "id": 43616,
        "name": "Louvigné-de-Bais",
        "latitude": "48.04898000",
        "longitude": "-1.33091000"
    },
    {
        "id": 43617,
        "name": "Louvigné-du-Désert",
        "latitude": "48.48047000",
        "longitude": "-1.12537000"
    },
    {
        "id": 43615,
        "name": "Louvigny",
        "latitude": "49.15715000",
        "longitude": "-0.39376000"
    },
    {
        "id": 43618,
        "name": "Louvres",
        "latitude": "49.04460000",
        "longitude": "2.50479000"
    },
    {
        "id": 43619,
        "name": "Louvroil",
        "latitude": "50.26427000",
        "longitude": "3.96272000"
    },
    {
        "id": 43620,
        "name": "Louzy",
        "latitude": "47.01201000",
        "longitude": "-0.18537000"
    },
    {
        "id": 43622,
        "name": "Lovagny",
        "latitude": "45.90377000",
        "longitude": "6.03281000"
    },
    {
        "id": 43623,
        "name": "Loyat",
        "latitude": "47.98933000",
        "longitude": "-2.38344000"
    },
    {
        "id": 43624,
        "name": "Loyettes",
        "latitude": "45.77663000",
        "longitude": "5.20687000"
    },
    {
        "id": 43625,
        "name": "Lozanne",
        "latitude": "45.85733000",
        "longitude": "4.68070000"
    },
    {
        "id": 43626,
        "name": "Lozinghem",
        "latitude": "50.51554000",
        "longitude": "2.50209000"
    },
    {
        "id": 43627,
        "name": "Luant",
        "latitude": "46.73300000",
        "longitude": "1.55793000"
    },
    {
        "id": 43628,
        "name": "Lubersac",
        "latitude": "45.44474000",
        "longitude": "1.40457000"
    },
    {
        "id": 43629,
        "name": "Luc-la-Primaube",
        "latitude": "44.31439000",
        "longitude": "2.53552000"
    },
    {
        "id": 43630,
        "name": "Luc-sur-Mer",
        "latitude": "49.31452000",
        "longitude": "-0.35499000"
    },
    {
        "id": 43675,
        "name": "Luçay-le-Mâle",
        "latitude": "47.12996000",
        "longitude": "1.44173000"
    },
    {
        "id": 43631,
        "name": "Lucciana",
        "latitude": "42.54609000",
        "longitude": "9.41865000"
    },
    {
        "id": 43638,
        "name": "Lucé",
        "latitude": "48.43689000",
        "longitude": "1.46359000"
    },
    {
        "id": 43632,
        "name": "Luceau",
        "latitude": "47.71167000",
        "longitude": "0.39734000"
    },
    {
        "id": 43633,
        "name": "Lucenay",
        "latitude": "45.91274000",
        "longitude": "4.70287000"
    },
    {
        "id": 43634,
        "name": "Lucenay-lès-Aix",
        "latitude": "46.70274000",
        "longitude": "3.48308000"
    },
    {
        "id": 43639,
        "name": "Lucéram",
        "latitude": "43.88293000",
        "longitude": "7.35988000"
    },
    {
        "id": 43635,
        "name": "Luché-Pringé",
        "latitude": "47.70326000",
        "longitude": "0.07549000"
    },
    {
        "id": 43636,
        "name": "Lucinges",
        "latitude": "46.19181000",
        "longitude": "6.31511000"
    },
    {
        "id": 43676,
        "name": "Luçon",
        "latitude": "46.45773000",
        "longitude": "-1.16512000"
    },
    {
        "id": 43637,
        "name": "Lucq-de-Béarn",
        "latitude": "43.30000000",
        "longitude": "-0.66667000"
    },
    {
        "id": 43640,
        "name": "Ludon-Médoc",
        "latitude": "44.98118000",
        "longitude": "-0.60254000"
    },
    {
        "id": 43641,
        "name": "Ludres",
        "latitude": "48.62032000",
        "longitude": "6.16747000"
    },
    {
        "id": 43642,
        "name": "Lugon-et-l'Île-du-Carnay",
        "latitude": "44.95556000",
        "longitude": "-0.33611000"
    },
    {
        "id": 43643,
        "name": "Lugrin",
        "latitude": "46.40208000",
        "longitude": "6.65283000"
    },
    {
        "id": 43644,
        "name": "Luisant",
        "latitude": "48.42950000",
        "longitude": "1.47383000"
    },
    {
        "id": 43645,
        "name": "Luitré",
        "latitude": "48.28247000",
        "longitude": "-1.11866000"
    },
    {
        "id": 43646,
        "name": "Lumbin",
        "latitude": "45.30954000",
        "longitude": "5.91505000"
    },
    {
        "id": 43647,
        "name": "Lumbres",
        "latitude": "50.70693000",
        "longitude": "2.12081000"
    },
    {
        "id": 43648,
        "name": "Lumes",
        "latitude": "49.73461000",
        "longitude": "4.78616000"
    },
    {
        "id": 43649,
        "name": "Lumigny-Nesles-Ormeaux",
        "latitude": "48.73333000",
        "longitude": "2.95000000"
    },
    {
        "id": 43650,
        "name": "Lumio",
        "latitude": "42.57894000",
        "longitude": "8.83373000"
    },
    {
        "id": 43651,
        "name": "Lunay",
        "latitude": "47.80864000",
        "longitude": "0.91499000"
    },
    {
        "id": 43652,
        "name": "Lunel",
        "latitude": "43.67778000",
        "longitude": "4.13611000"
    },
    {
        "id": 43653,
        "name": "Lunel-Viel",
        "latitude": "43.67890000",
        "longitude": "4.09250000"
    },
    {
        "id": 43654,
        "name": "Luneray",
        "latitude": "49.82795000",
        "longitude": "0.91581000"
    },
    {
        "id": 43655,
        "name": "Lunery",
        "latitude": "46.93569000",
        "longitude": "2.26895000"
    },
    {
        "id": 43656,
        "name": "Lunéville",
        "latitude": "48.59273000",
        "longitude": "6.49383000"
    },
    {
        "id": 43657,
        "name": "Luray",
        "latitude": "48.72035000",
        "longitude": "1.39889000"
    },
    {
        "id": 43658,
        "name": "Lurcy-Lévis",
        "latitude": "46.72981000",
        "longitude": "2.93829000"
    },
    {
        "id": 43659,
        "name": "Lure",
        "latitude": "47.68294000",
        "longitude": "6.49658000"
    },
    {
        "id": 43660,
        "name": "Lusanger",
        "latitude": "47.68153000",
        "longitude": "-1.58857000"
    },
    {
        "id": 43661,
        "name": "Lusignan",
        "latitude": "46.43598000",
        "longitude": "0.12620000"
    },
    {
        "id": 43662,
        "name": "Lusigny",
        "latitude": "46.58776000",
        "longitude": "3.49142000"
    },
    {
        "id": 43663,
        "name": "Lusigny-sur-Barse",
        "latitude": "48.25451000",
        "longitude": "4.26891000"
    },
    {
        "id": 43664,
        "name": "Lussac",
        "latitude": "44.95000000",
        "longitude": "-0.10000000"
    },
    {
        "id": 43665,
        "name": "Lussac-les-Châteaux",
        "latitude": "46.40327000",
        "longitude": "0.72524000"
    },
    {
        "id": 43666,
        "name": "Lutterbach",
        "latitude": "47.75976000",
        "longitude": "7.28032000"
    },
    {
        "id": 43667,
        "name": "Lutzelhouse",
        "latitude": "48.52114000",
        "longitude": "7.28700000"
    },
    {
        "id": 43668,
        "name": "Luxeuil-les-Bains",
        "latitude": "47.81710000",
        "longitude": "6.36500000"
    },
    {
        "id": 43669,
        "name": "Luynes",
        "latitude": "47.38441000",
        "longitude": "0.55470000"
    },
    {
        "id": 43670,
        "name": "Luz-Saint-Sauveur",
        "latitude": "42.87191000",
        "longitude": "-0.00323000"
    },
    {
        "id": 43671,
        "name": "Luzarches",
        "latitude": "49.11319000",
        "longitude": "2.42230000"
    },
    {
        "id": 43672,
        "name": "Luzech",
        "latitude": "44.47818000",
        "longitude": "1.28704000"
    },
    {
        "id": 43673,
        "name": "Luzinay",
        "latitude": "45.58996000",
        "longitude": "4.95891000"
    },
    {
        "id": 43674,
        "name": "Luzy",
        "latitude": "46.79011000",
        "longitude": "3.97036000"
    },
    {
        "id": 43677,
        "name": "Lyaud",
        "latitude": "46.33972000",
        "longitude": "6.52595000"
    },
    {
        "id": 43678,
        "name": "Lyon",
        "latitude": "45.74846000",
        "longitude": "4.84671000"
    },
    {
        "id": 43679,
        "name": "Lys-lez-Lannoy",
        "latitude": "50.66667000",
        "longitude": "3.21667000"
    },
    {
        "id": 43710,
        "name": "Mably",
        "latitude": "46.06484000",
        "longitude": "4.06014000"
    },
    {
        "id": 43711,
        "name": "Macau",
        "latitude": "45.00679000",
        "longitude": "-0.61821000"
    },
    {
        "id": 43714,
        "name": "Maché",
        "latitude": "46.75405000",
        "longitude": "-1.68692000"
    },
    {
        "id": 43712,
        "name": "Machecoul",
        "latitude": "46.99358000",
        "longitude": "-1.82352000"
    },
    {
        "id": 43713,
        "name": "Macheren",
        "latitude": "49.10019000",
        "longitude": "6.77763000"
    },
    {
        "id": 43715,
        "name": "Maclas",
        "latitude": "45.36289000",
        "longitude": "4.68200000"
    },
    {
        "id": 44451,
        "name": "Mâcon",
        "latitude": "46.31407000",
        "longitude": "4.82823000"
    },
    {
        "id": 44452,
        "name": "Mâcot-la-Plagne",
        "latitude": "45.55000000",
        "longitude": "6.66667000"
    },
    {
        "id": 43981,
        "name": "Maël-Carhaix",
        "latitude": "48.28444000",
        "longitude": "-3.42322000"
    },
    {
        "id": 43716,
        "name": "Maffliers",
        "latitude": "49.07758000",
        "longitude": "2.30768000"
    },
    {
        "id": 43717,
        "name": "Magalas",
        "latitude": "43.47095000",
        "longitude": "3.22338000"
    },
    {
        "id": 43718,
        "name": "Magenta",
        "latitude": "49.04899000",
        "longitude": "3.96882000"
    },
    {
        "id": 43719,
        "name": "Magescq",
        "latitude": "43.78205000",
        "longitude": "-1.21652000"
    },
    {
        "id": 43720,
        "name": "Magland",
        "latitude": "46.02064000",
        "longitude": "6.62089000"
    },
    {
        "id": 43721,
        "name": "Magnac-Laval",
        "latitude": "46.21514000",
        "longitude": "1.16724000"
    },
    {
        "id": 43722,
        "name": "Magnac-sur-Touvre",
        "latitude": "45.66667000",
        "longitude": "0.23333000"
    },
    {
        "id": 43723,
        "name": "Magnanville",
        "latitude": "48.96798000",
        "longitude": "1.67842000"
    },
    {
        "id": 43730,
        "name": "Magné",
        "latitude": "46.31548000",
        "longitude": "-0.54575000"
    },
    {
        "id": 43724,
        "name": "Magny-Cours",
        "latitude": "46.88333000",
        "longitude": "3.15000000"
    },
    {
        "id": 43726,
        "name": "Magny-en-Vexin",
        "latitude": "49.15515000",
        "longitude": "1.78669000"
    },
    {
        "id": 43727,
        "name": "Magny-le-Désert",
        "latitude": "48.57015000",
        "longitude": "-0.32732000"
    },
    {
        "id": 43728,
        "name": "Magny-le-Hongre",
        "latitude": "48.86325000",
        "longitude": "2.81546000"
    },
    {
        "id": 43729,
        "name": "Magny-les-Hameaux",
        "latitude": "48.74345000",
        "longitude": "2.06154000"
    },
    {
        "id": 43725,
        "name": "Magny-Vernois",
        "latitude": "47.66997000",
        "longitude": "6.47545000"
    },
    {
        "id": 43982,
        "name": "Maîche",
        "latitude": "47.25160000",
        "longitude": "6.80309000"
    },
    {
        "id": 43731,
        "name": "Maidières",
        "latitude": "48.89961000",
        "longitude": "6.04005000"
    },
    {
        "id": 43732,
        "name": "Maignelay-Montigny",
        "latitude": "49.55000000",
        "longitude": "2.51667000"
    },
    {
        "id": 43733,
        "name": "Maillane",
        "latitude": "43.83289000",
        "longitude": "4.78209000"
    },
    {
        "id": 43734,
        "name": "Maillezais",
        "latitude": "46.37267000",
        "longitude": "-0.73963000"
    },
    {
        "id": 43735,
        "name": "Maillot",
        "latitude": "48.17557000",
        "longitude": "3.30784000"
    },
    {
        "id": 43736,
        "name": "Mailly-le-Camp",
        "latitude": "48.66526000",
        "longitude": "4.21303000"
    },
    {
        "id": 43737,
        "name": "Maincy",
        "latitude": "48.54977000",
        "longitude": "2.70017000"
    },
    {
        "id": 43738,
        "name": "Maing",
        "latitude": "50.30791000",
        "longitude": "3.48447000"
    },
    {
        "id": 43739,
        "name": "Maintenon",
        "latitude": "48.58704000",
        "longitude": "1.57847000"
    },
    {
        "id": 43740,
        "name": "Mainvilliers",
        "latitude": "48.45003000",
        "longitude": "1.45607000"
    },
    {
        "id": 43741,
        "name": "Maisdon-sur-Sèvre",
        "latitude": "47.09714000",
        "longitude": "-1.38000000"
    },
    {
        "id": 43742,
        "name": "Maisnil-lès-Ruitz",
        "latitude": "50.45342000",
        "longitude": "2.58992000"
    },
    {
        "id": 43743,
        "name": "Maisons-Alfort",
        "latitude": "48.81171000",
        "longitude": "2.43945000"
    },
    {
        "id": 43744,
        "name": "Maisons-Laffitte",
        "latitude": "48.95264000",
        "longitude": "2.14521000"
    },
    {
        "id": 43745,
        "name": "Maisse",
        "latitude": "48.39525000",
        "longitude": "2.37902000"
    },
    {
        "id": 43746,
        "name": "Maizières-la-Grande-Paroisse",
        "latitude": "48.51012000",
        "longitude": "3.78573000"
    },
    {
        "id": 43747,
        "name": "Maizières-lès-Metz",
        "latitude": "49.21335000",
        "longitude": "6.15956000"
    },
    {
        "id": 43748,
        "name": "Malakoff",
        "latitude": "48.81999000",
        "longitude": "2.29998000"
    },
    {
        "id": 43749,
        "name": "Malansac",
        "latitude": "47.67724000",
        "longitude": "-2.29543000"
    },
    {
        "id": 43750,
        "name": "Malataverne",
        "latitude": "44.48525000",
        "longitude": "4.75326000"
    },
    {
        "id": 43751,
        "name": "Malaucène",
        "latitude": "44.17393000",
        "longitude": "5.13213000"
    },
    {
        "id": 43752,
        "name": "Malaunay",
        "latitude": "49.52710000",
        "longitude": "1.04292000"
    },
    {
        "id": 43753,
        "name": "Malay-le-Grand",
        "latitude": "48.17589000",
        "longitude": "3.34189000"
    },
    {
        "id": 43754,
        "name": "Malemort-du-Comtat",
        "latitude": "44.02096000",
        "longitude": "5.15961000"
    },
    {
        "id": 43755,
        "name": "Malemort-sur-Corrèze",
        "latitude": "45.17075000",
        "longitude": "1.56393000"
    },
    {
        "id": 43756,
        "name": "Malesherbes",
        "latitude": "48.29566000",
        "longitude": "2.40935000"
    },
    {
        "id": 43757,
        "name": "Malestroit",
        "latitude": "47.80934000",
        "longitude": "-2.38457000"
    },
    {
        "id": 43758,
        "name": "Malguénac",
        "latitude": "48.08008000",
        "longitude": "-3.05248000"
    },
    {
        "id": 43759,
        "name": "Malicorne-sur-Sarthe",
        "latitude": "47.81317000",
        "longitude": "-0.08152000"
    },
    {
        "id": 43760,
        "name": "Malijai",
        "latitude": "44.04600000",
        "longitude": "6.03041000"
    },
    {
        "id": 43761,
        "name": "Malintrat",
        "latitude": "45.81509000",
        "longitude": "3.18603000"
    },
    {
        "id": 43762,
        "name": "Malissard",
        "latitude": "44.89969000",
        "longitude": "4.95384000"
    },
    {
        "id": 43763,
        "name": "Mallemoisson",
        "latitude": "44.04687000",
        "longitude": "6.12557000"
    },
    {
        "id": 43764,
        "name": "Mallemort",
        "latitude": "43.73172000",
        "longitude": "5.17945000"
    },
    {
        "id": 43765,
        "name": "Malpassé",
        "latitude": "43.32170000",
        "longitude": "5.41551000"
    },
    {
        "id": 43766,
        "name": "Malville",
        "latitude": "47.35905000",
        "longitude": "-1.86227000"
    },
    {
        "id": 43767,
        "name": "Malzéville",
        "latitude": "48.71235000",
        "longitude": "6.18468000"
    },
    {
        "id": 43768,
        "name": "Mamers",
        "latitude": "48.34961000",
        "longitude": "0.36937000"
    },
    {
        "id": 43769,
        "name": "Mametz",
        "latitude": "50.63485000",
        "longitude": "2.32478000"
    },
    {
        "id": 43770,
        "name": "Mamirolle",
        "latitude": "47.19857000",
        "longitude": "6.15924000"
    },
    {
        "id": 43771,
        "name": "Manche",
        "latitude": "49.03822000",
        "longitude": "-1.31865000"
    },
    {
        "id": 43772,
        "name": "Mancieulles",
        "latitude": "49.28231000",
        "longitude": "5.89612000"
    },
    {
        "id": 43773,
        "name": "Mandelieu-la-Napoule",
        "latitude": "43.54577000",
        "longitude": "6.93734000"
    },
    {
        "id": 43774,
        "name": "Mandeure",
        "latitude": "47.44921000",
        "longitude": "6.80762000"
    },
    {
        "id": 43775,
        "name": "Mandres-les-Roses",
        "latitude": "48.70198000",
        "longitude": "2.54662000"
    },
    {
        "id": 43776,
        "name": "Manduel",
        "latitude": "43.81855000",
        "longitude": "4.47247000"
    },
    {
        "id": 43777,
        "name": "Mane",
        "latitude": "43.93795000",
        "longitude": "5.76718000"
    },
    {
        "id": 43789,
        "name": "Manéglise",
        "latitude": "49.56578000",
        "longitude": "0.25572000"
    },
    {
        "id": 43778,
        "name": "Manneville-sur-Risle",
        "latitude": "49.35173000",
        "longitude": "0.54526000"
    },
    {
        "id": 43779,
        "name": "Manom",
        "latitude": "49.37092000",
        "longitude": "6.18345000"
    },
    {
        "id": 43780,
        "name": "Manosque",
        "latitude": "43.82883000",
        "longitude": "5.78688000"
    },
    {
        "id": 43781,
        "name": "Mansac",
        "latitude": "45.16839000",
        "longitude": "1.38342000"
    },
    {
        "id": 43782,
        "name": "Mansigné",
        "latitude": "47.74833000",
        "longitude": "0.13311000"
    },
    {
        "id": 43783,
        "name": "Mansle",
        "latitude": "45.87526000",
        "longitude": "0.17914000"
    },
    {
        "id": 43784,
        "name": "Mantes-la-Jolie",
        "latitude": "48.99048000",
        "longitude": "1.71670000"
    },
    {
        "id": 43785,
        "name": "Mantes-la-Ville",
        "latitude": "48.97374000",
        "longitude": "1.70253000"
    },
    {
        "id": 43786,
        "name": "Manthelan",
        "latitude": "47.13632000",
        "longitude": "0.79319000"
    },
    {
        "id": 43787,
        "name": "Manzat",
        "latitude": "45.96160000",
        "longitude": "2.94140000"
    },
    {
        "id": 43788,
        "name": "Manziat",
        "latitude": "46.36125000",
        "longitude": "4.90580000"
    },
    {
        "id": 43790,
        "name": "Marange-Silvange",
        "latitude": "49.20822000",
        "longitude": "6.10426000"
    },
    {
        "id": 43791,
        "name": "Marans",
        "latitude": "46.30811000",
        "longitude": "-0.99450000"
    },
    {
        "id": 43792,
        "name": "Maraussan",
        "latitude": "43.36895000",
        "longitude": "3.15643000"
    },
    {
        "id": 43793,
        "name": "Marbache",
        "latitude": "48.79867000",
        "longitude": "6.10600000"
    },
    {
        "id": 43794,
        "name": "Marboué",
        "latitude": "48.11377000",
        "longitude": "1.33210000"
    },
    {
        "id": 43795,
        "name": "Marboz",
        "latitude": "46.34290000",
        "longitude": "5.25722000"
    },
    {
        "id": 43796,
        "name": "Marcamps",
        "latitude": "45.04045000",
        "longitude": "-0.49275000"
    },
    {
        "id": 43797,
        "name": "Marcellaz-Albanais",
        "latitude": "45.87546000",
        "longitude": "5.99880000"
    },
    {
        "id": 43798,
        "name": "Marcey-les-Grèves",
        "latitude": "48.69673000",
        "longitude": "-1.39156000"
    },
    {
        "id": 43799,
        "name": "Marcheprime",
        "latitude": "44.69146000",
        "longitude": "-0.85496000"
    },
    {
        "id": 43800,
        "name": "Marchiennes",
        "latitude": "50.40000000",
        "longitude": "3.28333000"
    },
    {
        "id": 43801,
        "name": "Marciac",
        "latitude": "43.52321000",
        "longitude": "0.16091000"
    },
    {
        "id": 43802,
        "name": "Marcigny",
        "latitude": "46.27627000",
        "longitude": "4.03957000"
    },
    {
        "id": 43803,
        "name": "Marcillac",
        "latitude": "45.26870000",
        "longitude": "-0.52379000"
    },
    {
        "id": 43804,
        "name": "Marcillac-Vallon",
        "latitude": "44.47464000",
        "longitude": "2.46454000"
    },
    {
        "id": 43805,
        "name": "Marcilly-en-Villette",
        "latitude": "47.76505000",
        "longitude": "2.02266000"
    },
    {
        "id": 43806,
        "name": "Marcilly-le-Châtel",
        "latitude": "45.69590000",
        "longitude": "4.03082000"
    },
    {
        "id": 43807,
        "name": "Marcilly-sur-Eure",
        "latitude": "48.82568000",
        "longitude": "1.34741000"
    },
    {
        "id": 43808,
        "name": "Marcilly-sur-Tille",
        "latitude": "47.51700000",
        "longitude": "5.12979000"
    },
    {
        "id": 43809,
        "name": "Marck",
        "latitude": "50.94897000",
        "longitude": "1.94328000"
    },
    {
        "id": 43810,
        "name": "Marckolsheim",
        "latitude": "48.16471000",
        "longitude": "7.54400000"
    },
    {
        "id": 43811,
        "name": "Marcoing",
        "latitude": "50.12110000",
        "longitude": "3.17321000"
    },
    {
        "id": 43926,
        "name": "Marçon",
        "latitude": "47.71059000",
        "longitude": "0.51101000"
    },
    {
        "id": 43812,
        "name": "Marconne",
        "latitude": "50.37143000",
        "longitude": "2.04669000"
    },
    {
        "id": 43813,
        "name": "Marconnelle",
        "latitude": "50.37588000",
        "longitude": "2.01108000"
    },
    {
        "id": 43814,
        "name": "Marcorignan",
        "latitude": "43.22634000",
        "longitude": "2.92281000"
    },
    {
        "id": 43815,
        "name": "Marcoussis",
        "latitude": "48.64026000",
        "longitude": "2.23858000"
    },
    {
        "id": 43816,
        "name": "Marcq-en-Barœul",
        "latitude": "50.66667000",
        "longitude": "3.08333000"
    },
    {
        "id": 43817,
        "name": "Mardeuil",
        "latitude": "49.05393000",
        "longitude": "3.93000000"
    },
    {
        "id": 43818,
        "name": "Mardié",
        "latitude": "47.88617000",
        "longitude": "2.05745000"
    },
    {
        "id": 43819,
        "name": "Mareau-aux-Prés",
        "latitude": "47.86667000",
        "longitude": "1.78333000"
    },
    {
        "id": 43820,
        "name": "Mareil-Marly",
        "latitude": "48.88213000",
        "longitude": "2.07351000"
    },
    {
        "id": 43821,
        "name": "Mareil-sur-Mauldre",
        "latitude": "48.89521000",
        "longitude": "1.86870000"
    },
    {
        "id": 43822,
        "name": "Marennes",
        "latitude": "45.62089000",
        "longitude": "4.91236000"
    },
    {
        "id": 43823,
        "name": "Marennes",
        "latitude": "45.82280000",
        "longitude": "-1.10546000"
    },
    {
        "id": 43824,
        "name": "Maretz",
        "latitude": "50.04530000",
        "longitude": "3.41808000"
    },
    {
        "id": 43825,
        "name": "Mareuil",
        "latitude": "45.45000000",
        "longitude": "0.45000000"
    },
    {
        "id": 43826,
        "name": "Mareuil-le-Port",
        "latitude": "49.08179000",
        "longitude": "3.76011000"
    },
    {
        "id": 43827,
        "name": "Mareuil-lès-Meaux",
        "latitude": "48.92648000",
        "longitude": "2.86134000"
    },
    {
        "id": 43828,
        "name": "Mareuil-sur-Ay",
        "latitude": "49.04581000",
        "longitude": "4.03587000"
    },
    {
        "id": 43829,
        "name": "Mareuil-sur-Cher",
        "latitude": "47.29314000",
        "longitude": "1.32897000"
    },
    {
        "id": 43830,
        "name": "Mareuil-sur-Lay-Dissais",
        "latitude": "46.53333000",
        "longitude": "-1.23333000"
    },
    {
        "id": 43831,
        "name": "Mareuil-sur-Ourcq",
        "latitude": "49.13752000",
        "longitude": "3.07822000"
    },
    {
        "id": 43832,
        "name": "Margaux",
        "latitude": "45.04012000",
        "longitude": "-0.67892000"
    },
    {
        "id": 43833,
        "name": "Margencel",
        "latitude": "46.34140000",
        "longitude": "6.41416000"
    },
    {
        "id": 43834,
        "name": "Margency",
        "latitude": "49.00000000",
        "longitude": "2.30000000"
    },
    {
        "id": 43835,
        "name": "Margny-lès-Compiègne",
        "latitude": "49.42559000",
        "longitude": "2.81806000"
    },
    {
        "id": 43836,
        "name": "Margon",
        "latitude": "48.33568000",
        "longitude": "0.83454000"
    },
    {
        "id": 43837,
        "name": "Marguerittes",
        "latitude": "43.85960000",
        "longitude": "4.44517000"
    },
    {
        "id": 43838,
        "name": "Marignane",
        "latitude": "43.41727000",
        "longitude": "5.21462000"
    },
    {
        "id": 43844,
        "name": "Marigné-Laillé",
        "latitude": "47.81765000",
        "longitude": "0.34050000"
    },
    {
        "id": 43839,
        "name": "Marignier",
        "latitude": "46.09014000",
        "longitude": "6.50004000"
    },
    {
        "id": 43840,
        "name": "Marigny",
        "latitude": "49.09952000",
        "longitude": "-1.24179000"
    },
    {
        "id": 43841,
        "name": "Marigny-Brizay",
        "latitude": "46.74622000",
        "longitude": "0.37626000"
    },
    {
        "id": 43842,
        "name": "Marigny-le-Châtel",
        "latitude": "48.40291000",
        "longitude": "3.73588000"
    },
    {
        "id": 43843,
        "name": "Marigny-les-Usages",
        "latitude": "47.95799000",
        "longitude": "2.01462000"
    },
    {
        "id": 43845,
        "name": "Marin",
        "latitude": "46.37941000",
        "longitude": "6.52559000"
    },
    {
        "id": 43846,
        "name": "Marines",
        "latitude": "49.14485000",
        "longitude": "1.98226000"
    },
    {
        "id": 43847,
        "name": "Maringues",
        "latitude": "45.92164000",
        "longitude": "3.33029000"
    },
    {
        "id": 43848,
        "name": "Marle",
        "latitude": "49.74006000",
        "longitude": "3.77060000"
    },
    {
        "id": 43849,
        "name": "Marlenheim",
        "latitude": "48.62134000",
        "longitude": "7.49096000"
    },
    {
        "id": 43850,
        "name": "Marles-en-Brie",
        "latitude": "48.72783000",
        "longitude": "2.88003000"
    },
    {
        "id": 43851,
        "name": "Marles-les-Mines",
        "latitude": "50.50478000",
        "longitude": "2.50882000"
    },
    {
        "id": 43852,
        "name": "Marlhes",
        "latitude": "45.28307000",
        "longitude": "4.39616000"
    },
    {
        "id": 43853,
        "name": "Marly",
        "latitude": "50.34556000",
        "longitude": "3.54959000"
    },
    {
        "id": 43854,
        "name": "Marly",
        "latitude": "49.06382000",
        "longitude": "6.15427000"
    },
    {
        "id": 43855,
        "name": "Marly-la-Ville",
        "latitude": "49.08201000",
        "longitude": "2.50347000"
    },
    {
        "id": 43856,
        "name": "Marly-le-Roi",
        "latitude": "48.86667000",
        "longitude": "2.08333000"
    },
    {
        "id": 43857,
        "name": "Marmagne",
        "latitude": "46.83666000",
        "longitude": "4.35892000"
    },
    {
        "id": 43858,
        "name": "Marmagne",
        "latitude": "47.10000000",
        "longitude": "2.28333000"
    },
    {
        "id": 43859,
        "name": "Marmande",
        "latitude": "44.50012000",
        "longitude": "0.16526000"
    },
    {
        "id": 43860,
        "name": "Marmoutier",
        "latitude": "48.69005000",
        "longitude": "7.38195000"
    },
    {
        "id": 43861,
        "name": "Marnay",
        "latitude": "47.29088000",
        "longitude": "5.77277000"
    },
    {
        "id": 43862,
        "name": "Marnaz",
        "latitude": "46.06356000",
        "longitude": "6.52653000"
    },
    {
        "id": 43863,
        "name": "Marne",
        "latitude": "48.99116000",
        "longitude": "4.25966000"
    },
    {
        "id": 43864,
        "name": "Marnes-la-Coquette",
        "latitude": "48.82732000",
        "longitude": "2.17151000"
    },
    {
        "id": 43927,
        "name": "Marœuil",
        "latitude": "50.32519000",
        "longitude": "2.70504000"
    },
    {
        "id": 43865,
        "name": "Maroilles",
        "latitude": "50.13508000",
        "longitude": "3.76132000"
    },
    {
        "id": 43866,
        "name": "Marolles-en-Hurepoix",
        "latitude": "48.56232000",
        "longitude": "2.29885000"
    },
    {
        "id": 43867,
        "name": "Marolles-les-Braults",
        "latitude": "48.25271000",
        "longitude": "0.31631000"
    },
    {
        "id": 43868,
        "name": "Marolles-sur-Seine",
        "latitude": "48.38662000",
        "longitude": "3.03562000"
    },
    {
        "id": 43869,
        "name": "Maromme",
        "latitude": "49.47925000",
        "longitude": "1.02375000"
    },
    {
        "id": 43870,
        "name": "Marpent",
        "latitude": "50.29252000",
        "longitude": "4.07936000"
    },
    {
        "id": 43871,
        "name": "Marquefave",
        "latitude": "43.31766000",
        "longitude": "1.24661000"
    },
    {
        "id": 43872,
        "name": "Marquette-en-Ostrevant",
        "latitude": "50.28333000",
        "longitude": "3.26667000"
    },
    {
        "id": 43873,
        "name": "Marquette-lez-Lille",
        "latitude": "50.67628000",
        "longitude": "3.06613000"
    },
    {
        "id": 43874,
        "name": "Marquillies",
        "latitude": "50.55577000",
        "longitude": "2.87067000"
    },
    {
        "id": 43875,
        "name": "Marquion",
        "latitude": "50.21126000",
        "longitude": "3.08719000"
    },
    {
        "id": 43876,
        "name": "Marquise",
        "latitude": "50.81294000",
        "longitude": "1.70786000"
    },
    {
        "id": 43877,
        "name": "Marsac-en-Livradois",
        "latitude": "45.47886000",
        "longitude": "3.72783000"
    },
    {
        "id": 43878,
        "name": "Marsac-sur-Don",
        "latitude": "47.59648000",
        "longitude": "-1.67952000"
    },
    {
        "id": 43879,
        "name": "Marsannay-la-Côte",
        "latitude": "47.27095000",
        "longitude": "4.98895000"
    },
    {
        "id": 43880,
        "name": "Marsanne",
        "latitude": "44.64204000",
        "longitude": "4.87529000"
    },
    {
        "id": 43881,
        "name": "Marsat",
        "latitude": "45.87645000",
        "longitude": "3.08173000"
    },
    {
        "id": 43882,
        "name": "Marseillan",
        "latitude": "43.35618000",
        "longitude": "3.52795000"
    },
    {
        "id": 43883,
        "name": "Marseille",
        "latitude": "43.29695000",
        "longitude": "5.38107000"
    },
    {
        "id": 43884,
        "name": "Marseille 01",
        "latitude": "43.29814000",
        "longitude": "5.38407000"
    },
    {
        "id": 43885,
        "name": "Marseille 02",
        "latitude": "43.29664000",
        "longitude": "5.37034000"
    },
    {
        "id": 43886,
        "name": "Marseille 03",
        "latitude": "43.30257000",
        "longitude": "5.37583000"
    },
    {
        "id": 43887,
        "name": "Marseille 04",
        "latitude": "43.30213000",
        "longitude": "5.40141000"
    },
    {
        "id": 43888,
        "name": "Marseille 05",
        "latitude": "43.29551000",
        "longitude": "5.40055000"
    },
    {
        "id": 43889,
        "name": "Marseille 06",
        "latitude": "43.28764000",
        "longitude": "5.37918000"
    },
    {
        "id": 43890,
        "name": "Marseille 07",
        "latitude": "43.28289000",
        "longitude": "5.36021000"
    },
    {
        "id": 43891,
        "name": "Marseille 08",
        "latitude": "43.27083000",
        "longitude": "5.38210000"
    },
    {
        "id": 43892,
        "name": "Marseille 09",
        "latitude": "43.25433000",
        "longitude": "5.40570000"
    },
    {
        "id": 43893,
        "name": "Marseille 10",
        "latitude": "43.27889000",
        "longitude": "5.41523000"
    },
    {
        "id": 43894,
        "name": "Marseille 11",
        "latitude": "43.29070000",
        "longitude": "5.43840000"
    },
    {
        "id": 43895,
        "name": "Marseille 12",
        "latitude": "43.29614000",
        "longitude": "5.43617000"
    },
    {
        "id": 43896,
        "name": "Marseille 13",
        "latitude": "43.31856000",
        "longitude": "5.40836000"
    },
    {
        "id": 43897,
        "name": "Marseille 14",
        "latitude": "43.34447000",
        "longitude": "5.38004000"
    },
    {
        "id": 43898,
        "name": "Marseille 15",
        "latitude": "43.37224000",
        "longitude": "5.35386000"
    },
    {
        "id": 43899,
        "name": "Marseille 16",
        "latitude": "43.35982000",
        "longitude": "5.33421000"
    },
    {
        "id": 43900,
        "name": "Marseille Bompard",
        "latitude": "43.28120000",
        "longitude": "5.36081000"
    },
    {
        "id": 43901,
        "name": "Marseille Endoume",
        "latitude": "43.28320000",
        "longitude": "5.35159000"
    },
    {
        "id": 43902,
        "name": "Marseille Prefecture",
        "latitude": "43.29092000",
        "longitude": "5.37901000"
    },
    {
        "id": 43903,
        "name": "Marseille Roucas-Blanc",
        "latitude": "43.27652000",
        "longitude": "5.36519000"
    },
    {
        "id": 43904,
        "name": "Marseille Saint-Victor",
        "latitude": "43.28955000",
        "longitude": "5.36845000"
    },
    {
        "id": 43905,
        "name": "Marseille Vauban",
        "latitude": "43.28408000",
        "longitude": "5.37540000"
    },
    {
        "id": 43906,
        "name": "Marsillargues",
        "latitude": "43.66424000",
        "longitude": "4.17448000"
    },
    {
        "id": 43907,
        "name": "Marsilly",
        "latitude": "46.23027000",
        "longitude": "-1.13840000"
    },
    {
        "id": 43908,
        "name": "Marssac-sur-Tarn",
        "latitude": "43.91768000",
        "longitude": "2.02921000"
    },
    {
        "id": 43909,
        "name": "Martel",
        "latitude": "44.93667000",
        "longitude": "1.60851000"
    },
    {
        "id": 43910,
        "name": "Marthod",
        "latitude": "45.72714000",
        "longitude": "6.40299000"
    },
    {
        "id": 43911,
        "name": "Martignas-sur-Jalle",
        "latitude": "44.84528000",
        "longitude": "-0.78060000"
    },
    {
        "id": 43912,
        "name": "Martignat",
        "latitude": "46.20972000",
        "longitude": "5.61049000"
    },
    {
        "id": 43913,
        "name": "Martigné-Briand",
        "latitude": "47.23358000",
        "longitude": "-0.42933000"
    },
    {
        "id": 43914,
        "name": "Martigné-Ferchaud",
        "latitude": "47.82934000",
        "longitude": "-1.31911000"
    },
    {
        "id": 43915,
        "name": "Martigné-sur-Mayenne",
        "latitude": "48.20000000",
        "longitude": "-0.66667000"
    },
    {
        "id": 43916,
        "name": "Martigues",
        "latitude": "43.40735000",
        "longitude": "5.05526000"
    },
    {
        "id": 43917,
        "name": "Martillac",
        "latitude": "44.71100000",
        "longitude": "-0.53747000"
    },
    {
        "id": 43918,
        "name": "Martin-Église",
        "latitude": "49.90174000",
        "longitude": "1.14120000"
    },
    {
        "id": 43919,
        "name": "Martinvast",
        "latitude": "49.59608000",
        "longitude": "-1.66434000"
    },
    {
        "id": 43920,
        "name": "Martizay",
        "latitude": "46.80767000",
        "longitude": "1.04324000"
    },
    {
        "id": 43921,
        "name": "Martres-Tolosane",
        "latitude": "43.19929000",
        "longitude": "1.01056000"
    },
    {
        "id": 43922,
        "name": "Marvejols",
        "latitude": "44.55378000",
        "longitude": "3.29137000"
    },
    {
        "id": 43923,
        "name": "Mary-sur-Marne",
        "latitude": "49.01591000",
        "longitude": "3.02788000"
    },
    {
        "id": 43924,
        "name": "Marzan",
        "latitude": "47.54069000",
        "longitude": "-2.32466000"
    },
    {
        "id": 43925,
        "name": "Marzy",
        "latitude": "46.98125000",
        "longitude": "3.09352000"
    },
    {
        "id": 43928,
        "name": "Masevaux",
        "latitude": "47.77671000",
        "longitude": "6.99683000"
    },
    {
        "id": 43929,
        "name": "Masnières",
        "latitude": "50.11489000",
        "longitude": "3.20889000"
    },
    {
        "id": 43930,
        "name": "Masny",
        "latitude": "50.34797000",
        "longitude": "3.20165000"
    },
    {
        "id": 43931,
        "name": "Massay",
        "latitude": "47.15324000",
        "longitude": "1.99334000"
    },
    {
        "id": 43932,
        "name": "Masseube",
        "latitude": "43.42984000",
        "longitude": "0.57810000"
    },
    {
        "id": 43933,
        "name": "Massiac",
        "latitude": "45.25146000",
        "longitude": "3.19735000"
    },
    {
        "id": 43934,
        "name": "Massieux",
        "latitude": "45.90984000",
        "longitude": "4.83265000"
    },
    {
        "id": 43935,
        "name": "Massongy",
        "latitude": "46.31807000",
        "longitude": "6.33148000"
    },
    {
        "id": 43936,
        "name": "Massy",
        "latitude": "48.72692000",
        "longitude": "2.28301000"
    },
    {
        "id": 43937,
        "name": "Matha",
        "latitude": "45.86823000",
        "longitude": "-0.31849000"
    },
    {
        "id": 43938,
        "name": "Mathay",
        "latitude": "47.43905000",
        "longitude": "6.78487000"
    },
    {
        "id": 43939,
        "name": "Mathieu",
        "latitude": "49.25448000",
        "longitude": "-0.37188000"
    },
    {
        "id": 43940,
        "name": "Matignon",
        "latitude": "48.59627000",
        "longitude": "-2.29125000"
    },
    {
        "id": 43941,
        "name": "Matour",
        "latitude": "46.30654000",
        "longitude": "4.48178000"
    },
    {
        "id": 43942,
        "name": "Matzenheim",
        "latitude": "48.39450000",
        "longitude": "7.62185000"
    },
    {
        "id": 43943,
        "name": "Maubec",
        "latitude": "43.84379000",
        "longitude": "5.13919000"
    },
    {
        "id": 43944,
        "name": "Maubec",
        "latitude": "45.56739000",
        "longitude": "5.26371000"
    },
    {
        "id": 43945,
        "name": "Maubeuge",
        "latitude": "50.27875000",
        "longitude": "3.97267000"
    },
    {
        "id": 43946,
        "name": "Maubourguet",
        "latitude": "43.46824000",
        "longitude": "0.03578000"
    },
    {
        "id": 43947,
        "name": "Mauguio",
        "latitude": "43.61810000",
        "longitude": "4.00739000"
    },
    {
        "id": 43948,
        "name": "Maule",
        "latitude": "48.91056000",
        "longitude": "1.85264000"
    },
    {
        "id": 43949,
        "name": "Mauléon-Licharre",
        "latitude": "43.22684000",
        "longitude": "-0.88038000"
    },
    {
        "id": 43950,
        "name": "Maulévrier",
        "latitude": "47.00929000",
        "longitude": "-0.74239000"
    },
    {
        "id": 43951,
        "name": "Maure-de-Bretagne",
        "latitude": "47.89239000",
        "longitude": "-1.99104000"
    },
    {
        "id": 43952,
        "name": "Maurecourt",
        "latitude": "48.99614000",
        "longitude": "2.06155000"
    },
    {
        "id": 43953,
        "name": "Maureilhan",
        "latitude": "43.35000000",
        "longitude": "3.11667000"
    },
    {
        "id": 43954,
        "name": "Maureillas-las-Illas",
        "latitude": "42.49014000",
        "longitude": "2.80752000"
    },
    {
        "id": 43955,
        "name": "Maurepas",
        "latitude": "48.76486000",
        "longitude": "1.92923000"
    },
    {
        "id": 43956,
        "name": "Mauriac",
        "latitude": "45.21899000",
        "longitude": "2.33353000"
    },
    {
        "id": 43957,
        "name": "Mauron",
        "latitude": "48.08257000",
        "longitude": "-2.28477000"
    },
    {
        "id": 43958,
        "name": "Maurs",
        "latitude": "44.71065000",
        "longitude": "2.19797000"
    },
    {
        "id": 43959,
        "name": "Maussane-les-Alpilles",
        "latitude": "43.72236000",
        "longitude": "4.80497000"
    },
    {
        "id": 43960,
        "name": "Mauves",
        "latitude": "45.03833000",
        "longitude": "4.82934000"
    },
    {
        "id": 43961,
        "name": "Mauves-sur-Loire",
        "latitude": "47.29738000",
        "longitude": "-1.38763000"
    },
    {
        "id": 43962,
        "name": "Mauvezin",
        "latitude": "43.73048000",
        "longitude": "0.87810000"
    },
    {
        "id": 43964,
        "name": "Mauzé-sur-le-Mignon",
        "latitude": "46.19516000",
        "longitude": "-0.67032000"
    },
    {
        "id": 43963,
        "name": "Mauzé-Thouarsais",
        "latitude": "46.97611000",
        "longitude": "-0.27846000"
    },
    {
        "id": 43965,
        "name": "Maxent",
        "latitude": "47.98333000",
        "longitude": "-2.03333000"
    },
    {
        "id": 43967,
        "name": "Maxéville",
        "latitude": "48.71324000",
        "longitude": "6.16658000"
    },
    {
        "id": 43966,
        "name": "Maxilly-sur-Léman",
        "latitude": "46.39828000",
        "longitude": "6.63113000"
    },
    {
        "id": 43968,
        "name": "May-sur-Orne",
        "latitude": "49.10107000",
        "longitude": "-0.37471000"
    },
    {
        "id": 43969,
        "name": "Mayenne",
        "latitude": "48.13494000",
        "longitude": "-0.66667000"
    },
    {
        "id": 43970,
        "name": "Mayet",
        "latitude": "47.75968000",
        "longitude": "0.27468000"
    },
    {
        "id": 43971,
        "name": "Mazamet",
        "latitude": "43.49001000",
        "longitude": "2.37304000"
    },
    {
        "id": 43972,
        "name": "Mazan",
        "latitude": "44.05696000",
        "longitude": "5.12680000"
    },
    {
        "id": 43973,
        "name": "Mazargues",
        "latitude": "43.24622000",
        "longitude": "5.39788000"
    },
    {
        "id": 43980,
        "name": "Mazé",
        "latitude": "47.45632000",
        "longitude": "-0.27106000"
    },
    {
        "id": 43978,
        "name": "Mazères",
        "latitude": "43.25055000",
        "longitude": "1.67728000"
    },
    {
        "id": 43979,
        "name": "Mazères-Lezons",
        "latitude": "43.26667000",
        "longitude": "-0.35000000"
    },
    {
        "id": 43974,
        "name": "Mazet-Saint-Voy",
        "latitude": "45.04696000",
        "longitude": "4.24414000"
    },
    {
        "id": 43976,
        "name": "Mazières-de-Touraine",
        "latitude": "47.38326000",
        "longitude": "0.42548000"
    },
    {
        "id": 43977,
        "name": "Mazières-en-Mauges",
        "latitude": "47.04536000",
        "longitude": "-0.81702000"
    },
    {
        "id": 43975,
        "name": "Mazingarbe",
        "latitude": "50.47109000",
        "longitude": "2.71544000"
    },
    {
        "id": 44454,
        "name": "Méaudre",
        "latitude": "45.13020000",
        "longitude": "5.52853000"
    },
    {
        "id": 44455,
        "name": "Méaulte",
        "latitude": "49.98167000",
        "longitude": "2.66121000"
    },
    {
        "id": 43983,
        "name": "Meaux",
        "latitude": "48.96014000",
        "longitude": "2.87885000"
    },
    {
        "id": 44456,
        "name": "Mécleuves",
        "latitude": "49.04429000",
        "longitude": "6.27051000"
    },
    {
        "id": 44457,
        "name": "Médan",
        "latitude": "48.95539000",
        "longitude": "1.99494000"
    },
    {
        "id": 44458,
        "name": "Médis",
        "latitude": "45.64337000",
        "longitude": "-0.96298000"
    },
    {
        "id": 44459,
        "name": "Médréac",
        "latitude": "48.26703000",
        "longitude": "-2.06618000"
    },
    {
        "id": 44460,
        "name": "Mées",
        "latitude": "43.70452000",
        "longitude": "-1.10952000"
    },
    {
        "id": 43984,
        "name": "Megève",
        "latitude": "45.85687000",
        "longitude": "6.61775000"
    },
    {
        "id": 43985,
        "name": "Mehun-sur-Yèvre",
        "latitude": "47.13782000",
        "longitude": "2.21105000"
    },
    {
        "id": 43986,
        "name": "Meilhan",
        "latitude": "43.86436000",
        "longitude": "-0.70587000"
    },
    {
        "id": 43987,
        "name": "Meilhan-sur-Garonne",
        "latitude": "44.52131000",
        "longitude": "0.03472000"
    },
    {
        "id": 43988,
        "name": "Meillac",
        "latitude": "48.41174000",
        "longitude": "-1.81378000"
    },
    {
        "id": 43989,
        "name": "Meillonnas",
        "latitude": "46.24367000",
        "longitude": "5.35019000"
    },
    {
        "id": 43990,
        "name": "Meistratzheim",
        "latitude": "48.44853000",
        "longitude": "7.54350000"
    },
    {
        "id": 43991,
        "name": "Melay",
        "latitude": "47.18333000",
        "longitude": "-0.69429000"
    },
    {
        "id": 43992,
        "name": "Melesse",
        "latitude": "48.21771000",
        "longitude": "-1.69496000"
    },
    {
        "id": 43993,
        "name": "Melgven",
        "latitude": "47.90626000",
        "longitude": "-3.83518000"
    },
    {
        "id": 44461,
        "name": "Mélisey",
        "latitude": "47.75229000",
        "longitude": "6.58014000"
    },
    {
        "id": 43994,
        "name": "Mellac",
        "latitude": "47.90345000",
        "longitude": "-3.57675000"
    },
    {
        "id": 43995,
        "name": "Melle",
        "latitude": "46.22285000",
        "longitude": "-0.14216000"
    },
    {
        "id": 43996,
        "name": "Mellecey",
        "latitude": "46.81084000",
        "longitude": "4.72822000"
    },
    {
        "id": 43997,
        "name": "Melrand",
        "latitude": "47.98002000",
        "longitude": "-3.10941000"
    },
    {
        "id": 43998,
        "name": "Melun",
        "latitude": "48.54570000",
        "longitude": "2.65356000"
    },
    {
        "id": 43999,
        "name": "Mende",
        "latitude": "44.51849000",
        "longitude": "3.50372000"
    },
    {
        "id": 44466,
        "name": "Ménéac",
        "latitude": "48.13972000",
        "longitude": "-2.46134000"
    },
    {
        "id": 44462,
        "name": "Ménerbes",
        "latitude": "43.83242000",
        "longitude": "5.20597000"
    },
    {
        "id": 44463,
        "name": "Ménesplet",
        "latitude": "45.01667000",
        "longitude": "0.11667000"
    },
    {
        "id": 44464,
        "name": "Ménestreau-en-Villette",
        "latitude": "47.69962000",
        "longitude": "2.02333000"
    },
    {
        "id": 44000,
        "name": "Menetou-Salon",
        "latitude": "47.23172000",
        "longitude": "2.48715000"
    },
    {
        "id": 44467,
        "name": "Ménétrol",
        "latitude": "45.87155000",
        "longitude": "3.12422000"
    },
    {
        "id": 44465,
        "name": "Ménilles",
        "latitude": "49.03333000",
        "longitude": "1.36667000"
    },
    {
        "id": 44001,
        "name": "Mennecy",
        "latitude": "48.56903000",
        "longitude": "2.44384000"
    },
    {
        "id": 44002,
        "name": "Menneval",
        "latitude": "49.10000000",
        "longitude": "0.61667000"
    },
    {
        "id": 44003,
        "name": "Menpenti",
        "latitude": "43.28198000",
        "longitude": "5.39607000"
    },
    {
        "id": 44004,
        "name": "Mens",
        "latitude": "44.81557000",
        "longitude": "5.75146000"
    },
    {
        "id": 44005,
        "name": "Mensignac",
        "latitude": "45.22505000",
        "longitude": "0.56214000"
    },
    {
        "id": 44006,
        "name": "Menthon-Saint-Bernard",
        "latitude": "45.85995000",
        "longitude": "6.19552000"
    },
    {
        "id": 44007,
        "name": "Menton",
        "latitude": "43.77649000",
        "longitude": "7.50435000"
    },
    {
        "id": 44008,
        "name": "Menucourt",
        "latitude": "49.02841000",
        "longitude": "1.98046000"
    },
    {
        "id": 44468,
        "name": "Méounes-lès-Montrieux",
        "latitude": "43.28102000",
        "longitude": "5.96986000"
    },
    {
        "id": 44009,
        "name": "Mer",
        "latitude": "47.70555000",
        "longitude": "1.50621000"
    },
    {
        "id": 44010,
        "name": "Mercurey",
        "latitude": "46.84035000",
        "longitude": "4.71743000"
    },
    {
        "id": 44011,
        "name": "Mercurol-Veaunes",
        "latitude": "45.07437000",
        "longitude": "4.89279000"
    },
    {
        "id": 44012,
        "name": "Mercury",
        "latitude": "45.67452000",
        "longitude": "6.33688000"
    },
    {
        "id": 44013,
        "name": "Mercus-Garrabet",
        "latitude": "42.87790000",
        "longitude": "1.62900000"
    },
    {
        "id": 44014,
        "name": "Mercy-le-Bas",
        "latitude": "49.38935000",
        "longitude": "5.75599000"
    },
    {
        "id": 44015,
        "name": "Merdrignac",
        "latitude": "48.19372000",
        "longitude": "-2.41533000"
    },
    {
        "id": 44480,
        "name": "Méré",
        "latitude": "48.78445000",
        "longitude": "1.81247000"
    },
    {
        "id": 44469,
        "name": "Méreau",
        "latitude": "47.16295000",
        "longitude": "2.05086000"
    },
    {
        "id": 44481,
        "name": "Méréville",
        "latitude": "48.59113000",
        "longitude": "6.15106000"
    },
    {
        "id": 44482,
        "name": "Méréville",
        "latitude": "48.31476000",
        "longitude": "2.08609000"
    },
    {
        "id": 44470,
        "name": "Méribel",
        "latitude": "45.41497000",
        "longitude": "6.56500000"
    },
    {
        "id": 44471,
        "name": "Méricourt",
        "latitude": "50.40116000",
        "longitude": "2.86246000"
    },
    {
        "id": 44472,
        "name": "Mériel",
        "latitude": "49.07614000",
        "longitude": "2.21054000"
    },
    {
        "id": 44473,
        "name": "Mérignac",
        "latitude": "44.83248000",
        "longitude": "-0.63381000"
    },
    {
        "id": 44474,
        "name": "Mérignies",
        "latitude": "50.50609000",
        "longitude": "3.11043000"
    },
    {
        "id": 44475,
        "name": "Mérindol",
        "latitude": "43.75516000",
        "longitude": "5.20357000"
    },
    {
        "id": 44016,
        "name": "Merlevenez",
        "latitude": "47.73563000",
        "longitude": "-3.23047000"
    },
    {
        "id": 44017,
        "name": "Merlimont",
        "latitude": "50.45590000",
        "longitude": "1.61315000"
    },
    {
        "id": 44018,
        "name": "Mers-les-Bains",
        "latitude": "50.06538000",
        "longitude": "1.38810000"
    },
    {
        "id": 44019,
        "name": "Merten",
        "latitude": "49.24903000",
        "longitude": "6.66465000"
    },
    {
        "id": 44020,
        "name": "Mertzwiller",
        "latitude": "48.86558000",
        "longitude": "7.68136000"
    },
    {
        "id": 44476,
        "name": "Méru",
        "latitude": "49.23333000",
        "longitude": "2.13333000"
    },
    {
        "id": 44021,
        "name": "Mervans",
        "latitude": "46.79923000",
        "longitude": "5.18430000"
    },
    {
        "id": 44022,
        "name": "Mervent",
        "latitude": "46.52239000",
        "longitude": "-0.75654000"
    },
    {
        "id": 44023,
        "name": "Merville",
        "latitude": "50.64329000",
        "longitude": "2.64125000"
    },
    {
        "id": 44024,
        "name": "Merville",
        "latitude": "43.72354000",
        "longitude": "1.29656000"
    },
    {
        "id": 44025,
        "name": "Merville-Franceville-Plage",
        "latitude": "49.27218000",
        "longitude": "-0.19616000"
    },
    {
        "id": 44026,
        "name": "Merxheim",
        "latitude": "47.91128000",
        "longitude": "7.29492000"
    },
    {
        "id": 44477,
        "name": "Méry",
        "latitude": "45.64139000",
        "longitude": "5.93550000"
    },
    {
        "id": 44478,
        "name": "Méry-sur-Oise",
        "latitude": "49.05876000",
        "longitude": "2.19113000"
    },
    {
        "id": 44479,
        "name": "Méry-sur-Seine",
        "latitude": "48.50937000",
        "longitude": "3.89129000"
    },
    {
        "id": 44483,
        "name": "Mésanger",
        "latitude": "47.43131000",
        "longitude": "-1.22798000"
    },
    {
        "id": 44027,
        "name": "Meschers-sur-Gironde",
        "latitude": "45.56037000",
        "longitude": "-0.95470000"
    },
    {
        "id": 44028,
        "name": "Meslan",
        "latitude": "47.99502000",
        "longitude": "-3.43285000"
    },
    {
        "id": 44029,
        "name": "Meslay-du-Maine",
        "latitude": "47.95116000",
        "longitude": "-0.55428000"
    },
    {
        "id": 44030,
        "name": "Mesnières-en-Bray",
        "latitude": "49.76172000",
        "longitude": "1.38187000"
    },
    {
        "id": 44031,
        "name": "Mesquer",
        "latitude": "47.39919000",
        "longitude": "-2.45986000"
    },
    {
        "id": 44032,
        "name": "Messac",
        "latitude": "47.82399000",
        "longitude": "-1.81085000"
    },
    {
        "id": 44033,
        "name": "Messei",
        "latitude": "48.71157000",
        "longitude": "-0.53739000"
    },
    {
        "id": 44034,
        "name": "Messein",
        "latitude": "48.61180000",
        "longitude": "6.13959000"
    },
    {
        "id": 44035,
        "name": "Messeix",
        "latitude": "45.61702000",
        "longitude": "2.54157000"
    },
    {
        "id": 44036,
        "name": "Messery",
        "latitude": "46.35036000",
        "longitude": "6.29099000"
    },
    {
        "id": 44037,
        "name": "Messigny-et-Vantoux",
        "latitude": "47.40666000",
        "longitude": "5.01715000"
    },
    {
        "id": 44038,
        "name": "Messimy",
        "latitude": "45.69816000",
        "longitude": "4.67429000"
    },
    {
        "id": 44484,
        "name": "Méteren",
        "latitude": "50.73333000",
        "longitude": "2.70000000"
    },
    {
        "id": 44039,
        "name": "Mettray",
        "latitude": "47.45251000",
        "longitude": "0.64939000"
    },
    {
        "id": 44040,
        "name": "Metz",
        "latitude": "45.93343000",
        "longitude": "6.10973000"
    },
    {
        "id": 44041,
        "name": "Metz",
        "latitude": "49.11911000",
        "longitude": "6.17269000"
    },
    {
        "id": 44042,
        "name": "Metzeral",
        "latitude": "48.01217000",
        "longitude": "7.07091000"
    },
    {
        "id": 44043,
        "name": "Metzervisse",
        "latitude": "49.31476000",
        "longitude": "6.28277000"
    },
    {
        "id": 44044,
        "name": "Meucon",
        "latitude": "47.71648000",
        "longitude": "-2.76170000"
    },
    {
        "id": 44045,
        "name": "Meudon",
        "latitude": "48.81381000",
        "longitude": "2.23500000"
    },
    {
        "id": 44046,
        "name": "Meulan-en-Yvelines",
        "latitude": "49.00768000",
        "longitude": "1.90602000"
    },
    {
        "id": 44047,
        "name": "Meung-sur-Loire",
        "latitude": "47.83124000",
        "longitude": "1.69582000"
    },
    {
        "id": 44048,
        "name": "Meurchin",
        "latitude": "50.49831000",
        "longitude": "2.89318000"
    },
    {
        "id": 44049,
        "name": "Meursac",
        "latitude": "45.64890000",
        "longitude": "-0.80801000"
    },
    {
        "id": 44050,
        "name": "Meursault",
        "latitude": "46.97822000",
        "longitude": "4.76952000"
    },
    {
        "id": 44051,
        "name": "Meurthe-et-Moselle",
        "latitude": "48.65082000",
        "longitude": "6.15660000"
    },
    {
        "id": 44052,
        "name": "Meuse",
        "latitude": "48.97176000",
        "longitude": "5.36371000"
    },
    {
        "id": 44053,
        "name": "Meximieux",
        "latitude": "45.90823000",
        "longitude": "5.19920000"
    },
    {
        "id": 44054,
        "name": "Mexy",
        "latitude": "49.50072000",
        "longitude": "5.78078000"
    },
    {
        "id": 44055,
        "name": "Meyenheim",
        "latitude": "47.91364000",
        "longitude": "7.35560000"
    },
    {
        "id": 44056,
        "name": "Meylan",
        "latitude": "45.20978000",
        "longitude": "5.77762000"
    },
    {
        "id": 44057,
        "name": "Meymac",
        "latitude": "45.53583000",
        "longitude": "2.14699000"
    },
    {
        "id": 44058,
        "name": "Meynes",
        "latitude": "43.87974000",
        "longitude": "4.55958000"
    },
    {
        "id": 44059,
        "name": "Meyrargues",
        "latitude": "43.63677000",
        "longitude": "5.52679000"
    },
    {
        "id": 44060,
        "name": "Meyreuil",
        "latitude": "43.48780000",
        "longitude": "5.49574000"
    },
    {
        "id": 44061,
        "name": "Meyrueis",
        "latitude": "44.17737000",
        "longitude": "3.43083000"
    },
    {
        "id": 44062,
        "name": "Meyssac",
        "latitude": "45.05547000",
        "longitude": "1.67412000"
    },
    {
        "id": 44063,
        "name": "Meysse",
        "latitude": "44.61028000",
        "longitude": "4.72306000"
    },
    {
        "id": 44064,
        "name": "Meythet",
        "latitude": "45.91836000",
        "longitude": "6.09422000"
    },
    {
        "id": 44065,
        "name": "Meyzieu",
        "latitude": "45.76637000",
        "longitude": "5.00277000"
    },
    {
        "id": 44453,
        "name": "Mèze",
        "latitude": "43.42504000",
        "longitude": "3.60590000"
    },
    {
        "id": 44066,
        "name": "Mezel",
        "latitude": "45.75508000",
        "longitude": "3.24225000"
    },
    {
        "id": 44485,
        "name": "Mézeray",
        "latitude": "47.82682000",
        "longitude": "-0.01770000"
    },
    {
        "id": 44492,
        "name": "Mézériat",
        "latitude": "46.23701000",
        "longitude": "5.04615000"
    },
    {
        "id": 44488,
        "name": "Mézières-en-Brenne",
        "latitude": "46.81979000",
        "longitude": "1.21123000"
    },
    {
        "id": 44489,
        "name": "Mézières-en-Drouais",
        "latitude": "48.72413000",
        "longitude": "1.42440000"
    },
    {
        "id": 44490,
        "name": "Mézières-sur-Seine",
        "latitude": "48.96128000",
        "longitude": "1.79245000"
    },
    {
        "id": 44486,
        "name": "Mézin",
        "latitude": "44.05668000",
        "longitude": "0.25874000"
    },
    {
        "id": 44487,
        "name": "Méziré",
        "latitude": "47.53336000",
        "longitude": "6.92072000"
    },
    {
        "id": 44491,
        "name": "Mézy-sur-Seine",
        "latitude": "49.00000000",
        "longitude": "1.88333000"
    },
    {
        "id": 44108,
        "name": "Miélan",
        "latitude": "43.43054000",
        "longitude": "0.30794000"
    },
    {
        "id": 44067,
        "name": "Mieussy",
        "latitude": "46.13369000",
        "longitude": "6.52416000"
    },
    {
        "id": 44068,
        "name": "Migennes",
        "latitude": "47.96548000",
        "longitude": "3.51787000"
    },
    {
        "id": 44069,
        "name": "Mignaloux-Beauvoir",
        "latitude": "46.54157000",
        "longitude": "0.41538000"
    },
    {
        "id": 44070,
        "name": "Migné-Auxances",
        "latitude": "46.62745000",
        "longitude": "0.31458000"
    },
    {
        "id": 44071,
        "name": "Milhaud",
        "latitude": "43.78977000",
        "longitude": "4.31035000"
    },
    {
        "id": 44072,
        "name": "Milizac",
        "latitude": "48.46667000",
        "longitude": "-4.56667000"
    },
    {
        "id": 44073,
        "name": "Millas",
        "latitude": "42.69203000",
        "longitude": "2.69508000"
    },
    {
        "id": 44074,
        "name": "Millau",
        "latitude": "44.09774000",
        "longitude": "3.07777000"
    },
    {
        "id": 44075,
        "name": "Millery",
        "latitude": "45.63232000",
        "longitude": "4.78207000"
    },
    {
        "id": 44076,
        "name": "Milly-la-Forêt",
        "latitude": "48.40400000",
        "longitude": "2.47015000"
    },
    {
        "id": 44077,
        "name": "Milly-sur-Thérain",
        "latitude": "49.50240000",
        "longitude": "1.99605000"
    },
    {
        "id": 44078,
        "name": "Mimbaste",
        "latitude": "43.64887000",
        "longitude": "-0.97383000"
    },
    {
        "id": 44079,
        "name": "Mimet",
        "latitude": "43.41427000",
        "longitude": "5.50579000"
    },
    {
        "id": 44080,
        "name": "Mimizan",
        "latitude": "44.20057000",
        "longitude": "-1.22886000"
    },
    {
        "id": 44081,
        "name": "Miniac-Morvan",
        "latitude": "48.51477000",
        "longitude": "-1.89955000"
    },
    {
        "id": 44082,
        "name": "Minihy-Tréguier",
        "latitude": "48.77746000",
        "longitude": "-3.22883000"
    },
    {
        "id": 44083,
        "name": "Mionnay",
        "latitude": "45.89824000",
        "longitude": "4.92808000"
    },
    {
        "id": 44084,
        "name": "Mions",
        "latitude": "45.66302000",
        "longitude": "4.95292000"
    },
    {
        "id": 44085,
        "name": "Mios",
        "latitude": "44.60489000",
        "longitude": "-0.93329000"
    },
    {
        "id": 44086,
        "name": "Mirabel-aux-Baronnies",
        "latitude": "44.31002000",
        "longitude": "5.09962000"
    },
    {
        "id": 44087,
        "name": "Miramas",
        "latitude": "43.58508000",
        "longitude": "5.00268000"
    },
    {
        "id": 44088,
        "name": "Mirambeau",
        "latitude": "45.37310000",
        "longitude": "-0.57067000"
    },
    {
        "id": 44089,
        "name": "Miramont-de-Guyenne",
        "latitude": "44.60157000",
        "longitude": "0.36247000"
    },
    {
        "id": 44090,
        "name": "Mirande",
        "latitude": "43.51481000",
        "longitude": "0.40410000"
    },
    {
        "id": 44091,
        "name": "Mirandol-Bourgnounac",
        "latitude": "44.14273000",
        "longitude": "2.16857000"
    },
    {
        "id": 44102,
        "name": "Miré",
        "latitude": "47.75759000",
        "longitude": "-0.49157000"
    },
    {
        "id": 44092,
        "name": "Mirebeau",
        "latitude": "46.78743000",
        "longitude": "0.18341000"
    },
    {
        "id": 44093,
        "name": "Mirebeau-sur-Bèze",
        "latitude": "47.39828000",
        "longitude": "5.32093000"
    },
    {
        "id": 44094,
        "name": "Mirecourt",
        "latitude": "48.30089000",
        "longitude": "6.13282000"
    },
    {
        "id": 44095,
        "name": "Mirefleurs",
        "latitude": "45.69319000",
        "longitude": "3.22370000"
    },
    {
        "id": 44096,
        "name": "Miremont",
        "latitude": "43.36995000",
        "longitude": "1.41724000"
    },
    {
        "id": 44097,
        "name": "Mirepeix",
        "latitude": "43.18333000",
        "longitude": "-0.25000000"
    },
    {
        "id": 44098,
        "name": "Mirepoix",
        "latitude": "43.08773000",
        "longitude": "1.87350000"
    },
    {
        "id": 44099,
        "name": "Mireval",
        "latitude": "43.50860000",
        "longitude": "3.80170000"
    },
    {
        "id": 44100,
        "name": "Miribel",
        "latitude": "45.82580000",
        "longitude": "4.95440000"
    },
    {
        "id": 44101,
        "name": "Miribel-les-Échelles",
        "latitude": "45.43155000",
        "longitude": "5.70810000"
    },
    {
        "id": 44103,
        "name": "Miserey-Salines",
        "latitude": "47.28638000",
        "longitude": "5.97387000"
    },
    {
        "id": 44105,
        "name": "Misérieux",
        "latitude": "45.97433000",
        "longitude": "4.81340000"
    },
    {
        "id": 44104,
        "name": "Missillac",
        "latitude": "47.48155000",
        "longitude": "-2.16000000"
    },
    {
        "id": 44106,
        "name": "Mitry-Mory",
        "latitude": "48.98333000",
        "longitude": "2.61667000"
    },
    {
        "id": 44107,
        "name": "Mittelhausbergen",
        "latitude": "48.61436000",
        "longitude": "7.69009000"
    },
    {
        "id": 44109,
        "name": "Modane",
        "latitude": "45.20032000",
        "longitude": "6.66871000"
    },
    {
        "id": 44427,
        "name": "Moëlan-sur-Mer",
        "latitude": "47.81418000",
        "longitude": "-3.62892000"
    },
    {
        "id": 44110,
        "name": "Mogneville",
        "latitude": "49.31535000",
        "longitude": "2.47170000"
    },
    {
        "id": 44111,
        "name": "Moidieu",
        "latitude": "45.51680000",
        "longitude": "5.00749000"
    },
    {
        "id": 44112,
        "name": "Moigny-sur-École",
        "latitude": "48.43262000",
        "longitude": "2.45802000"
    },
    {
        "id": 44113,
        "name": "Moirans",
        "latitude": "45.33140000",
        "longitude": "5.55432000"
    },
    {
        "id": 44114,
        "name": "Moirans-en-Montagne",
        "latitude": "46.43381000",
        "longitude": "5.72643000"
    },
    {
        "id": 44115,
        "name": "Moisdon-la-Rivière",
        "latitude": "47.62175000",
        "longitude": "-1.37258000"
    },
    {
        "id": 44116,
        "name": "Moisenay",
        "latitude": "48.56266000",
        "longitude": "2.73527000"
    },
    {
        "id": 44117,
        "name": "Moislains",
        "latitude": "49.98820000",
        "longitude": "2.96404000"
    },
    {
        "id": 44118,
        "name": "Moissac",
        "latitude": "44.10236000",
        "longitude": "1.09450000"
    },
    {
        "id": 44119,
        "name": "Moisselles",
        "latitude": "49.05000000",
        "longitude": "2.33597000"
    },
    {
        "id": 44120,
        "name": "Moissy-Cramayel",
        "latitude": "48.62605000",
        "longitude": "2.60125000"
    },
    {
        "id": 44121,
        "name": "Molac",
        "latitude": "47.73011000",
        "longitude": "-2.43484000"
    },
    {
        "id": 44123,
        "name": "Molières",
        "latitude": "44.19305000",
        "longitude": "1.36318000"
    },
    {
        "id": 44124,
        "name": "Molières-sur-Cèze",
        "latitude": "44.25989000",
        "longitude": "4.15836000"
    },
    {
        "id": 44122,
        "name": "Molinet",
        "latitude": "46.46576000",
        "longitude": "3.93589000"
    },
    {
        "id": 44125,
        "name": "Mollégès",
        "latitude": "43.80703000",
        "longitude": "4.94934000"
    },
    {
        "id": 44126,
        "name": "Molsheim",
        "latitude": "48.54042000",
        "longitude": "7.49199000"
    },
    {
        "id": 44127,
        "name": "Mommenheim",
        "latitude": "48.75782000",
        "longitude": "7.64494000"
    },
    {
        "id": 44128,
        "name": "Monbazillac",
        "latitude": "44.79374000",
        "longitude": "0.49256000"
    },
    {
        "id": 44134,
        "name": "Moncé-en-Belin",
        "latitude": "47.89442000",
        "longitude": "0.19809000"
    },
    {
        "id": 44129,
        "name": "Monchecourt",
        "latitude": "50.30413000",
        "longitude": "3.21029000"
    },
    {
        "id": 44130,
        "name": "Monchy-Saint-Éloi",
        "latitude": "49.29042000",
        "longitude": "2.46739000"
    },
    {
        "id": 44131,
        "name": "Monclar-de-Quercy",
        "latitude": "43.96554000",
        "longitude": "1.58587000"
    },
    {
        "id": 44132,
        "name": "Moncontour",
        "latitude": "46.88086000",
        "longitude": "-0.01996000"
    },
    {
        "id": 44133,
        "name": "Moncoutant",
        "latitude": "46.72547000",
        "longitude": "-0.58797000"
    },
    {
        "id": 44135,
        "name": "Mondelange",
        "latitude": "49.26145000",
        "longitude": "6.16503000"
    },
    {
        "id": 44136,
        "name": "Mondeville",
        "latitude": "49.17497000",
        "longitude": "-0.32238000"
    },
    {
        "id": 44137,
        "name": "Mondonville",
        "latitude": "43.67296000",
        "longitude": "1.28592000"
    },
    {
        "id": 44138,
        "name": "Mondoubleau",
        "latitude": "47.98061000",
        "longitude": "0.89782000"
    },
    {
        "id": 44139,
        "name": "Mondragon",
        "latitude": "44.23831000",
        "longitude": "4.71286000"
    },
    {
        "id": 44140,
        "name": "Monein",
        "latitude": "43.33333000",
        "longitude": "-0.58333000"
    },
    {
        "id": 44141,
        "name": "Monestiés",
        "latitude": "44.06667000",
        "longitude": "2.10000000"
    },
    {
        "id": 44336,
        "name": "Monéteau",
        "latitude": "47.84923000",
        "longitude": "3.58178000"
    },
    {
        "id": 44142,
        "name": "Monflanquin",
        "latitude": "44.52966000",
        "longitude": "0.76772000"
    },
    {
        "id": 44143,
        "name": "Monistrol-sur-Loire",
        "latitude": "45.29263000",
        "longitude": "4.17233000"
    },
    {
        "id": 44144,
        "name": "Monnaie",
        "latitude": "47.50162000",
        "longitude": "0.78872000"
    },
    {
        "id": 44145,
        "name": "Monnetier-Mornex",
        "latitude": "46.16030000",
        "longitude": "6.20667000"
    },
    {
        "id": 44146,
        "name": "Monnières",
        "latitude": "47.13301000",
        "longitude": "-1.35317000"
    },
    {
        "id": 44147,
        "name": "Mons",
        "latitude": "43.61240000",
        "longitude": "1.57207000"
    },
    {
        "id": 44148,
        "name": "Mons-en-Barœul",
        "latitude": "50.63333000",
        "longitude": "3.11667000"
    },
    {
        "id": 44149,
        "name": "Mons-en-Pévèle",
        "latitude": "50.47926000",
        "longitude": "3.09767000"
    },
    {
        "id": 44152,
        "name": "Monségur",
        "latitude": "44.65040000",
        "longitude": "0.08047000"
    },
    {
        "id": 44150,
        "name": "Monsempron-Libos",
        "latitude": "44.49004000",
        "longitude": "0.94090000"
    },
    {
        "id": 44151,
        "name": "Monswiller",
        "latitude": "48.75511000",
        "longitude": "7.37732000"
    },
    {
        "id": 44153,
        "name": "Mont",
        "latitude": "43.43333000",
        "longitude": "-0.65000000"
    },
    {
        "id": 44154,
        "name": "Mont-Bernanchon",
        "latitude": "50.58333000",
        "longitude": "2.58333000"
    },
    {
        "id": 44160,
        "name": "Mont-de-Lans",
        "latitude": "45.03626000",
        "longitude": "6.13141000"
    },
    {
        "id": 44161,
        "name": "Mont-de-Marsan",
        "latitude": "43.89022000",
        "longitude": "-0.49713000"
    },
    {
        "id": 44155,
        "name": "Mont-Dore",
        "latitude": "45.57641000",
        "longitude": "2.80889000"
    },
    {
        "id": 44162,
        "name": "Mont-près-Chambord",
        "latitude": "47.56235000",
        "longitude": "1.45712000"
    },
    {
        "id": 44156,
        "name": "Mont-Saint-Aignan",
        "latitude": "49.46307000",
        "longitude": "1.09364000"
    },
    {
        "id": 44158,
        "name": "Mont-Saint-Éloi",
        "latitude": "50.35105000",
        "longitude": "2.69249000"
    },
    {
        "id": 44157,
        "name": "Mont-Saint-Martin",
        "latitude": "49.54363000",
        "longitude": "5.78337000"
    },
    {
        "id": 44159,
        "name": "Mont-Saxonnex",
        "latitude": "46.05080000",
        "longitude": "6.47429000"
    },
    {
        "id": 44163,
        "name": "Mont-sous-Vaudrey",
        "latitude": "46.97874000",
        "longitude": "5.60295000"
    },
    {
        "id": 44164,
        "name": "Montady",
        "latitude": "43.33590000",
        "longitude": "3.12780000"
    },
    {
        "id": 44165,
        "name": "Montagnac",
        "latitude": "43.48087000",
        "longitude": "3.48312000"
    },
    {
        "id": 44166,
        "name": "Montagnat",
        "latitude": "46.16761000",
        "longitude": "5.28791000"
    },
    {
        "id": 44167,
        "name": "Montagne",
        "latitude": "44.93333000",
        "longitude": "-0.13333000"
    },
    {
        "id": 44168,
        "name": "Montagny",
        "latitude": "45.62801000",
        "longitude": "4.74778000"
    },
    {
        "id": 44169,
        "name": "Montaigu",
        "latitude": "46.97667000",
        "longitude": "-1.30846000"
    },
    {
        "id": 44170,
        "name": "Montaigu-de-Quercy",
        "latitude": "44.33964000",
        "longitude": "1.01690000"
    },
    {
        "id": 44171,
        "name": "Montaigut",
        "latitude": "45.61499000",
        "longitude": "3.44896000"
    },
    {
        "id": 44172,
        "name": "Montaigut-sur-Save",
        "latitude": "43.68947000",
        "longitude": "1.23133000"
    },
    {
        "id": 44173,
        "name": "Montalieu",
        "latitude": "45.81245000",
        "longitude": "5.40221000"
    },
    {
        "id": 44174,
        "name": "Montamisé",
        "latitude": "46.62181000",
        "longitude": "0.42442000"
    },
    {
        "id": 44175,
        "name": "Montanay",
        "latitude": "45.87908000",
        "longitude": "4.86308000"
    },
    {
        "id": 44176,
        "name": "Montans",
        "latitude": "43.86700000",
        "longitude": "1.88568000"
    },
    {
        "id": 44177,
        "name": "Montardon",
        "latitude": "43.36667000",
        "longitude": "-0.35000000"
    },
    {
        "id": 44178,
        "name": "Montaren-et-Saint-Médiers",
        "latitude": "44.02869000",
        "longitude": "4.38030000"
    },
    {
        "id": 44179,
        "name": "Montargis",
        "latitude": "47.99696000",
        "longitude": "2.73261000"
    },
    {
        "id": 44180,
        "name": "Montarnaud",
        "latitude": "43.64743000",
        "longitude": "3.69690000"
    },
    {
        "id": 44181,
        "name": "Montastruc-la-Conseillère",
        "latitude": "43.71982000",
        "longitude": "1.59019000"
    },
    {
        "id": 44182,
        "name": "Montataire",
        "latitude": "49.25900000",
        "longitude": "2.43777000"
    },
    {
        "id": 44183,
        "name": "Montauban",
        "latitude": "44.01819000",
        "longitude": "1.36432000"
    },
    {
        "id": 44184,
        "name": "Montauban-de-Bretagne",
        "latitude": "48.19933000",
        "longitude": "-2.04476000"
    },
    {
        "id": 44185,
        "name": "Montaure",
        "latitude": "49.23417000",
        "longitude": "1.08837000"
    },
    {
        "id": 44186,
        "name": "Montauroux",
        "latitude": "43.61823000",
        "longitude": "6.76528000"
    },
    {
        "id": 44187,
        "name": "Montaut",
        "latitude": "43.13333000",
        "longitude": "-0.20000000"
    },
    {
        "id": 44188,
        "name": "Montauville",
        "latitude": "48.90111000",
        "longitude": "6.02359000"
    },
    {
        "id": 44189,
        "name": "Montayral",
        "latitude": "44.47513000",
        "longitude": "0.98769000"
    },
    {
        "id": 44190,
        "name": "Montbard",
        "latitude": "47.62294000",
        "longitude": "4.33700000"
    },
    {
        "id": 44191,
        "name": "Montbazens",
        "latitude": "44.47807000",
        "longitude": "2.22980000"
    },
    {
        "id": 44192,
        "name": "Montbazin",
        "latitude": "43.51667000",
        "longitude": "3.69667000"
    },
    {
        "id": 44193,
        "name": "Montbazon",
        "latitude": "47.28348000",
        "longitude": "0.69988000"
    },
    {
        "id": 44204,
        "name": "Montbéliard",
        "latitude": "47.50957000",
        "longitude": "6.79823000"
    },
    {
        "id": 44194,
        "name": "Montberon",
        "latitude": "43.71586000",
        "longitude": "1.47968000"
    },
    {
        "id": 44195,
        "name": "Montbert",
        "latitude": "47.05613000",
        "longitude": "-1.48890000"
    },
    {
        "id": 44196,
        "name": "Montbeton",
        "latitude": "44.01667000",
        "longitude": "1.30000000"
    },
    {
        "id": 44197,
        "name": "Montbizot",
        "latitude": "48.14995000",
        "longitude": "0.18384000"
    },
    {
        "id": 44198,
        "name": "Montblanc",
        "latitude": "43.39486000",
        "longitude": "3.36752000"
    },
    {
        "id": 44199,
        "name": "Montbonnot-Saint-Martin",
        "latitude": "45.22537000",
        "longitude": "5.80170000"
    },
    {
        "id": 44200,
        "name": "Montboucher-sur-Jabron",
        "latitude": "44.55050000",
        "longitude": "4.80200000"
    },
    {
        "id": 44201,
        "name": "Montbrison",
        "latitude": "45.60752000",
        "longitude": "4.06525000"
    },
    {
        "id": 44202,
        "name": "Montbron",
        "latitude": "45.66667000",
        "longitude": "0.50000000"
    },
    {
        "id": 44203,
        "name": "Montbronn",
        "latitude": "48.99243000",
        "longitude": "7.31075000"
    },
    {
        "id": 44205,
        "name": "Montcaret",
        "latitude": "44.85000000",
        "longitude": "0.06667000"
    },
    {
        "id": 44206,
        "name": "Montceau-les-Mines",
        "latitude": "46.66667000",
        "longitude": "4.36667000"
    },
    {
        "id": 44207,
        "name": "Montcenis",
        "latitude": "46.78785000",
        "longitude": "4.38738000"
    },
    {
        "id": 44208,
        "name": "Montchanin",
        "latitude": "46.74964000",
        "longitude": "4.47070000"
    },
    {
        "id": 44209,
        "name": "Montcornet",
        "latitude": "49.69526000",
        "longitude": "4.01667000"
    },
    {
        "id": 44210,
        "name": "Montcourt-Fromonville",
        "latitude": "48.30672000",
        "longitude": "2.70460000"
    },
    {
        "id": 44211,
        "name": "Montcresson",
        "latitude": "47.90561000",
        "longitude": "2.80796000"
    },
    {
        "id": 44212,
        "name": "Montcuq",
        "latitude": "44.33838000",
        "longitude": "1.20850000"
    },
    {
        "id": 44213,
        "name": "Montcy-Notre-Dame",
        "latitude": "49.77507000",
        "longitude": "4.74245000"
    },
    {
        "id": 44214,
        "name": "Montdidier",
        "latitude": "49.64800000",
        "longitude": "2.56991000"
    },
    {
        "id": 44215,
        "name": "Montebourg",
        "latitude": "49.48774000",
        "longitude": "-1.38036000"
    },
    {
        "id": 44216,
        "name": "Montech",
        "latitude": "43.95816000",
        "longitude": "1.23204000"
    },
    {
        "id": 44217,
        "name": "Monteils",
        "latitude": "44.16862000",
        "longitude": "1.57608000"
    },
    {
        "id": 44334,
        "name": "Montéléger",
        "latitude": "44.85296000",
        "longitude": "4.93598000"
    },
    {
        "id": 44332,
        "name": "Montélier",
        "latitude": "44.93441000",
        "longitude": "5.03126000"
    },
    {
        "id": 44333,
        "name": "Montélimar",
        "latitude": "44.55468000",
        "longitude": "4.75469000"
    },
    {
        "id": 44218,
        "name": "Montenay",
        "latitude": "48.28787000",
        "longitude": "-0.89373000"
    },
    {
        "id": 44219,
        "name": "Montendre",
        "latitude": "45.28469000",
        "longitude": "-0.40627000"
    },
    {
        "id": 44220,
        "name": "Montenois",
        "latitude": "47.49222000",
        "longitude": "6.66561000"
    },
    {
        "id": 44221,
        "name": "Monterblanc",
        "latitude": "47.74211000",
        "longitude": "-2.67950000"
    },
    {
        "id": 44222,
        "name": "Montereau-Fault-Yonne",
        "latitude": "48.38333000",
        "longitude": "2.95000000"
    },
    {
        "id": 44223,
        "name": "Monterfil",
        "latitude": "48.06733000",
        "longitude": "-1.97905000"
    },
    {
        "id": 44224,
        "name": "Montescot",
        "latitude": "42.60692000",
        "longitude": "2.93318000"
    },
    {
        "id": 44225,
        "name": "Montescourt-Lizerolles",
        "latitude": "49.73828000",
        "longitude": "3.25736000"
    },
    {
        "id": 44226,
        "name": "Montesquieu-Volvestre",
        "latitude": "43.20725000",
        "longitude": "1.22862000"
    },
    {
        "id": 44227,
        "name": "Montesquiu d'Albera",
        "latitude": "42.51798000",
        "longitude": "2.88243000"
    },
    {
        "id": 44228,
        "name": "Montesson",
        "latitude": "48.90924000",
        "longitude": "2.13754000"
    },
    {
        "id": 44229,
        "name": "Monteux",
        "latitude": "44.03618000",
        "longitude": "4.99630000"
    },
    {
        "id": 44335,
        "name": "Montévrain",
        "latitude": "48.87415000",
        "longitude": "2.75114000"
    },
    {
        "id": 44230,
        "name": "Montfaucon",
        "latitude": "47.23562000",
        "longitude": "6.08162000"
    },
    {
        "id": 44231,
        "name": "Montfaucon",
        "latitude": "44.07245000",
        "longitude": "4.75504000"
    },
    {
        "id": 44233,
        "name": "Montfaucon-en-Velay",
        "latitude": "45.18561000",
        "longitude": "4.31376000"
    },
    {
        "id": 44232,
        "name": "Montfaucon-Montigné",
        "latitude": "47.10000000",
        "longitude": "-1.11667000"
    },
    {
        "id": 44234,
        "name": "Montfavet",
        "latitude": "43.93335000",
        "longitude": "4.87342000"
    },
    {
        "id": 44235,
        "name": "Montfermeil",
        "latitude": "48.89820000",
        "longitude": "2.57913000"
    },
    {
        "id": 44236,
        "name": "Montferrand-le-Château",
        "latitude": "47.17536000",
        "longitude": "5.91534000"
    },
    {
        "id": 44237,
        "name": "Montferrat",
        "latitude": "45.48481000",
        "longitude": "5.59020000"
    },
    {
        "id": 44238,
        "name": "Montferrat",
        "latitude": "43.61196000",
        "longitude": "6.48093000"
    },
    {
        "id": 44239,
        "name": "Montferrier-sur-Lez",
        "latitude": "43.66742000",
        "longitude": "3.85439000"
    },
    {
        "id": 44240,
        "name": "Montfort-en-Chalosse",
        "latitude": "43.71124000",
        "longitude": "-0.83947000"
    },
    {
        "id": 44241,
        "name": "Montfort-le-Gesnois",
        "latitude": "48.05000000",
        "longitude": "0.41667000"
    },
    {
        "id": 44242,
        "name": "Montfort-sur-Meu",
        "latitude": "48.13542000",
        "longitude": "-1.95201000"
    },
    {
        "id": 44243,
        "name": "Montfrin",
        "latitude": "43.87596000",
        "longitude": "4.59959000"
    },
    {
        "id": 44244,
        "name": "Montgaillard",
        "latitude": "42.93333000",
        "longitude": "1.63333000"
    },
    {
        "id": 44245,
        "name": "Montgermont",
        "latitude": "48.15592000",
        "longitude": "-1.71464000"
    },
    {
        "id": 44246,
        "name": "Montgeron",
        "latitude": "48.70543000",
        "longitude": "2.45039000"
    },
    {
        "id": 44247,
        "name": "Montgiscard",
        "latitude": "43.46072000",
        "longitude": "1.56739000"
    },
    {
        "id": 44248,
        "name": "Montgivray",
        "latitude": "46.60324000",
        "longitude": "1.98162000"
    },
    {
        "id": 44249,
        "name": "Montguyon",
        "latitude": "45.21667000",
        "longitude": "-0.18333000"
    },
    {
        "id": 44250,
        "name": "Monthermé",
        "latitude": "49.88465000",
        "longitude": "4.73013000"
    },
    {
        "id": 44251,
        "name": "Monthyon",
        "latitude": "49.00753000",
        "longitude": "2.82610000"
    },
    {
        "id": 44252,
        "name": "Monticello",
        "latitude": "42.61705000",
        "longitude": "8.95477000"
    },
    {
        "id": 44253,
        "name": "Montier-en-Der",
        "latitude": "48.47823000",
        "longitude": "4.77073000"
    },
    {
        "id": 44254,
        "name": "Montierchaume",
        "latitude": "46.86362000",
        "longitude": "1.77181000"
    },
    {
        "id": 44255,
        "name": "Montignac",
        "latitude": "45.06429000",
        "longitude": "1.16196000"
    },
    {
        "id": 44264,
        "name": "Montigné-le-Brillant",
        "latitude": "48.00872000",
        "longitude": "-0.81488000"
    },
    {
        "id": 44256,
        "name": "Montigny",
        "latitude": "49.45960000",
        "longitude": "1.00168000"
    },
    {
        "id": 44258,
        "name": "Montigny-en-Gohelle",
        "latitude": "50.42540000",
        "longitude": "2.93902000"
    },
    {
        "id": 44259,
        "name": "Montigny-en-Ostrevent",
        "latitude": "50.36667000",
        "longitude": "3.18333000"
    },
    {
        "id": 44260,
        "name": "Montigny-le-Bretonneux",
        "latitude": "48.76636000",
        "longitude": "2.03405000"
    },
    {
        "id": 44257,
        "name": "Montigny-Lencoup",
        "latitude": "48.45162000",
        "longitude": "3.06503000"
    },
    {
        "id": 44261,
        "name": "Montigny-lès-Cormeilles",
        "latitude": "48.98201000",
        "longitude": "2.20035000"
    },
    {
        "id": 44262,
        "name": "Montigny-lès-Metz",
        "latitude": "49.09560000",
        "longitude": "6.15271000"
    },
    {
        "id": 44263,
        "name": "Montigny-sur-Loing",
        "latitude": "48.33575000",
        "longitude": "2.74423000"
    },
    {
        "id": 44265,
        "name": "Montivilliers",
        "latitude": "49.54518000",
        "longitude": "0.18769000"
    },
    {
        "id": 44266,
        "name": "Montjean-sur-Loire",
        "latitude": "47.38842000",
        "longitude": "-0.85873000"
    },
    {
        "id": 44267,
        "name": "Montjoie-en-Couserans",
        "latitude": "43.00250000",
        "longitude": "1.16000000"
    },
    {
        "id": 44268,
        "name": "Montjoire",
        "latitude": "43.76899000",
        "longitude": "1.53362000"
    },
    {
        "id": 44269,
        "name": "Montlaur",
        "latitude": "43.48935000",
        "longitude": "1.56807000"
    },
    {
        "id": 44270,
        "name": "Montlebon",
        "latitude": "47.03778000",
        "longitude": "6.61111000"
    },
    {
        "id": 44271,
        "name": "Montlhéry",
        "latitude": "48.64004000",
        "longitude": "2.27465000"
    },
    {
        "id": 44272,
        "name": "Montlieu-la-Garde",
        "latitude": "45.25000000",
        "longitude": "-0.25000000"
    },
    {
        "id": 44273,
        "name": "Montlignon",
        "latitude": "49.00636000",
        "longitude": "2.28705000"
    },
    {
        "id": 44274,
        "name": "Montlivault",
        "latitude": "47.63987000",
        "longitude": "1.44487000"
    },
    {
        "id": 44275,
        "name": "Montlouis-sur-Loire",
        "latitude": "47.38845000",
        "longitude": "0.83208000"
    },
    {
        "id": 44277,
        "name": "Montluçon",
        "latitude": "46.34015000",
        "longitude": "2.60254000"
    },
    {
        "id": 44276,
        "name": "Montluel",
        "latitude": "45.85190000",
        "longitude": "5.05780000"
    },
    {
        "id": 44278,
        "name": "Montmacq",
        "latitude": "49.48181000",
        "longitude": "2.90257000"
    },
    {
        "id": 44279,
        "name": "Montmagny",
        "latitude": "48.97338000",
        "longitude": "2.34688000"
    },
    {
        "id": 44280,
        "name": "Montmain",
        "latitude": "49.40996000",
        "longitude": "1.23742000"
    },
    {
        "id": 44281,
        "name": "Montmarault",
        "latitude": "46.31738000",
        "longitude": "2.95472000"
    },
    {
        "id": 44282,
        "name": "Montmartin-sur-Mer",
        "latitude": "48.98861000",
        "longitude": "-1.52530000"
    },
    {
        "id": 44290,
        "name": "Montmédy",
        "latitude": "49.52096000",
        "longitude": "5.36835000"
    },
    {
        "id": 44291,
        "name": "Montmélian",
        "latitude": "45.50509000",
        "longitude": "6.05634000"
    },
    {
        "id": 44283,
        "name": "Montmerle-sur-Saône",
        "latitude": "46.08333000",
        "longitude": "4.76667000"
    },
    {
        "id": 44284,
        "name": "Montmeyran",
        "latitude": "44.83389000",
        "longitude": "4.97181000"
    },
    {
        "id": 44285,
        "name": "Montmirail",
        "latitude": "48.87214000",
        "longitude": "3.53797000"
    },
    {
        "id": 44286,
        "name": "Montmoreau-Saint-Cybard",
        "latitude": "45.40000000",
        "longitude": "0.13333000"
    },
    {
        "id": 44287,
        "name": "Montmorency",
        "latitude": "48.98826000",
        "longitude": "2.34340000"
    },
    {
        "id": 44288,
        "name": "Montmorillon",
        "latitude": "46.42614000",
        "longitude": "0.87070000"
    },
    {
        "id": 44289,
        "name": "Montmorot",
        "latitude": "46.67541000",
        "longitude": "5.52283000"
    },
    {
        "id": 44292,
        "name": "Montoir-de-Bretagne",
        "latitude": "47.33012000",
        "longitude": "-2.15107000"
    },
    {
        "id": 44293,
        "name": "Montoire-sur-le-Loir",
        "latitude": "47.75316000",
        "longitude": "0.86525000"
    },
    {
        "id": 44294,
        "name": "Montois-la-Montagne",
        "latitude": "49.21680000",
        "longitude": "6.02137000"
    },
    {
        "id": 44295,
        "name": "Montoison",
        "latitude": "44.79608000",
        "longitude": "4.94118000"
    },
    {
        "id": 44296,
        "name": "Montolivet",
        "latitude": "43.31715000",
        "longitude": "5.42350000"
    },
    {
        "id": 44297,
        "name": "Montournais",
        "latitude": "46.74191000",
        "longitude": "-0.76464000"
    },
    {
        "id": 44298,
        "name": "Montpellier",
        "latitude": "43.61092000",
        "longitude": "3.87723000"
    },
    {
        "id": 44299,
        "name": "Montpeyroux",
        "latitude": "43.69590000",
        "longitude": "3.50542000"
    },
    {
        "id": 44300,
        "name": "Montpezat-de-Quercy",
        "latitude": "44.23876000",
        "longitude": "1.47682000"
    },
    {
        "id": 44301,
        "name": "Montpon-Ménestérol",
        "latitude": "45.00000000",
        "longitude": "0.16667000"
    },
    {
        "id": 44302,
        "name": "Montpont-en-Bresse",
        "latitude": "46.55630000",
        "longitude": "5.16459000"
    },
    {
        "id": 44303,
        "name": "Montrabé",
        "latitude": "43.64477000",
        "longitude": "1.52384000"
    },
    {
        "id": 44324,
        "name": "Montréal",
        "latitude": "43.19980000",
        "longitude": "2.14122000"
    },
    {
        "id": 44325,
        "name": "Montréal-la-Cluse",
        "latitude": "46.18333000",
        "longitude": "5.58333000"
    },
    {
        "id": 44304,
        "name": "Montredon",
        "latitude": "43.24016000",
        "longitude": "5.36629000"
    },
    {
        "id": 44305,
        "name": "Montredon-Labessonnié",
        "latitude": "43.71723000",
        "longitude": "2.32454000"
    },
    {
        "id": 44326,
        "name": "Montréjeau",
        "latitude": "43.08555000",
        "longitude": "0.56470000"
    },
    {
        "id": 44306,
        "name": "Montrem",
        "latitude": "45.13417000",
        "longitude": "0.59029000"
    },
    {
        "id": 44307,
        "name": "Montreuil",
        "latitude": "48.86415000",
        "longitude": "2.44322000"
    },
    {
        "id": 44308,
        "name": "Montreuil",
        "latitude": "50.46374000",
        "longitude": "1.76348000"
    },
    {
        "id": 44310,
        "name": "Montreuil-aux-Lions",
        "latitude": "49.02124000",
        "longitude": "3.19543000"
    },
    {
        "id": 44309,
        "name": "Montreuil-Bellay",
        "latitude": "47.13106000",
        "longitude": "-0.15209000"
    },
    {
        "id": 44311,
        "name": "Montreuil-le-Gast",
        "latitude": "48.24647000",
        "longitude": "-1.72498000"
    },
    {
        "id": 44312,
        "name": "Montreuil-sous-Pérouse",
        "latitude": "48.15176000",
        "longitude": "-1.23946000"
    },
    {
        "id": 44313,
        "name": "Montreuil-sur-Ille",
        "latitude": "48.30788000",
        "longitude": "-1.66880000"
    },
    {
        "id": 44314,
        "name": "Montreux-Château",
        "latitude": "47.61003000",
        "longitude": "6.99923000"
    },
    {
        "id": 44315,
        "name": "Montrevault",
        "latitude": "47.25965000",
        "longitude": "-1.04679000"
    },
    {
        "id": 44316,
        "name": "Montrevel-en-Bresse",
        "latitude": "46.33527000",
        "longitude": "5.12269000"
    },
    {
        "id": 44317,
        "name": "Montrichard",
        "latitude": "47.34310000",
        "longitude": "1.18653000"
    },
    {
        "id": 44318,
        "name": "Montricoux",
        "latitude": "44.07589000",
        "longitude": "1.61946000"
    },
    {
        "id": 44319,
        "name": "Montrodat",
        "latitude": "44.55038000",
        "longitude": "3.32929000"
    },
    {
        "id": 44320,
        "name": "Montrond-les-Bains",
        "latitude": "45.64374000",
        "longitude": "4.23752000"
    },
    {
        "id": 44321,
        "name": "Montrottier",
        "latitude": "45.79010000",
        "longitude": "4.46660000"
    },
    {
        "id": 44322,
        "name": "Montrouge",
        "latitude": "48.81620000",
        "longitude": "2.31393000"
    },
    {
        "id": 44323,
        "name": "Montry",
        "latitude": "48.88409000",
        "longitude": "2.82915000"
    },
    {
        "id": 44327,
        "name": "Monts",
        "latitude": "47.27723000",
        "longitude": "0.62473000"
    },
    {
        "id": 44329,
        "name": "Montségur-sur-Lauzon",
        "latitude": "44.36098000",
        "longitude": "4.85468000"
    },
    {
        "id": 44328,
        "name": "Montsoult",
        "latitude": "49.06942000",
        "longitude": "2.31966000"
    },
    {
        "id": 44330,
        "name": "Montsûrs",
        "latitude": "48.13604000",
        "longitude": "-0.55413000"
    },
    {
        "id": 44331,
        "name": "Montussan",
        "latitude": "44.88057000",
        "longitude": "-0.42181000"
    },
    {
        "id": 44337,
        "name": "Moosch",
        "latitude": "47.86035000",
        "longitude": "7.04870000"
    },
    {
        "id": 44338,
        "name": "Morainvilliers",
        "latitude": "48.92902000",
        "longitude": "1.93621000"
    },
    {
        "id": 44340,
        "name": "Morancé",
        "latitude": "45.89815000",
        "longitude": "4.70051000"
    },
    {
        "id": 44339,
        "name": "Morancez",
        "latitude": "48.40051000",
        "longitude": "1.49388000"
    },
    {
        "id": 44341,
        "name": "Morangis",
        "latitude": "48.70383000",
        "longitude": "2.33908000"
    },
    {
        "id": 44342,
        "name": "Morannes",
        "latitude": "47.74364000",
        "longitude": "-0.41604000"
    },
    {
        "id": 44343,
        "name": "Morbecque",
        "latitude": "50.69365000",
        "longitude": "2.51787000"
    },
    {
        "id": 44344,
        "name": "Morbier",
        "latitude": "46.53693000",
        "longitude": "6.01532000"
    },
    {
        "id": 44345,
        "name": "Morbihan",
        "latitude": "47.84765000",
        "longitude": "-2.77760000"
    },
    {
        "id": 44346,
        "name": "Morcenx",
        "latitude": "44.03536000",
        "longitude": "-0.91375000"
    },
    {
        "id": 44347,
        "name": "Mordelles",
        "latitude": "48.07571000",
        "longitude": "-1.84286000"
    },
    {
        "id": 44378,
        "name": "Moréac",
        "latitude": "47.91967000",
        "longitude": "-2.81934000"
    },
    {
        "id": 44379,
        "name": "Morée",
        "latitude": "47.90397000",
        "longitude": "1.23421000"
    },
    {
        "id": 44348,
        "name": "Morestel",
        "latitude": "45.67900000",
        "longitude": "5.46479000"
    },
    {
        "id": 44349,
        "name": "Moret-sur-Loing",
        "latitude": "48.37239000",
        "longitude": "2.81713000"
    },
    {
        "id": 44350,
        "name": "Moreuil",
        "latitude": "49.77457000",
        "longitude": "2.48273000"
    },
    {
        "id": 44351,
        "name": "Morez",
        "latitude": "46.52540000",
        "longitude": "6.02589000"
    },
    {
        "id": 44352,
        "name": "Morhange",
        "latitude": "48.92359000",
        "longitude": "6.64163000"
    },
    {
        "id": 44353,
        "name": "Morienval",
        "latitude": "49.29770000",
        "longitude": "2.92078000"
    },
    {
        "id": 44355,
        "name": "Morières-lès-Avignon",
        "latitude": "43.94030000",
        "longitude": "4.90110000"
    },
    {
        "id": 44354,
        "name": "Morigny-Champigny",
        "latitude": "48.44685000",
        "longitude": "2.18351000"
    },
    {
        "id": 44356,
        "name": "Morlaas",
        "latitude": "43.35000000",
        "longitude": "-0.26667000"
    },
    {
        "id": 44357,
        "name": "Morlaix",
        "latitude": "48.57742000",
        "longitude": "-3.82788000"
    },
    {
        "id": 44358,
        "name": "Mormant",
        "latitude": "48.60901000",
        "longitude": "2.89025000"
    },
    {
        "id": 44359,
        "name": "Mormoiron",
        "latitude": "44.06810000",
        "longitude": "5.18312000"
    },
    {
        "id": 44360,
        "name": "Mornac",
        "latitude": "45.68333000",
        "longitude": "0.26667000"
    },
    {
        "id": 44361,
        "name": "Mornant",
        "latitude": "45.61885000",
        "longitude": "4.67231000"
    },
    {
        "id": 44362,
        "name": "Mornas",
        "latitude": "44.20242000",
        "longitude": "4.72763000"
    },
    {
        "id": 44363,
        "name": "Morosaglia",
        "latitude": "42.43511000",
        "longitude": "9.30009000"
    },
    {
        "id": 44364,
        "name": "Morre",
        "latitude": "47.22595000",
        "longitude": "6.07512000"
    },
    {
        "id": 44365,
        "name": "Morsang-sur-Orge",
        "latitude": "48.66181000",
        "longitude": "2.35338000"
    },
    {
        "id": 44366,
        "name": "Morsbach",
        "latitude": "49.16806000",
        "longitude": "6.87190000"
    },
    {
        "id": 44367,
        "name": "Morschwiller-le-Bas",
        "latitude": "47.73615000",
        "longitude": "7.26954000"
    },
    {
        "id": 44368,
        "name": "Mortagne-au-Perche",
        "latitude": "48.52018000",
        "longitude": "0.54734000"
    },
    {
        "id": 44369,
        "name": "Mortagne-du-Nord",
        "latitude": "50.50352000",
        "longitude": "3.45352000"
    },
    {
        "id": 44370,
        "name": "Mortagne-sur-Gironde",
        "latitude": "45.48139000",
        "longitude": "-0.78702000"
    },
    {
        "id": 44371,
        "name": "Mortagne-sur-Sèvre",
        "latitude": "46.99203000",
        "longitude": "-0.94738000"
    },
    {
        "id": 44372,
        "name": "Mortain",
        "latitude": "48.64782000",
        "longitude": "-0.94055000"
    },
    {
        "id": 44373,
        "name": "Mortcerf",
        "latitude": "48.78879000",
        "longitude": "2.91692000"
    },
    {
        "id": 44374,
        "name": "Morteau",
        "latitude": "47.05784000",
        "longitude": "6.60716000"
    },
    {
        "id": 44375,
        "name": "Mortrée",
        "latitude": "48.63899000",
        "longitude": "0.07909000"
    },
    {
        "id": 44376,
        "name": "Morvillars",
        "latitude": "47.54854000",
        "longitude": "6.93450000"
    },
    {
        "id": 44377,
        "name": "Morzine",
        "latitude": "46.18149000",
        "longitude": "6.70664000"
    },
    {
        "id": 44380,
        "name": "Moselle",
        "latitude": "49.04177000",
        "longitude": "6.58355000"
    },
    {
        "id": 44381,
        "name": "Mouans-Sartoux",
        "latitude": "43.62101000",
        "longitude": "6.97139000"
    },
    {
        "id": 44382,
        "name": "Mouchamps",
        "latitude": "46.78131000",
        "longitude": "-1.06179000"
    },
    {
        "id": 44383,
        "name": "Mouchard",
        "latitude": "46.97259000",
        "longitude": "5.79626000"
    },
    {
        "id": 44384,
        "name": "Mouchin",
        "latitude": "50.51639000",
        "longitude": "3.29627000"
    },
    {
        "id": 44385,
        "name": "Mouen",
        "latitude": "49.14696000",
        "longitude": "-0.48366000"
    },
    {
        "id": 44386,
        "name": "Mougins",
        "latitude": "43.60068000",
        "longitude": "6.99523000"
    },
    {
        "id": 44387,
        "name": "Mougon",
        "latitude": "46.29561000",
        "longitude": "-0.28659000"
    },
    {
        "id": 44388,
        "name": "Mouguerre",
        "latitude": "43.46795000",
        "longitude": "-1.41824000"
    },
    {
        "id": 44389,
        "name": "Mouilleron-en-Pareds",
        "latitude": "46.67630000",
        "longitude": "-0.84940000"
    },
    {
        "id": 44390,
        "name": "Mouilleron-le-Captif",
        "latitude": "46.71898000",
        "longitude": "-1.45463000"
    },
    {
        "id": 44391,
        "name": "Moulay",
        "latitude": "48.27245000",
        "longitude": "-0.62734000"
    },
    {
        "id": 44392,
        "name": "Mouleydier",
        "latitude": "44.85572000",
        "longitude": "0.59759000"
    },
    {
        "id": 44393,
        "name": "Mouliets-et-Villemartin",
        "latitude": "44.83333000",
        "longitude": "-0.01667000"
    },
    {
        "id": 44394,
        "name": "Moulins",
        "latitude": "46.56459000",
        "longitude": "3.33243000"
    },
    {
        "id": 44395,
        "name": "Moulins-Engilbert",
        "latitude": "46.98821000",
        "longitude": "3.81084000"
    },
    {
        "id": 44396,
        "name": "Moulins-lès-Metz",
        "latitude": "49.10434000",
        "longitude": "6.10832000"
    },
    {
        "id": 44397,
        "name": "Moulis-en-Médoc",
        "latitude": "45.05938000",
        "longitude": "-0.77033000"
    },
    {
        "id": 44398,
        "name": "Moult",
        "latitude": "49.11494000",
        "longitude": "-0.16472000"
    },
    {
        "id": 44399,
        "name": "Mourenx",
        "latitude": "43.38333000",
        "longitude": "-0.60000000"
    },
    {
        "id": 44400,
        "name": "Mouret",
        "latitude": "43.36126000",
        "longitude": "5.43006000"
    },
    {
        "id": 44401,
        "name": "Mouriès",
        "latitude": "43.68997000",
        "longitude": "4.87089000"
    },
    {
        "id": 44402,
        "name": "Mourmelon-le-Grand",
        "latitude": "49.13256000",
        "longitude": "4.36420000"
    },
    {
        "id": 44403,
        "name": "Mouroux",
        "latitude": "48.82263000",
        "longitude": "3.03879000"
    },
    {
        "id": 44404,
        "name": "Mours",
        "latitude": "49.13077000",
        "longitude": "2.26761000"
    },
    {
        "id": 44405,
        "name": "Mours-Saint-Eusèbe",
        "latitude": "45.06513000",
        "longitude": "5.05776000"
    },
    {
        "id": 44406,
        "name": "Moussac",
        "latitude": "43.98119000",
        "longitude": "4.22647000"
    },
    {
        "id": 44407,
        "name": "Moussan",
        "latitude": "43.23091000",
        "longitude": "2.95000000"
    },
    {
        "id": 44408,
        "name": "Moussy-le-Neuf",
        "latitude": "49.06427000",
        "longitude": "2.60252000"
    },
    {
        "id": 44409,
        "name": "Moussy-le-Vieux",
        "latitude": "49.04712000",
        "longitude": "2.62493000"
    },
    {
        "id": 44410,
        "name": "Moustoir-Ac",
        "latitude": "47.85461000",
        "longitude": "-2.83481000"
    },
    {
        "id": 44411,
        "name": "Mouthiers-sur-Boëme",
        "latitude": "45.55000000",
        "longitude": "0.11667000"
    },
    {
        "id": 44412,
        "name": "Moutiers",
        "latitude": "49.23302000",
        "longitude": "5.96553000"
    },
    {
        "id": 44428,
        "name": "Moûtiers",
        "latitude": "45.48459000",
        "longitude": "6.53146000"
    },
    {
        "id": 44413,
        "name": "Moutiers-les-Mauxfaits",
        "latitude": "46.48333000",
        "longitude": "-1.41667000"
    },
    {
        "id": 44414,
        "name": "Mouvaux",
        "latitude": "50.69944000",
        "longitude": "3.13429000"
    },
    {
        "id": 44415,
        "name": "Mouxy",
        "latitude": "45.68399000",
        "longitude": "5.93538000"
    },
    {
        "id": 44416,
        "name": "Mouy",
        "latitude": "49.31535000",
        "longitude": "2.31954000"
    },
    {
        "id": 44417,
        "name": "Mouzeil",
        "latitude": "47.44738000",
        "longitude": "-1.34786000"
    },
    {
        "id": 44418,
        "name": "Mouzillon",
        "latitude": "47.14096000",
        "longitude": "-1.28191000"
    },
    {
        "id": 44419,
        "name": "Mouzon",
        "latitude": "49.60706000",
        "longitude": "5.07569000"
    },
    {
        "id": 44420,
        "name": "Moyaux",
        "latitude": "49.19511000",
        "longitude": "0.35603000"
    },
    {
        "id": 44421,
        "name": "Moye",
        "latitude": "45.87566000",
        "longitude": "5.91289000"
    },
    {
        "id": 44422,
        "name": "Moyenmoutier",
        "latitude": "48.37730000",
        "longitude": "6.90047000"
    },
    {
        "id": 44423,
        "name": "Moyeuvre-Grande",
        "latitude": "49.25294000",
        "longitude": "6.04467000"
    },
    {
        "id": 44424,
        "name": "Moyrazès",
        "latitude": "44.34204000",
        "longitude": "2.43933000"
    },
    {
        "id": 44425,
        "name": "Mozac",
        "latitude": "45.89393000",
        "longitude": "3.09512000"
    },
    {
        "id": 44426,
        "name": "Mozé-sur-Louet",
        "latitude": "47.35856000",
        "longitude": "-0.55295000"
    },
    {
        "id": 44429,
        "name": "Mudaison",
        "latitude": "43.63333000",
        "longitude": "4.03333000"
    },
    {
        "id": 44430,
        "name": "Mugron",
        "latitude": "43.74952000",
        "longitude": "-0.75179000"
    },
    {
        "id": 44431,
        "name": "Muides-sur-Loire",
        "latitude": "47.66958000",
        "longitude": "1.52694000"
    },
    {
        "id": 44432,
        "name": "Muizon",
        "latitude": "49.27499000",
        "longitude": "3.89083000"
    },
    {
        "id": 44433,
        "name": "Mulhouse",
        "latitude": "47.75205000",
        "longitude": "7.32866000"
    },
    {
        "id": 44434,
        "name": "Mulsanne",
        "latitude": "47.91172000",
        "longitude": "0.24938000"
    },
    {
        "id": 44435,
        "name": "Munchhouse",
        "latitude": "47.86930000",
        "longitude": "7.45233000"
    },
    {
        "id": 44436,
        "name": "Mundolsheim",
        "latitude": "48.64215000",
        "longitude": "7.71378000"
    },
    {
        "id": 44437,
        "name": "Munster",
        "latitude": "48.04048000",
        "longitude": "7.13933000"
    },
    {
        "id": 44493,
        "name": "Mûr-de-Bretagne",
        "latitude": "48.20000000",
        "longitude": "-2.98333000"
    },
    {
        "id": 44438,
        "name": "Mur-de-Sologne",
        "latitude": "47.41239000",
        "longitude": "1.60832000"
    },
    {
        "id": 44439,
        "name": "Murat",
        "latitude": "45.11011000",
        "longitude": "2.86859000"
    },
    {
        "id": 44440,
        "name": "Muret",
        "latitude": "43.46027000",
        "longitude": "1.32571000"
    },
    {
        "id": 44441,
        "name": "Muron",
        "latitude": "46.03444000",
        "longitude": "-0.82867000"
    },
    {
        "id": 44494,
        "name": "Mûrs-Erigné",
        "latitude": "47.40000000",
        "longitude": "-0.55000000"
    },
    {
        "id": 44442,
        "name": "Murviel-lès-Béziers",
        "latitude": "43.43333000",
        "longitude": "3.13333000"
    },
    {
        "id": 44443,
        "name": "Murviel-lès-Montpellier",
        "latitude": "43.60528000",
        "longitude": "3.73750000"
    },
    {
        "id": 44444,
        "name": "Mus",
        "latitude": "43.73919000",
        "longitude": "4.20257000"
    },
    {
        "id": 44445,
        "name": "Mussidan",
        "latitude": "45.03542000",
        "longitude": "0.36290000"
    },
    {
        "id": 44446,
        "name": "Mussig",
        "latitude": "48.22993000",
        "longitude": "7.51963000"
    },
    {
        "id": 44447,
        "name": "Mussy-sur-Seine",
        "latitude": "47.97791000",
        "longitude": "4.49743000"
    },
    {
        "id": 44448,
        "name": "Muttersholtz",
        "latitude": "48.26761000",
        "longitude": "7.53567000"
    },
    {
        "id": 44449,
        "name": "Mutzig",
        "latitude": "48.53974000",
        "longitude": "7.45594000"
    },
    {
        "id": 44450,
        "name": "Muzillac",
        "latitude": "47.55451000",
        "longitude": "-2.48041000"
    },
    {
        "id": 44495,
        "name": "Nages-et-Solorgues",
        "latitude": "43.79010000",
        "longitude": "4.23027000"
    },
    {
        "id": 44496,
        "name": "Nailloux",
        "latitude": "43.35718000",
        "longitude": "1.62302000"
    },
    {
        "id": 44497,
        "name": "Naintré",
        "latitude": "46.76354000",
        "longitude": "0.48683000"
    },
    {
        "id": 44498,
        "name": "Naizin",
        "latitude": "47.98993000",
        "longitude": "-2.83287000"
    },
    {
        "id": 44499,
        "name": "Nalliers",
        "latitude": "46.47071000",
        "longitude": "-1.02774000"
    },
    {
        "id": 44500,
        "name": "Nancray",
        "latitude": "47.24536000",
        "longitude": "6.18283000"
    },
    {
        "id": 44501,
        "name": "Nancy",
        "latitude": "48.68439000",
        "longitude": "6.18496000"
    },
    {
        "id": 44502,
        "name": "Nandy",
        "latitude": "48.58301000",
        "longitude": "2.56292000"
    },
    {
        "id": 44503,
        "name": "Nangis",
        "latitude": "48.55535000",
        "longitude": "3.01306000"
    },
    {
        "id": 44504,
        "name": "Nans-les-Pins",
        "latitude": "43.37066000",
        "longitude": "5.78189000"
    },
    {
        "id": 44505,
        "name": "Nanterre",
        "latitude": "48.89198000",
        "longitude": "2.20675000"
    },
    {
        "id": 44506,
        "name": "Nantes",
        "latitude": "47.21725000",
        "longitude": "-1.55336000"
    },
    {
        "id": 44507,
        "name": "Nanteuil",
        "latitude": "46.41172000",
        "longitude": "-0.17461000"
    },
    {
        "id": 44508,
        "name": "Nanteuil-en-Vallée",
        "latitude": "46.00089000",
        "longitude": "0.32206000"
    },
    {
        "id": 44509,
        "name": "Nanteuil-le-Haudouin",
        "latitude": "49.14082000",
        "longitude": "2.81142000"
    },
    {
        "id": 44510,
        "name": "Nanteuil-lès-Meaux",
        "latitude": "48.92940000",
        "longitude": "2.89594000"
    },
    {
        "id": 44511,
        "name": "Nantiat",
        "latitude": "46.00910000",
        "longitude": "1.17308000"
    },
    {
        "id": 44512,
        "name": "Nantua",
        "latitude": "46.15343000",
        "longitude": "5.60601000"
    },
    {
        "id": 44513,
        "name": "Naours",
        "latitude": "50.03682000",
        "longitude": "2.27691000"
    },
    {
        "id": 44514,
        "name": "Narbonne",
        "latitude": "43.18396000",
        "longitude": "3.00141000"
    },
    {
        "id": 44515,
        "name": "Nargis",
        "latitude": "48.11106000",
        "longitude": "2.75597000"
    },
    {
        "id": 44516,
        "name": "Narrosse",
        "latitude": "43.70381000",
        "longitude": "-1.00742000"
    },
    {
        "id": 44517,
        "name": "Nassandres",
        "latitude": "49.12632000",
        "longitude": "0.73597000"
    },
    {
        "id": 44518,
        "name": "Naucelle",
        "latitude": "44.19810000",
        "longitude": "2.34310000"
    },
    {
        "id": 44519,
        "name": "Naucelles",
        "latitude": "44.95898000",
        "longitude": "2.41884000"
    },
    {
        "id": 44520,
        "name": "Navailles-Angos",
        "latitude": "43.41667000",
        "longitude": "-0.33333000"
    },
    {
        "id": 44521,
        "name": "Navarrenx",
        "latitude": "43.32135000",
        "longitude": "-0.75927000"
    },
    {
        "id": 44522,
        "name": "Naveil",
        "latitude": "47.79576000",
        "longitude": "1.03222000"
    },
    {
        "id": 44523,
        "name": "Navenne",
        "latitude": "47.60873000",
        "longitude": "6.16176000"
    },
    {
        "id": 44524,
        "name": "Naves",
        "latitude": "45.31395000",
        "longitude": "1.76708000"
    },
    {
        "id": 44525,
        "name": "Nay",
        "latitude": "43.18333000",
        "longitude": "-0.26667000"
    },
    {
        "id": 44526,
        "name": "Nazelles-Négron",
        "latitude": "47.43333000",
        "longitude": "0.95000000"
    },
    {
        "id": 44527,
        "name": "Neaufles-Saint-Martin",
        "latitude": "49.27650000",
        "longitude": "1.72794000"
    },
    {
        "id": 44528,
        "name": "Neauphle-le-Château",
        "latitude": "48.81418000",
        "longitude": "1.90567000"
    },
    {
        "id": 44692,
        "name": "Nébian",
        "latitude": "43.60657000",
        "longitude": "3.43133000"
    },
    {
        "id": 44691,
        "name": "Nègrepelisse",
        "latitude": "44.07436000",
        "longitude": "1.51978000"
    },
    {
        "id": 44529,
        "name": "Nemours",
        "latitude": "48.27511000",
        "longitude": "2.69078000"
    },
    {
        "id": 44693,
        "name": "Néoules",
        "latitude": "43.30844000",
        "longitude": "6.00798000"
    },
    {
        "id": 44694,
        "name": "Nérac",
        "latitude": "44.13613000",
        "longitude": "0.33934000"
    },
    {
        "id": 44530,
        "name": "Nercillac",
        "latitude": "45.71667000",
        "longitude": "-0.25000000"
    },
    {
        "id": 44695,
        "name": "Néris-les-Bains",
        "latitude": "46.28688000",
        "longitude": "2.65869000"
    },
    {
        "id": 44696,
        "name": "Nérondes",
        "latitude": "46.99758000",
        "longitude": "2.81834000"
    },
    {
        "id": 44531,
        "name": "Nersac",
        "latitude": "45.63333000",
        "longitude": "0.05000000"
    },
    {
        "id": 44532,
        "name": "Nesle",
        "latitude": "49.75888000",
        "longitude": "2.91133000"
    },
    {
        "id": 44533,
        "name": "Nesles",
        "latitude": "50.62588000",
        "longitude": "1.65641000"
    },
    {
        "id": 44534,
        "name": "Nesles-la-Montagne",
        "latitude": "49.01964000",
        "longitude": "3.42607000"
    },
    {
        "id": 44535,
        "name": "Nesles-la-Vallée",
        "latitude": "49.13202000",
        "longitude": "2.17099000"
    },
    {
        "id": 44536,
        "name": "Nesmy",
        "latitude": "46.59078000",
        "longitude": "-1.40074000"
    },
    {
        "id": 44537,
        "name": "Neuf-Berquin",
        "latitude": "50.66065000",
        "longitude": "2.67213000"
    },
    {
        "id": 44538,
        "name": "Neuf-Brisach",
        "latitude": "48.01783000",
        "longitude": "7.52795000"
    },
    {
        "id": 44540,
        "name": "Neufchâteau",
        "latitude": "48.35557000",
        "longitude": "5.69602000"
    },
    {
        "id": 44542,
        "name": "Neufchâtel-en-Bray",
        "latitude": "49.73315000",
        "longitude": "1.43956000"
    },
    {
        "id": 44541,
        "name": "Neufchâtel-Hardelot",
        "latitude": "50.62018000",
        "longitude": "1.64223000"
    },
    {
        "id": 44539,
        "name": "Neufchef",
        "latitude": "49.31678000",
        "longitude": "6.02416000"
    },
    {
        "id": 44543,
        "name": "Neufgrange",
        "latitude": "49.07679000",
        "longitude": "7.06526000"
    },
    {
        "id": 44544,
        "name": "Neufmanil",
        "latitude": "49.81096000",
        "longitude": "4.79673000"
    },
    {
        "id": 44545,
        "name": "Neufmoutiers-en-Brie",
        "latitude": "48.76882000",
        "longitude": "2.83156000"
    },
    {
        "id": 44554,
        "name": "Neuillé-Pont-Pierre",
        "latitude": "47.54840000",
        "longitude": "0.54962000"
    },
    {
        "id": 44548,
        "name": "Neuilly-en-Thelle",
        "latitude": "49.22445000",
        "longitude": "2.28525000"
    },
    {
        "id": 44549,
        "name": "Neuilly-le-Réal",
        "latitude": "46.46413000",
        "longitude": "3.43197000"
    },
    {
        "id": 44550,
        "name": "Neuilly-lès-Dijon",
        "latitude": "47.27943000",
        "longitude": "5.10645000"
    },
    {
        "id": 44546,
        "name": "Neuilly-Plaisance",
        "latitude": "48.86342000",
        "longitude": "2.50600000"
    },
    {
        "id": 44547,
        "name": "Neuilly-Saint-Front",
        "latitude": "49.17012000",
        "longitude": "3.26393000"
    },
    {
        "id": 44551,
        "name": "Neuilly-sous-Clermont",
        "latitude": "49.34437000",
        "longitude": "2.41030000"
    },
    {
        "id": 44552,
        "name": "Neuilly-sur-Marne",
        "latitude": "48.85373000",
        "longitude": "2.54903000"
    },
    {
        "id": 44553,
        "name": "Neuilly-sur-Seine",
        "latitude": "48.88460000",
        "longitude": "2.26965000"
    },
    {
        "id": 44555,
        "name": "Neulise",
        "latitude": "45.89811000",
        "longitude": "4.18134000"
    },
    {
        "id": 44556,
        "name": "Neulliac",
        "latitude": "48.12836000",
        "longitude": "-2.98289000"
    },
    {
        "id": 44557,
        "name": "Neung-sur-Beuvron",
        "latitude": "47.53486000",
        "longitude": "1.80514000"
    },
    {
        "id": 44558,
        "name": "Neussargues",
        "latitude": "45.12826000",
        "longitude": "2.97627000"
    },
    {
        "id": 44559,
        "name": "Neuvecelle",
        "latitude": "46.39502000",
        "longitude": "6.61257000"
    },
    {
        "id": 44581,
        "name": "Neuvéglise",
        "latitude": "44.92809000",
        "longitude": "2.98328000"
    },
    {
        "id": 44560,
        "name": "Neuves-Maisons",
        "latitude": "48.61811000",
        "longitude": "6.10544000"
    },
    {
        "id": 44561,
        "name": "Neuvic",
        "latitude": "45.10033000",
        "longitude": "0.46901000"
    },
    {
        "id": 44562,
        "name": "Neuvic-Entier",
        "latitude": "45.72206000",
        "longitude": "1.61303000"
    },
    {
        "id": 44565,
        "name": "Neuville-aux-Bois",
        "latitude": "48.06813000",
        "longitude": "2.05372000"
    },
    {
        "id": 44566,
        "name": "Neuville-de-Poitou",
        "latitude": "46.68333000",
        "longitude": "0.25000000"
    },
    {
        "id": 44567,
        "name": "Neuville-en-Ferrain",
        "latitude": "50.74839000",
        "longitude": "3.15676000"
    },
    {
        "id": 44568,
        "name": "Neuville-les-Dames",
        "latitude": "46.16230000",
        "longitude": "5.00667000"
    },
    {
        "id": 44563,
        "name": "Neuville-Saint-Rémy",
        "latitude": "50.18618000",
        "longitude": "3.22404000"
    },
    {
        "id": 44564,
        "name": "Neuville-Saint-Vaast",
        "latitude": "50.35756000",
        "longitude": "2.76261000"
    },
    {
        "id": 44569,
        "name": "Neuville-sur-Ain",
        "latitude": "46.07144000",
        "longitude": "5.37491000"
    },
    {
        "id": 44570,
        "name": "Neuville-sur-Escaut",
        "latitude": "50.30000000",
        "longitude": "3.35000000"
    },
    {
        "id": 44571,
        "name": "Neuville-sur-Oise",
        "latitude": "49.01667000",
        "longitude": "2.06667000"
    },
    {
        "id": 44573,
        "name": "Neuville-sur-Saône",
        "latitude": "45.87615000",
        "longitude": "4.84099000"
    },
    {
        "id": 44572,
        "name": "Neuville-sur-Sarthe",
        "latitude": "48.07615000",
        "longitude": "0.19264000"
    },
    {
        "id": 44574,
        "name": "Neuvy",
        "latitude": "46.56191000",
        "longitude": "3.29038000"
    },
    {
        "id": 44578,
        "name": "Neuvy-le-Roi",
        "latitude": "47.60386000",
        "longitude": "0.59472000"
    },
    {
        "id": 44575,
        "name": "Neuvy-Pailloux",
        "latitude": "46.88486000",
        "longitude": "1.86152000"
    },
    {
        "id": 44576,
        "name": "Neuvy-Saint-Sépulchre",
        "latitude": "46.59781000",
        "longitude": "1.80601000"
    },
    {
        "id": 44577,
        "name": "Neuvy-Sautour",
        "latitude": "48.04190000",
        "longitude": "3.79472000"
    },
    {
        "id": 44579,
        "name": "Neuvy-sur-Barangeon",
        "latitude": "47.31495000",
        "longitude": "2.25343000"
    },
    {
        "id": 44580,
        "name": "Neuvy-sur-Loire",
        "latitude": "47.52323000",
        "longitude": "2.88333000"
    },
    {
        "id": 44582,
        "name": "Neuwiller-lès-Saverne",
        "latitude": "48.82506000",
        "longitude": "7.40513000"
    },
    {
        "id": 44583,
        "name": "Nevers",
        "latitude": "46.98956000",
        "longitude": "3.15900000"
    },
    {
        "id": 44697,
        "name": "Névez",
        "latitude": "47.81924000",
        "longitude": "-3.79225000"
    },
    {
        "id": 44698,
        "name": "Névian",
        "latitude": "43.21238000",
        "longitude": "2.90286000"
    },
    {
        "id": 44699,
        "name": "Néville",
        "latitude": "49.82472000",
        "longitude": "0.70862000"
    },
    {
        "id": 44584,
        "name": "Nevoy",
        "latitude": "47.71591000",
        "longitude": "2.57791000"
    },
    {
        "id": 44585,
        "name": "Nexon",
        "latitude": "45.67962000",
        "longitude": "1.18555000"
    },
    {
        "id": 44586,
        "name": "Neydens",
        "latitude": "46.12162000",
        "longitude": "6.10436000"
    },
    {
        "id": 44587,
        "name": "Nice",
        "latitude": "43.70313000",
        "longitude": "7.26608000"
    },
    {
        "id": 44588,
        "name": "Niderviller",
        "latitude": "48.71294000",
        "longitude": "7.10627000"
    },
    {
        "id": 44589,
        "name": "Niederbronn-les-Bains",
        "latitude": "48.95165000",
        "longitude": "7.64271000"
    },
    {
        "id": 44590,
        "name": "Niederhaslach",
        "latitude": "48.54264000",
        "longitude": "7.34282000"
    },
    {
        "id": 44591,
        "name": "Niederhausbergen",
        "latitude": "48.62400000",
        "longitude": "7.70210000"
    },
    {
        "id": 44592,
        "name": "Niedernai",
        "latitude": "48.45101000",
        "longitude": "7.51635000"
    },
    {
        "id": 44593,
        "name": "Niederschaeffolsheim",
        "latitude": "48.77241000",
        "longitude": "7.73862000"
    },
    {
        "id": 44594,
        "name": "Nieppe",
        "latitude": "50.70425000",
        "longitude": "2.83506000"
    },
    {
        "id": 44595,
        "name": "Nieul",
        "latitude": "45.92668000",
        "longitude": "1.17494000"
    },
    {
        "id": 44596,
        "name": "Nieul-le-Dolent",
        "latitude": "46.57379000",
        "longitude": "-1.50808000"
    },
    {
        "id": 44597,
        "name": "Nieul-lès-Saintes",
        "latitude": "45.76021000",
        "longitude": "-0.73137000"
    },
    {
        "id": 44598,
        "name": "Nieul-sur-Mer",
        "latitude": "46.20583000",
        "longitude": "-1.16449000"
    },
    {
        "id": 44605,
        "name": "Niévroz",
        "latitude": "45.82576000",
        "longitude": "5.06108000"
    },
    {
        "id": 44599,
        "name": "Niherne",
        "latitude": "46.82768000",
        "longitude": "1.56384000"
    },
    {
        "id": 44600,
        "name": "Nilvange",
        "latitude": "49.34224000",
        "longitude": "6.04964000"
    },
    {
        "id": 44700,
        "name": "Nîmes",
        "latitude": "43.83378000",
        "longitude": "4.35962000"
    },
    {
        "id": 44601,
        "name": "Niort",
        "latitude": "46.32313000",
        "longitude": "-0.45877000"
    },
    {
        "id": 44602,
        "name": "Nissan-lez-Enserune",
        "latitude": "43.28961000",
        "longitude": "3.12705000"
    },
    {
        "id": 44603,
        "name": "Nivillac",
        "latitude": "47.53371000",
        "longitude": "-2.28298000"
    },
    {
        "id": 44604,
        "name": "Nivolas-Vermelle",
        "latitude": "45.55885000",
        "longitude": "5.30578000"
    },
    {
        "id": 44606,
        "name": "Noaillan",
        "latitude": "44.48057000",
        "longitude": "-0.36640000"
    },
    {
        "id": 44607,
        "name": "Noailles",
        "latitude": "49.32866000",
        "longitude": "2.20024000"
    },
    {
        "id": 44608,
        "name": "Noailles",
        "latitude": "43.29604000",
        "longitude": "5.37959000"
    },
    {
        "id": 44683,
        "name": "Noé",
        "latitude": "43.35814000",
        "longitude": "1.27709000"
    },
    {
        "id": 44701,
        "name": "Nœux-les-Mines",
        "latitude": "50.48333000",
        "longitude": "2.66667000"
    },
    {
        "id": 44609,
        "name": "Nogaro",
        "latitude": "43.75856000",
        "longitude": "-0.03293000"
    },
    {
        "id": 44614,
        "name": "Nogent-l’Artaud",
        "latitude": "48.96560000",
        "longitude": "3.32178000"
    },
    {
        "id": 44610,
        "name": "Nogent-le-Bas",
        "latitude": "48.01974000",
        "longitude": "5.33323000"
    },
    {
        "id": 44611,
        "name": "Nogent-le-Phaye",
        "latitude": "48.44528000",
        "longitude": "1.57777000"
    },
    {
        "id": 44612,
        "name": "Nogent-le-Roi",
        "latitude": "48.64785000",
        "longitude": "1.52933000"
    },
    {
        "id": 44613,
        "name": "Nogent-le-Rotrou",
        "latitude": "48.32157000",
        "longitude": "0.82177000"
    },
    {
        "id": 44615,
        "name": "Nogent-sur-Marne",
        "latitude": "48.83669000",
        "longitude": "2.48255000"
    },
    {
        "id": 44616,
        "name": "Nogent-sur-Oise",
        "latitude": "49.27158000",
        "longitude": "2.47074000"
    },
    {
        "id": 44617,
        "name": "Nogent-sur-Seine",
        "latitude": "48.49372000",
        "longitude": "3.50262000"
    },
    {
        "id": 44618,
        "name": "Nogent-sur-Vernisson",
        "latitude": "47.84603000",
        "longitude": "2.74267000"
    },
    {
        "id": 44619,
        "name": "Nogentel",
        "latitude": "49.01750000",
        "longitude": "3.40250000"
    },
    {
        "id": 44620,
        "name": "Nohanent",
        "latitude": "45.80815000",
        "longitude": "3.05507000"
    },
    {
        "id": 44621,
        "name": "Nohic",
        "latitude": "43.88970000",
        "longitude": "1.43741000"
    },
    {
        "id": 44622,
        "name": "Noidans-lès-Vesoul",
        "latitude": "47.61299000",
        "longitude": "6.12571000"
    },
    {
        "id": 44623,
        "name": "Nointot",
        "latitude": "49.59822000",
        "longitude": "0.47674000"
    },
    {
        "id": 44625,
        "name": "Noirétable",
        "latitude": "45.81671000",
        "longitude": "3.76247000"
    },
    {
        "id": 44624,
        "name": "Noirmoutier-en-l’Île",
        "latitude": "46.99733000",
        "longitude": "-2.27374000"
    },
    {
        "id": 44626,
        "name": "Noiseau",
        "latitude": "48.77589000",
        "longitude": "2.54892000"
    },
    {
        "id": 44627,
        "name": "Noisiel",
        "latitude": "48.84868000",
        "longitude": "2.62435000"
    },
    {
        "id": 44628,
        "name": "Noisy-le-Grand",
        "latitude": "48.84979000",
        "longitude": "2.56266000"
    },
    {
        "id": 44629,
        "name": "Noisy-le-Roi",
        "latitude": "48.84445000",
        "longitude": "2.06345000"
    },
    {
        "id": 44630,
        "name": "Noisy-le-Sec",
        "latitude": "48.89148000",
        "longitude": "2.46451000"
    },
    {
        "id": 44631,
        "name": "Noisy-sur-École",
        "latitude": "48.36701000",
        "longitude": "2.50804000"
    },
    {
        "id": 44632,
        "name": "Noizay",
        "latitude": "47.42188000",
        "longitude": "0.89201000"
    },
    {
        "id": 44633,
        "name": "Nolay",
        "latitude": "46.95202000",
        "longitude": "4.63405000"
    },
    {
        "id": 44634,
        "name": "Nomain",
        "latitude": "50.49857000",
        "longitude": "3.24991000"
    },
    {
        "id": 44635,
        "name": "Nomeny",
        "latitude": "48.88977000",
        "longitude": "6.22635000"
    },
    {
        "id": 44636,
        "name": "Nomexy",
        "latitude": "48.30741000",
        "longitude": "6.38572000"
    },
    {
        "id": 44637,
        "name": "Nommay",
        "latitude": "47.53780000",
        "longitude": "6.84219000"
    },
    {
        "id": 44638,
        "name": "Nonancourt",
        "latitude": "48.77086000",
        "longitude": "1.19799000"
    },
    {
        "id": 44639,
        "name": "Nontron",
        "latitude": "45.52950000",
        "longitude": "0.66179000"
    },
    {
        "id": 44640,
        "name": "Nord",
        "latitude": "50.33333000",
        "longitude": "3.36197000"
    },
    {
        "id": 44641,
        "name": "Nordhouse",
        "latitude": "48.44833000",
        "longitude": "7.67326000"
    },
    {
        "id": 44642,
        "name": "Normanville",
        "latitude": "49.07868000",
        "longitude": "1.15915000"
    },
    {
        "id": 44643,
        "name": "Norrent-Fontes",
        "latitude": "50.58333000",
        "longitude": "2.40000000"
    },
    {
        "id": 44644,
        "name": "Norroy-lès-Pont-à-Mousson",
        "latitude": "48.93495000",
        "longitude": "6.03078000"
    },
    {
        "id": 44645,
        "name": "Nort-sur-Erdre",
        "latitude": "47.43881000",
        "longitude": "-1.49833000"
    },
    {
        "id": 44646,
        "name": "Nortkerque",
        "latitude": "50.87519000",
        "longitude": "2.02464000"
    },
    {
        "id": 44647,
        "name": "Nostang",
        "latitude": "47.74870000",
        "longitude": "-3.18639000"
    },
    {
        "id": 44649,
        "name": "Notre-Dame du Mont",
        "latitude": "43.29293000",
        "longitude": "5.38203000"
    },
    {
        "id": 44648,
        "name": "Notre-Dame Limite",
        "latitude": "43.38165000",
        "longitude": "5.36269000"
    },
    {
        "id": 44650,
        "name": "Notre-Dame-de-Bondeville",
        "latitude": "49.48333000",
        "longitude": "1.05000000"
    },
    {
        "id": 44651,
        "name": "Notre-Dame-de-Gravenchon",
        "latitude": "49.48940000",
        "longitude": "0.57188000"
    },
    {
        "id": 44653,
        "name": "Notre-Dame-de-Mésage",
        "latitude": "45.06241000",
        "longitude": "5.75880000"
    },
    {
        "id": 44652,
        "name": "Notre-Dame-de-Monts",
        "latitude": "46.83100000",
        "longitude": "-2.13100000"
    },
    {
        "id": 44654,
        "name": "Notre-Dame-de-Riez",
        "latitude": "46.74445000",
        "longitude": "-1.90857000"
    },
    {
        "id": 44655,
        "name": "Notre-Dame-de-Sanilhac",
        "latitude": "45.12121000",
        "longitude": "0.71157000"
    },
    {
        "id": 44656,
        "name": "Notre-Dame-des-Landes",
        "latitude": "47.38048000",
        "longitude": "-1.70904000"
    },
    {
        "id": 44657,
        "name": "Nouaillé-Maupertuis",
        "latitude": "46.51113000",
        "longitude": "0.41667000"
    },
    {
        "id": 44658,
        "name": "Nouan-le-Fuzelier",
        "latitude": "47.53600000",
        "longitude": "2.03647000"
    },
    {
        "id": 44659,
        "name": "Nousty",
        "latitude": "43.26667000",
        "longitude": "-0.21667000"
    },
    {
        "id": 44660,
        "name": "Nouvion",
        "latitude": "50.20000000",
        "longitude": "1.78333000"
    },
    {
        "id": 44661,
        "name": "Nouvion-sur-Meuse",
        "latitude": "49.69949000",
        "longitude": "4.79562000"
    },
    {
        "id": 44662,
        "name": "Nouvoitou",
        "latitude": "48.04105000",
        "longitude": "-1.54714000"
    },
    {
        "id": 44663,
        "name": "Nouzilly",
        "latitude": "47.54499000",
        "longitude": "0.74623000"
    },
    {
        "id": 44664,
        "name": "Nouzonville",
        "latitude": "49.81369000",
        "longitude": "4.74736000"
    },
    {
        "id": 44665,
        "name": "Novalaise",
        "latitude": "45.59480000",
        "longitude": "5.77767000"
    },
    {
        "id": 44668,
        "name": "Novéant-sur-Moselle",
        "latitude": "49.02366000",
        "longitude": "6.04795000"
    },
    {
        "id": 44666,
        "name": "Noves",
        "latitude": "43.87736000",
        "longitude": "4.90248000"
    },
    {
        "id": 44667,
        "name": "Novillars",
        "latitude": "47.28465000",
        "longitude": "6.12878000"
    },
    {
        "id": 44669,
        "name": "Noyal-Muzillac",
        "latitude": "47.59169000",
        "longitude": "-2.45557000"
    },
    {
        "id": 44670,
        "name": "Noyal-Pontivy",
        "latitude": "48.06667000",
        "longitude": "-2.88333000"
    },
    {
        "id": 44671,
        "name": "Noyal-sur-Vilaine",
        "latitude": "48.11219000",
        "longitude": "-1.52333000"
    },
    {
        "id": 44672,
        "name": "Noyant",
        "latitude": "47.51048000",
        "longitude": "0.11771000"
    },
    {
        "id": 44673,
        "name": "Noyant-la-Gravoyère",
        "latitude": "47.70348000",
        "longitude": "-0.95730000"
    },
    {
        "id": 44674,
        "name": "Noyarey",
        "latitude": "45.24421000",
        "longitude": "5.63366000"
    },
    {
        "id": 44675,
        "name": "Noyelles-Godault",
        "latitude": "50.41753000",
        "longitude": "2.99306000"
    },
    {
        "id": 44676,
        "name": "Noyelles-lès-Vermelles",
        "latitude": "50.49001000",
        "longitude": "2.72628000"
    },
    {
        "id": 44677,
        "name": "Noyelles-sous-Lens",
        "latitude": "50.43084000",
        "longitude": "2.87221000"
    },
    {
        "id": 44678,
        "name": "Noyen-sur-Sarthe",
        "latitude": "47.87187000",
        "longitude": "-0.09916000"
    },
    {
        "id": 44679,
        "name": "Noyers-sur-Cher",
        "latitude": "47.27701000",
        "longitude": "1.40320000"
    },
    {
        "id": 44680,
        "name": "Noyon",
        "latitude": "49.58333000",
        "longitude": "3.00000000"
    },
    {
        "id": 44681,
        "name": "Nozay",
        "latitude": "48.65921000",
        "longitude": "2.24151000"
    },
    {
        "id": 44682,
        "name": "Nozay",
        "latitude": "47.56495000",
        "longitude": "-1.62629000"
    },
    {
        "id": 44684,
        "name": "Nuaillé",
        "latitude": "47.09524000",
        "longitude": "-0.79477000"
    },
    {
        "id": 44685,
        "name": "Nueil-les-Aubiers",
        "latitude": "46.93333000",
        "longitude": "-0.58333000"
    },
    {
        "id": 44686,
        "name": "Nueil-sur-Layon",
        "latitude": "47.11811000",
        "longitude": "-0.36573000"
    },
    {
        "id": 44687,
        "name": "Nuillé-sur-Vicoin",
        "latitude": "47.98556000",
        "longitude": "-0.78351000"
    },
    {
        "id": 44688,
        "name": "Nuits-Saint-Georges",
        "latitude": "47.13683000",
        "longitude": "4.94900000"
    },
    {
        "id": 44689,
        "name": "Nyoiseau",
        "latitude": "47.71667000",
        "longitude": "-0.91667000"
    },
    {
        "id": 44690,
        "name": "Nyons",
        "latitude": "44.36082000",
        "longitude": "5.14052000"
    },
    {
        "id": 44702,
        "name": "Obenheim",
        "latitude": "48.35975000",
        "longitude": "7.69200000"
    },
    {
        "id": 44703,
        "name": "Oberbronn",
        "latitude": "48.94020000",
        "longitude": "7.60700000"
    },
    {
        "id": 44704,
        "name": "Oberhaslach",
        "latitude": "48.55039000",
        "longitude": "7.33213000"
    },
    {
        "id": 44705,
        "name": "Oberhausbergen",
        "latitude": "48.60607000",
        "longitude": "7.68846000"
    },
    {
        "id": 44706,
        "name": "Oberhergheim",
        "latitude": "47.96668000",
        "longitude": "7.39516000"
    },
    {
        "id": 44707,
        "name": "Oberhoffen-sur-Moder",
        "latitude": "48.78366000",
        "longitude": "7.86347000"
    },
    {
        "id": 44708,
        "name": "Obermodern-Zutzendorf",
        "latitude": "48.85000000",
        "longitude": "7.53333000"
    },
    {
        "id": 44709,
        "name": "Obernai",
        "latitude": "48.46313000",
        "longitude": "7.48100000"
    },
    {
        "id": 44710,
        "name": "Oberschaeffolsheim",
        "latitude": "48.58643000",
        "longitude": "7.65018000"
    },
    {
        "id": 44711,
        "name": "Objat",
        "latitude": "45.26302000",
        "longitude": "1.40826000"
    },
    {
        "id": 44712,
        "name": "Octeville",
        "latitude": "49.62612000",
        "longitude": "-1.64349000"
    },
    {
        "id": 44713,
        "name": "Octeville-sur-Mer",
        "latitude": "49.55496000",
        "longitude": "0.11660000"
    },
    {
        "id": 44714,
        "name": "Oderen",
        "latitude": "47.91036000",
        "longitude": "6.97463000"
    },
    {
        "id": 44715,
        "name": "Odos",
        "latitude": "43.19622000",
        "longitude": "0.05693000"
    },
    {
        "id": 44716,
        "name": "Oermingen",
        "latitude": "49.00043000",
        "longitude": "7.12900000"
    },
    {
        "id": 48128,
        "name": "Œting",
        "latitude": "49.17291000",
        "longitude": "6.91472000"
    },
    {
        "id": 44717,
        "name": "Oeyreluy",
        "latitude": "43.66876000",
        "longitude": "-1.08276000"
    },
    {
        "id": 44718,
        "name": "Offemont",
        "latitude": "47.66278000",
        "longitude": "6.87764000"
    },
    {
        "id": 44719,
        "name": "Offenheim",
        "latitude": "48.63208000",
        "longitude": "7.61668000"
    },
    {
        "id": 44720,
        "name": "Offranville",
        "latitude": "49.87208000",
        "longitude": "1.04817000"
    },
    {
        "id": 44721,
        "name": "Ogeu-les-Bains",
        "latitude": "43.15000000",
        "longitude": "-0.50000000"
    },
    {
        "id": 44722,
        "name": "Ognes",
        "latitude": "49.61244000",
        "longitude": "3.19340000"
    },
    {
        "id": 44723,
        "name": "Ohlungen",
        "latitude": "48.81352000",
        "longitude": "7.70225000"
    },
    {
        "id": 44724,
        "name": "Oignies",
        "latitude": "50.46331000",
        "longitude": "2.99376000"
    },
    {
        "id": 44725,
        "name": "Oinville-sur-Montcient",
        "latitude": "49.02722000",
        "longitude": "1.84928000"
    },
    {
        "id": 44726,
        "name": "Oisemont",
        "latitude": "49.95587000",
        "longitude": "1.76703000"
    },
    {
        "id": 44727,
        "name": "Oisseau",
        "latitude": "48.35559000",
        "longitude": "-0.67148000"
    },
    {
        "id": 44728,
        "name": "Oissel",
        "latitude": "49.33309000",
        "longitude": "1.09413000"
    },
    {
        "id": 44729,
        "name": "Oissery",
        "latitude": "49.07047000",
        "longitude": "2.81819000"
    },
    {
        "id": 44730,
        "name": "Oisy-le-Verger",
        "latitude": "50.25047000",
        "longitude": "3.12330000"
    },
    {
        "id": 44731,
        "name": "Olemps",
        "latitude": "44.34638000",
        "longitude": "2.55140000"
    },
    {
        "id": 44732,
        "name": "Oletta",
        "latitude": "42.62991000",
        "longitude": "9.35431000"
    },
    {
        "id": 44733,
        "name": "Olivet",
        "latitude": "47.86219000",
        "longitude": "1.89910000"
    },
    {
        "id": 44734,
        "name": "Ollainville",
        "latitude": "48.59081000",
        "longitude": "2.21936000"
    },
    {
        "id": 44735,
        "name": "Ollioules",
        "latitude": "43.13990000",
        "longitude": "5.84766000"
    },
    {
        "id": 44736,
        "name": "Olmeto",
        "latitude": "41.71724000",
        "longitude": "8.91783000"
    },
    {
        "id": 44737,
        "name": "Olonne-sur-Mer",
        "latitude": "46.53524000",
        "longitude": "-1.77293000"
    },
    {
        "id": 44738,
        "name": "Olonzac",
        "latitude": "43.28294000",
        "longitude": "2.73098000"
    },
    {
        "id": 44739,
        "name": "Oloron-Sainte-Marie",
        "latitude": "43.19441000",
        "longitude": "-0.61069000"
    },
    {
        "id": 44740,
        "name": "Omissy",
        "latitude": "49.87760000",
        "longitude": "3.31235000"
    },
    {
        "id": 44741,
        "name": "Ondes",
        "latitude": "43.78250000",
        "longitude": "1.30823000"
    },
    {
        "id": 44742,
        "name": "Ondres",
        "latitude": "43.56461000",
        "longitude": "-1.44449000"
    },
    {
        "id": 44743,
        "name": "Onesse-Laharie",
        "latitude": "44.06146000",
        "longitude": "-1.06984000"
    },
    {
        "id": 44744,
        "name": "Onnaing",
        "latitude": "50.38584000",
        "longitude": "3.59963000"
    },
    {
        "id": 44745,
        "name": "Ons-en-Bray",
        "latitude": "49.41631000",
        "longitude": "1.92302000"
    },
    {
        "id": 44746,
        "name": "Onzain",
        "latitude": "47.49956000",
        "longitude": "1.17701000"
    },
    {
        "id": 44749,
        "name": "Opéra",
        "latitude": "43.29401000",
        "longitude": "5.37610000"
    },
    {
        "id": 44747,
        "name": "Opio",
        "latitude": "43.66862000",
        "longitude": "6.98212000"
    },
    {
        "id": 44748,
        "name": "Oppède le Vieux",
        "latitude": "43.82844000",
        "longitude": "5.16132000"
    },
    {
        "id": 44750,
        "name": "Oradour-sur-Glane",
        "latitude": "45.93405000",
        "longitude": "1.03170000"
    },
    {
        "id": 44751,
        "name": "Oradour-sur-Vayres",
        "latitude": "45.73286000",
        "longitude": "0.86457000"
    },
    {
        "id": 44752,
        "name": "Oraison",
        "latitude": "43.91726000",
        "longitude": "5.91836000"
    },
    {
        "id": 44753,
        "name": "Orange",
        "latitude": "44.13806000",
        "longitude": "4.81025000"
    },
    {
        "id": 44754,
        "name": "Orbec",
        "latitude": "49.01667000",
        "longitude": "0.41667000"
    },
    {
        "id": 44755,
        "name": "Orbey",
        "latitude": "48.12651000",
        "longitude": "7.16455000"
    },
    {
        "id": 44756,
        "name": "Orcet",
        "latitude": "45.70355000",
        "longitude": "3.16884000"
    },
    {
        "id": 44757,
        "name": "Orchamps",
        "latitude": "47.14751000",
        "longitude": "5.65873000"
    },
    {
        "id": 44758,
        "name": "Orchamps-Vennes",
        "latitude": "47.13042000",
        "longitude": "6.52468000"
    },
    {
        "id": 44759,
        "name": "Orchies",
        "latitude": "50.46667000",
        "longitude": "3.23333000"
    },
    {
        "id": 44760,
        "name": "Orcines",
        "latitude": "45.78246000",
        "longitude": "3.01234000"
    },
    {
        "id": 44761,
        "name": "Orgelet",
        "latitude": "46.52096000",
        "longitude": "5.61049000"
    },
    {
        "id": 44766,
        "name": "Orgères",
        "latitude": "47.99886000",
        "longitude": "-1.66781000"
    },
    {
        "id": 44767,
        "name": "Orgères-en-Beauce",
        "latitude": "48.14636000",
        "longitude": "1.68380000"
    },
    {
        "id": 44762,
        "name": "Orgerus",
        "latitude": "48.83851000",
        "longitude": "1.70132000"
    },
    {
        "id": 44763,
        "name": "Orgeval",
        "latitude": "48.92162000",
        "longitude": "1.97790000"
    },
    {
        "id": 44764,
        "name": "Orgon",
        "latitude": "43.79108000",
        "longitude": "5.03869000"
    },
    {
        "id": 44765,
        "name": "Orgueil",
        "latitude": "43.90618000",
        "longitude": "1.41071000"
    },
    {
        "id": 44769,
        "name": "Origny-en-Thiérache",
        "latitude": "49.89327000",
        "longitude": "4.01644000"
    },
    {
        "id": 44768,
        "name": "Origny-Sainte-Benoite",
        "latitude": "49.83333000",
        "longitude": "3.50000000"
    },
    {
        "id": 44770,
        "name": "Orival",
        "latitude": "49.72429000",
        "longitude": "1.20512000"
    },
    {
        "id": 44774,
        "name": "Orléans",
        "latitude": "47.90289000",
        "longitude": "1.90389000"
    },
    {
        "id": 44775,
        "name": "Orléat",
        "latitude": "45.86059000",
        "longitude": "3.42083000"
    },
    {
        "id": 44771,
        "name": "Orleix",
        "latitude": "43.27932000",
        "longitude": "0.12033000"
    },
    {
        "id": 44772,
        "name": "Orliénas",
        "latitude": "45.65886000",
        "longitude": "4.71832000"
    },
    {
        "id": 44773,
        "name": "Orly",
        "latitude": "48.74792000",
        "longitude": "2.39253000"
    },
    {
        "id": 44776,
        "name": "Ormes",
        "latitude": "47.94152000",
        "longitude": "1.81823000"
    },
    {
        "id": 44777,
        "name": "Ormesson-sur-Marne",
        "latitude": "48.78630000",
        "longitude": "2.54471000"
    },
    {
        "id": 44778,
        "name": "Ormoy",
        "latitude": "48.57489000",
        "longitude": "2.45206000"
    },
    {
        "id": 44779,
        "name": "Ornaisons",
        "latitude": "43.18040000",
        "longitude": "2.83689000"
    },
    {
        "id": 44780,
        "name": "Ornans",
        "latitude": "47.10749000",
        "longitude": "6.14306000"
    },
    {
        "id": 44781,
        "name": "Ornex",
        "latitude": "46.27270000",
        "longitude": "6.09982000"
    },
    {
        "id": 44782,
        "name": "Orry-la-Ville",
        "latitude": "49.13345000",
        "longitude": "2.51139000"
    },
    {
        "id": 44783,
        "name": "Orsan",
        "latitude": "44.13106000",
        "longitude": "4.66520000"
    },
    {
        "id": 44784,
        "name": "Orsay",
        "latitude": "48.69572000",
        "longitude": "2.18727000"
    },
    {
        "id": 44785,
        "name": "Ortaffa",
        "latitude": "42.57977000",
        "longitude": "2.92653000"
    },
    {
        "id": 44786,
        "name": "Orthez",
        "latitude": "43.48839000",
        "longitude": "-0.77244000"
    },
    {
        "id": 44787,
        "name": "Orval",
        "latitude": "46.72581000",
        "longitude": "2.47144000"
    },
    {
        "id": 44788,
        "name": "Orvault",
        "latitude": "47.27095000",
        "longitude": "-1.62190000"
    },
    {
        "id": 44789,
        "name": "Osny",
        "latitude": "49.07010000",
        "longitude": "2.06277000"
    },
    {
        "id": 44791,
        "name": "Ossé",
        "latitude": "48.05542000",
        "longitude": "-1.45029000"
    },
    {
        "id": 44792,
        "name": "Osséja",
        "latitude": "42.41383000",
        "longitude": "1.98192000"
    },
    {
        "id": 44790,
        "name": "Ossun",
        "latitude": "43.18333000",
        "longitude": "-0.03333000"
    },
    {
        "id": 44793,
        "name": "Ostheim",
        "latitude": "48.15995000",
        "longitude": "7.36976000"
    },
    {
        "id": 44794,
        "name": "Ostricourt",
        "latitude": "50.45145000",
        "longitude": "3.03417000"
    },
    {
        "id": 44795,
        "name": "Ostwald",
        "latitude": "48.54369000",
        "longitude": "7.71099000"
    },
    {
        "id": 44796,
        "name": "Othis",
        "latitude": "49.07387000",
        "longitude": "2.67502000"
    },
    {
        "id": 44797,
        "name": "Ottange",
        "latitude": "49.44307000",
        "longitude": "6.01988000"
    },
    {
        "id": 44798,
        "name": "Otterswiller",
        "latitude": "48.72568000",
        "longitude": "7.37878000"
    },
    {
        "id": 44799,
        "name": "Ottmarsheim",
        "latitude": "47.78802000",
        "longitude": "7.50777000"
    },
    {
        "id": 44800,
        "name": "Ottrott",
        "latitude": "48.45642000",
        "longitude": "7.42556000"
    },
    {
        "id": 44801,
        "name": "Ouches",
        "latitude": "46.01582000",
        "longitude": "3.98828000"
    },
    {
        "id": 44802,
        "name": "Oucques",
        "latitude": "47.82252000",
        "longitude": "1.29383000"
    },
    {
        "id": 44803,
        "name": "Oudon",
        "latitude": "47.34774000",
        "longitude": "-1.28500000"
    },
    {
        "id": 44804,
        "name": "Ouges",
        "latitude": "47.26164000",
        "longitude": "5.07395000"
    },
    {
        "id": 44805,
        "name": "Ouistreham",
        "latitude": "49.27566000",
        "longitude": "-0.25910000"
    },
    {
        "id": 44806,
        "name": "Oulins",
        "latitude": "48.86451000",
        "longitude": "1.47038000"
    },
    {
        "id": 44807,
        "name": "Oullins",
        "latitude": "45.71404000",
        "longitude": "4.80755000"
    },
    {
        "id": 44808,
        "name": "Ouroux-sur-Saône",
        "latitude": "46.72147000",
        "longitude": "4.95262000"
    },
    {
        "id": 44809,
        "name": "Oursbelille",
        "latitude": "43.28508000",
        "longitude": "0.03473000"
    },
    {
        "id": 44810,
        "name": "Ourville-en-Caux",
        "latitude": "49.72854000",
        "longitude": "0.60460000"
    },
    {
        "id": 44811,
        "name": "Ousse",
        "latitude": "43.28333000",
        "longitude": "-0.26667000"
    },
    {
        "id": 44812,
        "name": "Outarville",
        "latitude": "48.21393000",
        "longitude": "2.02198000"
    },
    {
        "id": 44813,
        "name": "Outreau",
        "latitude": "50.70535000",
        "longitude": "1.58970000"
    },
    {
        "id": 44814,
        "name": "Ouveillan",
        "latitude": "43.28915000",
        "longitude": "2.97124000"
    },
    {
        "id": 44815,
        "name": "Ouzouer-le-Marché",
        "latitude": "47.91055000",
        "longitude": "1.52607000"
    },
    {
        "id": 44816,
        "name": "Ouzouer-sur-Loire",
        "latitude": "47.76638000",
        "longitude": "2.48038000"
    },
    {
        "id": 44817,
        "name": "Ouzouer-sur-Trézée",
        "latitude": "47.67266000",
        "longitude": "2.80800000"
    },
    {
        "id": 44818,
        "name": "Oye-Plage",
        "latitude": "50.97713000",
        "longitude": "2.04276000"
    },
    {
        "id": 44819,
        "name": "Oyonnax",
        "latitude": "46.25917000",
        "longitude": "5.65727000"
    },
    {
        "id": 44820,
        "name": "Oytier-Saint-Oblas",
        "latitude": "45.55757000",
        "longitude": "5.03083000"
    },
    {
        "id": 44821,
        "name": "Ozoir-la-Ferrière",
        "latitude": "48.76699000",
        "longitude": "2.66871000"
    },
    {
        "id": 44822,
        "name": "Ozouer-le-Voulgis",
        "latitude": "48.66012000",
        "longitude": "2.77409000"
    },
    {
        "id": 44823,
        "name": "Pabu",
        "latitude": "48.58608000",
        "longitude": "-3.13608000"
    },
    {
        "id": 44825,
        "name": "Pacé",
        "latitude": "48.14848000",
        "longitude": "-1.77327000"
    },
    {
        "id": 44824,
        "name": "Pacy-sur-Eure",
        "latitude": "49.01667000",
        "longitude": "1.38333000"
    },
    {
        "id": 44826,
        "name": "Pagny-sur-Moselle",
        "latitude": "48.98365000",
        "longitude": "6.02069000"
    },
    {
        "id": 44827,
        "name": "Paillet",
        "latitude": "44.68512000",
        "longitude": "-0.36500000"
    },
    {
        "id": 44828,
        "name": "Paimboeuf",
        "latitude": "47.28654000",
        "longitude": "-2.03048000"
    },
    {
        "id": 44829,
        "name": "Paimpol",
        "latitude": "48.77841000",
        "longitude": "-3.04375000"
    },
    {
        "id": 44830,
        "name": "Paimpont",
        "latitude": "48.01809000",
        "longitude": "-2.17105000"
    },
    {
        "id": 44831,
        "name": "Palais de Justice",
        "latitude": "43.28657000",
        "longitude": "5.37603000"
    },
    {
        "id": 44832,
        "name": "Palaiseau",
        "latitude": "48.71828000",
        "longitude": "2.24980000"
    },
    {
        "id": 44833,
        "name": "Palaja",
        "latitude": "43.17363000",
        "longitude": "2.38462000"
    },
    {
        "id": 44834,
        "name": "Palama",
        "latitude": "43.36566000",
        "longitude": "5.44427000"
    },
    {
        "id": 44835,
        "name": "Palau-del-Vidre",
        "latitude": "42.57162000",
        "longitude": "2.96033000"
    },
    {
        "id": 44836,
        "name": "Palavas-les-Flots",
        "latitude": "43.52805000",
        "longitude": "3.92705000"
    },
    {
        "id": 44837,
        "name": "Palinges",
        "latitude": "46.55357000",
        "longitude": "4.21964000"
    },
    {
        "id": 44838,
        "name": "Pamiers",
        "latitude": "43.11650000",
        "longitude": "1.61079000"
    },
    {
        "id": 44839,
        "name": "Pamproux",
        "latitude": "46.39578000",
        "longitude": "-0.05327000"
    },
    {
        "id": 44840,
        "name": "Panazol",
        "latitude": "45.83465000",
        "longitude": "1.32759000"
    },
    {
        "id": 44841,
        "name": "Pancé",
        "latitude": "47.88181000",
        "longitude": "-1.65953000"
    },
    {
        "id": 44842,
        "name": "Panissières",
        "latitude": "45.79185000",
        "longitude": "4.34163000"
    },
    {
        "id": 44843,
        "name": "Pannecé",
        "latitude": "47.48583000",
        "longitude": "-1.23940000"
    },
    {
        "id": 44844,
        "name": "Pannes",
        "latitude": "48.01937000",
        "longitude": "2.66755000"
    },
    {
        "id": 44845,
        "name": "Pantin",
        "latitude": "48.89437000",
        "longitude": "2.40935000"
    },
    {
        "id": 44846,
        "name": "Paradou",
        "latitude": "43.71699000",
        "longitude": "4.78604000"
    },
    {
        "id": 44848,
        "name": "Paray-le-Monial",
        "latitude": "46.45457000",
        "longitude": "4.11584000"
    },
    {
        "id": 44847,
        "name": "Paray-Vieille-Poste",
        "latitude": "48.71402000",
        "longitude": "2.36283000"
    },
    {
        "id": 44861,
        "name": "Parçay-les-Pins",
        "latitude": "47.43668000",
        "longitude": "0.16312000"
    },
    {
        "id": 44860,
        "name": "Parçay-Meslay",
        "latitude": "47.44195000",
        "longitude": "0.74847000"
    },
    {
        "id": 44849,
        "name": "Parcé-sur-Sarthe",
        "latitude": "47.84356000",
        "longitude": "-0.20104000"
    },
    {
        "id": 44850,
        "name": "Parempuyre",
        "latitude": "44.95038000",
        "longitude": "-0.60453000"
    },
    {
        "id": 44851,
        "name": "Parentis-en-Born",
        "latitude": "44.35274000",
        "longitude": "-1.07095000"
    },
    {
        "id": 44852,
        "name": "Pargny-sur-Saulx",
        "latitude": "48.76866000",
        "longitude": "4.83758000"
    },
    {
        "id": 44854,
        "name": "Parigné",
        "latitude": "48.42727000",
        "longitude": "-1.19210000"
    },
    {
        "id": 44855,
        "name": "Parigné-le-Pôlin",
        "latitude": "47.84987000",
        "longitude": "0.10705000"
    },
    {
        "id": 44853,
        "name": "Parigny",
        "latitude": "48.59450000",
        "longitude": "-1.07925000"
    },
    {
        "id": 44856,
        "name": "Paris",
        "latitude": "48.85340000",
        "longitude": "2.34860000"
    },
    {
        "id": 44857,
        "name": "Parmain",
        "latitude": "49.11247000",
        "longitude": "2.21487000"
    },
    {
        "id": 44858,
        "name": "Paron",
        "latitude": "48.17888000",
        "longitude": "3.25075000"
    },
    {
        "id": 44859,
        "name": "Parthenay",
        "latitude": "46.64872000",
        "longitude": "-0.24682000"
    },
    {
        "id": 44862,
        "name": "Pas-de-Calais",
        "latitude": "50.48280000",
        "longitude": "2.28664000"
    },
    {
        "id": 44863,
        "name": "Paslières",
        "latitude": "45.92889000",
        "longitude": "3.49736000"
    },
    {
        "id": 44864,
        "name": "Pasly",
        "latitude": "49.40167000",
        "longitude": "3.29631000"
    },
    {
        "id": 44865,
        "name": "Passy",
        "latitude": "45.92341000",
        "longitude": "6.69562000"
    },
    {
        "id": 44866,
        "name": "Patay",
        "latitude": "48.04833000",
        "longitude": "1.69500000"
    },
    {
        "id": 44867,
        "name": "Pau",
        "latitude": "43.30000000",
        "longitude": "-0.36667000"
    },
    {
        "id": 44868,
        "name": "Pauillac",
        "latitude": "45.19644000",
        "longitude": "-0.74873000"
    },
    {
        "id": 44869,
        "name": "Paulhac",
        "latitude": "43.75639000",
        "longitude": "1.55667000"
    },
    {
        "id": 44870,
        "name": "Paulhaguet",
        "latitude": "45.20795000",
        "longitude": "3.51331000"
    },
    {
        "id": 44871,
        "name": "Paulhan",
        "latitude": "43.53944000",
        "longitude": "3.45760000"
    },
    {
        "id": 44872,
        "name": "Paulx",
        "latitude": "46.96181000",
        "longitude": "-1.75520000"
    },
    {
        "id": 44873,
        "name": "Pavie",
        "latitude": "43.60969000",
        "longitude": "0.59143000"
    },
    {
        "id": 44874,
        "name": "Pavilly",
        "latitude": "49.56703000",
        "longitude": "0.95445000"
    },
    {
        "id": 44875,
        "name": "Payns",
        "latitude": "48.38255000",
        "longitude": "3.97755000"
    },
    {
        "id": 44876,
        "name": "Payrin-Augmontel",
        "latitude": "43.54450000",
        "longitude": "2.35406000"
    },
    {
        "id": 44877,
        "name": "Payzac",
        "latitude": "45.40000000",
        "longitude": "1.21667000"
    },
    {
        "id": 44878,
        "name": "Peaugres",
        "latitude": "45.28651000",
        "longitude": "4.72845000"
    },
    {
        "id": 45342,
        "name": "Péaule",
        "latitude": "47.57972000",
        "longitude": "-2.35601000"
    },
    {
        "id": 45343,
        "name": "Péchabou",
        "latitude": "43.50241000",
        "longitude": "1.50934000"
    },
    {
        "id": 44879,
        "name": "Pechbonnieu",
        "latitude": "43.70242000",
        "longitude": "1.46538000"
    },
    {
        "id": 44880,
        "name": "Pecquencourt",
        "latitude": "50.37850000",
        "longitude": "3.21277000"
    },
    {
        "id": 45344,
        "name": "Pédernec",
        "latitude": "48.59682000",
        "longitude": "-3.26948000"
    },
    {
        "id": 45345,
        "name": "Pégomas",
        "latitude": "43.59662000",
        "longitude": "6.93211000"
    },
    {
        "id": 44881,
        "name": "Peillac",
        "latitude": "47.71391000",
        "longitude": "-2.21971000"
    },
    {
        "id": 44882,
        "name": "Peille",
        "latitude": "43.80296000",
        "longitude": "7.40191000"
    },
    {
        "id": 44883,
        "name": "Peillon",
        "latitude": "43.77861000",
        "longitude": "7.38278000"
    },
    {
        "id": 44884,
        "name": "Peillonnex",
        "latitude": "46.12861000",
        "longitude": "6.37715000"
    },
    {
        "id": 44885,
        "name": "Peipin",
        "latitude": "44.13778000",
        "longitude": "5.95722000"
    },
    {
        "id": 45346,
        "name": "Pélissanne",
        "latitude": "43.63260000",
        "longitude": "5.15220000"
    },
    {
        "id": 44886,
        "name": "Pellegrue",
        "latitude": "44.74355000",
        "longitude": "0.07595000"
    },
    {
        "id": 44887,
        "name": "Pellouailles-les-Vignes",
        "latitude": "47.52172000",
        "longitude": "-0.43954000"
    },
    {
        "id": 44888,
        "name": "Pelousey",
        "latitude": "47.27538000",
        "longitude": "5.92263000"
    },
    {
        "id": 44889,
        "name": "Peltre",
        "latitude": "49.07480000",
        "longitude": "6.22721000"
    },
    {
        "id": 45347,
        "name": "Pélussin",
        "latitude": "45.41951000",
        "longitude": "4.68365000"
    },
    {
        "id": 44890,
        "name": "Pencran",
        "latitude": "48.43333000",
        "longitude": "-4.23333000"
    },
    {
        "id": 44891,
        "name": "Pendé",
        "latitude": "50.16055000",
        "longitude": "1.58541000"
    },
    {
        "id": 45348,
        "name": "Pénestin",
        "latitude": "47.48226000",
        "longitude": "-2.47275000"
    },
    {
        "id": 44892,
        "name": "Pennautier",
        "latitude": "43.24513000",
        "longitude": "2.31892000"
    },
    {
        "id": 44893,
        "name": "Penta-di-Casinca",
        "latitude": "42.46579000",
        "longitude": "9.45884000"
    },
    {
        "id": 44894,
        "name": "Penvénan",
        "latitude": "48.81182000",
        "longitude": "-3.29550000"
    },
    {
        "id": 45349,
        "name": "Pépieux",
        "latitude": "43.29735000",
        "longitude": "2.67952000"
    },
    {
        "id": 44895,
        "name": "Percy",
        "latitude": "48.91714000",
        "longitude": "-1.18916000"
    },
    {
        "id": 45350,
        "name": "Pérenchies",
        "latitude": "50.67013000",
        "longitude": "2.97024000"
    },
    {
        "id": 44896,
        "name": "Peri",
        "latitude": "42.00345000",
        "longitude": "8.92066000"
    },
    {
        "id": 45351,
        "name": "Périer",
        "latitude": "43.27755000",
        "longitude": "5.37925000"
    },
    {
        "id": 45352,
        "name": "Périers",
        "latitude": "49.18667000",
        "longitude": "-1.40762000"
    },
    {
        "id": 45353,
        "name": "Pérignac",
        "latitude": "45.62306000",
        "longitude": "-0.46488000"
    },
    {
        "id": 45354,
        "name": "Pérignat-lès-Sarliève",
        "latitude": "45.73657000",
        "longitude": "3.13960000"
    },
    {
        "id": 45355,
        "name": "Pérignat-sur-Allier",
        "latitude": "45.72843000",
        "longitude": "3.23184000"
    },
    {
        "id": 45356,
        "name": "Périgneux",
        "latitude": "45.44077000",
        "longitude": "4.15569000"
    },
    {
        "id": 45357,
        "name": "Périgny",
        "latitude": "46.15519000",
        "longitude": "-1.09822000"
    },
    {
        "id": 45358,
        "name": "Périgny",
        "latitude": "48.69656000",
        "longitude": "2.55537000"
    },
    {
        "id": 45359,
        "name": "Périgueux",
        "latitude": "45.18333000",
        "longitude": "0.71667000"
    },
    {
        "id": 44897,
        "name": "Pernes",
        "latitude": "50.48437000",
        "longitude": "2.41253000"
    },
    {
        "id": 44898,
        "name": "Pernes-les-Fontaines",
        "latitude": "43.99802000",
        "longitude": "5.05906000"
    },
    {
        "id": 45360,
        "name": "Pérols",
        "latitude": "43.56313000",
        "longitude": "3.95203000"
    },
    {
        "id": 45361,
        "name": "Péron",
        "latitude": "46.19053000",
        "longitude": "5.92524000"
    },
    {
        "id": 45362,
        "name": "Péronnas",
        "latitude": "46.18338000",
        "longitude": "5.21052000"
    },
    {
        "id": 45363,
        "name": "Péronne",
        "latitude": "49.93218000",
        "longitude": "2.93630000"
    },
    {
        "id": 45364,
        "name": "Pérouges",
        "latitude": "45.90440000",
        "longitude": "5.17695000"
    },
    {
        "id": 45365,
        "name": "Péroy-les-Gombries",
        "latitude": "49.16248000",
        "longitude": "2.84556000"
    },
    {
        "id": 44899,
        "name": "Perpignan",
        "latitude": "42.69764000",
        "longitude": "2.89541000"
    },
    {
        "id": 44900,
        "name": "Perrecy-les-Forges",
        "latitude": "46.61453000",
        "longitude": "4.21380000"
    },
    {
        "id": 44901,
        "name": "Perreux",
        "latitude": "46.04004000",
        "longitude": "4.12663000"
    },
    {
        "id": 44902,
        "name": "Perriers-sur-Andelle",
        "latitude": "49.41487000",
        "longitude": "1.37098000"
    },
    {
        "id": 44903,
        "name": "Perrignier",
        "latitude": "46.30558000",
        "longitude": "6.43928000"
    },
    {
        "id": 44904,
        "name": "Perrigny",
        "latitude": "46.66744000",
        "longitude": "5.58456000"
    },
    {
        "id": 44905,
        "name": "Perrigny-lès-Dijon",
        "latitude": "47.26648000",
        "longitude": "5.00628000"
    },
    {
        "id": 44906,
        "name": "Perros-Guirec",
        "latitude": "48.81454000",
        "longitude": "-3.43963000"
    },
    {
        "id": 44907,
        "name": "Perrusson",
        "latitude": "47.09873000",
        "longitude": "1.01438000"
    },
    {
        "id": 44908,
        "name": "Pers-Jussy",
        "latitude": "46.10594000",
        "longitude": "6.26955000"
    },
    {
        "id": 44909,
        "name": "Persan",
        "latitude": "49.15335000",
        "longitude": "2.27218000"
    },
    {
        "id": 44910,
        "name": "Perthes",
        "latitude": "48.47821000",
        "longitude": "2.55509000"
    },
    {
        "id": 44911,
        "name": "Pertuis",
        "latitude": "43.69415000",
        "longitude": "5.50291000"
    },
    {
        "id": 44912,
        "name": "Peschadoires",
        "latitude": "45.82622000",
        "longitude": "3.49255000"
    },
    {
        "id": 44913,
        "name": "Pesmes",
        "latitude": "47.28008000",
        "longitude": "5.56698000"
    },
    {
        "id": 44914,
        "name": "Pessac",
        "latitude": "44.81011000",
        "longitude": "-0.64129000"
    },
    {
        "id": 44915,
        "name": "Petit-Couronne",
        "latitude": "49.38333000",
        "longitude": "1.01667000"
    },
    {
        "id": 44916,
        "name": "Petit-Mars",
        "latitude": "47.39512000",
        "longitude": "-1.45262000"
    },
    {
        "id": 44917,
        "name": "Petit-Noir",
        "latitude": "46.93333000",
        "longitude": "5.33333000"
    },
    {
        "id": 44918,
        "name": "Petit-Réderching",
        "latitude": "49.05494000",
        "longitude": "7.30640000"
    },
    {
        "id": 44919,
        "name": "Petite-Forêt",
        "latitude": "50.36667000",
        "longitude": "3.48333000"
    },
    {
        "id": 44920,
        "name": "Petite-Rosselle",
        "latitude": "49.21177000",
        "longitude": "6.85607000"
    },
    {
        "id": 44921,
        "name": "Petiville",
        "latitude": "49.46078000",
        "longitude": "0.58740000"
    },
    {
        "id": 44922,
        "name": "Peujard",
        "latitude": "45.03619000",
        "longitude": "-0.44096000"
    },
    {
        "id": 44923,
        "name": "Peymeinade",
        "latitude": "43.64244000",
        "longitude": "6.87583000"
    },
    {
        "id": 44924,
        "name": "Peynier",
        "latitude": "43.44837000",
        "longitude": "5.64139000"
    },
    {
        "id": 44925,
        "name": "Peypin",
        "latitude": "43.38503000",
        "longitude": "5.57788000"
    },
    {
        "id": 44926,
        "name": "Peyrat-de-Bellac",
        "latitude": "46.14087000",
        "longitude": "1.03661000"
    },
    {
        "id": 44927,
        "name": "Peyrat-le-Château",
        "latitude": "45.81578000",
        "longitude": "1.77233000"
    },
    {
        "id": 44928,
        "name": "Peyrehorade",
        "latitude": "43.54886000",
        "longitude": "-1.11574000"
    },
    {
        "id": 44929,
        "name": "Peyrestortes",
        "latitude": "42.75480000",
        "longitude": "2.85232000"
    },
    {
        "id": 44931,
        "name": "Peyriac-de-Mer",
        "latitude": "43.08720000",
        "longitude": "2.95831000"
    },
    {
        "id": 44930,
        "name": "Peyriac-Minervois",
        "latitude": "43.29068000",
        "longitude": "2.56613000"
    },
    {
        "id": 44932,
        "name": "Peyrilhac",
        "latitude": "45.95043000",
        "longitude": "1.13503000"
    },
    {
        "id": 44933,
        "name": "Peyrins",
        "latitude": "45.09331000",
        "longitude": "5.04513000"
    },
    {
        "id": 44934,
        "name": "Peyrolles-en-Provence",
        "latitude": "43.64545000",
        "longitude": "5.58492000"
    },
    {
        "id": 44935,
        "name": "Peyruis",
        "latitude": "44.02880000",
        "longitude": "5.94032000"
    },
    {
        "id": 45366,
        "name": "Pézenas",
        "latitude": "43.45953000",
        "longitude": "3.42384000"
    },
    {
        "id": 44936,
        "name": "Pezens",
        "latitude": "43.25446000",
        "longitude": "2.26868000"
    },
    {
        "id": 45367,
        "name": "Pézilla-la-Rivière",
        "latitude": "42.69536000",
        "longitude": "2.77115000"
    },
    {
        "id": 44937,
        "name": "Pfaffenheim",
        "latitude": "47.98567000",
        "longitude": "7.28928000"
    },
    {
        "id": 44938,
        "name": "Pfaffenhoffen",
        "latitude": "48.84220000",
        "longitude": "7.60714000"
    },
    {
        "id": 44939,
        "name": "Pfastatt",
        "latitude": "47.76915000",
        "longitude": "7.30295000"
    },
    {
        "id": 44940,
        "name": "Pfetterhouse",
        "latitude": "47.50128000",
        "longitude": "7.16598000"
    },
    {
        "id": 44941,
        "name": "Pfulgriesheim",
        "latitude": "48.64403000",
        "longitude": "7.67086000"
    },
    {
        "id": 44942,
        "name": "Phalempin",
        "latitude": "50.51691000",
        "longitude": "3.01584000"
    },
    {
        "id": 44943,
        "name": "Phalsbourg",
        "latitude": "48.76771000",
        "longitude": "7.25820000"
    },
    {
        "id": 44944,
        "name": "Pia",
        "latitude": "42.74490000",
        "longitude": "2.91930000"
    },
    {
        "id": 44945,
        "name": "Pibrac",
        "latitude": "43.62042000",
        "longitude": "1.28540000"
    },
    {
        "id": 44946,
        "name": "Picauville",
        "latitude": "49.37810000",
        "longitude": "-1.40048000"
    },
    {
        "id": 44947,
        "name": "Picquigny",
        "latitude": "49.94413000",
        "longitude": "2.14376000"
    },
    {
        "id": 44986,
        "name": "Piégut-Pluviers",
        "latitude": "45.62297000",
        "longitude": "0.69013000"
    },
    {
        "id": 44948,
        "name": "Piennes",
        "latitude": "49.30964000",
        "longitude": "5.78009000"
    },
    {
        "id": 44950,
        "name": "Pierre-Bénite",
        "latitude": "45.70359000",
        "longitude": "4.82424000"
    },
    {
        "id": 44949,
        "name": "Pierre-Buffière",
        "latitude": "45.69193000",
        "longitude": "1.36193000"
    },
    {
        "id": 44951,
        "name": "Pierre-Châtel",
        "latitude": "44.95735000",
        "longitude": "5.77454000"
    },
    {
        "id": 44952,
        "name": "Pierre-de-Bresse",
        "latitude": "46.88333000",
        "longitude": "5.25000000"
    },
    {
        "id": 44953,
        "name": "Pierrefeu-du-Var",
        "latitude": "43.22411000",
        "longitude": "6.14536000"
    },
    {
        "id": 44954,
        "name": "Pierrefitte-Nestalas",
        "latitude": "42.96667000",
        "longitude": "-0.06667000"
    },
    {
        "id": 44955,
        "name": "Pierrefitte-sur-Seine",
        "latitude": "48.96691000",
        "longitude": "2.36104000"
    },
    {
        "id": 44956,
        "name": "Pierrefonds",
        "latitude": "49.34878000",
        "longitude": "2.97790000"
    },
    {
        "id": 44957,
        "name": "Pierrefontaine-les-Varans",
        "latitude": "47.21601000",
        "longitude": "6.54030000"
    },
    {
        "id": 44958,
        "name": "Pierrefort",
        "latitude": "44.92175000",
        "longitude": "2.83811000"
    },
    {
        "id": 44959,
        "name": "Pierrelatte",
        "latitude": "44.37549000",
        "longitude": "4.70314000"
    },
    {
        "id": 44960,
        "name": "Pierrelaye",
        "latitude": "49.02110000",
        "longitude": "2.15481000"
    },
    {
        "id": 44961,
        "name": "Pierrepont",
        "latitude": "49.41646000",
        "longitude": "5.70908000"
    },
    {
        "id": 44962,
        "name": "Pierres",
        "latitude": "48.59209000",
        "longitude": "1.56444000"
    },
    {
        "id": 44963,
        "name": "Pierrevert",
        "latitude": "43.81137000",
        "longitude": "5.74958000"
    },
    {
        "id": 44964,
        "name": "Pierrevillers",
        "latitude": "49.22468000",
        "longitude": "6.10337000"
    },
    {
        "id": 44965,
        "name": "Pierry",
        "latitude": "49.02024000",
        "longitude": "3.94071000"
    },
    {
        "id": 44966,
        "name": "Pietranera",
        "latitude": "42.72338000",
        "longitude": "9.45621000"
    },
    {
        "id": 44967,
        "name": "Pietrosella",
        "latitude": "41.83576000",
        "longitude": "8.84573000"
    },
    {
        "id": 44968,
        "name": "Pignan",
        "latitude": "43.58365000",
        "longitude": "3.75981000"
    },
    {
        "id": 44969,
        "name": "Pignans",
        "latitude": "43.30071000",
        "longitude": "6.22650000"
    },
    {
        "id": 44970,
        "name": "Pinet",
        "latitude": "43.40556000",
        "longitude": "3.51000000"
    },
    {
        "id": 44971,
        "name": "Piney",
        "latitude": "48.36366000",
        "longitude": "4.33325000"
    },
    {
        "id": 44972,
        "name": "Pinon",
        "latitude": "49.48916000",
        "longitude": "3.44703000"
    },
    {
        "id": 44973,
        "name": "Pinsaguel",
        "latitude": "43.51040000",
        "longitude": "1.38831000"
    },
    {
        "id": 44974,
        "name": "Piolenc",
        "latitude": "44.17765000",
        "longitude": "4.76157000"
    },
    {
        "id": 44975,
        "name": "Pionsat",
        "latitude": "46.10963000",
        "longitude": "2.69319000"
    },
    {
        "id": 44976,
        "name": "Pipriac",
        "latitude": "47.80953000",
        "longitude": "-1.94638000"
    },
    {
        "id": 44980,
        "name": "Piré-sur-Seiche",
        "latitude": "48.00958000",
        "longitude": "-1.42909000"
    },
    {
        "id": 44977,
        "name": "Pirey",
        "latitude": "47.26136000",
        "longitude": "5.96466000"
    },
    {
        "id": 44978,
        "name": "Piriac-sur-Mer",
        "latitude": "47.37938000",
        "longitude": "-2.54616000"
    },
    {
        "id": 44979,
        "name": "Pirou",
        "latitude": "49.18089000",
        "longitude": "-1.57384000"
    },
    {
        "id": 44981,
        "name": "Pissos",
        "latitude": "44.30833000",
        "longitude": "-0.77963000"
    },
    {
        "id": 44982,
        "name": "Pissotte",
        "latitude": "46.49725000",
        "longitude": "-0.80666000"
    },
    {
        "id": 44983,
        "name": "Pissy-Pôville",
        "latitude": "49.52986000",
        "longitude": "0.99281000"
    },
    {
        "id": 44984,
        "name": "Pithiviers",
        "latitude": "48.17185000",
        "longitude": "2.25185000"
    },
    {
        "id": 44985,
        "name": "Pithiviers-le-Vieil",
        "latitude": "48.16313000",
        "longitude": "2.20922000"
    },
    {
        "id": 45368,
        "name": "Pîtres",
        "latitude": "49.31667000",
        "longitude": "1.23333000"
    },
    {
        "id": 44987,
        "name": "Plabennec",
        "latitude": "48.50244000",
        "longitude": "-4.42656000"
    },
    {
        "id": 44988,
        "name": "Plailly",
        "latitude": "49.10288000",
        "longitude": "2.58549000"
    },
    {
        "id": 44989,
        "name": "Plaimpied-Givaudins",
        "latitude": "46.99800000",
        "longitude": "2.45428000"
    },
    {
        "id": 44990,
        "name": "Plaine-Haute",
        "latitude": "48.44442000",
        "longitude": "-2.85416000"
    },
    {
        "id": 44991,
        "name": "Plainfaing",
        "latitude": "48.17284000",
        "longitude": "7.01539000"
    },
    {
        "id": 44992,
        "name": "Plaintel",
        "latitude": "48.40733000",
        "longitude": "-2.81733000"
    },
    {
        "id": 44993,
        "name": "Plaisance",
        "latitude": "43.60436000",
        "longitude": "0.04615000"
    },
    {
        "id": 44994,
        "name": "Plaisance-du-Touch",
        "latitude": "43.56566000",
        "longitude": "1.29749000"
    },
    {
        "id": 44995,
        "name": "Plaisir",
        "latitude": "48.82319000",
        "longitude": "1.95410000"
    },
    {
        "id": 44996,
        "name": "Plan-d'Aups-Sainte-Baume",
        "latitude": "43.33333000",
        "longitude": "5.71667000"
    },
    {
        "id": 44997,
        "name": "Plan-de-Cuques",
        "latitude": "43.34753000",
        "longitude": "5.46398000"
    },
    {
        "id": 44998,
        "name": "Plancher-Bas",
        "latitude": "47.71977000",
        "longitude": "6.73041000"
    },
    {
        "id": 44999,
        "name": "Plancher-les-Mines",
        "latitude": "47.76150000",
        "longitude": "6.74308000"
    },
    {
        "id": 45000,
        "name": "Plancoët",
        "latitude": "48.52199000",
        "longitude": "-2.23540000"
    },
    {
        "id": 45001,
        "name": "Planguenoual",
        "latitude": "48.53300000",
        "longitude": "-2.57642000"
    },
    {
        "id": 45002,
        "name": "Plappeville",
        "latitude": "49.13019000",
        "longitude": "6.12471000"
    },
    {
        "id": 45003,
        "name": "Plaudren",
        "latitude": "47.77868000",
        "longitude": "-2.69331000"
    },
    {
        "id": 45004,
        "name": "Plauzat",
        "latitude": "45.62157000",
        "longitude": "3.14896000"
    },
    {
        "id": 45005,
        "name": "Pleaux",
        "latitude": "45.13479000",
        "longitude": "2.22652000"
    },
    {
        "id": 45116,
        "name": "Pléchâtel",
        "latitude": "47.89496000",
        "longitude": "-1.74876000"
    },
    {
        "id": 45118,
        "name": "Plédéliac",
        "latitude": "48.45000000",
        "longitude": "-2.38333000"
    },
    {
        "id": 45117,
        "name": "Plédran",
        "latitude": "48.44600000",
        "longitude": "-2.74667000"
    },
    {
        "id": 45119,
        "name": "Pléguien",
        "latitude": "48.63525000",
        "longitude": "-2.94001000"
    },
    {
        "id": 45120,
        "name": "Pléhédel",
        "latitude": "48.69645000",
        "longitude": "-3.00833000"
    },
    {
        "id": 45006,
        "name": "Pleine-Fougères",
        "latitude": "48.53308000",
        "longitude": "-1.56534000"
    },
    {
        "id": 45121,
        "name": "Plélan-le-Grand",
        "latitude": "48.00124000",
        "longitude": "-2.09914000"
    },
    {
        "id": 45122,
        "name": "Plélan-le-Petit",
        "latitude": "48.43157000",
        "longitude": "-2.21740000"
    },
    {
        "id": 45123,
        "name": "Plélo",
        "latitude": "48.55534000",
        "longitude": "-2.94634000"
    },
    {
        "id": 45124,
        "name": "Plémet",
        "latitude": "48.17601000",
        "longitude": "-2.59325000"
    },
    {
        "id": 45125,
        "name": "Plémy",
        "latitude": "48.33644000",
        "longitude": "-2.68244000"
    },
    {
        "id": 45127,
        "name": "Plénée-Jugon",
        "latitude": "48.36462000",
        "longitude": "-2.40036000"
    },
    {
        "id": 45126,
        "name": "Pléneuf-Val-André",
        "latitude": "48.59300000",
        "longitude": "-2.54675000"
    },
    {
        "id": 45007,
        "name": "Plerguer",
        "latitude": "48.52676000",
        "longitude": "-1.84769000"
    },
    {
        "id": 45128,
        "name": "Plérin",
        "latitude": "48.54249000",
        "longitude": "-2.77983000"
    },
    {
        "id": 45008,
        "name": "Plescop",
        "latitude": "47.69726000",
        "longitude": "-2.80560000"
    },
    {
        "id": 45009,
        "name": "Pleslin-Trigavou",
        "latitude": "48.53333000",
        "longitude": "-2.06667000"
    },
    {
        "id": 45010,
        "name": "Plessala",
        "latitude": "48.27642000",
        "longitude": "-2.61876000"
    },
    {
        "id": 45011,
        "name": "Plessé",
        "latitude": "47.54180000",
        "longitude": "-1.88609000"
    },
    {
        "id": 45012,
        "name": "Plestan",
        "latitude": "48.42266000",
        "longitude": "-2.44777000"
    },
    {
        "id": 45013,
        "name": "Plestin-les-Grèves",
        "latitude": "48.65844000",
        "longitude": "-3.63056000"
    },
    {
        "id": 45014,
        "name": "Pleubian",
        "latitude": "48.84267000",
        "longitude": "-3.13900000"
    },
    {
        "id": 45015,
        "name": "Pleucadeuc",
        "latitude": "47.75876000",
        "longitude": "-2.37362000"
    },
    {
        "id": 45016,
        "name": "Pleudaniel",
        "latitude": "48.76644000",
        "longitude": "-3.14033000"
    },
    {
        "id": 45017,
        "name": "Pleudihen-sur-Rance",
        "latitude": "48.51667000",
        "longitude": "-1.96667000"
    },
    {
        "id": 45018,
        "name": "Pleugriffet",
        "latitude": "47.98594000",
        "longitude": "-2.68454000"
    },
    {
        "id": 45019,
        "name": "Pleugueneuc",
        "latitude": "48.39735000",
        "longitude": "-1.90301000"
    },
    {
        "id": 45020,
        "name": "Pleumartin",
        "latitude": "46.73786000",
        "longitude": "0.76900000"
    },
    {
        "id": 45021,
        "name": "Pleumeleuc",
        "latitude": "48.18438000",
        "longitude": "-1.91962000"
    },
    {
        "id": 45022,
        "name": "Pleumeur-Bodou",
        "latitude": "48.76667000",
        "longitude": "-3.51667000"
    },
    {
        "id": 45023,
        "name": "Pleumeur-Gautier",
        "latitude": "48.80044000",
        "longitude": "-3.15578000"
    },
    {
        "id": 45024,
        "name": "Pleurtuit",
        "latitude": "48.57858000",
        "longitude": "-2.05805000"
    },
    {
        "id": 45025,
        "name": "Pleuven",
        "latitude": "47.90000000",
        "longitude": "-4.03333000"
    },
    {
        "id": 45026,
        "name": "Pleyben",
        "latitude": "48.23333000",
        "longitude": "-3.96667000"
    },
    {
        "id": 45027,
        "name": "Pleyber-Christ",
        "latitude": "48.50000000",
        "longitude": "-3.86667000"
    },
    {
        "id": 45028,
        "name": "Plobannalec-Lesconil",
        "latitude": "47.81667000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45029,
        "name": "Plobsheim",
        "latitude": "48.46979000",
        "longitude": "7.72442000"
    },
    {
        "id": 45030,
        "name": "Ploemel",
        "latitude": "47.65153000",
        "longitude": "-3.07030000"
    },
    {
        "id": 45031,
        "name": "Ploemeur",
        "latitude": "47.73333000",
        "longitude": "-3.43333000"
    },
    {
        "id": 45100,
        "name": "Ploërdut",
        "latitude": "48.08742000",
        "longitude": "-3.28550000"
    },
    {
        "id": 45032,
        "name": "Ploeren",
        "latitude": "47.65713000",
        "longitude": "-2.86397000"
    },
    {
        "id": 45101,
        "name": "Ploërmel",
        "latitude": "47.93172000",
        "longitude": "-2.39781000"
    },
    {
        "id": 45129,
        "name": "Plœuc-sur-Lié",
        "latitude": "48.35000000",
        "longitude": "-2.75000000"
    },
    {
        "id": 45102,
        "name": "Ploëzal",
        "latitude": "48.71534000",
        "longitude": "-3.20333000"
    },
    {
        "id": 45033,
        "name": "Plogastel-Saint-Germain",
        "latitude": "47.98333000",
        "longitude": "-4.26667000"
    },
    {
        "id": 45034,
        "name": "Plogoff",
        "latitude": "48.03700000",
        "longitude": "-4.66606000"
    },
    {
        "id": 45035,
        "name": "Plogonnec",
        "latitude": "48.08333000",
        "longitude": "-4.18333000"
    },
    {
        "id": 45036,
        "name": "Plombières-les-Bains",
        "latitude": "47.96337000",
        "longitude": "6.45758000"
    },
    {
        "id": 45037,
        "name": "Plombières-lès-Dijon",
        "latitude": "47.33333000",
        "longitude": "4.96667000"
    },
    {
        "id": 45038,
        "name": "Plomelin",
        "latitude": "47.93333000",
        "longitude": "-4.15000000"
    },
    {
        "id": 45039,
        "name": "Plomeur",
        "latitude": "47.83333000",
        "longitude": "-4.28333000"
    },
    {
        "id": 45040,
        "name": "Plomodiern",
        "latitude": "48.18333000",
        "longitude": "-4.23333000"
    },
    {
        "id": 45041,
        "name": "Plonéis",
        "latitude": "48.01667000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45042,
        "name": "Plonéour-Lanvern",
        "latitude": "47.90000000",
        "longitude": "-4.28333000"
    },
    {
        "id": 45044,
        "name": "Plonévez-du-Faou",
        "latitude": "48.25000000",
        "longitude": "-3.83333000"
    },
    {
        "id": 45043,
        "name": "Plonévez-Porzay",
        "latitude": "48.10000000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45045,
        "name": "Plouagat",
        "latitude": "48.53611000",
        "longitude": "-2.99956000"
    },
    {
        "id": 45046,
        "name": "Plouaret",
        "latitude": "48.61667000",
        "longitude": "-3.46667000"
    },
    {
        "id": 45047,
        "name": "Plouarzel",
        "latitude": "48.43333000",
        "longitude": "-4.73333000"
    },
    {
        "id": 45048,
        "name": "Plouasne",
        "latitude": "48.30081000",
        "longitude": "-2.00698000"
    },
    {
        "id": 45049,
        "name": "Plouay",
        "latitude": "47.91444000",
        "longitude": "-3.33384000"
    },
    {
        "id": 45050,
        "name": "Ploubalay",
        "latitude": "48.58109000",
        "longitude": "-2.14069000"
    },
    {
        "id": 45051,
        "name": "Ploubazlanec",
        "latitude": "48.80000000",
        "longitude": "-3.03333000"
    },
    {
        "id": 45052,
        "name": "Ploubezre",
        "latitude": "48.70000000",
        "longitude": "-3.45000000"
    },
    {
        "id": 45053,
        "name": "Ploudalmézeau",
        "latitude": "48.53333000",
        "longitude": "-4.65000000"
    },
    {
        "id": 45054,
        "name": "Ploudaniel",
        "latitude": "48.53333000",
        "longitude": "-4.31667000"
    },
    {
        "id": 45097,
        "name": "Plouëc-du-Trieux",
        "latitude": "48.68333000",
        "longitude": "-3.20000000"
    },
    {
        "id": 45094,
        "name": "Plouédern",
        "latitude": "48.48333000",
        "longitude": "-4.25000000"
    },
    {
        "id": 45095,
        "name": "Plouénan",
        "latitude": "48.63333000",
        "longitude": "-4.00000000"
    },
    {
        "id": 45098,
        "name": "Plouër-sur-Rance",
        "latitude": "48.52669000",
        "longitude": "-2.00298000"
    },
    {
        "id": 45055,
        "name": "Plouescat",
        "latitude": "48.66667000",
        "longitude": "-4.16667000"
    },
    {
        "id": 45096,
        "name": "Plouézec",
        "latitude": "48.75122000",
        "longitude": "-2.98467000"
    },
    {
        "id": 45056,
        "name": "Plouezoc'h",
        "latitude": "48.63333000",
        "longitude": "-3.81667000"
    },
    {
        "id": 45057,
        "name": "Ploufragan",
        "latitude": "48.49141000",
        "longitude": "-2.79458000"
    },
    {
        "id": 45058,
        "name": "Plougasnou",
        "latitude": "48.70000000",
        "longitude": "-3.80000000"
    },
    {
        "id": 45059,
        "name": "Plougastel-Daoulas",
        "latitude": "48.36667000",
        "longitude": "-4.36667000"
    },
    {
        "id": 45060,
        "name": "Plougonvelin",
        "latitude": "48.34059000",
        "longitude": "-4.71846000"
    },
    {
        "id": 45061,
        "name": "Plougonven",
        "latitude": "48.51667000",
        "longitude": "-3.71667000"
    },
    {
        "id": 45062,
        "name": "Plougoulm",
        "latitude": "48.66667000",
        "longitude": "-4.05000000"
    },
    {
        "id": 45063,
        "name": "Plougoumelen",
        "latitude": "47.65070000",
        "longitude": "-2.91716000"
    },
    {
        "id": 45064,
        "name": "Plougourvest",
        "latitude": "48.55000000",
        "longitude": "-4.08333000"
    },
    {
        "id": 45065,
        "name": "Plougrescant",
        "latitude": "48.84026000",
        "longitude": "-3.22886000"
    },
    {
        "id": 45066,
        "name": "Plouguenast",
        "latitude": "48.28208000",
        "longitude": "-2.70443000"
    },
    {
        "id": 45067,
        "name": "Plouguerneau",
        "latitude": "48.60000000",
        "longitude": "-4.50000000"
    },
    {
        "id": 45068,
        "name": "Plouguernével",
        "latitude": "48.23940000",
        "longitude": "-3.25071000"
    },
    {
        "id": 45069,
        "name": "Plouguiel",
        "latitude": "48.79742000",
        "longitude": "-3.23883000"
    },
    {
        "id": 45070,
        "name": "Plouguin",
        "latitude": "48.53333000",
        "longitude": "-4.60000000"
    },
    {
        "id": 45071,
        "name": "Plouha",
        "latitude": "48.67650000",
        "longitude": "-2.92842000"
    },
    {
        "id": 45072,
        "name": "Plouharnel",
        "latitude": "47.59804000",
        "longitude": "-3.11274000"
    },
    {
        "id": 45073,
        "name": "Plouhinec",
        "latitude": "48.01667000",
        "longitude": "-4.48333000"
    },
    {
        "id": 45074,
        "name": "Plouider",
        "latitude": "48.61667000",
        "longitude": "-4.30000000"
    },
    {
        "id": 45075,
        "name": "Plouigneau",
        "latitude": "48.56667000",
        "longitude": "-3.70000000"
    },
    {
        "id": 45076,
        "name": "Plouisy",
        "latitude": "48.57817000",
        "longitude": "-3.18434000"
    },
    {
        "id": 45077,
        "name": "Ploumagoar",
        "latitude": "48.54509000",
        "longitude": "-3.13233000"
    },
    {
        "id": 45078,
        "name": "Ploumilliau",
        "latitude": "48.68333000",
        "longitude": "-3.51667000"
    },
    {
        "id": 45079,
        "name": "Ploumoguer",
        "latitude": "48.40000000",
        "longitude": "-4.71667000"
    },
    {
        "id": 45080,
        "name": "Plounéour-Ménez",
        "latitude": "48.45000000",
        "longitude": "-3.88333000"
    },
    {
        "id": 45081,
        "name": "Plounéour-Trez",
        "latitude": "48.65000000",
        "longitude": "-4.31667000"
    },
    {
        "id": 45082,
        "name": "Plounéventer",
        "latitude": "48.51667000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45083,
        "name": "Plounévez-Lochrist",
        "latitude": "48.61667000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45084,
        "name": "Plounévez-Moëdec",
        "latitude": "48.56667000",
        "longitude": "-3.45000000"
    },
    {
        "id": 45085,
        "name": "Plounévez-Quintin",
        "latitude": "48.28333000",
        "longitude": "-3.23333000"
    },
    {
        "id": 45086,
        "name": "Plounévézel",
        "latitude": "48.30000000",
        "longitude": "-3.60000000"
    },
    {
        "id": 45087,
        "name": "Plouray",
        "latitude": "48.14580000",
        "longitude": "-3.38671000"
    },
    {
        "id": 45088,
        "name": "Plourin-lès-Morlaix",
        "latitude": "48.53333000",
        "longitude": "-3.78333000"
    },
    {
        "id": 45089,
        "name": "Plourivo",
        "latitude": "48.74550000",
        "longitude": "-3.07075000"
    },
    {
        "id": 45090,
        "name": "Plouvien",
        "latitude": "48.53333000",
        "longitude": "-4.45000000"
    },
    {
        "id": 45091,
        "name": "Plouvorn",
        "latitude": "48.58333000",
        "longitude": "-4.03333000"
    },
    {
        "id": 45092,
        "name": "Plouzané",
        "latitude": "48.38333000",
        "longitude": "-4.61667000"
    },
    {
        "id": 45093,
        "name": "Plouzévédé",
        "latitude": "48.60000000",
        "longitude": "-4.11667000"
    },
    {
        "id": 45099,
        "name": "Plozévet",
        "latitude": "47.98333000",
        "longitude": "-4.41667000"
    },
    {
        "id": 45103,
        "name": "Pluduno",
        "latitude": "48.53110000",
        "longitude": "-2.26848000"
    },
    {
        "id": 45104,
        "name": "Pluguffan",
        "latitude": "47.98333000",
        "longitude": "-4.18333000"
    },
    {
        "id": 45105,
        "name": "Pluherlin",
        "latitude": "47.69632000",
        "longitude": "-2.36349000"
    },
    {
        "id": 45106,
        "name": "Plumaugat",
        "latitude": "48.25479000",
        "longitude": "-2.23843000"
    },
    {
        "id": 45107,
        "name": "Plumelec",
        "latitude": "47.83754000",
        "longitude": "-2.63987000"
    },
    {
        "id": 45111,
        "name": "Pluméliau",
        "latitude": "47.95769000",
        "longitude": "-2.97494000"
    },
    {
        "id": 45108,
        "name": "Plumelin",
        "latitude": "47.86036000",
        "longitude": "-2.88754000"
    },
    {
        "id": 45109,
        "name": "Plumergat",
        "latitude": "47.74134000",
        "longitude": "-2.91501000"
    },
    {
        "id": 45110,
        "name": "Plumieux",
        "latitude": "48.10303000",
        "longitude": "-2.58382000"
    },
    {
        "id": 45112,
        "name": "Pluneret",
        "latitude": "47.67525000",
        "longitude": "-2.95782000"
    },
    {
        "id": 45113,
        "name": "Plurien",
        "latitude": "48.62559000",
        "longitude": "-2.40423000"
    },
    {
        "id": 45114,
        "name": "Pluvigner",
        "latitude": "47.77627000",
        "longitude": "-3.01013000"
    },
    {
        "id": 45115,
        "name": "Pluzunet",
        "latitude": "48.64076000",
        "longitude": "-3.37113000"
    },
    {
        "id": 45130,
        "name": "Pocé-les-Bois",
        "latitude": "48.11626000",
        "longitude": "-1.25168000"
    },
    {
        "id": 45131,
        "name": "Pocé-sur-Cisse",
        "latitude": "47.44330000",
        "longitude": "0.99142000"
    },
    {
        "id": 45132,
        "name": "Podensac",
        "latitude": "44.65038000",
        "longitude": "-0.35508000"
    },
    {
        "id": 45133,
        "name": "Poey-de-Lescar",
        "latitude": "43.35000000",
        "longitude": "-0.46667000"
    },
    {
        "id": 45134,
        "name": "Poilly-lez-Gien",
        "latitude": "47.67724000",
        "longitude": "2.59743000"
    },
    {
        "id": 45135,
        "name": "Poisat",
        "latitude": "45.15852000",
        "longitude": "5.76051000"
    },
    {
        "id": 45136,
        "name": "Poissy",
        "latitude": "48.92902000",
        "longitude": "2.04952000"
    },
    {
        "id": 45137,
        "name": "Poisy",
        "latitude": "45.92142000",
        "longitude": "6.06356000"
    },
    {
        "id": 45138,
        "name": "Poitiers",
        "latitude": "46.58333000",
        "longitude": "0.33333000"
    },
    {
        "id": 45139,
        "name": "Poix-de-Picardie",
        "latitude": "49.78333000",
        "longitude": "1.98333000"
    },
    {
        "id": 45140,
        "name": "Poix-du-Nord",
        "latitude": "50.18927000",
        "longitude": "3.60956000"
    },
    {
        "id": 45141,
        "name": "Polignac",
        "latitude": "45.07090000",
        "longitude": "3.86031000"
    },
    {
        "id": 45142,
        "name": "Poligny",
        "latitude": "46.83712000",
        "longitude": "5.70505000"
    },
    {
        "id": 45143,
        "name": "Pollestres",
        "latitude": "42.64200000",
        "longitude": "2.87116000"
    },
    {
        "id": 45144,
        "name": "Polliat",
        "latitude": "46.24849000",
        "longitude": "5.12658000"
    },
    {
        "id": 45145,
        "name": "Pollionnay",
        "latitude": "45.76459000",
        "longitude": "4.66112000"
    },
    {
        "id": 45146,
        "name": "Polminhac",
        "latitude": "44.95177000",
        "longitude": "2.57751000"
    },
    {
        "id": 45147,
        "name": "Pomarez",
        "latitude": "43.62971000",
        "longitude": "-0.82934000"
    },
    {
        "id": 45159,
        "name": "Pomérols",
        "latitude": "43.39139000",
        "longitude": "3.49944000"
    },
    {
        "id": 45148,
        "name": "Pommeret",
        "latitude": "48.46378000",
        "longitude": "-2.62689000"
    },
    {
        "id": 45149,
        "name": "Pommerit-Jaudy",
        "latitude": "48.73058000",
        "longitude": "-3.24208000"
    },
    {
        "id": 45150,
        "name": "Pommerit-le-Vicomte",
        "latitude": "48.61989000",
        "longitude": "-3.09000000"
    },
    {
        "id": 45151,
        "name": "Pommeuse",
        "latitude": "48.81667000",
        "longitude": "3.01667000"
    },
    {
        "id": 45152,
        "name": "Pommiers",
        "latitude": "45.95540000",
        "longitude": "4.69251000"
    },
    {
        "id": 45153,
        "name": "Pompaire",
        "latitude": "46.60727000",
        "longitude": "-0.23237000"
    },
    {
        "id": 45154,
        "name": "Pompertuzat",
        "latitude": "43.49277000",
        "longitude": "1.51531000"
    },
    {
        "id": 45155,
        "name": "Pompey",
        "latitude": "48.76876000",
        "longitude": "6.12639000"
    },
    {
        "id": 45156,
        "name": "Pompignac",
        "latitude": "44.85114000",
        "longitude": "-0.43705000"
    },
    {
        "id": 45157,
        "name": "Pompignan",
        "latitude": "43.81768000",
        "longitude": "1.31209000"
    },
    {
        "id": 45158,
        "name": "Pomponne",
        "latitude": "48.88130000",
        "longitude": "2.68232000"
    },
    {
        "id": 45160,
        "name": "Ponchon",
        "latitude": "49.34652000",
        "longitude": "2.19677000"
    },
    {
        "id": 45161,
        "name": "Poncin",
        "latitude": "46.08531000",
        "longitude": "5.41120000"
    },
    {
        "id": 45162,
        "name": "Pons",
        "latitude": "45.57988000",
        "longitude": "-0.54783000"
    },
    {
        "id": 45163,
        "name": "Pont de Vivaux",
        "latitude": "43.27604000",
        "longitude": "5.41586000"
    },
    {
        "id": 45196,
        "name": "Pont-à-Marcq",
        "latitude": "50.52213000",
        "longitude": "3.11441000"
    },
    {
        "id": 45197,
        "name": "Pont-à-Mousson",
        "latitude": "48.90702000",
        "longitude": "6.05635000"
    },
    {
        "id": 45198,
        "name": "Pont-à-Vendin",
        "latitude": "50.47358000",
        "longitude": "2.88884000"
    },
    {
        "id": 45164,
        "name": "Pont-Audemer",
        "latitude": "49.35000000",
        "longitude": "0.51667000"
    },
    {
        "id": 45165,
        "name": "Pont-Aven",
        "latitude": "47.85000000",
        "longitude": "-3.75000000"
    },
    {
        "id": 45166,
        "name": "Pont-Croix",
        "latitude": "48.04088000",
        "longitude": "-4.48714000"
    },
    {
        "id": 45177,
        "name": "Pont-d'Ouilly",
        "latitude": "48.87590000",
        "longitude": "-0.40221000"
    },
    {
        "id": 45187,
        "name": "Pont-d’Ain",
        "latitude": "46.05583000",
        "longitude": "5.33974000"
    },
    {
        "id": 45178,
        "name": "Pont-de-Chéruy",
        "latitude": "45.75344000",
        "longitude": "5.17245000"
    },
    {
        "id": 45179,
        "name": "Pont-de-Larn",
        "latitude": "43.50412000",
        "longitude": "2.40786000"
    },
    {
        "id": 45180,
        "name": "Pont-de-Metz",
        "latitude": "49.87820000",
        "longitude": "2.24266000"
    },
    {
        "id": 45181,
        "name": "Pont-de-Roide",
        "latitude": "47.38738000",
        "longitude": "6.76840000"
    },
    {
        "id": 45182,
        "name": "Pont-de-Salars",
        "latitude": "44.28080000",
        "longitude": "2.72783000"
    },
    {
        "id": 45183,
        "name": "Pont-de-Vaux",
        "latitude": "46.43326000",
        "longitude": "4.94473000"
    },
    {
        "id": 45184,
        "name": "Pont-de-Veyle",
        "latitude": "46.26166000",
        "longitude": "4.88612000"
    },
    {
        "id": 45185,
        "name": "Pont-du-Casse",
        "latitude": "44.22867000",
        "longitude": "0.67924000"
    },
    {
        "id": 45186,
        "name": "Pont-du-Château",
        "latitude": "45.79830000",
        "longitude": "3.24839000"
    },
    {
        "id": 45194,
        "name": "Pont-Écrepin",
        "latitude": "48.76425000",
        "longitude": "-0.24455000"
    },
    {
        "id": 45188,
        "name": "Pont-en-Royans",
        "latitude": "45.06250000",
        "longitude": "5.34094000"
    },
    {
        "id": 45195,
        "name": "Pont-Évêque",
        "latitude": "45.53217000",
        "longitude": "4.90922000"
    },
    {
        "id": 45167,
        "name": "Pont-Hébert",
        "latitude": "49.16714000",
        "longitude": "-1.13428000"
    },
    {
        "id": 45189,
        "name": "Pont-l’Abbé",
        "latitude": "47.86667000",
        "longitude": "-4.21667000"
    },
    {
        "id": 45190,
        "name": "Pont-l’Abbé-d’Arnoult",
        "latitude": "45.82875000",
        "longitude": "-0.87499000"
    },
    {
        "id": 45191,
        "name": "Pont-l’Évêque",
        "latitude": "49.28912000",
        "longitude": "0.19161000"
    },
    {
        "id": 45168,
        "name": "Pont-Remy",
        "latitude": "50.05000000",
        "longitude": "1.91667000"
    },
    {
        "id": 45169,
        "name": "Pont-Saint-Esprit",
        "latitude": "44.25494000",
        "longitude": "4.64736000"
    },
    {
        "id": 45170,
        "name": "Pont-Saint-Martin",
        "latitude": "47.12338000",
        "longitude": "-1.58455000"
    },
    {
        "id": 45171,
        "name": "Pont-Saint-Pierre",
        "latitude": "49.33561000",
        "longitude": "1.27601000"
    },
    {
        "id": 45172,
        "name": "Pont-Saint-Vincent",
        "latitude": "48.60519000",
        "longitude": "6.09850000"
    },
    {
        "id": 45173,
        "name": "Pont-Sainte-Marie",
        "latitude": "48.31848000",
        "longitude": "4.09447000"
    },
    {
        "id": 45174,
        "name": "Pont-Sainte-Maxence",
        "latitude": "49.30168000",
        "longitude": "2.60467000"
    },
    {
        "id": 45175,
        "name": "Pont-Salomon",
        "latitude": "45.33777000",
        "longitude": "4.24781000"
    },
    {
        "id": 45176,
        "name": "Pont-Scorff",
        "latitude": "47.83343000",
        "longitude": "-3.40251000"
    },
    {
        "id": 45192,
        "name": "Pont-sur-Sambre",
        "latitude": "50.22195000",
        "longitude": "3.84693000"
    },
    {
        "id": 45193,
        "name": "Pont-sur-Yonne",
        "latitude": "48.28297000",
        "longitude": "3.20198000"
    },
    {
        "id": 45199,
        "name": "Pontacq",
        "latitude": "43.18333000",
        "longitude": "-0.11667000"
    },
    {
        "id": 45200,
        "name": "Pontailler-sur-Saône",
        "latitude": "47.30455000",
        "longitude": "5.41479000"
    },
    {
        "id": 45201,
        "name": "Pontarlier",
        "latitude": "46.90347000",
        "longitude": "6.35542000"
    },
    {
        "id": 45202,
        "name": "Pontault-Combault",
        "latitude": "48.79813000",
        "longitude": "2.60676000"
    },
    {
        "id": 45203,
        "name": "Pontcarré",
        "latitude": "48.79772000",
        "longitude": "2.70508000"
    },
    {
        "id": 45204,
        "name": "Pontcharra",
        "latitude": "45.43588000",
        "longitude": "6.01782000"
    },
    {
        "id": 45205,
        "name": "Pontcharra-sur-Turdine",
        "latitude": "45.87413000",
        "longitude": "4.48989000"
    },
    {
        "id": 45206,
        "name": "Pontchâteau",
        "latitude": "47.43797000",
        "longitude": "-2.09011000"
    },
    {
        "id": 45207,
        "name": "Ponteilla",
        "latitude": "42.62594000",
        "longitude": "2.81335000"
    },
    {
        "id": 45208,
        "name": "Pontenx-les-Forges",
        "latitude": "44.24134000",
        "longitude": "-1.12095000"
    },
    {
        "id": 45209,
        "name": "Pontfaverger-Moronvilliers",
        "latitude": "49.29702000",
        "longitude": "4.31657000"
    },
    {
        "id": 45210,
        "name": "Ponthierry",
        "latitude": "48.53366000",
        "longitude": "2.54419000"
    },
    {
        "id": 45211,
        "name": "Pontivy",
        "latitude": "48.06862000",
        "longitude": "-2.96280000"
    },
    {
        "id": 45212,
        "name": "Pontlevoy",
        "latitude": "47.39002000",
        "longitude": "1.25465000"
    },
    {
        "id": 45213,
        "name": "Pontoise",
        "latitude": "49.05000000",
        "longitude": "2.10000000"
    },
    {
        "id": 45214,
        "name": "Pontonx-sur-l'Adour",
        "latitude": "43.78783000",
        "longitude": "-0.92508000"
    },
    {
        "id": 45215,
        "name": "Pontorson",
        "latitude": "48.55316000",
        "longitude": "-1.50754000"
    },
    {
        "id": 45216,
        "name": "Pontpoint",
        "latitude": "49.30000000",
        "longitude": "2.65000000"
    },
    {
        "id": 45217,
        "name": "Pontrieux",
        "latitude": "48.70111000",
        "longitude": "-3.15967000"
    },
    {
        "id": 45218,
        "name": "Pontvallain",
        "latitude": "47.75169000",
        "longitude": "0.19145000"
    },
    {
        "id": 45219,
        "name": "Porcelette",
        "latitude": "49.15657000",
        "longitude": "6.65513000"
    },
    {
        "id": 45220,
        "name": "Porcheville",
        "latitude": "48.97254000",
        "longitude": "1.77973000"
    },
    {
        "id": 45221,
        "name": "Porcieu",
        "latitude": "45.83416000",
        "longitude": "5.40168000"
    },
    {
        "id": 45222,
        "name": "Pordic",
        "latitude": "48.57051000",
        "longitude": "-2.81783000"
    },
    {
        "id": 45223,
        "name": "Pornic",
        "latitude": "47.11205000",
        "longitude": "-2.08888000"
    },
    {
        "id": 45224,
        "name": "Pornichet",
        "latitude": "47.26682000",
        "longitude": "-2.33794000"
    },
    {
        "id": 45225,
        "name": "Porspoder",
        "latitude": "48.50000000",
        "longitude": "-4.76667000"
    },
    {
        "id": 45226,
        "name": "Port à Binson",
        "latitude": "49.08202000",
        "longitude": "3.75989000"
    },
    {
        "id": 45227,
        "name": "Port-Brillet",
        "latitude": "48.11315000",
        "longitude": "-0.97080000"
    },
    {
        "id": 45233,
        "name": "Port-de-Bouc",
        "latitude": "43.40657000",
        "longitude": "4.98090000"
    },
    {
        "id": 45234,
        "name": "Port-des-Barques",
        "latitude": "45.94763000",
        "longitude": "-1.07795000"
    },
    {
        "id": 45235,
        "name": "Port-en-Bessin-Huppain",
        "latitude": "49.34460000",
        "longitude": "-0.75557000"
    },
    {
        "id": 45236,
        "name": "Port-la-Nouvelle",
        "latitude": "43.01991000",
        "longitude": "3.04366000"
    },
    {
        "id": 45228,
        "name": "Port-Louis",
        "latitude": "47.70704000",
        "longitude": "-3.35484000"
    },
    {
        "id": 45229,
        "name": "Port-Saint-Louis-du-Rhône",
        "latitude": "43.38734000",
        "longitude": "4.82609000"
    },
    {
        "id": 45230,
        "name": "Port-Saint-Père",
        "latitude": "47.13294000",
        "longitude": "-1.74850000"
    },
    {
        "id": 45231,
        "name": "Port-Sainte-Foy-et-Ponchapt",
        "latitude": "44.83333000",
        "longitude": "0.20000000"
    },
    {
        "id": 45232,
        "name": "Port-Sainte-Marie",
        "latitude": "44.25158000",
        "longitude": "0.39134000"
    },
    {
        "id": 45237,
        "name": "Port-sur-Saône",
        "latitude": "47.68774000",
        "longitude": "6.05011000"
    },
    {
        "id": 45238,
        "name": "Portbail",
        "latitude": "49.33571000",
        "longitude": "-1.69560000"
    },
    {
        "id": 45239,
        "name": "Portel-des-Corbières",
        "latitude": "43.05000000",
        "longitude": "2.91667000"
    },
    {
        "id": 45240,
        "name": "Portes-lès-Valence",
        "latitude": "44.87585000",
        "longitude": "4.87415000"
    },
    {
        "id": 45241,
        "name": "Portet-sur-Garonne",
        "latitude": "43.52333000",
        "longitude": "1.40651000"
    },
    {
        "id": 45242,
        "name": "Portets",
        "latitude": "44.69679000",
        "longitude": "-0.42452000"
    },
    {
        "id": 45243,
        "name": "Portieux",
        "latitude": "48.34466000",
        "longitude": "6.34465000"
    },
    {
        "id": 45244,
        "name": "Portiragnes",
        "latitude": "43.30460000",
        "longitude": "3.33365000"
    },
    {
        "id": 45245,
        "name": "Porto-Vecchio",
        "latitude": "41.59101000",
        "longitude": "9.27947000"
    },
    {
        "id": 45246,
        "name": "Portvendres",
        "latitude": "42.51792000",
        "longitude": "3.10553000"
    },
    {
        "id": 45247,
        "name": "Poses",
        "latitude": "49.30539000",
        "longitude": "1.24353000"
    },
    {
        "id": 45248,
        "name": "Potigny",
        "latitude": "48.96925000",
        "longitude": "-0.24166000"
    },
    {
        "id": 45249,
        "name": "Pouancé",
        "latitude": "47.74167000",
        "longitude": "-1.17366000"
    },
    {
        "id": 45250,
        "name": "Pougues-les-Eaux",
        "latitude": "47.07518000",
        "longitude": "3.10150000"
    },
    {
        "id": 45251,
        "name": "Pouilley-les-Vignes",
        "latitude": "47.25770000",
        "longitude": "5.93581000"
    },
    {
        "id": 45252,
        "name": "Pouillon",
        "latitude": "43.60480000",
        "longitude": "-0.99947000"
    },
    {
        "id": 45253,
        "name": "Pouilly-en-Auxois",
        "latitude": "47.26238000",
        "longitude": "4.55583000"
    },
    {
        "id": 45255,
        "name": "Pouilly-lès-Feurs",
        "latitude": "45.79760000",
        "longitude": "4.23289000"
    },
    {
        "id": 45254,
        "name": "Pouilly-les-Nonains",
        "latitude": "46.04508000",
        "longitude": "3.98247000"
    },
    {
        "id": 45256,
        "name": "Pouilly-sous-Charlieu",
        "latitude": "46.14551000",
        "longitude": "4.11287000"
    },
    {
        "id": 45257,
        "name": "Pouilly-sur-Loire",
        "latitude": "47.28377000",
        "longitude": "2.95442000"
    },
    {
        "id": 45258,
        "name": "Poulainville",
        "latitude": "49.94745000",
        "longitude": "2.31373000"
    },
    {
        "id": 45259,
        "name": "Pouldergat",
        "latitude": "48.05000000",
        "longitude": "-4.33333000"
    },
    {
        "id": 45260,
        "name": "Pouldreuzic",
        "latitude": "47.95000000",
        "longitude": "-4.36667000"
    },
    {
        "id": 45261,
        "name": "Pouligny-Saint-Pierre",
        "latitude": "46.68095000",
        "longitude": "1.03877000"
    },
    {
        "id": 45262,
        "name": "Poullan-sur-Mer",
        "latitude": "48.08333000",
        "longitude": "-4.41667000"
    },
    {
        "id": 45263,
        "name": "Poullaouen",
        "latitude": "48.33333000",
        "longitude": "-3.65000000"
    },
    {
        "id": 45264,
        "name": "Poulx",
        "latitude": "43.90798000",
        "longitude": "4.41629000"
    },
    {
        "id": 45265,
        "name": "Pourrain",
        "latitude": "47.75615000",
        "longitude": "3.41193000"
    },
    {
        "id": 45266,
        "name": "Pourrières",
        "latitude": "43.50611000",
        "longitude": "5.73452000"
    },
    {
        "id": 45267,
        "name": "Pouru-Saint-Remy",
        "latitude": "49.68333000",
        "longitude": "5.08333000"
    },
    {
        "id": 45268,
        "name": "Poussan",
        "latitude": "43.48944000",
        "longitude": "3.67083000"
    },
    {
        "id": 45269,
        "name": "Pouxeux",
        "latitude": "48.10621000",
        "longitude": "6.57299000"
    },
    {
        "id": 45270,
        "name": "Pouzac",
        "latitude": "43.08606000",
        "longitude": "0.13522000"
    },
    {
        "id": 45271,
        "name": "Pouzauges",
        "latitude": "46.77941000",
        "longitude": "-0.83619000"
    },
    {
        "id": 45272,
        "name": "Prades",
        "latitude": "42.61715000",
        "longitude": "2.42208000"
    },
    {
        "id": 45273,
        "name": "Prades-le-Lez",
        "latitude": "43.69754000",
        "longitude": "3.86463000"
    },
    {
        "id": 45274,
        "name": "Pradines",
        "latitude": "44.48341000",
        "longitude": "1.40105000"
    },
    {
        "id": 45275,
        "name": "Pragoulin",
        "latitude": "46.05425000",
        "longitude": "3.39346000"
    },
    {
        "id": 45276,
        "name": "Prahecq",
        "latitude": "46.25897000",
        "longitude": "-0.34425000"
    },
    {
        "id": 45277,
        "name": "Prat",
        "latitude": "48.67677000",
        "longitude": "-3.29707000"
    },
    {
        "id": 45278,
        "name": "Prats de Molló",
        "latitude": "42.40404000",
        "longitude": "2.47928000"
    },
    {
        "id": 45279,
        "name": "Prayssac",
        "latitude": "44.50440000",
        "longitude": "1.18862000"
    },
    {
        "id": 45280,
        "name": "Praz-sur-Arly",
        "latitude": "45.83731000",
        "longitude": "6.57259000"
    },
    {
        "id": 45302,
        "name": "Pré-en-Pail",
        "latitude": "48.45993000",
        "longitude": "-0.19814000"
    },
    {
        "id": 45303,
        "name": "Préaux",
        "latitude": "49.49136000",
        "longitude": "1.21583000"
    },
    {
        "id": 45304,
        "name": "Préchac",
        "latitude": "44.39883000",
        "longitude": "-0.35387000"
    },
    {
        "id": 45305,
        "name": "Précieux",
        "latitude": "45.58702000",
        "longitude": "4.15152000"
    },
    {
        "id": 45306,
        "name": "Précigné",
        "latitude": "47.76692000",
        "longitude": "-0.32491000"
    },
    {
        "id": 45307,
        "name": "Précy-sur-Oise",
        "latitude": "49.20779000",
        "longitude": "2.37266000"
    },
    {
        "id": 45308,
        "name": "Préfailles",
        "latitude": "47.12839000",
        "longitude": "-2.21693000"
    },
    {
        "id": 45281,
        "name": "Preignac",
        "latitude": "44.58438000",
        "longitude": "-0.29423000"
    },
    {
        "id": 45282,
        "name": "Preignan",
        "latitude": "43.71177000",
        "longitude": "0.62608000"
    },
    {
        "id": 45309,
        "name": "Prémery",
        "latitude": "47.16952000",
        "longitude": "3.32743000"
    },
    {
        "id": 45310,
        "name": "Prémesques",
        "latitude": "50.65572000",
        "longitude": "2.95162000"
    },
    {
        "id": 45311,
        "name": "Prémilhat",
        "latitude": "46.31341000",
        "longitude": "2.53559000"
    },
    {
        "id": 45312,
        "name": "Préseau",
        "latitude": "50.31122000",
        "longitude": "3.57434000"
    },
    {
        "id": 45283,
        "name": "Presles-en-Brie",
        "latitude": "48.71527000",
        "longitude": "2.74112000"
    },
    {
        "id": 45284,
        "name": "Preuilly-sur-Claise",
        "latitude": "46.85424000",
        "longitude": "0.92954000"
    },
    {
        "id": 45285,
        "name": "Priay",
        "latitude": "46.00183000",
        "longitude": "5.28684000"
    },
    {
        "id": 45286,
        "name": "Prigonrieux",
        "latitude": "44.85451000",
        "longitude": "0.40275000"
    },
    {
        "id": 45287,
        "name": "Pringy",
        "latitude": "45.94622000",
        "longitude": "6.12608000"
    },
    {
        "id": 45288,
        "name": "Pringy",
        "latitude": "48.51815000",
        "longitude": "2.56333000"
    },
    {
        "id": 45289,
        "name": "Prinquiau",
        "latitude": "47.36215000",
        "longitude": "-2.00952000"
    },
    {
        "id": 45290,
        "name": "Prissé",
        "latitude": "46.32130000",
        "longitude": "4.74469000"
    },
    {
        "id": 45291,
        "name": "Privas",
        "latitude": "44.73500000",
        "longitude": "4.59918000"
    },
    {
        "id": 45292,
        "name": "Prix-lès-Mézières",
        "latitude": "49.75549000",
        "longitude": "4.69113000"
    },
    {
        "id": 45293,
        "name": "Priziac",
        "latitude": "48.06667000",
        "longitude": "-3.41667000"
    },
    {
        "id": 45294,
        "name": "Propriano",
        "latitude": "41.67590000",
        "longitude": "8.90412000"
    },
    {
        "id": 45295,
        "name": "Prouvy",
        "latitude": "50.31793000",
        "longitude": "3.45096000"
    },
    {
        "id": 45296,
        "name": "Proville",
        "latitude": "50.16137000",
        "longitude": "3.20629000"
    },
    {
        "id": 45297,
        "name": "Provin",
        "latitude": "50.51430000",
        "longitude": "2.90794000"
    },
    {
        "id": 45298,
        "name": "Provins",
        "latitude": "48.55897000",
        "longitude": "3.29939000"
    },
    {
        "id": 45299,
        "name": "Pruillé-le-Chétif",
        "latitude": "47.99383000",
        "longitude": "0.10677000"
    },
    {
        "id": 45300,
        "name": "Prunelli-di-Fiumorbo",
        "latitude": "42.01047000",
        "longitude": "9.32473000"
    },
    {
        "id": 45301,
        "name": "Pruniers-en-Sologne",
        "latitude": "47.31667000",
        "longitude": "1.66667000"
    },
    {
        "id": 45313,
        "name": "Publier",
        "latitude": "46.38773000",
        "longitude": "6.54356000"
    },
    {
        "id": 45316,
        "name": "Puget-sur-Argens",
        "latitude": "43.45576000",
        "longitude": "6.68519000"
    },
    {
        "id": 45314,
        "name": "Puget-Théniers",
        "latitude": "43.95598000",
        "longitude": "6.89378000"
    },
    {
        "id": 45315,
        "name": "Puget-Ville",
        "latitude": "43.28958000",
        "longitude": "6.13612000"
    },
    {
        "id": 45317,
        "name": "Pugnac",
        "latitude": "45.08190000",
        "longitude": "-0.49618000"
    },
    {
        "id": 45318,
        "name": "Puicheric",
        "latitude": "43.22380000",
        "longitude": "2.62455000"
    },
    {
        "id": 45319,
        "name": "Puilboreau",
        "latitude": "46.18620000",
        "longitude": "-1.11797000"
    },
    {
        "id": 45320,
        "name": "Puiseaux",
        "latitude": "48.09234000",
        "longitude": "2.05967000"
    },
    {
        "id": 45321,
        "name": "Puiseux-en-France",
        "latitude": "49.05552000",
        "longitude": "2.50035000"
    },
    {
        "id": 45322,
        "name": "Puissalicon",
        "latitude": "43.45828000",
        "longitude": "3.23690000"
    },
    {
        "id": 45323,
        "name": "Puisserguier",
        "latitude": "43.36767000",
        "longitude": "3.04047000"
    },
    {
        "id": 45324,
        "name": "Pujaudran",
        "latitude": "43.58954000",
        "longitude": "1.14977000"
    },
    {
        "id": 45325,
        "name": "Pujaut",
        "latitude": "44.00404000",
        "longitude": "4.77575000"
    },
    {
        "id": 45326,
        "name": "Pujols",
        "latitude": "44.39424000",
        "longitude": "0.68810000"
    },
    {
        "id": 45327,
        "name": "Pulligny",
        "latitude": "48.53893000",
        "longitude": "6.14426000"
    },
    {
        "id": 45328,
        "name": "Pulnoy",
        "latitude": "48.70343000",
        "longitude": "6.25765000"
    },
    {
        "id": 45329,
        "name": "Pulversheim",
        "latitude": "47.83753000",
        "longitude": "7.30119000"
    },
    {
        "id": 45330,
        "name": "Pusey",
        "latitude": "47.65243000",
        "longitude": "6.12615000"
    },
    {
        "id": 45331,
        "name": "Pusignan",
        "latitude": "45.75558000",
        "longitude": "5.06722000"
    },
    {
        "id": 45332,
        "name": "Pussay",
        "latitude": "48.34908000",
        "longitude": "1.99182000"
    },
    {
        "id": 45333,
        "name": "Putanges-Pont-Écrepin",
        "latitude": "48.76667000",
        "longitude": "-0.25000000"
    },
    {
        "id": 45334,
        "name": "Puteaux",
        "latitude": "48.88341000",
        "longitude": "2.23894000"
    },
    {
        "id": 45335,
        "name": "Puy-Guillaume",
        "latitude": "45.96036000",
        "longitude": "3.47405000"
    },
    {
        "id": 45336,
        "name": "Puy-l’Évêque",
        "latitude": "44.50483000",
        "longitude": "1.14015000"
    },
    {
        "id": 45337,
        "name": "Puygouzon",
        "latitude": "43.90000000",
        "longitude": "2.16667000"
    },
    {
        "id": 45338,
        "name": "Puylaurens",
        "latitude": "43.57202000",
        "longitude": "2.01227000"
    },
    {
        "id": 45339,
        "name": "Puyloubier",
        "latitude": "43.52522000",
        "longitude": "5.67650000"
    },
    {
        "id": 45340,
        "name": "Puymoyen",
        "latitude": "45.61667000",
        "longitude": "0.18333000"
    },
    {
        "id": 45341,
        "name": "Puyoô",
        "latitude": "43.52502000",
        "longitude": "-0.91283000"
    },
    {
        "id": 45371,
        "name": "Quaëdypre",
        "latitude": "50.93527000",
        "longitude": "2.45445000"
    },
    {
        "id": 45369,
        "name": "Quarante",
        "latitude": "43.34711000",
        "longitude": "2.96228000"
    },
    {
        "id": 45370,
        "name": "Quarouble",
        "latitude": "50.38634000",
        "longitude": "3.62306000"
    },
    {
        "id": 45406,
        "name": "Québriac",
        "latitude": "48.34444000",
        "longitude": "-1.82723000"
    },
    {
        "id": 45407,
        "name": "Quédillac",
        "latitude": "48.24904000",
        "longitude": "-2.14241000"
    },
    {
        "id": 45372,
        "name": "Quelaines-Saint-Gault",
        "latitude": "47.93333000",
        "longitude": "-0.80000000"
    },
    {
        "id": 45408,
        "name": "Quéménéven",
        "latitude": "48.11667000",
        "longitude": "-4.11667000"
    },
    {
        "id": 45373,
        "name": "Quemper-Guézennec",
        "latitude": "48.70378000",
        "longitude": "-3.10522000"
    },
    {
        "id": 45374,
        "name": "Quend",
        "latitude": "50.31584000",
        "longitude": "1.63609000"
    },
    {
        "id": 45375,
        "name": "Querqueville",
        "latitude": "49.66251000",
        "longitude": "-1.69119000"
    },
    {
        "id": 45376,
        "name": "Querrien",
        "latitude": "47.96667000",
        "longitude": "-3.53333000"
    },
    {
        "id": 45377,
        "name": "Quesnoy-sur-Deûle",
        "latitude": "50.71359000",
        "longitude": "2.99996000"
    },
    {
        "id": 45378,
        "name": "Quessoy",
        "latitude": "48.42208000",
        "longitude": "-2.66089000"
    },
    {
        "id": 45379,
        "name": "Questembert",
        "latitude": "47.66296000",
        "longitude": "-2.45220000"
    },
    {
        "id": 45380,
        "name": "Quetigny",
        "latitude": "47.31667000",
        "longitude": "5.11667000"
    },
    {
        "id": 45381,
        "name": "Quettehou",
        "latitude": "49.59308000",
        "longitude": "-1.30352000"
    },
    {
        "id": 45382,
        "name": "Quettreville-sur-Sienne",
        "latitude": "48.96802000",
        "longitude": "-1.46782000"
    },
    {
        "id": 45383,
        "name": "Quevauvillers",
        "latitude": "49.82376000",
        "longitude": "2.08431000"
    },
    {
        "id": 45409,
        "name": "Quéven",
        "latitude": "47.78333000",
        "longitude": "-3.41667000"
    },
    {
        "id": 45410,
        "name": "Quévert",
        "latitude": "48.46402000",
        "longitude": "-2.08745000"
    },
    {
        "id": 45384,
        "name": "Quiberon",
        "latitude": "47.48412000",
        "longitude": "-3.11963000"
    },
    {
        "id": 45385,
        "name": "Quiers-sur-Bézonde",
        "latitude": "47.99615000",
        "longitude": "2.44141000"
    },
    {
        "id": 45404,
        "name": "Quiévrechain",
        "latitude": "50.39172000",
        "longitude": "3.66815000"
    },
    {
        "id": 45405,
        "name": "Quiévy",
        "latitude": "50.16804000",
        "longitude": "3.42834000"
    },
    {
        "id": 45386,
        "name": "Quillan",
        "latitude": "42.87579000",
        "longitude": "2.18176000"
    },
    {
        "id": 45387,
        "name": "Quillebeuf-sur-Seine",
        "latitude": "49.46934000",
        "longitude": "0.52793000"
    },
    {
        "id": 45388,
        "name": "Quilly",
        "latitude": "47.46131000",
        "longitude": "-1.95274000"
    },
    {
        "id": 45389,
        "name": "Quimper",
        "latitude": "48.00000000",
        "longitude": "-4.10000000"
    },
    {
        "id": 45390,
        "name": "Quimperlé",
        "latitude": "47.86667000",
        "longitude": "-3.55000000"
    },
    {
        "id": 45391,
        "name": "Quincampoix",
        "latitude": "49.52710000",
        "longitude": "1.18849000"
    },
    {
        "id": 45392,
        "name": "Quincey",
        "latitude": "47.61165000",
        "longitude": "6.18556000"
    },
    {
        "id": 45394,
        "name": "Quincié-en-Beaujolais",
        "latitude": "46.11898000",
        "longitude": "4.61635000"
    },
    {
        "id": 45393,
        "name": "Quincieux",
        "latitude": "45.91374000",
        "longitude": "4.77698000"
    },
    {
        "id": 45396,
        "name": "Quincy-sous-Sénart",
        "latitude": "48.67294000",
        "longitude": "2.53419000"
    },
    {
        "id": 45395,
        "name": "Quincy-Voisins",
        "latitude": "48.90114000",
        "longitude": "2.87559000"
    },
    {
        "id": 45397,
        "name": "Quingey",
        "latitude": "47.10250000",
        "longitude": "5.88312000"
    },
    {
        "id": 45398,
        "name": "Quinsac",
        "latitude": "44.75535000",
        "longitude": "-0.48697000"
    },
    {
        "id": 45399,
        "name": "Quinssaines",
        "latitude": "46.32721000",
        "longitude": "2.51040000"
    },
    {
        "id": 45400,
        "name": "Quintenas",
        "latitude": "45.18892000",
        "longitude": "4.68743000"
    },
    {
        "id": 45401,
        "name": "Quintin",
        "latitude": "48.40342000",
        "longitude": "-2.91050000"
    },
    {
        "id": 45402,
        "name": "Quissac",
        "latitude": "43.91341000",
        "longitude": "3.99920000"
    },
    {
        "id": 45403,
        "name": "Quistinic",
        "latitude": "47.90410000",
        "longitude": "-3.13400000"
    },
    {
        "id": 45411,
        "name": "Rabastens",
        "latitude": "43.82512000",
        "longitude": "1.72382000"
    },
    {
        "id": 45412,
        "name": "Rabastens-de-Bigorre",
        "latitude": "43.38769000",
        "longitude": "0.15122000"
    },
    {
        "id": 45682,
        "name": "Râches",
        "latitude": "50.41706000",
        "longitude": "3.13635000"
    },
    {
        "id": 45413,
        "name": "Racquinghem",
        "latitude": "50.69245000",
        "longitude": "2.35736000"
    },
    {
        "id": 45414,
        "name": "Radinghem-en-Weppes",
        "latitude": "50.61884000",
        "longitude": "2.90924000"
    },
    {
        "id": 45415,
        "name": "Radon",
        "latitude": "48.50246000",
        "longitude": "0.10278000"
    },
    {
        "id": 45416,
        "name": "Raedersheim",
        "latitude": "47.88895000",
        "longitude": "7.28222000"
    },
    {
        "id": 45417,
        "name": "Rai",
        "latitude": "48.75000000",
        "longitude": "0.58333000"
    },
    {
        "id": 45418,
        "name": "Raillencourt-Sainte-Olle",
        "latitude": "50.18333000",
        "longitude": "3.16667000"
    },
    {
        "id": 45419,
        "name": "Raimbeaucourt",
        "latitude": "50.43466000",
        "longitude": "3.09333000"
    },
    {
        "id": 45420,
        "name": "Raismes",
        "latitude": "50.38333000",
        "longitude": "3.48333000"
    },
    {
        "id": 45421,
        "name": "Ramatuelle",
        "latitude": "43.21599000",
        "longitude": "6.61156000"
    },
    {
        "id": 45422,
        "name": "Rambervillers",
        "latitude": "48.34240000",
        "longitude": "6.63580000"
    },
    {
        "id": 45423,
        "name": "Rambouillet",
        "latitude": "48.64374000",
        "longitude": "1.82992000"
    },
    {
        "id": 45424,
        "name": "Ramonchamp",
        "latitude": "47.89429000",
        "longitude": "6.74171000"
    },
    {
        "id": 45425,
        "name": "Ramonville-Saint-Agne",
        "latitude": "43.54618000",
        "longitude": "1.47491000"
    },
    {
        "id": 45426,
        "name": "Ranchicourt",
        "latitude": "50.43559000",
        "longitude": "2.55690000"
    },
    {
        "id": 45427,
        "name": "Randan",
        "latitude": "46.01767000",
        "longitude": "3.35478000"
    },
    {
        "id": 45428,
        "name": "Rang-du-Fliers",
        "latitude": "50.41708000",
        "longitude": "1.64431000"
    },
    {
        "id": 45429,
        "name": "Rannée",
        "latitude": "47.92383000",
        "longitude": "-1.24124000"
    },
    {
        "id": 45430,
        "name": "Rantigny",
        "latitude": "49.32758000",
        "longitude": "2.44244000"
    },
    {
        "id": 45431,
        "name": "Ranville",
        "latitude": "49.23071000",
        "longitude": "-0.25560000"
    },
    {
        "id": 45432,
        "name": "Raon-aux-Bois",
        "latitude": "48.05686000",
        "longitude": "6.52098000"
    },
    {
        "id": 45433,
        "name": "Rauzan",
        "latitude": "44.77838000",
        "longitude": "-0.12465000"
    },
    {
        "id": 45434,
        "name": "Ravenel",
        "latitude": "49.51523000",
        "longitude": "2.50192000"
    },
    {
        "id": 45435,
        "name": "Ravières",
        "latitude": "47.73475000",
        "longitude": "4.22777000"
    },
    {
        "id": 45436,
        "name": "Razac-sur-l’Isle",
        "latitude": "45.16332000",
        "longitude": "0.60085000"
    },
    {
        "id": 45437,
        "name": "Razès",
        "latitude": "46.03219000",
        "longitude": "1.33676000"
    },
    {
        "id": 45683,
        "name": "Réalmont",
        "latitude": "43.77754000",
        "longitude": "2.18885000"
    },
    {
        "id": 45684,
        "name": "Réalville",
        "latitude": "44.11452000",
        "longitude": "1.47998000"
    },
    {
        "id": 45439,
        "name": "Rebais",
        "latitude": "48.84721000",
        "longitude": "3.23232000"
    },
    {
        "id": 45440,
        "name": "Rebréchien",
        "latitude": "47.98727000",
        "longitude": "2.04432000"
    },
    {
        "id": 45441,
        "name": "Recquignies",
        "latitude": "50.28396000",
        "longitude": "4.03722000"
    },
    {
        "id": 45685,
        "name": "Rédené",
        "latitude": "47.85000000",
        "longitude": "-3.46667000"
    },
    {
        "id": 45442,
        "name": "Redessan",
        "latitude": "43.83117000",
        "longitude": "4.49771000"
    },
    {
        "id": 45686,
        "name": "Réding",
        "latitude": "48.75177000",
        "longitude": "7.10747000"
    },
    {
        "id": 45443,
        "name": "Redon",
        "latitude": "47.65141000",
        "longitude": "-2.08481000"
    },
    {
        "id": 45687,
        "name": "Régny",
        "latitude": "45.99087000",
        "longitude": "4.21655000"
    },
    {
        "id": 45688,
        "name": "Réguiny",
        "latitude": "47.97649000",
        "longitude": "-2.74478000"
    },
    {
        "id": 45689,
        "name": "Réguisheim",
        "latitude": "47.89563000",
        "longitude": "7.35288000"
    },
    {
        "id": 45690,
        "name": "Régusse",
        "latitude": "43.65540000",
        "longitude": "6.13186000"
    },
    {
        "id": 45691,
        "name": "Réhon",
        "latitude": "49.49878000",
        "longitude": "5.75497000"
    },
    {
        "id": 45444,
        "name": "Reichshoffen",
        "latitude": "48.93287000",
        "longitude": "7.66561000"
    },
    {
        "id": 45445,
        "name": "Reichstett",
        "latitude": "48.64856000",
        "longitude": "7.75455000"
    },
    {
        "id": 45446,
        "name": "Reignac",
        "latitude": "45.23393000",
        "longitude": "-0.50627000"
    },
    {
        "id": 45447,
        "name": "Reignac-sur-Indre",
        "latitude": "47.22662000",
        "longitude": "0.91587000"
    },
    {
        "id": 45448,
        "name": "Reignier-Ésery",
        "latitude": "46.13333000",
        "longitude": "6.26667000"
    },
    {
        "id": 45449,
        "name": "Reillanne",
        "latitude": "43.87899000",
        "longitude": "5.65919000"
    },
    {
        "id": 45450,
        "name": "Reims",
        "latitude": "49.26526000",
        "longitude": "4.02853000"
    },
    {
        "id": 45451,
        "name": "Reiningue",
        "latitude": "47.75215000",
        "longitude": "7.23327000"
    },
    {
        "id": 45692,
        "name": "Rémalard",
        "latitude": "48.42867000",
        "longitude": "0.77185000"
    },
    {
        "id": 45693,
        "name": "Rémelfing",
        "latitude": "49.08947000",
        "longitude": "7.08820000"
    },
    {
        "id": 45694,
        "name": "Rémilly",
        "latitude": "49.01359000",
        "longitude": "6.39219000"
    },
    {
        "id": 45452,
        "name": "Remiremont",
        "latitude": "48.01754000",
        "longitude": "6.58820000"
    },
    {
        "id": 45453,
        "name": "Remouillé",
        "latitude": "47.05587000",
        "longitude": "-1.37682000"
    },
    {
        "id": 45454,
        "name": "Remoulins",
        "latitude": "43.93949000",
        "longitude": "4.56799000"
    },
    {
        "id": 45455,
        "name": "Remy",
        "latitude": "49.43333000",
        "longitude": "2.71667000"
    },
    {
        "id": 45456,
        "name": "Renage",
        "latitude": "45.33346000",
        "longitude": "5.48881000"
    },
    {
        "id": 45457,
        "name": "Renaison",
        "latitude": "46.04955000",
        "longitude": "3.92440000"
    },
    {
        "id": 45458,
        "name": "Renazé",
        "latitude": "47.79340000",
        "longitude": "-1.05437000"
    },
    {
        "id": 45459,
        "name": "Renescure",
        "latitude": "50.72754000",
        "longitude": "2.36801000"
    },
    {
        "id": 45460,
        "name": "Rennes",
        "latitude": "48.11198000",
        "longitude": "-1.67429000"
    },
    {
        "id": 45461,
        "name": "Renwez",
        "latitude": "49.83839000",
        "longitude": "4.60334000"
    },
    {
        "id": 45462,
        "name": "Replonges",
        "latitude": "46.30098000",
        "longitude": "4.88317000"
    },
    {
        "id": 45463,
        "name": "Requeil",
        "latitude": "47.78371000",
        "longitude": "0.16105000"
    },
    {
        "id": 45695,
        "name": "Réquista",
        "latitude": "44.03325000",
        "longitude": "2.53554000"
    },
    {
        "id": 45464,
        "name": "Ressons-sur-Matz",
        "latitude": "49.53858000",
        "longitude": "2.74416000"
    },
    {
        "id": 45465,
        "name": "Restigné",
        "latitude": "47.28224000",
        "longitude": "0.22788000"
    },
    {
        "id": 45466,
        "name": "Restinclières",
        "latitude": "43.72252000",
        "longitude": "4.03476000"
    },
    {
        "id": 45467,
        "name": "Rethel",
        "latitude": "49.50979000",
        "longitude": "4.36740000"
    },
    {
        "id": 45468,
        "name": "Retiers",
        "latitude": "47.91392000",
        "longitude": "-1.37967000"
    },
    {
        "id": 45469,
        "name": "Retonfey",
        "latitude": "49.13648000",
        "longitude": "6.30634000"
    },
    {
        "id": 45470,
        "name": "Retournac",
        "latitude": "45.20390000",
        "longitude": "4.03330000"
    },
    {
        "id": 45471,
        "name": "Rety",
        "latitude": "50.80000000",
        "longitude": "1.76667000"
    },
    {
        "id": 45472,
        "name": "Reugny",
        "latitude": "47.48208000",
        "longitude": "0.88468000"
    },
    {
        "id": 45473,
        "name": "Reuilly",
        "latitude": "47.08476000",
        "longitude": "2.04305000"
    },
    {
        "id": 45474,
        "name": "Revel",
        "latitude": "45.18618000",
        "longitude": "5.87208000"
    },
    {
        "id": 45475,
        "name": "Revel",
        "latitude": "43.45812000",
        "longitude": "2.00469000"
    },
    {
        "id": 45476,
        "name": "Reventin-Vaugris",
        "latitude": "45.46638000",
        "longitude": "4.84244000"
    },
    {
        "id": 45477,
        "name": "Revigny-sur-Ornain",
        "latitude": "48.83016000",
        "longitude": "4.98558000"
    },
    {
        "id": 45696,
        "name": "Réville",
        "latitude": "49.61906000",
        "longitude": "-1.25868000"
    },
    {
        "id": 45478,
        "name": "Revin",
        "latitude": "49.94039000",
        "longitude": "4.63503000"
    },
    {
        "id": 45479,
        "name": "Rexpoëde",
        "latitude": "50.93926000",
        "longitude": "2.53914000"
    },
    {
        "id": 45480,
        "name": "Reynès",
        "latitude": "42.47702000",
        "longitude": "2.70680000"
    },
    {
        "id": 45481,
        "name": "Reyrieux",
        "latitude": "45.93577000",
        "longitude": "4.81453000"
    },
    {
        "id": 45482,
        "name": "Rezé",
        "latitude": "47.18144000",
        "longitude": "-1.54965000"
    },
    {
        "id": 45483,
        "name": "Rhinau",
        "latitude": "48.32017000",
        "longitude": "7.70527000"
    },
    {
        "id": 45484,
        "name": "Ria-Sirach",
        "latitude": "42.61667000",
        "longitude": "2.40000000"
    },
    {
        "id": 45485,
        "name": "Riaillé",
        "latitude": "47.51797000",
        "longitude": "-1.29404000"
    },
    {
        "id": 45486,
        "name": "Rians",
        "latitude": "43.60691000",
        "longitude": "5.75696000"
    },
    {
        "id": 45487,
        "name": "Rians",
        "latitude": "47.17875000",
        "longitude": "2.61359000"
    },
    {
        "id": 45488,
        "name": "Riantec",
        "latitude": "47.71093000",
        "longitude": "-3.31629000"
    },
    {
        "id": 45489,
        "name": "Ribaute-les-Tavernes",
        "latitude": "44.03724000",
        "longitude": "4.08046000"
    },
    {
        "id": 45490,
        "name": "Ribeauvillé",
        "latitude": "48.19510000",
        "longitude": "7.31938000"
    },
    {
        "id": 45491,
        "name": "Ribemont",
        "latitude": "49.79540000",
        "longitude": "3.45893000"
    },
    {
        "id": 45492,
        "name": "Ribérac",
        "latitude": "45.25000000",
        "longitude": "0.33333000"
    },
    {
        "id": 45493,
        "name": "Richardménil",
        "latitude": "48.59334000",
        "longitude": "6.16917000"
    },
    {
        "id": 45494,
        "name": "Richebourg",
        "latitude": "50.58333000",
        "longitude": "2.73333000"
    },
    {
        "id": 45495,
        "name": "Richebourg-l'Avoué",
        "latitude": "50.56999000",
        "longitude": "2.74884000"
    },
    {
        "id": 45496,
        "name": "Richelieu",
        "latitude": "47.01389000",
        "longitude": "0.32406000"
    },
    {
        "id": 45497,
        "name": "Richemont",
        "latitude": "49.27723000",
        "longitude": "6.16372000"
    },
    {
        "id": 45498,
        "name": "Richwiller",
        "latitude": "47.77995000",
        "longitude": "7.28067000"
    },
    {
        "id": 45499,
        "name": "Riec-sur-Belon",
        "latitude": "47.83333000",
        "longitude": "-3.70000000"
    },
    {
        "id": 45500,
        "name": "Riedisheim",
        "latitude": "47.74847000",
        "longitude": "7.36716000"
    },
    {
        "id": 45501,
        "name": "Rieulay",
        "latitude": "50.37856000",
        "longitude": "3.25284000"
    },
    {
        "id": 45502,
        "name": "Rieumes",
        "latitude": "43.41143000",
        "longitude": "1.11702000"
    },
    {
        "id": 45503,
        "name": "Rieupeyroux",
        "latitude": "44.30799000",
        "longitude": "2.23819000"
    },
    {
        "id": 45504,
        "name": "Rieux",
        "latitude": "47.59781000",
        "longitude": "-2.10828000"
    },
    {
        "id": 45505,
        "name": "Rieux",
        "latitude": "49.30000000",
        "longitude": "2.51667000"
    },
    {
        "id": 45508,
        "name": "Rieux-en-Cambrésis",
        "latitude": "50.20069000",
        "longitude": "3.35232000"
    },
    {
        "id": 45506,
        "name": "Rieux-Minervois",
        "latitude": "43.28194000",
        "longitude": "2.58687000"
    },
    {
        "id": 45507,
        "name": "Rieux-Volvestre",
        "latitude": "43.25000000",
        "longitude": "1.20000000"
    },
    {
        "id": 45509,
        "name": "Riez",
        "latitude": "43.81810000",
        "longitude": "6.09268000"
    },
    {
        "id": 45510,
        "name": "Rignac",
        "latitude": "44.40853000",
        "longitude": "2.29001000"
    },
    {
        "id": 45511,
        "name": "Rilhac-Rancon",
        "latitude": "45.90000000",
        "longitude": "1.31667000"
    },
    {
        "id": 45512,
        "name": "Rillieux-la-Pape",
        "latitude": "45.81667000",
        "longitude": "4.90000000"
    },
    {
        "id": 45513,
        "name": "Rilly-la-Montagne",
        "latitude": "49.16553000",
        "longitude": "4.04568000"
    },
    {
        "id": 45514,
        "name": "Rimogne",
        "latitude": "49.84075000",
        "longitude": "4.53941000"
    },
    {
        "id": 45515,
        "name": "Rinxent",
        "latitude": "50.80714000",
        "longitude": "1.72899000"
    },
    {
        "id": 45516,
        "name": "Riom",
        "latitude": "45.89362000",
        "longitude": "3.11264000"
    },
    {
        "id": 45517,
        "name": "Riom-ès-Montagnes",
        "latitude": "45.28206000",
        "longitude": "2.65973000"
    },
    {
        "id": 45518,
        "name": "Rion-des-Landes",
        "latitude": "43.93449000",
        "longitude": "-0.92388000"
    },
    {
        "id": 45519,
        "name": "Rions",
        "latitude": "44.66378000",
        "longitude": "-0.35113000"
    },
    {
        "id": 45520,
        "name": "Riorges",
        "latitude": "46.04378000",
        "longitude": "4.04255000"
    },
    {
        "id": 45521,
        "name": "Riotord",
        "latitude": "45.23159000",
        "longitude": "4.40208000"
    },
    {
        "id": 45522,
        "name": "Rioz",
        "latitude": "47.42521000",
        "longitude": "6.06598000"
    },
    {
        "id": 45523,
        "name": "Riquewihr",
        "latitude": "48.16672000",
        "longitude": "7.29707000"
    },
    {
        "id": 45524,
        "name": "Ris-Orangis",
        "latitude": "48.65110000",
        "longitude": "2.41406000"
    },
    {
        "id": 45525,
        "name": "Riscle",
        "latitude": "43.65633000",
        "longitude": "-0.08607000"
    },
    {
        "id": 45526,
        "name": "Rive-de-Gier",
        "latitude": "45.52755000",
        "longitude": "4.61589000"
    },
    {
        "id": 45527,
        "name": "Rivedoux-Plage",
        "latitude": "46.15854000",
        "longitude": "-1.27093000"
    },
    {
        "id": 45528,
        "name": "Rivery",
        "latitude": "49.90328000",
        "longitude": "2.32235000"
    },
    {
        "id": 45529,
        "name": "Rives",
        "latitude": "45.35326000",
        "longitude": "5.50084000"
    },
    {
        "id": 45530,
        "name": "Rivesaltes",
        "latitude": "42.76945000",
        "longitude": "2.87239000"
    },
    {
        "id": 45531,
        "name": "Rivière",
        "latitude": "50.23316000",
        "longitude": "2.69200000"
    },
    {
        "id": 45532,
        "name": "Rivière-Saas-et-Gourby",
        "latitude": "43.67990000",
        "longitude": "-1.14986000"
    },
    {
        "id": 45533,
        "name": "Rivières",
        "latitude": "45.75295000",
        "longitude": "0.36128000"
    },
    {
        "id": 45534,
        "name": "Rixheim",
        "latitude": "47.74970000",
        "longitude": "7.40203000"
    },
    {
        "id": 45535,
        "name": "Roanne",
        "latitude": "46.03624000",
        "longitude": "4.06802000"
    },
    {
        "id": 45536,
        "name": "Robecq",
        "latitude": "50.59608000",
        "longitude": "2.56262000"
    },
    {
        "id": 45537,
        "name": "Robion",
        "latitude": "43.84697000",
        "longitude": "5.11017000"
    },
    {
        "id": 45538,
        "name": "Rocbaron",
        "latitude": "43.30904000",
        "longitude": "6.07941000"
    },
    {
        "id": 45539,
        "name": "Roche",
        "latitude": "45.58723000",
        "longitude": "5.16223000"
    },
    {
        "id": 45540,
        "name": "Roche-la-Molière",
        "latitude": "45.43088000",
        "longitude": "4.32820000"
    },
    {
        "id": 45541,
        "name": "Roche-lez-Beaupré",
        "latitude": "47.28333000",
        "longitude": "6.11667000"
    },
    {
        "id": 45542,
        "name": "Rochechouart",
        "latitude": "45.82290000",
        "longitude": "0.82080000"
    },
    {
        "id": 45543,
        "name": "Rochecorbon",
        "latitude": "47.41658000",
        "longitude": "0.75521000"
    },
    {
        "id": 45544,
        "name": "Rochefort",
        "latitude": "45.94204000",
        "longitude": "-0.96696000"
    },
    {
        "id": 45545,
        "name": "Rochefort-du-Gard",
        "latitude": "43.97652000",
        "longitude": "4.69023000"
    },
    {
        "id": 45546,
        "name": "Rochefort-sur-Loire",
        "latitude": "47.35705000",
        "longitude": "-0.65696000"
    },
    {
        "id": 45547,
        "name": "Rochegude",
        "latitude": "44.24414000",
        "longitude": "4.82719000"
    },
    {
        "id": 45548,
        "name": "Rochemaure",
        "latitude": "44.58747000",
        "longitude": "4.70391000"
    },
    {
        "id": 45549,
        "name": "Roches-Prémarie-Andillé",
        "latitude": "46.48251000",
        "longitude": "0.37106000"
    },
    {
        "id": 45550,
        "name": "Rocheservière",
        "latitude": "46.93765000",
        "longitude": "-1.51143000"
    },
    {
        "id": 45551,
        "name": "Rochetaillée-sur-Saône",
        "latitude": "45.85000000",
        "longitude": "4.85000000"
    },
    {
        "id": 45552,
        "name": "Rocquencourt",
        "latitude": "48.83783000",
        "longitude": "2.10228000"
    },
    {
        "id": 45553,
        "name": "Rocroi",
        "latitude": "49.92610000",
        "longitude": "4.52220000"
    },
    {
        "id": 45554,
        "name": "Rodez",
        "latitude": "44.35258000",
        "longitude": "2.57338000"
    },
    {
        "id": 45555,
        "name": "Rodilhan",
        "latitude": "43.82725000",
        "longitude": "4.43088000"
    },
    {
        "id": 45556,
        "name": "Roeschwoog",
        "latitude": "48.82676000",
        "longitude": "8.03684000"
    },
    {
        "id": 45697,
        "name": "Rœulx",
        "latitude": "50.30000000",
        "longitude": "3.33333000"
    },
    {
        "id": 45698,
        "name": "Rœux",
        "latitude": "50.29526000",
        "longitude": "2.90073000"
    },
    {
        "id": 45656,
        "name": "Roézé-sur-Sarthe",
        "latitude": "47.89546000",
        "longitude": "0.06723000"
    },
    {
        "id": 45557,
        "name": "Rogerville",
        "latitude": "49.50296000",
        "longitude": "0.26516000"
    },
    {
        "id": 45558,
        "name": "Rognac",
        "latitude": "43.48761000",
        "longitude": "5.23387000"
    },
    {
        "id": 45559,
        "name": "Rognes",
        "latitude": "43.66378000",
        "longitude": "5.34733000"
    },
    {
        "id": 45560,
        "name": "Rognonas",
        "latitude": "43.89993000",
        "longitude": "4.80369000"
    },
    {
        "id": 45561,
        "name": "Rohan",
        "latitude": "48.06833000",
        "longitude": "-2.75120000"
    },
    {
        "id": 45562,
        "name": "Rohrbach-lès-Bitche",
        "latitude": "49.04424000",
        "longitude": "7.26759000"
    },
    {
        "id": 45563,
        "name": "Roiffieux",
        "latitude": "45.22655000",
        "longitude": "4.65441000"
    },
    {
        "id": 45564,
        "name": "Roisel",
        "latitude": "49.94768000",
        "longitude": "3.09973000"
    },
    {
        "id": 45565,
        "name": "Roissy-en-Brie",
        "latitude": "48.79159000",
        "longitude": "2.64747000"
    },
    {
        "id": 45566,
        "name": "Roissy-en-France",
        "latitude": "49.00443000",
        "longitude": "2.51703000"
    },
    {
        "id": 45567,
        "name": "Rolampont",
        "latitude": "47.94951000",
        "longitude": "5.28560000"
    },
    {
        "id": 45568,
        "name": "Rolleville",
        "latitude": "49.58232000",
        "longitude": "0.21177000"
    },
    {
        "id": 45569,
        "name": "Romagnat",
        "latitude": "45.72955000",
        "longitude": "3.10061000"
    },
    {
        "id": 45572,
        "name": "Romagné",
        "latitude": "48.34019000",
        "longitude": "-1.27751000"
    },
    {
        "id": 45570,
        "name": "Romagnieu",
        "latitude": "45.57061000",
        "longitude": "5.64096000"
    },
    {
        "id": 45571,
        "name": "Romagny",
        "latitude": "48.63931000",
        "longitude": "-0.96605000"
    },
    {
        "id": 45573,
        "name": "Romainville",
        "latitude": "48.88540000",
        "longitude": "2.43482000"
    },
    {
        "id": 45576,
        "name": "Romanèche-Thorins",
        "latitude": "46.18402000",
        "longitude": "4.74242000"
    },
    {
        "id": 45574,
        "name": "Romans-sur-Isère",
        "latitude": "45.04960000",
        "longitude": "5.06602000"
    },
    {
        "id": 45575,
        "name": "Romanswiller",
        "latitude": "48.64556000",
        "longitude": "7.40762000"
    },
    {
        "id": 45577,
        "name": "Rombas",
        "latitude": "49.24946000",
        "longitude": "6.09398000"
    },
    {
        "id": 45578,
        "name": "Romenay",
        "latitude": "46.50208000",
        "longitude": "5.06904000"
    },
    {
        "id": 45581,
        "name": "Romillé",
        "latitude": "48.21619000",
        "longitude": "-1.89229000"
    },
    {
        "id": 45579,
        "name": "Romilly-sur-Andelle",
        "latitude": "49.33190000",
        "longitude": "1.26127000"
    },
    {
        "id": 45580,
        "name": "Romilly-sur-Seine",
        "latitude": "48.51085000",
        "longitude": "3.72634000"
    },
    {
        "id": 45582,
        "name": "Romorantin-Lanthenay",
        "latitude": "47.36667000",
        "longitude": "1.75000000"
    },
    {
        "id": 45583,
        "name": "Ronchamp",
        "latitude": "47.70063000",
        "longitude": "6.63591000"
    },
    {
        "id": 45584,
        "name": "Roncherolles-sur-le-Vivier",
        "latitude": "49.46667000",
        "longitude": "1.18333000"
    },
    {
        "id": 45585,
        "name": "Ronchin",
        "latitude": "50.59883000",
        "longitude": "3.09056000"
    },
    {
        "id": 45586,
        "name": "Roncq",
        "latitude": "50.75330000",
        "longitude": "3.12131000"
    },
    {
        "id": 45587,
        "name": "Roost-Warendin",
        "latitude": "50.41920000",
        "longitude": "3.10374000"
    },
    {
        "id": 45588,
        "name": "Roquebillière",
        "latitude": "44.01203000",
        "longitude": "7.30727000"
    },
    {
        "id": 45589,
        "name": "Roquebrune-Cap-Martin",
        "latitude": "43.76408000",
        "longitude": "7.48207000"
    },
    {
        "id": 45590,
        "name": "Roquebrune-sur-Argens",
        "latitude": "43.44313000",
        "longitude": "6.63772000"
    },
    {
        "id": 45591,
        "name": "Roquecourbe",
        "latitude": "43.66442000",
        "longitude": "2.29264000"
    },
    {
        "id": 45592,
        "name": "Roquefort",
        "latitude": "44.03500000",
        "longitude": "-0.32323000"
    },
    {
        "id": 45593,
        "name": "Roquefort-la-Bédoule",
        "latitude": "43.24883000",
        "longitude": "5.59015000"
    },
    {
        "id": 45594,
        "name": "Roquemaure",
        "latitude": "44.04944000",
        "longitude": "4.77740000"
    },
    {
        "id": 45595,
        "name": "Roquetoire",
        "latitude": "50.67029000",
        "longitude": "2.34307000"
    },
    {
        "id": 45596,
        "name": "Roquettes",
        "latitude": "43.49733000",
        "longitude": "1.36848000"
    },
    {
        "id": 45597,
        "name": "Roquevaire",
        "latitude": "43.35021000",
        "longitude": "5.60414000"
    },
    {
        "id": 45598,
        "name": "Roscanvel",
        "latitude": "48.31520000",
        "longitude": "-4.54937000"
    },
    {
        "id": 45599,
        "name": "Roscoff",
        "latitude": "48.72381000",
        "longitude": "-3.98709000"
    },
    {
        "id": 45600,
        "name": "Rosenau",
        "latitude": "47.63688000",
        "longitude": "7.53598000"
    },
    {
        "id": 45601,
        "name": "Rosheim",
        "latitude": "48.49710000",
        "longitude": "7.47105000"
    },
    {
        "id": 45602,
        "name": "Rosières",
        "latitude": "45.13287000",
        "longitude": "3.98826000"
    },
    {
        "id": 45603,
        "name": "Rosières-aux-Salines",
        "latitude": "48.59301000",
        "longitude": "6.33199000"
    },
    {
        "id": 45604,
        "name": "Rosières-en-Santerre",
        "latitude": "49.81433000",
        "longitude": "2.70095000"
    },
    {
        "id": 45605,
        "name": "Rosières-près-Troyes",
        "latitude": "48.26182000",
        "longitude": "4.07396000"
    },
    {
        "id": 45606,
        "name": "Rosny-sous-Bois",
        "latitude": "48.87017000",
        "longitude": "2.49910000"
    },
    {
        "id": 45607,
        "name": "Rosny-sur-Seine",
        "latitude": "48.99808000",
        "longitude": "1.63130000"
    },
    {
        "id": 45608,
        "name": "Rospez",
        "latitude": "48.72940000",
        "longitude": "-3.38364000"
    },
    {
        "id": 45609,
        "name": "Rosporden",
        "latitude": "47.96667000",
        "longitude": "-3.83333000"
    },
    {
        "id": 45610,
        "name": "Rosselange",
        "latitude": "49.25780000",
        "longitude": "6.06890000"
    },
    {
        "id": 45611,
        "name": "Rostrenen",
        "latitude": "48.23696000",
        "longitude": "-3.31442000"
    },
    {
        "id": 45612,
        "name": "Rosult",
        "latitude": "50.45888000",
        "longitude": "3.35902000"
    },
    {
        "id": 45613,
        "name": "Rothau",
        "latitude": "48.45687000",
        "longitude": "7.20772000"
    },
    {
        "id": 45614,
        "name": "Rots",
        "latitude": "49.21235000",
        "longitude": "-0.47319000"
    },
    {
        "id": 45615,
        "name": "Rouans",
        "latitude": "47.18321000",
        "longitude": "-1.85929000"
    },
    {
        "id": 45616,
        "name": "Roubaix",
        "latitude": "50.69421000",
        "longitude": "3.17456000"
    },
    {
        "id": 45617,
        "name": "Rouen",
        "latitude": "49.44313000",
        "longitude": "1.09932000"
    },
    {
        "id": 45618,
        "name": "Rouet",
        "latitude": "43.27808000",
        "longitude": "5.39158000"
    },
    {
        "id": 45619,
        "name": "Rouffach",
        "latitude": "47.95786000",
        "longitude": "7.30016000"
    },
    {
        "id": 45620,
        "name": "Rouffiac-Tolosan",
        "latitude": "43.66576000",
        "longitude": "1.52521000"
    },
    {
        "id": 45621,
        "name": "Rouffignac-Saint-Cernin-de-Reilhac",
        "latitude": "45.05000000",
        "longitude": "0.96667000"
    },
    {
        "id": 45625,
        "name": "Rougé",
        "latitude": "47.78367000",
        "longitude": "-1.44763000"
    },
    {
        "id": 45622,
        "name": "Rougemont",
        "latitude": "47.48211000",
        "longitude": "6.35574000"
    },
    {
        "id": 45623,
        "name": "Rougemont-le-Château",
        "latitude": "47.73532000",
        "longitude": "6.96690000"
    },
    {
        "id": 45624,
        "name": "Rougiers",
        "latitude": "43.39233000",
        "longitude": "5.85112000"
    },
    {
        "id": 45626,
        "name": "Rouhling",
        "latitude": "49.13888000",
        "longitude": "7.00446000"
    },
    {
        "id": 45627,
        "name": "Rouillac",
        "latitude": "45.77582000",
        "longitude": "-0.06380000"
    },
    {
        "id": 45629,
        "name": "Rouillé",
        "latitude": "46.42024000",
        "longitude": "0.04073000"
    },
    {
        "id": 45628,
        "name": "Rouillon",
        "latitude": "48.00629000",
        "longitude": "0.13527000"
    },
    {
        "id": 45630,
        "name": "Roujan",
        "latitude": "43.50489000",
        "longitude": "3.31071000"
    },
    {
        "id": 45631,
        "name": "Roulans",
        "latitude": "47.31553000",
        "longitude": "6.22901000"
    },
    {
        "id": 45632,
        "name": "Roullet-Saint-Estèphe",
        "latitude": "45.58333000",
        "longitude": "0.05000000"
    },
    {
        "id": 45633,
        "name": "Roumare",
        "latitude": "49.51008000",
        "longitude": "0.97422000"
    },
    {
        "id": 45634,
        "name": "Roumazières-Loubert",
        "latitude": "45.88690000",
        "longitude": "0.58125000"
    },
    {
        "id": 45635,
        "name": "Rousies",
        "latitude": "50.27346000",
        "longitude": "4.00382000"
    },
    {
        "id": 45636,
        "name": "Roussay",
        "latitude": "47.09011000",
        "longitude": "-1.06417000"
    },
    {
        "id": 45637,
        "name": "Rousset",
        "latitude": "43.48307000",
        "longitude": "5.61959000"
    },
    {
        "id": 45638,
        "name": "Roussillon",
        "latitude": "43.90239000",
        "longitude": "5.29274000"
    },
    {
        "id": 45639,
        "name": "Roussillon",
        "latitude": "45.37388000",
        "longitude": "4.81496000"
    },
    {
        "id": 45640,
        "name": "Rousson",
        "latitude": "44.19136000",
        "longitude": "4.14786000"
    },
    {
        "id": 45641,
        "name": "Routot",
        "latitude": "49.37800000",
        "longitude": "0.73346000"
    },
    {
        "id": 45642,
        "name": "Rouvroy",
        "latitude": "50.39247000",
        "longitude": "2.90396000"
    },
    {
        "id": 45643,
        "name": "Rouxmesnil-Bouteilles",
        "latitude": "49.90633000",
        "longitude": "1.07976000"
    },
    {
        "id": 45644,
        "name": "Rouziers-de-Touraine",
        "latitude": "47.51720000",
        "longitude": "0.64848000"
    },
    {
        "id": 45645,
        "name": "Royan",
        "latitude": "45.62846000",
        "longitude": "-1.02810000"
    },
    {
        "id": 45646,
        "name": "Royat",
        "latitude": "45.76497000",
        "longitude": "3.05013000"
    },
    {
        "id": 45647,
        "name": "Roybon",
        "latitude": "45.25851000",
        "longitude": "5.24502000"
    },
    {
        "id": 45648,
        "name": "Roye",
        "latitude": "49.70038000",
        "longitude": "2.78959000"
    },
    {
        "id": 45649,
        "name": "Roye",
        "latitude": "47.66995000",
        "longitude": "6.54139000"
    },
    {
        "id": 45650,
        "name": "Roz-Landrieux",
        "latitude": "48.54337000",
        "longitude": "-1.81586000"
    },
    {
        "id": 45651,
        "name": "Roz-sur-Couesnon",
        "latitude": "48.58867000",
        "longitude": "-1.59213000"
    },
    {
        "id": 45652,
        "name": "Rozay-en-Brie",
        "latitude": "48.68333000",
        "longitude": "2.95816000"
    },
    {
        "id": 45655,
        "name": "Rozérieulles",
        "latitude": "49.10668000",
        "longitude": "6.08198000"
    },
    {
        "id": 45653,
        "name": "Rozier-en-Donzy",
        "latitude": "45.80069000",
        "longitude": "4.27736000"
    },
    {
        "id": 45654,
        "name": "Rozoy-sur-Serre",
        "latitude": "49.70956000",
        "longitude": "4.12902000"
    },
    {
        "id": 45657,
        "name": "Ruaudin",
        "latitude": "47.94509000",
        "longitude": "0.26760000"
    },
    {
        "id": 45658,
        "name": "Rubelles",
        "latitude": "48.55327000",
        "longitude": "2.67593000"
    },
    {
        "id": 45659,
        "name": "Rue",
        "latitude": "50.27150000",
        "longitude": "1.67163000"
    },
    {
        "id": 45660,
        "name": "Rueil-Malmaison",
        "latitude": "48.87650000",
        "longitude": "2.18967000"
    },
    {
        "id": 45661,
        "name": "Ruelisheim",
        "latitude": "47.82405000",
        "longitude": "7.35744000"
    },
    {
        "id": 45662,
        "name": "Ruelle-sur-Touvre",
        "latitude": "45.68333000",
        "longitude": "0.23333000"
    },
    {
        "id": 45663,
        "name": "Ruffec",
        "latitude": "46.02877000",
        "longitude": "0.19821000"
    },
    {
        "id": 45664,
        "name": "Ruffey-lès-Echirey",
        "latitude": "47.36667000",
        "longitude": "5.08333000"
    },
    {
        "id": 45665,
        "name": "Ruffiac",
        "latitude": "47.81866000",
        "longitude": "-2.28238000"
    },
    {
        "id": 45666,
        "name": "Rugles",
        "latitude": "48.82212000",
        "longitude": "0.70979000"
    },
    {
        "id": 45667,
        "name": "Ruillé-sur-Loir",
        "latitude": "47.75075000",
        "longitude": "0.62058000"
    },
    {
        "id": 45668,
        "name": "Ruitz",
        "latitude": "50.46643000",
        "longitude": "2.58876000"
    },
    {
        "id": 45669,
        "name": "Rully",
        "latitude": "46.87499000",
        "longitude": "4.74322000"
    },
    {
        "id": 45670,
        "name": "Rumegies",
        "latitude": "50.48877000",
        "longitude": "3.35131000"
    },
    {
        "id": 45671,
        "name": "Rumersheim-le-Haut",
        "latitude": "47.85124000",
        "longitude": "7.52079000"
    },
    {
        "id": 45672,
        "name": "Rumilly",
        "latitude": "45.86116000",
        "longitude": "5.94513000"
    },
    {
        "id": 45673,
        "name": "Rumilly-en-Cambrésis",
        "latitude": "50.12691000",
        "longitude": "3.21874000"
    },
    {
        "id": 45674,
        "name": "Ruminghem",
        "latitude": "50.85922000",
        "longitude": "2.15838000"
    },
    {
        "id": 45675,
        "name": "Rungis",
        "latitude": "48.74607000",
        "longitude": "2.35275000"
    },
    {
        "id": 45676,
        "name": "Ruoms",
        "latitude": "44.45294000",
        "longitude": "4.34202000"
    },
    {
        "id": 45677,
        "name": "Rupt-sur-Moselle",
        "latitude": "47.92271000",
        "longitude": "6.66291000"
    },
    {
        "id": 45678,
        "name": "Rurange-lès-Thionville",
        "latitude": "49.27599000",
        "longitude": "6.23184000"
    },
    {
        "id": 45679,
        "name": "Russ",
        "latitude": "48.49600000",
        "longitude": "7.25911000"
    },
    {
        "id": 45680,
        "name": "Russange",
        "latitude": "49.48283000",
        "longitude": "5.95204000"
    },
    {
        "id": 45681,
        "name": "Ruy",
        "latitude": "45.58640000",
        "longitude": "5.32143000"
    },
    {
        "id": 46891,
        "name": "Saâcy-sur-Marne",
        "latitude": "48.96210000",
        "longitude": "3.21083000"
    },
    {
        "id": 45703,
        "name": "Sablé-sur-Sarthe",
        "latitude": "47.83765000",
        "longitude": "-0.33294000"
    },
    {
        "id": 45699,
        "name": "Sablet",
        "latitude": "44.19265000",
        "longitude": "5.00566000"
    },
    {
        "id": 45700,
        "name": "Sablonceaux",
        "latitude": "45.70963000",
        "longitude": "-0.88806000"
    },
    {
        "id": 45701,
        "name": "Sablons",
        "latitude": "45.03333000",
        "longitude": "-0.18333000"
    },
    {
        "id": 45702,
        "name": "Sablons",
        "latitude": "45.32104000",
        "longitude": "4.77449000"
    },
    {
        "id": 45704,
        "name": "Sabran",
        "latitude": "44.15040000",
        "longitude": "4.54820000"
    },
    {
        "id": 45705,
        "name": "Sabres",
        "latitude": "44.14896000",
        "longitude": "-0.74123000"
    },
    {
        "id": 45706,
        "name": "Saché",
        "latitude": "47.24707000",
        "longitude": "0.54455000"
    },
    {
        "id": 45707,
        "name": "Saclas",
        "latitude": "48.35845000",
        "longitude": "2.12349000"
    },
    {
        "id": 45708,
        "name": "Saclay",
        "latitude": "48.73260000",
        "longitude": "2.16923000"
    },
    {
        "id": 45709,
        "name": "Sacy-le-Grand",
        "latitude": "49.35479000",
        "longitude": "2.54461000"
    },
    {
        "id": 45710,
        "name": "Sadirac",
        "latitude": "44.78179000",
        "longitude": "-0.41334000"
    },
    {
        "id": 45711,
        "name": "Saffré",
        "latitude": "47.50143000",
        "longitude": "-1.57856000"
    },
    {
        "id": 45712,
        "name": "Sagy",
        "latitude": "46.60129000",
        "longitude": "5.31045000"
    },
    {
        "id": 45713,
        "name": "Sagy",
        "latitude": "49.04988000",
        "longitude": "1.95216000"
    },
    {
        "id": 45714,
        "name": "Sahurs",
        "latitude": "49.35863000",
        "longitude": "0.94256000"
    },
    {
        "id": 45715,
        "name": "Saignon",
        "latitude": "43.86308000",
        "longitude": "5.42838000"
    },
    {
        "id": 45716,
        "name": "Sail-sous-Couzan",
        "latitude": "45.73482000",
        "longitude": "3.96898000"
    },
    {
        "id": 45717,
        "name": "Sailly-Flibeaucourt",
        "latitude": "50.18419000",
        "longitude": "1.77180000"
    },
    {
        "id": 45718,
        "name": "Sailly-Labourse",
        "latitude": "50.50120000",
        "longitude": "2.69708000"
    },
    {
        "id": 45719,
        "name": "Sailly-lez-Lannoy",
        "latitude": "50.65000000",
        "longitude": "3.21667000"
    },
    {
        "id": 45720,
        "name": "Sailly-sur-la-Lys",
        "latitude": "50.65820000",
        "longitude": "2.76872000"
    },
    {
        "id": 45721,
        "name": "Sain-Bel",
        "latitude": "45.81017000",
        "longitude": "4.59797000"
    },
    {
        "id": 45722,
        "name": "Sainghin-en-Mélantois",
        "latitude": "50.58836000",
        "longitude": "3.16619000"
    },
    {
        "id": 45723,
        "name": "Sainghin-en-Weppes",
        "latitude": "50.56407000",
        "longitude": "2.89804000"
    },
    {
        "id": 45724,
        "name": "Sains-du-Nord",
        "latitude": "50.09377000",
        "longitude": "4.00898000"
    },
    {
        "id": 45725,
        "name": "Sains-en-Amiénois",
        "latitude": "49.81716000",
        "longitude": "2.31862000"
    },
    {
        "id": 45726,
        "name": "Sains-en-Gohelle",
        "latitude": "50.44512000",
        "longitude": "2.68301000"
    },
    {
        "id": 45727,
        "name": "Saint-Affrique",
        "latitude": "43.95575000",
        "longitude": "2.88915000"
    },
    {
        "id": 45728,
        "name": "Saint-Agathon",
        "latitude": "48.55867000",
        "longitude": "-3.10525000"
    },
    {
        "id": 45729,
        "name": "Saint-Agnant",
        "latitude": "45.87354000",
        "longitude": "-0.96119000"
    },
    {
        "id": 45730,
        "name": "Saint-Agnant-de-Versillat",
        "latitude": "46.27800000",
        "longitude": "1.50962000"
    },
    {
        "id": 45731,
        "name": "Saint-Agrève",
        "latitude": "45.01003000",
        "longitude": "4.39612000"
    },
    {
        "id": 45732,
        "name": "Saint-Aignan",
        "latitude": "47.26892000",
        "longitude": "1.37614000"
    },
    {
        "id": 45733,
        "name": "Saint-Aigulin",
        "latitude": "45.15735000",
        "longitude": "-0.00863000"
    },
    {
        "id": 45734,
        "name": "Saint-Alban",
        "latitude": "48.55630000",
        "longitude": "-2.54013000"
    },
    {
        "id": 45735,
        "name": "Saint-Alban",
        "latitude": "43.69270000",
        "longitude": "1.41020000"
    },
    {
        "id": 45737,
        "name": "Saint-Alban-de-Roche",
        "latitude": "45.59490000",
        "longitude": "5.22630000"
    },
    {
        "id": 45736,
        "name": "Saint-Alban-Leysse",
        "latitude": "45.58333000",
        "longitude": "5.95000000"
    },
    {
        "id": 45738,
        "name": "Saint-Alban-sur-Limagnole",
        "latitude": "44.78100000",
        "longitude": "3.38793000"
    },
    {
        "id": 45739,
        "name": "Saint-Alexandre",
        "latitude": "44.22741000",
        "longitude": "4.62081000"
    },
    {
        "id": 45740,
        "name": "Saint-Amand",
        "latitude": "49.04338000",
        "longitude": "-0.96491000"
    },
    {
        "id": 45743,
        "name": "Saint-Amand-en-Puisaye",
        "latitude": "47.52913000",
        "longitude": "3.07400000"
    },
    {
        "id": 45744,
        "name": "Saint-Amand-les-Eaux",
        "latitude": "50.44718000",
        "longitude": "3.43076000"
    },
    {
        "id": 45741,
        "name": "Saint-Amand-Longpré",
        "latitude": "47.68333000",
        "longitude": "1.01667000"
    },
    {
        "id": 45742,
        "name": "Saint-Amand-Montrond",
        "latitude": "46.72284000",
        "longitude": "2.50494000"
    },
    {
        "id": 45745,
        "name": "Saint-Amand-sur-Sèvre",
        "latitude": "46.86905000",
        "longitude": "-0.79441000"
    },
    {
        "id": 45746,
        "name": "Saint-Amans-Soult",
        "latitude": "43.47697000",
        "longitude": "2.49076000"
    },
    {
        "id": 45747,
        "name": "Saint-Amans-Valtoret",
        "latitude": "43.48071000",
        "longitude": "2.49095000"
    },
    {
        "id": 45749,
        "name": "Saint-Amant-de-Boixe",
        "latitude": "45.79790000",
        "longitude": "0.13524000"
    },
    {
        "id": 45748,
        "name": "Saint-Amant-Tallende",
        "latitude": "45.66900000",
        "longitude": "3.10788000"
    },
    {
        "id": 45750,
        "name": "Saint-Amarin",
        "latitude": "47.87494000",
        "longitude": "7.02940000"
    },
    {
        "id": 45751,
        "name": "Saint-Ambroix",
        "latitude": "44.25893000",
        "longitude": "4.19833000"
    },
    {
        "id": 45753,
        "name": "Saint-Amé",
        "latitude": "48.02388000",
        "longitude": "6.66416000"
    },
    {
        "id": 45752,
        "name": "Saint-Amour",
        "latitude": "46.43638000",
        "longitude": "5.34416000"
    },
    {
        "id": 45769,
        "name": "Saint-Andéol-le-Château",
        "latitude": "45.58597000",
        "longitude": "4.69504000"
    },
    {
        "id": 45754,
        "name": "Saint-Andiol",
        "latitude": "43.83484000",
        "longitude": "4.94453000"
    },
    {
        "id": 45755,
        "name": "Saint-André",
        "latitude": "43.35630000",
        "longitude": "5.34335000"
    },
    {
        "id": 45757,
        "name": "Saint-André-de-Corcy",
        "latitude": "45.92590000",
        "longitude": "4.95166000"
    },
    {
        "id": 45760,
        "name": "Saint-André-de-la-Marche",
        "latitude": "47.09952000",
        "longitude": "-0.99441000"
    },
    {
        "id": 45761,
        "name": "Saint-André-de-la-Roche",
        "latitude": "43.73333000",
        "longitude": "7.28333000"
    },
    {
        "id": 45758,
        "name": "Saint-André-de-Sangonis",
        "latitude": "43.64753000",
        "longitude": "3.50209000"
    },
    {
        "id": 45759,
        "name": "Saint-André-de-Seignanx",
        "latitude": "43.55800000",
        "longitude": "-1.35183000"
    },
    {
        "id": 45762,
        "name": "Saint-André-des-Eaux",
        "latitude": "47.31461000",
        "longitude": "-2.31105000"
    },
    {
        "id": 45763,
        "name": "Saint-André-le-Gaz",
        "latitude": "45.54546000",
        "longitude": "5.52886000"
    },
    {
        "id": 45764,
        "name": "Saint-André-le-Puy",
        "latitude": "45.64763000",
        "longitude": "4.25887000"
    },
    {
        "id": 45765,
        "name": "Saint-André-les-Vergers",
        "latitude": "48.28527000",
        "longitude": "4.05210000"
    },
    {
        "id": 45766,
        "name": "Saint-André-lez-Lille",
        "latitude": "50.66667000",
        "longitude": "3.05000000"
    },
    {
        "id": 45767,
        "name": "Saint-André-sur-Orne",
        "latitude": "49.11741000",
        "longitude": "-0.38227000"
    },
    {
        "id": 45768,
        "name": "Saint-André-sur-Vieux-Jonc",
        "latitude": "46.15855000",
        "longitude": "5.14776000"
    },
    {
        "id": 45756,
        "name": "Saint-André-Treize-Voies",
        "latitude": "46.93369000",
        "longitude": "-1.41274000"
    },
    {
        "id": 45770,
        "name": "Saint-Antoine",
        "latitude": "43.37087000",
        "longitude": "5.35888000"
    },
    {
        "id": 45771,
        "name": "Saint-Antoine-de-Breuilh",
        "latitude": "44.83333000",
        "longitude": "0.16667000"
    },
    {
        "id": 45772,
        "name": "Saint-Antoine-du-Rocher",
        "latitude": "47.49677000",
        "longitude": "0.62997000"
    },
    {
        "id": 45773,
        "name": "Saint-Antoine-l'Abbaye",
        "latitude": "45.16667000",
        "longitude": "5.21667000"
    },
    {
        "id": 45774,
        "name": "Saint-Apollinaire",
        "latitude": "47.33333000",
        "longitude": "5.08333000"
    },
    {
        "id": 45775,
        "name": "Saint-Armel",
        "latitude": "48.01305000",
        "longitude": "-1.59152000"
    },
    {
        "id": 45776,
        "name": "Saint-Arnoult",
        "latitude": "49.52676000",
        "longitude": "0.67117000"
    },
    {
        "id": 45777,
        "name": "Saint-Arnoult-en-Yvelines",
        "latitude": "48.57111000",
        "longitude": "1.93950000"
    },
    {
        "id": 45778,
        "name": "Saint-Astier",
        "latitude": "45.14582000",
        "longitude": "0.52898000"
    },
    {
        "id": 45779,
        "name": "Saint-Aubert",
        "latitude": "50.20805000",
        "longitude": "3.41727000"
    },
    {
        "id": 45780,
        "name": "Saint-Aubin",
        "latitude": "47.03336000",
        "longitude": "5.32666000"
    },
    {
        "id": 45782,
        "name": "Saint-Aubin-de-Médoc",
        "latitude": "44.91180000",
        "longitude": "-0.72460000"
    },
    {
        "id": 45783,
        "name": "Saint-Aubin-des-Bois",
        "latitude": "48.46549000",
        "longitude": "1.35784000"
    },
    {
        "id": 45784,
        "name": "Saint-Aubin-des-Châteaux",
        "latitude": "47.72047000",
        "longitude": "-1.48876000"
    },
    {
        "id": 45785,
        "name": "Saint-Aubin-des-Ormeaux",
        "latitude": "46.99238000",
        "longitude": "-1.04274000"
    },
    {
        "id": 45786,
        "name": "Saint-Aubin-du-Cormier",
        "latitude": "48.25843000",
        "longitude": "-1.40252000"
    },
    {
        "id": 45787,
        "name": "Saint-Aubin-en-Bray",
        "latitude": "49.42051000",
        "longitude": "1.87782000"
    },
    {
        "id": 45788,
        "name": "Saint-Aubin-le-Cloud",
        "latitude": "46.65308000",
        "longitude": "-0.35258000"
    },
    {
        "id": 45789,
        "name": "Saint-Aubin-lès-Elbeuf",
        "latitude": "49.30360000",
        "longitude": "1.01056000"
    },
    {
        "id": 45781,
        "name": "Saint-Aubin-Routot",
        "latitude": "49.52341000",
        "longitude": "0.32602000"
    },
    {
        "id": 45790,
        "name": "Saint-Aubin-sur-Gaillon",
        "latitude": "49.14701000",
        "longitude": "1.32914000"
    },
    {
        "id": 45791,
        "name": "Saint-Aubin-sur-Mer",
        "latitude": "49.32787000",
        "longitude": "-0.38696000"
    },
    {
        "id": 45792,
        "name": "Saint-Aubin-sur-Scie",
        "latitude": "49.87162000",
        "longitude": "1.06823000"
    },
    {
        "id": 45793,
        "name": "Saint-Augustin",
        "latitude": "48.78333000",
        "longitude": "3.03016000"
    },
    {
        "id": 45794,
        "name": "Saint-Aulaye",
        "latitude": "45.20000000",
        "longitude": "0.13333000"
    },
    {
        "id": 45795,
        "name": "Saint-Aunès",
        "latitude": "43.64083000",
        "longitude": "3.96583000"
    },
    {
        "id": 45796,
        "name": "Saint-Aupre",
        "latitude": "45.40296000",
        "longitude": "5.66682000"
    },
    {
        "id": 45799,
        "name": "Saint-Avé",
        "latitude": "47.69085000",
        "longitude": "-2.74648000"
    },
    {
        "id": 45797,
        "name": "Saint-Avertin",
        "latitude": "47.36357000",
        "longitude": "0.73993000"
    },
    {
        "id": 45798,
        "name": "Saint-Avold",
        "latitude": "49.10465000",
        "longitude": "6.70402000"
    },
    {
        "id": 45800,
        "name": "Saint-Ay",
        "latitude": "47.85890000",
        "longitude": "1.75137000"
    },
    {
        "id": 45801,
        "name": "Saint-Baldoph",
        "latitude": "45.53120000",
        "longitude": "5.95217000"
    },
    {
        "id": 45802,
        "name": "Saint-Barnabé",
        "latitude": "48.13712000",
        "longitude": "-2.70483000"
    },
    {
        "id": 45803,
        "name": "Saint-Barnabé",
        "latitude": "43.30000000",
        "longitude": "5.41667000"
    },
    {
        "id": 45805,
        "name": "Saint-Barthélémy",
        "latitude": "43.33105000",
        "longitude": "5.40241000"
    },
    {
        "id": 45804,
        "name": "Saint-Barthélemy-de-Vals",
        "latitude": "45.16938000",
        "longitude": "4.87160000"
    },
    {
        "id": 45806,
        "name": "Saint-Baudelle",
        "latitude": "48.28081000",
        "longitude": "-0.63728000"
    },
    {
        "id": 45807,
        "name": "Saint-Bauzille-de-Putois",
        "latitude": "43.89454000",
        "longitude": "3.73580000"
    },
    {
        "id": 45808,
        "name": "Saint-Beauzire",
        "latitude": "45.84964000",
        "longitude": "3.17947000"
    },
    {
        "id": 45809,
        "name": "Saint-Benin-d’Azy",
        "latitude": "46.99914000",
        "longitude": "3.39267000"
    },
    {
        "id": 45810,
        "name": "Saint-Benoît",
        "latitude": "46.55315000",
        "longitude": "0.34190000"
    },
    {
        "id": 45811,
        "name": "Saint-Benoît-de-Carmaux",
        "latitude": "44.05037000",
        "longitude": "2.12911000"
    },
    {
        "id": 45812,
        "name": "Saint-Benoît-sur-Loire",
        "latitude": "47.80561000",
        "longitude": "2.31274000"
    },
    {
        "id": 45813,
        "name": "Saint-Berain-sous-Sanvignes",
        "latitude": "46.70678000",
        "longitude": "4.29405000"
    },
    {
        "id": 45835,
        "name": "Saint-Béron",
        "latitude": "45.50308000",
        "longitude": "5.72790000"
    },
    {
        "id": 45814,
        "name": "Saint-Berthevin",
        "latitude": "48.06667000",
        "longitude": "-0.83333000"
    },
    {
        "id": 45815,
        "name": "Saint-Bon-Tarentaise",
        "latitude": "45.43373000",
        "longitude": "6.63686000"
    },
    {
        "id": 45816,
        "name": "Saint-Bonnet-de-Mure",
        "latitude": "45.69042000",
        "longitude": "5.02912000"
    },
    {
        "id": 45817,
        "name": "Saint-Bonnet-en-Champsaur",
        "latitude": "44.68333000",
        "longitude": "6.08333000"
    },
    {
        "id": 45818,
        "name": "Saint-Bonnet-le-Château",
        "latitude": "45.42402000",
        "longitude": "4.06706000"
    },
    {
        "id": 45819,
        "name": "Saint-Bonnet-les-Oules",
        "latitude": "45.54946000",
        "longitude": "4.32522000"
    },
    {
        "id": 45820,
        "name": "Saint-Bonnet-près-Riom",
        "latitude": "45.92972000",
        "longitude": "3.11310000"
    },
    {
        "id": 45821,
        "name": "Saint-Branchs",
        "latitude": "47.22655000",
        "longitude": "0.77306000"
    },
    {
        "id": 45822,
        "name": "Saint-Brandan",
        "latitude": "48.39008000",
        "longitude": "-2.86875000"
    },
    {
        "id": 45834,
        "name": "Saint-Brès",
        "latitude": "43.66742000",
        "longitude": "4.03105000"
    },
    {
        "id": 45823,
        "name": "Saint-Brevin-les-Pins",
        "latitude": "47.25000000",
        "longitude": "-2.16667000"
    },
    {
        "id": 45824,
        "name": "Saint-Briac-sur-Mer",
        "latitude": "48.62189000",
        "longitude": "-2.13435000"
    },
    {
        "id": 45825,
        "name": "Saint-Brice",
        "latitude": "45.68333000",
        "longitude": "-0.28333000"
    },
    {
        "id": 45826,
        "name": "Saint-Brice-Courcelles",
        "latitude": "49.26667000",
        "longitude": "3.98333000"
    },
    {
        "id": 45827,
        "name": "Saint-Brice-en-Coglès",
        "latitude": "48.41157000",
        "longitude": "-1.37019000"
    },
    {
        "id": 45828,
        "name": "Saint-Brice-sous-Forêt",
        "latitude": "49.00132000",
        "longitude": "2.35361000"
    },
    {
        "id": 45829,
        "name": "Saint-Brice-sur-Vienne",
        "latitude": "45.87852000",
        "longitude": "0.95594000"
    },
    {
        "id": 45830,
        "name": "Saint-Brieuc",
        "latitude": "48.51513000",
        "longitude": "-2.76838000"
    },
    {
        "id": 45831,
        "name": "Saint-Bris-le-Vineux",
        "latitude": "47.74396000",
        "longitude": "3.64922000"
    },
    {
        "id": 45832,
        "name": "Saint-Brisson-sur-Loire",
        "latitude": "47.64686000",
        "longitude": "2.68229000"
    },
    {
        "id": 45833,
        "name": "Saint-Broladre",
        "latitude": "48.58677000",
        "longitude": "-1.65769000"
    },
    {
        "id": 45836,
        "name": "Saint-Calais",
        "latitude": "47.92211000",
        "longitude": "0.74587000"
    },
    {
        "id": 45837,
        "name": "Saint-Cannat",
        "latitude": "43.62132000",
        "longitude": "5.29810000"
    },
    {
        "id": 45838,
        "name": "Saint-Caprais-de-Bordeaux",
        "latitude": "44.74786000",
        "longitude": "-0.43192000"
    },
    {
        "id": 45839,
        "name": "Saint-Caradec",
        "latitude": "48.19429000",
        "longitude": "-2.84386000"
    },
    {
        "id": 45840,
        "name": "Saint-Carreuc",
        "latitude": "48.39889000",
        "longitude": "-2.73056000"
    },
    {
        "id": 45841,
        "name": "Saint-Cassien",
        "latitude": "45.35710000",
        "longitude": "5.54793000"
    },
    {
        "id": 45842,
        "name": "Saint-Cast-le-Guildo",
        "latitude": "48.63028000",
        "longitude": "-2.25777000"
    },
    {
        "id": 45892,
        "name": "Saint-Céré",
        "latitude": "44.85726000",
        "longitude": "1.89415000"
    },
    {
        "id": 45843,
        "name": "Saint-Cergues",
        "latitude": "46.23005000",
        "longitude": "6.31924000"
    },
    {
        "id": 45844,
        "name": "Saint-Cernin",
        "latitude": "45.05850000",
        "longitude": "2.42081000"
    },
    {
        "id": 45893,
        "name": "Saint-Cézaire-sur-Siagne",
        "latitude": "43.65015000",
        "longitude": "6.79219000"
    },
    {
        "id": 45845,
        "name": "Saint-Chaffrey",
        "latitude": "44.92555000",
        "longitude": "6.60768000"
    },
    {
        "id": 45846,
        "name": "Saint-Chamas",
        "latitude": "43.55048000",
        "longitude": "5.03501000"
    },
    {
        "id": 45847,
        "name": "Saint-Chamond",
        "latitude": "45.47590000",
        "longitude": "4.51294000"
    },
    {
        "id": 45848,
        "name": "Saint-Chaptes",
        "latitude": "43.97172000",
        "longitude": "4.27812000"
    },
    {
        "id": 45849,
        "name": "Saint-Charles",
        "latitude": "43.30199000",
        "longitude": "5.38405000"
    },
    {
        "id": 45850,
        "name": "Saint-Chef",
        "latitude": "45.63585000",
        "longitude": "5.36465000"
    },
    {
        "id": 45860,
        "name": "Saint-Chéron",
        "latitude": "48.55433000",
        "longitude": "2.12403000"
    },
    {
        "id": 45851,
        "name": "Saint-Chinian",
        "latitude": "43.42238000",
        "longitude": "2.94643000"
    },
    {
        "id": 45852,
        "name": "Saint-Christo-en-Jarez",
        "latitude": "45.54508000",
        "longitude": "4.48826000"
    },
    {
        "id": 45853,
        "name": "Saint-Christol",
        "latitude": "43.72805000",
        "longitude": "4.07991000"
    },
    {
        "id": 45854,
        "name": "Saint-Christol-lès-Alès",
        "latitude": "44.08256000",
        "longitude": "4.07506000"
    },
    {
        "id": 45855,
        "name": "Saint-Christoly-de-Blaye",
        "latitude": "45.13179000",
        "longitude": "-0.50760000"
    },
    {
        "id": 45857,
        "name": "Saint-Christophe-du-Bois",
        "latitude": "47.03012000",
        "longitude": "-0.94441000"
    },
    {
        "id": 45858,
        "name": "Saint-Christophe-du-Ligneron",
        "latitude": "46.82478000",
        "longitude": "-1.76586000"
    },
    {
        "id": 45859,
        "name": "Saint-Christophe-sur-le-Nais",
        "latitude": "47.61720000",
        "longitude": "0.47801000"
    },
    {
        "id": 45856,
        "name": "Saint-Christophe-Vallon",
        "latitude": "44.47072000",
        "longitude": "2.41184000"
    },
    {
        "id": 45861,
        "name": "Saint-Ciers-d’Abzac",
        "latitude": "45.03333000",
        "longitude": "-0.26667000"
    },
    {
        "id": 45862,
        "name": "Saint-Ciers-sur-Gironde",
        "latitude": "45.28855000",
        "longitude": "-0.60794000"
    },
    {
        "id": 45863,
        "name": "Saint-Clair-de-la-Tour",
        "latitude": "45.57345000",
        "longitude": "5.47741000"
    },
    {
        "id": 45864,
        "name": "Saint-Clair-du-Rhône",
        "latitude": "45.44186000",
        "longitude": "4.77123000"
    },
    {
        "id": 45865,
        "name": "Saint-Claud",
        "latitude": "45.89526000",
        "longitude": "0.46454000"
    },
    {
        "id": 45866,
        "name": "Saint-Claude",
        "latitude": "46.38679000",
        "longitude": "5.86473000"
    },
    {
        "id": 45867,
        "name": "Saint-Claude-de-Diray",
        "latitude": "47.61651000",
        "longitude": "1.41798000"
    },
    {
        "id": 45869,
        "name": "Saint-Clément",
        "latitude": "45.34150000",
        "longitude": "1.68507000"
    },
    {
        "id": 45870,
        "name": "Saint-Clément",
        "latitude": "48.21724000",
        "longitude": "3.29609000"
    },
    {
        "id": 45872,
        "name": "Saint-Clément-de-la-Place",
        "latitude": "47.52744000",
        "longitude": "-0.74545000"
    },
    {
        "id": 45871,
        "name": "Saint-Clément-de-Rivière",
        "latitude": "43.68333000",
        "longitude": "3.83333000"
    },
    {
        "id": 45873,
        "name": "Saint-Clément-des-Levées",
        "latitude": "47.33333000",
        "longitude": "-0.18222000"
    },
    {
        "id": 45868,
        "name": "Saint-Cloud",
        "latitude": "48.84598000",
        "longitude": "2.20289000"
    },
    {
        "id": 45874,
        "name": "Saint-Contest",
        "latitude": "49.21401000",
        "longitude": "-0.40221000"
    },
    {
        "id": 45875,
        "name": "Saint-Cosme-en-Vairais",
        "latitude": "48.26667000",
        "longitude": "0.46667000"
    },
    {
        "id": 45876,
        "name": "Saint-Coulomb",
        "latitude": "48.67465000",
        "longitude": "-1.91092000"
    },
    {
        "id": 45878,
        "name": "Saint-Crépin-Ibouvillers",
        "latitude": "49.26283000",
        "longitude": "2.07793000"
    },
    {
        "id": 45877,
        "name": "Saint-Crespin-sur-Moine",
        "latitude": "47.09968000",
        "longitude": "-1.18651000"
    },
    {
        "id": 45879,
        "name": "Saint-Cyprien",
        "latitude": "45.53450000",
        "longitude": "4.23428000"
    },
    {
        "id": 45880,
        "name": "Saint-Cyprien",
        "latitude": "44.86924000",
        "longitude": "1.04156000"
    },
    {
        "id": 45881,
        "name": "Saint-Cyprien-Plage",
        "latitude": "42.63229000",
        "longitude": "3.03333000"
    },
    {
        "id": 45882,
        "name": "Saint-Cyr",
        "latitude": "45.25183000",
        "longitude": "4.73045000"
    },
    {
        "id": 45883,
        "name": "Saint-Cyr-en-Bourg",
        "latitude": "47.19520000",
        "longitude": "-0.06085000"
    },
    {
        "id": 45884,
        "name": "Saint-Cyr-en-Val",
        "latitude": "47.83182000",
        "longitude": "1.96672000"
    },
    {
        "id": 45885,
        "name": "Saint-Cyr-l’École",
        "latitude": "48.79865000",
        "longitude": "2.06814000"
    },
    {
        "id": 45886,
        "name": "Saint-Cyr-sous-Dourdan",
        "latitude": "48.56667000",
        "longitude": "2.03333000"
    },
    {
        "id": 45891,
        "name": "Saint-Cyr-sur-le-Rhône",
        "latitude": "45.51585000",
        "longitude": "4.85250000"
    },
    {
        "id": 45887,
        "name": "Saint-Cyr-sur-Loire",
        "latitude": "47.40000000",
        "longitude": "0.66667000"
    },
    {
        "id": 45888,
        "name": "Saint-Cyr-sur-Menthon",
        "latitude": "46.27502000",
        "longitude": "4.97246000"
    },
    {
        "id": 45889,
        "name": "Saint-Cyr-sur-Mer",
        "latitude": "43.18048000",
        "longitude": "5.70120000"
    },
    {
        "id": 45890,
        "name": "Saint-Cyr-sur-Morin",
        "latitude": "48.90664000",
        "longitude": "3.18016000"
    },
    {
        "id": 45894,
        "name": "Saint-Denis",
        "latitude": "48.93564000",
        "longitude": "2.35387000"
    },
    {
        "id": 45898,
        "name": "Saint-Denis-d’Oléron",
        "latitude": "46.03496000",
        "longitude": "-1.37867000"
    },
    {
        "id": 45895,
        "name": "Saint-Denis-de-Cabanne",
        "latitude": "46.17249000",
        "longitude": "4.21447000"
    },
    {
        "id": 45896,
        "name": "Saint-Denis-de-Gastines",
        "latitude": "48.34210000",
        "longitude": "-0.85869000"
    },
    {
        "id": 45897,
        "name": "Saint-Denis-de-Pile",
        "latitude": "45.00000000",
        "longitude": "-0.20000000"
    },
    {
        "id": 45899,
        "name": "Saint-Denis-en-Bugey",
        "latitude": "45.94963000",
        "longitude": "5.32773000"
    },
    {
        "id": 45900,
        "name": "Saint-Denis-en-Val",
        "latitude": "47.87321000",
        "longitude": "1.96601000"
    },
    {
        "id": 45901,
        "name": "Saint-Denis-la-Chevasse",
        "latitude": "46.82273000",
        "longitude": "-1.35749000"
    },
    {
        "id": 45903,
        "name": "Saint-Denis-lès-Bourg",
        "latitude": "46.20217000",
        "longitude": "5.18924000"
    },
    {
        "id": 45902,
        "name": "Saint-Denis-les-Ponts",
        "latitude": "48.06692000",
        "longitude": "1.29764000"
    },
    {
        "id": 45904,
        "name": "Saint-Denis-sur-Sarthon",
        "latitude": "48.45320000",
        "longitude": "-0.04720000"
    },
    {
        "id": 45920,
        "name": "Saint-Désir",
        "latitude": "49.14056000",
        "longitude": "0.21398000"
    },
    {
        "id": 45905,
        "name": "Saint-Didier",
        "latitude": "44.00424000",
        "longitude": "5.11053000"
    },
    {
        "id": 45906,
        "name": "Saint-Didier",
        "latitude": "48.09506000",
        "longitude": "-1.37276000"
    },
    {
        "id": 45907,
        "name": "Saint-Didier-de-Formans",
        "latitude": "45.95969000",
        "longitude": "4.78406000"
    },
    {
        "id": 45908,
        "name": "Saint-Didier-de-la-Tour",
        "latitude": "45.55755000",
        "longitude": "5.48026000"
    },
    {
        "id": 45909,
        "name": "Saint-Didier-en-Velay",
        "latitude": "45.30265000",
        "longitude": "4.27514000"
    },
    {
        "id": 45910,
        "name": "Saint-Didier-sous-Riverie",
        "latitude": "45.59627000",
        "longitude": "4.60650000"
    },
    {
        "id": 45911,
        "name": "Saint-Didier-sur-Chalaronne",
        "latitude": "46.17714000",
        "longitude": "4.81626000"
    },
    {
        "id": 45913,
        "name": "Saint-Dié-des-Vosges",
        "latitude": "48.28333000",
        "longitude": "6.95000000"
    },
    {
        "id": 45912,
        "name": "Saint-Dizier",
        "latitude": "48.63773000",
        "longitude": "4.94892000"
    },
    {
        "id": 45914,
        "name": "Saint-Dolay",
        "latitude": "47.54476000",
        "longitude": "-2.15466000"
    },
    {
        "id": 45915,
        "name": "Saint-Domineuc",
        "latitude": "48.37191000",
        "longitude": "-1.87544000"
    },
    {
        "id": 45916,
        "name": "Saint-Donan",
        "latitude": "48.46958000",
        "longitude": "-2.88442000"
    },
    {
        "id": 45917,
        "name": "Saint-Doulchard",
        "latitude": "47.10371000",
        "longitude": "2.35200000"
    },
    {
        "id": 45918,
        "name": "Saint-Drézéry",
        "latitude": "43.72991000",
        "longitude": "3.97620000"
    },
    {
        "id": 45919,
        "name": "Saint-Dyé-sur-Loire",
        "latitude": "47.65595000",
        "longitude": "1.48847000"
    },
    {
        "id": 46635,
        "name": "Saint-Égrève",
        "latitude": "45.23313000",
        "longitude": "5.68154000"
    },
    {
        "id": 46636,
        "name": "Saint-Éloi",
        "latitude": "46.97342000",
        "longitude": "3.22228000"
    },
    {
        "id": 46637,
        "name": "Saint-Éloy-de-Gy",
        "latitude": "47.15552000",
        "longitude": "2.34267000"
    },
    {
        "id": 46638,
        "name": "Saint-Éloy-les-Mines",
        "latitude": "46.16051000",
        "longitude": "2.83379000"
    },
    {
        "id": 46639,
        "name": "Saint-Émilion",
        "latitude": "44.88333000",
        "longitude": "-0.15000000"
    },
    {
        "id": 46640,
        "name": "Saint-Épain",
        "latitude": "47.14482000",
        "longitude": "0.53668000"
    },
    {
        "id": 45921,
        "name": "Saint-Erblon",
        "latitude": "48.01895000",
        "longitude": "-1.65162000"
    },
    {
        "id": 45922,
        "name": "Saint-Erme-Outre-et-Ramecourt",
        "latitude": "49.51464000",
        "longitude": "3.84060000"
    },
    {
        "id": 45923,
        "name": "Saint-Estèphe",
        "latitude": "45.26252000",
        "longitude": "-0.77237000"
    },
    {
        "id": 45924,
        "name": "Saint-Estève",
        "latitude": "42.71175000",
        "longitude": "2.84490000"
    },
    {
        "id": 46641,
        "name": "Saint-Étienne",
        "latitude": "45.43389000",
        "longitude": "4.39000000"
    },
    {
        "id": 46642,
        "name": "Saint-Étienne-au-Mont",
        "latitude": "50.67794000",
        "longitude": "1.63084000"
    },
    {
        "id": 46643,
        "name": "Saint-Étienne-de-Baïgorry",
        "latitude": "43.17533000",
        "longitude": "-1.34670000"
    },
    {
        "id": 46644,
        "name": "Saint-Étienne-de-Chigny",
        "latitude": "47.38333000",
        "longitude": "0.53333000"
    },
    {
        "id": 46645,
        "name": "Saint-Étienne-de-Crossey",
        "latitude": "45.38027000",
        "longitude": "5.64365000"
    },
    {
        "id": 45925,
        "name": "Saint-Etienne-de-Cuines",
        "latitude": "45.35000000",
        "longitude": "6.28333000"
    },
    {
        "id": 46646,
        "name": "Saint-Étienne-de-Fontbellon",
        "latitude": "44.60077000",
        "longitude": "4.38635000"
    },
    {
        "id": 46647,
        "name": "Saint-Étienne-de-Mer-Morte",
        "latitude": "46.92848000",
        "longitude": "-1.74272000"
    },
    {
        "id": 46648,
        "name": "Saint-Étienne-de-Montluc",
        "latitude": "47.27690000",
        "longitude": "-1.78072000"
    },
    {
        "id": 46649,
        "name": "Saint-Étienne-de-Saint-Geoirs",
        "latitude": "45.33909000",
        "longitude": "5.34650000"
    },
    {
        "id": 46650,
        "name": "Saint-Étienne-de-Tinée",
        "latitude": "44.25643000",
        "longitude": "6.92499000"
    },
    {
        "id": 45926,
        "name": "Saint-Etienne-de-Tulmont",
        "latitude": "44.05000000",
        "longitude": "1.46667000"
    },
    {
        "id": 46651,
        "name": "Saint-Étienne-des-Oullières",
        "latitude": "46.06731000",
        "longitude": "4.64945000"
    },
    {
        "id": 46652,
        "name": "Saint-Étienne-du-Bois",
        "latitude": "46.83041000",
        "longitude": "-1.59714000"
    },
    {
        "id": 46653,
        "name": "Saint-Étienne-du-Bois",
        "latitude": "46.28990000",
        "longitude": "5.29471000"
    },
    {
        "id": 46654,
        "name": "Saint-Étienne-du-Grès",
        "latitude": "43.78045000",
        "longitude": "4.72534000"
    },
    {
        "id": 46655,
        "name": "Saint-Étienne-du-Rouvray",
        "latitude": "49.37794000",
        "longitude": "1.10467000"
    },
    {
        "id": 46656,
        "name": "Saint-Étienne-en-Coglès",
        "latitude": "48.40172000",
        "longitude": "-1.32812000"
    },
    {
        "id": 46657,
        "name": "Saint-Étienne-lès-Remiremont",
        "latitude": "48.02287000",
        "longitude": "6.60868000"
    },
    {
        "id": 46658,
        "name": "Saint-Étienne-sur-Chalaronne",
        "latitude": "46.14778000",
        "longitude": "4.87129000"
    },
    {
        "id": 45928,
        "name": "Saint-Eusèbe",
        "latitude": "46.71346000",
        "longitude": "4.46203000"
    },
    {
        "id": 45927,
        "name": "Saint-Eustache-la-Forêt",
        "latitude": "49.55137000",
        "longitude": "0.45599000"
    },
    {
        "id": 46659,
        "name": "Saint-Évarzec",
        "latitude": "47.93333000",
        "longitude": "-4.01667000"
    },
    {
        "id": 45929,
        "name": "Saint-Fargeau",
        "latitude": "47.64133000",
        "longitude": "3.07066000"
    },
    {
        "id": 45930,
        "name": "Saint-Fargeau-Ponthierry",
        "latitude": "48.55713000",
        "longitude": "2.52840000"
    },
    {
        "id": 45945,
        "name": "Saint-Félicien",
        "latitude": "45.08600000",
        "longitude": "4.62738000"
    },
    {
        "id": 45946,
        "name": "Saint-Féliu-d’Avall",
        "latitude": "42.68117000",
        "longitude": "2.73813000"
    },
    {
        "id": 45947,
        "name": "Saint-Félix",
        "latitude": "45.80269000",
        "longitude": "5.97714000"
    },
    {
        "id": 45948,
        "name": "Saint-Félix-Lauragais",
        "latitude": "43.44863000",
        "longitude": "1.88814000"
    },
    {
        "id": 45931,
        "name": "Saint-Fiacre-sur-Maine",
        "latitude": "47.14255000",
        "longitude": "-1.41714000"
    },
    {
        "id": 45932,
        "name": "Saint-Florent",
        "latitude": "42.68150000",
        "longitude": "9.30396000"
    },
    {
        "id": 45933,
        "name": "Saint-Florent-des-Bois",
        "latitude": "46.59377000",
        "longitude": "-1.31580000"
    },
    {
        "id": 45934,
        "name": "Saint-Florent-le-Vieil",
        "latitude": "47.36069000",
        "longitude": "-1.01515000"
    },
    {
        "id": 45935,
        "name": "Saint-Florent-sur-Auzonnet",
        "latitude": "44.24032000",
        "longitude": "4.11252000"
    },
    {
        "id": 45936,
        "name": "Saint-Florent-sur-Cher",
        "latitude": "46.99552000",
        "longitude": "2.25076000"
    },
    {
        "id": 45937,
        "name": "Saint-Florentin",
        "latitude": "48.00057000",
        "longitude": "3.72489000"
    },
    {
        "id": 45938,
        "name": "Saint-Flour",
        "latitude": "45.03374000",
        "longitude": "3.09297000"
    },
    {
        "id": 45939,
        "name": "Saint-Folquin",
        "latitude": "50.94473000",
        "longitude": "2.12433000"
    },
    {
        "id": 45940,
        "name": "Saint-Fons",
        "latitude": "45.70875000",
        "longitude": "4.85325000"
    },
    {
        "id": 45941,
        "name": "Saint-Forgeux",
        "latitude": "45.85895000",
        "longitude": "4.47480000"
    },
    {
        "id": 45942,
        "name": "Saint-Fort",
        "latitude": "47.79928000",
        "longitude": "-0.72095000"
    },
    {
        "id": 45943,
        "name": "Saint-François",
        "latitude": "46.41537000",
        "longitude": "3.90542000"
    },
    {
        "id": 45944,
        "name": "Saint-Fulgent",
        "latitude": "46.85226000",
        "longitude": "-1.17798000"
    },
    {
        "id": 45949,
        "name": "Saint-Galmier",
        "latitude": "45.59787000",
        "longitude": "4.31086000"
    },
    {
        "id": 45950,
        "name": "Saint-Gatien-des-Bois",
        "latitude": "49.35000000",
        "longitude": "0.18333000"
    },
    {
        "id": 45951,
        "name": "Saint-Gaudens",
        "latitude": "43.10809000",
        "longitude": "0.72345000"
    },
    {
        "id": 45952,
        "name": "Saint-Gaultier",
        "latitude": "46.63518000",
        "longitude": "1.41289000"
    },
    {
        "id": 45953,
        "name": "Saint-Gelais",
        "latitude": "46.38234000",
        "longitude": "-0.39084000"
    },
    {
        "id": 46043,
        "name": "Saint-Gély-du-Fesc",
        "latitude": "43.69272000",
        "longitude": "3.80492000"
    },
    {
        "id": 45954,
        "name": "Saint-Gence",
        "latitude": "45.92198000",
        "longitude": "1.13726000"
    },
    {
        "id": 45969,
        "name": "Saint-Genès-Champanelle",
        "latitude": "45.72037000",
        "longitude": "3.01883000"
    },
    {
        "id": 45955,
        "name": "Saint-Genest-Lerpt",
        "latitude": "45.44347000",
        "longitude": "4.33968000"
    },
    {
        "id": 45956,
        "name": "Saint-Genest-Malifaux",
        "latitude": "45.34064000",
        "longitude": "4.41652000"
    },
    {
        "id": 45957,
        "name": "Saint-Gengoux-le-National",
        "latitude": "46.61476000",
        "longitude": "4.66261000"
    },
    {
        "id": 45964,
        "name": "Saint-Geniès-Bellevue",
        "latitude": "43.68409000",
        "longitude": "1.48693000"
    },
    {
        "id": 45965,
        "name": "Saint-Geniès-de-Comolas",
        "latitude": "44.06617000",
        "longitude": "4.72157000"
    },
    {
        "id": 45966,
        "name": "Saint-Geniès-de-Fontedit",
        "latitude": "43.46667000",
        "longitude": "3.18333000"
    },
    {
        "id": 45967,
        "name": "Saint-Geniès-de-Malgoirès",
        "latitude": "43.95000000",
        "longitude": "4.21667000"
    },
    {
        "id": 45968,
        "name": "Saint-Geniès-des-Mourgues",
        "latitude": "43.69780000",
        "longitude": "4.03610000"
    },
    {
        "id": 45958,
        "name": "Saint-Geniez-d’Olt",
        "latitude": "44.46561000",
        "longitude": "2.97261000"
    },
    {
        "id": 45961,
        "name": "Saint-Genis-de-Saintonge",
        "latitude": "45.48107000",
        "longitude": "-0.56848000"
    },
    {
        "id": 46044,
        "name": "Saint-Génis-des-Fontaines",
        "latitude": "42.54325000",
        "longitude": "2.92060000"
    },
    {
        "id": 45959,
        "name": "Saint-Genis-Laval",
        "latitude": "45.69542000",
        "longitude": "4.79316000"
    },
    {
        "id": 45962,
        "name": "Saint-Genis-les-Ollières",
        "latitude": "45.75710000",
        "longitude": "4.72625000"
    },
    {
        "id": 45960,
        "name": "Saint-Genis-Pouilly",
        "latitude": "46.24356000",
        "longitude": "6.02119000"
    },
    {
        "id": 45963,
        "name": "Saint-Genix-sur-Guiers",
        "latitude": "45.59864000",
        "longitude": "5.63559000"
    },
    {
        "id": 45970,
        "name": "Saint-Geoire-en-Valdaine",
        "latitude": "45.45668000",
        "longitude": "5.63515000"
    },
    {
        "id": 45971,
        "name": "Saint-Georges-Buttavent",
        "latitude": "48.31018000",
        "longitude": "-0.69372000"
    },
    {
        "id": 45973,
        "name": "Saint-Georges-de-Commiers",
        "latitude": "45.04546000",
        "longitude": "5.70628000"
    },
    {
        "id": 45974,
        "name": "Saint-Georges-de-Didonne",
        "latitude": "45.60342000",
        "longitude": "-1.00487000"
    },
    {
        "id": 45975,
        "name": "Saint-Georges-de-Luzençon",
        "latitude": "44.06471000",
        "longitude": "2.98597000"
    },
    {
        "id": 45976,
        "name": "Saint-Georges-de-Mons",
        "latitude": "45.93941000",
        "longitude": "2.83866000"
    },
    {
        "id": 45977,
        "name": "Saint-Georges-de-Montaigu",
        "latitude": "46.94655000",
        "longitude": "-1.29262000"
    },
    {
        "id": 45978,
        "name": "Saint-Georges-de-Pointindoux",
        "latitude": "46.64462000",
        "longitude": "-1.62204000"
    },
    {
        "id": 45979,
        "name": "Saint-Georges-de-Reintembault",
        "latitude": "48.50744000",
        "longitude": "-1.24328000"
    },
    {
        "id": 45980,
        "name": "Saint-Georges-de-Reneins",
        "latitude": "46.06185000",
        "longitude": "4.72169000"
    },
    {
        "id": 45981,
        "name": "Saint-Georges-des-Coteaux",
        "latitude": "45.76667000",
        "longitude": "-0.71667000"
    },
    {
        "id": 45982,
        "name": "Saint-Georges-des-Groseillers",
        "latitude": "48.76993000",
        "longitude": "-0.56768000"
    },
    {
        "id": 45983,
        "name": "Saint-Georges-du-Bois",
        "latitude": "47.97242000",
        "longitude": "0.10113000"
    },
    {
        "id": 45984,
        "name": "Saint-Georges-du-Bois",
        "latitude": "46.14074000",
        "longitude": "-0.73393000"
    },
    {
        "id": 45972,
        "name": "Saint-Georges-Haute-Ville",
        "latitude": "45.55701000",
        "longitude": "4.09801000"
    },
    {
        "id": 45986,
        "name": "Saint-Georges-lès-Baillargeaux",
        "latitude": "46.67017000",
        "longitude": "0.40209000"
    },
    {
        "id": 45985,
        "name": "Saint-Georges-les-Bains",
        "latitude": "44.86100000",
        "longitude": "4.80924000"
    },
    {
        "id": 45987,
        "name": "Saint-Georges-sur-Allier",
        "latitude": "45.71015000",
        "longitude": "3.24261000"
    },
    {
        "id": 45988,
        "name": "Saint-Georges-sur-Baulche",
        "latitude": "47.80039000",
        "longitude": "3.53144000"
    },
    {
        "id": 45989,
        "name": "Saint-Georges-sur-Cher",
        "latitude": "47.32996000",
        "longitude": "1.13261000"
    },
    {
        "id": 45990,
        "name": "Saint-Georges-sur-Eure",
        "latitude": "48.41869000",
        "longitude": "1.35460000"
    },
    {
        "id": 45991,
        "name": "Saint-Georges-sur-Loire",
        "latitude": "47.40792000",
        "longitude": "-0.76194000"
    },
    {
        "id": 45992,
        "name": "Saint-Geours-de-Maremne",
        "latitude": "43.68936000",
        "longitude": "-1.22937000"
    },
    {
        "id": 46045,
        "name": "Saint-Gérand-le-Puy",
        "latitude": "46.25790000",
        "longitude": "3.51210000"
    },
    {
        "id": 46046,
        "name": "Saint-Géréon",
        "latitude": "47.36774000",
        "longitude": "-1.20262000"
    },
    {
        "id": 45993,
        "name": "Saint-Germain",
        "latitude": "47.72496000",
        "longitude": "6.53117000"
    },
    {
        "id": 45994,
        "name": "Saint-Germain",
        "latitude": "48.25736000",
        "longitude": "4.03264000"
    },
    {
        "id": 46005,
        "name": "Saint-Germain-de-la-Grange",
        "latitude": "48.83436000",
        "longitude": "1.89884000"
    },
    {
        "id": 46002,
        "name": "Saint-Germain-de-Lusignan",
        "latitude": "45.45011000",
        "longitude": "-0.46147000"
    },
    {
        "id": 46003,
        "name": "Saint-Germain-de-Marencennes",
        "latitude": "46.07733000",
        "longitude": "-0.79133000"
    },
    {
        "id": 46004,
        "name": "Saint-Germain-de-Prinçay",
        "latitude": "46.72107000",
        "longitude": "-1.02153000"
    },
    {
        "id": 46006,
        "name": "Saint-Germain-des-Fossés",
        "latitude": "46.20682000",
        "longitude": "3.43374000"
    },
    {
        "id": 46007,
        "name": "Saint-Germain-des-Prés",
        "latitude": "47.40998000",
        "longitude": "-0.83342000"
    },
    {
        "id": 46008,
        "name": "Saint-Germain-des-Prés",
        "latitude": "47.95384000",
        "longitude": "2.84846000"
    },
    {
        "id": 46009,
        "name": "Saint-Germain-du-Bois",
        "latitude": "46.75287000",
        "longitude": "5.24530000"
    },
    {
        "id": 46010,
        "name": "Saint-Germain-du-Corbéis",
        "latitude": "48.42215000",
        "longitude": "0.06193000"
    },
    {
        "id": 46011,
        "name": "Saint-Germain-du-Plain",
        "latitude": "46.69938000",
        "longitude": "4.98510000"
    },
    {
        "id": 46012,
        "name": "Saint-Germain-du-Puch",
        "latitude": "44.85000000",
        "longitude": "-0.31667000"
    },
    {
        "id": 46013,
        "name": "Saint-Germain-du-Puy",
        "latitude": "47.10000000",
        "longitude": "2.48333000"
    },
    {
        "id": 46014,
        "name": "Saint-Germain-en-Coglès",
        "latitude": "48.40531000",
        "longitude": "-1.26369000"
    },
    {
        "id": 46015,
        "name": "Saint-Germain-en-Laye",
        "latitude": "48.89643000",
        "longitude": "2.09040000"
    },
    {
        "id": 46016,
        "name": "Saint-Germain-la-Blanche-Herbe",
        "latitude": "49.18327000",
        "longitude": "-0.40426000"
    },
    {
        "id": 45995,
        "name": "Saint-Germain-Laprade",
        "latitude": "45.03862000",
        "longitude": "3.97004000"
    },
    {
        "id": 45996,
        "name": "Saint-Germain-Laval",
        "latitude": "48.39968000",
        "longitude": "2.99781000"
    },
    {
        "id": 45997,
        "name": "Saint-Germain-Laval",
        "latitude": "45.83249000",
        "longitude": "4.01444000"
    },
    {
        "id": 45998,
        "name": "Saint-Germain-Lembron",
        "latitude": "45.45793000",
        "longitude": "3.23973000"
    },
    {
        "id": 46018,
        "name": "Saint-Germain-lès-Arpajon",
        "latitude": "48.59733000",
        "longitude": "2.26481000"
    },
    {
        "id": 46017,
        "name": "Saint-Germain-les-Belles",
        "latitude": "45.61356000",
        "longitude": "1.49490000"
    },
    {
        "id": 46019,
        "name": "Saint-Germain-lès-Corbeil",
        "latitude": "48.62211000",
        "longitude": "2.48775000"
    },
    {
        "id": 45999,
        "name": "Saint-Germain-Lespinasse",
        "latitude": "46.10342000",
        "longitude": "3.96503000"
    },
    {
        "id": 46000,
        "name": "Saint-Germain-Nuelles",
        "latitude": "45.85242000",
        "longitude": "4.61182000"
    },
    {
        "id": 46020,
        "name": "Saint-Germain-sur-Avre",
        "latitude": "48.76466000",
        "longitude": "1.26776000"
    },
    {
        "id": 46021,
        "name": "Saint-Germain-sur-Moine",
        "latitude": "47.11746000",
        "longitude": "-1.12223000"
    },
    {
        "id": 46022,
        "name": "Saint-Germain-sur-Morin",
        "latitude": "48.88257000",
        "longitude": "2.85127000"
    },
    {
        "id": 46001,
        "name": "Saint-Germain-Village",
        "latitude": "49.34908000",
        "longitude": "0.50331000"
    },
    {
        "id": 46023,
        "name": "Saint-Germer-de-Fly",
        "latitude": "49.44281000",
        "longitude": "1.77986000"
    },
    {
        "id": 46024,
        "name": "Saint-Gervais",
        "latitude": "45.01583000",
        "longitude": "-0.45238000"
    },
    {
        "id": 46025,
        "name": "Saint-Gervais",
        "latitude": "46.90174000",
        "longitude": "-2.00210000"
    },
    {
        "id": 46026,
        "name": "Saint-Gervais-d’Auvergne",
        "latitude": "46.02916000",
        "longitude": "2.81901000"
    },
    {
        "id": 46027,
        "name": "Saint-Gervais-en-Belin",
        "latitude": "47.87720000",
        "longitude": "0.21770000"
    },
    {
        "id": 46028,
        "name": "Saint-Gervais-la-Forêt",
        "latitude": "47.56705000",
        "longitude": "1.35493000"
    },
    {
        "id": 46029,
        "name": "Saint-Gervais-les-Bains",
        "latitude": "45.89126000",
        "longitude": "6.71678000"
    },
    {
        "id": 46030,
        "name": "Saint-Gervais-les-Trois-Clochers",
        "latitude": "46.90067000",
        "longitude": "0.40766000"
    },
    {
        "id": 46031,
        "name": "Saint-Gervasy",
        "latitude": "43.87687000",
        "longitude": "4.46588000"
    },
    {
        "id": 46032,
        "name": "Saint-Gildas-de-Rhuys",
        "latitude": "47.50060000",
        "longitude": "-2.83825000"
    },
    {
        "id": 46033,
        "name": "Saint-Gildas-des-Bois",
        "latitude": "47.51622000",
        "longitude": "-2.03659000"
    },
    {
        "id": 46034,
        "name": "Saint-Gilles",
        "latitude": "43.67656000",
        "longitude": "4.43024000"
    },
    {
        "id": 46035,
        "name": "Saint-Gilles",
        "latitude": "48.15381000",
        "longitude": "-1.82477000"
    },
    {
        "id": 46036,
        "name": "Saint-Gilles-Croix-de-Vie",
        "latitude": "46.69761000",
        "longitude": "-1.94561000"
    },
    {
        "id": 46037,
        "name": "Saint-Giniez",
        "latitude": "43.26946000",
        "longitude": "5.38566000"
    },
    {
        "id": 46038,
        "name": "Saint-Girons",
        "latitude": "42.98491000",
        "longitude": "1.14587000"
    },
    {
        "id": 46039,
        "name": "Saint-Gobain",
        "latitude": "49.59572000",
        "longitude": "3.37750000"
    },
    {
        "id": 46040,
        "name": "Saint-Gonnery",
        "latitude": "48.12465000",
        "longitude": "-2.81848000"
    },
    {
        "id": 46041,
        "name": "Saint-Gratien",
        "latitude": "48.97350000",
        "longitude": "2.28729000"
    },
    {
        "id": 46042,
        "name": "Saint-Grégoire",
        "latitude": "48.15085000",
        "longitude": "-1.68706000"
    },
    {
        "id": 46072,
        "name": "Saint-Héand",
        "latitude": "45.52740000",
        "longitude": "4.37077000"
    },
    {
        "id": 46073,
        "name": "Saint-Hélen",
        "latitude": "48.47002000",
        "longitude": "-1.95894000"
    },
    {
        "id": 46047,
        "name": "Saint-Henri",
        "latitude": "43.36151000",
        "longitude": "5.33069000"
    },
    {
        "id": 46048,
        "name": "Saint-Herblain",
        "latitude": "47.21765000",
        "longitude": "-1.64841000"
    },
    {
        "id": 46049,
        "name": "Saint-Herblon",
        "latitude": "47.40786000",
        "longitude": "-1.09738000"
    },
    {
        "id": 46050,
        "name": "Saint-Hilaire",
        "latitude": "45.30000000",
        "longitude": "5.88333000"
    },
    {
        "id": 46051,
        "name": "Saint-Hilaire",
        "latitude": "44.53333000",
        "longitude": "0.71667000"
    },
    {
        "id": 46054,
        "name": "Saint-Hilaire-de-Brethmas",
        "latitude": "44.08003000",
        "longitude": "4.12478000"
    },
    {
        "id": 46055,
        "name": "Saint-Hilaire-de-Chaléons",
        "latitude": "47.10196000",
        "longitude": "-1.86690000"
    },
    {
        "id": 46056,
        "name": "Saint-Hilaire-de-Clisson",
        "latitude": "47.06222000",
        "longitude": "-1.30778000"
    },
    {
        "id": 46061,
        "name": "Saint-Hilaire-de-la-Côte",
        "latitude": "45.39162000",
        "longitude": "5.31590000"
    },
    {
        "id": 46057,
        "name": "Saint-Hilaire-de-Loulay",
        "latitude": "47.00190000",
        "longitude": "-1.33079000"
    },
    {
        "id": 46058,
        "name": "Saint-Hilaire-de-Riez",
        "latitude": "46.71308000",
        "longitude": "-1.92583000"
    },
    {
        "id": 46059,
        "name": "Saint-Hilaire-de-Talmont",
        "latitude": "46.47002000",
        "longitude": "-1.60359000"
    },
    {
        "id": 46060,
        "name": "Saint-Hilaire-de-Villefranche",
        "latitude": "45.85091000",
        "longitude": "-0.52993000"
    },
    {
        "id": 46062,
        "name": "Saint-Hilaire-des-Loges",
        "latitude": "46.47190000",
        "longitude": "-0.66393000"
    },
    {
        "id": 46063,
        "name": "Saint-Hilaire-du-Harcouët",
        "latitude": "48.57700000",
        "longitude": "-1.09004000"
    },
    {
        "id": 46064,
        "name": "Saint-Hilaire-du-Rosier",
        "latitude": "45.09942000",
        "longitude": "5.24946000"
    },
    {
        "id": 46065,
        "name": "Saint-Hilaire-la-Palud",
        "latitude": "46.26676000",
        "longitude": "-0.71380000"
    },
    {
        "id": 46066,
        "name": "Saint-Hilaire-lez-Cambrai",
        "latitude": "50.18419000",
        "longitude": "3.41327000"
    },
    {
        "id": 46052,
        "name": "Saint-Hilaire-Petitville",
        "latitude": "49.30250000",
        "longitude": "-1.21995000"
    },
    {
        "id": 46053,
        "name": "Saint-Hilaire-Saint-Mesmin",
        "latitude": "47.86614000",
        "longitude": "1.83351000"
    },
    {
        "id": 46067,
        "name": "Saint-Hippolyte",
        "latitude": "42.78550000",
        "longitude": "2.96620000"
    },
    {
        "id": 46068,
        "name": "Saint-Hippolyte",
        "latitude": "48.23156000",
        "longitude": "7.37121000"
    },
    {
        "id": 46069,
        "name": "Saint-Hippolyte",
        "latitude": "47.31880000",
        "longitude": "6.81617000"
    },
    {
        "id": 46070,
        "name": "Saint-Hippolyte",
        "latitude": "45.91884000",
        "longitude": "-0.89183000"
    },
    {
        "id": 46071,
        "name": "Saint-Hippolyte-du-Fort",
        "latitude": "43.96362000",
        "longitude": "3.85572000"
    },
    {
        "id": 46074,
        "name": "Saint-Ismier",
        "latitude": "45.25227000",
        "longitude": "5.83073000"
    },
    {
        "id": 46075,
        "name": "Saint-Jacques-de-la-Lande",
        "latitude": "48.06514000",
        "longitude": "-1.72086000"
    },
    {
        "id": 46076,
        "name": "Saint-Jacques-sur-Darnétal",
        "latitude": "49.43980000",
        "longitude": "1.20359000"
    },
    {
        "id": 46077,
        "name": "Saint-Jacut-les-Pins",
        "latitude": "47.68581000",
        "longitude": "-2.21514000"
    },
    {
        "id": 46078,
        "name": "Saint-James",
        "latitude": "48.52180000",
        "longitude": "-1.32629000"
    },
    {
        "id": 46079,
        "name": "Saint-Jans-Cappel",
        "latitude": "50.76387000",
        "longitude": "2.72227000"
    },
    {
        "id": 46080,
        "name": "Saint-Jean",
        "latitude": "47.24553000",
        "longitude": "-0.38413000"
    },
    {
        "id": 46081,
        "name": "Saint-Jean",
        "latitude": "43.66420000",
        "longitude": "1.49941000"
    },
    {
        "id": 46082,
        "name": "Saint-Jean du Désert",
        "latitude": "43.29331000",
        "longitude": "5.42134000"
    },
    {
        "id": 46083,
        "name": "Saint-Jean-Bonnefonds",
        "latitude": "45.45609000",
        "longitude": "4.44223000"
    },
    {
        "id": 46084,
        "name": "Saint-Jean-Brévelay",
        "latitude": "47.84497000",
        "longitude": "-2.72231000"
    },
    {
        "id": 46085,
        "name": "Saint-Jean-Cap-Ferrat",
        "latitude": "43.68843000",
        "longitude": "7.33361000"
    },
    {
        "id": 46112,
        "name": "Saint-Jean-d’Aulps",
        "latitude": "46.23438000",
        "longitude": "6.65327000"
    },
    {
        "id": 46113,
        "name": "Saint-Jean-d’Illac",
        "latitude": "44.80869000",
        "longitude": "-0.78565000"
    },
    {
        "id": 46087,
        "name": "Saint-Jean-de-Boiseau",
        "latitude": "47.19315000",
        "longitude": "-1.72340000"
    },
    {
        "id": 46088,
        "name": "Saint-Jean-de-Bournay",
        "latitude": "45.50093000",
        "longitude": "5.13818000"
    },
    {
        "id": 46089,
        "name": "Saint-Jean-de-Braye",
        "latitude": "47.91303000",
        "longitude": "1.97705000"
    },
    {
        "id": 46090,
        "name": "Saint-Jean-de-Fos",
        "latitude": "43.70100000",
        "longitude": "3.55171000"
    },
    {
        "id": 46091,
        "name": "Saint-Jean-de-Gonville",
        "latitude": "46.21298000",
        "longitude": "5.95047000"
    },
    {
        "id": 46105,
        "name": "Saint-Jean-de-la-Ruelle",
        "latitude": "47.91127000",
        "longitude": "1.86483000"
    },
    {
        "id": 46092,
        "name": "Saint-Jean-de-Liversay",
        "latitude": "46.26894000",
        "longitude": "-0.87385000"
    },
    {
        "id": 46093,
        "name": "Saint-Jean-de-Losne",
        "latitude": "47.10247000",
        "longitude": "5.26556000"
    },
    {
        "id": 46094,
        "name": "Saint-Jean-de-Luz",
        "latitude": "43.38871000",
        "longitude": "-1.66267000"
    },
    {
        "id": 46095,
        "name": "Saint-Jean-de-Maurienne",
        "latitude": "45.27534000",
        "longitude": "6.35293000"
    },
    {
        "id": 46096,
        "name": "Saint-Jean-de-Moirans",
        "latitude": "45.34506000",
        "longitude": "5.58536000"
    },
    {
        "id": 46097,
        "name": "Saint-Jean-de-Monts",
        "latitude": "46.79088000",
        "longitude": "-2.08219000"
    },
    {
        "id": 46098,
        "name": "Saint-Jean-de-Muzols",
        "latitude": "45.08179000",
        "longitude": "4.81396000"
    },
    {
        "id": 46099,
        "name": "Saint-Jean-de-Niost",
        "latitude": "45.83333000",
        "longitude": "5.21667000"
    },
    {
        "id": 46100,
        "name": "Saint-Jean-de-Sauves",
        "latitude": "46.84130000",
        "longitude": "0.09272000"
    },
    {
        "id": 46101,
        "name": "Saint-Jean-de-Sixt",
        "latitude": "45.92312000",
        "longitude": "6.41180000"
    },
    {
        "id": 46102,
        "name": "Saint-Jean-de-Soudain",
        "latitude": "45.56770000",
        "longitude": "5.42880000"
    },
    {
        "id": 46103,
        "name": "Saint-Jean-de-Thouars",
        "latitude": "46.96486000",
        "longitude": "-0.21114000"
    },
    {
        "id": 46104,
        "name": "Saint-Jean-de-Védas",
        "latitude": "43.57759000",
        "longitude": "3.82603000"
    },
    {
        "id": 46106,
        "name": "Saint-Jean-des-Baisants",
        "latitude": "49.09371000",
        "longitude": "-0.97289000"
    },
    {
        "id": 46107,
        "name": "Saint-Jean-des-Champs",
        "latitude": "48.82800000",
        "longitude": "-1.46487000"
    },
    {
        "id": 46108,
        "name": "Saint-Jean-des-Mauvrets",
        "latitude": "47.39857000",
        "longitude": "-0.44929000"
    },
    {
        "id": 46109,
        "name": "Saint-Jean-du-Cardonnay",
        "latitude": "49.50455000",
        "longitude": "1.01140000"
    },
    {
        "id": 46110,
        "name": "Saint-Jean-du-Falga",
        "latitude": "43.08647000",
        "longitude": "1.62780000"
    },
    {
        "id": 46111,
        "name": "Saint-Jean-du-Gard",
        "latitude": "44.10523000",
        "longitude": "3.88566000"
    },
    {
        "id": 46114,
        "name": "Saint-Jean-en-Royans",
        "latitude": "45.01968000",
        "longitude": "5.29223000"
    },
    {
        "id": 46115,
        "name": "Saint-Jean-la-Poterie",
        "latitude": "47.63581000",
        "longitude": "-2.12420000"
    },
    {
        "id": 46116,
        "name": "Saint-Jean-le-Blanc",
        "latitude": "47.89327000",
        "longitude": "1.91540000"
    },
    {
        "id": 46117,
        "name": "Saint-Jean-le-Vieux",
        "latitude": "46.03005000",
        "longitude": "5.38798000"
    },
    {
        "id": 46118,
        "name": "Saint-Jean-les-Deux-Jumeaux",
        "latitude": "48.95140000",
        "longitude": "3.01959000"
    },
    {
        "id": 46086,
        "name": "Saint-Jean-Pied-de-Port",
        "latitude": "43.16363000",
        "longitude": "-1.23738000"
    },
    {
        "id": 46119,
        "name": "Saint-Jean-sur-Couesnon",
        "latitude": "48.29013000",
        "longitude": "-1.36835000"
    },
    {
        "id": 46120,
        "name": "Saint-Jean-sur-Mayenne",
        "latitude": "48.13522000",
        "longitude": "-0.75613000"
    },
    {
        "id": 46121,
        "name": "Saint-Jean-sur-Veyle",
        "latitude": "46.25902000",
        "longitude": "4.91712000"
    },
    {
        "id": 46122,
        "name": "Saint-Jeannet",
        "latitude": "43.74718000",
        "longitude": "7.14299000"
    },
    {
        "id": 46123,
        "name": "Saint-Jeoire",
        "latitude": "46.13733000",
        "longitude": "6.46172000"
    },
    {
        "id": 46166,
        "name": "Saint-Jérôme",
        "latitude": "43.33404000",
        "longitude": "5.41733000"
    },
    {
        "id": 46124,
        "name": "Saint-Joachim",
        "latitude": "47.38349000",
        "longitude": "-2.19239000"
    },
    {
        "id": 46125,
        "name": "Saint-Jorioz",
        "latitude": "45.83074000",
        "longitude": "6.15792000"
    },
    {
        "id": 46126,
        "name": "Saint-Jory",
        "latitude": "43.74146000",
        "longitude": "1.37089000"
    },
    {
        "id": 46127,
        "name": "Saint-Joseph",
        "latitude": "45.55757000",
        "longitude": "4.62076000"
    },
    {
        "id": 46128,
        "name": "Saint-Joseph",
        "latitude": "43.34617000",
        "longitude": "5.37976000"
    },
    {
        "id": 46129,
        "name": "Saint-Joseph-de-Rivière",
        "latitude": "45.37572000",
        "longitude": "5.69643000"
    },
    {
        "id": 46130,
        "name": "Saint-Josse",
        "latitude": "50.46801000",
        "longitude": "1.66180000"
    },
    {
        "id": 46131,
        "name": "Saint-Jouan-des-Guérets",
        "latitude": "48.59932000",
        "longitude": "-1.97372000"
    },
    {
        "id": 46132,
        "name": "Saint-Jouin-Bruneval",
        "latitude": "49.64275000",
        "longitude": "0.16400000"
    },
    {
        "id": 46133,
        "name": "Saint-Jouvent",
        "latitude": "45.95680000",
        "longitude": "1.20500000"
    },
    {
        "id": 46165,
        "name": "Saint-Juéry",
        "latitude": "43.95000000",
        "longitude": "2.21667000"
    },
    {
        "id": 46134,
        "name": "Saint-Julien",
        "latitude": "47.40029000",
        "longitude": "5.14163000"
    },
    {
        "id": 46135,
        "name": "Saint-Julien",
        "latitude": "48.45142000",
        "longitude": "-2.81250000"
    },
    {
        "id": 46136,
        "name": "Saint-Julien",
        "latitude": "43.31334000",
        "longitude": "5.44935000"
    },
    {
        "id": 46137,
        "name": "Saint-Julien-Chapteuil",
        "latitude": "45.03486000",
        "longitude": "4.06110000"
    },
    {
        "id": 46139,
        "name": "Saint-Julien-de-Concelles",
        "latitude": "47.25285000",
        "longitude": "-1.38428000"
    },
    {
        "id": 46140,
        "name": "Saint-Julien-de-Peyrolas",
        "latitude": "44.28837000",
        "longitude": "4.56507000"
    },
    {
        "id": 46141,
        "name": "Saint-Julien-des-Landes",
        "latitude": "46.63940000",
        "longitude": "-1.71381000"
    },
    {
        "id": 46142,
        "name": "Saint-Julien-du-Sault",
        "latitude": "48.03188000",
        "longitude": "3.29556000"
    },
    {
        "id": 46143,
        "name": "Saint-Julien-en-Born",
        "latitude": "44.06311000",
        "longitude": "-1.22445000"
    },
    {
        "id": 46144,
        "name": "Saint-Julien-en-Genevois",
        "latitude": "46.14434000",
        "longitude": "6.08256000"
    },
    {
        "id": 46145,
        "name": "Saint-Julien-en-Saint-Alban",
        "latitude": "44.75417000",
        "longitude": "4.69678000"
    },
    {
        "id": 46148,
        "name": "Saint-Julien-lès-Metz",
        "latitude": "49.13288000",
        "longitude": "6.20240000"
    },
    {
        "id": 46146,
        "name": "Saint-Julien-les-Rosiers",
        "latitude": "44.17445000",
        "longitude": "4.10803000"
    },
    {
        "id": 46147,
        "name": "Saint-Julien-les-Villas",
        "latitude": "48.27127000",
        "longitude": "4.09901000"
    },
    {
        "id": 46138,
        "name": "Saint-Julien-Molin-Molette",
        "latitude": "45.32148000",
        "longitude": "4.61692000"
    },
    {
        "id": 46149,
        "name": "Saint-Junien",
        "latitude": "45.88867000",
        "longitude": "0.90143000"
    },
    {
        "id": 46150,
        "name": "Saint-Just",
        "latitude": "43.31700000",
        "longitude": "5.40587000"
    },
    {
        "id": 46151,
        "name": "Saint-Just",
        "latitude": "43.65806000",
        "longitude": "4.11472000"
    },
    {
        "id": 46152,
        "name": "Saint-Just",
        "latitude": "47.76581000",
        "longitude": "-1.96096000"
    },
    {
        "id": 46153,
        "name": "Saint-Just",
        "latitude": "49.10852000",
        "longitude": "1.44101000"
    },
    {
        "id": 46154,
        "name": "Saint-Just-Chaleyssin",
        "latitude": "45.59421000",
        "longitude": "4.99852000"
    },
    {
        "id": 46159,
        "name": "Saint-Just-d'Ardèche",
        "latitude": "44.30000000",
        "longitude": "4.61667000"
    },
    {
        "id": 46160,
        "name": "Saint-Just-de-Claix",
        "latitude": "45.07562000",
        "longitude": "5.28309000"
    },
    {
        "id": 46161,
        "name": "Saint-Just-en-Chaussée",
        "latitude": "49.50503000",
        "longitude": "2.43285000"
    },
    {
        "id": 46162,
        "name": "Saint-Just-en-Chevalet",
        "latitude": "45.91477000",
        "longitude": "3.84234000"
    },
    {
        "id": 46163,
        "name": "Saint-Just-la-Pendue",
        "latitude": "45.89423000",
        "longitude": "4.24129000"
    },
    {
        "id": 46164,
        "name": "Saint-Just-le-Martel",
        "latitude": "45.86351000",
        "longitude": "1.38829000"
    },
    {
        "id": 46155,
        "name": "Saint-Just-Luzac",
        "latitude": "45.80000000",
        "longitude": "-1.03333000"
    },
    {
        "id": 46156,
        "name": "Saint-Just-Malmont",
        "latitude": "45.33932000",
        "longitude": "4.31275000"
    },
    {
        "id": 46157,
        "name": "Saint-Just-Saint-Rambert",
        "latitude": "45.49973000",
        "longitude": "4.24141000"
    },
    {
        "id": 46158,
        "name": "Saint-Just-Sauvage",
        "latitude": "48.55478000",
        "longitude": "3.78449000"
    },
    {
        "id": 46167,
        "name": "Saint-Lambert",
        "latitude": "43.28606000",
        "longitude": "5.36000000"
    },
    {
        "id": 46168,
        "name": "Saint-Lambert-du-Lattay",
        "latitude": "47.30250000",
        "longitude": "-0.63321000"
    },
    {
        "id": 46169,
        "name": "Saint-Lambert-la-Potherie",
        "latitude": "47.48289000",
        "longitude": "-0.67789000"
    },
    {
        "id": 46170,
        "name": "Saint-Lary-Soulan",
        "latitude": "42.81713000",
        "longitude": "0.32238000"
    },
    {
        "id": 46171,
        "name": "Saint-Lattier",
        "latitude": "45.08815000",
        "longitude": "5.20352000"
    },
    {
        "id": 46172,
        "name": "Saint-Laurent",
        "latitude": "49.76449000",
        "longitude": "4.77193000"
    },
    {
        "id": 46173,
        "name": "Saint-Laurent-Blangy",
        "latitude": "50.29446000",
        "longitude": "2.80698000"
    },
    {
        "id": 46175,
        "name": "Saint-Laurent-de-Brévedent",
        "latitude": "49.52564000",
        "longitude": "0.25458000"
    },
    {
        "id": 46176,
        "name": "Saint-Laurent-de-Chamousset",
        "latitude": "45.73827000",
        "longitude": "4.46415000"
    },
    {
        "id": 46178,
        "name": "Saint-Laurent-de-la-Plaine",
        "latitude": "47.31738000",
        "longitude": "-0.80333000"
    },
    {
        "id": 46179,
        "name": "Saint-Laurent-de-la-Prée",
        "latitude": "45.98259000",
        "longitude": "-1.03625000"
    },
    {
        "id": 46180,
        "name": "Saint-Laurent-de-la-Salanque",
        "latitude": "42.77270000",
        "longitude": "2.98998000"
    },
    {
        "id": 46177,
        "name": "Saint-Laurent-de-Mure",
        "latitude": "45.68563000",
        "longitude": "5.04484000"
    },
    {
        "id": 46181,
        "name": "Saint-Laurent-des-Arbres",
        "latitude": "44.05493000",
        "longitude": "4.70026000"
    },
    {
        "id": 46182,
        "name": "Saint-Laurent-des-Autels",
        "latitude": "47.28524000",
        "longitude": "-1.18881000"
    },
    {
        "id": 46183,
        "name": "Saint-Laurent-du-Pape",
        "latitude": "44.82328000",
        "longitude": "4.76679000"
    },
    {
        "id": 46184,
        "name": "Saint-Laurent-du-Pont",
        "latitude": "45.39240000",
        "longitude": "5.73312000"
    },
    {
        "id": 46185,
        "name": "Saint-Laurent-du-Var",
        "latitude": "43.67323000",
        "longitude": "7.19000000"
    },
    {
        "id": 46186,
        "name": "Saint-Laurent-en-Grandvaux",
        "latitude": "46.58333000",
        "longitude": "5.95000000"
    },
    {
        "id": 46187,
        "name": "Saint-Laurent-en-Royans",
        "latitude": "45.02653000",
        "longitude": "5.32669000"
    },
    {
        "id": 46174,
        "name": "Saint-Laurent-Nouan",
        "latitude": "47.71667000",
        "longitude": "1.60000000"
    },
    {
        "id": 46188,
        "name": "Saint-Laurent-sur-Gorre",
        "latitude": "45.77052000",
        "longitude": "0.95859000"
    },
    {
        "id": 46189,
        "name": "Saint-Laurent-sur-Saône",
        "latitude": "46.30532000",
        "longitude": "4.84119000"
    },
    {
        "id": 46190,
        "name": "Saint-Laurent-sur-Sèvre",
        "latitude": "46.95809000",
        "longitude": "-0.89392000"
    },
    {
        "id": 46191,
        "name": "Saint-Lazare",
        "latitude": "43.30545000",
        "longitude": "5.37443000"
    },
    {
        "id": 46210,
        "name": "Saint-Léger-de-Montbrun",
        "latitude": "47.00000000",
        "longitude": "-0.13333000"
    },
    {
        "id": 46211,
        "name": "Saint-Léger-des-Bois",
        "latitude": "47.46238000",
        "longitude": "-0.70953000"
    },
    {
        "id": 46212,
        "name": "Saint-Léger-des-Vignes",
        "latitude": "46.84063000",
        "longitude": "3.45488000"
    },
    {
        "id": 46213,
        "name": "Saint-Léger-du-Bourg-Denis",
        "latitude": "49.43345000",
        "longitude": "1.15803000"
    },
    {
        "id": 46214,
        "name": "Saint-Léger-en-Yvelines",
        "latitude": "48.72173000",
        "longitude": "1.76638000"
    },
    {
        "id": 46216,
        "name": "Saint-Léger-lès-Domart",
        "latitude": "50.05208000",
        "longitude": "2.14067000"
    },
    {
        "id": 46215,
        "name": "Saint-Léger-les-Vignes",
        "latitude": "47.13587000",
        "longitude": "-1.73060000"
    },
    {
        "id": 46217,
        "name": "Saint-Léger-sous-Cholet",
        "latitude": "47.09405000",
        "longitude": "-0.91024000"
    },
    {
        "id": 46218,
        "name": "Saint-Léger-sur-Dheune",
        "latitude": "46.84548000",
        "longitude": "4.63607000"
    },
    {
        "id": 46219,
        "name": "Saint-Léger-sur-Roanne",
        "latitude": "46.04174000",
        "longitude": "3.99644000"
    },
    {
        "id": 46220,
        "name": "Saint-Léon-sur-l’Isle",
        "latitude": "45.11491000",
        "longitude": "0.50444000"
    },
    {
        "id": 46221,
        "name": "Saint-Léonard",
        "latitude": "50.69058000",
        "longitude": "1.62536000"
    },
    {
        "id": 46222,
        "name": "Saint-Léonard",
        "latitude": "49.74252000",
        "longitude": "0.35907000"
    },
    {
        "id": 46223,
        "name": "Saint-Léonard",
        "latitude": "48.21638000",
        "longitude": "6.94355000"
    },
    {
        "id": 46224,
        "name": "Saint-Léonard-de-Noblat",
        "latitude": "45.83566000",
        "longitude": "1.49174000"
    },
    {
        "id": 46192,
        "name": "Saint-Leu",
        "latitude": "46.73060000",
        "longitude": "4.50083000"
    },
    {
        "id": 46193,
        "name": "Saint-Leu-la-Forêt",
        "latitude": "49.01667000",
        "longitude": "2.25000000"
    },
    {
        "id": 46194,
        "name": "Saint-Lizier",
        "latitude": "43.00183000",
        "longitude": "1.13686000"
    },
    {
        "id": 46225,
        "name": "Saint-Lô",
        "latitude": "49.11624000",
        "longitude": "-1.09031000"
    },
    {
        "id": 46195,
        "name": "Saint-Loubès",
        "latitude": "44.91536000",
        "longitude": "-0.42703000"
    },
    {
        "id": 46196,
        "name": "Saint-Louis",
        "latitude": "47.59206000",
        "longitude": "7.55923000"
    },
    {
        "id": 46197,
        "name": "Saint-Louis",
        "latitude": "43.34809000",
        "longitude": "5.35463000"
    },
    {
        "id": 46198,
        "name": "Saint-Louis-de-Montferrand",
        "latitude": "44.95000000",
        "longitude": "-0.53543000"
    },
    {
        "id": 46199,
        "name": "Saint-Loup",
        "latitude": "43.27766000",
        "longitude": "5.43133000"
    },
    {
        "id": 46200,
        "name": "Saint-Loup-Cammas",
        "latitude": "43.69767000",
        "longitude": "1.48127000"
    },
    {
        "id": 46201,
        "name": "Saint-Loup-sur-Semouse",
        "latitude": "47.88345000",
        "longitude": "6.27530000"
    },
    {
        "id": 46202,
        "name": "Saint-Lubin-des-Joncherets",
        "latitude": "48.76667000",
        "longitude": "1.21667000"
    },
    {
        "id": 46203,
        "name": "Saint-Lumine-de-Clisson",
        "latitude": "47.08413000",
        "longitude": "-1.33524000"
    },
    {
        "id": 46204,
        "name": "Saint-Lumine-de-Coutais",
        "latitude": "47.05399000",
        "longitude": "-1.72777000"
    },
    {
        "id": 46205,
        "name": "Saint-Lunaire",
        "latitude": "48.63811000",
        "longitude": "-2.11392000"
    },
    {
        "id": 46206,
        "name": "Saint-Lupicin",
        "latitude": "46.40034000",
        "longitude": "5.79220000"
    },
    {
        "id": 46209,
        "name": "Saint-Lyé",
        "latitude": "48.36502000",
        "longitude": "3.99900000"
    },
    {
        "id": 46207,
        "name": "Saint-Lyphard",
        "latitude": "47.39822000",
        "longitude": "-2.30642000"
    },
    {
        "id": 46208,
        "name": "Saint-Lys",
        "latitude": "43.51127000",
        "longitude": "1.17557000"
    },
    {
        "id": 46226,
        "name": "Saint-Macaire",
        "latitude": "44.56527000",
        "longitude": "-0.22431000"
    },
    {
        "id": 46227,
        "name": "Saint-Macaire-en-Mauges",
        "latitude": "47.12357000",
        "longitude": "-0.99120000"
    },
    {
        "id": 46228,
        "name": "Saint-Maixant",
        "latitude": "44.57868000",
        "longitude": "-0.25920000"
    },
    {
        "id": 46229,
        "name": "Saint-Malo",
        "latitude": "48.64720000",
        "longitude": "-2.00883000"
    },
    {
        "id": 46230,
        "name": "Saint-Malo-de-Guersac",
        "latitude": "47.35345000",
        "longitude": "-2.17773000"
    },
    {
        "id": 46231,
        "name": "Saint-Malô-du-Bois",
        "latitude": "46.93333000",
        "longitude": "-0.90000000"
    },
    {
        "id": 46232,
        "name": "Saint-Mamert-du-Gard",
        "latitude": "43.88835000",
        "longitude": "4.18725000"
    },
    {
        "id": 46233,
        "name": "Saint-Mamet-la-Salvetat",
        "latitude": "44.85813000",
        "longitude": "2.30688000"
    },
    {
        "id": 46234,
        "name": "Saint-Mammès",
        "latitude": "48.38458000",
        "longitude": "2.81578000"
    },
    {
        "id": 46236,
        "name": "Saint-Mandé",
        "latitude": "48.83864000",
        "longitude": "2.41579000"
    },
    {
        "id": 46235,
        "name": "Saint-Mandrier-sur-Mer",
        "latitude": "43.07800000",
        "longitude": "5.92900000"
    },
    {
        "id": 46237,
        "name": "Saint-Manvieu",
        "latitude": "49.18067000",
        "longitude": "-0.50211000"
    },
    {
        "id": 46238,
        "name": "Saint-Manvieu-Norrey",
        "latitude": "49.18139000",
        "longitude": "-0.50000000"
    },
    {
        "id": 46239,
        "name": "Saint-Marc-Jaumegarde",
        "latitude": "43.54718000",
        "longitude": "5.52280000"
    },
    {
        "id": 46240,
        "name": "Saint-Marc-le-Blanc",
        "latitude": "48.36498000",
        "longitude": "-1.40938000"
    },
    {
        "id": 46241,
        "name": "Saint-Marcel",
        "latitude": "49.10000000",
        "longitude": "1.45000000"
    },
    {
        "id": 46242,
        "name": "Saint-Marcel",
        "latitude": "43.28745000",
        "longitude": "5.46604000"
    },
    {
        "id": 46243,
        "name": "Saint-Marcel",
        "latitude": "46.77371000",
        "longitude": "4.89205000"
    },
    {
        "id": 46244,
        "name": "Saint-Marcel",
        "latitude": "46.60193000",
        "longitude": "1.51324000"
    },
    {
        "id": 46245,
        "name": "Saint-Marcel-d'Ardèche",
        "latitude": "44.32726000",
        "longitude": "4.61769000"
    },
    {
        "id": 46246,
        "name": "Saint-Marcel-lès-Annonay",
        "latitude": "45.28613000",
        "longitude": "4.62616000"
    },
    {
        "id": 46247,
        "name": "Saint-Marcel-lès-Sauzet",
        "latitude": "44.59752000",
        "longitude": "4.80541000"
    },
    {
        "id": 46248,
        "name": "Saint-Marcel-lès-Valence",
        "latitude": "44.97132000",
        "longitude": "4.95316000"
    },
    {
        "id": 46249,
        "name": "Saint-Marcel-sur-Aude",
        "latitude": "43.25000000",
        "longitude": "2.93333000"
    },
    {
        "id": 46250,
        "name": "Saint-Marcellin",
        "latitude": "45.14914000",
        "longitude": "5.31673000"
    },
    {
        "id": 46251,
        "name": "Saint-Marcellin-en-Forez",
        "latitude": "45.49676000",
        "longitude": "4.17233000"
    },
    {
        "id": 46252,
        "name": "Saint-Mard",
        "latitude": "49.03701000",
        "longitude": "2.69645000"
    },
    {
        "id": 46253,
        "name": "Saint-Mariens",
        "latitude": "45.11631000",
        "longitude": "-0.40084000"
    },
    {
        "id": 46256,
        "name": "Saint-Mars-d’Outillé",
        "latitude": "47.87034000",
        "longitude": "0.33221000"
    },
    {
        "id": 46254,
        "name": "Saint-Mars-de-Coutais",
        "latitude": "47.11153000",
        "longitude": "-1.73437000"
    },
    {
        "id": 46255,
        "name": "Saint-Mars-du-Désert",
        "latitude": "47.36560000",
        "longitude": "-1.40678000"
    },
    {
        "id": 46257,
        "name": "Saint-Mars-la-Brière",
        "latitude": "48.03046000",
        "longitude": "0.37319000"
    },
    {
        "id": 46258,
        "name": "Saint-Mars-la-Jaille",
        "latitude": "47.52566000",
        "longitude": "-1.18483000"
    },
    {
        "id": 46267,
        "name": "Saint-Martin-au-Laërt",
        "latitude": "50.75179000",
        "longitude": "2.24051000"
    },
    {
        "id": 46259,
        "name": "Saint-Martin-Belle-Roche",
        "latitude": "46.38312000",
        "longitude": "4.85539000"
    },
    {
        "id": 46260,
        "name": "Saint-Martin-Bellevue",
        "latitude": "45.96262000",
        "longitude": "6.15763000"
    },
    {
        "id": 46261,
        "name": "Saint-Martin-Boulogne",
        "latitude": "50.72691000",
        "longitude": "1.61864000"
    },
    {
        "id": 46294,
        "name": "Saint-Martin-d’Auxigny",
        "latitude": "47.20371000",
        "longitude": "2.41553000"
    },
    {
        "id": 46295,
        "name": "Saint-Martin-d’Hères",
        "latitude": "45.17870000",
        "longitude": "5.76281000"
    },
    {
        "id": 46268,
        "name": "Saint-Martin-de-Belleville",
        "latitude": "45.38175000",
        "longitude": "6.50519000"
    },
    {
        "id": 46269,
        "name": "Saint-Martin-de-Boscherville",
        "latitude": "49.44377000",
        "longitude": "0.96271000"
    },
    {
        "id": 46270,
        "name": "Saint-Martin-de-Crau",
        "latitude": "43.63955000",
        "longitude": "4.81270000"
    },
    {
        "id": 46271,
        "name": "Saint-Martin-de-Fontenay",
        "latitude": "49.11523000",
        "longitude": "-0.37391000"
    },
    {
        "id": 46272,
        "name": "Saint-Martin-de-Hinx",
        "latitude": "43.58238000",
        "longitude": "-1.26809000"
    },
    {
        "id": 46281,
        "name": "Saint-Martin-de-la-Place",
        "latitude": "47.31747000",
        "longitude": "-0.14849000"
    },
    {
        "id": 46273,
        "name": "Saint-Martin-de-Landelles",
        "latitude": "48.54530000",
        "longitude": "-1.17240000"
    },
    {
        "id": 46274,
        "name": "Saint-Martin-de-Londres",
        "latitude": "43.79040000",
        "longitude": "3.73066000"
    },
    {
        "id": 46275,
        "name": "Saint-Martin-de-Nigelles",
        "latitude": "48.61234000",
        "longitude": "1.60920000"
    },
    {
        "id": 46276,
        "name": "Saint-Martin-de-Queyrières",
        "latitude": "44.82319000",
        "longitude": "6.57901000"
    },
    {
        "id": 46277,
        "name": "Saint-Martin-de-Ré",
        "latitude": "46.20311000",
        "longitude": "-1.36726000"
    },
    {
        "id": 46278,
        "name": "Saint-Martin-de-Seignanx",
        "latitude": "43.54283000",
        "longitude": "-1.38946000"
    },
    {
        "id": 46279,
        "name": "Saint-Martin-de-Valamas",
        "latitude": "44.93718000",
        "longitude": "4.36865000"
    },
    {
        "id": 46280,
        "name": "Saint-Martin-de-Valgalgues",
        "latitude": "44.16315000",
        "longitude": "4.08364000"
    },
    {
        "id": 46282,
        "name": "Saint-Martin-des-Besaces",
        "latitude": "49.01091000",
        "longitude": "-0.84500000"
    },
    {
        "id": 46283,
        "name": "Saint-Martin-des-Champs",
        "latitude": "48.66841000",
        "longitude": "-1.33393000"
    },
    {
        "id": 46284,
        "name": "Saint-Martin-des-Champs",
        "latitude": "48.58333000",
        "longitude": "-3.83333000"
    },
    {
        "id": 46285,
        "name": "Saint-Martin-des-Noyers",
        "latitude": "46.72226000",
        "longitude": "-1.17727000"
    },
    {
        "id": 46286,
        "name": "Saint-Martin-du-Fouilloux",
        "latitude": "47.43360000",
        "longitude": "-0.70357000"
    },
    {
        "id": 46287,
        "name": "Saint-Martin-du-Frêne",
        "latitude": "46.13591000",
        "longitude": "5.55049000"
    },
    {
        "id": 46288,
        "name": "Saint-Martin-du-Manoir",
        "latitude": "49.53213000",
        "longitude": "0.23759000"
    },
    {
        "id": 46289,
        "name": "Saint-Martin-du-Mont",
        "latitude": "46.09800000",
        "longitude": "5.31706000"
    },
    {
        "id": 46290,
        "name": "Saint-Martin-du-Tertre",
        "latitude": "49.10743000",
        "longitude": "2.34533000"
    },
    {
        "id": 46291,
        "name": "Saint-Martin-du-Tertre",
        "latitude": "48.21659000",
        "longitude": "3.26151000"
    },
    {
        "id": 46292,
        "name": "Saint-Martin-du-Var",
        "latitude": "43.81846000",
        "longitude": "7.19062000"
    },
    {
        "id": 46293,
        "name": "Saint-Martin-du-Vivier",
        "latitude": "49.46667000",
        "longitude": "1.16667000"
    },
    {
        "id": 46296,
        "name": "Saint-Martin-en-Bresse",
        "latitude": "46.81725000",
        "longitude": "5.06027000"
    },
    {
        "id": 46297,
        "name": "Saint-Martin-en-Campagne",
        "latitude": "49.95648000",
        "longitude": "1.22233000"
    },
    {
        "id": 46298,
        "name": "Saint-Martin-en-Haut",
        "latitude": "45.65984000",
        "longitude": "4.56180000"
    },
    {
        "id": 46299,
        "name": "Saint-Martin-la-Plaine",
        "latitude": "45.54635000",
        "longitude": "4.59749000"
    },
    {
        "id": 46262,
        "name": "Saint-Martin-Lacaussade",
        "latitude": "45.14631000",
        "longitude": "-0.64310000"
    },
    {
        "id": 46263,
        "name": "Saint-Martin-Lalande",
        "latitude": "43.29928000",
        "longitude": "2.02004000"
    },
    {
        "id": 46300,
        "name": "Saint-Martin-le-Beau",
        "latitude": "47.35566000",
        "longitude": "0.90953000"
    },
    {
        "id": 46301,
        "name": "Saint-Martin-le-Vinoux",
        "latitude": "45.20297000",
        "longitude": "5.71630000"
    },
    {
        "id": 46264,
        "name": "Saint-Martin-Longueau",
        "latitude": "49.34281000",
        "longitude": "2.60276000"
    },
    {
        "id": 46265,
        "name": "Saint-Martin-Osmonville",
        "latitude": "49.63873000",
        "longitude": "1.29952000"
    },
    {
        "id": 46302,
        "name": "Saint-Martin-sous-Mouzeuil",
        "latitude": "46.45905000",
        "longitude": "-0.98893000"
    },
    {
        "id": 46303,
        "name": "Saint-Martin-sur-Ocre",
        "latitude": "47.65942000",
        "longitude": "2.65810000"
    },
    {
        "id": 46304,
        "name": "Saint-Martin-sur-Oust",
        "latitude": "47.74600000",
        "longitude": "-2.25343000"
    },
    {
        "id": 46266,
        "name": "Saint-Martin-Vésubie",
        "latitude": "44.06892000",
        "longitude": "7.25583000"
    },
    {
        "id": 46305,
        "name": "Saint-Mathieu",
        "latitude": "45.70674000",
        "longitude": "0.75908000"
    },
    {
        "id": 46306,
        "name": "Saint-Mathieu-de-Tréviers",
        "latitude": "43.76795000",
        "longitude": "3.85814000"
    },
    {
        "id": 46307,
        "name": "Saint-Mathurin",
        "latitude": "46.56493000",
        "longitude": "-1.71389000"
    },
    {
        "id": 46308,
        "name": "Saint-Mathurin-sur-Loire",
        "latitude": "47.41667000",
        "longitude": "-0.31667000"
    },
    {
        "id": 46309,
        "name": "Saint-Maur",
        "latitude": "46.80657000",
        "longitude": "1.63904000"
    },
    {
        "id": 46310,
        "name": "Saint-Maur-des-Fossés",
        "latitude": "48.79395000",
        "longitude": "2.49323000"
    },
    {
        "id": 46311,
        "name": "Saint-Maurice",
        "latitude": "48.82182000",
        "longitude": "2.42716000"
    },
    {
        "id": 46313,
        "name": "Saint-Maurice-de-Beynost",
        "latitude": "45.83440000",
        "longitude": "4.97750000"
    },
    {
        "id": 46314,
        "name": "Saint-Maurice-de-Gourdans",
        "latitude": "45.82248000",
        "longitude": "5.19450000"
    },
    {
        "id": 46315,
        "name": "Saint-Maurice-de-Lignon",
        "latitude": "45.22430000",
        "longitude": "4.13880000"
    },
    {
        "id": 46316,
        "name": "Saint-Maurice-en-Gourgois",
        "latitude": "45.40127000",
        "longitude": "4.18253000"
    },
    {
        "id": 46317,
        "name": "Saint-Maurice-la-Clouère",
        "latitude": "46.37804000",
        "longitude": "0.41253000"
    },
    {
        "id": 46318,
        "name": "Saint-Maurice-la-Souterraine",
        "latitude": "46.21388000",
        "longitude": "1.43130000"
    },
    {
        "id": 46312,
        "name": "Saint-Maurice-Montcouronne",
        "latitude": "48.58288000",
        "longitude": "2.12504000"
    },
    {
        "id": 46319,
        "name": "Saint-Maurice-sur-Dargoire",
        "latitude": "45.58224000",
        "longitude": "4.63120000"
    },
    {
        "id": 46320,
        "name": "Saint-Maurice-sur-Fessard",
        "latitude": "47.99231000",
        "longitude": "2.62157000"
    },
    {
        "id": 46321,
        "name": "Saint-Maurice-sur-Moselle",
        "latitude": "47.85889000",
        "longitude": "6.82477000"
    },
    {
        "id": 46322,
        "name": "Saint-Mauront",
        "latitude": "43.31552000",
        "longitude": "5.37538000"
    },
    {
        "id": 46323,
        "name": "Saint-Max",
        "latitude": "48.70426000",
        "longitude": "6.20686000"
    },
    {
        "id": 46324,
        "name": "Saint-Maximin",
        "latitude": "49.22182000",
        "longitude": "2.45359000"
    },
    {
        "id": 46325,
        "name": "Saint-Maximin-la-Sainte-Baume",
        "latitude": "43.44813000",
        "longitude": "5.86081000"
    },
    {
        "id": 46326,
        "name": "Saint-Maxire",
        "latitude": "46.39911000",
        "longitude": "-0.47988000"
    },
    {
        "id": 46349,
        "name": "Saint-Médard-d’Eyrans",
        "latitude": "44.71667000",
        "longitude": "-0.51667000"
    },
    {
        "id": 46347,
        "name": "Saint-Médard-de-Guizières",
        "latitude": "45.01667000",
        "longitude": "-0.05000000"
    },
    {
        "id": 46348,
        "name": "Saint-Médard-de-Mussidan",
        "latitude": "45.03333000",
        "longitude": "0.35000000"
    },
    {
        "id": 46350,
        "name": "Saint-Médard-en-Jalles",
        "latitude": "44.89692000",
        "longitude": "-0.72136000"
    },
    {
        "id": 46351,
        "name": "Saint-Médard-sur-Ille",
        "latitude": "48.27202000",
        "longitude": "-1.65968000"
    },
    {
        "id": 46352,
        "name": "Saint-Méen-le-Grand",
        "latitude": "48.18971000",
        "longitude": "-2.19486000"
    },
    {
        "id": 46327,
        "name": "Saint-Melaine-sur-Aubance",
        "latitude": "47.36667000",
        "longitude": "-0.50000000"
    },
    {
        "id": 46353,
        "name": "Saint-Méloir-des-Ondes",
        "latitude": "48.63790000",
        "longitude": "-1.90448000"
    },
    {
        "id": 46354,
        "name": "Saint-Même-le-Tenu",
        "latitude": "47.02005000",
        "longitude": "-1.79459000"
    },
    {
        "id": 46355,
        "name": "Saint-Même-les-Carrières",
        "latitude": "45.65000000",
        "longitude": "-0.15000000"
    },
    {
        "id": 46328,
        "name": "Saint-Memmie",
        "latitude": "48.95247000",
        "longitude": "4.38409000"
    },
    {
        "id": 46329,
        "name": "Saint-Menet",
        "latitude": "43.28997000",
        "longitude": "5.50427000"
    },
    {
        "id": 46330,
        "name": "Saint-Menges",
        "latitude": "49.73873000",
        "longitude": "4.92628000"
    },
    {
        "id": 46331,
        "name": "Saint-Mesmin",
        "latitude": "46.79369000",
        "longitude": "-0.73262000"
    },
    {
        "id": 46332,
        "name": "Saint-Mexant",
        "latitude": "45.28514000",
        "longitude": "1.65799000"
    },
    {
        "id": 46333,
        "name": "Saint-Michel",
        "latitude": "45.65000000",
        "longitude": "0.10000000"
    },
    {
        "id": 46334,
        "name": "Saint-Michel",
        "latitude": "49.91952000",
        "longitude": "4.13278000"
    },
    {
        "id": 46335,
        "name": "Saint-Michel-Chef-Chef",
        "latitude": "47.18072000",
        "longitude": "-2.14869000"
    },
    {
        "id": 46337,
        "name": "Saint-Michel-de-Maurienne",
        "latitude": "45.22011000",
        "longitude": "6.46868000"
    },
    {
        "id": 46339,
        "name": "Saint-Michel-l’Observatoire",
        "latitude": "43.90977000",
        "longitude": "5.71584000"
    },
    {
        "id": 46338,
        "name": "Saint-Michel-le-Cloucq",
        "latitude": "46.48500000",
        "longitude": "-0.75262000"
    },
    {
        "id": 46336,
        "name": "Saint-Michel-Mont-Mercure",
        "latitude": "46.82941000",
        "longitude": "-0.88298000"
    },
    {
        "id": 46340,
        "name": "Saint-Michel-sur-Meurthe",
        "latitude": "48.32219000",
        "longitude": "6.89024000"
    },
    {
        "id": 46341,
        "name": "Saint-Michel-sur-Orge",
        "latitude": "48.63479000",
        "longitude": "2.30831000"
    },
    {
        "id": 46342,
        "name": "Saint-Mihiel",
        "latitude": "48.88746000",
        "longitude": "5.55099000"
    },
    {
        "id": 46343,
        "name": "Saint-Mitre",
        "latitude": "43.34640000",
        "longitude": "5.42274000"
    },
    {
        "id": 46344,
        "name": "Saint-Mitre-les-Remparts",
        "latitude": "43.45503000",
        "longitude": "5.01429000"
    },
    {
        "id": 46345,
        "name": "Saint-Molf",
        "latitude": "47.39167000",
        "longitude": "-2.42357000"
    },
    {
        "id": 46346,
        "name": "Saint-Morillon",
        "latitude": "44.65060000",
        "longitude": "-0.50322000"
    },
    {
        "id": 46356,
        "name": "Saint-Nabord",
        "latitude": "48.05171000",
        "longitude": "6.58248000"
    },
    {
        "id": 46357,
        "name": "Saint-Nauphary",
        "latitude": "43.96712000",
        "longitude": "1.42549000"
    },
    {
        "id": 46358,
        "name": "Saint-Nazaire",
        "latitude": "47.27956000",
        "longitude": "-2.20993000"
    },
    {
        "id": 46359,
        "name": "Saint-Nazaire",
        "latitude": "42.66790000",
        "longitude": "2.99168000"
    },
    {
        "id": 46360,
        "name": "Saint-Nazaire-d’Aude",
        "latitude": "43.24518000",
        "longitude": "2.89443000"
    },
    {
        "id": 46361,
        "name": "Saint-Nazaire-les-Eymes",
        "latitude": "45.24921000",
        "longitude": "5.85254000"
    },
    {
        "id": 46362,
        "name": "Saint-Nicolas",
        "latitude": "50.30413000",
        "longitude": "2.77939000"
    },
    {
        "id": 46369,
        "name": "Saint-Nicolas-d’Aliermont",
        "latitude": "49.87857000",
        "longitude": "1.22486000"
    },
    {
        "id": 46363,
        "name": "Saint-Nicolas-de-Bourgueil",
        "latitude": "47.28493000",
        "longitude": "0.12727000"
    },
    {
        "id": 46366,
        "name": "Saint-Nicolas-de-la-Grave",
        "latitude": "44.06417000",
        "longitude": "1.02280000"
    },
    {
        "id": 46367,
        "name": "Saint-Nicolas-de-la-Taille",
        "latitude": "49.51085000",
        "longitude": "0.47405000"
    },
    {
        "id": 46364,
        "name": "Saint-Nicolas-de-Port",
        "latitude": "48.62857000",
        "longitude": "6.29668000"
    },
    {
        "id": 46365,
        "name": "Saint-Nicolas-de-Redon",
        "latitude": "47.64343000",
        "longitude": "-2.06305000"
    },
    {
        "id": 46368,
        "name": "Saint-Nicolas-du-Pélem",
        "latitude": "48.31222000",
        "longitude": "-3.16465000"
    },
    {
        "id": 46370,
        "name": "Saint-Nizier-sous-Charlieu",
        "latitude": "46.15290000",
        "longitude": "4.12399000"
    },
    {
        "id": 46371,
        "name": "Saint-Nolff",
        "latitude": "47.70365000",
        "longitude": "-2.65209000"
    },
    {
        "id": 46372,
        "name": "Saint-Nom-la-Bretêche",
        "latitude": "48.85942000",
        "longitude": "2.02233000"
    },
    {
        "id": 46373,
        "name": "Saint-Omer",
        "latitude": "50.74834000",
        "longitude": "2.26091000"
    },
    {
        "id": 46374,
        "name": "Saint-Omer-en-Chaussée",
        "latitude": "49.53142000",
        "longitude": "2.00335000"
    },
    {
        "id": 46375,
        "name": "Saint-Orens-de-Gameville",
        "latitude": "43.55402000",
        "longitude": "1.53411000"
    },
    {
        "id": 46376,
        "name": "Saint-Ouen",
        "latitude": "48.90654000",
        "longitude": "2.33339000"
    },
    {
        "id": 46377,
        "name": "Saint-Ouen",
        "latitude": "47.81404000",
        "longitude": "1.08067000"
    },
    {
        "id": 46378,
        "name": "Saint-Ouen",
        "latitude": "50.03819000",
        "longitude": "2.12088000"
    },
    {
        "id": 46379,
        "name": "Saint-Ouen-de-Thouberville",
        "latitude": "49.35726000",
        "longitude": "0.88848000"
    },
    {
        "id": 46380,
        "name": "Saint-Ouen-des-Alleux",
        "latitude": "48.32805000",
        "longitude": "-1.42590000"
    },
    {
        "id": 46385,
        "name": "Saint-Ouën-des-Toits",
        "latitude": "48.13333000",
        "longitude": "-0.90000000"
    },
    {
        "id": 46381,
        "name": "Saint-Ouen-du-Tilleul",
        "latitude": "49.29723000",
        "longitude": "0.94760000"
    },
    {
        "id": 46382,
        "name": "Saint-Ouen-en-Belin",
        "latitude": "47.83302000",
        "longitude": "0.20924000"
    },
    {
        "id": 46383,
        "name": "Saint-Ouen-l’Aumône",
        "latitude": "49.04353000",
        "longitude": "2.12134000"
    },
    {
        "id": 46384,
        "name": "Saint-Ours",
        "latitude": "45.85024000",
        "longitude": "2.89163000"
    },
    {
        "id": 46386,
        "name": "Saint-Pabu",
        "latitude": "48.56667000",
        "longitude": "-4.60000000"
    },
    {
        "id": 46420,
        "name": "Saint-Paër",
        "latitude": "49.51452000",
        "longitude": "0.87935000"
    },
    {
        "id": 46387,
        "name": "Saint-Pair-sur-Mer",
        "latitude": "48.81455000",
        "longitude": "-1.56761000"
    },
    {
        "id": 46388,
        "name": "Saint-Pal-de-Mons",
        "latitude": "45.24634000",
        "longitude": "4.27416000"
    },
    {
        "id": 46389,
        "name": "Saint-Palais",
        "latitude": "43.32867000",
        "longitude": "-1.03333000"
    },
    {
        "id": 46390,
        "name": "Saint-Palais-sur-Mer",
        "latitude": "45.64255000",
        "longitude": "-1.08810000"
    },
    {
        "id": 46391,
        "name": "Saint-Pantaléon-de-Larche",
        "latitude": "45.14122000",
        "longitude": "1.44652000"
    },
    {
        "id": 46392,
        "name": "Saint-Pardoux",
        "latitude": "46.57155000",
        "longitude": "-0.30542000"
    },
    {
        "id": 46393,
        "name": "Saint-Pardoux-Isaac",
        "latitude": "44.61190000",
        "longitude": "0.37345000"
    },
    {
        "id": 46394,
        "name": "Saint-Pardoux-la-Rivière",
        "latitude": "45.49388000",
        "longitude": "0.74651000"
    },
    {
        "id": 46395,
        "name": "Saint-Pargoire",
        "latitude": "43.52766000",
        "longitude": "3.51870000"
    },
    {
        "id": 46396,
        "name": "Saint-Parize-le-Châtel",
        "latitude": "46.85447000",
        "longitude": "3.18267000"
    },
    {
        "id": 46397,
        "name": "Saint-Parres-aux-Tertres",
        "latitude": "48.29780000",
        "longitude": "4.11752000"
    },
    {
        "id": 46398,
        "name": "Saint-Paterne",
        "latitude": "48.41614000",
        "longitude": "0.11271000"
    },
    {
        "id": 46399,
        "name": "Saint-Paterne-Racan",
        "latitude": "47.60213000",
        "longitude": "0.48478000"
    },
    {
        "id": 46400,
        "name": "Saint-Pathus",
        "latitude": "49.07136000",
        "longitude": "2.79886000"
    },
    {
        "id": 46401,
        "name": "Saint-Paul",
        "latitude": "49.42962000",
        "longitude": "2.00755000"
    },
    {
        "id": 46402,
        "name": "Saint-Paul",
        "latitude": "45.39840000",
        "longitude": "4.22617000"
    },
    {
        "id": 46403,
        "name": "Saint-Paul",
        "latitude": "45.75114000",
        "longitude": "1.43238000"
    },
    {
        "id": 46404,
        "name": "Saint-Paul-Cap-de-Joux",
        "latitude": "43.64791000",
        "longitude": "1.97559000"
    },
    {
        "id": 46406,
        "name": "Saint-Paul-de-Fenouillet",
        "latitude": "42.80938000",
        "longitude": "2.50374000"
    },
    {
        "id": 46407,
        "name": "Saint-Paul-de-Jarrat",
        "latitude": "42.91404000",
        "longitude": "1.65437000"
    },
    {
        "id": 46408,
        "name": "Saint-Paul-de-Varax",
        "latitude": "46.09692000",
        "longitude": "5.12959000"
    },
    {
        "id": 46409,
        "name": "Saint-Paul-de-Varces",
        "latitude": "45.07175000",
        "longitude": "5.64247000"
    },
    {
        "id": 46410,
        "name": "Saint-Paul-de-Vence",
        "latitude": "43.70000000",
        "longitude": "7.11667000"
    },
    {
        "id": 46411,
        "name": "Saint-Paul-des-Landes",
        "latitude": "44.94356000",
        "longitude": "2.31439000"
    },
    {
        "id": 46412,
        "name": "Saint-Paul-en-Chablais",
        "latitude": "46.37958000",
        "longitude": "6.62483000"
    },
    {
        "id": 46413,
        "name": "Saint-Paul-en-Forêt",
        "latitude": "43.56709000",
        "longitude": "6.69206000"
    },
    {
        "id": 46414,
        "name": "Saint-Paul-en-Jarez",
        "latitude": "45.48512000",
        "longitude": "4.57113000"
    },
    {
        "id": 46415,
        "name": "Saint-Paul-en-Pareds",
        "latitude": "46.82214000",
        "longitude": "-0.98977000"
    },
    {
        "id": 46416,
        "name": "Saint-Paul-lès-Dax",
        "latitude": "43.72715000",
        "longitude": "-1.05162000"
    },
    {
        "id": 46417,
        "name": "Saint-Paul-lès-Romans",
        "latitude": "45.06725000",
        "longitude": "5.13213000"
    },
    {
        "id": 46405,
        "name": "Saint-Paul-Trois-Châteaux",
        "latitude": "44.34594000",
        "longitude": "4.76356000"
    },
    {
        "id": 46418,
        "name": "Saint-Paulet-de-Caisson",
        "latitude": "44.26055000",
        "longitude": "4.59785000"
    },
    {
        "id": 46419,
        "name": "Saint-Paulien",
        "latitude": "45.13629000",
        "longitude": "3.81290000"
    },
    {
        "id": 46480,
        "name": "Saint-Pé-de-Bigorre",
        "latitude": "43.10369000",
        "longitude": "-0.15943000"
    },
    {
        "id": 46481,
        "name": "Saint-Pée-sur-Nivelle",
        "latitude": "43.35564000",
        "longitude": "-1.55013000"
    },
    {
        "id": 46482,
        "name": "Saint-Péray",
        "latitude": "44.94866000",
        "longitude": "4.84510000"
    },
    {
        "id": 46421,
        "name": "Saint-Perdon",
        "latitude": "43.86560000",
        "longitude": "-0.59069000"
    },
    {
        "id": 46477,
        "name": "Saint-Père",
        "latitude": "48.58727000",
        "longitude": "-1.92413000"
    },
    {
        "id": 46478,
        "name": "Saint-Père-en-Retz",
        "latitude": "47.20559000",
        "longitude": "-2.04095000"
    },
    {
        "id": 46479,
        "name": "Saint-Père-sur-Loire",
        "latitude": "47.76667000",
        "longitude": "2.36667000"
    },
    {
        "id": 46422,
        "name": "Saint-Perreux",
        "latitude": "47.66952000",
        "longitude": "-2.10809000"
    },
    {
        "id": 46423,
        "name": "Saint-Philbert-de-Bouaine",
        "latitude": "46.98568000",
        "longitude": "-1.52022000"
    },
    {
        "id": 46424,
        "name": "Saint-Philbert-de-Grand-Lieu",
        "latitude": "47.03580000",
        "longitude": "-1.64120000"
    },
    {
        "id": 46425,
        "name": "Saint-Philbert-du-Peuple",
        "latitude": "47.39314000",
        "longitude": "-0.04360000"
    },
    {
        "id": 46426,
        "name": "Saint-Philibert",
        "latitude": "47.58821000",
        "longitude": "-2.99978000"
    },
    {
        "id": 46427,
        "name": "Saint-Piat",
        "latitude": "48.54668000",
        "longitude": "1.58363000"
    },
    {
        "id": 46428,
        "name": "Saint-Pierre",
        "latitude": "43.29282000",
        "longitude": "5.40682000"
    },
    {
        "id": 46444,
        "name": "Saint-Pierre-d’Aurillac",
        "latitude": "44.57168000",
        "longitude": "-0.18922000"
    },
    {
        "id": 46445,
        "name": "Saint-Pierre-d’Oléron",
        "latitude": "45.94430000",
        "longitude": "-1.30630000"
    },
    {
        "id": 46431,
        "name": "Saint-Pierre-de-Bœuf",
        "latitude": "45.36667000",
        "longitude": "4.75000000"
    },
    {
        "id": 46432,
        "name": "Saint-Pierre-de-Chandieu",
        "latitude": "45.64625000",
        "longitude": "5.01481000"
    },
    {
        "id": 46433,
        "name": "Saint-Pierre-de-Plesguen",
        "latitude": "48.44638000",
        "longitude": "-1.91278000"
    },
    {
        "id": 46434,
        "name": "Saint-Pierre-de-Varengeville",
        "latitude": "49.50240000",
        "longitude": "0.93118000"
    },
    {
        "id": 46435,
        "name": "Saint-Pierre-des-Corps",
        "latitude": "47.38623000",
        "longitude": "0.74849000"
    },
    {
        "id": 46438,
        "name": "Saint-Pierre-des-Échaubrognes",
        "latitude": "46.98988000",
        "longitude": "-0.74441000"
    },
    {
        "id": 46436,
        "name": "Saint-Pierre-des-Fleurs",
        "latitude": "49.25000000",
        "longitude": "0.96667000"
    },
    {
        "id": 46437,
        "name": "Saint-Pierre-des-Nids",
        "latitude": "48.39826000",
        "longitude": "-0.09984000"
    },
    {
        "id": 46439,
        "name": "Saint-Pierre-du-Chemin",
        "latitude": "46.69523000",
        "longitude": "-0.70095000"
    },
    {
        "id": 46440,
        "name": "Saint-Pierre-du-Mont",
        "latitude": "43.88453000",
        "longitude": "-0.52185000"
    },
    {
        "id": 46441,
        "name": "Saint-Pierre-du-Perray",
        "latitude": "48.61064000",
        "longitude": "2.49429000"
    },
    {
        "id": 46442,
        "name": "Saint-Pierre-du-Regard",
        "latitude": "48.84286000",
        "longitude": "-0.54631000"
    },
    {
        "id": 46443,
        "name": "Saint-Pierre-du-Vauvray",
        "latitude": "49.23208000",
        "longitude": "1.22125000"
    },
    {
        "id": 46453,
        "name": "Saint-Pierre-Église",
        "latitude": "49.66848000",
        "longitude": "-1.40358000"
    },
    {
        "id": 46446,
        "name": "Saint-Pierre-en-Val",
        "latitude": "50.02168000",
        "longitude": "1.44707000"
    },
    {
        "id": 46447,
        "name": "Saint-Pierre-la-Cour",
        "latitude": "48.11258000",
        "longitude": "-1.02496000"
    },
    {
        "id": 46448,
        "name": "Saint-Pierre-la-Palud",
        "latitude": "45.79092000",
        "longitude": "4.61177000"
    },
    {
        "id": 46449,
        "name": "Saint-Pierre-le-Moûtier",
        "latitude": "46.79277000",
        "longitude": "3.11657000"
    },
    {
        "id": 46450,
        "name": "Saint-Pierre-lès-Elbeuf",
        "latitude": "49.27901000",
        "longitude": "1.04305000"
    },
    {
        "id": 46451,
        "name": "Saint-Pierre-lès-Nemours",
        "latitude": "48.26733000",
        "longitude": "2.67966000"
    },
    {
        "id": 46429,
        "name": "Saint-Pierre-Montlimart",
        "latitude": "47.26976000",
        "longitude": "-1.02738000"
    },
    {
        "id": 46430,
        "name": "Saint-Pierre-Quiberon",
        "latitude": "47.52061000",
        "longitude": "-3.13084000"
    },
    {
        "id": 46452,
        "name": "Saint-Pierre-sur-Dives",
        "latitude": "49.01667000",
        "longitude": "-0.03333000"
    },
    {
        "id": 46454,
        "name": "Saint-Planchers",
        "latitude": "48.82269000",
        "longitude": "-1.52598000"
    },
    {
        "id": 46455,
        "name": "Saint-Pol-de-Léon",
        "latitude": "48.68333000",
        "longitude": "-3.98333000"
    },
    {
        "id": 46456,
        "name": "Saint-Pol-sur-Mer",
        "latitude": "51.03116000",
        "longitude": "2.33983000"
    },
    {
        "id": 46457,
        "name": "Saint-Pol-sur-Ternoise",
        "latitude": "50.38113000",
        "longitude": "2.33407000"
    },
    {
        "id": 46458,
        "name": "Saint-Pons-de-Thomières",
        "latitude": "43.48333000",
        "longitude": "2.76667000"
    },
    {
        "id": 46459,
        "name": "Saint-Porchaire",
        "latitude": "45.82075000",
        "longitude": "-0.78235000"
    },
    {
        "id": 46460,
        "name": "Saint-Porquier",
        "latitude": "44.00344000",
        "longitude": "1.17932000"
    },
    {
        "id": 46461,
        "name": "Saint-Pouange",
        "latitude": "48.22656000",
        "longitude": "4.03979000"
    },
    {
        "id": 46462,
        "name": "Saint-Pourçain-sur-Sioule",
        "latitude": "46.30748000",
        "longitude": "3.28931000"
    },
    {
        "id": 46463,
        "name": "Saint-Prest",
        "latitude": "48.49107000",
        "longitude": "1.53034000"
    },
    {
        "id": 46464,
        "name": "Saint-Priest",
        "latitude": "45.69651000",
        "longitude": "4.94385000"
    },
    {
        "id": 46466,
        "name": "Saint-Priest-en-Jarez",
        "latitude": "45.47390000",
        "longitude": "4.37678000"
    },
    {
        "id": 46467,
        "name": "Saint-Priest-sous-Aixe",
        "latitude": "45.81667000",
        "longitude": "1.10000000"
    },
    {
        "id": 46465,
        "name": "Saint-Priest-Taurion",
        "latitude": "45.88686000",
        "longitude": "1.40016000"
    },
    {
        "id": 46468,
        "name": "Saint-Privat",
        "latitude": "44.62859000",
        "longitude": "4.41489000"
    },
    {
        "id": 46469,
        "name": "Saint-Privat",
        "latitude": "45.13831000",
        "longitude": "2.09902000"
    },
    {
        "id": 46470,
        "name": "Saint-Privat-des-Vieux",
        "latitude": "44.14415000",
        "longitude": "4.12988000"
    },
    {
        "id": 46471,
        "name": "Saint-Privat-la-Montagne",
        "latitude": "49.18768000",
        "longitude": "6.03874000"
    },
    {
        "id": 46472,
        "name": "Saint-Prix",
        "latitude": "49.01667000",
        "longitude": "2.26667000"
    },
    {
        "id": 46473,
        "name": "Saint-Projet-Saint-Constant",
        "latitude": "45.72802000",
        "longitude": "0.33851000"
    },
    {
        "id": 46474,
        "name": "Saint-Prouant",
        "latitude": "46.75822000",
        "longitude": "-0.95703000"
    },
    {
        "id": 46475,
        "name": "Saint-Pryvé-Saint-Mesmin",
        "latitude": "47.88177000",
        "longitude": "1.86950000"
    },
    {
        "id": 46476,
        "name": "Saint-Python",
        "latitude": "50.19001000",
        "longitude": "3.48027000"
    },
    {
        "id": 46483,
        "name": "Saint-Quay-Perros",
        "latitude": "48.78333000",
        "longitude": "-3.45000000"
    },
    {
        "id": 46484,
        "name": "Saint-Quay-Portrieux",
        "latitude": "48.64992000",
        "longitude": "-2.83059000"
    },
    {
        "id": 46485,
        "name": "Saint-Quentin",
        "latitude": "49.84889000",
        "longitude": "3.28757000"
    },
    {
        "id": 46487,
        "name": "Saint-Quentin-de-Baron",
        "latitude": "44.81802000",
        "longitude": "-0.28636000"
    },
    {
        "id": 46488,
        "name": "Saint-Quentin-en-Mauges",
        "latitude": "47.29130000",
        "longitude": "-0.91191000"
    },
    {
        "id": 46489,
        "name": "Saint-Quentin-en-Yvelines",
        "latitude": "48.77186000",
        "longitude": "2.01891000"
    },
    {
        "id": 46486,
        "name": "Saint-Quentin-Fallavier",
        "latitude": "45.64335000",
        "longitude": "5.11266000"
    },
    {
        "id": 46490,
        "name": "Saint-Quentin-la-Poterie",
        "latitude": "44.04392000",
        "longitude": "4.44432000"
    },
    {
        "id": 46491,
        "name": "Saint-Quentin-sur-Isère",
        "latitude": "45.27843000",
        "longitude": "5.54463000"
    },
    {
        "id": 46492,
        "name": "Saint-Quentin-sur-le-Homme",
        "latitude": "48.64751000",
        "longitude": "-1.31806000"
    },
    {
        "id": 46493,
        "name": "Saint-Rambert-en-Bugey",
        "latitude": "45.94734000",
        "longitude": "5.43630000"
    },
    {
        "id": 46494,
        "name": "Saint-Raphaël",
        "latitude": "43.42332000",
        "longitude": "6.77350000"
    },
    {
        "id": 46508,
        "name": "Saint-Rémy",
        "latitude": "46.76334000",
        "longitude": "4.83928000"
    },
    {
        "id": 46509,
        "name": "Saint-Rémy",
        "latitude": "48.94007000",
        "longitude": "-0.50344000"
    },
    {
        "id": 46510,
        "name": "Saint-Rémy-de-Maurienne",
        "latitude": "45.40000000",
        "longitude": "6.26667000"
    },
    {
        "id": 46511,
        "name": "Saint-Rémy-de-Provence",
        "latitude": "43.78848000",
        "longitude": "4.83167000"
    },
    {
        "id": 46512,
        "name": "Saint-Rémy-en-Mauges",
        "latitude": "47.27202000",
        "longitude": "-1.07499000"
    },
    {
        "id": 46513,
        "name": "Saint-Rémy-en-Rollat",
        "latitude": "46.18330000",
        "longitude": "3.39106000"
    },
    {
        "id": 46514,
        "name": "Saint-Rémy-lès-Chevreuse",
        "latitude": "48.70708000",
        "longitude": "2.07692000"
    },
    {
        "id": 46515,
        "name": "Saint-Rémy-sur-Avre",
        "latitude": "48.76143000",
        "longitude": "1.24532000"
    },
    {
        "id": 46516,
        "name": "Saint-Rémy-sur-Durolle",
        "latitude": "45.88815000",
        "longitude": "3.59257000"
    },
    {
        "id": 46495,
        "name": "Saint-Renan",
        "latitude": "48.43333000",
        "longitude": "-4.61667000"
    },
    {
        "id": 46496,
        "name": "Saint-Restitut",
        "latitude": "44.32446000",
        "longitude": "4.79282000"
    },
    {
        "id": 46497,
        "name": "Saint-Riquier",
        "latitude": "50.13235000",
        "longitude": "1.94755000"
    },
    {
        "id": 46498,
        "name": "Saint-Rogatien",
        "latitude": "46.15000000",
        "longitude": "-1.06963000"
    },
    {
        "id": 46499,
        "name": "Saint-Romain-de-Benet",
        "latitude": "45.69150000",
        "longitude": "-0.84765000"
    },
    {
        "id": 46500,
        "name": "Saint-Romain-de-Colbosc",
        "latitude": "49.53093000",
        "longitude": "0.35719000"
    },
    {
        "id": 46501,
        "name": "Saint-Romain-de-Jalionas",
        "latitude": "45.75677000",
        "longitude": "5.21741000"
    },
    {
        "id": 46502,
        "name": "Saint-Romain-de-Popey",
        "latitude": "45.84725000",
        "longitude": "4.53118000"
    },
    {
        "id": 46503,
        "name": "Saint-Romain-en-Gal",
        "latitude": "45.53255000",
        "longitude": "4.86190000"
    },
    {
        "id": 46504,
        "name": "Saint-Romain-la-Motte",
        "latitude": "46.08150000",
        "longitude": "3.99076000"
    },
    {
        "id": 46505,
        "name": "Saint-Romain-le-Puy",
        "latitude": "45.55837000",
        "longitude": "4.12296000"
    },
    {
        "id": 46506,
        "name": "Saint-Romain-sur-Cher",
        "latitude": "47.31877000",
        "longitude": "1.39956000"
    },
    {
        "id": 46507,
        "name": "Saint-Romans",
        "latitude": "45.11278000",
        "longitude": "5.32239000"
    },
    {
        "id": 46544,
        "name": "Saint-Saëns",
        "latitude": "49.67302000",
        "longitude": "1.28525000"
    },
    {
        "id": 46517,
        "name": "Saint-Samson-sur-Rance",
        "latitude": "48.49252000",
        "longitude": "-2.02865000"
    },
    {
        "id": 46518,
        "name": "Saint-Satur",
        "latitude": "47.33914000",
        "longitude": "2.83734000"
    },
    {
        "id": 46519,
        "name": "Saint-Saturnin",
        "latitude": "45.66667000",
        "longitude": "0.05000000"
    },
    {
        "id": 46520,
        "name": "Saint-Saturnin",
        "latitude": "48.05795000",
        "longitude": "0.15218000"
    },
    {
        "id": 46521,
        "name": "Saint-Saturnin",
        "latitude": "45.65956000",
        "longitude": "3.09232000"
    },
    {
        "id": 46522,
        "name": "Saint-Saturnin-lès-Apt",
        "latitude": "43.93333000",
        "longitude": "5.38333000"
    },
    {
        "id": 46523,
        "name": "Saint-Saturnin-lès-Avignon",
        "latitude": "43.95501000",
        "longitude": "4.92548000"
    },
    {
        "id": 46524,
        "name": "Saint-Saturnin-sur-Loire",
        "latitude": "47.39438000",
        "longitude": "-0.43565000"
    },
    {
        "id": 46525,
        "name": "Saint-Saulve",
        "latitude": "50.37141000",
        "longitude": "3.55612000"
    },
    {
        "id": 46526,
        "name": "Saint-Sauvant",
        "latitude": "46.35965000",
        "longitude": "0.05634000"
    },
    {
        "id": 46527,
        "name": "Saint-Sauves-d'Auvergne",
        "latitude": "45.60000000",
        "longitude": "2.68333000"
    },
    {
        "id": 46528,
        "name": "Saint-Sauveur",
        "latitude": "49.31857000",
        "longitude": "2.78321000"
    },
    {
        "id": 46529,
        "name": "Saint-Sauveur",
        "latitude": "45.15335000",
        "longitude": "5.35280000"
    },
    {
        "id": 46530,
        "name": "Saint-Sauveur",
        "latitude": "47.80530000",
        "longitude": "6.38583000"
    },
    {
        "id": 46531,
        "name": "Saint-Sauveur",
        "latitude": "43.74830000",
        "longitude": "1.40085000"
    },
    {
        "id": 46532,
        "name": "Saint-Sauveur",
        "latitude": "45.20272000",
        "longitude": "-0.83433000"
    },
    {
        "id": 46536,
        "name": "Saint-Sauveur-d’Aunis",
        "latitude": "46.21716000",
        "longitude": "-0.88580000"
    },
    {
        "id": 46534,
        "name": "Saint-Sauveur-de-Montagut",
        "latitude": "44.82142000",
        "longitude": "4.57993000"
    },
    {
        "id": 46535,
        "name": "Saint-Sauveur-des-Landes",
        "latitude": "48.34278000",
        "longitude": "-1.31248000"
    },
    {
        "id": 46537,
        "name": "Saint-Sauveur-en-Rue",
        "latitude": "45.26981000",
        "longitude": "4.49512000"
    },
    {
        "id": 46538,
        "name": "Saint-Sauveur-le-Vicomte",
        "latitude": "49.38547000",
        "longitude": "-1.53310000"
    },
    {
        "id": 46533,
        "name": "Saint-Sauveur-Lendelin",
        "latitude": "49.12988000",
        "longitude": "-1.41405000"
    },
    {
        "id": 46539,
        "name": "Saint-Sauveur-sur-École",
        "latitude": "48.49750000",
        "longitude": "2.54711000"
    },
    {
        "id": 46540,
        "name": "Saint-Savin",
        "latitude": "45.62776000",
        "longitude": "5.30856000"
    },
    {
        "id": 46541,
        "name": "Saint-Savin",
        "latitude": "45.15000000",
        "longitude": "-0.45000000"
    },
    {
        "id": 46542,
        "name": "Saint-Savinien",
        "latitude": "45.87711000",
        "longitude": "-0.67919000"
    },
    {
        "id": 46543,
        "name": "Saint-Savournin",
        "latitude": "43.40848000",
        "longitude": "5.52690000"
    },
    {
        "id": 46577,
        "name": "Saint-Sébastien-de-Morsent",
        "latitude": "49.01096000",
        "longitude": "1.08730000"
    },
    {
        "id": 46578,
        "name": "Saint-Sébastien-sur-Loire",
        "latitude": "47.20768000",
        "longitude": "-1.50332000"
    },
    {
        "id": 46545,
        "name": "Saint-Selve",
        "latitude": "44.67095000",
        "longitude": "-0.47887000"
    },
    {
        "id": 46546,
        "name": "Saint-Senier-sous-Avranches",
        "latitude": "48.68399000",
        "longitude": "-1.33126000"
    },
    {
        "id": 46547,
        "name": "Saint-Senoux",
        "latitude": "47.90552000",
        "longitude": "-1.78819000"
    },
    {
        "id": 46548,
        "name": "Saint-Sernin",
        "latitude": "44.57147000",
        "longitude": "4.39186000"
    },
    {
        "id": 46549,
        "name": "Saint-Sernin-du-Bois",
        "latitude": "46.84074000",
        "longitude": "4.43271000"
    },
    {
        "id": 46550,
        "name": "Saint-Seurin-sur-l’Isle",
        "latitude": "45.01667000",
        "longitude": "0.00000000"
    },
    {
        "id": 46551,
        "name": "Saint-Sever",
        "latitude": "43.75727000",
        "longitude": "-0.57357000"
    },
    {
        "id": 46552,
        "name": "Saint-Sever-Calvados",
        "latitude": "48.84035000",
        "longitude": "-1.04773000"
    },
    {
        "id": 46554,
        "name": "Saint-Siméon-de-Bressieux",
        "latitude": "45.33850000",
        "longitude": "5.26591000"
    },
    {
        "id": 46553,
        "name": "Saint-Simon",
        "latitude": "44.96320000",
        "longitude": "2.48994000"
    },
    {
        "id": 46555,
        "name": "Saint-Sorlin-en-Bugey",
        "latitude": "45.88576000",
        "longitude": "5.36688000"
    },
    {
        "id": 46556,
        "name": "Saint-Sorlin-en-Valloire",
        "latitude": "45.28774000",
        "longitude": "4.94903000"
    },
    {
        "id": 46557,
        "name": "Saint-Souplet",
        "latitude": "50.05639000",
        "longitude": "3.53179000"
    },
    {
        "id": 46558,
        "name": "Saint-Soupplets",
        "latitude": "49.03866000",
        "longitude": "2.80723000"
    },
    {
        "id": 46559,
        "name": "Saint-Sulpice",
        "latitude": "49.35049000",
        "longitude": "2.12314000"
    },
    {
        "id": 46560,
        "name": "Saint-Sulpice-de-Cognac",
        "latitude": "45.75978000",
        "longitude": "-0.38093000"
    },
    {
        "id": 46561,
        "name": "Saint-Sulpice-de-Faleyrens",
        "latitude": "44.86667000",
        "longitude": "-0.18333000"
    },
    {
        "id": 46562,
        "name": "Saint-Sulpice-de-Pommeray",
        "latitude": "47.60000000",
        "longitude": "1.26667000"
    },
    {
        "id": 46563,
        "name": "Saint-Sulpice-de-Royan",
        "latitude": "45.67035000",
        "longitude": "-1.01252000"
    },
    {
        "id": 46564,
        "name": "Saint-Sulpice-et-Cameyrac",
        "latitude": "44.91131000",
        "longitude": "-0.39048000"
    },
    {
        "id": 46565,
        "name": "Saint-Sulpice-la-Forêt",
        "latitude": "48.21667000",
        "longitude": "-1.57972000"
    },
    {
        "id": 46566,
        "name": "Saint-Sulpice-la-Pointe",
        "latitude": "43.77500000",
        "longitude": "1.68511000"
    },
    {
        "id": 46567,
        "name": "Saint-Sulpice-le-Guérétois",
        "latitude": "46.20097000",
        "longitude": "1.82826000"
    },
    {
        "id": 46568,
        "name": "Saint-Sulpice-les-Feuilles",
        "latitude": "46.31868000",
        "longitude": "1.36792000"
    },
    {
        "id": 46569,
        "name": "Saint-Sulpice-sur-Lèze",
        "latitude": "43.33076000",
        "longitude": "1.32091000"
    },
    {
        "id": 46570,
        "name": "Saint-Sulpice-sur-Risle",
        "latitude": "48.78063000",
        "longitude": "0.65739000"
    },
    {
        "id": 46571,
        "name": "Saint-Sylvain",
        "latitude": "49.05624000",
        "longitude": "-0.21758000"
    },
    {
        "id": 46572,
        "name": "Saint-Sylvestre-Cappel",
        "latitude": "50.77625000",
        "longitude": "2.55622000"
    },
    {
        "id": 46573,
        "name": "Saint-Sylvestre-sur-Lot",
        "latitude": "44.39667000",
        "longitude": "0.80441000"
    },
    {
        "id": 46574,
        "name": "Saint-Symphorien",
        "latitude": "46.26442000",
        "longitude": "-0.49220000"
    },
    {
        "id": 46575,
        "name": "Saint-Symphorien-de-Lay",
        "latitude": "45.94772000",
        "longitude": "4.21318000"
    },
    {
        "id": 46576,
        "name": "Saint-Symphorien-sur-Coise",
        "latitude": "45.63220000",
        "longitude": "4.45709000"
    },
    {
        "id": 46584,
        "name": "Saint-Thégonnec",
        "latitude": "48.51667000",
        "longitude": "-3.95000000"
    },
    {
        "id": 46579,
        "name": "Saint-Thibault-des-Vignes",
        "latitude": "48.87111000",
        "longitude": "2.68041000"
    },
    {
        "id": 46580,
        "name": "Saint-Thibéry",
        "latitude": "43.39657000",
        "longitude": "3.41774000"
    },
    {
        "id": 46581,
        "name": "Saint-Thonan",
        "latitude": "48.48333000",
        "longitude": "-4.33333000"
    },
    {
        "id": 46582,
        "name": "Saint-Thurial",
        "latitude": "48.02924000",
        "longitude": "-1.93181000"
    },
    {
        "id": 46583,
        "name": "Saint-Thuriau",
        "latitude": "48.01667000",
        "longitude": "-2.95000000"
    },
    {
        "id": 46585,
        "name": "Saint-Trivier-de-Courtes",
        "latitude": "46.45980000",
        "longitude": "5.07762000"
    },
    {
        "id": 46586,
        "name": "Saint-Trivier-sur-Moignans",
        "latitude": "46.07233000",
        "longitude": "4.89721000"
    },
    {
        "id": 46587,
        "name": "Saint-Trojan-les-Bains",
        "latitude": "45.84134000",
        "longitude": "-1.20728000"
    },
    {
        "id": 46588,
        "name": "Saint-Tronc",
        "latitude": "43.27093000",
        "longitude": "5.42285000"
    },
    {
        "id": 46589,
        "name": "Saint-Tropez",
        "latitude": "43.26764000",
        "longitude": "6.64049000"
    },
    {
        "id": 46590,
        "name": "Saint-Urbain",
        "latitude": "46.87557000",
        "longitude": "-2.00961000"
    },
    {
        "id": 46591,
        "name": "Saint-Urbain",
        "latitude": "48.40000000",
        "longitude": "-4.23333000"
    },
    {
        "id": 46592,
        "name": "Saint-Usage",
        "latitude": "47.11009000",
        "longitude": "5.26044000"
    },
    {
        "id": 46593,
        "name": "Saint-Usuge",
        "latitude": "46.67900000",
        "longitude": "5.25089000"
    },
    {
        "id": 46594,
        "name": "Saint-Uze",
        "latitude": "45.18409000",
        "longitude": "4.86000000"
    },
    {
        "id": 46595,
        "name": "Saint-Vaast-la-Hougue",
        "latitude": "49.58843000",
        "longitude": "-1.26931000"
    },
    {
        "id": 46601,
        "name": "Saint-Valérien",
        "latitude": "48.17805000",
        "longitude": "3.09523000"
    },
    {
        "id": 46596,
        "name": "Saint-Valery-en-Caux",
        "latitude": "49.86667000",
        "longitude": "0.73333000"
    },
    {
        "id": 46597,
        "name": "Saint-Valery-sur-Somme",
        "latitude": "50.18333000",
        "longitude": "1.63333000"
    },
    {
        "id": 46598,
        "name": "Saint-Vallier",
        "latitude": "46.64107000",
        "longitude": "4.37107000"
    },
    {
        "id": 46599,
        "name": "Saint-Vallier",
        "latitude": "45.17154000",
        "longitude": "4.81758000"
    },
    {
        "id": 46600,
        "name": "Saint-Vallier-de-Thiey",
        "latitude": "43.69841000",
        "longitude": "6.84779000"
    },
    {
        "id": 46602,
        "name": "Saint-Varent",
        "latitude": "46.88934000",
        "longitude": "-0.23210000"
    },
    {
        "id": 46603,
        "name": "Saint-Vaury",
        "latitude": "46.20417000",
        "longitude": "1.75654000"
    },
    {
        "id": 46604,
        "name": "Saint-Venant",
        "latitude": "50.61955000",
        "longitude": "2.53946000"
    },
    {
        "id": 46624,
        "name": "Saint-Vérand",
        "latitude": "45.17344000",
        "longitude": "5.33261000"
    },
    {
        "id": 46605,
        "name": "Saint-Viance",
        "latitude": "45.21760000",
        "longitude": "1.45263000"
    },
    {
        "id": 46622,
        "name": "Saint-Viâtre",
        "latitude": "47.52438000",
        "longitude": "1.93276000"
    },
    {
        "id": 46606,
        "name": "Saint-Viaud",
        "latitude": "47.25619000",
        "longitude": "-2.01833000"
    },
    {
        "id": 46607,
        "name": "Saint-Victor",
        "latitude": "46.39506000",
        "longitude": "2.60819000"
    },
    {
        "id": 46608,
        "name": "Saint-Victor-de-Cessieu",
        "latitude": "45.54113000",
        "longitude": "5.39098000"
    },
    {
        "id": 46609,
        "name": "Saint-Victor-la-Coste",
        "latitude": "44.06295000",
        "longitude": "4.64238000"
    },
    {
        "id": 46610,
        "name": "Saint-Victoret",
        "latitude": "43.41957000",
        "longitude": "5.23396000"
    },
    {
        "id": 46611,
        "name": "Saint-Victurnien",
        "latitude": "45.87855000",
        "longitude": "1.01376000"
    },
    {
        "id": 46612,
        "name": "Saint-Vigor-le-Grand",
        "latitude": "49.28242000",
        "longitude": "-0.68579000"
    },
    {
        "id": 46613,
        "name": "Saint-Vincent-de-Mercuze",
        "latitude": "45.37215000",
        "longitude": "5.95424000"
    },
    {
        "id": 46614,
        "name": "Saint-Vincent-de-Paul",
        "latitude": "43.74431000",
        "longitude": "-1.00662000"
    },
    {
        "id": 46615,
        "name": "Saint-Vincent-de-Tyrosse",
        "latitude": "43.66031000",
        "longitude": "-1.30799000"
    },
    {
        "id": 46616,
        "name": "Saint-Vincent-des-Landes",
        "latitude": "47.65695000",
        "longitude": "-1.49572000"
    },
    {
        "id": 46617,
        "name": "Saint-Vincent-sur-Graon",
        "latitude": "46.51690000",
        "longitude": "-1.38881000"
    },
    {
        "id": 46618,
        "name": "Saint-Vincent-sur-Oust",
        "latitude": "47.70010000",
        "longitude": "-2.14657000"
    },
    {
        "id": 46619,
        "name": "Saint-Vit",
        "latitude": "47.18333000",
        "longitude": "5.81667000"
    },
    {
        "id": 46620,
        "name": "Saint-Vite",
        "latitude": "44.47133000",
        "longitude": "0.93876000"
    },
    {
        "id": 46621,
        "name": "Saint-Vivien-de-Médoc",
        "latitude": "45.42695000",
        "longitude": "-1.03377000"
    },
    {
        "id": 46623,
        "name": "Saint-Vrain",
        "latitude": "48.54302000",
        "longitude": "2.33331000"
    },
    {
        "id": 46625,
        "name": "Saint-Wandrille-Rançon",
        "latitude": "49.52658000",
        "longitude": "0.76497000"
    },
    {
        "id": 46626,
        "name": "Saint-Witz",
        "latitude": "49.09100000",
        "longitude": "2.57122000"
    },
    {
        "id": 46627,
        "name": "Saint-Xandre",
        "latitude": "46.20444000",
        "longitude": "-1.10267000"
    },
    {
        "id": 46628,
        "name": "Saint-Yan",
        "latitude": "46.41147000",
        "longitude": "4.03876000"
    },
    {
        "id": 46629,
        "name": "Saint-Yorre",
        "latitude": "46.06598000",
        "longitude": "3.46430000"
    },
    {
        "id": 46630,
        "name": "Saint-Yrieix-la-Perche",
        "latitude": "45.51604000",
        "longitude": "1.20569000"
    },
    {
        "id": 46631,
        "name": "Saint-Yrieix-sur-Charente",
        "latitude": "45.68333000",
        "longitude": "0.11667000"
    },
    {
        "id": 46632,
        "name": "Saint-Yvi",
        "latitude": "47.96667000",
        "longitude": "-3.93333000"
    },
    {
        "id": 46633,
        "name": "Saint-Yzan-de-Soudiac",
        "latitude": "45.14118000",
        "longitude": "-0.41078000"
    },
    {
        "id": 46634,
        "name": "Saint-Zacharie",
        "latitude": "43.38521000",
        "longitude": "5.70808000"
    },
    {
        "id": 46660,
        "name": "Sainte-Adresse",
        "latitude": "49.50890000",
        "longitude": "0.08446000"
    },
    {
        "id": 46661,
        "name": "Sainte-Agnès",
        "latitude": "43.80054000",
        "longitude": "7.46150000"
    },
    {
        "id": 46662,
        "name": "Sainte-Anastasie-sur-Issole",
        "latitude": "43.34266000",
        "longitude": "6.12493000"
    },
    {
        "id": 46663,
        "name": "Sainte-Anne",
        "latitude": "43.25720000",
        "longitude": "5.39377000"
    },
    {
        "id": 46664,
        "name": "Sainte-Anne-d'Auray",
        "latitude": "47.70000000",
        "longitude": "-2.95000000"
    },
    {
        "id": 46665,
        "name": "Sainte-Anne-sur-Brivet",
        "latitude": "47.46071000",
        "longitude": "-2.00415000"
    },
    {
        "id": 46666,
        "name": "Sainte-Bazeille",
        "latitude": "44.53073000",
        "longitude": "0.09740000"
    },
    {
        "id": 46667,
        "name": "Sainte-Catherine",
        "latitude": "50.30757000",
        "longitude": "2.76404000"
    },
    {
        "id": 46674,
        "name": "Sainte-Cécile",
        "latitude": "46.74286000",
        "longitude": "-1.11429000"
    },
    {
        "id": 46675,
        "name": "Sainte-Cécile-les-Vignes",
        "latitude": "44.24542000",
        "longitude": "4.88613000"
    },
    {
        "id": 46668,
        "name": "Sainte-Colombe",
        "latitude": "48.53047000",
        "longitude": "3.25517000"
    },
    {
        "id": 46669,
        "name": "Sainte-Colombe",
        "latitude": "45.52531000",
        "longitude": "4.86664000"
    },
    {
        "id": 46670,
        "name": "Sainte-Colombe-en-Bruilhois",
        "latitude": "44.17822000",
        "longitude": "0.51572000"
    },
    {
        "id": 46671,
        "name": "Sainte-Consorce",
        "latitude": "45.77585000",
        "longitude": "4.69033000"
    },
    {
        "id": 46672,
        "name": "Sainte-Croix-aux-Mines",
        "latitude": "48.26249000",
        "longitude": "7.22739000"
    },
    {
        "id": 46673,
        "name": "Sainte-Croix-en-Plaine",
        "latitude": "48.00876000",
        "longitude": "7.38556000"
    },
    {
        "id": 46676,
        "name": "Sainte-Eulalie",
        "latitude": "44.90667000",
        "longitude": "-0.47417000"
    },
    {
        "id": 46677,
        "name": "Sainte-Euphémie",
        "latitude": "45.97101000",
        "longitude": "4.79853000"
    },
    {
        "id": 46687,
        "name": "Sainte-Féréole",
        "latitude": "45.22932000",
        "longitude": "1.58248000"
    },
    {
        "id": 46678,
        "name": "Sainte-Feyre",
        "latitude": "46.13900000",
        "longitude": "1.91517000"
    },
    {
        "id": 46679,
        "name": "Sainte-Flaive-des-Loups",
        "latitude": "46.61303000",
        "longitude": "-1.58082000"
    },
    {
        "id": 46680,
        "name": "Sainte-Florine",
        "latitude": "45.40519000",
        "longitude": "3.31732000"
    },
    {
        "id": 46681,
        "name": "Sainte-Fortunade",
        "latitude": "45.20691000",
        "longitude": "1.77117000"
    },
    {
        "id": 46682,
        "name": "Sainte-Foy",
        "latitude": "46.54488000",
        "longitude": "-1.67265000"
    },
    {
        "id": 46683,
        "name": "Sainte-Foy-de-Peyrolières",
        "latitude": "43.49262000",
        "longitude": "1.14477000"
    },
    {
        "id": 46686,
        "name": "Sainte-Foy-l’Argentière",
        "latitude": "45.70823000",
        "longitude": "4.47025000"
    },
    {
        "id": 46684,
        "name": "Sainte-Foy-la-Grande",
        "latitude": "44.83333000",
        "longitude": "0.21667000"
    },
    {
        "id": 46685,
        "name": "Sainte-Foy-lès-Lyon",
        "latitude": "45.73375000",
        "longitude": "4.80259000"
    },
    {
        "id": 46688,
        "name": "Sainte-Gauburge-Sainte-Colombe",
        "latitude": "48.71722000",
        "longitude": "0.43126000"
    },
    {
        "id": 46689,
        "name": "Sainte-Gemme-la-Plaine",
        "latitude": "46.48286000",
        "longitude": "-1.11321000"
    },
    {
        "id": 46690,
        "name": "Sainte-Gemmes-sur-Loire",
        "latitude": "47.42290000",
        "longitude": "-0.55684000"
    },
    {
        "id": 46691,
        "name": "Sainte-Geneviève",
        "latitude": "49.28920000",
        "longitude": "2.19904000"
    },
    {
        "id": 46692,
        "name": "Sainte-Geneviève-des-Bois",
        "latitude": "47.81777000",
        "longitude": "2.81652000"
    },
    {
        "id": 46693,
        "name": "Sainte-Geneviève-des-Bois",
        "latitude": "48.64682000",
        "longitude": "2.31965000"
    },
    {
        "id": 46694,
        "name": "Sainte-Geneviève-sur-Argence",
        "latitude": "44.80222000",
        "longitude": "2.75954000"
    },
    {
        "id": 46697,
        "name": "Sainte-Hélène",
        "latitude": "44.96667000",
        "longitude": "-0.88333000"
    },
    {
        "id": 46698,
        "name": "Sainte-Hélène",
        "latitude": "47.71976000",
        "longitude": "-3.20359000"
    },
    {
        "id": 46699,
        "name": "Sainte-Hélène-sur-Isère",
        "latitude": "45.61434000",
        "longitude": "6.32052000"
    },
    {
        "id": 46695,
        "name": "Sainte-Hermine",
        "latitude": "46.55619000",
        "longitude": "-1.05476000"
    },
    {
        "id": 46696,
        "name": "Sainte-Honorine-du-Fay",
        "latitude": "49.07775000",
        "longitude": "-0.49295000"
    },
    {
        "id": 46700,
        "name": "Sainte-Jamme-sur-Sarthe",
        "latitude": "48.14264000",
        "longitude": "0.16743000"
    },
    {
        "id": 46701,
        "name": "Sainte-Livrade-sur-Lot",
        "latitude": "44.39929000",
        "longitude": "0.59120000"
    },
    {
        "id": 46702,
        "name": "Sainte-Lizaigne",
        "latitude": "47.00695000",
        "longitude": "2.02266000"
    },
    {
        "id": 46703,
        "name": "Sainte-Luce-sur-Loire",
        "latitude": "47.25381000",
        "longitude": "-1.48430000"
    },
    {
        "id": 46704,
        "name": "Sainte-Marguerite",
        "latitude": "48.26735000",
        "longitude": "6.98439000"
    },
    {
        "id": 46705,
        "name": "Sainte-Marguerite",
        "latitude": "43.26196000",
        "longitude": "5.40125000"
    },
    {
        "id": 46706,
        "name": "Sainte-Marguerite-sur-Duclair",
        "latitude": "49.50852000",
        "longitude": "0.84362000"
    },
    {
        "id": 46707,
        "name": "Sainte-Marie",
        "latitude": "47.69429000",
        "longitude": "-2.00190000"
    },
    {
        "id": 46710,
        "name": "Sainte-Marie-aux-Chênes",
        "latitude": "49.19262000",
        "longitude": "6.00150000"
    },
    {
        "id": 46711,
        "name": "Sainte-Marie-aux-Mines",
        "latitude": "48.24649000",
        "longitude": "7.18385000"
    },
    {
        "id": 46712,
        "name": "Sainte-Marie-de-Gosse",
        "latitude": "43.55000000",
        "longitude": "-0.23333000"
    },
    {
        "id": 46713,
        "name": "Sainte-Marie-de-Ré",
        "latitude": "46.15237000",
        "longitude": "-1.31281000"
    },
    {
        "id": 46714,
        "name": "Sainte-Marie-des-Champs",
        "latitude": "49.62120000",
        "longitude": "0.77904000"
    },
    {
        "id": 46708,
        "name": "Sainte-Marie-Kerque",
        "latitude": "50.89917000",
        "longitude": "2.13664000"
    },
    {
        "id": 46709,
        "name": "Sainte-Marie-Plage",
        "latitude": "42.72498000",
        "longitude": "3.03751000"
    },
    {
        "id": 46715,
        "name": "Sainte-Marthe",
        "latitude": "43.33449000",
        "longitude": "5.39112000"
    },
    {
        "id": 46716,
        "name": "Sainte-Maure",
        "latitude": "48.34729000",
        "longitude": "4.05962000"
    },
    {
        "id": 46717,
        "name": "Sainte-Maure-de-Touraine",
        "latitude": "47.11130000",
        "longitude": "0.62236000"
    },
    {
        "id": 46718,
        "name": "Sainte-Maxime",
        "latitude": "43.30907000",
        "longitude": "6.63849000"
    },
    {
        "id": 46719,
        "name": "Sainte-Menehould",
        "latitude": "49.09008000",
        "longitude": "4.89733000"
    },
    {
        "id": 46720,
        "name": "Sainte-Mère-Église",
        "latitude": "49.41000000",
        "longitude": "-1.31726000"
    },
    {
        "id": 46721,
        "name": "Sainte-Pazanne",
        "latitude": "47.10301000",
        "longitude": "-1.80950000"
    },
    {
        "id": 46722,
        "name": "Sainte-Radegonde",
        "latitude": "44.33743000",
        "longitude": "2.62672000"
    },
    {
        "id": 46723,
        "name": "Sainte-Radegonde",
        "latitude": "46.98333000",
        "longitude": "-0.25000000"
    },
    {
        "id": 46724,
        "name": "Sainte-Reine-de-Bretagne",
        "latitude": "47.44131000",
        "longitude": "-2.19238000"
    },
    {
        "id": 46725,
        "name": "Sainte-Savine",
        "latitude": "48.29637000",
        "longitude": "4.04642000"
    },
    {
        "id": 46726,
        "name": "Sainte-Sigolène",
        "latitude": "45.24329000",
        "longitude": "4.23343000"
    },
    {
        "id": 46727,
        "name": "Sainte-Solange",
        "latitude": "47.13628000",
        "longitude": "2.55019000"
    },
    {
        "id": 46728,
        "name": "Sainte-Soulle",
        "latitude": "46.18847000",
        "longitude": "-1.01607000"
    },
    {
        "id": 46729,
        "name": "Sainte-Suzanne",
        "latitude": "48.09818000",
        "longitude": "-0.35439000"
    },
    {
        "id": 46730,
        "name": "Sainte-Suzanne",
        "latitude": "47.50017000",
        "longitude": "6.76775000"
    },
    {
        "id": 46731,
        "name": "Sainte-Terre",
        "latitude": "44.83333000",
        "longitude": "-0.11667000"
    },
    {
        "id": 46732,
        "name": "Sainte-Tulle",
        "latitude": "43.78619000",
        "longitude": "5.76513000"
    },
    {
        "id": 46733,
        "name": "Sainte-Verge",
        "latitude": "47.00818000",
        "longitude": "-0.21033000"
    },
    {
        "id": 46734,
        "name": "Saintes",
        "latitude": "45.74544000",
        "longitude": "-0.63450000"
    },
    {
        "id": 46735,
        "name": "Saintes-Maries-de-la-Mer",
        "latitude": "43.45214000",
        "longitude": "4.42913000"
    },
    {
        "id": 46736,
        "name": "Saintry-sur-Seine",
        "latitude": "48.59640000",
        "longitude": "2.49515000"
    },
    {
        "id": 46737,
        "name": "Saints",
        "latitude": "48.76066000",
        "longitude": "3.04645000"
    },
    {
        "id": 46738,
        "name": "Saivres",
        "latitude": "46.43262000",
        "longitude": "-0.23677000"
    },
    {
        "id": 46892,
        "name": "Saïx",
        "latitude": "43.58333000",
        "longitude": "2.18333000"
    },
    {
        "id": 46739,
        "name": "Saizerais",
        "latitude": "48.79250000",
        "longitude": "6.04470000"
    },
    {
        "id": 46740,
        "name": "Salagnon",
        "latitude": "45.66863000",
        "longitude": "5.36325000"
    },
    {
        "id": 46741,
        "name": "Salaise-sur-Sanne",
        "latitude": "45.35126000",
        "longitude": "4.81070000"
    },
    {
        "id": 46742,
        "name": "Salbert",
        "latitude": "47.66959000",
        "longitude": "6.81269000"
    },
    {
        "id": 46743,
        "name": "Salbris",
        "latitude": "47.42420000",
        "longitude": "2.05124000"
    },
    {
        "id": 46744,
        "name": "Saleilles",
        "latitude": "42.65418000",
        "longitude": "2.95309000"
    },
    {
        "id": 46745,
        "name": "Salernes",
        "latitude": "43.56350000",
        "longitude": "6.23386000"
    },
    {
        "id": 46746,
        "name": "Sales",
        "latitude": "45.87441000",
        "longitude": "5.95996000"
    },
    {
        "id": 46747,
        "name": "Saleux",
        "latitude": "49.85630000",
        "longitude": "2.23698000"
    },
    {
        "id": 46748,
        "name": "Salies-de-Béarn",
        "latitude": "43.47422000",
        "longitude": "-0.92448000"
    },
    {
        "id": 46749,
        "name": "Salies-du-Salat",
        "latitude": "43.10074000",
        "longitude": "0.95866000"
    },
    {
        "id": 46750,
        "name": "Salignac",
        "latitude": "45.01607000",
        "longitude": "-0.37964000"
    },
    {
        "id": 46751,
        "name": "Salignac-Eyvigues",
        "latitude": "44.97464000",
        "longitude": "1.32428000"
    },
    {
        "id": 46752,
        "name": "Saligny",
        "latitude": "46.80833000",
        "longitude": "-1.42726000"
    },
    {
        "id": 46753,
        "name": "Salindres",
        "latitude": "44.17174000",
        "longitude": "4.16020000"
    },
    {
        "id": 46754,
        "name": "Salins",
        "latitude": "48.42156000",
        "longitude": "3.02130000"
    },
    {
        "id": 46755,
        "name": "Salins-les-Bains",
        "latitude": "46.94663000",
        "longitude": "5.87763000"
    },
    {
        "id": 46756,
        "name": "Salins-les-Thermes",
        "latitude": "45.47169000",
        "longitude": "6.53051000"
    },
    {
        "id": 46757,
        "name": "Sallanches",
        "latitude": "45.94423000",
        "longitude": "6.63162000"
    },
    {
        "id": 46758,
        "name": "Sallaumines",
        "latitude": "50.41749000",
        "longitude": "2.86174000"
    },
    {
        "id": 46759,
        "name": "Sallebœuf",
        "latitude": "44.83333000",
        "longitude": "-0.40000000"
    },
    {
        "id": 46760,
        "name": "Sallertaine",
        "latitude": "46.86017000",
        "longitude": "-1.95522000"
    },
    {
        "id": 46761,
        "name": "Salles",
        "latitude": "44.55000000",
        "longitude": "-0.86073000"
    },
    {
        "id": 46762,
        "name": "Salles-Curan",
        "latitude": "44.18220000",
        "longitude": "2.78821000"
    },
    {
        "id": 46763,
        "name": "Salles-la-Source",
        "latitude": "44.43505000",
        "longitude": "2.51283000"
    },
    {
        "id": 46764,
        "name": "Salles-sur-Mer",
        "latitude": "46.10543000",
        "longitude": "-1.05741000"
    },
    {
        "id": 46765,
        "name": "Salomé",
        "latitude": "50.53352000",
        "longitude": "2.84709000"
    },
    {
        "id": 46766,
        "name": "Salon-de-Provence",
        "latitude": "43.64229000",
        "longitude": "5.09478000"
    },
    {
        "id": 46767,
        "name": "Salouël",
        "latitude": "49.86988000",
        "longitude": "2.24340000"
    },
    {
        "id": 46768,
        "name": "Salses-le-Château",
        "latitude": "42.83333000",
        "longitude": "2.91667000"
    },
    {
        "id": 46769,
        "name": "Salviac",
        "latitude": "44.68080000",
        "longitude": "1.26506000"
    },
    {
        "id": 46770,
        "name": "Samadet",
        "latitude": "43.63761000",
        "longitude": "-0.48785000"
    },
    {
        "id": 46771,
        "name": "Samatan",
        "latitude": "43.49286000",
        "longitude": "0.92976000"
    },
    {
        "id": 46777,
        "name": "Saméon",
        "latitude": "50.47135000",
        "longitude": "3.33544000"
    },
    {
        "id": 46772,
        "name": "Samer",
        "latitude": "50.63840000",
        "longitude": "1.74628000"
    },
    {
        "id": 46773,
        "name": "Sammeron",
        "latitude": "48.94721000",
        "longitude": "3.08333000"
    },
    {
        "id": 46776,
        "name": "Samoëns",
        "latitude": "46.08282000",
        "longitude": "6.72647000"
    },
    {
        "id": 46774,
        "name": "Samois-sur-Seine",
        "latitude": "48.45251000",
        "longitude": "2.75040000"
    },
    {
        "id": 46775,
        "name": "Samoreau",
        "latitude": "48.42946000",
        "longitude": "2.75587000"
    },
    {
        "id": 46778,
        "name": "San-Martino-di-Lota",
        "latitude": "42.73163000",
        "longitude": "9.43966000"
    },
    {
        "id": 46779,
        "name": "San-Nicolao",
        "latitude": "42.36993000",
        "longitude": "9.51443000"
    },
    {
        "id": 46780,
        "name": "Sanary-sur-Mer",
        "latitude": "43.11985000",
        "longitude": "5.80155000"
    },
    {
        "id": 46784,
        "name": "Sancé",
        "latitude": "46.33926000",
        "longitude": "4.83049000"
    },
    {
        "id": 46781,
        "name": "Sancerre",
        "latitude": "47.32889000",
        "longitude": "2.83447000"
    },
    {
        "id": 46782,
        "name": "Sancey-le-Grand",
        "latitude": "47.29489000",
        "longitude": "6.58287000"
    },
    {
        "id": 46783,
        "name": "Sancoins",
        "latitude": "46.83314000",
        "longitude": "2.92238000"
    },
    {
        "id": 46785,
        "name": "Sand",
        "latitude": "48.38319000",
        "longitude": "7.61233000"
    },
    {
        "id": 46786,
        "name": "Sandillon",
        "latitude": "47.84510000",
        "longitude": "2.03155000"
    },
    {
        "id": 46787,
        "name": "Sangatte",
        "latitude": "50.94564000",
        "longitude": "1.75321000"
    },
    {
        "id": 46788,
        "name": "Sanguinet",
        "latitude": "44.48320000",
        "longitude": "-1.07457000"
    },
    {
        "id": 46789,
        "name": "Sannerville",
        "latitude": "49.18018000",
        "longitude": "-0.22434000"
    },
    {
        "id": 46790,
        "name": "Sannois",
        "latitude": "48.96667000",
        "longitude": "2.25000000"
    },
    {
        "id": 46791,
        "name": "Sansac-de-Marmiesse",
        "latitude": "44.88376000",
        "longitude": "2.34768000"
    },
    {
        "id": 46792,
        "name": "Sant Andreu de Sureda",
        "latitude": "42.55201000",
        "longitude": "2.97129000"
    },
    {
        "id": 46793,
        "name": "Sant Joan de Pladecorts",
        "latitude": "42.51069000",
        "longitude": "2.79091000"
    },
    {
        "id": 46794,
        "name": "Sant Llorenç de Cerdans",
        "latitude": "42.38473000",
        "longitude": "2.61320000"
    },
    {
        "id": 46795,
        "name": "Santa-Lucia-di-Moriani",
        "latitude": "42.38212000",
        "longitude": "9.52242000"
    },
    {
        "id": 46796,
        "name": "Santa-Maria-di-Lota",
        "latitude": "42.74783000",
        "longitude": "9.43202000"
    },
    {
        "id": 46797,
        "name": "Santec",
        "latitude": "48.70000000",
        "longitude": "-4.03333000"
    },
    {
        "id": 46798,
        "name": "Santeny",
        "latitude": "48.72730000",
        "longitude": "2.57346000"
    },
    {
        "id": 46799,
        "name": "Santes",
        "latitude": "50.59316000",
        "longitude": "2.96289000"
    },
    {
        "id": 46800,
        "name": "Sanvignes-les-Mines",
        "latitude": "46.66444000",
        "longitude": "4.29188000"
    },
    {
        "id": 46893,
        "name": "Saône",
        "latitude": "47.22311000",
        "longitude": "6.11682000"
    },
    {
        "id": 46801,
        "name": "Saran",
        "latitude": "47.95013000",
        "longitude": "1.87601000"
    },
    {
        "id": 46802,
        "name": "Sarbazan",
        "latitude": "44.02029000",
        "longitude": "-0.31294000"
    },
    {
        "id": 46803,
        "name": "Sarcelles",
        "latitude": "48.99739000",
        "longitude": "2.37821000"
    },
    {
        "id": 46804,
        "name": "Sare",
        "latitude": "43.31260000",
        "longitude": "-1.58012000"
    },
    {
        "id": 46805,
        "name": "Sargé-lès-le-Mans",
        "latitude": "48.03333000",
        "longitude": "0.23333000"
    },
    {
        "id": 46806,
        "name": "Sargé-sur-Braye",
        "latitude": "47.92352000",
        "longitude": "0.85340000"
    },
    {
        "id": 46807,
        "name": "Sari-Solenzara",
        "latitude": "41.83519000",
        "longitude": "9.37470000"
    },
    {
        "id": 46808,
        "name": "Sarlat-la-Canéda",
        "latitude": "44.88902000",
        "longitude": "1.21656000"
    },
    {
        "id": 46809,
        "name": "Sarralbe",
        "latitude": "48.99858000",
        "longitude": "7.03074000"
    },
    {
        "id": 46810,
        "name": "Sarras",
        "latitude": "45.18679000",
        "longitude": "4.80004000"
    },
    {
        "id": 46811,
        "name": "Sarre-Union",
        "latitude": "48.93818000",
        "longitude": "7.09373000"
    },
    {
        "id": 46812,
        "name": "Sarrebourg",
        "latitude": "48.73558000",
        "longitude": "7.05720000"
    },
    {
        "id": 46813,
        "name": "Sarreguemines",
        "latitude": "49.10995000",
        "longitude": "7.06747000"
    },
    {
        "id": 46814,
        "name": "Sarreinsming",
        "latitude": "49.09121000",
        "longitude": "7.11053000"
    },
    {
        "id": 46815,
        "name": "Sarrewerden",
        "latitude": "48.92261000",
        "longitude": "7.08412000"
    },
    {
        "id": 46816,
        "name": "Sarrians",
        "latitude": "44.08312000",
        "longitude": "4.97111000"
    },
    {
        "id": 46817,
        "name": "Sarrola",
        "latitude": "42.02828000",
        "longitude": "8.84241000"
    },
    {
        "id": 46818,
        "name": "Sarry",
        "latitude": "48.91852000",
        "longitude": "4.40621000"
    },
    {
        "id": 46819,
        "name": "Sars-Poteries",
        "latitude": "50.16928000",
        "longitude": "4.02676000"
    },
    {
        "id": 46823,
        "name": "Sartène",
        "latitude": "41.61667000",
        "longitude": "8.98333000"
    },
    {
        "id": 46820,
        "name": "Sarthe",
        "latitude": "48.00493000",
        "longitude": "0.26516000"
    },
    {
        "id": 46821,
        "name": "Sartilly",
        "latitude": "48.75239000",
        "longitude": "-1.45678000"
    },
    {
        "id": 46822,
        "name": "Sartrouville",
        "latitude": "48.94820000",
        "longitude": "2.19169000"
    },
    {
        "id": 46824,
        "name": "Sarzeau",
        "latitude": "47.52772000",
        "longitude": "-2.76933000"
    },
    {
        "id": 46825,
        "name": "Sassenage",
        "latitude": "45.21266000",
        "longitude": "5.66290000"
    },
    {
        "id": 46826,
        "name": "Sassenay",
        "latitude": "46.83074000",
        "longitude": "4.92523000"
    },
    {
        "id": 46827,
        "name": "Sathonay-Camp",
        "latitude": "45.82462000",
        "longitude": "4.87453000"
    },
    {
        "id": 46828,
        "name": "Sathonay-Village",
        "latitude": "45.83337000",
        "longitude": "4.87821000"
    },
    {
        "id": 46829,
        "name": "Satillieu",
        "latitude": "45.15041000",
        "longitude": "4.61410000"
    },
    {
        "id": 46830,
        "name": "Satolas-et-Bonce",
        "latitude": "45.69368000",
        "longitude": "5.13032000"
    },
    {
        "id": 46831,
        "name": "Saubens",
        "latitude": "43.47984000",
        "longitude": "1.35189000"
    },
    {
        "id": 46832,
        "name": "Saubion",
        "latitude": "43.67140000",
        "longitude": "-1.34821000"
    },
    {
        "id": 46833,
        "name": "Saubrigues",
        "latitude": "43.60989000",
        "longitude": "-1.31381000"
    },
    {
        "id": 46834,
        "name": "Saucats",
        "latitude": "44.65405000",
        "longitude": "-0.59643000"
    },
    {
        "id": 46835,
        "name": "Saugnac-et-Cambran",
        "latitude": "43.67098000",
        "longitude": "-0.99495000"
    },
    {
        "id": 46836,
        "name": "Saugues",
        "latitude": "44.96008000",
        "longitude": "3.54737000"
    },
    {
        "id": 46837,
        "name": "Saujon",
        "latitude": "45.67309000",
        "longitude": "-0.92620000"
    },
    {
        "id": 46838,
        "name": "Saulce-sur-Rhône",
        "latitude": "44.70430000",
        "longitude": "4.80061000"
    },
    {
        "id": 46839,
        "name": "Saulcy-sur-Meurthe",
        "latitude": "48.23758000",
        "longitude": "6.96443000"
    },
    {
        "id": 46840,
        "name": "Saulgé",
        "latitude": "46.37758000",
        "longitude": "0.87577000"
    },
    {
        "id": 46841,
        "name": "Saulieu",
        "latitude": "47.28023000",
        "longitude": "4.22857000"
    },
    {
        "id": 46842,
        "name": "Saulnes",
        "latitude": "49.53189000",
        "longitude": "5.82130000"
    },
    {
        "id": 46843,
        "name": "Saulny",
        "latitude": "49.15770000",
        "longitude": "6.10929000"
    },
    {
        "id": 46844,
        "name": "Sault",
        "latitude": "44.08968000",
        "longitude": "5.40836000"
    },
    {
        "id": 46845,
        "name": "Sault-Brénaz",
        "latitude": "45.86132000",
        "longitude": "5.39954000"
    },
    {
        "id": 46846,
        "name": "Sault-lès-Rethel",
        "latitude": "49.49980000",
        "longitude": "4.36227000"
    },
    {
        "id": 46847,
        "name": "Saultain",
        "latitude": "50.33676000",
        "longitude": "3.57723000"
    },
    {
        "id": 46848,
        "name": "Saulx-les-Chartreux",
        "latitude": "48.69062000",
        "longitude": "2.26727000"
    },
    {
        "id": 46849,
        "name": "Saulxures-lès-Nancy",
        "latitude": "48.68872000",
        "longitude": "6.24353000"
    },
    {
        "id": 46850,
        "name": "Saulxures-sur-Moselotte",
        "latitude": "47.94894000",
        "longitude": "6.77040000"
    },
    {
        "id": 46851,
        "name": "Saulzoir",
        "latitude": "50.24057000",
        "longitude": "3.44430000"
    },
    {
        "id": 46852,
        "name": "Saumur",
        "latitude": "47.25908000",
        "longitude": "-0.07796000"
    },
    {
        "id": 46853,
        "name": "Sausheim",
        "latitude": "47.78711000",
        "longitude": "7.37267000"
    },
    {
        "id": 46854,
        "name": "Saussan",
        "latitude": "43.57220000",
        "longitude": "3.77500000"
    },
    {
        "id": 46855,
        "name": "Saussay",
        "latitude": "48.85571000",
        "longitude": "1.40889000"
    },
    {
        "id": 46856,
        "name": "Sausset-les-Pins",
        "latitude": "43.33136000",
        "longitude": "5.10431000"
    },
    {
        "id": 46857,
        "name": "Sautron",
        "latitude": "47.26274000",
        "longitude": "-1.67107000"
    },
    {
        "id": 46858,
        "name": "Sauvagnon",
        "latitude": "43.40000000",
        "longitude": "-0.38333000"
    },
    {
        "id": 46859,
        "name": "Sauve",
        "latitude": "43.94150000",
        "longitude": "3.94903000"
    },
    {
        "id": 46860,
        "name": "Sauverny",
        "latitude": "46.31514000",
        "longitude": "6.11827000"
    },
    {
        "id": 46861,
        "name": "Sauveterre",
        "latitude": "44.02282000",
        "longitude": "4.79347000"
    },
    {
        "id": 46862,
        "name": "Sauveterre-de-Béarn",
        "latitude": "43.40000000",
        "longitude": "-0.93333000"
    },
    {
        "id": 46863,
        "name": "Sauveterre-de-Guyenne",
        "latitude": "44.69300000",
        "longitude": "-0.08549000"
    },
    {
        "id": 46864,
        "name": "Sauvian",
        "latitude": "43.29293000",
        "longitude": "3.26024000"
    },
    {
        "id": 46865,
        "name": "Sauviat-sur-Vige",
        "latitude": "45.90720000",
        "longitude": "1.60827000"
    },
    {
        "id": 46866,
        "name": "Sauvigny-les-Bois",
        "latitude": "46.96676000",
        "longitude": "3.27190000"
    },
    {
        "id": 46867,
        "name": "Sauxillanges",
        "latitude": "45.55109000",
        "longitude": "3.37147000"
    },
    {
        "id": 46869,
        "name": "Sauzé-Vaussais",
        "latitude": "46.13369000",
        "longitude": "0.10673000"
    },
    {
        "id": 46868,
        "name": "Sauzet",
        "latitude": "44.60397000",
        "longitude": "4.82091000"
    },
    {
        "id": 46870,
        "name": "Savasse",
        "latitude": "44.60428000",
        "longitude": "4.77653000"
    },
    {
        "id": 46871,
        "name": "Savenay",
        "latitude": "47.36072000",
        "longitude": "-1.94215000"
    },
    {
        "id": 46872,
        "name": "Savennières",
        "latitude": "47.38225000",
        "longitude": "-0.65708000"
    },
    {
        "id": 46873,
        "name": "Saverdun",
        "latitude": "43.23526000",
        "longitude": "1.57398000"
    },
    {
        "id": 46874,
        "name": "Saverne",
        "latitude": "48.74164000",
        "longitude": "7.36221000"
    },
    {
        "id": 46884,
        "name": "Savigné",
        "latitude": "46.15950000",
        "longitude": "0.31937000"
    },
    {
        "id": 46885,
        "name": "Savigné-sur-Lathan",
        "latitude": "47.44483000",
        "longitude": "0.32093000"
    },
    {
        "id": 46875,
        "name": "Savigneux",
        "latitude": "45.61670000",
        "longitude": "4.08330000"
    },
    {
        "id": 46876,
        "name": "Savigny",
        "latitude": "45.81595000",
        "longitude": "4.57410000"
    },
    {
        "id": 46878,
        "name": "Savigny-en-Sancerre",
        "latitude": "47.44142000",
        "longitude": "2.80953000"
    },
    {
        "id": 46879,
        "name": "Savigny-en-Véron",
        "latitude": "47.20071000",
        "longitude": "0.14457000"
    },
    {
        "id": 46880,
        "name": "Savigny-le-Temple",
        "latitude": "48.57409000",
        "longitude": "2.58287000"
    },
    {
        "id": 46881,
        "name": "Savigny-lès-Beaune",
        "latitude": "47.06357000",
        "longitude": "4.81821000"
    },
    {
        "id": 46877,
        "name": "Savigny-Lévescault",
        "latitude": "46.53502000",
        "longitude": "0.47719000"
    },
    {
        "id": 46882,
        "name": "Savigny-sur-Braye",
        "latitude": "47.87923000",
        "longitude": "0.80981000"
    },
    {
        "id": 46883,
        "name": "Savigny-sur-Orge",
        "latitude": "48.67677000",
        "longitude": "2.34835000"
    },
    {
        "id": 46886,
        "name": "Savoie",
        "latitude": "45.46805000",
        "longitude": "6.48547000"
    },
    {
        "id": 46887,
        "name": "Savonnières",
        "latitude": "47.34769000",
        "longitude": "0.54961000"
    },
    {
        "id": 46888,
        "name": "Savy-Berlette",
        "latitude": "50.35249000",
        "longitude": "2.56456000"
    },
    {
        "id": 46889,
        "name": "Sayat",
        "latitude": "45.82640000",
        "longitude": "3.05250000"
    },
    {
        "id": 46890,
        "name": "Saze",
        "latitude": "43.94340000",
        "longitude": "4.68096000"
    },
    {
        "id": 46894,
        "name": "Scaër",
        "latitude": "48.03333000",
        "longitude": "-3.70000000"
    },
    {
        "id": 46895,
        "name": "Sceaux",
        "latitude": "48.77644000",
        "longitude": "2.29026000"
    },
    {
        "id": 46896,
        "name": "Scey-sur-Saône-et-Saint-Albin",
        "latitude": "47.66398000",
        "longitude": "5.97451000"
    },
    {
        "id": 46897,
        "name": "Scherwiller",
        "latitude": "48.28713000",
        "longitude": "7.42135000"
    },
    {
        "id": 46898,
        "name": "Schiltigheim",
        "latitude": "48.60749000",
        "longitude": "7.74931000"
    },
    {
        "id": 46899,
        "name": "Schirmeck",
        "latitude": "48.48313000",
        "longitude": "7.22004000"
    },
    {
        "id": 46900,
        "name": "Schirrhein",
        "latitude": "48.80161000",
        "longitude": "7.90588000"
    },
    {
        "id": 46901,
        "name": "Schnersheim",
        "latitude": "48.65741000",
        "longitude": "7.56701000"
    },
    {
        "id": 46904,
        "name": "Schœneck",
        "latitude": "49.21667000",
        "longitude": "6.91667000"
    },
    {
        "id": 46902,
        "name": "Schweighouse-sur-Moder",
        "latitude": "48.82009000",
        "longitude": "7.72810000"
    },
    {
        "id": 46903,
        "name": "Schwindratzheim",
        "latitude": "48.75582000",
        "longitude": "7.59898000"
    },
    {
        "id": 46905,
        "name": "Sciez",
        "latitude": "46.33251000",
        "longitude": "6.38413000"
    },
    {
        "id": 46906,
        "name": "Scionzier",
        "latitude": "46.06010000",
        "longitude": "6.55271000"
    },
    {
        "id": 46907,
        "name": "Scorbé-Clairvaux",
        "latitude": "46.81061000",
        "longitude": "0.41369000"
    },
    {
        "id": 46908,
        "name": "Scy-Chazelles",
        "latitude": "49.11340000",
        "longitude": "6.11470000"
    },
    {
        "id": 47132,
        "name": "Sébazac-Concourès",
        "latitude": "44.40484000",
        "longitude": "2.60324000"
    },
    {
        "id": 46909,
        "name": "Seboncourt",
        "latitude": "49.95273000",
        "longitude": "3.47586000"
    },
    {
        "id": 46910,
        "name": "Sebourg",
        "latitude": "50.34249000",
        "longitude": "3.64352000"
    },
    {
        "id": 46911,
        "name": "Seclin",
        "latitude": "50.54873000",
        "longitude": "3.02731000"
    },
    {
        "id": 46912,
        "name": "Secondigny",
        "latitude": "46.61024000",
        "longitude": "-0.41679000"
    },
    {
        "id": 46913,
        "name": "Sedan",
        "latitude": "49.70187000",
        "longitude": "4.94028000"
    },
    {
        "id": 47133,
        "name": "Sées",
        "latitude": "48.60403000",
        "longitude": "0.17244000"
    },
    {
        "id": 47134,
        "name": "Séez",
        "latitude": "45.62368000",
        "longitude": "6.80149000"
    },
    {
        "id": 47135,
        "name": "Ségny",
        "latitude": "46.29571000",
        "longitude": "6.07257000"
    },
    {
        "id": 46914,
        "name": "Segonzac",
        "latitude": "45.61667000",
        "longitude": "-0.21667000"
    },
    {
        "id": 46915,
        "name": "Segré",
        "latitude": "47.68646000",
        "longitude": "-0.87237000"
    },
    {
        "id": 46916,
        "name": "Seichamps",
        "latitude": "48.71492000",
        "longitude": "6.26139000"
    },
    {
        "id": 46917,
        "name": "Seiches-sur-le-Loir",
        "latitude": "47.57351000",
        "longitude": "-0.35628000"
    },
    {
        "id": 46918,
        "name": "Seignelay",
        "latitude": "47.90542000",
        "longitude": "3.60136000"
    },
    {
        "id": 46919,
        "name": "Seignosse",
        "latitude": "43.68774000",
        "longitude": "-1.37000000"
    },
    {
        "id": 46920,
        "name": "Seigy",
        "latitude": "47.25688000",
        "longitude": "1.39964000"
    },
    {
        "id": 46921,
        "name": "Seilh",
        "latitude": "43.69469000",
        "longitude": "1.35509000"
    },
    {
        "id": 46922,
        "name": "Seilhac",
        "latitude": "45.36709000",
        "longitude": "1.71350000"
    },
    {
        "id": 46923,
        "name": "Seillans",
        "latitude": "43.63623000",
        "longitude": "6.64332000"
    },
    {
        "id": 46927,
        "name": "Seine-et-Marne",
        "latitude": "48.64599000",
        "longitude": "2.95905000"
    },
    {
        "id": 46924,
        "name": "Seine-Maritime",
        "latitude": "49.67278000",
        "longitude": "1.12519000"
    },
    {
        "id": 46925,
        "name": "Seine-Port",
        "latitude": "48.55743000",
        "longitude": "2.55316000"
    },
    {
        "id": 46926,
        "name": "Seine-Saint-Denis",
        "latitude": "48.91421000",
        "longitude": "2.47604000"
    },
    {
        "id": 46928,
        "name": "Seingbouse",
        "latitude": "49.11341000",
        "longitude": "6.83186000"
    },
    {
        "id": 46929,
        "name": "Seissan",
        "latitude": "43.49215000",
        "longitude": "0.59250000"
    },
    {
        "id": 47136,
        "name": "Sélestat",
        "latitude": "48.26195000",
        "longitude": "7.44890000"
    },
    {
        "id": 46930,
        "name": "Selles-Saint-Denis",
        "latitude": "47.38695000",
        "longitude": "1.92295000"
    },
    {
        "id": 46931,
        "name": "Selles-sur-Cher",
        "latitude": "47.27904000",
        "longitude": "1.55387000"
    },
    {
        "id": 46932,
        "name": "Seloncourt",
        "latitude": "47.45989000",
        "longitude": "6.85535000"
    },
    {
        "id": 46933,
        "name": "Selongey",
        "latitude": "47.58846000",
        "longitude": "5.18483000"
    },
    {
        "id": 46934,
        "name": "Seltz",
        "latitude": "48.89520000",
        "longitude": "8.10757000"
    },
    {
        "id": 46935,
        "name": "Selvigny",
        "latitude": "50.07971000",
        "longitude": "3.34899000"
    },
    {
        "id": 47137,
        "name": "Sémalens",
        "latitude": "43.59225000",
        "longitude": "2.11208000"
    },
    {
        "id": 46936,
        "name": "Semblançay",
        "latitude": "47.50000000",
        "longitude": "0.58333000"
    },
    {
        "id": 47138,
        "name": "Séméac",
        "latitude": "43.22915000",
        "longitude": "0.10602000"
    },
    {
        "id": 46937,
        "name": "Semoy",
        "latitude": "47.93333000",
        "longitude": "1.95000000"
    },
    {
        "id": 46938,
        "name": "Semur-en-Auxois",
        "latitude": "47.48333000",
        "longitude": "4.33333000"
    },
    {
        "id": 46939,
        "name": "Semussac",
        "latitude": "45.60000000",
        "longitude": "-0.91667000"
    },
    {
        "id": 47139,
        "name": "Sénas",
        "latitude": "43.74375000",
        "longitude": "5.07800000"
    },
    {
        "id": 47140,
        "name": "Séné",
        "latitude": "47.61900000",
        "longitude": "-2.73700000"
    },
    {
        "id": 46940,
        "name": "Senlis",
        "latitude": "49.20724000",
        "longitude": "2.58661000"
    },
    {
        "id": 46941,
        "name": "Sennecey-le-Grand",
        "latitude": "46.64137000",
        "longitude": "4.86707000"
    },
    {
        "id": 46942,
        "name": "Sennecey-lès-Dijon",
        "latitude": "47.28984000",
        "longitude": "5.10485000"
    },
    {
        "id": 46943,
        "name": "Senonches",
        "latitude": "48.55999000",
        "longitude": "1.03069000"
    },
    {
        "id": 46944,
        "name": "Senones",
        "latitude": "48.39475000",
        "longitude": "6.97818000"
    },
    {
        "id": 46945,
        "name": "Sens",
        "latitude": "48.19738000",
        "longitude": "3.28328000"
    },
    {
        "id": 46946,
        "name": "Sens-de-Bretagne",
        "latitude": "48.33245000",
        "longitude": "-1.53535000"
    },
    {
        "id": 46947,
        "name": "Sentheim",
        "latitude": "47.75560000",
        "longitude": "7.05305000"
    },
    {
        "id": 46950,
        "name": "Septème",
        "latitude": "45.55294000",
        "longitude": "5.00596000"
    },
    {
        "id": 46951,
        "name": "Septèmes-les-Vallons",
        "latitude": "43.39834000",
        "longitude": "5.36596000"
    },
    {
        "id": 46948,
        "name": "Septeuil",
        "latitude": "48.89245000",
        "longitude": "1.68357000"
    },
    {
        "id": 46949,
        "name": "Septfonds",
        "latitude": "44.17813000",
        "longitude": "1.61806000"
    },
    {
        "id": 46952,
        "name": "Sequedin",
        "latitude": "50.62575000",
        "longitude": "2.98276000"
    },
    {
        "id": 46953,
        "name": "Seraincourt",
        "latitude": "49.03573000",
        "longitude": "1.86703000"
    },
    {
        "id": 47141,
        "name": "Séreilhac",
        "latitude": "45.76843000",
        "longitude": "1.08052000"
    },
    {
        "id": 46975,
        "name": "Serémange-Erzange",
        "latitude": "49.32062000",
        "longitude": "6.09092000"
    },
    {
        "id": 47142,
        "name": "Sérent",
        "latitude": "47.82333000",
        "longitude": "-2.50571000"
    },
    {
        "id": 47147,
        "name": "Sérézin-du-Rhône",
        "latitude": "45.62871000",
        "longitude": "4.82400000"
    },
    {
        "id": 46954,
        "name": "Sergines",
        "latitude": "48.34203000",
        "longitude": "3.26213000"
    },
    {
        "id": 46955,
        "name": "Sergy",
        "latitude": "46.25069000",
        "longitude": "6.00183000"
    },
    {
        "id": 47143,
        "name": "Sérifontaine",
        "latitude": "49.35440000",
        "longitude": "1.76873000"
    },
    {
        "id": 47144,
        "name": "Sérignan",
        "latitude": "43.27873000",
        "longitude": "3.27712000"
    },
    {
        "id": 47145,
        "name": "Sérignan-du-Comtat",
        "latitude": "44.18915000",
        "longitude": "4.84379000"
    },
    {
        "id": 47146,
        "name": "Sérigné",
        "latitude": "46.50107000",
        "longitude": "-0.84453000"
    },
    {
        "id": 46956,
        "name": "Sermaises",
        "latitude": "48.29650000",
        "longitude": "2.20546000"
    },
    {
        "id": 46957,
        "name": "Sermaize-les-Bains",
        "latitude": "48.78507000",
        "longitude": "4.91169000"
    },
    {
        "id": 46959,
        "name": "Sermérieu",
        "latitude": "45.66995000",
        "longitude": "5.41110000"
    },
    {
        "id": 46958,
        "name": "Sermoise-sur-Loire",
        "latitude": "46.95000000",
        "longitude": "3.18333000"
    },
    {
        "id": 46960,
        "name": "Sernhac",
        "latitude": "43.91116000",
        "longitude": "4.55039000"
    },
    {
        "id": 46961,
        "name": "Serpaize",
        "latitude": "45.55824000",
        "longitude": "4.91764000"
    },
    {
        "id": 46962,
        "name": "Serques",
        "latitude": "50.79338000",
        "longitude": "2.20134000"
    },
    {
        "id": 46963,
        "name": "Serqueux",
        "latitude": "49.63202000",
        "longitude": "1.54005000"
    },
    {
        "id": 46964,
        "name": "Serquigny",
        "latitude": "49.10943000",
        "longitude": "0.71016000"
    },
    {
        "id": 46965,
        "name": "Serre-les-Sapins",
        "latitude": "47.25000000",
        "longitude": "5.93333000"
    },
    {
        "id": 46966,
        "name": "Serres",
        "latitude": "44.42753000",
        "longitude": "5.71458000"
    },
    {
        "id": 46967,
        "name": "Serres-Castet",
        "latitude": "43.38333000",
        "longitude": "-0.35000000"
    },
    {
        "id": 46969,
        "name": "Serrières",
        "latitude": "45.31799000",
        "longitude": "4.76327000"
    },
    {
        "id": 46970,
        "name": "Serrières-de-Briord",
        "latitude": "45.80706000",
        "longitude": "5.44795000"
    },
    {
        "id": 46968,
        "name": "Serris",
        "latitude": "48.84528000",
        "longitude": "2.78611000"
    },
    {
        "id": 46971,
        "name": "Servas",
        "latitude": "46.13317000",
        "longitude": "5.16510000"
    },
    {
        "id": 46972,
        "name": "Servian",
        "latitude": "43.42716000",
        "longitude": "3.30032000"
    },
    {
        "id": 46973,
        "name": "Servon",
        "latitude": "48.71662000",
        "longitude": "2.58737000"
    },
    {
        "id": 46974,
        "name": "Servon-sur-Vilaine",
        "latitude": "48.12114000",
        "longitude": "-1.45971000"
    },
    {
        "id": 46976,
        "name": "Sessenheim",
        "latitude": "48.79652000",
        "longitude": "7.98719000"
    },
    {
        "id": 47129,
        "name": "Sète",
        "latitude": "43.40280000",
        "longitude": "3.69278000"
    },
    {
        "id": 46977,
        "name": "Seugy",
        "latitude": "49.12182000",
        "longitude": "2.39377000"
    },
    {
        "id": 46978,
        "name": "Seurre",
        "latitude": "46.99923000",
        "longitude": "5.15138000"
    },
    {
        "id": 47149,
        "name": "Sévérac",
        "latitude": "47.55008000",
        "longitude": "-2.07496000"
    },
    {
        "id": 47150,
        "name": "Sévérac-le-Château",
        "latitude": "44.32429000",
        "longitude": "3.05929000"
    },
    {
        "id": 47148,
        "name": "Sévignac",
        "latitude": "48.33297000",
        "longitude": "-2.33915000"
    },
    {
        "id": 46979,
        "name": "Sevran",
        "latitude": "48.94472000",
        "longitude": "2.52746000"
    },
    {
        "id": 47130,
        "name": "Sèvres",
        "latitude": "48.82292000",
        "longitude": "2.21757000"
    },
    {
        "id": 47131,
        "name": "Sèvres-Anxaumont",
        "latitude": "46.57036000",
        "longitude": "0.46603000"
    },
    {
        "id": 46980,
        "name": "Sevrey",
        "latitude": "46.73825000",
        "longitude": "4.84030000"
    },
    {
        "id": 46981,
        "name": "Sevrier",
        "latitude": "45.86442000",
        "longitude": "6.13990000"
    },
    {
        "id": 46982,
        "name": "Seyne-les-Alpes",
        "latitude": "44.35042000",
        "longitude": "6.35634000"
    },
    {
        "id": 46983,
        "name": "Seynod",
        "latitude": "45.88549000",
        "longitude": "6.08831000"
    },
    {
        "id": 46984,
        "name": "Seysses",
        "latitude": "43.49801000",
        "longitude": "1.31081000"
    },
    {
        "id": 46985,
        "name": "Seyssinet-Pariset",
        "latitude": "45.17675000",
        "longitude": "5.69387000"
    },
    {
        "id": 46986,
        "name": "Seyssins",
        "latitude": "45.16224000",
        "longitude": "5.68673000"
    },
    {
        "id": 46987,
        "name": "Seyssuel",
        "latitude": "45.55826000",
        "longitude": "4.84313000"
    },
    {
        "id": 47151,
        "name": "Sézanne",
        "latitude": "48.72047000",
        "longitude": "3.72339000"
    },
    {
        "id": 46988,
        "name": "Sibiril",
        "latitude": "48.66667000",
        "longitude": "-4.06667000"
    },
    {
        "id": 46989,
        "name": "Sierck-les-Bains",
        "latitude": "49.43942000",
        "longitude": "6.35816000"
    },
    {
        "id": 46990,
        "name": "Sierentz",
        "latitude": "47.65834000",
        "longitude": "7.45387000"
    },
    {
        "id": 46991,
        "name": "Sigean",
        "latitude": "43.02777000",
        "longitude": "2.97916000"
    },
    {
        "id": 46992,
        "name": "Signes",
        "latitude": "43.29080000",
        "longitude": "5.86284000"
    },
    {
        "id": 46993,
        "name": "Signy-le-Petit",
        "latitude": "49.90277000",
        "longitude": "4.27990000"
    },
    {
        "id": 46994,
        "name": "Sigolsheim",
        "latitude": "48.13493000",
        "longitude": "7.29980000"
    },
    {
        "id": 46995,
        "name": "Sillans",
        "latitude": "45.34261000",
        "longitude": "5.38864000"
    },
    {
        "id": 46999,
        "name": "Sillé-le-Guillaume",
        "latitude": "48.18266000",
        "longitude": "-0.12642000"
    },
    {
        "id": 46996,
        "name": "Sillery",
        "latitude": "49.19822000",
        "longitude": "4.13244000"
    },
    {
        "id": 46997,
        "name": "Sillingy",
        "latitude": "45.94781000",
        "longitude": "6.04415000"
    },
    {
        "id": 46998,
        "name": "Silly-le-Long",
        "latitude": "49.10749000",
        "longitude": "2.79226000"
    },
    {
        "id": 47000,
        "name": "Simandre",
        "latitude": "46.62364000",
        "longitude": "4.98777000"
    },
    {
        "id": 47001,
        "name": "Simandres",
        "latitude": "45.61955000",
        "longitude": "4.87314000"
    },
    {
        "id": 47002,
        "name": "Simiane-Collongue",
        "latitude": "43.43067000",
        "longitude": "5.43454000"
    },
    {
        "id": 47003,
        "name": "Sin-le-Noble",
        "latitude": "50.36159000",
        "longitude": "3.13113000"
    },
    {
        "id": 47004,
        "name": "Sinceny",
        "latitude": "49.59619000",
        "longitude": "3.24679000"
    },
    {
        "id": 47005,
        "name": "Sion-les-Mines",
        "latitude": "47.73476000",
        "longitude": "-1.59190000"
    },
    {
        "id": 47006,
        "name": "Sireuil",
        "latitude": "45.61667000",
        "longitude": "0.01667000"
    },
    {
        "id": 47007,
        "name": "Sisco",
        "latitude": "42.80000000",
        "longitude": "9.43333000"
    },
    {
        "id": 47008,
        "name": "Sissonne",
        "latitude": "49.57107000",
        "longitude": "3.89369000"
    },
    {
        "id": 47009,
        "name": "Sisteron",
        "latitude": "44.19002000",
        "longitude": "5.94643000"
    },
    {
        "id": 47010,
        "name": "Six-Fours-les-Plages",
        "latitude": "43.09174000",
        "longitude": "5.82465000"
    },
    {
        "id": 47011,
        "name": "Sixt-sur-Aff",
        "latitude": "47.77648000",
        "longitude": "-2.07867000"
    },
    {
        "id": 47012,
        "name": "Sizun",
        "latitude": "48.40000000",
        "longitude": "-4.08333000"
    },
    {
        "id": 47013,
        "name": "Smarves",
        "latitude": "46.51078000",
        "longitude": "0.34980000"
    },
    {
        "id": 47014,
        "name": "Sochaux",
        "latitude": "47.50808000",
        "longitude": "6.82748000"
    },
    {
        "id": 47015,
        "name": "Socx",
        "latitude": "50.93571000",
        "longitude": "2.42422000"
    },
    {
        "id": 47016,
        "name": "Soignolles-en-Brie",
        "latitude": "48.65350000",
        "longitude": "2.69968000"
    },
    {
        "id": 47017,
        "name": "Soings-en-Sologne",
        "latitude": "47.41289000",
        "longitude": "1.52452000"
    },
    {
        "id": 47018,
        "name": "Soissons",
        "latitude": "49.38167000",
        "longitude": "3.32361000"
    },
    {
        "id": 47019,
        "name": "Soisy-sous-Montmorency",
        "latitude": "48.98813000",
        "longitude": "2.30156000"
    },
    {
        "id": 47021,
        "name": "Soisy-sur-École",
        "latitude": "48.47637000",
        "longitude": "2.49301000"
    },
    {
        "id": 47020,
        "name": "Soisy-sur-Seine",
        "latitude": "48.64875000",
        "longitude": "2.45223000"
    },
    {
        "id": 47022,
        "name": "Solaize",
        "latitude": "45.63970000",
        "longitude": "4.84038000"
    },
    {
        "id": 47023,
        "name": "Solers",
        "latitude": "48.65919000",
        "longitude": "2.71617000"
    },
    {
        "id": 47024,
        "name": "Solesmes",
        "latitude": "50.18468000",
        "longitude": "3.49799000"
    },
    {
        "id": 47025,
        "name": "Solesmes",
        "latitude": "47.85009000",
        "longitude": "-0.29806000"
    },
    {
        "id": 47026,
        "name": "Solgne",
        "latitude": "48.96543000",
        "longitude": "6.29429000"
    },
    {
        "id": 47027,
        "name": "Soliers",
        "latitude": "49.13440000",
        "longitude": "-0.29613000"
    },
    {
        "id": 47028,
        "name": "Solignac",
        "latitude": "45.75528000",
        "longitude": "1.27563000"
    },
    {
        "id": 47029,
        "name": "Solignac-sur-Loire",
        "latitude": "44.96833000",
        "longitude": "3.88635000"
    },
    {
        "id": 47030,
        "name": "Solliès-Pont",
        "latitude": "43.19009000",
        "longitude": "6.04115000"
    },
    {
        "id": 47031,
        "name": "Solliès-Toucas",
        "latitude": "43.20579000",
        "longitude": "6.02485000"
    },
    {
        "id": 47032,
        "name": "Solliès-Ville",
        "latitude": "43.18256000",
        "longitude": "6.03849000"
    },
    {
        "id": 47033,
        "name": "Solre-le-Château",
        "latitude": "50.17432000",
        "longitude": "4.08898000"
    },
    {
        "id": 47034,
        "name": "Somain",
        "latitude": "50.35961000",
        "longitude": "3.28108000"
    },
    {
        "id": 47035,
        "name": "Somme",
        "latitude": "49.93141000",
        "longitude": "2.27639000"
    },
    {
        "id": 47036,
        "name": "Sommières",
        "latitude": "43.78534000",
        "longitude": "4.08973000"
    },
    {
        "id": 47037,
        "name": "Sonchamp",
        "latitude": "48.57590000",
        "longitude": "1.87753000"
    },
    {
        "id": 47038,
        "name": "Songeons",
        "latitude": "49.54916000",
        "longitude": "1.85361000"
    },
    {
        "id": 47039,
        "name": "Sonnay",
        "latitude": "45.35809000",
        "longitude": "4.90479000"
    },
    {
        "id": 47040,
        "name": "Sonnaz",
        "latitude": "45.61765000",
        "longitude": "5.91505000"
    },
    {
        "id": 47041,
        "name": "Sonzay",
        "latitude": "47.52687000",
        "longitude": "0.46203000"
    },
    {
        "id": 47042,
        "name": "Soorts-Hossegor",
        "latitude": "43.66490000",
        "longitude": "-1.39717000"
    },
    {
        "id": 47043,
        "name": "Sorbiers",
        "latitude": "45.49111000",
        "longitude": "4.44163000"
    },
    {
        "id": 47050,
        "name": "Sorède",
        "latitude": "42.53069000",
        "longitude": "2.95708000"
    },
    {
        "id": 47044,
        "name": "Sorel-Moussel",
        "latitude": "48.83391000",
        "longitude": "1.36699000"
    },
    {
        "id": 47051,
        "name": "Sorèze",
        "latitude": "43.45241000",
        "longitude": "2.06799000"
    },
    {
        "id": 47045,
        "name": "Sorges",
        "latitude": "45.30563000",
        "longitude": "0.87328000"
    },
    {
        "id": 47046,
        "name": "Sorgues",
        "latitude": "44.01023000",
        "longitude": "4.87381000"
    },
    {
        "id": 47047,
        "name": "Sorigny",
        "latitude": "47.24329000",
        "longitude": "0.69520000"
    },
    {
        "id": 47048,
        "name": "Sormiou",
        "latitude": "43.20959000",
        "longitude": "5.41872000"
    },
    {
        "id": 47049,
        "name": "Sornay",
        "latitude": "46.63023000",
        "longitude": "5.17999000"
    },
    {
        "id": 47052,
        "name": "Sospel",
        "latitude": "43.87792000",
        "longitude": "7.44788000"
    },
    {
        "id": 47053,
        "name": "Sottevast",
        "latitude": "49.52374000",
        "longitude": "-1.59169000"
    },
    {
        "id": 47054,
        "name": "Sotteville-lès-Rouen",
        "latitude": "49.40972000",
        "longitude": "1.09005000"
    },
    {
        "id": 47055,
        "name": "Soual",
        "latitude": "43.55530000",
        "longitude": "2.11679000"
    },
    {
        "id": 47056,
        "name": "Soubise",
        "latitude": "45.92395000",
        "longitude": "-1.00938000"
    },
    {
        "id": 47057,
        "name": "Soucelles",
        "latitude": "47.56848000",
        "longitude": "-0.41819000"
    },
    {
        "id": 47058,
        "name": "Souchez",
        "latitude": "50.39291000",
        "longitude": "2.73984000"
    },
    {
        "id": 47059,
        "name": "Soucht",
        "latitude": "48.95902000",
        "longitude": "7.33434000"
    },
    {
        "id": 47060,
        "name": "Soucieu-en-Jarrest",
        "latitude": "45.67771000",
        "longitude": "4.70379000"
    },
    {
        "id": 47061,
        "name": "Soucy",
        "latitude": "48.24956000",
        "longitude": "3.32385000"
    },
    {
        "id": 47062,
        "name": "Soudan",
        "latitude": "47.73777000",
        "longitude": "-1.30566000"
    },
    {
        "id": 47063,
        "name": "Soues",
        "latitude": "43.20736000",
        "longitude": "0.09874000"
    },
    {
        "id": 47064,
        "name": "Souesmes",
        "latitude": "47.45638000",
        "longitude": "2.17495000"
    },
    {
        "id": 47065,
        "name": "Souffelweyersheim",
        "latitude": "48.63540000",
        "longitude": "7.74141000"
    },
    {
        "id": 47066,
        "name": "Soufflenheim",
        "latitude": "48.83026000",
        "longitude": "7.96268000"
    },
    {
        "id": 47067,
        "name": "Souillac",
        "latitude": "44.89720000",
        "longitude": "1.47224000"
    },
    {
        "id": 47068,
        "name": "Soulac-sur-Mer",
        "latitude": "45.51068000",
        "longitude": "-1.12524000"
    },
    {
        "id": 47069,
        "name": "Soulaines-sur-Aubance",
        "latitude": "47.36381000",
        "longitude": "-0.52265000"
    },
    {
        "id": 47070,
        "name": "Soulaire-et-Bourg",
        "latitude": "47.57896000",
        "longitude": "-0.55232000"
    },
    {
        "id": 47071,
        "name": "Soulgé-sur-Ouette",
        "latitude": "48.06667000",
        "longitude": "-0.56667000"
    },
    {
        "id": 47072,
        "name": "Souligné-sous-Ballon",
        "latitude": "48.13758000",
        "longitude": "0.23572000"
    },
    {
        "id": 47073,
        "name": "Soullans",
        "latitude": "46.79624000",
        "longitude": "-1.90106000"
    },
    {
        "id": 47074,
        "name": "Soultz-Haut-Rhin",
        "latitude": "47.88849000",
        "longitude": "7.22860000"
    },
    {
        "id": 47075,
        "name": "Soultz-sous-Forêts",
        "latitude": "48.93693000",
        "longitude": "7.88110000"
    },
    {
        "id": 47076,
        "name": "Soultzeren",
        "latitude": "48.06394000",
        "longitude": "7.10233000"
    },
    {
        "id": 47077,
        "name": "Soultzmatt",
        "latitude": "47.96188000",
        "longitude": "7.23759000"
    },
    {
        "id": 47078,
        "name": "Soumoulou",
        "latitude": "43.26667000",
        "longitude": "-0.18333000"
    },
    {
        "id": 47079,
        "name": "Souppes-sur-Loing",
        "latitude": "48.18297000",
        "longitude": "2.73521000"
    },
    {
        "id": 47080,
        "name": "Souprosse",
        "latitude": "43.78869000",
        "longitude": "-0.71035000"
    },
    {
        "id": 47081,
        "name": "Souraïde",
        "latitude": "43.34154000",
        "longitude": "-1.47559000"
    },
    {
        "id": 47082,
        "name": "Sourcieux-les-Mines",
        "latitude": "45.80606000",
        "longitude": "4.62254000"
    },
    {
        "id": 47083,
        "name": "Sourdeval",
        "latitude": "48.72312000",
        "longitude": "-0.92223000"
    },
    {
        "id": 47084,
        "name": "Sourdun",
        "latitude": "48.53688000",
        "longitude": "3.35200000"
    },
    {
        "id": 47085,
        "name": "Sours",
        "latitude": "48.41043000",
        "longitude": "1.59889000"
    },
    {
        "id": 47086,
        "name": "Sourzac",
        "latitude": "45.04978000",
        "longitude": "0.39598000"
    },
    {
        "id": 47087,
        "name": "Soussans",
        "latitude": "45.05619000",
        "longitude": "-0.69916000"
    },
    {
        "id": 47088,
        "name": "Soustons",
        "latitude": "43.75328000",
        "longitude": "-1.32780000"
    },
    {
        "id": 47089,
        "name": "Souvigny",
        "latitude": "46.53480000",
        "longitude": "3.19205000"
    },
    {
        "id": 47090,
        "name": "Soyaux",
        "latitude": "45.65000000",
        "longitude": "0.20000000"
    },
    {
        "id": 47091,
        "name": "Soyons",
        "latitude": "44.88914000",
        "longitude": "4.85026000"
    },
    {
        "id": 47092,
        "name": "Spay",
        "latitude": "47.92384000",
        "longitude": "0.15258000"
    },
    {
        "id": 47095,
        "name": "Spéracèdes",
        "latitude": "43.64850000",
        "longitude": "6.85889000"
    },
    {
        "id": 47096,
        "name": "Spézet",
        "latitude": "48.20000000",
        "longitude": "-3.71667000"
    },
    {
        "id": 47093,
        "name": "Spicheren",
        "latitude": "49.19252000",
        "longitude": "6.96611000"
    },
    {
        "id": 47094,
        "name": "Spycker",
        "latitude": "50.96915000",
        "longitude": "2.32184000"
    },
    {
        "id": 47097,
        "name": "Staffelfelden",
        "latitude": "47.82647000",
        "longitude": "7.25235000"
    },
    {
        "id": 47098,
        "name": "Stains",
        "latitude": "48.95000000",
        "longitude": "2.38333000"
    },
    {
        "id": 47099,
        "name": "Steenbecque",
        "latitude": "50.67421000",
        "longitude": "2.48442000"
    },
    {
        "id": 47100,
        "name": "Steene",
        "latitude": "50.95228000",
        "longitude": "2.36813000"
    },
    {
        "id": 47101,
        "name": "Steenvoorde",
        "latitude": "50.81046000",
        "longitude": "2.58244000"
    },
    {
        "id": 47102,
        "name": "Steenwerck",
        "latitude": "50.70199000",
        "longitude": "2.77829000"
    },
    {
        "id": 47103,
        "name": "Steinbach",
        "latitude": "47.82140000",
        "longitude": "7.15279000"
    },
    {
        "id": 47104,
        "name": "Steinbourg",
        "latitude": "48.77028000",
        "longitude": "7.41354000"
    },
    {
        "id": 47105,
        "name": "Stenay",
        "latitude": "49.49489000",
        "longitude": "5.18606000"
    },
    {
        "id": 47106,
        "name": "Still",
        "latitude": "48.54982000",
        "longitude": "7.40444000"
    },
    {
        "id": 47107,
        "name": "Stiring-Wendel",
        "latitude": "49.20198000",
        "longitude": "6.93170000"
    },
    {
        "id": 47108,
        "name": "Stosswihr",
        "latitude": "48.05389000",
        "longitude": "7.09964000"
    },
    {
        "id": 47109,
        "name": "Stotzheim",
        "latitude": "48.37868000",
        "longitude": "7.49235000"
    },
    {
        "id": 47110,
        "name": "Strasbourg",
        "latitude": "48.58392000",
        "longitude": "7.74553000"
    },
    {
        "id": 47112,
        "name": "Sucé-sur-Erdre",
        "latitude": "47.33333000",
        "longitude": "-1.53333000"
    },
    {
        "id": 47111,
        "name": "Sucy-en-Brie",
        "latitude": "48.76872000",
        "longitude": "2.53474000"
    },
    {
        "id": 47128,
        "name": "Suèvres",
        "latitude": "47.66655000",
        "longitude": "1.46153000"
    },
    {
        "id": 47113,
        "name": "Suippes",
        "latitude": "49.12886000",
        "longitude": "4.53446000"
    },
    {
        "id": 47114,
        "name": "Sully-sur-Loire",
        "latitude": "47.76372000",
        "longitude": "2.37238000"
    },
    {
        "id": 47115,
        "name": "Sulniac",
        "latitude": "47.67375000",
        "longitude": "-2.57136000"
    },
    {
        "id": 47116,
        "name": "Sumène",
        "latitude": "43.98057000",
        "longitude": "3.71575000"
    },
    {
        "id": 47117,
        "name": "Sundhoffen",
        "latitude": "48.04263000",
        "longitude": "7.41320000"
    },
    {
        "id": 47118,
        "name": "Sundhouse",
        "latitude": "48.25110000",
        "longitude": "7.60427000"
    },
    {
        "id": 47119,
        "name": "Surbourg",
        "latitude": "48.91009000",
        "longitude": "7.84716000"
    },
    {
        "id": 47120,
        "name": "Suresnes",
        "latitude": "48.87143000",
        "longitude": "2.22929000"
    },
    {
        "id": 47121,
        "name": "Surgères",
        "latitude": "46.10820000",
        "longitude": "-0.75148000"
    },
    {
        "id": 47122,
        "name": "Surtainville",
        "latitude": "49.45977000",
        "longitude": "-1.81298000"
    },
    {
        "id": 47123,
        "name": "Survilliers",
        "latitude": "49.09712000",
        "longitude": "2.54449000"
    },
    {
        "id": 47124,
        "name": "Sury-le-Comtal",
        "latitude": "45.53735000",
        "longitude": "4.18573000"
    },
    {
        "id": 47125,
        "name": "Surzur",
        "latitude": "47.57850000",
        "longitude": "-2.62892000"
    },
    {
        "id": 47126,
        "name": "Sussargues",
        "latitude": "43.71250000",
        "longitude": "4.00310000"
    },
    {
        "id": 47127,
        "name": "Suze-la-Rousse",
        "latitude": "44.28761000",
        "longitude": "4.84161000"
    },
    {
        "id": 47152,
        "name": "Tabanac",
        "latitude": "44.72059000",
        "longitude": "-0.40513000"
    },
    {
        "id": 47153,
        "name": "Tacoignières",
        "latitude": "48.83619000",
        "longitude": "1.67501000"
    },
    {
        "id": 47154,
        "name": "Taden",
        "latitude": "48.47530000",
        "longitude": "-2.01946000"
    },
    {
        "id": 47155,
        "name": "Taillades",
        "latitude": "43.83762000",
        "longitude": "5.08951000"
    },
    {
        "id": 47156,
        "name": "Taillecourt",
        "latitude": "47.49521000",
        "longitude": "6.85442000"
    },
    {
        "id": 47157,
        "name": "Tain-l’Hermitage",
        "latitude": "45.06672000",
        "longitude": "4.85590000"
    },
    {
        "id": 47158,
        "name": "Taintrux",
        "latitude": "48.24944000",
        "longitude": "6.89963000"
    },
    {
        "id": 47159,
        "name": "Taissy",
        "latitude": "49.21509000",
        "longitude": "4.09406000"
    },
    {
        "id": 47160,
        "name": "Talange",
        "latitude": "49.23577000",
        "longitude": "6.17167000"
    },
    {
        "id": 47161,
        "name": "Talant",
        "latitude": "47.33693000",
        "longitude": "5.00888000"
    },
    {
        "id": 47162,
        "name": "Talence",
        "latitude": "44.80477000",
        "longitude": "-0.59543000"
    },
    {
        "id": 47163,
        "name": "Talensac",
        "latitude": "48.10847000",
        "longitude": "-1.92829000"
    },
    {
        "id": 47164,
        "name": "Tallard",
        "latitude": "44.46200000",
        "longitude": "6.05205000"
    },
    {
        "id": 47165,
        "name": "Tallende",
        "latitude": "45.67040000",
        "longitude": "3.12460000"
    },
    {
        "id": 47166,
        "name": "Talloires",
        "latitude": "45.84098000",
        "longitude": "6.21374000"
    },
    {
        "id": 47167,
        "name": "Talmas",
        "latitude": "50.03049000",
        "longitude": "2.32554000"
    },
    {
        "id": 47168,
        "name": "Taluyers",
        "latitude": "45.63973000",
        "longitude": "4.72203000"
    },
    {
        "id": 47169,
        "name": "Tancarville",
        "latitude": "49.48550000",
        "longitude": "0.45765000"
    },
    {
        "id": 47170,
        "name": "Taninges",
        "latitude": "46.10883000",
        "longitude": "6.59231000"
    },
    {
        "id": 47171,
        "name": "Tanlay",
        "latitude": "47.84488000",
        "longitude": "4.08504000"
    },
    {
        "id": 47172,
        "name": "Tanneron",
        "latitude": "43.59057000",
        "longitude": "6.87541000"
    },
    {
        "id": 47173,
        "name": "Taponnat-Fleurignac",
        "latitude": "45.77868000",
        "longitude": "0.40932000"
    },
    {
        "id": 47174,
        "name": "Taradeau",
        "latitude": "43.45444000",
        "longitude": "6.42729000"
    },
    {
        "id": 47175,
        "name": "Tarare",
        "latitude": "45.89614000",
        "longitude": "4.43300000"
    },
    {
        "id": 47176,
        "name": "Tarascon",
        "latitude": "43.80583000",
        "longitude": "4.66028000"
    },
    {
        "id": 47177,
        "name": "Tarascon-sur-Ariège",
        "latitude": "42.84545000",
        "longitude": "1.60332000"
    },
    {
        "id": 47178,
        "name": "Tarbes",
        "latitude": "43.23333000",
        "longitude": "0.08333000"
    },
    {
        "id": 47179,
        "name": "Targon",
        "latitude": "44.73495000",
        "longitude": "-0.26351000"
    },
    {
        "id": 47180,
        "name": "Tarn",
        "latitude": "43.78170000",
        "longitude": "2.16317000"
    },
    {
        "id": 47181,
        "name": "Tarnos",
        "latitude": "43.54170000",
        "longitude": "-1.46281000"
    },
    {
        "id": 47182,
        "name": "Tartas",
        "latitude": "43.83248000",
        "longitude": "-0.80895000"
    },
    {
        "id": 47183,
        "name": "Tassin-la-Demi-Lune",
        "latitude": "45.75499000",
        "longitude": "4.78812000"
    },
    {
        "id": 47184,
        "name": "Tatinghem",
        "latitude": "50.74317000",
        "longitude": "2.20724000"
    },
    {
        "id": 47186,
        "name": "Taulé",
        "latitude": "48.60000000",
        "longitude": "-3.90000000"
    },
    {
        "id": 47185,
        "name": "Taulignan",
        "latitude": "44.44419000",
        "longitude": "4.97057000"
    },
    {
        "id": 47187,
        "name": "Taupont",
        "latitude": "47.95961000",
        "longitude": "-2.43933000"
    },
    {
        "id": 47188,
        "name": "Tauriac",
        "latitude": "45.04915000",
        "longitude": "-0.50048000"
    },
    {
        "id": 47189,
        "name": "Tauxigny",
        "latitude": "47.21337000",
        "longitude": "0.83479000"
    },
    {
        "id": 47190,
        "name": "Tavaux",
        "latitude": "47.03376000",
        "longitude": "5.40500000"
    },
    {
        "id": 47191,
        "name": "Tavel",
        "latitude": "44.01270000",
        "longitude": "4.69835000"
    },
    {
        "id": 47192,
        "name": "Taverny",
        "latitude": "49.02542000",
        "longitude": "2.21691000"
    },
    {
        "id": 47193,
        "name": "Tavers",
        "latitude": "47.75921000",
        "longitude": "1.61267000"
    },
    {
        "id": 47194,
        "name": "Teillé",
        "latitude": "47.46170000",
        "longitude": "-1.27810000"
    },
    {
        "id": 47195,
        "name": "Telgruc-sur-Mer",
        "latitude": "48.23333000",
        "longitude": "-4.35000000"
    },
    {
        "id": 47196,
        "name": "Teloché",
        "latitude": "47.88819000",
        "longitude": "0.27086000"
    },
    {
        "id": 47197,
        "name": "Templemars",
        "latitude": "50.57387000",
        "longitude": "3.05437000"
    },
    {
        "id": 47198,
        "name": "Templeuve-en-Pévèle",
        "latitude": "50.52336000",
        "longitude": "3.17809000"
    },
    {
        "id": 47199,
        "name": "Tenay",
        "latitude": "45.91884000",
        "longitude": "5.50797000"
    },
    {
        "id": 47200,
        "name": "Tence",
        "latitude": "45.11423000",
        "longitude": "4.29097000"
    },
    {
        "id": 47201,
        "name": "Tencin",
        "latitude": "45.31105000",
        "longitude": "5.95752000"
    },
    {
        "id": 47202,
        "name": "Tende",
        "latitude": "44.08752000",
        "longitude": "7.59366000"
    },
    {
        "id": 47203,
        "name": "Tennie",
        "latitude": "48.10769000",
        "longitude": "-0.07626000"
    },
    {
        "id": 47204,
        "name": "Tenteling",
        "latitude": "49.12544000",
        "longitude": "6.93751000"
    },
    {
        "id": 47206,
        "name": "Tercé",
        "latitude": "46.51667000",
        "longitude": "0.56386000"
    },
    {
        "id": 47205,
        "name": "Tercis-les-Bains",
        "latitude": "43.67048000",
        "longitude": "-1.10738000"
    },
    {
        "id": 47207,
        "name": "Tergnier",
        "latitude": "49.65607000",
        "longitude": "3.30107000"
    },
    {
        "id": 47208,
        "name": "Ternay",
        "latitude": "45.60234000",
        "longitude": "4.81064000"
    },
    {
        "id": 47209,
        "name": "Terrasson-Lavilledieu",
        "latitude": "45.13011000",
        "longitude": "1.30136000"
    },
    {
        "id": 47210,
        "name": "Territoire de Belfort",
        "latitude": "47.63578000",
        "longitude": "6.88843000"
    },
    {
        "id": 47211,
        "name": "Terville",
        "latitude": "49.34668000",
        "longitude": "6.13346000"
    },
    {
        "id": 47213,
        "name": "Tessé-la-Madeleine",
        "latitude": "48.54963000",
        "longitude": "-0.42521000"
    },
    {
        "id": 47212,
        "name": "Tessy-sur-Vire",
        "latitude": "48.97381000",
        "longitude": "-1.06087000"
    },
    {
        "id": 47417,
        "name": "Téteghem",
        "latitude": "51.01859000",
        "longitude": "2.44454000"
    },
    {
        "id": 47214,
        "name": "Teting-sur-Nied",
        "latitude": "49.05704000",
        "longitude": "6.66294000"
    },
    {
        "id": 47215,
        "name": "Teyran",
        "latitude": "43.68528000",
        "longitude": "3.92889000"
    },
    {
        "id": 47216,
        "name": "Thairé",
        "latitude": "46.07341000",
        "longitude": "-1.00230000"
    },
    {
        "id": 47217,
        "name": "Thann",
        "latitude": "47.80789000",
        "longitude": "7.10301000"
    },
    {
        "id": 47218,
        "name": "Thaon",
        "latitude": "49.25796000",
        "longitude": "-0.45605000"
    },
    {
        "id": 47219,
        "name": "Thaon-les-Vosges",
        "latitude": "48.25000000",
        "longitude": "6.41667000"
    },
    {
        "id": 47266,
        "name": "Théding",
        "latitude": "49.12847000",
        "longitude": "6.89220000"
    },
    {
        "id": 47220,
        "name": "Theillay",
        "latitude": "47.31429000",
        "longitude": "2.04028000"
    },
    {
        "id": 47221,
        "name": "Theix",
        "latitude": "47.62916000",
        "longitude": "-2.65186000"
    },
    {
        "id": 47222,
        "name": "Theizé",
        "latitude": "45.93963000",
        "longitude": "4.61634000"
    },
    {
        "id": 47267,
        "name": "Thélus",
        "latitude": "50.35441000",
        "longitude": "2.80146000"
    },
    {
        "id": 47268,
        "name": "Thénac",
        "latitude": "45.66705000",
        "longitude": "-0.65345000"
    },
    {
        "id": 47269,
        "name": "Thénezay",
        "latitude": "46.71879000",
        "longitude": "-0.02883000"
    },
    {
        "id": 47223,
        "name": "Thenon",
        "latitude": "45.13897000",
        "longitude": "1.07211000"
    },
    {
        "id": 47270,
        "name": "Théoule-sur-Mer",
        "latitude": "43.50780000",
        "longitude": "6.94080000"
    },
    {
        "id": 47271,
        "name": "Thérouanne",
        "latitude": "50.63695000",
        "longitude": "2.25838000"
    },
    {
        "id": 47272,
        "name": "Thésée",
        "latitude": "47.32345000",
        "longitude": "1.30882000"
    },
    {
        "id": 47224,
        "name": "Theys",
        "latitude": "45.30132000",
        "longitude": "5.99848000"
    },
    {
        "id": 47273,
        "name": "Théza",
        "latitude": "42.63797000",
        "longitude": "2.95108000"
    },
    {
        "id": 47274,
        "name": "Thézan-lès-Béziers",
        "latitude": "43.41667000",
        "longitude": "3.16667000"
    },
    {
        "id": 47225,
        "name": "Thiais",
        "latitude": "48.76496000",
        "longitude": "2.39610000"
    },
    {
        "id": 47226,
        "name": "Thiant",
        "latitude": "50.30480000",
        "longitude": "3.44796000"
    },
    {
        "id": 47227,
        "name": "Thiberville",
        "latitude": "49.13768000",
        "longitude": "0.45502000"
    },
    {
        "id": 47228,
        "name": "Thiers",
        "latitude": "45.85654000",
        "longitude": "3.54758000"
    },
    {
        "id": 47229,
        "name": "Thiers",
        "latitude": "43.29748000",
        "longitude": "5.38198000"
    },
    {
        "id": 47230,
        "name": "Thiers-sur-Thève",
        "latitude": "49.15252000",
        "longitude": "2.57051000"
    },
    {
        "id": 47231,
        "name": "Thierville-sur-Meuse",
        "latitude": "49.17050000",
        "longitude": "5.35266000"
    },
    {
        "id": 47232,
        "name": "Thil",
        "latitude": "49.47300000",
        "longitude": "5.90821000"
    },
    {
        "id": 47233,
        "name": "Thil",
        "latitude": "45.81368000",
        "longitude": "5.02323000"
    },
    {
        "id": 47234,
        "name": "Thilay",
        "latitude": "49.87345000",
        "longitude": "4.80772000"
    },
    {
        "id": 47235,
        "name": "Thilouze",
        "latitude": "47.22415000",
        "longitude": "0.57963000"
    },
    {
        "id": 47236,
        "name": "Thionville",
        "latitude": "49.35994000",
        "longitude": "6.16044000"
    },
    {
        "id": 47237,
        "name": "Thiron Gardais",
        "latitude": "48.31667000",
        "longitude": "0.98333000"
    },
    {
        "id": 47238,
        "name": "Thise",
        "latitude": "47.28486000",
        "longitude": "6.08127000"
    },
    {
        "id": 47239,
        "name": "Thiverny",
        "latitude": "49.25180000",
        "longitude": "2.43609000"
    },
    {
        "id": 47240,
        "name": "Thiverval-Grignon",
        "latitude": "48.84964000",
        "longitude": "1.91729000"
    },
    {
        "id": 47241,
        "name": "Thiviers",
        "latitude": "45.41542000",
        "longitude": "0.91963000"
    },
    {
        "id": 47242,
        "name": "Thizy-les-Bourgs",
        "latitude": "46.02995000",
        "longitude": "4.31299000"
    },
    {
        "id": 47243,
        "name": "Thoiry",
        "latitude": "46.23762000",
        "longitude": "5.98111000"
    },
    {
        "id": 47244,
        "name": "Thoiry",
        "latitude": "48.86715000",
        "longitude": "1.79760000"
    },
    {
        "id": 47245,
        "name": "Thoissey",
        "latitude": "46.17222000",
        "longitude": "4.80251000"
    },
    {
        "id": 47246,
        "name": "Thomery",
        "latitude": "48.40723000",
        "longitude": "2.78852000"
    },
    {
        "id": 47275,
        "name": "Thônes",
        "latitude": "45.88123000",
        "longitude": "6.32572000"
    },
    {
        "id": 47247,
        "name": "Thonon-les-Bains",
        "latitude": "46.37049000",
        "longitude": "6.47985000"
    },
    {
        "id": 47248,
        "name": "Thorens-Glières",
        "latitude": "45.99510000",
        "longitude": "6.24557000"
    },
    {
        "id": 47251,
        "name": "Thorigné",
        "latitude": "46.29149000",
        "longitude": "-0.25122000"
    },
    {
        "id": 47252,
        "name": "Thorigné-sur-Dué",
        "latitude": "48.03920000",
        "longitude": "0.53554000"
    },
    {
        "id": 47249,
        "name": "Thorigny-sur-Marne",
        "latitude": "48.88689000",
        "longitude": "2.71806000"
    },
    {
        "id": 47250,
        "name": "Thorigny-sur-Oreuse",
        "latitude": "48.29297000",
        "longitude": "3.40128000"
    },
    {
        "id": 47253,
        "name": "Thouarcé",
        "latitude": "47.26734000",
        "longitude": "-0.50186000"
    },
    {
        "id": 47255,
        "name": "Thouaré-sur-Loire",
        "latitude": "47.26893000",
        "longitude": "-1.43834000"
    },
    {
        "id": 47254,
        "name": "Thouars",
        "latitude": "46.97602000",
        "longitude": "-0.21507000"
    },
    {
        "id": 47256,
        "name": "Thourotte",
        "latitude": "49.47591000",
        "longitude": "2.88210000"
    },
    {
        "id": 47257,
        "name": "Thuellin",
        "latitude": "45.63964000",
        "longitude": "5.50840000"
    },
    {
        "id": 47258,
        "name": "Thueyts",
        "latitude": "44.67628000",
        "longitude": "4.22146000"
    },
    {
        "id": 47259,
        "name": "Thuir",
        "latitude": "42.63290000",
        "longitude": "2.75471000"
    },
    {
        "id": 47260,
        "name": "Thumeries",
        "latitude": "50.47662000",
        "longitude": "3.05500000"
    },
    {
        "id": 47261,
        "name": "Thun-Saint-Amand",
        "latitude": "50.47276000",
        "longitude": "3.45115000"
    },
    {
        "id": 47264,
        "name": "Thuré",
        "latitude": "46.83221000",
        "longitude": "0.45797000"
    },
    {
        "id": 47262,
        "name": "Thurins",
        "latitude": "45.68179000",
        "longitude": "4.64079000"
    },
    {
        "id": 47263,
        "name": "Thury-Harcourt",
        "latitude": "48.98434000",
        "longitude": "-0.47519000"
    },
    {
        "id": 47265,
        "name": "Thyez",
        "latitude": "46.08249000",
        "longitude": "6.53777000"
    },
    {
        "id": 47276,
        "name": "Tiercé",
        "latitude": "47.61587000",
        "longitude": "-0.46609000"
    },
    {
        "id": 47277,
        "name": "Tiffauges",
        "latitude": "47.01080000",
        "longitude": "-1.10999000"
    },
    {
        "id": 47278,
        "name": "Tigery",
        "latitude": "48.64257000",
        "longitude": "2.50779000"
    },
    {
        "id": 47279,
        "name": "Tignes",
        "latitude": "45.49604000",
        "longitude": "6.92463000"
    },
    {
        "id": 47280,
        "name": "Tignieu",
        "latitude": "45.75164000",
        "longitude": "5.18911000"
    },
    {
        "id": 47281,
        "name": "Tigy",
        "latitude": "47.79365000",
        "longitude": "2.19767000"
    },
    {
        "id": 47286,
        "name": "Tillé",
        "latitude": "49.46415000",
        "longitude": "2.11038000"
    },
    {
        "id": 47282,
        "name": "Tillières",
        "latitude": "47.14397000",
        "longitude": "-1.16334000"
    },
    {
        "id": 47283,
        "name": "Tillières-sur-Avre",
        "latitude": "48.75585000",
        "longitude": "1.05388000"
    },
    {
        "id": 47284,
        "name": "Tilloy-lès-Mofflaines",
        "latitude": "50.27519000",
        "longitude": "2.81456000"
    },
    {
        "id": 47285,
        "name": "Tilly-sur-Seulles",
        "latitude": "49.17598000",
        "longitude": "-0.62605000"
    },
    {
        "id": 47287,
        "name": "Tilques",
        "latitude": "50.77712000",
        "longitude": "2.19948000"
    },
    {
        "id": 47288,
        "name": "Tinchebray",
        "latitude": "48.76437000",
        "longitude": "-0.73333000"
    },
    {
        "id": 47289,
        "name": "Tinqueux",
        "latitude": "49.25000000",
        "longitude": "3.98333000"
    },
    {
        "id": 47290,
        "name": "Tinténiac",
        "latitude": "48.32860000",
        "longitude": "-1.83630000"
    },
    {
        "id": 47291,
        "name": "Tocane-Saint-Apre",
        "latitude": "45.25404000",
        "longitude": "0.49682000"
    },
    {
        "id": 47292,
        "name": "Tollevast",
        "latitude": "49.57437000",
        "longitude": "-1.62746000"
    },
    {
        "id": 47293,
        "name": "Tomblaine",
        "latitude": "48.68378000",
        "longitude": "6.21620000"
    },
    {
        "id": 47294,
        "name": "Tonnay-Boutonne",
        "latitude": "45.96815000",
        "longitude": "-0.70847000"
    },
    {
        "id": 47295,
        "name": "Tonnay-Charente",
        "latitude": "45.94900000",
        "longitude": "-0.89350000"
    },
    {
        "id": 47296,
        "name": "Tonneins",
        "latitude": "44.39206000",
        "longitude": "0.31241000"
    },
    {
        "id": 47297,
        "name": "Tonnerre",
        "latitude": "47.85628000",
        "longitude": "3.97369000"
    },
    {
        "id": 47298,
        "name": "Tonquédec",
        "latitude": "48.66886000",
        "longitude": "-3.39712000"
    },
    {
        "id": 47301,
        "name": "Torcé",
        "latitude": "48.06120000",
        "longitude": "-1.26708000"
    },
    {
        "id": 47299,
        "name": "Torcy",
        "latitude": "48.85064000",
        "longitude": "2.65078000"
    },
    {
        "id": 47300,
        "name": "Torcy",
        "latitude": "46.76857000",
        "longitude": "4.45333000"
    },
    {
        "id": 47302,
        "name": "Torfou",
        "latitude": "47.03682000",
        "longitude": "-1.11635000"
    },
    {
        "id": 47303,
        "name": "Torigni-sur-Vire",
        "latitude": "49.03702000",
        "longitude": "-0.98214000"
    },
    {
        "id": 47304,
        "name": "Torreilles",
        "latitude": "42.75433000",
        "longitude": "2.99292000"
    },
    {
        "id": 47305,
        "name": "Tosse",
        "latitude": "43.68916000",
        "longitude": "-1.33262000"
    },
    {
        "id": 47306,
        "name": "Tossiat",
        "latitude": "46.13965000",
        "longitude": "5.31158000"
    },
    {
        "id": 47418,
        "name": "Tôtes",
        "latitude": "49.68091000",
        "longitude": "1.04649000"
    },
    {
        "id": 47307,
        "name": "Toucy",
        "latitude": "47.73602000",
        "longitude": "3.29502000"
    },
    {
        "id": 47308,
        "name": "Toufflers",
        "latitude": "50.66039000",
        "longitude": "3.23358000"
    },
    {
        "id": 47309,
        "name": "Toul",
        "latitude": "48.68075000",
        "longitude": "5.89115000"
    },
    {
        "id": 47310,
        "name": "Toulaud",
        "latitude": "44.89773000",
        "longitude": "4.81639000"
    },
    {
        "id": 47311,
        "name": "Toulenne",
        "latitude": "44.55665000",
        "longitude": "-0.26328000"
    },
    {
        "id": 47312,
        "name": "Toulon",
        "latitude": "43.12442000",
        "longitude": "5.92836000"
    },
    {
        "id": 47313,
        "name": "Toulon-sur-Allier",
        "latitude": "46.51845000",
        "longitude": "3.35989000"
    },
    {
        "id": 47314,
        "name": "Toulon-sur-Arroux",
        "latitude": "46.69345000",
        "longitude": "4.13869000"
    },
    {
        "id": 47315,
        "name": "Toulouges",
        "latitude": "42.66961000",
        "longitude": "2.83008000"
    },
    {
        "id": 47316,
        "name": "Toulouse",
        "latitude": "43.60426000",
        "longitude": "1.44367000"
    },
    {
        "id": 47317,
        "name": "Touques",
        "latitude": "49.34443000",
        "longitude": "0.10218000"
    },
    {
        "id": 47318,
        "name": "Touquin",
        "latitude": "48.73498000",
        "longitude": "3.01222000"
    },
    {
        "id": 47319,
        "name": "Tourbes",
        "latitude": "43.44615000",
        "longitude": "3.37852000"
    },
    {
        "id": 47320,
        "name": "Tourcoing",
        "latitude": "50.72391000",
        "longitude": "3.16117000"
    },
    {
        "id": 47321,
        "name": "Tourlaville",
        "latitude": "49.63829000",
        "longitude": "-1.56639000"
    },
    {
        "id": 47322,
        "name": "Tournan-en-Brie",
        "latitude": "48.74146000",
        "longitude": "2.77200000"
    },
    {
        "id": 47323,
        "name": "Tournay",
        "latitude": "43.18438000",
        "longitude": "0.24454000"
    },
    {
        "id": 47324,
        "name": "Tournefeuille",
        "latitude": "43.58872000",
        "longitude": "1.31922000"
    },
    {
        "id": 47325,
        "name": "Tournehem-sur-la-Hem",
        "latitude": "50.80000000",
        "longitude": "2.05000000"
    },
    {
        "id": 47326,
        "name": "Tournes",
        "latitude": "49.79700000",
        "longitude": "4.63856000"
    },
    {
        "id": 47327,
        "name": "Tournon-Saint-Martin",
        "latitude": "46.73423000",
        "longitude": "0.95514000"
    },
    {
        "id": 47328,
        "name": "Tournon-sur-Rhône",
        "latitude": "45.06667000",
        "longitude": "4.83333000"
    },
    {
        "id": 47329,
        "name": "Tournus",
        "latitude": "46.56758000",
        "longitude": "4.90574000"
    },
    {
        "id": 47330,
        "name": "Tourouvre",
        "latitude": "48.58951000",
        "longitude": "0.65253000"
    },
    {
        "id": 47331,
        "name": "Tourrette-Levens",
        "latitude": "43.78640000",
        "longitude": "7.27598000"
    },
    {
        "id": 47332,
        "name": "Tourrettes-sur-Loup",
        "latitude": "43.71569000",
        "longitude": "7.05892000"
    },
    {
        "id": 47333,
        "name": "Tours",
        "latitude": "47.39484000",
        "longitude": "0.70398000"
    },
    {
        "id": 47334,
        "name": "Tours-sur-Marne",
        "latitude": "49.04873000",
        "longitude": "4.12060000"
    },
    {
        "id": 47335,
        "name": "Tourves",
        "latitude": "43.40803000",
        "longitude": "5.92392000"
    },
    {
        "id": 47336,
        "name": "Tourville-la-Rivière",
        "latitude": "49.32782000",
        "longitude": "1.10551000"
    },
    {
        "id": 47337,
        "name": "Tourville-sur-Arques",
        "latitude": "49.85926000",
        "longitude": "1.10238000"
    },
    {
        "id": 47338,
        "name": "Tourville-sur-Odon",
        "latitude": "49.14154000",
        "longitude": "-0.50128000"
    },
    {
        "id": 47339,
        "name": "Toury",
        "latitude": "48.19397000",
        "longitude": "1.93484000"
    },
    {
        "id": 47340,
        "name": "Toussieu",
        "latitude": "45.65443000",
        "longitude": "4.98495000"
    },
    {
        "id": 47341,
        "name": "Toutainville",
        "latitude": "49.36474000",
        "longitude": "0.46538000"
    },
    {
        "id": 47342,
        "name": "Toutlemonde",
        "latitude": "47.05488000",
        "longitude": "-0.76548000"
    },
    {
        "id": 47343,
        "name": "Touvois",
        "latitude": "46.90208000",
        "longitude": "-1.68333000"
    },
    {
        "id": 47344,
        "name": "Touvre",
        "latitude": "45.66667000",
        "longitude": "0.25000000"
    },
    {
        "id": 47345,
        "name": "Tracy-le-Mont",
        "latitude": "49.47225000",
        "longitude": "3.00939000"
    },
    {
        "id": 47350,
        "name": "Traînou",
        "latitude": "47.97353000",
        "longitude": "2.10399000"
    },
    {
        "id": 47346,
        "name": "Tramoyes",
        "latitude": "45.87599000",
        "longitude": "4.96502000"
    },
    {
        "id": 47347,
        "name": "Trangé",
        "latitude": "48.02706000",
        "longitude": "0.11054000"
    },
    {
        "id": 47348,
        "name": "Trans-en-Provence",
        "latitude": "43.50326000",
        "longitude": "6.48641000"
    },
    {
        "id": 47349,
        "name": "Trappes",
        "latitude": "48.77413000",
        "longitude": "2.01781000"
    },
    {
        "id": 47389,
        "name": "Trèbes",
        "latitude": "43.21064000",
        "longitude": "2.44165000"
    },
    {
        "id": 47390,
        "name": "Trébeurden",
        "latitude": "48.76667000",
        "longitude": "-3.56667000"
    },
    {
        "id": 47391,
        "name": "Trédarzec",
        "latitude": "48.78583000",
        "longitude": "-3.20100000"
    },
    {
        "id": 47392,
        "name": "Trédrez-Locquémeau",
        "latitude": "48.70000000",
        "longitude": "-3.56667000"
    },
    {
        "id": 47351,
        "name": "Treffiagat",
        "latitude": "47.81667000",
        "longitude": "-4.26667000"
    },
    {
        "id": 47352,
        "name": "Treffléan",
        "latitude": "47.68163000",
        "longitude": "-2.61287000"
    },
    {
        "id": 47353,
        "name": "Treffort-Cuisiat",
        "latitude": "46.26667000",
        "longitude": "5.36667000"
    },
    {
        "id": 47393,
        "name": "Trégastel",
        "latitude": "48.81667000",
        "longitude": "-3.50000000"
    },
    {
        "id": 47394,
        "name": "Tréguier",
        "latitude": "48.78333000",
        "longitude": "-3.23333000"
    },
    {
        "id": 47395,
        "name": "Trégunc",
        "latitude": "47.85000000",
        "longitude": "-3.85000000"
    },
    {
        "id": 47354,
        "name": "Treignac",
        "latitude": "45.53696000",
        "longitude": "1.79520000"
    },
    {
        "id": 47355,
        "name": "Treillières",
        "latitude": "47.33060000",
        "longitude": "-1.61918000"
    },
    {
        "id": 47356,
        "name": "Treize-Septiers",
        "latitude": "46.98524000",
        "longitude": "-1.22921000"
    },
    {
        "id": 47396,
        "name": "Trélazé",
        "latitude": "47.44565000",
        "longitude": "-0.46540000"
    },
    {
        "id": 47400,
        "name": "Trélévern",
        "latitude": "48.81071000",
        "longitude": "-3.37141000"
    },
    {
        "id": 47397,
        "name": "Trélissac",
        "latitude": "45.19766000",
        "longitude": "0.78615000"
    },
    {
        "id": 47398,
        "name": "Trélivan",
        "latitude": "48.43198000",
        "longitude": "-2.11748000"
    },
    {
        "id": 47399,
        "name": "Trélon",
        "latitude": "50.05805000",
        "longitude": "4.10200000"
    },
    {
        "id": 47357,
        "name": "Tremblay",
        "latitude": "48.42216000",
        "longitude": "-1.47555000"
    },
    {
        "id": 47358,
        "name": "Tremblay-en-France",
        "latitude": "48.94956000",
        "longitude": "2.56840000"
    },
    {
        "id": 47401,
        "name": "Trémentines",
        "latitude": "47.12357000",
        "longitude": "-0.78500000"
    },
    {
        "id": 47402,
        "name": "Trémery",
        "latitude": "49.24610000",
        "longitude": "6.22369000"
    },
    {
        "id": 47404,
        "name": "Tréméven",
        "latitude": "47.90000000",
        "longitude": "-3.53333000"
    },
    {
        "id": 47403,
        "name": "Trémuson",
        "latitude": "48.52325000",
        "longitude": "-2.84833000"
    },
    {
        "id": 47405,
        "name": "Tréon",
        "latitude": "48.67709000",
        "longitude": "1.32668000"
    },
    {
        "id": 47359,
        "name": "Trept",
        "latitude": "45.68742000",
        "longitude": "5.31843000"
    },
    {
        "id": 47360,
        "name": "Tresbœuf",
        "latitude": "47.88333000",
        "longitude": "-1.55000000"
    },
    {
        "id": 47361,
        "name": "Tresques",
        "latitude": "44.10689000",
        "longitude": "4.58739000"
    },
    {
        "id": 47362,
        "name": "Tressange",
        "latitude": "49.40271000",
        "longitude": "5.98084000"
    },
    {
        "id": 47363,
        "name": "Tresserve",
        "latitude": "45.67610000",
        "longitude": "5.89906000"
    },
    {
        "id": 47364,
        "name": "Tresses",
        "latitude": "44.84781000",
        "longitude": "-0.46296000"
    },
    {
        "id": 47365,
        "name": "Tressin",
        "latitude": "50.61750000",
        "longitude": "3.19354000"
    },
    {
        "id": 47366,
        "name": "Trets",
        "latitude": "43.44818000",
        "longitude": "5.68328000"
    },
    {
        "id": 47409,
        "name": "Trévé",
        "latitude": "48.21147000",
        "longitude": "-2.79317000"
    },
    {
        "id": 47406,
        "name": "Trévol",
        "latitude": "46.62924000",
        "longitude": "3.30599000"
    },
    {
        "id": 47407,
        "name": "Trévou-Tréguignec",
        "latitude": "48.81869000",
        "longitude": "-3.34132000"
    },
    {
        "id": 47408,
        "name": "Trévoux",
        "latitude": "45.94281000",
        "longitude": "4.77143000"
    },
    {
        "id": 47367,
        "name": "Triaize",
        "latitude": "46.39265000",
        "longitude": "-1.19785000"
    },
    {
        "id": 47368,
        "name": "Tricot",
        "latitude": "49.56080000",
        "longitude": "2.58789000"
    },
    {
        "id": 47369,
        "name": "Trie-Château",
        "latitude": "49.28540000",
        "longitude": "1.82129000"
    },
    {
        "id": 47370,
        "name": "Trie-sur-Baïse",
        "latitude": "43.33333000",
        "longitude": "0.36667000"
    },
    {
        "id": 47371,
        "name": "Triel-sur-Seine",
        "latitude": "48.97818000",
        "longitude": "2.00743000"
    },
    {
        "id": 47372,
        "name": "Trieux",
        "latitude": "49.32462000",
        "longitude": "5.93049000"
    },
    {
        "id": 47373,
        "name": "Trignac",
        "latitude": "47.31809000",
        "longitude": "-2.18895000"
    },
    {
        "id": 47374,
        "name": "Triguères",
        "latitude": "47.93975000",
        "longitude": "2.98570000"
    },
    {
        "id": 47375,
        "name": "Trilport",
        "latitude": "48.95685000",
        "longitude": "2.95076000"
    },
    {
        "id": 47376,
        "name": "Trith-Saint-Léger",
        "latitude": "50.31667000",
        "longitude": "3.48333000"
    },
    {
        "id": 47377,
        "name": "Trizay",
        "latitude": "45.88276000",
        "longitude": "-0.89697000"
    },
    {
        "id": 47378,
        "name": "Troarn",
        "latitude": "49.17835000",
        "longitude": "-0.18169000"
    },
    {
        "id": 47379,
        "name": "Troissereux",
        "latitude": "49.47998000",
        "longitude": "2.04485000"
    },
    {
        "id": 47380,
        "name": "Tronville-en-Barrois",
        "latitude": "48.71977000",
        "longitude": "5.27808000"
    },
    {
        "id": 47381,
        "name": "Trosly-Breuil",
        "latitude": "49.40000000",
        "longitude": "2.96667000"
    },
    {
        "id": 47382,
        "name": "Trouillas",
        "latitude": "42.61089000",
        "longitude": "2.80821000"
    },
    {
        "id": 47383,
        "name": "Trouville-sur-Mer",
        "latitude": "49.36570000",
        "longitude": "0.08041000"
    },
    {
        "id": 47384,
        "name": "Trouy",
        "latitude": "47.01153000",
        "longitude": "2.36018000"
    },
    {
        "id": 47385,
        "name": "Troyes",
        "latitude": "48.30073000",
        "longitude": "4.08524000"
    },
    {
        "id": 47386,
        "name": "Truchtersheim",
        "latitude": "48.66313000",
        "longitude": "7.60752000"
    },
    {
        "id": 47387,
        "name": "Trun",
        "latitude": "48.84268000",
        "longitude": "0.03268000"
    },
    {
        "id": 47388,
        "name": "Truyes",
        "latitude": "47.27299000",
        "longitude": "0.85179000"
    },
    {
        "id": 47410,
        "name": "Tucquegnieux",
        "latitude": "49.31010000",
        "longitude": "5.89448000"
    },
    {
        "id": 47411,
        "name": "Tuffé",
        "latitude": "48.11319000",
        "longitude": "0.51551000"
    },
    {
        "id": 47412,
        "name": "Tulette",
        "latitude": "44.28656000",
        "longitude": "4.93122000"
    },
    {
        "id": 47413,
        "name": "Tulle",
        "latitude": "45.26582000",
        "longitude": "1.77233000"
    },
    {
        "id": 47414,
        "name": "Tullins",
        "latitude": "45.30239000",
        "longitude": "5.49077000"
    },
    {
        "id": 47415,
        "name": "Turckheim",
        "latitude": "48.08748000",
        "longitude": "7.27707000"
    },
    {
        "id": 47416,
        "name": "Turretot",
        "latitude": "49.60923000",
        "longitude": "0.23422000"
    },
    {
        "id": 47419,
        "name": "Uberach",
        "latitude": "48.84990000",
        "longitude": "7.62934000"
    },
    {
        "id": 47420,
        "name": "Uchaud",
        "latitude": "43.75857000",
        "longitude": "4.26843000"
    },
    {
        "id": 47421,
        "name": "Uchaux",
        "latitude": "44.21667000",
        "longitude": "4.80000000"
    },
    {
        "id": 47422,
        "name": "Uckange",
        "latitude": "49.30304000",
        "longitude": "6.14920000"
    },
    {
        "id": 47423,
        "name": "Uffholtz",
        "latitude": "47.82082000",
        "longitude": "7.17785000"
    },
    {
        "id": 47424,
        "name": "Ugine",
        "latitude": "45.75571000",
        "longitude": "6.41503000"
    },
    {
        "id": 47425,
        "name": "Ully-Saint-Georges",
        "latitude": "49.27914000",
        "longitude": "2.28094000"
    },
    {
        "id": 47426,
        "name": "Ungersheim",
        "latitude": "47.87848000",
        "longitude": "7.30797000"
    },
    {
        "id": 47427,
        "name": "Unieux",
        "latitude": "45.40395000",
        "longitude": "4.27094000"
    },
    {
        "id": 47428,
        "name": "Unverre",
        "latitude": "48.19829000",
        "longitude": "1.09207000"
    },
    {
        "id": 47429,
        "name": "Upie",
        "latitude": "44.80250000",
        "longitude": "4.97673000"
    },
    {
        "id": 47430,
        "name": "Urcuit",
        "latitude": "43.48594000",
        "longitude": "-1.33668000"
    },
    {
        "id": 47431,
        "name": "Uriménil",
        "latitude": "48.10079000",
        "longitude": "6.40046000"
    },
    {
        "id": 47432,
        "name": "Urmatt",
        "latitude": "48.52752000",
        "longitude": "7.32565000"
    },
    {
        "id": 47433,
        "name": "Urrugne",
        "latitude": "43.36361000",
        "longitude": "-1.69921000"
    },
    {
        "id": 47434,
        "name": "Urt",
        "latitude": "43.49009000",
        "longitude": "-1.29744000"
    },
    {
        "id": 47435,
        "name": "Urville-Nacqueville",
        "latitude": "49.67444000",
        "longitude": "-1.73664000"
    },
    {
        "id": 47436,
        "name": "Urzy",
        "latitude": "47.04877000",
        "longitude": "3.20295000"
    },
    {
        "id": 47437,
        "name": "Us",
        "latitude": "49.10000000",
        "longitude": "1.96667000"
    },
    {
        "id": 47438,
        "name": "Ussac",
        "latitude": "45.19389000",
        "longitude": "1.51337000"
    },
    {
        "id": 47439,
        "name": "Ussel",
        "latitude": "45.54804000",
        "longitude": "2.30917000"
    },
    {
        "id": 47440,
        "name": "Usson-du-Poitou",
        "latitude": "46.27782000",
        "longitude": "0.52816000"
    },
    {
        "id": 47441,
        "name": "Usson-en-Forez",
        "latitude": "45.39174000",
        "longitude": "3.94142000"
    },
    {
        "id": 47442,
        "name": "Ustaritz",
        "latitude": "43.39650000",
        "longitude": "-1.45603000"
    },
    {
        "id": 47443,
        "name": "Uxegney",
        "latitude": "48.19652000",
        "longitude": "6.36971000"
    },
    {
        "id": 47444,
        "name": "Uxem",
        "latitude": "51.02170000",
        "longitude": "2.48376000"
    },
    {
        "id": 47445,
        "name": "Uzein",
        "latitude": "43.40000000",
        "longitude": "-0.43333000"
    },
    {
        "id": 47446,
        "name": "Uzemain",
        "latitude": "48.08572000",
        "longitude": "6.34443000"
    },
    {
        "id": 47447,
        "name": "Uzerche",
        "latitude": "45.42462000",
        "longitude": "1.56341000"
    },
    {
        "id": 47448,
        "name": "Uzès",
        "latitude": "44.01362000",
        "longitude": "4.41529000"
    },
    {
        "id": 47449,
        "name": "Vaas",
        "latitude": "47.66890000",
        "longitude": "0.31677000"
    },
    {
        "id": 47450,
        "name": "Vacon",
        "latitude": "48.66865000",
        "longitude": "5.60024000"
    },
    {
        "id": 47451,
        "name": "Vacqueyras",
        "latitude": "44.13835000",
        "longitude": "4.98332000"
    },
    {
        "id": 47452,
        "name": "Vacquiers",
        "latitude": "43.77667000",
        "longitude": "1.48127000"
    },
    {
        "id": 47453,
        "name": "Vagney",
        "latitude": "48.00629000",
        "longitude": "6.71740000"
    },
    {
        "id": 47454,
        "name": "Vaiges",
        "latitude": "48.04025000",
        "longitude": "-0.47513000"
    },
    {
        "id": 47455,
        "name": "Vailhauquès",
        "latitude": "43.67159000",
        "longitude": "3.72042000"
    },
    {
        "id": 47456,
        "name": "Vailly-sur-Aisne",
        "latitude": "49.40834000",
        "longitude": "3.51631000"
    },
    {
        "id": 47458,
        "name": "Vairé",
        "latitude": "46.60104000",
        "longitude": "-1.75538000"
    },
    {
        "id": 47457,
        "name": "Vaires-sur-Marne",
        "latitude": "48.87649000",
        "longitude": "2.63982000"
    },
    {
        "id": 47459,
        "name": "Vaison-la-Romaine",
        "latitude": "44.23896000",
        "longitude": "5.07461000"
    },
    {
        "id": 47460,
        "name": "Vaivre-et-Montoille",
        "latitude": "47.63021000",
        "longitude": "6.10362000"
    },
    {
        "id": 47461,
        "name": "Val Thorens",
        "latitude": "45.29777000",
        "longitude": "6.58377000"
    },
    {
        "id": 47465,
        "name": "Val-d’Isère",
        "latitude": "45.45142000",
        "longitude": "6.97455000"
    },
    {
        "id": 47462,
        "name": "Val-de-Marne",
        "latitude": "48.78149000",
        "longitude": "2.49331000"
    },
    {
        "id": 47463,
        "name": "Val-de-Meuse",
        "latitude": "48.00000000",
        "longitude": "5.50000000"
    },
    {
        "id": 47464,
        "name": "Val-de-Reuil",
        "latitude": "49.27385000",
        "longitude": "1.21021000"
    },
    {
        "id": 47466,
        "name": "Valady",
        "latitude": "44.45633000",
        "longitude": "2.42746000"
    },
    {
        "id": 47467,
        "name": "Valbonne",
        "latitude": "43.63292000",
        "longitude": "6.99911000"
    },
    {
        "id": 47468,
        "name": "Valdahon",
        "latitude": "47.15000000",
        "longitude": "6.35000000"
    },
    {
        "id": 47469,
        "name": "Valdoie",
        "latitude": "47.67041000",
        "longitude": "6.84203000"
    },
    {
        "id": 47479,
        "name": "Valençay",
        "latitude": "47.16207000",
        "longitude": "1.56852000"
    },
    {
        "id": 47470,
        "name": "Valence",
        "latitude": "44.10823000",
        "longitude": "0.89101000"
    },
    {
        "id": 47471,
        "name": "Valence",
        "latitude": "44.92560000",
        "longitude": "4.90956000"
    },
    {
        "id": 47472,
        "name": "Valence-d’Albigeois",
        "latitude": "44.01928000",
        "longitude": "2.40534000"
    },
    {
        "id": 47473,
        "name": "Valence-sur-Baïse",
        "latitude": "43.88270000",
        "longitude": "0.38111000"
    },
    {
        "id": 47474,
        "name": "Valenciennes",
        "latitude": "50.35909000",
        "longitude": "3.52506000"
    },
    {
        "id": 47475,
        "name": "Valencin",
        "latitude": "45.61109000",
        "longitude": "5.02935000"
    },
    {
        "id": 47476,
        "name": "Valensole",
        "latitude": "43.83766000",
        "longitude": "5.98392000"
    },
    {
        "id": 47477,
        "name": "Valentigney",
        "latitude": "47.46388000",
        "longitude": "6.83168000"
    },
    {
        "id": 47478,
        "name": "Valenton",
        "latitude": "48.74527000",
        "longitude": "2.46467000"
    },
    {
        "id": 47480,
        "name": "Valergues",
        "latitude": "43.66824000",
        "longitude": "4.06124000"
    },
    {
        "id": 47481,
        "name": "Valff",
        "latitude": "48.42140000",
        "longitude": "7.52058000"
    },
    {
        "id": 47482,
        "name": "Valfin-lès-Saint-Claude",
        "latitude": "46.43758000",
        "longitude": "5.85513000"
    },
    {
        "id": 47483,
        "name": "Valframbert",
        "latitude": "48.46465000",
        "longitude": "0.10828000"
    },
    {
        "id": 47484,
        "name": "Vallabrègues",
        "latitude": "43.85307000",
        "longitude": "4.62662000"
    },
    {
        "id": 47485,
        "name": "Vallauris",
        "latitude": "43.57803000",
        "longitude": "7.05451000"
    },
    {
        "id": 47486,
        "name": "Valleiry",
        "latitude": "46.11106000",
        "longitude": "5.97037000"
    },
    {
        "id": 47487,
        "name": "Valleraugue",
        "latitude": "44.08130000",
        "longitude": "3.64154000"
    },
    {
        "id": 47488,
        "name": "Valleroy",
        "latitude": "49.20944000",
        "longitude": "5.93703000"
    },
    {
        "id": 47489,
        "name": "Vallet",
        "latitude": "47.16227000",
        "longitude": "-1.26607000"
    },
    {
        "id": 47491,
        "name": "Vallières",
        "latitude": "45.90043000",
        "longitude": "5.93863000"
    },
    {
        "id": 47490,
        "name": "Valliquerville",
        "latitude": "49.61385000",
        "longitude": "0.68703000"
    },
    {
        "id": 47492,
        "name": "Valloire",
        "latitude": "45.16542000",
        "longitude": "6.42998000"
    },
    {
        "id": 47494,
        "name": "Vallon-en-Sully",
        "latitude": "46.53629000",
        "longitude": "2.60804000"
    },
    {
        "id": 47493,
        "name": "Vallon-Pont-d’Arc",
        "latitude": "44.40685000",
        "longitude": "4.39374000"
    },
    {
        "id": 47495,
        "name": "Valmondois",
        "latitude": "49.09730000",
        "longitude": "2.18996000"
    },
    {
        "id": 47496,
        "name": "Valmont",
        "latitude": "49.08430000",
        "longitude": "6.69781000"
    },
    {
        "id": 47497,
        "name": "Valognes",
        "latitude": "49.50881000",
        "longitude": "-1.47047000"
    },
    {
        "id": 47498,
        "name": "Valras-Plage",
        "latitude": "43.24808000",
        "longitude": "3.29032000"
    },
    {
        "id": 47500,
        "name": "Valréas",
        "latitude": "44.38490000",
        "longitude": "4.99125000"
    },
    {
        "id": 47499,
        "name": "Valros",
        "latitude": "43.41956000",
        "longitude": "3.36506000"
    },
    {
        "id": 47501,
        "name": "Vals-les-Bains",
        "latitude": "44.66561000",
        "longitude": "4.36615000"
    },
    {
        "id": 47502,
        "name": "Vals-près-le-Puy",
        "latitude": "45.03155000",
        "longitude": "3.87787000"
    },
    {
        "id": 47503,
        "name": "Vandœuvre-lès-Nancy",
        "latitude": "48.65000000",
        "longitude": "6.18333000"
    },
    {
        "id": 47504,
        "name": "Vannes",
        "latitude": "47.65790000",
        "longitude": "-2.75574000"
    },
    {
        "id": 47505,
        "name": "Vanves",
        "latitude": "48.82345000",
        "longitude": "2.29025000"
    },
    {
        "id": 47506,
        "name": "Var",
        "latitude": "43.45860000",
        "longitude": "6.29145000"
    },
    {
        "id": 47507,
        "name": "Varades",
        "latitude": "47.38458000",
        "longitude": "-1.02842000"
    },
    {
        "id": 47508,
        "name": "Varangéville",
        "latitude": "48.63675000",
        "longitude": "6.31875000"
    },
    {
        "id": 47509,
        "name": "Varengeville-sur-Mer",
        "latitude": "49.90475000",
        "longitude": "0.99479000"
    },
    {
        "id": 47510,
        "name": "Varennes-Jarcy",
        "latitude": "48.67914000",
        "longitude": "2.56152000"
    },
    {
        "id": 47513,
        "name": "Varennes-le-Grand",
        "latitude": "46.71868000",
        "longitude": "4.86872000"
    },
    {
        "id": 47511,
        "name": "Varennes-Saint-Sauveur",
        "latitude": "46.48226000",
        "longitude": "5.24349000"
    },
    {
        "id": 47514,
        "name": "Varennes-sur-Allier",
        "latitude": "46.31318000",
        "longitude": "3.40147000"
    },
    {
        "id": 47515,
        "name": "Varennes-sur-Loire",
        "latitude": "47.23767000",
        "longitude": "0.05350000"
    },
    {
        "id": 47516,
        "name": "Varennes-sur-Seine",
        "latitude": "48.37304000",
        "longitude": "2.92571000"
    },
    {
        "id": 47512,
        "name": "Varennes-Vauzelles",
        "latitude": "47.01678000",
        "longitude": "3.14037000"
    },
    {
        "id": 47517,
        "name": "Varetz",
        "latitude": "45.19392000",
        "longitude": "1.45063000"
    },
    {
        "id": 47518,
        "name": "Varilhes",
        "latitude": "43.04514000",
        "longitude": "1.62805000"
    },
    {
        "id": 47519,
        "name": "Varois-et-Chaignot",
        "latitude": "47.35059000",
        "longitude": "5.12838000"
    },
    {
        "id": 47520,
        "name": "Varrains",
        "latitude": "47.22305000",
        "longitude": "-0.06033000"
    },
    {
        "id": 47521,
        "name": "Varreddes",
        "latitude": "49.00305000",
        "longitude": "2.92788000"
    },
    {
        "id": 47522,
        "name": "Vars",
        "latitude": "45.76256000",
        "longitude": "0.12478000"
    },
    {
        "id": 47523,
        "name": "Varzy",
        "latitude": "47.35810000",
        "longitude": "3.38619000"
    },
    {
        "id": 47524,
        "name": "Vasles",
        "latitude": "46.57618000",
        "longitude": "-0.02638000"
    },
    {
        "id": 47525,
        "name": "Vasselay",
        "latitude": "47.15686000",
        "longitude": "2.38963000"
    },
    {
        "id": 47526,
        "name": "Vassy",
        "latitude": "48.85381000",
        "longitude": "-0.67485000"
    },
    {
        "id": 47527,
        "name": "Vatan",
        "latitude": "47.07447000",
        "longitude": "1.81010000"
    },
    {
        "id": 47528,
        "name": "Vauchrétien",
        "latitude": "47.33234000",
        "longitude": "-0.47678000"
    },
    {
        "id": 47529,
        "name": "Vaucouleurs",
        "latitude": "48.60313000",
        "longitude": "5.66659000"
    },
    {
        "id": 47530,
        "name": "Vaucresson",
        "latitude": "48.84078000",
        "longitude": "2.15652000"
    },
    {
        "id": 47531,
        "name": "Vaudelnay",
        "latitude": "47.13813000",
        "longitude": "-0.20677000"
    },
    {
        "id": 47532,
        "name": "Vaudry",
        "latitude": "48.84130000",
        "longitude": "-0.85309000"
    },
    {
        "id": 47533,
        "name": "Vaugneray",
        "latitude": "45.73791000",
        "longitude": "4.65645000"
    },
    {
        "id": 47534,
        "name": "Vaugrigneuse",
        "latitude": "48.60263000",
        "longitude": "2.12218000"
    },
    {
        "id": 47535,
        "name": "Vauhallan",
        "latitude": "48.73354000",
        "longitude": "2.20277000"
    },
    {
        "id": 47536,
        "name": "Vaujours",
        "latitude": "48.93022000",
        "longitude": "2.57110000"
    },
    {
        "id": 47537,
        "name": "Vaulnaveys-le-Bas",
        "latitude": "45.10020000",
        "longitude": "5.82532000"
    },
    {
        "id": 47538,
        "name": "Vaulnaveys-le-Haut",
        "latitude": "45.12524000",
        "longitude": "5.81723000"
    },
    {
        "id": 47541,
        "name": "Vaulx-en-Velin",
        "latitude": "45.78693000",
        "longitude": "4.92510000"
    },
    {
        "id": 47539,
        "name": "Vaulx-Milieu",
        "latitude": "45.61329000",
        "longitude": "5.18371000"
    },
    {
        "id": 47540,
        "name": "Vaulx-Vraucourt",
        "latitude": "50.14910000",
        "longitude": "2.90830000"
    },
    {
        "id": 47542,
        "name": "Vaumoise",
        "latitude": "49.23525000",
        "longitude": "2.98077000"
    },
    {
        "id": 47543,
        "name": "Vauréal",
        "latitude": "49.03333000",
        "longitude": "2.03333000"
    },
    {
        "id": 47544,
        "name": "Vauvert",
        "latitude": "43.69529000",
        "longitude": "4.27705000"
    },
    {
        "id": 47545,
        "name": "Vaux-en-Bugey",
        "latitude": "45.92671000",
        "longitude": "5.35141000"
    },
    {
        "id": 47546,
        "name": "Vaux-le-Pénil",
        "latitude": "48.52803000",
        "longitude": "2.69165000"
    },
    {
        "id": 47547,
        "name": "Vaux-sur-Mer",
        "latitude": "45.64606000",
        "longitude": "-1.05841000"
    },
    {
        "id": 47548,
        "name": "Vaux-sur-Seine",
        "latitude": "49.01271000",
        "longitude": "1.96942000"
    },
    {
        "id": 47549,
        "name": "Vay",
        "latitude": "47.55466000",
        "longitude": "-1.70095000"
    },
    {
        "id": 47550,
        "name": "Vayrac",
        "latitude": "44.95337000",
        "longitude": "1.70358000"
    },
    {
        "id": 47551,
        "name": "Vayres",
        "latitude": "44.90000000",
        "longitude": "-0.31667000"
    },
    {
        "id": 47552,
        "name": "Veauche",
        "latitude": "45.56326000",
        "longitude": "4.29192000"
    },
    {
        "id": 47553,
        "name": "Vecoux",
        "latitude": "47.97797000",
        "longitude": "6.63651000"
    },
    {
        "id": 47554,
        "name": "Vedène",
        "latitude": "43.97744000",
        "longitude": "4.90428000"
    },
    {
        "id": 47555,
        "name": "Veigné",
        "latitude": "47.28556000",
        "longitude": "0.74079000"
    },
    {
        "id": 47556,
        "name": "Veigy",
        "latitude": "46.26787000",
        "longitude": "6.26304000"
    },
    {
        "id": 47557,
        "name": "Velaine-en-Haye",
        "latitude": "48.69990000",
        "longitude": "6.02754000"
    },
    {
        "id": 47558,
        "name": "Velaines",
        "latitude": "48.69880000",
        "longitude": "5.30483000"
    },
    {
        "id": 47559,
        "name": "Velars-sur-Ouche",
        "latitude": "47.31960000",
        "longitude": "4.90594000"
    },
    {
        "id": 47560,
        "name": "Velaux",
        "latitude": "43.52839000",
        "longitude": "5.25661000"
    },
    {
        "id": 47923,
        "name": "Vélines",
        "latitude": "44.85000000",
        "longitude": "0.11667000"
    },
    {
        "id": 47924,
        "name": "Vélizy-Villacoublay",
        "latitude": "48.78198000",
        "longitude": "2.19395000"
    },
    {
        "id": 47561,
        "name": "Velleron",
        "latitude": "43.95742000",
        "longitude": "5.02936000"
    },
    {
        "id": 47925,
        "name": "Vémars",
        "latitude": "49.06942000",
        "longitude": "2.56643000"
    },
    {
        "id": 47562,
        "name": "Venansault",
        "latitude": "46.68516000",
        "longitude": "-1.51415000"
    },
    {
        "id": 47563,
        "name": "Venarey-les-Laumes",
        "latitude": "47.54202000",
        "longitude": "4.46022000"
    },
    {
        "id": 47564,
        "name": "Venasque",
        "latitude": "43.99595000",
        "longitude": "5.14666000"
    },
    {
        "id": 47565,
        "name": "Vence",
        "latitude": "43.72254000",
        "longitude": "7.11183000"
    },
    {
        "id": 47566,
        "name": "Vendargues",
        "latitude": "43.65833000",
        "longitude": "3.97000000"
    },
    {
        "id": 47567,
        "name": "Vendat",
        "latitude": "46.16387000",
        "longitude": "3.35366000"
    },
    {
        "id": 47568,
        "name": "Vendays-Montalivet",
        "latitude": "45.35492000",
        "longitude": "-1.06088000"
    },
    {
        "id": 47569,
        "name": "Vendegies-sur-Écaillon",
        "latitude": "50.26224000",
        "longitude": "3.51256000"
    },
    {
        "id": 47570,
        "name": "Vendenheim",
        "latitude": "48.66948000",
        "longitude": "7.70983000"
    },
    {
        "id": 47571,
        "name": "Vendeuvre-du-Poitou",
        "latitude": "46.73579000",
        "longitude": "0.30996000"
    },
    {
        "id": 47572,
        "name": "Vendeuvre-sur-Barse",
        "latitude": "48.23786000",
        "longitude": "4.46905000"
    },
    {
        "id": 47573,
        "name": "Vendeville",
        "latitude": "50.57693000",
        "longitude": "3.07870000"
    },
    {
        "id": 47574,
        "name": "Vendin-le-Vieil",
        "latitude": "50.47385000",
        "longitude": "2.86674000"
    },
    {
        "id": 47575,
        "name": "Vendin-lès-Béthune",
        "latitude": "50.54012000",
        "longitude": "2.60043000"
    },
    {
        "id": 47579,
        "name": "Vendœuvres",
        "latitude": "46.80000000",
        "longitude": "1.35000000"
    },
    {
        "id": 47578,
        "name": "Vendôme",
        "latitude": "47.79292000",
        "longitude": "1.06556000"
    },
    {
        "id": 47576,
        "name": "Vendrennes",
        "latitude": "46.82523000",
        "longitude": "-1.12357000"
    },
    {
        "id": 47577,
        "name": "Vendres",
        "latitude": "43.26977000",
        "longitude": "3.22341000"
    },
    {
        "id": 47927,
        "name": "Vénéjan",
        "latitude": "44.19729000",
        "longitude": "4.65422000"
    },
    {
        "id": 47580,
        "name": "Venelles",
        "latitude": "43.59859000",
        "longitude": "5.47977000"
    },
    {
        "id": 47581,
        "name": "Venerque",
        "latitude": "43.43457000",
        "longitude": "1.44588000"
    },
    {
        "id": 47582,
        "name": "Venette",
        "latitude": "49.41705000",
        "longitude": "2.80317000"
    },
    {
        "id": 47583,
        "name": "Veneux-les-Sablons",
        "latitude": "48.37872000",
        "longitude": "2.79499000"
    },
    {
        "id": 47926,
        "name": "Vénissieux",
        "latitude": "45.69706000",
        "longitude": "4.88593000"
    },
    {
        "id": 47584,
        "name": "Venizel",
        "latitude": "49.36583000",
        "longitude": "3.39321000"
    },
    {
        "id": 47585,
        "name": "Vennecy",
        "latitude": "47.95465000",
        "longitude": "2.05459000"
    },
    {
        "id": 47586,
        "name": "Venoy",
        "latitude": "47.80518000",
        "longitude": "3.63695000"
    },
    {
        "id": 47587,
        "name": "Ventabren",
        "latitude": "43.53847000",
        "longitude": "5.29541000"
    },
    {
        "id": 47588,
        "name": "Ventiseri",
        "latitude": "41.94356000",
        "longitude": "9.33342000"
    },
    {
        "id": 47589,
        "name": "Ver-sur-Launette",
        "latitude": "49.10495000",
        "longitude": "2.68409000"
    },
    {
        "id": 47590,
        "name": "Ver-sur-Mer",
        "latitude": "49.32987000",
        "longitude": "-0.53118000"
    },
    {
        "id": 47591,
        "name": "Verberie",
        "latitude": "49.31115000",
        "longitude": "2.73210000"
    },
    {
        "id": 47592,
        "name": "Vercel-Villedieu-le-Camp",
        "latitude": "47.18318000",
        "longitude": "6.40082000"
    },
    {
        "id": 47593,
        "name": "Verdun",
        "latitude": "49.15964000",
        "longitude": "5.38290000"
    },
    {
        "id": 47594,
        "name": "Verdun-sur-Garonne",
        "latitude": "43.85446000",
        "longitude": "1.23425000"
    },
    {
        "id": 47595,
        "name": "Verdun-sur-le-Doubs",
        "latitude": "46.89692000",
        "longitude": "5.02127000"
    },
    {
        "id": 47596,
        "name": "Verduron",
        "latitude": "43.37063000",
        "longitude": "5.34513000"
    },
    {
        "id": 47928,
        "name": "Véretz",
        "latitude": "47.35706000",
        "longitude": "0.80575000"
    },
    {
        "id": 47597,
        "name": "Verfeil",
        "latitude": "43.65781000",
        "longitude": "1.66340000"
    },
    {
        "id": 47601,
        "name": "Vergèze",
        "latitude": "43.74404000",
        "longitude": "4.22109000"
    },
    {
        "id": 47598,
        "name": "Vergigny",
        "latitude": "47.97059000",
        "longitude": "3.71861000"
    },
    {
        "id": 47599,
        "name": "Vergongheon",
        "latitude": "45.37107000",
        "longitude": "3.31981000"
    },
    {
        "id": 47600,
        "name": "Vergt",
        "latitude": "45.02695000",
        "longitude": "0.71820000"
    },
    {
        "id": 47929,
        "name": "Vérines",
        "latitude": "46.19372000",
        "longitude": "-0.96683000"
    },
    {
        "id": 47602,
        "name": "Verlinghem",
        "latitude": "50.68291000",
        "longitude": "2.99907000"
    },
    {
        "id": 47603,
        "name": "Vermand",
        "latitude": "49.87550000",
        "longitude": "3.14959000"
    },
    {
        "id": 47604,
        "name": "Vermelles",
        "latitude": "50.48949000",
        "longitude": "2.74739000"
    },
    {
        "id": 47605,
        "name": "Vermenton",
        "latitude": "47.66459000",
        "longitude": "3.73501000"
    },
    {
        "id": 47606,
        "name": "Vern-d’Anjou",
        "latitude": "47.60119000",
        "longitude": "-0.83357000"
    },
    {
        "id": 47607,
        "name": "Vern-sur-Seiche",
        "latitude": "48.04514000",
        "longitude": "-1.60057000"
    },
    {
        "id": 47608,
        "name": "Vernaison",
        "latitude": "45.64781000",
        "longitude": "4.81140000"
    },
    {
        "id": 47609,
        "name": "Vernantes",
        "latitude": "47.39320000",
        "longitude": "0.05304000"
    },
    {
        "id": 47627,
        "name": "Vernègues",
        "latitude": "43.68575000",
        "longitude": "5.17156000"
    },
    {
        "id": 47610,
        "name": "Vernet",
        "latitude": "43.43333000",
        "longitude": "1.41667000"
    },
    {
        "id": 47611,
        "name": "Vernet-les-Bains",
        "latitude": "42.54834000",
        "longitude": "2.38717000"
    },
    {
        "id": 47612,
        "name": "Verneuil-en-Halatte",
        "latitude": "49.27914000",
        "longitude": "2.52410000"
    },
    {
        "id": 47613,
        "name": "Verneuil-sur-Avre",
        "latitude": "48.73949000",
        "longitude": "0.92731000"
    },
    {
        "id": 47614,
        "name": "Verneuil-sur-Seine",
        "latitude": "48.97388000",
        "longitude": "1.96480000"
    },
    {
        "id": 47615,
        "name": "Verneuil-sur-Vienne",
        "latitude": "45.85524000",
        "longitude": "1.10133000"
    },
    {
        "id": 47616,
        "name": "Verniolle",
        "latitude": "43.08162000",
        "longitude": "1.64904000"
    },
    {
        "id": 47617,
        "name": "Vernioz",
        "latitude": "45.42672000",
        "longitude": "4.88267000"
    },
    {
        "id": 47618,
        "name": "Vernoil-le-Fourrier",
        "latitude": "47.38333000",
        "longitude": "0.08333000"
    },
    {
        "id": 47619,
        "name": "Vernon",
        "latitude": "49.09292000",
        "longitude": "1.46332000"
    },
    {
        "id": 47620,
        "name": "Vernosc-lès-Annonay",
        "latitude": "45.21596000",
        "longitude": "4.71310000"
    },
    {
        "id": 47621,
        "name": "Vernou-la-Celle-sur-Seine",
        "latitude": "48.38789000",
        "longitude": "2.84718000"
    },
    {
        "id": 47622,
        "name": "Vernou-sur-Brenne",
        "latitude": "47.41936000",
        "longitude": "0.84757000"
    },
    {
        "id": 47623,
        "name": "Vernouillet",
        "latitude": "48.97146000",
        "longitude": "1.98082000"
    },
    {
        "id": 47624,
        "name": "Vernouillet",
        "latitude": "48.72090000",
        "longitude": "1.36951000"
    },
    {
        "id": 47625,
        "name": "Vernoux-en-Vivarais",
        "latitude": "44.89577000",
        "longitude": "4.64524000"
    },
    {
        "id": 47626,
        "name": "Verny",
        "latitude": "49.00677000",
        "longitude": "6.20350000"
    },
    {
        "id": 47930,
        "name": "Véron",
        "latitude": "48.12853000",
        "longitude": "3.30773000"
    },
    {
        "id": 47628,
        "name": "Verquin",
        "latitude": "50.50240000",
        "longitude": "2.63888000"
    },
    {
        "id": 47629,
        "name": "Verrières",
        "latitude": "48.23334000",
        "longitude": "4.14893000"
    },
    {
        "id": 47630,
        "name": "Verrières-le-Buisson",
        "latitude": "48.74565000",
        "longitude": "2.26796000"
    },
    {
        "id": 47631,
        "name": "Vers-Pont-du-Gard",
        "latitude": "43.96667000",
        "longitude": "4.53333000"
    },
    {
        "id": 47632,
        "name": "Versailles",
        "latitude": "48.80359000",
        "longitude": "2.13424000"
    },
    {
        "id": 47633,
        "name": "Verson",
        "latitude": "49.15432000",
        "longitude": "-0.45628000"
    },
    {
        "id": 47634,
        "name": "Versonnex",
        "latitude": "45.92914000",
        "longitude": "5.92586000"
    },
    {
        "id": 47636,
        "name": "Vert-en-Drouais",
        "latitude": "48.76029000",
        "longitude": "1.29460000"
    },
    {
        "id": 47637,
        "name": "Vert-le-Grand",
        "latitude": "48.57172000",
        "longitude": "2.35777000"
    },
    {
        "id": 47638,
        "name": "Vert-le-Petit",
        "latitude": "48.55163000",
        "longitude": "2.36526000"
    },
    {
        "id": 47635,
        "name": "Vert-Saint-Denis",
        "latitude": "48.56818000",
        "longitude": "2.62007000"
    },
    {
        "id": 47639,
        "name": "Vertaizon",
        "latitude": "45.76983000",
        "longitude": "3.28650000"
    },
    {
        "id": 47640,
        "name": "Vertheuil",
        "latitude": "45.25000000",
        "longitude": "-0.83333000"
    },
    {
        "id": 47641,
        "name": "Verton",
        "latitude": "50.40234000",
        "longitude": "1.64766000"
    },
    {
        "id": 47642,
        "name": "Vertou",
        "latitude": "47.16869000",
        "longitude": "-1.46929000"
    },
    {
        "id": 47643,
        "name": "Vertus",
        "latitude": "48.90609000",
        "longitude": "4.00216000"
    },
    {
        "id": 47644,
        "name": "Vervins",
        "latitude": "49.83510000",
        "longitude": "3.90925000"
    },
    {
        "id": 47645,
        "name": "Verzenay",
        "latitude": "49.15918000",
        "longitude": "4.14543000"
    },
    {
        "id": 47646,
        "name": "Verzy",
        "latitude": "49.14576000",
        "longitude": "4.16409000"
    },
    {
        "id": 47647,
        "name": "Vescovato",
        "latitude": "42.49293000",
        "longitude": "9.43934000"
    },
    {
        "id": 47648,
        "name": "Vesoul",
        "latitude": "47.62604000",
        "longitude": "6.14251000"
    },
    {
        "id": 47649,
        "name": "Vesseaux",
        "latitude": "44.65152000",
        "longitude": "4.44025000"
    },
    {
        "id": 47650,
        "name": "Vestric-et-Candiac",
        "latitude": "43.74061000",
        "longitude": "4.25914000"
    },
    {
        "id": 47931,
        "name": "Vétraz-Monthoux",
        "latitude": "46.17430000",
        "longitude": "6.25852000"
    },
    {
        "id": 47651,
        "name": "Veurey-Voroize",
        "latitude": "45.27268000",
        "longitude": "5.61372000"
    },
    {
        "id": 47652,
        "name": "Veynes",
        "latitude": "44.53406000",
        "longitude": "5.82321000"
    },
    {
        "id": 47653,
        "name": "Veyrac",
        "latitude": "45.89521000",
        "longitude": "1.10500000"
    },
    {
        "id": 47654,
        "name": "Veyras",
        "latitude": "44.73518000",
        "longitude": "4.56254000"
    },
    {
        "id": 47655,
        "name": "Veyre-Monton",
        "latitude": "45.66866000",
        "longitude": "3.17144000"
    },
    {
        "id": 47656,
        "name": "Veyrier-du-Lac",
        "latitude": "45.88234000",
        "longitude": "6.17709000"
    },
    {
        "id": 47932,
        "name": "Vézelise",
        "latitude": "48.48748000",
        "longitude": "6.08825000"
    },
    {
        "id": 47933,
        "name": "Vézénobres",
        "latitude": "44.05130000",
        "longitude": "4.13775000"
    },
    {
        "id": 47657,
        "name": "Vezin-le-Coquet",
        "latitude": "48.11857000",
        "longitude": "-1.75466000"
    },
    {
        "id": 47658,
        "name": "Vezins",
        "latitude": "47.12015000",
        "longitude": "-0.70971000"
    },
    {
        "id": 47659,
        "name": "Vianne",
        "latitude": "44.19658000",
        "longitude": "0.32104000"
    },
    {
        "id": 47660,
        "name": "Viarmes",
        "latitude": "49.13082000",
        "longitude": "2.37074000"
    },
    {
        "id": 47661,
        "name": "Vias",
        "latitude": "43.31156000",
        "longitude": "3.41774000"
    },
    {
        "id": 47662,
        "name": "Vibraye",
        "latitude": "48.05607000",
        "longitude": "0.74171000"
    },
    {
        "id": 47664,
        "name": "Vic-en-Bigorre",
        "latitude": "43.38682000",
        "longitude": "0.05471000"
    },
    {
        "id": 47663,
        "name": "Vic-Fezensac",
        "latitude": "43.77183000",
        "longitude": "0.31368000"
    },
    {
        "id": 47665,
        "name": "Vic-la-Gardiole",
        "latitude": "43.49080000",
        "longitude": "3.79750000"
    },
    {
        "id": 47666,
        "name": "Vic-le-Comte",
        "latitude": "45.64296000",
        "longitude": "3.24607000"
    },
    {
        "id": 47667,
        "name": "Vic-sur-Aisne",
        "latitude": "49.40609000",
        "longitude": "3.11223000"
    },
    {
        "id": 47668,
        "name": "Vic-sur-Cère",
        "latitude": "44.98011000",
        "longitude": "2.62505000"
    },
    {
        "id": 47669,
        "name": "Vic-sur-Seille",
        "latitude": "48.78195000",
        "longitude": "6.53079000"
    },
    {
        "id": 47670,
        "name": "Vichy",
        "latitude": "46.12709000",
        "longitude": "3.42577000"
    },
    {
        "id": 47671,
        "name": "Vicq",
        "latitude": "50.40738000",
        "longitude": "3.60348000"
    },
    {
        "id": 47672,
        "name": "Vicq-sur-Breuilh",
        "latitude": "45.64661000",
        "longitude": "1.38179000"
    },
    {
        "id": 47673,
        "name": "Vidauban",
        "latitude": "43.42721000",
        "longitude": "6.43185000"
    },
    {
        "id": 47674,
        "name": "Vieille Chapelle",
        "latitude": "43.24963000",
        "longitude": "5.38048000"
    },
    {
        "id": 47675,
        "name": "Vieille-Brioude",
        "latitude": "45.26470000",
        "longitude": "3.40479000"
    },
    {
        "id": 47677,
        "name": "Vieille-Église",
        "latitude": "50.92823000",
        "longitude": "2.07671000"
    },
    {
        "id": 47676,
        "name": "Vieille-Toulouse",
        "latitude": "43.52366000",
        "longitude": "1.44230000"
    },
    {
        "id": 47678,
        "name": "Vieillevigne",
        "latitude": "46.97214000",
        "longitude": "-1.43405000"
    },
    {
        "id": 47679,
        "name": "Vielle-Saint-Girons",
        "latitude": "43.95000000",
        "longitude": "-1.30000000"
    },
    {
        "id": 47680,
        "name": "Vielmur-sur-Agout",
        "latitude": "43.61667000",
        "longitude": "2.10000000"
    },
    {
        "id": 47681,
        "name": "Viennay",
        "latitude": "46.68711000",
        "longitude": "-0.24641000"
    },
    {
        "id": 47682,
        "name": "Vienne",
        "latitude": "45.52569000",
        "longitude": "4.87484000"
    },
    {
        "id": 47683,
        "name": "Vienne",
        "latitude": "46.53528000",
        "longitude": "0.45201000"
    },
    {
        "id": 47684,
        "name": "Vienne-en-Val",
        "latitude": "47.80036000",
        "longitude": "2.13460000"
    },
    {
        "id": 47685,
        "name": "Vierzon",
        "latitude": "47.22186000",
        "longitude": "2.06840000"
    },
    {
        "id": 47686,
        "name": "Viesly",
        "latitude": "50.15345000",
        "longitude": "3.46236000"
    },
    {
        "id": 47687,
        "name": "Vieux-Berquin",
        "latitude": "50.69489000",
        "longitude": "2.64444000"
    },
    {
        "id": 47688,
        "name": "Vieux-Boucau-les-Bains",
        "latitude": "43.78947000",
        "longitude": "-1.39957000"
    },
    {
        "id": 47689,
        "name": "Vieux-Charmont",
        "latitude": "47.52190000",
        "longitude": "6.83738000"
    },
    {
        "id": 47690,
        "name": "Vieux-Condé",
        "latitude": "50.45944000",
        "longitude": "3.56738000"
    },
    {
        "id": 47691,
        "name": "Vieux-Thann",
        "latitude": "47.80400000",
        "longitude": "7.12067000"
    },
    {
        "id": 47692,
        "name": "Vif",
        "latitude": "45.05654000",
        "longitude": "5.67204000"
    },
    {
        "id": 47693,
        "name": "Vigeois",
        "latitude": "45.37934000",
        "longitude": "1.51731000"
    },
    {
        "id": 47694,
        "name": "Vignacourt",
        "latitude": "50.01236000",
        "longitude": "2.19743000"
    },
    {
        "id": 47695,
        "name": "Vigneulles-lès-Hattonchâtel",
        "latitude": "48.98152000",
        "longitude": "5.70464000"
    },
    {
        "id": 47696,
        "name": "Vigneux-de-Bretagne",
        "latitude": "47.32547000",
        "longitude": "-1.73678000"
    },
    {
        "id": 47697,
        "name": "Vigneux-sur-Seine",
        "latitude": "48.70291000",
        "longitude": "2.41357000"
    },
    {
        "id": 47698,
        "name": "Vignoc",
        "latitude": "48.24842000",
        "longitude": "-1.78169000"
    },
    {
        "id": 47699,
        "name": "Vignot",
        "latitude": "48.77418000",
        "longitude": "5.60904000"
    },
    {
        "id": 47700,
        "name": "Vignoux-sur-Barangeon",
        "latitude": "47.20068000",
        "longitude": "2.17258000"
    },
    {
        "id": 47701,
        "name": "Vigny",
        "latitude": "49.07902000",
        "longitude": "1.92806000"
    },
    {
        "id": 47702,
        "name": "Vigy",
        "latitude": "49.20443000",
        "longitude": "6.29906000"
    },
    {
        "id": 47703,
        "name": "Vihiers",
        "latitude": "47.14631000",
        "longitude": "-0.53238000"
    },
    {
        "id": 47704,
        "name": "Vilallonga dels Monts",
        "latitude": "42.52557000",
        "longitude": "2.90434000"
    },
    {
        "id": 47705,
        "name": "Villabé",
        "latitude": "48.58949000",
        "longitude": "2.45096000"
    },
    {
        "id": 47706,
        "name": "Village-Neuf",
        "latitude": "47.60682000",
        "longitude": "7.56964000"
    },
    {
        "id": 47707,
        "name": "Villaines-la-Juhel",
        "latitude": "48.34416000",
        "longitude": "-0.27734000"
    },
    {
        "id": 47708,
        "name": "Villandry",
        "latitude": "47.34019000",
        "longitude": "0.51050000"
    },
    {
        "id": 47709,
        "name": "Villar-Saint-Pancrace",
        "latitude": "44.87318000",
        "longitude": "6.62669000"
    },
    {
        "id": 47710,
        "name": "Villard-Bonnot",
        "latitude": "45.23460000",
        "longitude": "5.88323000"
    },
    {
        "id": 47711,
        "name": "Villard-de-Lans",
        "latitude": "45.07156000",
        "longitude": "5.55637000"
    },
    {
        "id": 47712,
        "name": "Villargondran",
        "latitude": "45.26427000",
        "longitude": "6.37668000"
    },
    {
        "id": 47713,
        "name": "Villars",
        "latitude": "45.46785000",
        "longitude": "4.35539000"
    },
    {
        "id": 47714,
        "name": "Villars-les-Dombes",
        "latitude": "46.00208000",
        "longitude": "5.03248000"
    },
    {
        "id": 47715,
        "name": "Villaudric",
        "latitude": "43.83044000",
        "longitude": "1.43166000"
    },
    {
        "id": 47716,
        "name": "Villaz",
        "latitude": "45.95127000",
        "longitude": "6.19447000"
    },
    {
        "id": 47837,
        "name": "Villé",
        "latitude": "48.34270000",
        "longitude": "7.30260000"
    },
    {
        "id": 47717,
        "name": "Ville-d’Avray",
        "latitude": "48.82358000",
        "longitude": "2.19311000"
    },
    {
        "id": 47718,
        "name": "Ville-la-Grand",
        "latitude": "46.20300000",
        "longitude": "6.25010000"
    },
    {
        "id": 47719,
        "name": "Ville-sous-Anjou",
        "latitude": "45.37274000",
        "longitude": "4.85081000"
    },
    {
        "id": 47720,
        "name": "Ville-sous-la-Ferté",
        "latitude": "48.12060000",
        "longitude": "4.78957000"
    },
    {
        "id": 47721,
        "name": "Villebarou",
        "latitude": "47.62344000",
        "longitude": "1.32252000"
    },
    {
        "id": 47722,
        "name": "Villebernier",
        "latitude": "47.25374000",
        "longitude": "-0.03229000"
    },
    {
        "id": 47723,
        "name": "Villeblevin",
        "latitude": "48.32446000",
        "longitude": "3.08038000"
    },
    {
        "id": 47724,
        "name": "Villebois",
        "latitude": "45.84822000",
        "longitude": "5.43310000"
    },
    {
        "id": 47725,
        "name": "Villebon-sur-Yvette",
        "latitude": "48.70594000",
        "longitude": "2.24019000"
    },
    {
        "id": 47726,
        "name": "Villebret",
        "latitude": "46.26683000",
        "longitude": "2.63862000"
    },
    {
        "id": 47727,
        "name": "Villecresnes",
        "latitude": "48.72002000",
        "longitude": "2.53940000"
    },
    {
        "id": 47728,
        "name": "Villecroze",
        "latitude": "43.58223000",
        "longitude": "6.27587000"
    },
    {
        "id": 47729,
        "name": "Villedieu-la-Blouère",
        "latitude": "47.14738000",
        "longitude": "-1.06286000"
    },
    {
        "id": 47730,
        "name": "Villedieu-les-Poêles",
        "latitude": "48.83333000",
        "longitude": "-1.21667000"
    },
    {
        "id": 47731,
        "name": "Villedieu-sur-Indre",
        "latitude": "46.84598000",
        "longitude": "1.53975000"
    },
    {
        "id": 47732,
        "name": "Villedômer",
        "latitude": "47.54651000",
        "longitude": "0.88775000"
    },
    {
        "id": 47733,
        "name": "Villefagnan",
        "latitude": "46.01140000",
        "longitude": "0.07936000"
    },
    {
        "id": 47734,
        "name": "Villefontaine",
        "latitude": "45.61278000",
        "longitude": "5.15058000"
    },
    {
        "id": 47735,
        "name": "Villefranche-d'Albigeois",
        "latitude": "43.89635000",
        "longitude": "2.33022000"
    },
    {
        "id": 47738,
        "name": "Villefranche-d’Allier",
        "latitude": "46.39652000",
        "longitude": "2.85717000"
    },
    {
        "id": 47736,
        "name": "Villefranche-de-Lauragais",
        "latitude": "43.40000000",
        "longitude": "1.71694000"
    },
    {
        "id": 47737,
        "name": "Villefranche-de-Rouergue",
        "latitude": "44.35166000",
        "longitude": "2.03702000"
    },
    {
        "id": 47739,
        "name": "Villefranche-sur-Cher",
        "latitude": "47.30000000",
        "longitude": "1.76667000"
    },
    {
        "id": 47740,
        "name": "Villefranche-sur-Mer",
        "latitude": "43.70392000",
        "longitude": "7.31088000"
    },
    {
        "id": 47741,
        "name": "Villefranche-sur-Saône",
        "latitude": "45.98967000",
        "longitude": "4.71961000"
    },
    {
        "id": 47742,
        "name": "Villefranque",
        "latitude": "43.43631000",
        "longitude": "-1.45324000"
    },
    {
        "id": 47743,
        "name": "Villegailhenc",
        "latitude": "43.26867000",
        "longitude": "2.35469000"
    },
    {
        "id": 47744,
        "name": "Villegouge",
        "latitude": "44.96667000",
        "longitude": "-0.30000000"
    },
    {
        "id": 47745,
        "name": "Villejuif",
        "latitude": "48.79390000",
        "longitude": "2.35992000"
    },
    {
        "id": 47746,
        "name": "Villejust",
        "latitude": "48.68304000",
        "longitude": "2.23610000"
    },
    {
        "id": 47747,
        "name": "Villelaure",
        "latitude": "43.71075000",
        "longitude": "5.43422000"
    },
    {
        "id": 47748,
        "name": "Villelongue-de-la-Salanque",
        "latitude": "42.72637000",
        "longitude": "2.98240000"
    },
    {
        "id": 47749,
        "name": "Villemandeur",
        "latitude": "47.98701000",
        "longitude": "2.71802000"
    },
    {
        "id": 47750,
        "name": "Villemeux-sur-Eure",
        "latitude": "48.67259000",
        "longitude": "1.46470000"
    },
    {
        "id": 47751,
        "name": "Villemoirieu",
        "latitude": "45.71884000",
        "longitude": "5.22586000"
    },
    {
        "id": 47752,
        "name": "Villemoisson-sur-Orge",
        "latitude": "48.66632000",
        "longitude": "2.33657000"
    },
    {
        "id": 47753,
        "name": "Villemolaque",
        "latitude": "42.58815000",
        "longitude": "2.83890000"
    },
    {
        "id": 47754,
        "name": "Villemomble",
        "latitude": "48.88333000",
        "longitude": "2.50000000"
    },
    {
        "id": 47755,
        "name": "Villemoustaussou",
        "latitude": "43.25186000",
        "longitude": "2.36552000"
    },
    {
        "id": 47756,
        "name": "Villemur-sur-Tarn",
        "latitude": "43.86708000",
        "longitude": "1.50281000"
    },
    {
        "id": 47757,
        "name": "Villenauxe-la-Grande",
        "latitude": "48.58333000",
        "longitude": "3.55000000"
    },
    {
        "id": 47758,
        "name": "Villenave-d’Ornon",
        "latitude": "44.77327000",
        "longitude": "-0.54420000"
    },
    {
        "id": 47759,
        "name": "Villeneuve",
        "latitude": "46.02096000",
        "longitude": "4.83591000"
    },
    {
        "id": 47760,
        "name": "Villeneuve",
        "latitude": "44.94488000",
        "longitude": "6.56545000"
    },
    {
        "id": 47761,
        "name": "Villeneuve",
        "latitude": "44.43333000",
        "longitude": "2.03333000"
    },
    {
        "id": 47766,
        "name": "Villeneuve-d'Ascq",
        "latitude": "50.61669000",
        "longitude": "3.16664000"
    },
    {
        "id": 47771,
        "name": "Villeneuve-d’Olmes",
        "latitude": "42.90610000",
        "longitude": "1.81937000"
    },
    {
        "id": 47767,
        "name": "Villeneuve-de-Berg",
        "latitude": "44.55699000",
        "longitude": "4.50215000"
    },
    {
        "id": 47770,
        "name": "Villeneuve-de-la-Raho",
        "latitude": "42.63596000",
        "longitude": "2.91651000"
    },
    {
        "id": 47768,
        "name": "Villeneuve-de-Marsan",
        "latitude": "43.88906000",
        "longitude": "-0.30926000"
    },
    {
        "id": 47769,
        "name": "Villeneuve-de-Rivière",
        "latitude": "43.12829000",
        "longitude": "0.66351000"
    },
    {
        "id": 47772,
        "name": "Villeneuve-la-Comptal",
        "latitude": "43.28833000",
        "longitude": "1.91773000"
    },
    {
        "id": 47773,
        "name": "Villeneuve-la-Garenne",
        "latitude": "48.93935000",
        "longitude": "2.31478000"
    },
    {
        "id": 47774,
        "name": "Villeneuve-la-Guyard",
        "latitude": "48.34093000",
        "longitude": "3.06176000"
    },
    {
        "id": 47775,
        "name": "Villeneuve-la-Rivière",
        "latitude": "42.69366000",
        "longitude": "2.80294000"
    },
    {
        "id": 47776,
        "name": "Villeneuve-le-Comte",
        "latitude": "48.81413000",
        "longitude": "2.82953000"
    },
    {
        "id": 47777,
        "name": "Villeneuve-le-Roi",
        "latitude": "48.73684000",
        "longitude": "2.40081000"
    },
    {
        "id": 47779,
        "name": "Villeneuve-lès-Avignon",
        "latitude": "43.96840000",
        "longitude": "4.79630000"
    },
    {
        "id": 47781,
        "name": "Villeneuve-lès-Béziers",
        "latitude": "43.31517000",
        "longitude": "3.28059000"
    },
    {
        "id": 47780,
        "name": "Villeneuve-lès-Bouloc",
        "latitude": "43.76769000",
        "longitude": "1.42278000"
    },
    {
        "id": 47782,
        "name": "Villeneuve-lès-Maguelone",
        "latitude": "43.53333000",
        "longitude": "3.86667000"
    },
    {
        "id": 47778,
        "name": "Villeneuve-les-Sablons",
        "latitude": "49.23753000",
        "longitude": "2.07782000"
    },
    {
        "id": 47762,
        "name": "Villeneuve-Loubet",
        "latitude": "43.65790000",
        "longitude": "7.12233000"
    },
    {
        "id": 47763,
        "name": "Villeneuve-Saint-Georges",
        "latitude": "48.73219000",
        "longitude": "2.44925000"
    },
    {
        "id": 47764,
        "name": "Villeneuve-Saint-Germain",
        "latitude": "49.37976000",
        "longitude": "3.35952000"
    },
    {
        "id": 47783,
        "name": "Villeneuve-sur-Bellot",
        "latitude": "48.86203000",
        "longitude": "3.34143000"
    },
    {
        "id": 47784,
        "name": "Villeneuve-sur-Lot",
        "latitude": "44.40854000",
        "longitude": "0.70415000"
    },
    {
        "id": 47785,
        "name": "Villeneuve-sur-Yonne",
        "latitude": "48.08247000",
        "longitude": "3.29688000"
    },
    {
        "id": 47765,
        "name": "Villeneuve-Tolosane",
        "latitude": "43.52316000",
        "longitude": "1.34102000"
    },
    {
        "id": 47786,
        "name": "Villennes-sur-Seine",
        "latitude": "48.94137000",
        "longitude": "1.99137000"
    },
    {
        "id": 47787,
        "name": "Villenouvelle",
        "latitude": "43.43581000",
        "longitude": "1.66279000"
    },
    {
        "id": 47788,
        "name": "Villenoy",
        "latitude": "48.94112000",
        "longitude": "2.86020000"
    },
    {
        "id": 47789,
        "name": "Villeparisis",
        "latitude": "48.94208000",
        "longitude": "2.61463000"
    },
    {
        "id": 47790,
        "name": "Villepinte",
        "latitude": "48.96203000",
        "longitude": "2.53253000"
    },
    {
        "id": 47791,
        "name": "Villepinte",
        "latitude": "43.28213000",
        "longitude": "2.08760000"
    },
    {
        "id": 47792,
        "name": "Villepreux",
        "latitude": "48.82815000",
        "longitude": "1.99760000"
    },
    {
        "id": 47813,
        "name": "Villeréal",
        "latitude": "44.63631000",
        "longitude": "0.74326000"
    },
    {
        "id": 47793,
        "name": "Villerest",
        "latitude": "45.99539000",
        "longitude": "4.03463000"
    },
    {
        "id": 47794,
        "name": "Villereversure",
        "latitude": "46.18521000",
        "longitude": "5.38262000"
    },
    {
        "id": 47795,
        "name": "Villers-Bocage",
        "latitude": "49.99810000",
        "longitude": "2.31683000"
    },
    {
        "id": 47796,
        "name": "Villers-Bocage",
        "latitude": "49.07960000",
        "longitude": "-0.65412000"
    },
    {
        "id": 47797,
        "name": "Villers-Bretonneux",
        "latitude": "49.86844000",
        "longitude": "2.51688000"
    },
    {
        "id": 47798,
        "name": "Villers-Cotterêts",
        "latitude": "49.25311000",
        "longitude": "3.09003000"
    },
    {
        "id": 47810,
        "name": "Villers-Écalles",
        "latitude": "49.54136000",
        "longitude": "0.91942000"
    },
    {
        "id": 47803,
        "name": "Villers-en-Cauchies",
        "latitude": "50.22574000",
        "longitude": "3.40362000"
    },
    {
        "id": 47804,
        "name": "Villers-la-Montagne",
        "latitude": "49.47175000",
        "longitude": "5.82522000"
    },
    {
        "id": 47805,
        "name": "Villers-le-Lac",
        "latitude": "47.06319000",
        "longitude": "6.66699000"
    },
    {
        "id": 47806,
        "name": "Villers-lès-Nancy",
        "latitude": "48.67333000",
        "longitude": "6.15283000"
    },
    {
        "id": 47799,
        "name": "Villers-Outréaux",
        "latitude": "50.03590000",
        "longitude": "3.29947000"
    },
    {
        "id": 47800,
        "name": "Villers-Pol",
        "latitude": "50.28460000",
        "longitude": "3.61449000"
    },
    {
        "id": 47801,
        "name": "Villers-Saint-Paul",
        "latitude": "49.28885000",
        "longitude": "2.48968000"
    },
    {
        "id": 47802,
        "name": "Villers-Semeuse",
        "latitude": "49.74201000",
        "longitude": "4.74697000"
    },
    {
        "id": 47807,
        "name": "Villers-sous-Saint-Leu",
        "latitude": "49.21235000",
        "longitude": "2.39485000"
    },
    {
        "id": 47808,
        "name": "Villers-sur-Coudun",
        "latitude": "49.48308000",
        "longitude": "2.80457000"
    },
    {
        "id": 47809,
        "name": "Villers-sur-Mer",
        "latitude": "49.32264000",
        "longitude": "0.00027000"
    },
    {
        "id": 47811,
        "name": "Villersexel",
        "latitude": "47.55070000",
        "longitude": "6.43273000"
    },
    {
        "id": 47812,
        "name": "Villerupt",
        "latitude": "49.46715000",
        "longitude": "5.93202000"
    },
    {
        "id": 47814,
        "name": "Villes-sur-Auzon",
        "latitude": "44.05669000",
        "longitude": "5.23430000"
    },
    {
        "id": 47815,
        "name": "Villetaneuse",
        "latitude": "48.95833000",
        "longitude": "2.34167000"
    },
    {
        "id": 47816,
        "name": "Villetelle",
        "latitude": "43.73105000",
        "longitude": "4.13658000"
    },
    {
        "id": 47817,
        "name": "Villette-de-Vienne",
        "latitude": "45.59049000",
        "longitude": "4.91528000"
    },
    {
        "id": 47818,
        "name": "Villeurbanne",
        "latitude": "45.76601000",
        "longitude": "4.87950000"
    },
    {
        "id": 47819,
        "name": "Villevaudé",
        "latitude": "48.91751000",
        "longitude": "2.65228000"
    },
    {
        "id": 47823,
        "name": "Villevêque",
        "latitude": "47.56095000",
        "longitude": "-0.42383000"
    },
    {
        "id": 47820,
        "name": "Villeveyrac",
        "latitude": "43.50095000",
        "longitude": "3.60723000"
    },
    {
        "id": 47821,
        "name": "Villevieille",
        "latitude": "43.78795000",
        "longitude": "4.09756000"
    },
    {
        "id": 47822,
        "name": "Villevocance",
        "latitude": "45.22481000",
        "longitude": "4.58827000"
    },
    {
        "id": 47824,
        "name": "Villey-Saint-Étienne",
        "latitude": "48.73254000",
        "longitude": "5.97851000"
    },
    {
        "id": 47836,
        "name": "Villié-Morgon",
        "latitude": "46.16227000",
        "longitude": "4.68069000"
    },
    {
        "id": 47827,
        "name": "Villiers-en-Lieu",
        "latitude": "48.66785000",
        "longitude": "4.89755000"
    },
    {
        "id": 47828,
        "name": "Villiers-en-Plaine",
        "latitude": "46.40895000",
        "longitude": "-0.53756000"
    },
    {
        "id": 47830,
        "name": "Villiers-le-Bâcle",
        "latitude": "48.72819000",
        "longitude": "2.11925000"
    },
    {
        "id": 47829,
        "name": "Villiers-le-Bel",
        "latitude": "49.00875000",
        "longitude": "2.39819000"
    },
    {
        "id": 47831,
        "name": "Villiers-le-Morhier",
        "latitude": "48.62018000",
        "longitude": "1.56349000"
    },
    {
        "id": 47825,
        "name": "Villiers-Saint-Fréderic",
        "latitude": "48.81667000",
        "longitude": "1.88333000"
    },
    {
        "id": 47826,
        "name": "Villiers-Saint-Georges",
        "latitude": "48.64998000",
        "longitude": "3.40754000"
    },
    {
        "id": 47832,
        "name": "Villiers-sur-Loir",
        "latitude": "47.80507000",
        "longitude": "0.99774000"
    },
    {
        "id": 47833,
        "name": "Villiers-sur-Marne",
        "latitude": "48.83100000",
        "longitude": "2.54844000"
    },
    {
        "id": 47834,
        "name": "Villiers-sur-Morin",
        "latitude": "48.86099000",
        "longitude": "2.87773000"
    },
    {
        "id": 47835,
        "name": "Villiers-sur-Orge",
        "latitude": "48.65953000",
        "longitude": "2.30002000"
    },
    {
        "id": 47838,
        "name": "Vimines",
        "latitude": "45.54640000",
        "longitude": "5.86523000"
    },
    {
        "id": 47839,
        "name": "Vimory",
        "latitude": "47.94786000",
        "longitude": "2.68701000"
    },
    {
        "id": 47840,
        "name": "Vimoutiers",
        "latitude": "48.92772000",
        "longitude": "0.19835000"
    },
    {
        "id": 47841,
        "name": "Vimy",
        "latitude": "50.37243000",
        "longitude": "2.81034000"
    },
    {
        "id": 47842,
        "name": "Vinassan",
        "latitude": "43.20443000",
        "longitude": "3.07463000"
    },
    {
        "id": 47843,
        "name": "Vinay",
        "latitude": "45.20832000",
        "longitude": "5.40646000"
    },
    {
        "id": 47851,
        "name": "Vinça",
        "latitude": "42.64486000",
        "longitude": "2.52830000"
    },
    {
        "id": 47844,
        "name": "Vincennes",
        "latitude": "48.84860000",
        "longitude": "2.43769000"
    },
    {
        "id": 47845,
        "name": "Vincey",
        "latitude": "48.33749000",
        "longitude": "6.33134000"
    },
    {
        "id": 47846,
        "name": "Vineuil",
        "latitude": "47.58380000",
        "longitude": "1.37601000"
    },
    {
        "id": 47847,
        "name": "Vineuil-Saint-Firmin",
        "latitude": "49.20024000",
        "longitude": "2.49567000"
    },
    {
        "id": 47848,
        "name": "Vinneuf",
        "latitude": "48.34795000",
        "longitude": "3.14013000"
    },
    {
        "id": 47849,
        "name": "Vinon-sur-Verdon",
        "latitude": "43.72484000",
        "longitude": "5.81168000"
    },
    {
        "id": 47850,
        "name": "Vinsobres",
        "latitude": "44.33328000",
        "longitude": "5.06204000"
    },
    {
        "id": 47852,
        "name": "Violaines",
        "latitude": "50.54160000",
        "longitude": "2.78860000"
    },
    {
        "id": 47853,
        "name": "Violay",
        "latitude": "45.85335000",
        "longitude": "4.35951000"
    },
    {
        "id": 47854,
        "name": "Violès",
        "latitude": "44.16176000",
        "longitude": "4.95483000"
    },
    {
        "id": 47855,
        "name": "Vion",
        "latitude": "47.81923000",
        "longitude": "-0.23916000"
    },
    {
        "id": 47856,
        "name": "Virazeil",
        "latitude": "44.50705000",
        "longitude": "0.22177000"
    },
    {
        "id": 47857,
        "name": "Vire",
        "latitude": "48.83849000",
        "longitude": "-0.88929000"
    },
    {
        "id": 47858,
        "name": "Vireux-Molhain",
        "latitude": "50.07874000",
        "longitude": "4.72426000"
    },
    {
        "id": 47859,
        "name": "Vireux-Wallerand",
        "latitude": "50.08196000",
        "longitude": "4.73017000"
    },
    {
        "id": 47860,
        "name": "Virey-le-Grand",
        "latitude": "46.83333000",
        "longitude": "4.86667000"
    },
    {
        "id": 47861,
        "name": "Viriat",
        "latitude": "46.25484000",
        "longitude": "5.21567000"
    },
    {
        "id": 47862,
        "name": "Virieu",
        "latitude": "45.48404000",
        "longitude": "5.47586000"
    },
    {
        "id": 47863,
        "name": "Virieu-le-Grand",
        "latitude": "45.84766000",
        "longitude": "5.65146000"
    },
    {
        "id": 47864,
        "name": "Viriville",
        "latitude": "45.31579000",
        "longitude": "5.20376000"
    },
    {
        "id": 47865,
        "name": "Viroflay",
        "latitude": "48.80078000",
        "longitude": "2.16181000"
    },
    {
        "id": 47866,
        "name": "Viry",
        "latitude": "46.11291000",
        "longitude": "6.03808000"
    },
    {
        "id": 47867,
        "name": "Viry-Châtillon",
        "latitude": "48.67211000",
        "longitude": "2.39318000"
    },
    {
        "id": 47868,
        "name": "Viry-Noureuil",
        "latitude": "49.63214000",
        "longitude": "3.24322000"
    },
    {
        "id": 47869,
        "name": "Visan",
        "latitude": "44.31468000",
        "longitude": "4.95033000"
    },
    {
        "id": 47876,
        "name": "Vitré",
        "latitude": "48.11776000",
        "longitude": "-1.20577000"
    },
    {
        "id": 47870,
        "name": "Vitrolles",
        "latitude": "43.46000000",
        "longitude": "5.24861000"
    },
    {
        "id": 47871,
        "name": "Vitry-aux-Loges",
        "latitude": "47.93333000",
        "longitude": "2.26667000"
    },
    {
        "id": 47872,
        "name": "Vitry-en-Artois",
        "latitude": "50.32660000",
        "longitude": "2.97999000"
    },
    {
        "id": 47873,
        "name": "Vitry-le-François",
        "latitude": "48.72472000",
        "longitude": "4.58439000"
    },
    {
        "id": 47874,
        "name": "Vitry-sur-Orne",
        "latitude": "49.26595000",
        "longitude": "6.11074000"
    },
    {
        "id": 47875,
        "name": "Vitry-sur-Seine",
        "latitude": "48.78716000",
        "longitude": "2.40332000"
    },
    {
        "id": 47877,
        "name": "Vitteaux",
        "latitude": "47.39732000",
        "longitude": "4.54190000"
    },
    {
        "id": 47878,
        "name": "Vittel",
        "latitude": "48.20085000",
        "longitude": "5.94843000"
    },
    {
        "id": 47879,
        "name": "Viuz-en-Sallaz",
        "latitude": "46.14673000",
        "longitude": "6.40781000"
    },
    {
        "id": 47880,
        "name": "Viuz-la-Chiésaz",
        "latitude": "45.81203000",
        "longitude": "6.06645000"
    },
    {
        "id": 47881,
        "name": "Vivier-au-Court",
        "latitude": "49.73326000",
        "longitude": "4.82939000"
    },
    {
        "id": 47882,
        "name": "Viviers",
        "latitude": "44.48280000",
        "longitude": "4.68895000"
    },
    {
        "id": 47883,
        "name": "Viviers-du-Lac",
        "latitude": "45.65000000",
        "longitude": "5.90000000"
    },
    {
        "id": 47884,
        "name": "Viviers-lès-Montagnes",
        "latitude": "43.55474000",
        "longitude": "2.17672000"
    },
    {
        "id": 47885,
        "name": "Viviez",
        "latitude": "44.55678000",
        "longitude": "2.21649000"
    },
    {
        "id": 47886,
        "name": "Vivonne",
        "latitude": "46.42953000",
        "longitude": "0.26443000"
    },
    {
        "id": 47887,
        "name": "Vivy",
        "latitude": "47.32648000",
        "longitude": "-0.05531000"
    },
    {
        "id": 47888,
        "name": "Vix",
        "latitude": "46.36456000",
        "longitude": "-0.86072000"
    },
    {
        "id": 47889,
        "name": "Vizille",
        "latitude": "45.07819000",
        "longitude": "5.77074000"
    },
    {
        "id": 47934,
        "name": "Vœuil-et-Giget",
        "latitude": "45.58333000",
        "longitude": "0.15000000"
    },
    {
        "id": 47890,
        "name": "Voglans",
        "latitude": "45.61868000",
        "longitude": "5.88798000"
    },
    {
        "id": 47891,
        "name": "Void-Vacon",
        "latitude": "48.68333000",
        "longitude": "5.61667000"
    },
    {
        "id": 47892,
        "name": "Voiron",
        "latitude": "45.36471000",
        "longitude": "5.58560000"
    },
    {
        "id": 47893,
        "name": "Voisenon",
        "latitude": "48.57170000",
        "longitude": "2.66480000"
    },
    {
        "id": 47894,
        "name": "Voisins-le-Bretonneux",
        "latitude": "48.75793000",
        "longitude": "2.05137000"
    },
    {
        "id": 47895,
        "name": "Volgelsheim",
        "latitude": "48.01462000",
        "longitude": "7.55456000"
    },
    {
        "id": 47896,
        "name": "Volmerange-les-Mines",
        "latitude": "49.44326000",
        "longitude": "6.08062000"
    },
    {
        "id": 47897,
        "name": "Volonne",
        "latitude": "44.11039000",
        "longitude": "6.01424000"
    },
    {
        "id": 47898,
        "name": "Volstroff",
        "latitude": "49.31135000",
        "longitude": "6.25976000"
    },
    {
        "id": 47899,
        "name": "Volvic",
        "latitude": "45.87196000",
        "longitude": "3.03832000"
    },
    {
        "id": 47900,
        "name": "Volx",
        "latitude": "43.87787000",
        "longitude": "5.84148000"
    },
    {
        "id": 47901,
        "name": "Vonnas",
        "latitude": "46.21727000",
        "longitude": "4.99246000"
    },
    {
        "id": 47902,
        "name": "Voreppe",
        "latitude": "45.29484000",
        "longitude": "5.63192000"
    },
    {
        "id": 47903,
        "name": "Vorey",
        "latitude": "45.18638000",
        "longitude": "3.90991000"
    },
    {
        "id": 47904,
        "name": "Vosges",
        "latitude": "48.19161000",
        "longitude": "6.40533000"
    },
    {
        "id": 47905,
        "name": "Vougy",
        "latitude": "46.10435000",
        "longitude": "4.11771000"
    },
    {
        "id": 47906,
        "name": "Vouillé",
        "latitude": "46.64011000",
        "longitude": "0.16778000"
    },
    {
        "id": 47907,
        "name": "Voujeaucourt",
        "latitude": "47.47513000",
        "longitude": "6.77431000"
    },
    {
        "id": 47908,
        "name": "Voulangis",
        "latitude": "48.85252000",
        "longitude": "2.89558000"
    },
    {
        "id": 47909,
        "name": "Voulx",
        "latitude": "48.28204000",
        "longitude": "2.96747000"
    },
    {
        "id": 47910,
        "name": "Vouneuil-sous-Biard",
        "latitude": "46.57387000",
        "longitude": "0.26988000"
    },
    {
        "id": 47911,
        "name": "Vouneuil-sur-Vienne",
        "latitude": "46.71793000",
        "longitude": "0.53936000"
    },
    {
        "id": 47912,
        "name": "Vourey",
        "latitude": "45.32180000",
        "longitude": "5.51931000"
    },
    {
        "id": 47913,
        "name": "Vourles",
        "latitude": "45.65878000",
        "longitude": "4.77325000"
    },
    {
        "id": 47914,
        "name": "Voutezac",
        "latitude": "45.29214000",
        "longitude": "1.43721000"
    },
    {
        "id": 47915,
        "name": "Vouvray",
        "latitude": "47.41087000",
        "longitude": "0.79892000"
    },
    {
        "id": 47916,
        "name": "Vouziers",
        "latitude": "49.39791000",
        "longitude": "4.70120000"
    },
    {
        "id": 47917,
        "name": "Vouzon",
        "latitude": "47.64515000",
        "longitude": "2.05609000"
    },
    {
        "id": 47918,
        "name": "Voves",
        "latitude": "48.27138000",
        "longitude": "1.62583000"
    },
    {
        "id": 47919,
        "name": "Vred",
        "latitude": "50.39455000",
        "longitude": "3.23029000"
    },
    {
        "id": 47920,
        "name": "Vrigne-aux-Bois",
        "latitude": "49.73716000",
        "longitude": "4.85567000"
    },
    {
        "id": 47921,
        "name": "Vue",
        "latitude": "47.19953000",
        "longitude": "-1.87750000"
    },
    {
        "id": 47922,
        "name": "Vulaines-sur-Seine",
        "latitude": "48.43186000",
        "longitude": "2.76476000"
    },
    {
        "id": 47935,
        "name": "Wahagnies",
        "latitude": "50.48665000",
        "longitude": "3.03448000"
    },
    {
        "id": 47936,
        "name": "Wailly",
        "latitude": "50.52287000",
        "longitude": "2.06792000"
    },
    {
        "id": 47937,
        "name": "Walbourg",
        "latitude": "48.88628000",
        "longitude": "7.78828000"
    },
    {
        "id": 47938,
        "name": "Waldighofen",
        "latitude": "47.55045000",
        "longitude": "7.31512000"
    },
    {
        "id": 47939,
        "name": "Walincourt-Selvigny",
        "latitude": "50.06667000",
        "longitude": "3.33333000"
    },
    {
        "id": 47940,
        "name": "Wallers",
        "latitude": "50.37432000",
        "longitude": "3.39188000"
    },
    {
        "id": 47941,
        "name": "Walscheid",
        "latitude": "48.65308000",
        "longitude": "7.14998000"
    },
    {
        "id": 47942,
        "name": "Wambrechies",
        "latitude": "50.68276000",
        "longitude": "3.04784000"
    },
    {
        "id": 47943,
        "name": "Wandignies-Hamage",
        "latitude": "50.39609000",
        "longitude": "3.31450000"
    },
    {
        "id": 47944,
        "name": "Wangenbourg-Engenthal",
        "latitude": "48.62805000",
        "longitude": "7.30471000"
    },
    {
        "id": 47945,
        "name": "Warcq",
        "latitude": "49.77517000",
        "longitude": "4.68175000"
    },
    {
        "id": 47946,
        "name": "Wardrecques",
        "latitude": "50.71108000",
        "longitude": "2.34483000"
    },
    {
        "id": 47947,
        "name": "Wargnies-le-Grand",
        "latitude": "50.30751000",
        "longitude": "3.66038000"
    },
    {
        "id": 47948,
        "name": "Warhem",
        "latitude": "50.97592000",
        "longitude": "2.49303000"
    },
    {
        "id": 47949,
        "name": "Warluis",
        "latitude": "49.38874000",
        "longitude": "2.14220000"
    },
    {
        "id": 47950,
        "name": "Warmeriville",
        "latitude": "49.35227000",
        "longitude": "4.21882000"
    },
    {
        "id": 47951,
        "name": "Wasquehal",
        "latitude": "50.67043000",
        "longitude": "3.13382000"
    },
    {
        "id": 47952,
        "name": "Wasselonne",
        "latitude": "48.63779000",
        "longitude": "7.44506000"
    },
    {
        "id": 47953,
        "name": "Wassigny",
        "latitude": "50.01334000",
        "longitude": "3.59988000"
    },
    {
        "id": 47954,
        "name": "Wassy",
        "latitude": "48.49811000",
        "longitude": "4.94775000"
    },
    {
        "id": 47955,
        "name": "Watten",
        "latitude": "50.83685000",
        "longitude": "2.21346000"
    },
    {
        "id": 47956,
        "name": "Wattignies",
        "latitude": "50.58639000",
        "longitude": "3.04394000"
    },
    {
        "id": 47957,
        "name": "Wattrelos",
        "latitude": "50.70118000",
        "longitude": "3.21812000"
    },
    {
        "id": 47958,
        "name": "Wattwiller",
        "latitude": "47.83649000",
        "longitude": "7.17785000"
    },
    {
        "id": 47959,
        "name": "Wavignies",
        "latitude": "49.54748000",
        "longitude": "2.36032000"
    },
    {
        "id": 47960,
        "name": "Wavrechain-sous-Denain",
        "latitude": "50.33224000",
        "longitude": "3.41252000"
    },
    {
        "id": 47961,
        "name": "Wavrin",
        "latitude": "50.57386000",
        "longitude": "2.93630000"
    },
    {
        "id": 47962,
        "name": "Waziers",
        "latitude": "50.38717000",
        "longitude": "3.11490000"
    },
    {
        "id": 47963,
        "name": "Weitbruch",
        "latitude": "48.75455000",
        "longitude": "7.77935000"
    },
    {
        "id": 47964,
        "name": "Wervicq-Sud",
        "latitude": "50.77450000",
        "longitude": "3.04207000"
    },
    {
        "id": 47965,
        "name": "Westhoffen",
        "latitude": "48.60352000",
        "longitude": "7.44289000"
    },
    {
        "id": 47966,
        "name": "Wettolsheim",
        "latitude": "48.05755000",
        "longitude": "7.29844000"
    },
    {
        "id": 47967,
        "name": "Weyersheim",
        "latitude": "48.71622000",
        "longitude": "7.80127000"
    },
    {
        "id": 47968,
        "name": "Widensolen",
        "latitude": "48.06377000",
        "longitude": "7.48015000"
    },
    {
        "id": 47969,
        "name": "Wiesviller",
        "latitude": "49.08095000",
        "longitude": "7.16415000"
    },
    {
        "id": 47970,
        "name": "Wignehies",
        "latitude": "50.01550000",
        "longitude": "4.00913000"
    },
    {
        "id": 47971,
        "name": "Wihr-au-Val",
        "latitude": "48.05196000",
        "longitude": "7.20493000"
    },
    {
        "id": 47972,
        "name": "Willems",
        "latitude": "50.63206000",
        "longitude": "3.23840000"
    },
    {
        "id": 47973,
        "name": "Willerwald",
        "latitude": "49.02481000",
        "longitude": "7.03726000"
    },
    {
        "id": 47974,
        "name": "Wimereux",
        "latitude": "50.76963000",
        "longitude": "1.61139000"
    },
    {
        "id": 47975,
        "name": "Wimille",
        "latitude": "50.76418000",
        "longitude": "1.63137000"
    },
    {
        "id": 47976,
        "name": "Wimmenau",
        "latitude": "48.91217000",
        "longitude": "7.42189000"
    },
    {
        "id": 47977,
        "name": "Wingen-sur-Moder",
        "latitude": "48.91900000",
        "longitude": "7.37955000"
    },
    {
        "id": 47978,
        "name": "Wingersheim",
        "latitude": "48.72149000",
        "longitude": "7.63464000"
    },
    {
        "id": 47979,
        "name": "Wingles",
        "latitude": "50.49382000",
        "longitude": "2.85500000"
    },
    {
        "id": 47980,
        "name": "Winnezeele",
        "latitude": "50.84100000",
        "longitude": "2.55118000"
    },
    {
        "id": 47981,
        "name": "Wintzenheim",
        "latitude": "48.07269000",
        "longitude": "7.29072000"
    },
    {
        "id": 47982,
        "name": "Wisches",
        "latitude": "48.50881000",
        "longitude": "7.26814000"
    },
    {
        "id": 47983,
        "name": "Wissant",
        "latitude": "50.88530000",
        "longitude": "1.66263000"
    },
    {
        "id": 47984,
        "name": "Wissembourg",
        "latitude": "49.03708000",
        "longitude": "7.94548000"
    },
    {
        "id": 47985,
        "name": "Wissous",
        "latitude": "48.73352000",
        "longitude": "2.32338000"
    },
    {
        "id": 47986,
        "name": "Witry-lès-Reims",
        "latitude": "49.29162000",
        "longitude": "4.11921000"
    },
    {
        "id": 47987,
        "name": "Wittelsheim",
        "latitude": "47.80947000",
        "longitude": "7.24154000"
    },
    {
        "id": 47988,
        "name": "Wittenheim",
        "latitude": "47.81090000",
        "longitude": "7.32756000"
    },
    {
        "id": 47989,
        "name": "Wittisheim",
        "latitude": "48.26451000",
        "longitude": "7.58683000"
    },
    {
        "id": 47990,
        "name": "Wizernes",
        "latitude": "50.71170000",
        "longitude": "2.24316000"
    },
    {
        "id": 47991,
        "name": "Woerth",
        "latitude": "48.93953000",
        "longitude": "7.74279000"
    },
    {
        "id": 47992,
        "name": "Woincourt",
        "latitude": "50.06630000",
        "longitude": "1.53676000"
    },
    {
        "id": 47993,
        "name": "Woippy",
        "latitude": "49.15111000",
        "longitude": "6.15132000"
    },
    {
        "id": 47994,
        "name": "Wolfgantzen",
        "latitude": "48.02805000",
        "longitude": "7.50040000"
    },
    {
        "id": 47995,
        "name": "Wolfisheim",
        "latitude": "48.58723000",
        "longitude": "7.66708000"
    },
    {
        "id": 47996,
        "name": "Wormhout",
        "latitude": "50.88129000",
        "longitude": "2.46901000"
    },
    {
        "id": 47997,
        "name": "Woustviller",
        "latitude": "49.07636000",
        "longitude": "7.00487000"
    },
    {
        "id": 47998,
        "name": "Xertigny",
        "latitude": "48.04394000",
        "longitude": "6.40836000"
    },
    {
        "id": 47999,
        "name": "Xonrupt-Longemer",
        "latitude": "48.08223000",
        "longitude": "6.92944000"
    },
    {
        "id": 48000,
        "name": "Yainville",
        "latitude": "49.45371000",
        "longitude": "0.82920000"
    },
    {
        "id": 48001,
        "name": "Ychoux",
        "latitude": "44.32869000",
        "longitude": "-0.95179000"
    },
    {
        "id": 48002,
        "name": "Ydes",
        "latitude": "45.34722000",
        "longitude": "2.43727000"
    },
    {
        "id": 48024,
        "name": "Yébleron",
        "latitude": "49.63333000",
        "longitude": "0.53746000"
    },
    {
        "id": 48003,
        "name": "Yenne",
        "latitude": "45.70420000",
        "longitude": "5.75795000"
    },
    {
        "id": 48004,
        "name": "Yerres",
        "latitude": "48.71785000",
        "longitude": "2.49338000"
    },
    {
        "id": 48005,
        "name": "Yerville",
        "latitude": "49.66720000",
        "longitude": "0.89594000"
    },
    {
        "id": 48023,
        "name": "Yèvres",
        "latitude": "48.21078000",
        "longitude": "1.18717000"
    },
    {
        "id": 48006,
        "name": "Yffiniac",
        "latitude": "48.48428000",
        "longitude": "-2.67647000"
    },
    {
        "id": 48007,
        "name": "Ygos-Saint-Saturnin",
        "latitude": "43.97651000",
        "longitude": "-0.73780000"
    },
    {
        "id": 48008,
        "name": "Ymare",
        "latitude": "49.35060000",
        "longitude": "1.17938000"
    },
    {
        "id": 48009,
        "name": "Youx",
        "latitude": "46.14467000",
        "longitude": "2.79903000"
    },
    {
        "id": 48010,
        "name": "Yport",
        "latitude": "49.73716000",
        "longitude": "0.31537000"
    },
    {
        "id": 48011,
        "name": "Yssingeaux",
        "latitude": "45.14282000",
        "longitude": "4.12372000"
    },
    {
        "id": 48012,
        "name": "Ytrac",
        "latitude": "44.91200000",
        "longitude": "2.36248000"
    },
    {
        "id": 48013,
        "name": "Yutz",
        "latitude": "49.35571000",
        "longitude": "6.19260000"
    },
    {
        "id": 48014,
        "name": "Yvelines",
        "latitude": "48.80546000",
        "longitude": "1.85696000"
    },
    {
        "id": 48015,
        "name": "Yves",
        "latitude": "46.01922000",
        "longitude": "-1.04833000"
    },
    {
        "id": 48016,
        "name": "Yvetot",
        "latitude": "49.61744000",
        "longitude": "0.75814000"
    },
    {
        "id": 48017,
        "name": "Yvignac-la-Tour",
        "latitude": "48.35000000",
        "longitude": "-2.18333000"
    },
    {
        "id": 48018,
        "name": "Yvrac",
        "latitude": "44.87786000",
        "longitude": "-0.45870000"
    },
    {
        "id": 48019,
        "name": "Yvré-le-Pôlin",
        "latitude": "47.82235000",
        "longitude": "0.15581000"
    },
    {
        "id": 48020,
        "name": "Yzernay",
        "latitude": "47.02229000",
        "longitude": "-0.70295000"
    },
    {
        "id": 48021,
        "name": "Yzeure",
        "latitude": "46.56596000",
        "longitude": "3.35446000"
    },
    {
        "id": 48022,
        "name": "Yzeures-sur-Creuse",
        "latitude": "46.78609000",
        "longitude": "0.87166000"
    },
    {
        "id": 48025,
        "name": "Zegerscappel",
        "latitude": "50.88333000",
        "longitude": "2.40000000"
    },
    {
        "id": 48026,
        "name": "Zillisheim",
        "latitude": "47.69604000",
        "longitude": "7.29736000"
    },
    {
        "id": 48027,
        "name": "Zimmersheim",
        "latitude": "47.72079000",
        "longitude": "7.38847000"
    },
    {
        "id": 48028,
        "name": "Zonza",
        "latitude": "41.74934000",
        "longitude": "9.17082000"
    },
    {
        "id": 48029,
        "name": "Zutkerque",
        "latitude": "50.85303000",
        "longitude": "2.06818000"
    },
    {
        "id": 48030,
        "name": "Zuydcoote",
        "latitude": "51.06096000",
        "longitude": "2.49338000"
    }
]
export default cities;
