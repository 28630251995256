import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../utils/baseUrl";
import { handleApiError } from "../utils/errorHandler";
import { showAlert } from "./alertSlice";
// import { saveUserState } from "./loadStorage";


export const getStripePaiementAsync = createAsyncThunk(
    "stripe/getStripePaiementAsync",
    async (dispatch) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${baseURL}api/stripe/payment`
            });
            return response.data;
        } catch (error) {
            return dispatch(showAlert({ isError: false, errorMessage: handleApiError(error.response) }))
        }
    }
);


export const onboardUserAsync = createAsyncThunk(
    "stripe/onboardUserAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/onboard-user`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);

export const getAccountStatusAsync = createAsyncThunk(
    "stripe/getAccountStatusAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/get-account-status`
            });
            // saveUserState(response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);

export const getAccountBalanceAsync = createAsyncThunk(
    "stripe/getAccountBalanceAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/get-account-balance`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);


export const payoutSettingAsync = createAsyncThunk(
    "stripe/payoutSettingAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/payout-setting`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);


export const getSessionIdAsync = createAsyncThunk(
    "stripe/getSessionIdAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/session-id`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);


export const successRequestAsync = createAsyncThunk(
    "stripe/successRequestAsync",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/stripe-request-success`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);

export const getRefund = createAsyncThunk(
    "stripe/Refund",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                data: payload,
                url: `${baseURL}api/stripe/refund`
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);



const initialState = {
    data: [],
    status: "idle",
    isSuccess: false,
    paiements: [],
    url: "",
    balance: "",
    errorMessage: "",
    sessionId: "",
    isFetching: false,
};

const stripeSlice = createSlice({
    name: "stripe",
    initialState,
    reducers: {
        stripeClearState: (state) => {
            state.status = 'idle';
            state.url = '';
            state.balance = '';
            state.errorMessage = '';
            state.paiements = [];
            state.isSuccess = false;
            state.sessionId = '';
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getStripePaiementAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [getStripePaiementAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.paiements = payload;
            state.isFetching = false
        },
        [getStripePaiementAsync.rejected]: (state) => {
            state.status = 'failed';
            state.isFetching = false
        },

        //Onboard User 
        [onboardUserAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [onboardUserAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.url = payload.url;
            state.isFetching = false
        },
        [onboardUserAsync.rejected]: (state, { payload }) => {
            state.status = 'failed';
            state.errorMessage = payload;
            state.isFetching = false
        },

        // get Account Status
        [getAccountStatusAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [getAccountStatusAsync.fulfilled]: (state) => {
            state.status = "success";
            state.isFetching = false
        },
        [getAccountStatusAsync.rejected]: (state, { payload }) => {
            state.status = 'failed';
            state.errorMessage = payload;
            state.isFetching = false
        },

        // Get Balance
        [getAccountBalanceAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [getAccountBalanceAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.isFetching = false
            state.balance = payload
        },
        [getAccountBalanceAsync.rejected]: (state, { payload }) => {
            state.status = 'failed';
            state.errorMessage = payload;
            state.isFetching = false
        },

        // Payout Setting
        [payoutSettingAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [payoutSettingAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.isFetching = false
            state.url = payload.url;
        },
        [payoutSettingAsync.rejected]: (state, { payload }) => {
            state.status = 'failed';
            state.errorMessage = payload;
            state.isFetching = false
        },

        // Session ID
        [getSessionIdAsync.pending]: (state) => {
            state.status = 'pending'
            state.isFetching = true
        },
        [getSessionIdAsync.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.isFetching = false
            state.sessionId = payload.sessionId;
        },
        [getSessionIdAsync.rejected]: (state, { payload }) => {
            state.status = 'failed';
            state.errorMessage = payload;
            state.isFetching = false
        },


        // Stripe Request Success
        // [successRequestAsync.pending]: (state) => {
        //     state.status = 'pending'
        //     state.isFetching = true
        // },
        // [successRequestAsync.fulfilled]: (state) => {
        //     state.status = "success";
        //     state.isFetching = false;
        //     state.isSuccess = true;
        // },
        // [successRequestAsync.rejected]: (state, { payload }) => {
        //     state.status = 'failed';
        //     state.errorMessage = payload;
        //     state.isFetching = false
        // },
    },
});
export const { stripeClearState } = stripeSlice.actions;
export default stripeSlice.reducer;
