import { createSlice } from '@reduxjs/toolkit';
import { loadChatTokenState, loadTokenState } from '../../../utils/local-storage';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: loadTokenState(),
        chatToken: loadChatTokenState(),
        user: ""
    },
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload
            return state
        },

        setUser: (state, { payload }) => {
            state.user = payload
            return state
        },

        setChatToken: (state, {payload}) => {
            state.chatToken = payload
            return state
        }
    },
});

export const { setToken, setUser, setChatToken } = authSlice.actions;
export default authSlice.reducer;