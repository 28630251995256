import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Modal, Radio } from "antd";
import Banner from "../components/banner";
import "react-toastify/dist/ReactToastify.css";
import { useDisponibiliteFetchQuery } from "../disponibilite/service/disponibilite-api";
import moment from "moment";
import PaiementForm from "./paiement-form";
import "./styles/style.scss";
import { usePrestationByUserQuery } from "../prestation/service/prestation-api";
import { Button } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { InputErrors, InputRegex } from "../../../utils/globalVars";

function ReservationPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const prestations = usePrestationByUserQuery(state.professionalId);
  const disponibilite = useDisponibiliteFetchQuery(state.professionalId);
  const [isSuccess, setIsSuccess] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endTime, setEndTime] = useState("");
  const [open, setOpen] = useState(false);
  const [prestation, setPrestation] = useState(state.prestation);
  const [address, setAddress] = useState(state.adresse);
  const { user } = useSelector((state) => state.auth);
  const [saloon, setSaloon] = useState(true);
  const [edit, setEdit] = useState(false);
  const [slotId, setSlotId] = useState(null);
  const [addressError, setAddressError] = useState(null);

  const handleClick = (value) => {
    setStartDate(value.start);
    setEndTime(value.end);
    setIsSuccess(true);
    setSlotId(value.id);
  };

  const handleAddressChange = (e) => {
    setEdit(false);
    if (e.target.value === 1) {
      setAddress(user.adresse);
      setSaloon(false);
    } else {
      setAddress(state.adresse);
      setSaloon(true);
    }
  };

  const editAddress = (e) => {
    setAddress("");
    setEdit((prev) => !prev);
  };

  const handleAddressInput = (e) => {
    if (e.target.value.match(InputRegex.addressCheck)) {
      setAddress(e.target.value);
      setAddressError(null);
    } else {
      setAddress(e.target.value);
      setAddressError(InputErrors.addressError);
    }
  };

  return (
    <div className="reservation animate__animated animate__fadeIn">
      <Banner title="Réservation" />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="animate__animated animate__fadeIn">
              <div className="prestation">
                <span onClick={() => navigate(-1)} className="goBack">
                  Fiche coiffeuse
                </span>{" "}
                / <span>Reservation</span>
                <br />
                <br />
                <h5>1 . Choix de la prestation</h5>
                {prestations.data?.map((item, itemIndex) => (
                  <Fragment key={"prestation-" + itemIndex}>
                    {!prestation ? (
                      <div className="content animate__animated animate__fadeIn">
                        <span>{item.libelle}</span>
                        <div>
                          <span>{item.price} €</span>
                          <Button
                            type="link"
                            onClick={() => setPrestation(item)}
                          >
                            Choisir <CaretRightOutlined />
                          </Button>
                        </div>
                      </div>
                    ) : item.id === prestation.id ? (
                      <div className="content animate__animated animate__fadeIn">
                        <span>{item.libelle}</span>
                        <div>
                          <span>{item.price} €</span>
                          <Button type="link" onClick={() => setPrestation("")}>
                            Modifier <CaretRightOutlined />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))}
              </div>

              <div className="horaire">
                <h5>2 . Choix de la date & heure</h5>
                <span>
                  Les heures proposées correspondent aux disponibilités du
                  prestataire choisi
                </span>
                <div className="disponibilite">
                  <ul>
                    {disponibilite.isSuccess &&
                      disponibilite.data &&
                      disponibilite.data
                        .filter((x) => x.start >= moment().toISOString())
                        .map((item, index) => (
                          <li
                            index={index}
                            onClick={() => handleClick(item)}
                            className={
                              item.start === startDate ? "active_date" : ""
                            }
                            key={"item-" + index}
                          >
                            <span>
                              {moment(item.start).format("llll")} -{" "}
                              {moment(item.end).format("llll")}
                            </span>
                            <span>Disponible</span>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <h5>3. Lieu de rendez-vous</h5>
            {state.mobilite === "se déplace" && (
              <>
                <Radio.Group onChange={handleAddressChange} defaultValue={2}>
                  <Radio value={1}>Ma Maison</Radio>
                  <Radio value={2}>Salon De Coiffure</Radio>
                </Radio.Group>
              </>
            )}
            <div className="address_content animate__animated animate__fadeIn">
              <label htmlFor="addresse">Addresse:</label>
              <input
                className="address_input"
                name="addresse"
                onChange={handleAddressInput}
                value={address}
                disabled={!edit}
                maxLength={500}
                minLength={5}
              />

              {state.mobilite === "se déplace" && !saloon && (
                <Button type="link" onClick={editAddress}>
                  Modifier <CaretRightOutlined />
                </Button>
              )}
            </div>
            {addressError && (
              <Alert message={addressError} type="error" showIcon />
            )}
            {isSuccess && prestation && address && !addressError && (
              <Button
                type="primary"
                id="confirmer_btn"
                onClick={() => setOpen(true)}
              >
                Confirmer
              </Button>
            )}
          </div>
        </div>
      </div>

      <Modal
        className="auth"
        centered
        open={open}
        footer={null}
        closable={true}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <div className="modal-body">
          <PaiementForm
            professional={state.professionalId}
            adresse={address}
            prestation={prestation}
            params={state}
            date={startDate}
            endTime={endTime}
            slotId={slotId}
          />
        </div>
      </Modal>
    </div>
  );
}
export default ReservationPage;
