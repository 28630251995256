import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/api-config";

export const catalogueApi = createApi({
  reducerPath: "catalogueApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Catalogue"],
  endpoints: (builder) => ({
    catalogueCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/catalogue`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Catalogue"],
    }),

    catalogueByUser: builder.query({
      query: (id) => ({ url: `/api/v1/catalogue/user/${id}` }),
      providesTags: ["Catalogue"],
    }),

    catalogueFetchOne: builder.mutation({
      query: (id) => ({
        url: `/api/v1/catalogue/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Catalogue"],
    }),

    catalogueUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/catalogue/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Catalogue"],
    }),

    catalogueDelete: builder.mutation({
      query: (id) => ({
        url: `/api/v1/catalogue/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Catalogue"],
    }),
  }),
});

export const {
  useCatalogueByUserQuery,
  useCatalogueCreateMutation,
  useCatalogueFetchOneMutation,
  useCatalogueUpdateMutation,
  useCatalogueDeleteMutation,
} = catalogueApi;
