import { Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import Banner from "../account/components/banner";

function WelcomePage() {
  return (
    <div className="auth">
      <Banner title="Connexion" />
      <div className="container">
        <div className="title" style={{ textAlign: "center" }}>
          <h2>Je suis</h2>
          <p>Un email vous sera envoyé pour réinitialiser votre mot de passe</p>
        </div>
        <Row>
          <Col lg={8} style={{ margin: "auto" }}>
            <Button className="btn-lg btn-primary" htmlType="submit">
              <Link to={"/login-client"}>Client</Link>
            </Button>
            <br />
            <br />
            <Button className="btn-lg btn-primary" htmlType="submit">
              <Link to={"/login-coiffeuse"}>Coiffeuse</Link>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default WelcomePage;
