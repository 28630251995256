import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        isError: false,
        isSuccess: false,
        successMessage: '',
        errorMessage: ''
    },
    reducers: {
        showAlert: (state, { payload }) => {
            state.isError = payload.isError
            state.isSuccess = payload.isSuccess
            state.errorMessage = payload.errorMessage
            state.successMessage = payload.successMessage
        }
    },
});

export const { showAlert } = alertSlice.actions;
export default alertSlice.reducer;